import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import "react-datepicker/dist/react-datepicker.css";
import CommissionService from '../../../services/etopup/CommissionService';
import AsyncSelect from 'react-select/async'
import API from '../../../http-common';
import LoginService from '../../../services/common/LoginService';
import LoadingPopup from '../../wallet/LoadingPopup';

class EditCommission extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commissionId: this.props.match.params.commissionId,
            isLoading: false,
            isLoadingPop: false,
            selectOption: null,
            fullName: '',
            customerType: '',
            commissionData: {
                commissionPercent: '',
                commissionAssignment: '',
                customerId: '',
                remark: ''
            },
        };
        this.onChangeCommissionPercent = this.onChangeCommissionPercent.bind(this);
        this.onChangeCommissionAssignment = this.onChangeCommissionAssignment.bind(this);
        this.onChangeRemark = this.onChangeRemark.bind(this);
        
    }
     

    onChangeCommissionPercent(e) {
        const commissionPercent = e.target.value;
        this.setState(function (prevState) {
            return {
                commissionData: {
                    ...prevState.commissionData,
                    commissionPercent: commissionPercent
                }
            };
        });
    }
    onChangeCommissionAssignment(e) {
        const commissionAssignment = e.target.value;
        this.setState(function (prevState) {
            return {
                commissionData: {
                    ...prevState.commissionData,
                    commissionAssignment: commissionAssignment
                }
            };
        });
    }
    onChangeRemark(e) {
        const remark = e.target.value;

        this.setState(function (prevState) {
            return {
                commissionData: {
                    ...prevState.commissionData,
                    remark: remark
                }
            };
        });
    }



    Cancel = (e) => {
        this.props.history.push('/dealersCommissionList');
    }


    componentDidMount() {
        this.getCommissionById(this.props.location.state.commissionId);

        LoginService.getUserInfo(localStorage.userId)
        .then(response => {
          this.setState({
            fullName: response.data.customerFullName,
            customerType: response.data.type
          });
        })
        .catch(e => {
        });
    }
    getCommissionById(commissionId) {
        CommissionService.getCommissionById(commissionId, localStorage.userId)
            .then(response => {
                this.setState({
                    commissionData: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {
                
                this.setState({ isLoading: true })
            });
    }
    handleSubmit = e => {
        e.preventDefault();
        var data = new FormData();
        data.append("id", this.props.location.state.commissionId);
        data.append("commissionPercent", this.state.commissionData.commissionPercent);
        data.append("commissionAssignment", this.state.commissionData.commissionAssignment);
        data.append("customerId", this.state.selectOption && this.state.selectOption.value);
        data.append("remark", this.state.commissionData.remark);


        this.setState({ isLoadingPop: true})  
        CommissionService.updateCommission(localStorage.userId, data)
            .then(
                (response) => {
                NotificationManager.success('Updated Successfully',`${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoadingPop: false})  
                this.Cancel()
            },
            (err) =>{
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);  
                this.setState({ isLoadingPop: false})  
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoadingPop: false})  
            });
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
          setTimeout(() => {
            API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
              .then((response) =>
                response.data
              )
              .then((data) => {
                const temp = [];
                data.forEach((element) => {
                  temp.push({ label: element.fullName, value: element.id })
                });
                callback(temp)
                this.setState({
                  parentCustomerList: data,
    
                })
              })
              .catch((error) => {
              })
          })
        }else {
            callback([])
        }
      }
    
      onSearchChange = (selectOption) => {
        this.setState({
          selectOption: selectOption
        });
    
    
      }

    render() {

        const { commissionData, selectOption, customerType, isLoading } = this.state;

        if (selectOption === null && commissionData.customerId.id) {
            this.state.selectOption = {
              label: commissionData.customerId && commissionData.customerId.fullName,
              value: commissionData.customerId && commissionData.customerId.id
            }
        }
        
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={150} width={150} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={this.handleSubmit} noValidate>
                                            {/*Basic Information*/}
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                                                            <div >
                                                                <label htmlFor='Custome rList'>Customer :</label>
                                                                <div style={{ fontSize: 'smaller' }}>

                                                                    <AsyncSelect id='CustomerList'
                                                                        className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                                                                        placeholder={"Search by name, account no or mobile number"}
                                                                        value={this.state.selectOption}
                                                                        isClearable={true}
                                                                        loadOptions={this.fatchData}
                                                                        isLoading={this.state.selectOption === {} &&
                                                                            this.state.selectOption === undefined &&
                                                                            this.state.selectOption === null ? true : false
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.onSearchChange(e)
                                                                        }}
                                                                        defaultOptions={false}
                                                                    />
                                                                </div>
                                                               

                                                            </div>

                                                            :
                                                            <></>
                                                        }

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Commission in %:</label>
                                                            <input type="text" className="form-control " id='fullName'
                                                                value={commissionData.commissionPercent} onChange={this.onChangeCommissionPercent} required="required" />
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Assigned To:</label>
                                                            <input type="text" className="form-control " id='fullName'
                                                     
                                                                value={commissionData.commissionAssignment} onChange={this.onChangeCommissionAssignment} required="required" />
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Remark:</label>
                                                            <input type="text" className="form-control " id='fullName'

                                                                value={commissionData.remark} onChange={this.onChangeRemark} />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoadingPop } type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Update</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>
                                {this.state.isLoadingPop && <LoadingPopup
                                    content={<>
                                            <form>
                                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                </form>
                                    </>}
                                />}

                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditCommission);