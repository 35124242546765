import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {  withRouter } from 'react-router-dom';
import IntegrationTypeService from '../../../services/etopup/IntegrationTypeService';
import Header from '../../common/Header';

class AddIntegrationType extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      type: '',
      remark: ''
        }
      this.handelInputChange=this.handelInputChange.bind(this);
      this.handleSubmit=this.handleSubmit.bind(this)
     }
     handelInputChange = e => {
      e.preventDefault()
      const { name, value } = e.target
      }

  handleSubmit = e => {
    e.preventDefault();
       
    var integrationTypeData = new FormData();
      integrationTypeData.append("type", this.state.type);
      integrationTypeData.append("remark", this.state.remark);

    

    IntegrationTypeService.createIntegrationType(localStorage.userId,integrationTypeData)
      .then(response => {
         NotificationManager.success("Integration type successfully saved", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        e.target.reset();
      })
      .catch(err  => {
        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      });
  };

  Cancel = e => {
    this.props.history.push('/integrationTypeList');
}

  render() {

    return (
      <div>
        <NotificationContainer/>
        <div className="content-wrapper bg-white">
          <div className="content-header">
            <div className="container-fluid">
   
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active"> IntegrationType</li>
                </ol>
              </div>
              <div >
                <div className="modal-body pt-5 ">

                  <div className='col-1'></div>
                  <div className='col-8 bg-light p-3'>
   
                    <form onSubmit={ this.handleSubmit }>
                      <div className="card  border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Add Integration Type </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col- col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='type'>Type:</label>

                                <div>
                                                        <select onChange={ e => this.state.type = e.target.value } className="form-control" name='type'>
                                                            <option value=""> Select integration type</option>
                                                            <option value={ `${process.env.REACT_APP_INTEGRATION_TYPE_SALAAM_IIT280}` }>{ "Salaam IIT280" }</option>
                                                            <option value={ `${process.env.REACT_APP_INTEGRATION_TYPE_SALAAM_AMINPAY}` }>{ "Salaam AminPay" }</option>
                                                            <option value={ `${process.env.REACT_APP_INTEGRATION_TYPE_MTN_DIRECT}` }>{ "Mtn" }</option>
                                                            <option value={ `${process.env.REACT_APP_INTEGRATION_TYPE_ROSHAN_DIRECT}` }>{ "Roshan" }</option>
                                                            <option value={ `${process.env.REACT_APP_INTEGRATION_TYPE_ETISALAT_DIRECT}` }>{ "Etisalat" }</option>
                                                        </select>
                                                    </div>
                            </div>

                            <div className=" form-group col- col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='remark'>Remark:</label>
                              <input type="text" className="form-control " id='remark' name='remark'
                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange }  />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="row  d-flex justify-content-left clear-fix ">
                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " onChange={ this.handelInputChange } onBlur={ this.handelInputChange } style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" 
                          className="btn btn-warning  px-3 py-2" onClick={this.Cancel} style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AddIntegrationType);
