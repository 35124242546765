import { faBackward, faBackwardFast, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import SettingService from '../../../services/common/SettingService';
import Menu from '../../wallet/Menu';
import { LOCALES } from '../Constant';
import Header from '../Header';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}


class ConfigurationList extends Component {
    constructor(props) {

        super(props)

        this.state = {
            pageNo: 1,
            pageSize: LOCALES.configuration_PAGE_SIZE,
            totalPage: 0,
            isLoading: false,
            totalelement: 0,
            resourceList: [],
            configurationList: [],
            configurationDB: [
                {
                    selected: ''
                }
            ],
            roleId: null,
            roleIds: [],
            counter: 1,
            roleconfiguration: 0,
            formErrors: {
                roleIdError: ""
            },
        }


    }

 
    componentDidMount() {            
        this.searchSelectedConfigurations();

    }
   

    searchSelectedConfigurations() {
        this.state.configurationList = []
        SettingService.getAutoConfigures()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    configurationList: data
                });

            });

        this.state.roleconfiguration = 0;
    }

 

    handleSubmit = e => {

            let configuration = {
                autoConfigures: this.state.configurationList
            }

            this.setState({ isLoading: true})
            SettingService.createAutoConfigure(configuration)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_PROCESS_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({ isLoading: false})
                        this.searchSelectedConfigurations()
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false})
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });
       
    };

    onChangeValue() {
        this.setState(function (prevState) {
            return {
                configurationDB: {
                    ...prevState.configurationDB,
                    selected: !this.state.configurationDB.selected
                },

            };
        });
    }

 

    render() {
        const { configurationList, totalelement, configurationDB, roleIds, roleId, pageNo, pageSize, totalPage, formErrors } = this.state;
        pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1

   
        return (
            <div>
                {/* <Header />
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" >Configuration List</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" >Display Configuration Information</div>
                                   
                                    <div className="clearfix">&nbsp;</div>
                                    <table className=" table table-striped table-birdered " style={{ height: '30px', fontSize: 'smaller' }} >
                                        <thead className=' thead-light'>
                                            <tr style={{ marginTop: 0 }}>
                                                <th>No.</th>
                                                <th>Description</th>                                           
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className='content-wrapper '>

                                            {configurationList && configurationList.map(configuration =>

                                                <tr key={configuration.id}>
                                                    <td>{this.state.counter++}</td>
                                                    <td className='p-1 '>{configuration.description}</td>                                                
                                                    <td className='p-1 '>
                                                        <input checked={configurationDB.selected = configuration.status} onChange={e => { e.target.checked ? configuration.status = true : configuration.status = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                              
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>
                                    <div className="clearfix">&nbsp;</div>
                                   
                                    <div className="row  d-flex justify-content-left ">
                                        <button disabled={this.state.isLoading} type="submit" onClick={this.handleSubmit} className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                                    </div>
                                </div>
                            </div>
                            <NotificationContainer />
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                 </>}
                            />}
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }
}


export default ConfigurationList;