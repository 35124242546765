import API from '../../http-common';


class LoginService {

    // login 
    signin(loginInfo) {
      return API.post("/api/aelafAdmin/signin", loginInfo);
    }

    companyProfileInfo(){
      return API.get('/api/aelafAdmin/companyProfileInfo');
    }
    // logedin info 
    getUserInfo(userId) {
      return API.get('/api/v1/getUserInfo' + '/' + userId);
    }

    // login 
    createEmplyeedAUser(userInfo) {
      return API.post("/api/v1/createEmployeedUser", userInfo);
    }
    

    // get users on the customer 
    getUsers(customerId, pageNo, pageSize) {
      return API.get('/api/v1/getUsers/' + customerId + '/' + pageNo + '/' + pageSize);
    }    

    // Signup 
    signup(signupInfo) {
      return API.post("/api/aelafAdmin/signUpCustomer", signupInfo);
    }

    // createUser 
    createUser(signupInfo) {
      return API.post("/api/v1/createUser", signupInfo);
    }

    ChangePassword(userId, newPassword, oldPassword) {
      return API.post("/api/v1/ChangePassword?userId=" + userId + "&newPassword=" +  newPassword + "&oldPassword=" + oldPassword);
    }

    ResetPassword(userId, newPassword) {
      return API.post("/api/v1/ResetPassword?userId=" + userId + "&newPassword=" +  newPassword);
    }

    getRoleInfo(roleId) {
      return API.get('/api/v1/getRoleInfo' + '/' + roleId);
    }

    getRoles(){
      return API.get('/api/v1/getRoles');
    }

    
    createRole(name){
      return API.post('/api/v1/createRole/' + name);
    }

}
export default new LoginService()