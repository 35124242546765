import React, { Component } from 'react'
import ReportService from '../../services/Reports/ReportService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import PreviewTable from './PreviewTable';
import { LOCALES } from '../common/Constant';
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { downloadExcel } from "react-export-table-to-excel";


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}




export class BalanceChcecker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CashReport: [],
            customerTypes: [],
            customerTypeId: undefined,
            status: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            reportType: 'pdf',
            vewierBtn: false,
            reportVewier: false,
            reportDownload: false,
            isLoading: false,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            columns: [
                {
                    Header: 'Date',
                    accessor: 'date',
                },

                {
                    Header: 'Total Cash',
                    accessor: 'totalCash',
                },
                {
                    Header: 'Total Bank Balance ',
                    accessor: 'totalBankBalance',
                },
                {
                    Header: 'Total AirTime',
                    accessor: 'totalAirTime',
                },
                {
                    Header: 'Total Customer Balance',
                    accessor: 'totalTopupBalance',
                },
                {
                    Header: 'Difference',
                    accessor: 'difference',
                }
            ],

            reportData: [],
            generateData: 1,
            pageNumber: 1,
            formErrors: {
                reportTypeError: "",
                dateToError: "",
                dateFromError: ""
            },
        }

    }


    getReportData() {
        let FilterCashInfo = {
            reportFormat: this.state.reportType,
            user: localStorage.userName,
            status: this.state.status,
            customerTypeId: this.state.customerTypeId,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }

        ReportService.getTotalBalanceDifference(FilterCashInfo, localStorage.userId)
        .then(response => response.data)
        .then((data) => {
            console.log(data)
            this.setState({
                isLoading: true,
                reportData: data !== undefined || data !== '' || data != null? data: []
            });

        })
        .catch(e => {

        });
    }

    getGenerateReport = e => {
        e.preventDefault();
        const { formErrors } = this.state
        this.state.generateData = 1
        this.setState({
            isLoading: false 
         })  
        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'

            }
        }

        if (formValid(this.state.formErrors)) {
                this.getReportData();
                this.setState({
                    reportVewier: !this.state.reportVewier,
                    isLoading: true
                });
            

        }

        this.setState({
            formErrors
        });

    }

   
    componentDidMount() { 
        this.setState({
           isLoading: true 
        })  
       
    }

   
  
   

  



    exportPDF = () => {
        const { reportData, reportformat } = this.state
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        doc.setFont('Lato-Regular', 'normal');

        const title = "Balance Checker Report";
        const header = [["Date", "Total Cash", "Total Bank Balance", "Total AirTime", "Total Customer Balance", "Difference"]];

        const body = [];
        reportData.forEach((element, index, array) => {
            body.push([element.date,
            element.totalCash, element.totalBankBalance, element.totalAirTime, element.totalTopupBalance, element.difference])
        });

        let content = {
            startY: 50,
            head: header,
            body: body
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("BalanceCheckerReport.pdf")
    }

    actionPageSize(e) {
        this.state.pageSize = e.target.value;
        this.setState({
          isLoading: false,
          pageSize: e.target.value
        });
    
        this.getReportData();
      }

    exportToEXcel() {
        const { reportData, reportformat } = this.state
        const header = ["Date", "Total Cash", "Total Bank Balance", "Total AirTime", "Total Customer Balance", "Difference"];

        const body = [];
        reportData.forEach((element, index, array) => {
            body.push([element.date,
                element.totalCash, element.totalBankBalance, element.totalAirTime, element.totalTopupBalance, element.difference])
            });



        downloadExcel({
            fileName: "Customer Report",
            sheet: "Customer Report",
            tablePayload: {
                header,
                body: body
            },
        });
    }

    render() {
        const { formErrors,  isLoading, columns, reportData } = this.state

        return (
            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item " >Reports</li>
                                        <li className="breadcrumb-item active" >Balance Check Report</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" > Balance Check Information</div>

                                    <form onSubmit={this.getGenerateReport} noValidate>
                                        <div className='row'>
                                            <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                <label htmlFor='dateFrom' >Date From</label>

                                                <input type="date"  className="form-control" id='dateFrom'
                                                    onChange={e => this.state.dateFrom = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateFromError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                                )}
                                            </div>
                                            <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                <label htmlFor='dateTo'>Date To</label>
                                                <input type="date" className="form-control" id='dateTo'
                                                    onChange={e => this.state.dateTo = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateToError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                                )}

                                            </div>
                                        </div>



                                        <br />
                                        <div>
                                            <div className="row  d-flex justify-content-left ">

                                                <button type='submit' title={"Preview"} style={{ marginLeft: "10px" }}
                                                    className="btn btn-sm btn-success primary m-1  float-left"
                                                    >
                                                    <i className="fa fa-eye fa-sm" /> Generate 
                                                </button>

                                                <button type='submit' title={"Download"}
                                                    className="btn btn-sm btn-success primary m-1  float-left">
                                                    <i className="fa fa-download fa-sm" /> Clear
                                                </button>
</div>
                                            </div>
                                    
                                    </form>
                                                                              


                                            <Link className=" float-right m-1"
                                                onClick={() => this.exportToEXcel()}>
                                                <FontAwesomeIcon icon={faDownload} title="download as excel" />
                                                <FontAwesomeIcon icon={faFileExcel} title="download as excel" />
                                            </Link>

                                            <Link className=" float-right m-1"
                                                onClick={() => this.exportPDF()}>
                                                <FontAwesomeIcon icon={faDownload} title="download as pdf" />
                                                <FontAwesomeIcon icon={faFilePdf} title="download as pdf" />
                                            </Link>
                                        
                                    <div style={{ clear: 'both' }}></div>
                                    {!isLoading ?
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                                            </div>
                                        </div>

                                        :
                                        <>
                                           <div className='tableContainer'>
                                               <PreviewTable columns={columns} data={reportData} />
                                            </div>
                                            <div className="clearfix">&nbsp;</div>                                         

                                        </>
                                    }




                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }

}

export default BalanceChcecker