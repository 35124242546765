import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';

import CashInfoService from '../../../services/etopup/CashInfoService';
import RegionService from '../../../services/common/RegionService';
import CashMethodService from '../../../services/etopup/CashMethodService';
import CustomerListService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import AsyncSelect from 'react-select/async'
import Header from '../../common/Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);


export default class EditCashOut extends React.Component {
    constructor(props) {
        super(props);


        this.onChangeReceiverMobileNo = this.onChangeReceiverMobileNo.bind(this);
        this.onChangeCashMethod = this.onChangeCashMethod.bind(this)

        // this.handleAttachment = this.handleAttachment.bind(this);

        this.state = {
            cashMethod: [],
            balanceInfo: [],
            customerBank: [],
            customerId: [],
            bankInfoId: [],
            cashMethodId: '',
            isLoading: false,
            cashTypeId: LOCALES.CASH_OUT_TYPE,
            id: this.props.location.state.id,
            paramCustomerId: this.props.location.state.customerId,
            searchValue: null,
            cashInfoData: {
                customerId: [],
                accountNo: "",
                senderMobileNo: "",
                receiverMobileNo: "",
                cashMethodId: '',
                amount: "",
                description: "",
                personalName: '',
                personalBankName: '',
                personalBankAccount: ''
            },
            formErrors: {
                cashMethodError: "",
                phoneNumberError: "",
                customerNotFoundError: "",
                amountError: "",
                descriptionError: ""
            },


        };


    }

    cancel() {
        this.props.history.push('/cashOutList')
    }

    onChangeReceiverMobileNo = mobileNo => {
        this.state.cashInfoData.receiverMobileNo = mobileNo
        const { formErrors } = this.state
        this.state.customerId = undefined
        this.state.balanceInfo = undefined
        this.state.receiverMobileNo = mobileNo
        formErrors.phoneNumberError =
            mobileNo.length == 0 || mobileNo.length < 6 || !phoneNoRegEX.test(mobileNo) ? "Phone Number sholud be digites with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            const receiverMobileNo = mobileNo;
            this.getCustomerBankInfo(mobileNo)

            this.setState(function (prevState) {
                return {
                    cashInfoData: {
                        ...prevState.cashInfoData,
                        receiverMobileNo: receiverMobileNo
                    }

                };
            });
        }

        this.setState({
            formErrors
        })

    }

    onChangeCashMethod(e) {
        this.state.cashInfoData.cashMethodId = e.target.value;
        this.state.cashMethodId = e.target.value;
        const cashMethodId = e.target.value;

        this.setState(function (prevState) {
            return {
                cashInfoData: {
                    ...prevState.cashInfoData,
                    cashMethodId: cashMethodId
                }
            };
        });
    }

    componentDidMount() {
        this.getCashOutById(this.state.id);

        CashMethodService.getCashMethods()
            .then(response => {
                this.setState({
                    cashMethod: response.data

                });
                
            })
            .catch(e => {
                
            });

            CustomerListService.getBalance(this.state.customerId, localStorage.userId)
            .then(response => {
              this.setState({
                balanceInfo: response.data
              });
            })
            .catch(e => {
        
            });

    }


    getCustomerBankInfo(mobileNo) {
        this.state.customerBank = undefined
        this.state.customerId = undefined
        this.state.bankInfoId = undefined
        CustomerListService.getCustomerBankInfoByMobile(mobileNo, LOCALES.DEFAULT_CUSTOMER, localStorage.userId)
            .then(response => {
                this.setState({
                    customerBank: response.data,
                    customerId: response.data.customerId,
                    bankInfoId: response.data.bankInfoId
                });
                
            })
            .catch(e => {
                
            });
    }



    getCashOutById(id) {
        CashInfoService.getCashOut(id, localStorage.userId)
            .then(response => {
                this.setState({
                    cashInfoData: response.data,
                    cashMethodId: response.data.cashMethodId
                });
                
            })
            .catch(e => {
                
            });
    }

  


    updateCustomer() {
        const { searchValue, cashInfoData, cashMethodId, formErrors } = this.state
        formErrors.customerNotFoundError = searchValue == "" || searchValue == undefined   || searchValue === null ? "Phone number is Required" : this.state.formErrors.customerNotFoundError;
        formErrors.amountError = cashInfoData.amount == '' || cashInfoData.amount == null || cashInfoData.amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
        formErrors.cashMethodError = cashMethodId == '' || cashMethodId == null || cashMethodId == undefined ? 'You must make a selection' : this.state.formErrors.cashMethodError;

        if (formValid(this.state.formErrors)) {
            if (this.state.balanceInfo >= this.state.cashInfoData.amount) {
                const formData = new FormData();
                formData.append("amount", this.state.cashInfoData.amount);
                formData.append("cashMethodId", this.state.cashInfoData.cashMethodId)
                formData.append("cashTypeId", this.state.cashInfoData.cashTypeId)
                formData.append("senderCustomerId", localStorage.customerIdentity)
                formData.append("description", this.state.cashInfoData.description)
                formData.append("receiverCustomerId", this.state.searchValue && this.state.searchValue.value)
                formData.append("userId", localStorage.userId)
                formData.append("id", this.state.id)

                this.setState({ isLoading: true})  
                CashInfoService.updateCashOutWithFile(formData)
                    .then(
                        (res) => {
                            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                            this.setState({ isLoading: false})  
                            this.cancel()
                        },
                        (err) => {
                            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                            this.setState({ isLoading: false})  
                        })
                    .catch(function (response) {
                        NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false})  
                    });
            } else {
                NotificationManager.info(`${process.env.REACT_APP_CHECK_BALANCE}`, `${process.env.REACT_APP_MSG_TITLE_INFO}`, 3000);
            }
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }

    handelChange = e => {
        e.preventDefault()
        const { customerId, cashInfoData } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                cashInfoData.amount = value
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 3 & max 50" : ""
                break;
            // case 'phoneNumber':
            //     cashInfoData.receiverMobileNo = value
            //     formErrors.phoneNumberError =
            //         value.length == 0 || value.length < 10 || !phoneNoRegEX.test(value) ? name + " sholud be digites with a min 1 & max 10" : ""
            //     if (formErrors.phoneNumberError.length == 0) {
            //         this.onChangeReceiverMobileNo(value)
            //         formErrors.customerNotFoundError =
            //             customerId == null || customerId == undefined ?
            //                 "With this " + name + " there is no registered customer " : ""
            //     }
            //     break;
            case 'description':
                cashInfoData.description = value
                formErrors.descriptionError =
                    !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
          setTimeout(() => {
            API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/"+ localStorage.userId)    
          .then((response) =>               
             response.data
          )
              .then((data) => {
                const temp = [];
                data.forEach((element) => {
                  temp.push({ label: element.fullName, value: element.id })                
                });
                callback(temp)
                this.setState({
                  customerList: data
                })
              })
              .catch((error) => {
              })
          })
        }else {
            callback([])
        }
      }
    
    
      onSearchChange = (searchValue) => {
        const { formErrors } = this.state
    
        formErrors.customerNotFoundError =
           customerRegEx.test(searchValue) ? " Please insert customer Name or account no or mobile number" : ""
    
       if (searchValue) {
      this.getCustomerBalance(searchValue.value)
    
          this.setState({
            searchValue,
            formErrors
          });
        }
      }
    
getCustomerBalance(customerId){
    CustomerListService.getBalance(customerId, localStorage.userId)
    .then(response => {
      this.setState({
        balanceInfo: response.data
      });
    })
    .catch(e => {

    });
}
    render() {

        const { cashInfoData, searchValue, cashMethod, formErrors, balanceInfo } = this.state
        let cashMethodList = cashMethod.length > 0
            && cashMethod.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);

            if (searchValue === null && cashInfoData.customerId != '' ) {
                this.state.searchValue = {
                  label: cashInfoData.receiverFullName,
                  value: cashInfoData.customerId
                }
              this.getCustomerBalance(cashInfoData.customerId)
            }

        return (
            <div >
                {/* <Header/>
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Cash Out Information</li>

                                    </ol>
                                </div>

                            </div>
                            <div >
                                <div className="modal-body">

                                    <div className='col-1'></div>
                                    <div className='col-7 bg-light p-4'>

                                        <div class="modal-header">
                                            <h4 class="modal-title" >
                                                Edit Cash Out Information
                                            </h4>
                                        </div>


                                        {/*<h2>Register</h2> */}
                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                            <label htmlFor='cashMethodList'>Cash Method:</label>
                                            <select className="form-control "
                                                value={cashInfoData.cashMethodId}
                                                onChange={this.onChangeCashMethod} id='cashMethodList'>
                                                <option value={null}>----please select---</option>
                                                {cashMethodList}
                                            </select>

                                            {formErrors.cashMethodError.length > 0 && (
                                                <span style={{ color: "#F61C04" }}>{formErrors.cashMethodError}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                        <label htmlFor='CustomerList'>Customer:</label>
                                            <div style={{ fontSize: 'smaller' }}>
                                                <AsyncSelect id='CustomerList'
                                                    placeholder={"Search by name, account no or mobile number"}
                                                    value={this.state.searchValue}
                                                    isClearable={true}
                                                    loadOptions={this.fatchData}
                                                    isLoading={this.state.searchValue === {} &&
                                                        this.state.searchValue === undefined &&
                                                        this.state.searchValue === null ? true : false
                                                    }

                                                    onChange={(e) => {
                                                        this.onSearchChange(e)
                                                    }}
                                                    defaultOptions={false}
                                                />
                                                {formErrors.customerNotFoundError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.customerNotFoundError}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                            <label htmlFor='Balance'>Balance:</label>
                                            <input type="text" className="form-control " id='Balance'
                                                placeholder="Balance"
                                                value={balanceInfo}
                                                readOnly />
                                        </div>
                                                
                                        

                                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                            <label htmlFor='Amount'>Amount:</label>
                                            <input type="text" className="form-control" id='Amount'
                                                value={cashInfoData.amount} name='amount'
                                                onChange={this.handelChange} onBlur={this.handelChange}
                                                placeholder="Amount" />
                                            {formErrors.amountError.length > 0 && (
                                                <span style={{ color: "#F61C04" }}>{formErrors.amountError}</span>
                                            )}
                                        </div>

                                        <div className="form-group col-15 col-sm-8 col-md-8 col-lg-8">
                                            <label htmlFor='Description'>Description:</label>
                                            <textarea type="" className="form-control" id='Description'
                                                value={cashInfoData.description} name='description'
                                                onChange={this.handelChange} onBlur={this.handelChange}
                                                placeholder="Description" />
                                            {formErrors.descriptionError.length > 0 && (
                                                <span style={{ color: "#F61C04" }}>{formErrors.descriptionError}</span>
                                            )}
                                        </div>



                                        <div className="row  d-flex justify-content-left ">

                                            <button type="submit" style={{ marginLeft: "15px" }} className="btn btn-success  px-3 "
                                                onClick={() => this.updateCustomer()}>Update</button>
                                            <button type="submit" onClick={this.cancel.bind(this)}
                                                className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                                        </div>
                                        <NotificationContainer />
                                        {this.state.isLoading && <LoadingPopup
                                        content={<>
                                                <form>
                                                        <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                    </form>
                                        </>}
                                        />}

                                    </div>

                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}
