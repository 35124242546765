import API from '../../http-common';

class ProviderCommissionService {

    getProviderCommissionById(id, userId) {
        return API.get("/api/v1/getProviderCommissionById/" + id + "/" + userId);
    }
    getProviderCommissionByProviderId(providerId,userId) {
        return API.get("/api/v1/getProviderCommissionByProviderId/" + providerId+'/'+userId);
    }

    getAllProviderCommissions(pageNo, pageSize, userId) {
        return API.get("/api/v1/getAllProviderCommissions/" + pageNo + "/" + pageSize + "/" + userId);
    }

    createProviderCommission(providerCommissionData) {
        return API.post("/api/v1/createProviderCommission", providerCommissionData);
    }

    updateProviderCommission(providerCommissionData) {
        return API.put("/api/v1/updateProviderCommission", providerCommissionData);
    }
}
export default new ProviderCommissionService()