import API from '../../http-common';

class TopupBalanceService {
    findTopupByCustomerId(customerId, userId) {
        return API.get('/api/v1/findTopupByCustomerId/' + customerId + "/" + userId);
    }

    findTopupBalanceByCustomerId(customerId, userId) {
        return API.get('/api/v1/findTopupBalanceByCustomerId/' + customerId + "/" + userId);
    }

    findTopupAccBalanceByCustomerId(customerId, userId) {
        return API.get('/api/v1/findTopupAccBalance/' + customerId + "/" + userId);
    }

    getTotalCompanyBalance(userId){
        return API.get('/api/v1/getTotalCompanyBalance/'  + userId);
    }
}
export default new TopupBalanceService()