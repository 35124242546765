
import CashInfoService from '../../../services/etopup/CashInfoService';
import CustomerListService from '../../../services/common/CustomersService';
import React, { Component } from 'react'
//import Menu from '../../wallet/Menu';
import TopupMenu from '../../../components/etopup/TopupMenu';
import { Link } from 'react-router-dom';
import { LOCALES } from '../../common/Constant'
import ProvidersService from '../../../services/common/ProvidersService';
import Header from '../Header';
import ReactLoading from "react-loading";
import IntegrationTypeService from '../../../services/etopup/IntegrationTypeService';

export default class ProviderDetail extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            isLoading: false,
            id: this.props.location.state.id,
            providersInfo: {
                providerId: '',
                startCommand: '',
                command: '',
                ipAddress: '',
                port: '',
                addtionalPath: '',
                serviceCode: '',
                bearerCode: '',
                language: '',
                currency: '',
                integrationTypeId:''
            }
        };
    }

    componentDidMount() {
        ProvidersService.getProvidersInfo(this.state.id, localStorage.userId)
            .then(response => {
                this.setState({
                    isLoading: true,
                    providersInfo: response.data
                });
            })
            .catch(e => {
            });
    }



    render() {
        const { providersInfo, isLoading } = this.state;
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            Provider Detail
                                        </li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        <div className="form-title" >Provider Detail</div>
                                        <hr />
                                        <div className="table-responsive w-50">
                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                                <tr className="subheaders">
                                                    <td colspan="2" className="text-white py-4" > Provider Detail Information</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Providers Company Name</td>
                                                    <td className="text-justify">{ providersInfo.providerId.providerName }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >URL</td>
                                                    <td className="text-justify">{ providersInfo.url }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Integration Type</td>
                                                    <td className="text-justify">{ providersInfo.integrationTypeId.type }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.ETSALAT_PROVIDER }>
                                                    <td className="text-left font-weight-bolder" >Start Command</td>
                                                    <td className="text-justify">{ providersInfo.startCommand }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.ETSALAT_PROVIDER }>
                                                    <td className="text-left font-weight-bolder">Command</td>
                                                    <td className="text-justify">{ providersInfo.command }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.ETSALAT_PROVIDER }>
                                                    <td className="text-left font-weight-bolder">USSD</td>
                                                    <td className="text-justify">{ providersInfo.ussd }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER } className="subheaders">
                                                    <td colspan="2" className="text-blue py-4"> COMVIVA Provider Information</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Service Code</td>
                                                    <td className="text-justify">{ providersInfo.serviceCode }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Bearer Code</td>
                                                    <td className="text-justify">{ providersInfo.bearerCode }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Language</td>
                                                    <td className="text-justify">{ providersInfo.language }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Currency</td>
                                                    <td className="text-justify">{ providersInfo.currency }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.COMVIVA_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Service Code</td>
                                                    <td className="text-justify">{ providersInfo.serviceCode }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER } className="subheaders">
                                                    <td colspan="2" className="text-blue py-4"> MTN Provider Information</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Client Id</td>
                                                    <td className="text-justify">{ providersInfo.clientId }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Client User Id</td>
                                                    <td className="text-justify">{ providersInfo.clientUserId }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Initiator Principal Id</td>
                                                    <td className="text-justify">{ providersInfo.initiatorPrincipalId }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Channel</td>
                                                    <td className="text-justify">{ providersInfo.channel }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> Client Reference </td>
                                                    <td className="text-justify">{ providersInfo.clientReference }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> password </td>
                                                    <td className="text-justify">{ providersInfo.password }</td>
                                                </tr>
                                                <tr hidden={ providersInfo.providerId.id !== LOCALES.MTN_PROVIDER }>
                                                    <td className="text-left font-weight-bolder"> clientRequestTimeout </td>
                                                    <td className="text-justify">{ providersInfo.clientRequestTimeout }</td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                </div>


                                <div className="row  d-flex justify-content-left px-4 py-2  ">

                                    <Link to="/providersList">
                                        <button type="submit" className="btn btn-success  px-5 py-2">Back</button>
                                    </Link>
                                </div>
                            </div>

                            <div className='col-2'></div>
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>

            )
        }
    }
}