
import RegionService from '../../../services/common/RegionService';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ReactLoading from "react-loading";

export default class RegionDetail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            regionData: {
                region: "",
                majorCity: "",
                regionZone: "",
                remark: ""
            }
        };
    }

    componentDidMount() {
        this.getRegionById(this.props.location.state.id);
    }
    getRegionById(id) {
        RegionService.getRegionById(id, localStorage.userId)
            .then(response => {
                this.setState({
                    isLoading: true,
                    regionData: response.data
                });
                
            })
            .catch(e => {
                
                this.setState({ isLoading: true });
            });
    }

    render() {
        const { regionData, isLoading } = this.state;

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Region Detail</li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        {/*
                                       <div className="form-title" >Customer Detail</div>
                                       <hr />  
                                    */}
                                        <div className="table-responsive w-50">

                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>

                                                <tr className="subheaders bg-light">
                                                    <td colspan="2" className="text-blue py-4"> Region Information</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Region Name.</td>
                                                    <td className="text-justify">{ regionData.region }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Major City</td>
                                                    <td className="text-justify">{ regionData.majorCity }</td>
                                                </tr>
                                                <tr >
                                                    <td className="text-left font-weight-bolder" >Region Zone</td>
                                                    <td className="text-justify">{ regionData.regionZone }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder">Remark </td>
                                                    <td className="text-justify">{ regionData.remark }</td>
                                                </tr>



                                                <tr style={ { textAlign: 'center' } }>
                                                    <Link to="/regionListing" className='pl-5'>
                                                        <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                    </Link>

                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='col-2'></div>




                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>
            )
        }
    }
}