import TopupOrderService from '../../../services/etopup/TopupOrderService';
import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { format } from "date-fns";
import NumberFormat from 'react-number-format';
import Image from "react-image-enlarger";

export default class ViewTopupOrder extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            orderId: this.props.match.params.orderId,
            zoomedAttachment: false,
            topupOrderData: {
                parentId: '',
                dealerId: '',
                amount: '',
                providerId: '',
                createdAt: ''
            }
        };
        this.attachmentZoomOut = this.attachmentZoomOut.bind(this); 
        this.attachmentZoomIn = this.attachmentZoomIn.bind(this);
    }
    attachmentZoomOut() {
        this.setState({ zoomedAttachment: false })
    }
    attachmentZoomIn() {
        this.setState({ zoomedAttachment: true })
    }
    componentDidMount() {
        this.getTopupOrderById(this.props.location.state.orderId);
        
    }

    getTopupOrderById(orderId) {

        TopupOrderService.getTopupOrderById(orderId, localStorage.userId)
            .then(response => {
                this.setState({
                    topupOrderData: response.data
                });
              
            })
            .catch(e => {
                
            });
    }

    render() {
        const { topupOrderData,zoomedIdentity } = this.state;


        //   console.log(resultArray[0]["bankName"]);
        return (
            <div>
                {/* <Header />
                <TopupMenu /> */}
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">

                        <div className="row mb-2">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">eTop Up Order Detail</li>
                                </ol>
                            </div>

                        </div>

                        <div >
                            <div className="card ">
                                <div className="card-body">
                                    {/*
                                       <div className="form-title" >Customer Detail</div>
                                       <hr />  
                                    */}
                                <div className='col sm-12 col md-7 col lg-7'>
                                    <div className="table-responsive w-50">

                                        <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                            {/* <tr >
                                                <td style={{borderRightStyle:'hidden'}}>
                                                <div className='center'> <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '90px', height: '90px',margin: 'auto',alignSelf: 'center' }} src={"data:image/png;base64," +resultArray[resultArray.length - 1]["attachment"]}></img> </div>
                                                </td>
                                                <td style={{borderLeftStyle:'hidden'}}>
                                                    <label>Account No:</label> {resultArray[resultArray.length - 1]["accountNo"]} <hr /> 
                                                    <label>Balance:</label> {resultArray[resultArray.length - 1]["balance"]}
                                                </td>
                                            </tr> */}
                                            <tr className="subheaders bg-light">
                                                <td colspan="2" className="text-blue py-4">eTop Up order Detail</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Parent Name:</td>
                                                <td className="text-justify">{ topupOrderData.parentId.firstName + " " + topupOrderData.parentId.middleName + " " + topupOrderData.parentId.lastName }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Dealer  Name:</td>
                                                <td className="text-justify">{ topupOrderData.dealerId.firstName + " " + topupOrderData.dealerId.middleName + " " + topupOrderData.dealerId.lastName }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Amount:</td>
                                                {/* <td className="text-justify">{ topupOrderData.amount }</td> */ }
                                                <td className="text-justify">{ <NumberFormat value={ topupOrderData.amount+".00" } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> }</td>    
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Provider:</td>
                                                <td className="text-justify">{ topupOrderData.providerId.providerName }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Order Date:</td>
                                                <td className="text-justify">{ topupOrderData.createdAt } </td>

                                            </tr>

                                            <tr style={ { textAlign: 'center' } }>
                                                <Link to="/ordersList" className='pl-5'>
                                                    <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                </Link>

                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            
                                </div>
                            </div>
                        </div>
                        <div className='col-2'></div>
                        {/* /.row */ }
                    </div>
                    {/* /.container-fluid */ }
                </div>
            </div>

        )
    }
}