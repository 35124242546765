import React, { Component } from 'react';
import ProvidersService from '../../../services/common/ProvidersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import TopupMenu from '../../../components/etopup/TopupMenu';
import 'react-notifications/lib/notifications.css';
import Header from '../../common/Header';
import NumberFormat from 'react-number-format';
import ReactLoading from "react-loading";
import TelcomBalanceService from '../../../services/etopup/TelcomBalanceService';

class TelcomBalanceList extends Component {
    constructor (props) {

        super(props)

        this.state = {

            mtnBalanceLoading:false,
            salaamBalanceLoading:false,
            roshanBalanceLoading:false,
            etisalatBalanceLoading:false,
            kydcBalanceLoading:false,

            mtnBalanceObj:[],
            salaamBalanceObj:[],
            roshanBalanceObj:[],
            kydcBalanceObj:[],
            etisalatBalanceObj:[]
        }
    }

     componentDidMount() {

          {/*MTN balance list*/ }
          TelcomBalanceService.getMTNBalance(localStorage.userId)
          .then(response => {
              this.setState({
                mtnBalanceObj: response.data,
                mtnBalanceLoading:true
              });
          })
          .catch(e => {
          });

        {/*Salaam balance list*/ }
        TelcomBalanceService.getSalaamBalance(localStorage.userId)
        .then(response => {
            this.setState({
                salaamBalanceObj: response.data,
                salaamBalanceLoading:true
            });
        })
        .catch(e => {
        });

        {/*Salaam balance list*/ }
        TelcomBalanceService.getKYDCBalance(localStorage.userId)
        .then(response => {
            this.setState({
                kydcBalanceObj: response.data,
                kydcBalanceLoading:true
            });
        })
        .catch(e => {
        });

        {/*Etisalat balance list*/ }
        TelcomBalanceService.getEtisalatBalance(localStorage.userId)
        .then(response => {
            this.setState({
            etisalatBalanceObj: response.data,
            etisalatBalanceLoading:true
            });
        })
        .catch(e => {
        });

        {/*Etisalat balance list*/ }
        TelcomBalanceService.getRoshanBalance(localStorage.userId)
        .then(response => {
            this.setState({
                roshanBalanceObj: response.data,
                roshanBalanceLoading:true
            });
        })
        .catch(e => {
        });


        }

     render() {
        const { mtnBalanceObj, salaamBalanceObj,roshanBalanceObj,kydcBalanceObj,etisalatBalanceObj, isLoading } = this.state;
        const {mtnBalanceLoading,salaamBalanceLoading,roshanBalanceLoading,etisalatBalanceLoading,kydcBalanceLoading }=this.state  
        
            return (

                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" >Telcom Balance </li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Balance Info</div>

                                        <table className=" table table-striped table-birdered" style={ { fontSize: 'smaller' } } >
                                            <thead className=' thead-light'>
                                                <tr>
                                                    <th>Provider Name</th>
                                                    <th>System Balance</th>
                                                    <th>Telcom Balance</th>
                                                    <th>Difference</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '> 

                                        { !mtnBalanceLoading ? 
                                            
                                              <tr>
                                                  <td className='p-0' colSpan={4} ><ReactLoading type="bars" color="#0000FF" height={ 20 } width={ 20 } /></td>
                                              </tr> 
                                               :
                                                <tr>
                                                    <td className='p-0'>{ mtnBalanceObj.providerName}</td>
                                                    <td className='p-0'>
                                                    { 
                                                    <NumberFormat value={ mtnBalanceObj.systemBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                    }</td>
                                                    <td className='p-0'>
                                                         { 
                                                        <NumberFormat value={ mtnBalanceObj.serverBalance } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                         }
                                                    </td>
                                                    <td className='p-0'>
                                                        { 
                                                        <NumberFormat value={ mtnBalanceObj.balanceDifference.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        }
                                                    </td>
                                                </tr>
                                        }

                                        { !salaamBalanceLoading ? 
                                            <tr>
                                                <td className='p-0' colSpan={4}><ReactLoading className='text-center' type="bars" color="#0000FF" height={ 20 } width={ 20 } /></td>
                                            </tr> 
                                             :
                                             <tr>
                                                <td className='p-0'>{ salaamBalanceObj.providerName}</td>
                                                <td className='p-0'>
                                                    {
                                                     <NumberFormat value={ salaamBalanceObj.systemBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                    }
                                                </td>
                                                <td className='p-0'>
                                                    {
                                                     <NumberFormat value={ salaamBalanceObj.serverBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                     }
                                                </td>
                                                <td className='p-0'>
                                                    {
                                                        <NumberFormat value={ salaamBalanceObj.balanceDifference.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                     }
                                                </td>
                                            </tr>
                                        }

                                        { !etisalatBalanceLoading ? 
                                            <tr>
                                                <td className='p-0' colSpan={4}><ReactLoading className='text-center' type="bars" color="#0000FF" height={ 20 } width={ 20 } /></td>
                                            </tr> 
                                             :
                                              <tr>
                                                  <td className='p-0'>{ etisalatBalanceObj.providerName}</td>
                                                  <td className='p-0'>
                                                    { 
                                                    <NumberFormat value={ etisalatBalanceObj.systemBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                    }
                                                  </td>
                                                  <td className='p-0'>
                                                     {
                                                     <NumberFormat value={ etisalatBalanceObj.serverBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                     }
                                                  </td>
                                                  <td className='p-0'>
                                                    { 
                                                    <NumberFormat value={ etisalatBalanceObj.balanceDifference.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                    }
                                                 </td>
                                             </tr>
                                        }

                                        { !roshanBalanceLoading ? 
                                            <tr>
                                                <td className='p-0' colSpan={4}><ReactLoading className='text-center' type="bars" color="#0000FF" height={ 20 } width={ 20 } /></td>
                                            </tr> 
                                             :
                                              <tr>
                                                  <td className='p-0'>{ roshanBalanceObj.providerName}</td>
                                                  <td className='p-0'>
                                                    { 
                                                    <NumberFormat value={ roshanBalanceObj.systemBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                    }
                                                  </td>
                                                  <td className='p-0'>
                                                     {  
                                                     <NumberFormat value={ roshanBalanceObj.serverBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                     }
                                                  </td>
                                                  <td className='p-0'>
                                                      { 
                                                      <NumberFormat value={ roshanBalanceObj.balanceDifference .toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                      }
                                                    </td>
                                             </tr>
                                         }
                                         { !kydcBalanceLoading ? 
                                            <tr>
                                                <td className='p-0' colSpan={4}><ReactLoading className='text-center' type="bars" color="#0000FF" height={ 20 } width={ 20 } /></td>
                                            </tr> 
                                             :
                                              <tr>
                                                  <td className='p-0'>{ kydcBalanceObj.providerName}</td>
                                                  <td className='p-0'>
                                                    {  
                                                    <NumberFormat value={ kydcBalanceObj.systemBalance .toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                    }
                                                  </td>
                                                  <td className='p-0'>
                                                    { 
                                                    <NumberFormat value={ kydcBalanceObj.serverBalance  } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                    }
                                                 </td>
                                                  <td className='p-0'>
                                                     { 
                                                     <NumberFormat value={ kydcBalanceObj.balanceDifference.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />  
                                                     }
                                                  </td>
                                             </tr>
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <NotificationContainer />
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>
                        {/* /.content */ }
                    </div>
                </div>

            )
        
    }
}


export default TelcomBalanceList;