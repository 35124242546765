
import React from "react";
 
const BankInfo = props => {
  return (
    <div >
        {props.content}

    </div>
  );
};
 
export default BankInfo;