
import CustomersService from '../../../services/common/CustomersService'
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ReactLoading from "react-loading";
import Moment from 'react-moment';
import Image from "react-image-enlarger";
import API from '../../../http-common';
import { Buffer } from 'buffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import '../../../assets/css/style.css'

class ViewCustomer extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            identityzIndex:0,
            qrCodezIndex:0,
            zoomedIdentity: false,
            zoomedQRCode: false,
            id: this.props.location.state.id,
            isLoading: false,
            qrCode: null,
            customerDetail:[],
        };
        this.identityZoomOut = this.identityZoomOut.bind(this);
        this.identityZoomIn = this.identityZoomIn.bind(this);
        this.editCustomer = this.editCustomer.bind(this);

        // this.profileZoomOut = this.profileZoomOut.bind(this);
        // this.profileZoomIn = this.identityZoomIn.bind(this);
    }
    Cancel = (e) => {
        this.props.history.push('/customerList');
    }
    componentDidMount() {
        this.getCustomerByCustomerId(this.props.location.state.id);
        API.get("api/v1/generateQRCodeByByte/" + this.props.location.state.mobileNo,
            // `${process.env.REACT_APP_SERVER_URL}` + "files/QRCode.PNG",
            {
                responseType: "arraybuffer",
            }
        ).then(res => {
            this.setState({
                qrCode: Buffer.from(res.data, "binary").toString("base64")
            })

        });
    }



    getCustomerByCustomerId(id) {
        CustomersService.getCustomerByCustomerId(id, localStorage.userId)
            .then(response => {
                this.setState({
                    customerDetail:response.data,
                    isLoading: true,
                });
                
            })
            .catch(e => {
                
            });

    }

    editCustomer(mobileNo) {
        this.props.history.push(`/editCustomer?id = ${mobileNo}`)

    }

    downloadDocument(mobileNo) {
        API.get("api/v1/pdf/customerDetail/" + mobileNo,
            {
                responseType: "blob"
            }
        ).then(response => {

            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });

            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })

    }

    identityZoomOut() {
        this.setState({ zoomedIdentity: false,identityzIndex:0 })
    }
    identityZoomIn() {
        this.setState({ zoomedIdentity: true,identityzIndex:9999 })
    }

    QRCodeZoomOut() {
        this.setState({ zoomedQRCode: false,qrCodezIndex:0})
    }
    QRCodeZoomIn() {
        this.setState({ zoomedQRCode: true ,qrCodezIndex:9999})
    }


    render() {
        const { customerDetail,isLoading,identityzIndex,qrCodezIndex, qrCode, zoomedIdentity, zoomedQRCode } = this.state;
     
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={150} width={150} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className="content-wrapper bg-white">
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Customer Detail Information</li>

                                    </ol>
                                    <div className="float-right">
                                    <Link  
                                     onClick={() => this.downloadDocument(customerDetail.mobileNo)}>
                                    
                                       <FontAwesomeIcon icon={faDownload} title="download as pdf" />
                                        <FontAwesomeIcon icon={faFilePdf} title="download as pdf" />
                                    </Link>
                                    </div>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        <div className='row mb-0'>
                                        { customerDetail.attachment &&
                                            <div className='customer-header card'>
                                               
                                                <img id="logo"
                                                    className="mx-auto d-block rounded-circle"
                                                    style={{ width: '90px', height: '90px', margin: 'auto', alignSelf: 'center' }}
                                                    src={"data:image/png;base64," +
                                                    customerDetail.attachment}>
                                                </img>
                                              </div>
                                            }
                                            <div className='customer-header card'>
                                                <label >Account No</label> 
                                                <h5>{ customerDetail.ledgerAccount} </h5>
                                            </div>
                                            <div className='customer-header card'>
                                                <label>Wallet Balance</label> 
                                                <h5>{ < NumberFormat value={ customerDetail.walletBalance < 0 ? -(customerDetail.walletBalance): customerDetail.walletBalance } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />} </h5>
                                            </div>
                                            <div className='customer-header card'>
                                            {customerDetail.topupBaseBalance>=0 && 
                                                <>
                                                    <label >Topup Bal.</label> 
                                                    <h5> {< NumberFormat value={ customerDetail.topupBaseBalance < 0 ? -(customerDetail.topupBaseBalance.toFixed(2)): customerDetail.topupBaseBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />} </h5>
                                                </>
                                                }
                                            </div>
                                            <div className='customer-header card'>
                                                    {customerDetail.topupBaseBalance>=0 && 
                                                    <>
                                                        <label>Acc Bal.</label> 
                                                        <h5>  {<NumberFormat value={ customerDetail.topupAccumulatedBalance < 0 ? -(customerDetail.topupAccumulatedBalance.toFixed(2)): customerDetail.topupAccumulatedBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />} </h5>
                                                        </>
                                                        }
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col sm-12 col md-7 col lg-7'>
                                                <div className="table-responsive w-100">


                                                    <table className="table table-bordered mb-5" style={{ fontSize: 'smaller' }}>
                                                        <tr className="subheaders bg-light">
                                                            <td colSpan={"2"} className="text-blue py-4"> Basic Information</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder" >Full Name</td>
                                                            <td className="text-justify">{customerDetail.fullName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder" >Mobile No</td>
                                                            <td className="text-justify">{customerDetail.mobileNo}</td>
                                                        </tr>
                                                        <tr >
                                                            <td className="text-left font-weight-bolder" >Type</td>
                                                            <td className="text-justify">{customerDetail.type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Company Name</td>
                                                            <td className="text-justify">{customerDetail.companyName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Old Fin A/C #</td>
                                                            <td className="text-justify">{customerDetail.oldAccountNo}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Birth Date</td>
                                                            <td className="text-justify">{customerDetail.birthDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Place of Birth</td>
                                                            <td className="text-justify">{customerDetail.placeofBirthValue}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Gender</td>
                                                            <td className="text-justify">{customerDetail.gender}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Registerd by </td>
                                                            <td className="text-justify">{customerDetail.registeredBy == undefined ? "sign up" : customerDetail.registeredBy}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Created Date</td>
                                                            <td className="text-justify">{
                                                                customerDetail.createdAt === "null"
                                                                    || customerDetail.createdAt === null
                                                                    || customerDetail.createdAt === undefined ? "" : <Moment format="YYYY/MM/DD hh:mm:ss.ms">{customerDetail.createdAt}</Moment>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Updated Date</td>
                                                            <td className="text-justify">{
                                                                customerDetail.updatedAt === "null"
                                                                    || customerDetail.updatedAt === null ||
                                                                    customerDetail.updatedAt === undefined ? "" : <Moment format="YYYY/MM/DD hh:mm:ss.ms">{customerDetail.updatedAt}</Moment>}</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colSpan="2" className="text-blue py-4 "> Address Information</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Country </td>
                                                            <td className="text-justify">{customerDetail.countryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Province </td>
                                                            <td className="text-justify">{customerDetail.province}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">City </td>
                                                            <td className="text-justify">{customerDetail.city}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Street </td>
                                                            <td className="text-justify">{customerDetail.street}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Email </td>
                                                            <td className="text-justify">{customerDetail.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Website </td>
                                                            <td className="text-justify">{customerDetail.website}</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colSpan="2" className="text-blue py-4 "> Location</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Latitude </td>
                                                            <td className="text-justify">{customerDetail.latitude }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Longitude </td>
                                                            <td className="text-justify">{customerDetail.longitude }</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light" hidden={customerDetail.userName === undefined}>
                                                            <td colSpan="2" className="text-blue py-4 "> Activation Information</td>
                                                        </tr>
                                                        <tr hidden={customerDetail.userName === undefined} >
                                                            <td className="text-left font-weight-bolder">Approved by </td>
                                                            <td className="text-justify">{customerDetail.userName == "null" ? "" : customerDetail.userName}</td>
                                                        </tr>
                                                        <tr hidden={customerDetail.userName === undefined}>
                                                            <td className="text-left font-weight-bolder">Approved Date </td>
                                                            <td className="text-justify">{customerDetail.activatedDate == "null" || customerDetail.activatedDate === undefined || customerDetail.activatedDate === null ? "" : <Moment format="YYYY/MM/DD hh:mm:ss.ms">{customerDetail.activatedDate}</Moment>}</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colSpan="2" className="text-blue py-4 "> Additional Mobile Information</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Mobile No </td>
                                                            <td className="text-justify">Status</td>
                                                        </tr>
                                                        {customerDetail.additionalMobileNo.length > 0 && customerDetail.additionalMobileNo.map(mobileInfo =>
                                                            <tr key={mobileInfo.mobileNo}>
                                                                <td className="text-left "> {mobileInfo.mobileNo}</td>
                                                                <td className="text-right "> {mobileInfo.status}</td>
                                                            </tr>
                                                        )}
                                                        <tr className="subheaders bg-light">
                                                            <td colSpan="2" className="text-blue py-4 "> Bank Information</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Bank Name </td>
                                                            <td className="text-justify">Bank Account</td>
                                                        </tr>
                                                        {customerDetail.customerBankList.length > 0 && customerDetail.customerBankList.map(bankInfo =>
                                                            <tr key={bankInfo.bankAccount}>
                                                                <td className="text-left font-weight-bolder"> {bankInfo.bankName}</td>
                                                                <td className="text-justify">{bankInfo.bankAccount}</td>
                                                            </tr>
                                                        )}

                                                      
                                                        <tr style={{ textAlign: 'center' }} >
                                                            <button type="submit" className="btn btn-success auto mt-4 px-4 py-1" onClick={() => this.Cancel()}>Back</button>
                                                         
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                            <div className='form-group sm-12 col md-2 col lg-2 w-50' id="img-container">

                                                <div className='form-group sm-12 md-2 col div-image-postion ' id="img-container_1"  style={{zIndex: this.state.identityzIndex}}>
                                                    {/* <ReactImageZoom { ...props } /> */}
                                                { customerDetail.identityCard &&    
                                                <Image className='div-img-image-postion'
                                                        style={{ width: "200px", height: "auto"}}
                                                        zoomed={zoomedIdentity}
                                                        src={"data:image/png;base64," + customerDetail.identityCard}
                                                        alt="Identity Card"
                                                        onClick={() => this.identityZoomIn()}
                                                        onRequestClose={() => this.identityZoomOut()}
                                                    />
                                                }
                                                </div >
                                                <br />
                                                <div className='form-group sm-12 md-2 col ' id="img-container_2">
                                                    {/* <QRCode
            title="QRCode"${}
            value={ resultArray[ resultArray.length - 1 ][ "fullName" ] }
            bgColor={'#FFFFFF'}
            fgColor={'#000000'}
            size={256}
          /> */}
                                                    {/* <img id="qrCode"
                                                        className="mx-auto d-block rounded-circle"
                                                        style={{ width: '90px', height: '90px', margin: 'auto', alignSelf: 'rghit' }}
                                                        src={`${process.env.REACT_APP_SERVER_URL}` + "files/QRCode.PNG"}>
                                                    </img> */}
                                                    {/* <img src={qrCode}/> */}
                                                    <div style={{zIndex: this.state.qrCodezIndex}}>
                                                    { qrCode &&
                                                        <Image id='QRCodeId'
                                                            style={{ width: "200px", height: "auto" }}
                                                            zoomed={zoomedQRCode}
                                                            src={"data:image/png;base64," + qrCode}
                                                            // src={`${process.env.REACT_APP_SERVER_URL}` + "files/QRCode.PNG"}
                                                            alt="QRCode"
                                                            onClick={() => this.QRCodeZoomIn()}
                                                            onRequestClose={() => this.QRCodeZoomOut()}
                                                        />
                                                     }

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        {/* <div className="table-responsive w-10">
                                        <p>Pic</p>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-2'></div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                </div>

            )
        }
    }
}

export default ViewCustomer

