import API from '../../http-common';

class CashMethodService {
  // get all Cash Information 
  getCashMethods() {
    return API.get("/api/v1/getCashMethods");
  }

  // create  Cash Information 
  createCashMethod(cashMethod) {
    return API.post("/api/v1/createCashMethod", cashMethod);
  }

  // get single Cash Information 
  getCashMethod(id) {
    return API.get("/api/v1/getCashMethodById/" + id);
  }  

  // remove  Cash Information 
  deleteCashMethod(id) {
    return API.delete('/api/v1/deleteCashMethod' + '/' + id);
  }

  // update  Cash Method Information 
  updateCashMethod(id, cashMethod) {
    return API.put("/api/v1/updateCashMethod" +"/" + id, cashMethod);
  }
  
}


export default new CashMethodService()