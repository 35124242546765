import React, { Component } from 'react'
import TopupMenu from '../../etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import WalletTrxnService from '../../../services/wallet/WalletTrxnService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import WalletBalanceService from '../../../services/wallet/WalletBalanceService';
import LoginService from '../../../services/common/LoginService'; 
import ReactLoading from "react-loading"; 


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class SalesWalletToTopupTransfer extends React.Component {

    constructor () {
        super();
        this.state = {
            customerType: '',
            mainDealerSelectOption: null,
            isLoading: false,
            salesAssignmentList: [],
            customerId: '',
            amount: 0,
            userId: '',
            getWalletByCustomerId: [],
            formErrors: {
                amountError: "",
                mainDealerSelectOptionError: ""
            },
        }
        this.handelInputChange = this.handelInputChange.bind(this);
    }
    Cancel = e => {
        this.props.history.push('/transactionMonitoringList');
    }
    fatchMainDealers = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getMainDealers/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            mainDealersList: data,

                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }else {
            callback([])
          }
    }

    onMainDealerSearchChange = (mainDealerSelectOption) => {
        const { formErrors } = this.state
        if (mainDealerSelectOption) {

            this.state.mainDealerSelectOption = mainDealerSelectOption
            formErrors.mainDealerSelectOptionError = mainDealerSelectOption == "" ? 'Please select the dealer.' : "";
            this.setState({
                customerId: mainDealerSelectOption.value
            });
        }
    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
    
        switch (name) {
            case 'amount':
                formErrors.amountError =
                value.length == 0 || !amountRegEx.test(value) ? "" + "Amount should only be digits and greater than zero." : ""
                break;
                default:
                break;
                    }
        this.setState({
            formErrors, [ name ]: value
        })
    }
    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
      .then(
        (response) => {
          this.setState({
            customerType: response.data.type
          })
        },
        (error) => {
        })
      .catch(e => {
      });


        WalletBalanceService.getWalletByCustomerId(localStorage.customerIdentity, localStorage.userId)
            .then(response => {

                this.setState({
                    getWalletByCustomerId: response.data
                });
                
            })
            .catch(e => {
                
            });
    }


    handleSubmit = e => {
        e.preventDefault();
        const { amount,mainDealerSelectOption, formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined || amount == 0? 'Amount is Required' : this.state.formErrors.amountError;
       
        if(localStorage.type==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`)
        {
        formErrors.mainDealerSelectOptionError = mainDealerSelectOption === '' || mainDealerSelectOption === null || mainDealerSelectOption === undefined ? 'Please select main dealer' : this.state.formErrors.mainDealerSelectOptionError;
        }
       
        
        if (formValid(this.state.formErrors)) {
            if(localStorage.type==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`)
            {
                this.state.customerId=this.state.mainDealerSelectOption.value
            }else{
                this.state.customerId = localStorage.customerIdentity
            }
            this.setState({ isLoading: true})
            WalletTrxnService.SalesWalletToTopupTransfer(this.state.customerId, this.state.amount, localStorage.userId)
                .then(response => {
                    NotificationManager.success("Successfully transfered !!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({ isLoading: false})
                    this.props.history.push('/transactionMonitoringList');
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                //    this.setState({ isLoading: false})
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };


    render() {
        const {  formErrors } = this.state

        return (

            <div>
                {/* <Header />
                <TopupMenu />
                <NotificationContainer /> */}

                <div className="content-wrapper bg-white">
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">

                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" >Recharge Sales Topup  </li>

                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    {/* <h6 class="title " >Customer Type </h6> */ }
                                                </div>
                                                <div className="card-body">
                                                { this.state.customerType== `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` &&  
                                                    <div className='row'>
                                                      
                                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 border p-2">
                                                            <label htmlFor='salesTeamAssignment'>Select Sales Man:</label>
                                                            <AsyncSelect id='mainDealersList'
                                                                required
                                                                placeholder={ "Search  by name or mobile" }
                                                                value={ this.state.mainDealerSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchMainDealers }
                                                                isLoading={ this.state.mainDealerSelectOption === {} &&
                                                                    this.state.mainDealerSelectOption === undefined &&
                                                                    this.state.mainDealerSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onMainDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.mainDealerSelectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.mainDealerSelectOptionError }</span>
                                                            ) }
                                                          </div>
                                                        </div>
                                                        }
                                                        <div className='row'>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Amount :</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                                
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(SalesWalletToTopupTransfer);
