import API from '../../http-common';

class RefundWalletService {
    refundWallet(userId,refundData) {
        return API.put('/api/v1/refundWalletBalance/'+userId,refundData);
    }

    getMonthlyWalletToWalletTransfer(customerId, userId) {
        return API.get('/api/v1/getMonthlyWalletToWalletTransfer/' + customerId +"/" + userId);
    }
    
    getAllRefundedWalletTransactions(pageNo,pageSize, userId) {
        return API.get('/api/v1/getAllRefundedWalletTransactions/'+ pageNo +'/'+pageSize +'/'+userId);
    }
}
export default new RefundWalletService()