import API from '../../http-common';

class TelcomBalanceService {


    getMTNBalance(userId) {
        return API.get('/api/v1/getMTNBalance/' + userId);
    }

    getSalaamBalance(userId) {
        return API.get('/api/v1/getSalaamBalance/' + userId);
    }

    getKYDCBalance(userId) {
        return API.get('/api/v1/getKYDCBalance/' + userId);
    }

    getRoshanBalance(userId) {
        return API.get('/api/v1/getRoshanBalance/' + userId);
    }

    getEtisalatBalance(userId) {
        return API.get('/api/v1/getEtisalatBalance/' + userId);
    }

    getTelcomBalance(userId) {
        return API.get('/api/v1/getTelcomBalance/' + userId);
    }
}
export default new TelcomBalanceService()