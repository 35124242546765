import React, { Component } from 'react'
import Menu from '../../../components/wallet/Menu';
import CustomersService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import PartnerTargetService from '../../../services/etopup/PartnerTargetService';
import ProviderService from '../../../services/etopup/ProviderService';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import LoadingPopup from '../../wallet/LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/); 

class EditPartnerTarget extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            partnerTargetId: this.props.match.params.partnerTargetId,
            isLoading: false,
            isLoadingPop: false,
            month: new Date(),
            partnerTargetData: {
                id: '',
                providerId: '',
                target: ''
            },
            providersList: [],
            formErrors: {
                targetError: "",
                providerIdError:""
            },
        };
        this.onChangeProviderId = this.onChangeProviderId.bind(this);
        this.onChangeTarget = this.onChangeTarget.bind(this);
        //   this.onChangeMonth = this.onChangeMonth.bind(this);
    }

    handleChange(value) {
        this.setState({ month: value });
    }
    onChangeProviderId(e) {
        const providerId = e.target.value;

        this.setState(function (prevState) {
            return {
                partnerTargetData: {
                    ...prevState.partnerTargetData,
                    providerId: providerId
                }
            };
        });
    }
    onChangeTarget(e) {
        const target = e.target.value;

        this.setState(function (prevState) {
            return {
                partnerTargetData: {
                    ...prevState.partnerTargetData,
                    target: target
                }
            };
        });
    }
    onChangeMonth(e) {
        const month = e.target.value;

        this.setState(function (prevState) {
            return {
                partnerTargetData: {
                    ...prevState.partnerTargetData,
                    month: month
                }
            };
        });
    }



    Cancel = (e) => {
        this.props.history.push('/partnerTargetList');
    }

    componentDidMount() {

        this.getPartnerTargetById(this.props.location.state.partnerTargetId);

        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }
    getPartnerTargetById(partnerTargetId) {
        PartnerTargetService.getPartnerTargetById(partnerTargetId, localStorage.userId)
            .then(response => {
                this.setState({
                    //  partnerTargetData: response.data
                    partnerTargetData: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {
                
                this.setState({ isLoading: true })
            });
    }
    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target
    
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'target':
                formErrors.targetError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;

            default:
                break;

        }
 
  
    }
    handleSubmit = e => {
        e.preventDefault();
        var data = new FormData();
        const { target, providerId, formErrors } = this.state
        formErrors.providerIdError = this.state.providerId === "" || this.state.providerId === null || this.state.providerId === undefined ? "Please select provider" : "";
       
        if(this.state.target === "" || this.state.target === null || this.state.target === undefined)
        {
            this.state.target=0
        }
        data.append("id", this.props.location.state.partnerTargetId);
        data.append("target", this.state.partnerTargetData.target);
        data.append("providerId", this.state.partnerTargetData.providerId.id);
        data.append("month", moment(this.state.month).format('yyyy-MM-DD'));
        data.append("userId", localStorage.userId);


        this.setState({ isLoadingPop: true})  
        PartnerTargetService.updatePartnerTarget(data)
            .then(response => {
                NotificationManager.success('Updated Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoadingPop: false})  
                this.props.history.push('/partnerTargetList');
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
               // this.setState({ isLoadingPop: false})  
            });
    }


    render() {
        const { partnerTargetData, isLoading,formErrors, providersList } = this.state;
     
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">

                                                            <label htmlFor='Country'>Provider Name:</label>


                                                            <select value={ partnerTargetData.providerId.id } onChange={ this.onChangeProviderId } className="form-control" required="required">
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                            { formErrors.providerIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='FullName'>Target:</label>
                                                            <input type="text" className="form-control " placeholder="Target" value={ partnerTargetData.target } 
                                                           
                                                            onChange={ this.onChangeTarget }  />
                                                            { formErrors.targetError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.targetError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='date'>Month:</label>
                                                            {/* <input type="date" className="form-control " placeholder="month" value={ partnerTargetData.month } onChange={ this.onChangeMonth } required="required" /> */ }
                                                            <DatePicker
                                                                shouldCloseOnSelect={ true }
                                                                onChange={ (event) => this.handleChange(event) }
                                                                selected={ this.state.month }
                                                                dateFormat="yyyy-MM"
                                                                showMonthYearPicker
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>
                                {this.state.isLoadingPop && <LoadingPopup
                                    content={<>
                                            <form>
                                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                </form>
                                    </>}
                                />}

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditPartnerTarget);