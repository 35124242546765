import API from '../../http-common';

class CommissionService {


    getAllCommisions(pageNo, pageSize, userId, customerId) {
        return API.get('/api/v1/getAllCommissionByCustomerId/' + pageNo + "/" + pageSize + "/" + userId + "/" + customerId);
    }
    getCommisions(userId) {
        return API.get('/api/v1/getCommissions/' + userId);   
    }

    getCommisionsByCustomerId(id, userId) {
        return API.get('/api/v1/getCommissionsByCustomerId/' + id + "/" + userId);
    }

    getCommissionModelByCustomerId(id, userId) {
        return API.get('/api/v1/getCommissionModelByCustomerId/' + id + "/" + userId);
    }

    

    getCommissionById(id, userId) {
        return API.get('/api/v1/getCommissionById/' + id + "/" + userId);
    }

    getCommissionModelById(id, userId) {
        return API.get('/api/v1/getCommissionModelById/' + id + "/" + userId);
    }

    

    createCommission(commissions) {
        return API.post('/api/v1/createCommision', commissions);
    }
    updateCommission(userId, commissionDate) {
        return API.put('/api/v1/updateCommission/' + userId, commissionDate);
    }

}

export default new CommissionService()