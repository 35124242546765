import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt, faRoleCircle, faRole, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../common/Constant'
import PhoneInput from 'react-phone-input-2';
import LoginService from '../../../../services/common/LoginService';
import Popup from '../../../wallet/Popup';
import Header from '../../Header';
//import TopupMenu from '../../../etopup/TopupMenu';
import Menu from '../../../wallet/Menu';
import Dropzone from 'react-dropzone';
import TopupMenu from '../../../etopup/TopupMenu';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_]{3,20}$/u)

class RolesList extends Component {

  constructor (props) {

    super(props)

    this.state = {
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      id: '',
      name: '',
      rolees:[],
      isOpen:0,
      onEdit:'',
      roles:null,
      counter:1,
      formErrors: {
        roleNameError: ""
      },
    }


  }

 

  seachRolefoBasedOnFilter() {
    this.state.rolees = null;
    LoginService.getRoles()
      .then(response => response.data)
      .then((data) => {
        this.setState({
            rolees: data,
          isOpen: false
        });
      });
  }


  createRole = e => {
    e.preventDefault();
    const { name, formErrors } = this.state
    formErrors.roleNameError = name == '' || name == null ? " Role Name is Required" : this.state.formErrors.roleNameError;
  

    if (formValid(this.state.formErrors)) {
        LoginService.createRole(name)
          .then(
            (res) => {
              NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            },
            (err) => {
              NotificationManager.error(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            })
          .catch(function (response) {
            NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          });
   
    }
    this.setState({
      formErrors
    })
  };

  componentDidMount() {
    this.seachRolefoBasedOnFilter()
  }




  togglePopup = (id) => {
    this.state.name = ''
    this.state.roles = null
    this.state.id = ''
    this.state.onEdit = false
    this.state.btnValue = "Save"
    if (id != null || id != undefined) {
      this.state.btnValue = "update"
      this.state.onEdit = true
      LoginService.getRoleInfo(id)
        .then(response => response.data)
        .then((data) => {
          this.setState({
            roles: data
          });
        });


    }
    if (this.state.id == '' || this.state.id == null || this.state.id == undefined) {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }

  handelChange = e => {
    e.preventDefault();
    const { name, value } = e.target

    let formErrors = this.state.formErrors;

    switch (name) {
      case 'name':
        formErrors.firstNameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphanumeric and (_) characters with max 20" : ""
        break;
    
      default:
        break;
    }


    this.setState({
      formErrors, [ name ]: value
    })
  }

  

  


  render() {
    const { rolees, onEdit, roles, formErrors } = this.state;

    this.state.counter =  1

    if (onEdit && roles != null && roles != undefined && roles != '') {

      this.state.id = roles.id
      this.state.name = roles.name

      this.state.onEdit = false
    }

    return (
      <div>
        {/* <Header />
        <Menu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" >Role List</li>
                  </ol>
                </div>

              </div>
              <div className="card" >
                <div className="card-body">
                  <div className="form-title" >Display Role Information</div>

                  <button title={ "New Role" } type={ "button" }
                      style={ { marginLeft: "2px" } }
                      onClick={ () => this.togglePopup(null) }
                      className="btn btn-sm btn-success primary m-1  float-left">
                      <i className="fas fa-plus" /> Roles 
                    </button>
                  <table className=" table table-striped table-birdered" style={ { fontSize: 'smaller' } } >
                    <thead className=' thead-light'>
                      <tr style={ { marginTop: 0 } }>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='content-wrapper '>

                      { rolees.map(role =>

                        <tr key={ role.id }>
                          <td >{ this.state.counter++ }</td>
                          <td >{ role.name }</td>                        
                          <td >                          
                           
                            <button title={ "Edit" }
                              style={ { marginLeft: "2px" } }
                              onClick={ () => this.togglePopup(role.id) }
                              className="btn btn-warning btn-sm action-buttons">
                              <i className="nav-icon fas fa-edit fa-sm" />
                            </button>
                          </td>
                        </tr>


                      ) }
                    </tbody>
                  </table>
                
                  { this.state.isOpen && <Popup
                    content={ <>

                      <form className='' onSubmit={ this.createRole } noValidate>
                        <div className="card">
                          <div className="card-body" >                           
                          <div className=" form-group col-12 col-sm-6 col-md-6 col-lg-12">
                                <label htmlFor='name'>Name:</label>
                                <input type="text" className="form-control " id='name'
                                  name='name' value={ this.state.name }
                                  onChange={ this.handelChange }
                                  onBlur={ this.handelChange }
                                />
                                { formErrors.roleNameError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.roleNameError }</span>
                                ) }
                              </div>
                              <div className="row  d-flex justify-content-left ">
                                <button type="submit" style={ { marginLeft: "20px" } }
                                  className="btn btn-success px-4 py-1">{ this.state.btnValue }</button>
                                <button type="submit" onClick={ () => this.togglePopup() }
                                  className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                              </div>

                          </div>
                        </div>

                      </form>

                    </> }
                  /> }
                </div>
                <NotificationContainer />
              </div>
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>


          {/* /.content */ }
        </div>
      </div>

    )
  }
}
export default RolesList