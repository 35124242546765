import React, { Component } from 'react';
import CashInfoService from '../../../services/etopup/CashInfoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import Menu from '../../wallet/Menu';
import Popup from '../../wallet/Popup';
import AsyncSelect from 'react-select/async'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import PhoneInput from 'react-phone-input-2';
import Header from '../../common/Header';
import API from '../../../http-common';
import ReactLoading from "react-loading";
import LoginService from '../../../services/common/LoginService';
import NumberFormat from 'react-number-format';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u)
const accountNoRegEx = RegExp(/^(?=[1-9])([0-9])[\p{L}\p{N}_@,.&$%#\s-]{0,50}$/u)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);


class CashOutList extends Component {

  constructor(props) {

    super(props)

    this.state = {
      isLoading: false,
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      cashTypeId: LOCALES.CASH_OUT_TYPE,
      isProcessed: true,
      isRejected: false,
      isCompany: false,
      cashOutList: [
        {
          customerId: [],
          senderBankId: [],
          receiverBankId: []
        }
      ],
      cashStatus: null,
      cashStatusFilter: null,
      counter: 1,
      id: 0,
      mobileNo: null,
      dateFrom: null,
      dateTo: null,

      verificationStatus: 0,
      statusDescription: '',

      clear: 0,
      approver: false,
      verify: false,
      isExpand: true,
      setIsOpen: false,
      isOpen: false,
      statusValue: "Verify",
      searchValue: null,
      customerType: '',      
      formErrors: {
        phoneNumberError: "",
        accountNoError: "",
        fullNameError: "",
        dateToError: "",
        dateFromError: "",
        customerError: ""
      },
    }
    this.editCashOut = this.editCashOut.bind(this);
    this.cashOutDetail = this.cashOutDetail.bind(this);
    this.addCashOut = this.addCashOut.bind(this);

  }


  gotoEnteredPage = (pageNumber) => {
    this.state.pageNo = pageNumber
    this.seachCashOutfoBasedOnFilter()
    this.render()

  }

  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    this.seachCashOutfoBasedOnFilter()
    this.render()
  }

  gotoPageFirst = () => {
    this.state.pageNo = 1
    this.seachCashOutfoBasedOnFilter()
    this.render()
  }

  privPage = () => {

    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      this.seachCashOutfoBasedOnFilter()
      this.render()

    }
  }

  nextPage = () => {
    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      this.seachCashOutfoBasedOnFilter()
      this.render()
    }
  }

  addCashOut() {
    this.props.history.push(`/addCashOut`);
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (searchValue) => {
    const { formErrors } = this.state

    formErrors.customerError =
      customerRegEx.test(searchValue) ? " Please insert customer name or account no or mobile number" : ""

    if (searchValue) {
      this.setState({
        searchValue,
        formErrors
      });
    }
  }

  filterCashOutInfo(e) {
    debugger
    const { formErrors, dateFrom, dateTo } = this.state
    formErrors.dateFromError = ''

    if (this.state.fromDate != null && this.state.toDate != null) {
      if (this.state.fromDate > this.state.toDate) {
        formErrors.dateFromError = 'Date from should be lessthan or eual to date to'
      }
    }


    if (formValid(this.state.formErrors)) {
      this.state.clear = 0;
      this.seachCashOutfoBasedOnFilter()

    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  }

  clearValue() {
    this.state.cashStatusFilter = null
    this.state.searchValue = null
    this.state.mobileNo = null
    this.state.accountNo = null
    this.state.dateFrom = null
    this.state.dateTo = null
    this.state.clear = 1
  }

  seachCashOutfoBasedOnFilter() {
    let customerId =  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? this.state.searchValue && this.state.searchValue.value : localStorage.customerIdentity

    this.cashOutList = null;
    let FilterCashOutfo = {
      customerId: customerId,
      status: this.state.cashStatusFilter,
      mobileNo: this.state.mobileNo,
      cashTypeId: this.state.cashTypeId,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      userId: localStorage.userId
    }

    CashInfoService.getFilteredCashOuts(
      this.state.cashTypeId, this.state.pageNo, this.state.pageSize, FilterCashOutfo)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          cashOutList: data.cashInfoClone,
          isOpen: false,
          isLoading: true,
          totalPage: data.totalPages,
          totalelement: data.totalelement,
          pageNo: data.pageNumber + 1
        });
      });
  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.seachCashOutfoBasedOnFilter()
    }
  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }

  cashOutDetail(id, mobileNo) {
    this.props.history.push({pathname: "/viewCashOut", state: {id: id, mobileNo: mobileNo}});
    
  }

  verify(id, cashStatus) {
    this.props.history.push(`/workFlow/${id}/${cashStatus}`);
  }

  approve(id, cashStatus) {
    this.props.history.push(`/workFlow/${id}/${cashStatus}`);
  }

  editCashOut(id, customerId) {
    this.props.history.push({pathname: "/editCashOut", state: {id: id, customerId: customerId}});
  }



  componentDidMount() {
    const reloadCount = localStorage.getItem('reloadCount');
    if(reloadCount == "firstTime") {
      localStorage.removeItem('reloadCount');
      window.location.reload();
    }  
    this.seachCashOutfoBasedOnFilter()
    LoginService.getUserInfo(localStorage.userId)
      .then(response => {
        this.setState({
          customerType: response.data.type
        });
     
      })
      .catch(e => {
        
      });

      this.setState({
        isCompany:  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
      });

  }

  searchCashCashOutfos(cashTypeId, pageNo, pageSize) {
    CashInfoService.searchCashCashOuts(
      cashTypeId, pageNo, pageSize)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          cashOutList: data.content,
          totalPage: data.totalPages,
          totalelement: data.pageable.totalelement,
          pageNo: data.pageable.pageNumber + 1
        });
      });
  }

  async deleteCashOut(id) {
    if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
      try {
        const response = await CashInfoService.deleteCashOut(id, localStorage.userId);
        this.seachCashOutfoBasedOnFilter()
        NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      }
    }

  }

  togglePopup = (id, cashStatus) => {
    this.state.id = id
    this.state.cashStatus = cashStatus
    this.state.statusValue = "Approved"
    if (this.state.cashStatus == LOCALES.CASH_STATUS_REGISTERED) {
      this.state.statusValue = "Verify"
    }

    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handelChange = e => {
    e.preventDefault();
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    if (this.state.clear == 1) {
      e.target.reset();
      this.state.clear = 0
      this.seachCashOutfoBasedOnFilter()
    }

    switch (name) {
      case 'accountNo':
        formErrors.accountNoError =
          !accountNoRegEx.test(value) ? name + " sholud be digites with a min 9 & max 50" : ""
        break;
      case 'fullName':
        formErrors.fullNameError =
          !nameRegEx.test(value) ? name + " value sholud only alphabetic and (/_) characters with min 3 and max 50" : ""
        break;
      default:
        break;
    }

    this.setState({
      formErrors, [name]: value
    })
  }

  process() {
    if (this.state.statusValue == "Verify") {

      if (this.state.isProcessed) {
        this.state.cashStatus = LOCALES.SMARTPAY_CASH_VERIFIED
      }
      if (this.state.isRejected) {
        this.state.cashStatus = LOCALES.SMARTPAY_CASH_VERIFIEDREJECT
      }
    } else {
      if (this.state.isProcessed) {
        this.state.cashStatus = LOCALES.SMARTPAY_CASH_APPROVED
      }
      if (this.state.isRejected) {
        this.state.cashStatus = LOCALES.SMARTPAY_CASH_APPROVEREJECT
      }
    }

    let cashOutData = {
      senderMobileNo: localStorage.customerIdentity,
      cashStatus: this.state.cashStatus,
      verifyDescription: this.state.statusDescription,
      approveDescription: this.state.statusDescription,
      cashTypeId: this.state.cashTypeId,
      userId: localStorage.userId,
      verifyBy: localStorage.userId,
      approveBy: localStorage.userId
    }

    CashInfoService.processCashOut(this.state.id, cashOutData)
      .then(
        (res) => {
          NotificationManager.success(`${process.env.REACT_APP_PROCESS_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
          this.setState({
            isOpen: false,
            isLoading: false
          })
          this.seachCashOutfoBasedOnFilter()
        },
        (err) => {
          NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        })
      .catch(function (response) {
        NotificationManager.error(`${process.env.REACT_APP_PROCESS_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      });

  }

  onChangeReceiverMobileNo = mobile => {
    const { formErrors } = this.state
    formErrors.phoneNumberError =
      !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites " : ""
    this.setState({
      mobileNo: mobile,
      formErrors
    })
  }

  actionType(e) {
    let selectedactionType = e.target.value
    this.state.cashStatusFilter = null
    if (selectedactionType == "----please select---")
      selectedactionType = ""

    this.setState({
      cashStatusFilter: selectedactionType
    });
  }

  onChangeSuper() {
    this.setState({
      isRejected: this.state.isProcessed,
      isProcessed: !this.state.isProcessed,
    })
  }

  onChangeAdmin() {
    this.setState({
      isProcessed: this.state.isRejected,
      isRejected: !this.state.isRejected,
    })
  }

  actionPageSize(e) {
    this.state.pageSize = e.target.value;
    this.setState({
      isLoading: false,
      pageSize: e.target.value
    });

    this.seachCashOutfoBasedOnFilter();
  }

  async download(id, contentType) {
    const response = await CashInfoService.downloadCashInfo(id, contentType)
  }


  render() {
    const { cashOutList, pageNo, customerType, isLoading, totalPage, pageSize, formErrors, isCompany } = this.state;
    pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1

    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >Cash List</li>
                    </ol>
                  </div>

                </div>
                <div className="card" style={{ fontSize: 'smaller' }}>
                  <div className="card-body">
                    <div className="form-title" >Display Cash Out Information</div>

                    <form onSubmit={this.handelChange} hidden={this.state.isExpand} noValidate>
                      <div className="row" hidden={this.state.isExpand}>
                        {isCompany ?
                        <div className=" form-group col-5 col-sm-5 col-md-5 col-lg-5  ">
                          <label htmlFor='CustomerList'>Customer:</label>
                          <div >
                            <AsyncSelect id='CustomerList'
                              placeholder={"Search by name, account no or mobile number"}
                              value={this.state.searchValue}
                              isClearable={true}
                              loadOptions={this.fatchData}
                              isLoading={this.state.searchValue === {} &&
                                this.state.searchValue === undefined &&
                                this.state.searchValue === null ? true : false
                              }

                              onChange={(e) => {
                                this.onSearchChange(e)
                              }}
                              defaultOptions={false}
                            />
                            {formErrors.customerError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.customerError}</span>
                            )}
                          </div>
                        </div>
                        :
                        ''
                      }

                        <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3">
                          <label htmlFor='User Type'>Cash Status:</label>
                          <select value={this.state.cashStatusFilter}
                            onChange={e => this.actionType(e)} className="form-control" name='type'>
                            {/* {actionTypes.map(type =>
                                                        <option value={type.id}>{type.type}</option>
                                                    )} */}
                            <option key={null} value={null}>----please select---</option>
                            <option key={"0"} value={"0"}>Registered</option>
                            <option key={"1"} value={"1"}>Verified</option>
                            <option key={"2"} value={"2"}>Verified Rejected</option>
                            <option key={"3"} value={"3"}>Approved</option>
                            <option key={"4"} value={"4"}>Approved Rejected</option>
                          </select>
                        </div>
                        <div className="col-md-3">
                          <label htmlFor='dateFrom' >Date From</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateFrom'
                              onChange={e => this.state.dateFrom = e.target.value}
                              placeholder="Date" />
                            {formErrors.dateFromError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                            )}
                          </div>

                          <label>Date To</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateFrom'
                              onChange={e => this.state.dateTo = e.target.value}
                              placeholder="Date" />
                            {formErrors.dateToError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                            )}
                          </div>
                        </div>
                      </div>


                      <br />

                      <div>
                        <button title={'Search'}
                          className="btn btn-sm btn-success primary m-1 float-right"
                          onClick={() => this.filterCashOutInfo()}>Search</button>
                        <button type='submit' onClick={this.clearValue.bind(this)} title={'Clear'}
                          className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                      </div>
                    </form>
                    <div style={{ clear: 'both' }}></div>
                    <br />


                    <button type="button" title={"Add New Information"}
                      className="btn btn-sm btn-success primary m-1  float-left"
                      onClick={() => this.addCashOut()}>
                      <i className="fas fa-plus" /> Add
                    </button>
                    <button type='button' title={"Show/Hide Filter"}
                      className="btn btn-sm btn-success primary m-1 float-left"
                      onClick={() => this.filterToggle()}>
                      <FontAwesomeIcon icon={faFilter} />
                      Filter
                    </button>
                    <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                      <label htmlFor='Size'>Row #:</label>
                      <select value={pageSize}
                        onChange={e => this.actionPageSize(e)} name='Size'>                             
                        <option key={"20"} value={20}>20</option>
                        <option key={"50"} value={50}>50</option>
                        <option key={"500"} value={500}>500</option>
                        <option key={"1000"} value={1000}>1000</option>
                        <option key={"10000"} value={10000}>10000</option>
                      </select>
                    </div>
                      <div style={{ clear: 'both' }}></div>
                      <div className='tableContainer'>
                    <table className=" table table-striped table-birdered "  >
                      <thead className=' thead-light'>
                        <tr key={0} style={{ marginTop: 0 }}>
                          <th>No.</th>
                          <th>Full Name</th>
                          <th>Account No</th>
                          <th>Mobile Number</th>                        
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className='content-wrapper '>

                        {cashOutList && cashOutList.map(cashOut =>

                          <tr key={cashOut.id}>
                            <td>{this.state.counter++}</td>
                            <td>{cashOut.receiverFullName}</td>
                            <td>{cashOut.accountCode}</td>
                            <td>{cashOut.receiverMobileNo}</td>                          
                            <td><NumberFormat value={parseFloat(cashOut.amount).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></td>
                            <td>

                              {cashOut.cashStatus === LOCALES.CASH_STATUS_REGISTERED ? "Pending" : ""}
                              {cashOut.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED ? "Verified" : ""}
                              {cashOut.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ? "Rejected during Verify" : ""}
                              {cashOut.cashStatus === LOCALES.SMARTPAY_CASH_APPROVED ? "Approved" : ""}
                              {cashOut.cashStatus === LOCALES.SMARTPAY_CASH_APPROVEREJECT ? "Rejected during Approve" : ""}

                            </td>
                            <td>
                              <button onClick={() => this.cashOutDetail(cashOut.id, cashOut.receiverMobileNo)} title={"Detail"} className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                              <button 
                                 disabled={cashOut.attachment === null} style={{ marginLeft: "2px" }}
                                onClick={() => this.download(cashOut.id, cashOut.contentType)}
                                className="btn btn-success btn-sm action-buttons"
                                title={cashOut.attachment === null ? "No Attachment" : "Download"} ><i className="nav-icon fa fa-download fa-sm" /> </button>

                              <button hidden={customerType != `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && cashOut.customerId.id == localStorage.customerIdentity}
                              disabled={cashOut.cashStatus !== LOCALES.CASH_STATUS_REGISTERED} title={"Verify"}
                                style={{ marginLeft: "2px" }}
                                onClick={() => this.togglePopup(cashOut.id, cashOut.cashStatus)} className="btn btn-success btn-sm action-buttons">
                                <FontAwesomeIcon icon={faMoneyCheck} />
                              </button>

                              <button hidden={customerType != `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && cashOut.customerId.id == localStorage.customerIdentity}
                                disabled=
                                { 
                                  cashOut.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                  cashOut.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                  cashOut.cashStatus === LOCALES.SMARTPAY_CASH_APPROVED ||
                                  cashOut.cashStatus === LOCALES.SMARTPAY_CASH_APPROVEREJECT
                                } title={"Approve"}
                                style={{ marginLeft: "2px" }}
                                onClick={() => this.togglePopup(cashOut.id, cashOut.cashStatus)} className="btn btn-success btn-sm action-buttons">
                                <FontAwesomeIcon icon={faCheckDouble} />
                              </button>

                              <button disabled={cashOut.cashStatus !== LOCALES.CASH_STATUS_REGISTERED} style={{ marginLeft: "2px" }}
                                onClick={() => this.editCashOut(cashOut.id, cashOut.customerId, cashOut.cashMethodId)} title={"Edit"} className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>

                              <button disabled={cashOut.cashStatus !== LOCALES.CASH_STATUS_REGISTERED} style={{ marginLeft: "2px" }}
                                onClick={() => this.deleteCashOut(cashOut.id)} className="btn btn-danger btn-sm action-buttons" title={"Delete"} ><i className="nav-icon fa fa-trash fa-sm" /> </button>

                            </td>
                          </tr>

                        )}
                      </tbody>
                    </table>
</div>
                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{''}
                        <strong>
                          {pageNo} of {totalPage}
                        </strong>
                        {''}
                      </span>
                      <span>
                        | Go To Page : {''}
                        <input type='number' defaultValue={pageNo}
                          onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          }} />
                      </span>
                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackward} />
                      </button>

                      <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " disabled='true' value={pageNo} />

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                        <FontAwesomeIcon icon={faForward} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                        <FontAwesomeIcon icon={faForwardFast} />
                      </button>
                    </div>
                    {this.state.isOpen && <Popup
                      content={<>
                        <div className="card">
                          <div className="card-body">
                            <div className="row" >
                              <label style={{ marginLeft: "20px" }} htmlFor='status'>{this.state.statusValue}:  </label>
                              <input type="Checkbox" style={{ marginLeft: "10px" }} id="status"
                                checked={this.state.isProcessed}
                                onChange={e => { this.onChangeSuper(); this.state.isProcessed = e.target.checked }}
                              />

                              <label style={{ marginLeft: "20px" }}  htmlFor='Reject'>Reject: </label>
                              <input type="Checkbox" style={{ marginLeft: "10px" }} id="Reject"
                                checked={this.state.isRejected}
                                onChange={e => { this.onChangeAdmin(); this.state.isRejected = e.target.checked }}
                              />
                            </div>
                            <div className="row" >
                              <div className="form-group col-8 col-sm-6 col-md-6 col-lg-12">
                                <label htmlFor='Description'>Description:</label>
                                <textarea type="" className="form-control" id='Description'
                                  onChange={e => this.state.statusDescription = e.target.value}
                                  placeholder="Description" />
                              </div>
                            </div>
                            <div className="row  d-flex justify-content-left ">
                              <button type="submit" style={{ marginLeft: "20px" }}
                                onClick={() => this.process()}
                                className="btn btn-success px-4 py-1">Submit</button>
                              <button type="submit" onClick={() => this.togglePopup()}
                                className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </>}
                    />}
                  </div>
                  <NotificationContainer />
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            {/* /.content */}
          </div>


        </div>



      )
    }
  }

}
export default CashOutList