import React, { Component } from 'react'
import { Link } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import IntegrationTypeService from '../../../services/etopup/IntegrationTypeService';
import ReactLoading from "react-loading";

class IntegrationTypeList extends Component {

    constructor (props) {
        super(props)
        this.state = {
            integrationTypeList: [], 
            counter: 1,
            isLoading:false
        }
    }
   
    componentDidMount() {
        IntegrationTypeService.getAllIntegrationTypes(localStorage.userId)
            .then(response => {
                this.setState({
                    integrationTypeList: response.data,
                    isLoading:true
                });
            })
            .catch(e => {                
            });
    }

    render() {
        const { isLoading,integrationTypeList } = this.state;
        
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    {/* orderList.length > 0 && */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Integration Types</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Integration Type List </div>
                                     
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/addIntegrationType' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" >
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>

                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Type</th>
                                                    <th>Remark</th>
                                                    <th>Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { integrationTypeList !== undefined && integrationTypeList.map(list =>

                                                    <tr className='table-tr'>
                                                        <td className='p-0'>{ list.type}</td>
                                                        <td className='p-0 '>{ list.remark }</td>
                                                        <td className='p-0 '>{list.createdAt}</td> 

                                                        <td className='p-0'>
                                                            <Link to={ { pathname: '/#', state: { typeId: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" title="View order" /> </button>
                                                            </Link>
                                                            
                                                            
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                 
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>

                </div>

            )
        }
    }
}
export default IntegrationTypeList