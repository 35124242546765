import API from '../../http-common';

class PermissionService {
    // get all permission Information 
    getPermissions(pageNo, pageSize) {
        return API.get("/api/v1/getPermissions/" + pageNo + "/" + pageSize);
    }

    // get all roles Information 
    getRoles() {
        return API.get("/api/v1/getRoles");
    }

    // get all permission Information
    getPermissionByRoleId(pageNo, pageSize,roleId) {
        return API.get("/api/v1/getPermissionsByRoleId/" + pageNo +"/" + pageSize + "/" + roleId);
    }

    // create  permission Information 
    createPermission(roleId,permission) {
        return API.post("/api/v1/createPermissions/" + roleId, permission);
    }

    // get single permission Information 
    getPermission(id) {
        return API.get("/api/v1/getPermissionById/" + id);
    }

    // update  Permission Information 
    updatePermission(id, permission) {
        return API.put("/api/v1/updatePermission" + "/" + id, permission);
    }

    getUserRoles(userId){
        return API.get("/api/v1/getUserRoles/" + userId);
    }

    createUserRole(userRole){
        return API.post("/api/v1/createUserRole", userRole)
    }

    deleteUserRole(userId,id){
          return API.delete("/api/v1/deleteUserRole/" + id + "/" + userId);
    }

    

}


export default new PermissionService()