import API from '../../../src/http-common';

class RollbackRequestService {

    findRollbackRequestById(id,userId) {
        return API.get('/api/v1/findRollbackRequestById/'+id +'/'+userId);
    }
    createRollbackRequest(requestData) {
        return API.post('/api/v1/createRollbackRequest',requestData);
    }
    updateRollbackRequest(userId,requestData) {
        return API.put('/api/v1/updateRollbackRequest/'+userId, requestData);
    }

    findAllRollbackRequests(pageNo, pageSize,userId, searchParam) {
        return API.put("/api/v1/findAllRollbackRequests/" + pageNo + '/' + pageSize +'/'+userId, searchParam);
    }

    getApprovedRollbackById(id,userId) {
        return API.get('/api/v1/getApprovedRollbackById/'+id +'/'+userId);
    }

      // Verify Cash Information 
    processRollbackRequest(id, cashInfo) {
       return API.put("/api/v1/processRollbackRequest" +"/" + id, cashInfo);
    }
}
export default new RollbackRequestService()