import React, { Component } from 'react'
import Menu from '../../../components/wallet/Menu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import KioskCustomerAssignmentService from '../../../services/wallet/KioskCustomerAssignmentService';
import { Link } from "react-router-dom";
import LoadingPopup from '../LoadingPopup';
import ReactLoading from "react-loading"; 

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddKioskCustomerAssignment extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            createdBy: '',
            kioskId: null,
            isLoading: false,
            mainDealerId: null,
            KioskSelectOption: null,
            cashCollectorSelectionOption: null,
            kioskAssignmentList: [],
            pageNo: 1,
            createdBy: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                kioskIdError: "",
                customerIdError: ""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

 
    fetchKisokData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findKioskBySearchParam/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.kioskId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            kioskList: data,

                        })
                    })
                    .catch((error) => {
                     
                    })
            })
        }
    }

    onSearchChange = (KioskSelectOption) => {
        const { formErrors } = this.state
        if (KioskSelectOption) {
            this.state.KioskSelectOption = KioskSelectOption
            this.setState({
                kioskId: KioskSelectOption.value,
                formErrors
            });
        }
    }

    fatchMainDealers = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getUsersBySearchParam/" + inputValue)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            mainDealersList: data,
                        })
                    })
                    .catch((error) => {
                        
                    })
            })
        }else {
            callback([])
          }
    }

    Cancel = (e) => {
        this.props.history.push('/cashCollectorList');
    }

    onMainDealerSearchChange = (cashCollectorSelectionOption) => {
        const { formErrors } = this.state
        if (cashCollectorSelectionOption) {

            this.state.cashCollectorSelectionOption = cashCollectorSelectionOption
            this.setState({
                mainDealerId: cashCollectorSelectionOption.value,
                formErrors
            });
            this.getKioskAssignmentByCustomerId(this.state.cashCollectorSelectionOption.value, localStorage.userId)
        }

    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });

    }
    getKioskAssignmentByCustomerId(mainDealerId, userId) {
        if (mainDealerId) {
            KioskCustomerAssignmentService.getKioskAssignmentByCustomerId(mainDealerId, userId)
                .then(response => {
                    this.setState({
                        kioskAssignmentList: response.data
                    });
                    
                })
                .catch(e => {
                    
                });
        }
    }
 
    handleSubmit(e) {   //
        e.preventDefault();
        const { kioskId, mainDealerId, formErrors } = this.state

        formErrors.kioskIdError = kioskId == '' || kioskId == null || kioskId == undefined ? 'Please select kiosk' : this.state.formErrors.kioskIdError;
        formErrors.customerIdError = mainDealerId == '' || mainDealerId == null || mainDealerId == undefined ? 'Please select cash collector' : this.state.formErrors.customerIdError;

        
        if (this.state.kioskAssignmentList.kioskId === this.state.kioskId) {
            NotificationManager.error("Kiosk already assigned, please select other kiosk !! ", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {
            if (formValid(this.state.formErrors)) {
                var data = new FormData();
                data.append("kioskId", this.state.kioskId);
                data.append("customerId", this.state.mainDealerId);
                data.append("createdBy", localStorage.customerIdentity)
                data.append("userId", localStorage.userId);

                this.setState({ isLoadingPop: true})
                KioskCustomerAssignmentService.createKioskCustomerAssignment(data)
                    .then(response => {
                        NotificationManager.success("Assigned successfully.", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({
                            isLoading: false,
                            KioskSelectOption: '',
                            cashCollectorSelectionOption: ''

                        })
                        this.getKioskAssignmentByCustomerId()
                    })
                    .catch(function (err) {

                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    });

            } else {
                NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
                this.setState({
                    formErrors
                })
            }
        }

    };
    async RemoveAssignment(kioskId) {
        if (window.confirm("Are you sure you want to remove this assignment?")) {
            const response = await KioskCustomerAssignmentService.removeKioskAssignmenet(kioskId, localStorage.userId);
            NotificationManager.success('Removed Successfully',`${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.getKioskAssignmentByCustomerId()
        } else {
            NotificationManager.error('Assignment removal error', `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
    }

    render() {
        const { kioskAssignmentList, mainDealers, providersList, formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className=" col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Cash Collector Assignment</li>
                                </ol>
                          
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-10 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Assign Kiosk  </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-5 col-lg-5">
                                                            <label htmlFor='Country'>Select Cash Collector:</label>
                                                            <AsyncSelect id='mainDealersList'
                                                                required
                                                                placeholder={ "Search  by name or mobile" }
                                                                value={ this.state.cashCollectorSelectionOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchMainDealers }
                                                                isLoading={ this.state.cashCollectorSelectionOption === {} &&
                                                                    this.state.cashCollectorSelectionOption === undefined &&
                                                                    this.state.cashCollectorSelectionOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onMainDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.customerIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.customerIdError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Kiosk </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-5 col-lg-5 border">
                                                            <label htmlFor='salesTeamAssignment'>Select kiosk:</label>
                                                            <AsyncSelect id='kioskList'
                                                                required
                                                                placeholder={ "Search by kioskId or serialNo" }
                                                                value={ this.state.KioskSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchKisokData } KioskSelectOption
                                                                isLoading={ this.state.KioskSelectOption === {} &&
                                                                    this.state.KioskSelectOption === undefined &&
                                                                    this.state.KioskSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.kioskIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.kioskIdError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-1 col-lg-1 border ">
                                                            <button disabled={this.state.isLoading} type="submit" className="btn btn-success btn-sm mt-4 " style={ { width: "140%" } } title="Click here to assign"><i class="fas fa-angle-double-right" style={ { fontSize: "120%" } }></i></button>
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 border">
                                                            <form onSubmit={ this.handleSubmit }>
                                                                <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                                                    <thead >
                                                                        <tr style={ { marginTop: 0 } }>
                                                                            <th colspan="2" className='text-center'>Assigned Kioks'</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        { kioskAssignmentList !== undefined && kioskAssignmentList.map(assignmentList =>

                                                                            <tr key={ assignmentList.id } style={ { height: "20px" } }>
                                                                                <td className='p-1 '>KiskId:<span style={ { fontWeight: 'bolder' } }>{ assignmentList.kioskId }</span> & serialNo:<span style={ { fontWeight: 'bolder' } }>{ assignmentList.serialNo }</span></td>
                                                                                <button disabled={this.state.isLoading} type="submit" onClick={ () => this.RemoveAssignment(assignmentList.id) } className="btn btn-danger btn-sm m-1" style={ { width: "70%" } } title="Remove dealer"><i class="fa fa-minus" style={ { fontSize: "80%" } }></i></button>
                                                                            </tr>

                                                                        ) }

                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="row  d-flex justify-content-center clear-fix ">
                                                         <button type="submit" onClick={ () => this.Cancel() } style={{width:"6%"}} className="btn btn-success mr-5 pr-2" title="Return to list " ><i class="fas fa-angle-double-left" style={ { fontSize: "120%" } }></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                    <div className='row'>
                                    <div className='col-4'></div>
                                    <div className='col-4'>
                                          
                                        </div>
                                       
                                        <div className='col-4'></div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                </>}
                             />}


                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddKioskCustomerAssignment);
