import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {  withRouter } from 'react-router-dom';
import CountryService from '../../../services/common/CountryService';
import Header from '../Header';

class AddCountry extends React.Component {

  constructor () {
    super();

    this.state = {

      countryCode: '',
      countryName: ''
    }
  }


  handleSubmit = e => {
    e.preventDefault();

    var cuntryData = new FormData();
    if (this.countryCode) {
      cuntryData.append("countryCode", this.countryCode);
    }
    else {
      cuntryData.append("countryCode", this.state.countryCode);
    }
    if (this.countryName) {
      cuntryData.append("countryName", this.countryName);
    } else {
      cuntryData.append("countryName", this.state.countryName);
    }

    cuntryData.append("userId", localStorage.userId);

    //  CustomerService.createAgent(object)
    CountryService.createCountry(cuntryData)
      //response.data.success === "Success"
      .then(response => {
         NotificationManager.success("Country successfully saved", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        e.target.reset();
      })
      .catch(err  => {
        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      });
  };

  Cancel = e => {
    this.props.history.push('/countryList');
}


  render() {

    return (

      <div>
        {/* <Header />
        <Menu /> */}
        <NotificationContainer/>
        {/* Content Wrapper. Contains page content */ }
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              {/*  
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash In Information</li>

                  </ol>
                </div>
              </div> 
            */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Add Country</li>

                </ol>
              </div>
              <div >
                <div className="modal-body pt-5 ">

                  <div className='col-1'></div>
                  <div className='col-8 bg-light p-3'>
                    {/*
                    <div class="modal-header">
                      <h4 class="modal-title" >
                         Agent Registration
                      </h4>
                    </div> */}
                    <form onSubmit={ this.handleSubmit }>
                      {/*Basic Information*/ }
                      <div className="card  border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Country Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col- col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='FullName'>Country Code:</label>
                              <input type="text" className="form-control " id='fullName'

                                onChange={ e => this.countryCode = e.target.value } required="required" />
                            </div>

                            <div className=" form-group col- col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='MobileNumber'>Country Name:</label>
                              <input type="text" className="form-control " id='mobileNo'
                                onChange={ e => this.countryName = e.target.value } required="required" />
                            </div>

                          </div>

                        </div>
                      </div>

                      {/*Country Information*/ }

                      <div className="row  d-flex justify-content-left clear-fix ">
                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={() => this.Cancel()}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>
                    </form>
                  </div>



                </div>
              </div>


              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}

export default withRouter(AddCountry);
