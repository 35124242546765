import API from '../../http-common';

class TargetCommissionService {
    getPartnerMonthlyTargetBalance(month, userId) {
        return API.get('/api/v1/getPartnerMonthlyTargetBalance/' + month + "/" + userId);
    }
    createTargetCommission(TargetCommissionData) {
        return API.post('/api/v1/createTargetCommission', TargetCommissionData);
    }
    // getAllPartnerProviderBalance(pageNo, pageSize) {
    //     return API.get('/api/v1/getAllPartnerProviderBalance/' + pageNo + "/" + pageSize);
    // }

    getAllTargetCommissions(pageNo, pageSize, userId, serachParams) {
        return API.put('/api/v1/getAllTargetCommissions/' + pageNo + "/" + pageSize + "/" + userId, serachParams);
    }
    // getPartnerProviderBalanceById(id) {
    //     return API.get('/api/v1/getPartnerProviderBalanceById/' + id);
    // }
    getTargetCommissionById(id, userId) {
        return API.get('/api/v1/getTargetCommissionById/' + id + "/" + userId);
    }

    getTargetCommissionModelById(id, userId) {
        return API.get('/api/v1/getTargetCommissionModelById/' + id + "/" + userId);
    }

    // updatePartnerProviderBalanceService(PartnerProviderBalance) {
    //     return API.put('/api/v1/updatePartnerProviderBalance', PartnerProviderBalance);
    // }
    updateTargetCommission(TargetCommissionData) {
        return API.put('/api/v1/updateTargetCommission', TargetCommissionData);
    }

   
    approveTargetCommission(orderId, userId) {
        return API.get('/api/v1/approveTargetCommission/' + orderId + '/' + userId);
    }
    getTargetCommissionBalance(userId) {
        return API.get("/api/v1/getTargetCommissionBalance/" + userId);
    }

    getAirtimeCompanyBalance(userId) {
        return API.get("/api/v1/getAirtimeCompanyBalance/" + userId);
    }

    getAllPendingTargetCommissions(userId) {
        return API.get("/api/v1/getAllPendingTargetCommissions/" + userId);
    }

    deleteOrder(orderId,userId) {
        return API.delete('/api/v1/deleteTargetCommission/' + orderId+'/'+userId);
    }
}
export default new TargetCommissionService()