import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import SmsService from '../../../services/etopup/SmsService';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class EditSmsNotification extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            notificationId: this.props.match.params.notificationId,
            isLoading: false,
            notificationData: {
                id: '',
                title: '',
                senderMessage: '',
                receiverMessage: '',
                trxnType: '',
                remark: '',
                createdBy: '',
                formErrors: {
                    titleError: false,
                    senderMessageError: false,
                    receiverMessageError: false,
                    trxnTypeError: false,
                },
            },
            notificationList: [],
        };

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeSenderMessage = this.onChangeSenderMessage.bind(this);
        this.onChangeReceiverMessage = this.onChangeReceiverMessage.bind(this);
        this.onChangeTrxnType = this.onChangeTrxnType.bind(this);
        this.onChangeRemark = this.onChangeRemark.bind(this);


    }


    onChangeTitle(e) {
        const title = e.target.value;

        this.setState(function (prevState) {
            return {
                notificationData: {
                    ...prevState.notificationData,
                    title: title
                }
            };
        });
    }
    onChangeSenderMessage(e) {
        const senderMessage = e.target.value;

        this.setState(function (prevState) {
            return {
                notificationData: {
                    ...prevState.notificationData,
                    senderMessage: senderMessage
                }
            };
        });
    }
    onChangeReceiverMessage(e) {
        const receiverMessage = e.target.value;

        this.setState(function (prevState) {
            return {
                notificationData: {
                    ...prevState.notificationData,
                    receiverMessage: receiverMessage
                }
            };
        });
    }

    onChangeRemark(e) {
        const remark = e.target.value;

        this.setState(function (prevState) {
            return {
                notificationData: {
                    ...prevState.notificationData,
                    remark: remark
                }
            };
        });
    }
    onChangeTrxnType(e) {
        const trxnType = e.target.value;

        this.setState(function (prevState) {
            return {
                notificationData: {
                    ...prevState.notificationData,
                    trxnType: trxnType
                }
            };
        });
    }

    Cancel = (e) => {
        this.props.history.push('/smsNotificationList');
    }

    componentDidMount() {
        this.getSmsNotificationById(this.props.location.state.notificationId);
    }
    getSmsNotificationById(notificationId) {

        SmsService.getSmsNotificationById(notificationId, localStorage.userId)
            .then(response => {
                this.setState({
                    notificationData: response.data,
                    isLoading: true,
                });
             
                this.setState({ isLoading: true });
            })
            .catch(e => {
                
            });
    }
    handleSubmit = e => {

        e.preventDefault();
        var data = new FormData();

        data.append("id", this.props.location.state.notificationId);
        data.append("title", this.state.notificationData.title);
        data.append("senderMessage", this.state.notificationData.senderMessage);
        data.append("receiverMessage", this.state.notificationData.receiverMessage);
        data.append("trxnType", this.state.notificationData.trxnType);
        data.append("remark", this.state.notificationData.remark);
        data.append("createdBy", localStorage.customerIdentity);
        data.append("userId", localStorage.userId);

        SmsService.updateSmsNotification(data)
            .then(response => {
                NotificationManager.success('Updated Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.Cancel()
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            });

    }


    render() {

        const { notificationData, isLoading, formErrors, notificationList } = this.state;
      
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active"> SMS Notification</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0 ">
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Update SMS Notification </h6>
                                                </div>
                                                <div className="card-body ">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='title'>Title:</label>
                                                            <input type="text" id="title" className="form-control " value={ notificationData.title } onChange={ this.onChangeTitle } />
                                                            {/* { formErrors.titleError == "" && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.titleError }</span>
                                                            ) } */}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Sender Message:</label>
                                                            <textarea rows={ 5 } cols={ 5 } type="text" className="form-control " id='senderMessage' value={ notificationData.senderMessage } onChange={ this.onChangeSenderMessage } />
                                                            {/* { formErrors.senderMessageError == undefined && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.senderMessageError }</span>
                                                            ) } */}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Receiver Message:</label>
                                                            <textarea rows={ 5 } cols={ 5 } type="text" className="form-control " id='receiverMessage' value={ notificationData.receiverMessage } onChange={ this.onChangeReceiverMessage } />
                                                            {/* { formErrors.receiverMessageError == undefined && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.receiverMessageError }</span>
                                                            ) } */}
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Remark:</label>
                                                            <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='remark' value={ notificationData.remark } onChange={ this.onChangeRemark } />

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 pt-2 pt-2">
                                                            <label htmlFor='trxnType'>Notification Type:</label>
                                                            <div className='row mt-3'>
                                                                {/* <label for="trxnType"><input type="checkbox" name="transfer" value={ notificationData.trxnType } onChange={ this.onChangeTrxnType } className='ml-3' /><span className='ml-2'>Transfer</span></label> */ }
                                                                {/* <label for="trxnType"><input type="checkbox" name="topu" value={ notificationData.trxnType } onChange={ this.onChangeTrxnType } className='ml-3' /><span className='ml-2'>Topup</span></label> */ }
                                                                <select id='CustomerType' className="form-control" value={ notificationData.trxnType } onChange={ this.onChangeTrxnType } >
                                                                    <option value=""> Select notification type</option>
                                                                    <option value="transfer"> Transfer</option>
                                                                    <option value="topup"> Topup</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ml-5">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Update</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>


                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditSmsNotification);