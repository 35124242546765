import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AsyncSelect from 'react-select/async'
import 'react-notifications/lib/notifications.css';
import PostAccountService from '../../../services/etopup/PostAccountService';
import Header from '../../common/Header';
import API from '../../../http-common';
import Menu from '../../wallet/Menu';
import LoadingPopup from '../../wallet/LoadingPopup';
import ReactLoading from "react-loading";

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddPostAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openingBalance: 0,
      debit: 0,
      credit: 0,
      creditStatus:false,
      debitStatus:false,
      amount: 0,
      isLoading: false,
      transactionType:0,
      remark: "",
      formErrors: {
        remarkError: "",
        amountError: "",
        drAccountCodeError: "",
        crAccountCodeError:""            
      },
      customerList: [],
      drSelectOption: null,
      crSelectOption: null,
      customerId: '',
      fullName: ''
    }

  }




  drFatchData = (inputValue, callback) => {
    if (!inputValue) {
      callback({})
    } else {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data,

            })
          })
          .catch((error) => {
          })
      })
    }
  }

  onDrSearchChange = (drSelectOption) => {
    const { formErrors } = this.state
    formErrors.drAccountCodeError = drSelectOption == "" || drSelectOption == null ? 'You must make a selection' : "";



    this.setState({
      drSelectOption: drSelectOption,
      formErrors
    });

  }

  CrFatchData = (inputValue, callback) => {
    if (!inputValue) {
      callback({})
    } else {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data,

            })
          })
          .catch((error) => {
          })
      })
    }
  }

  onCrSearchChange = (crSelectOption) => {
    const { formErrors } = this.state
    formErrors.crAccountCodeError = crSelectOption == "" || crSelectOption == null ? 'You must make a selection' : "";
    this.setState({
      crSelectOption: crSelectOption,
      formErrors
    });

  }





  componentDidMount() { }




  handleSubmit = e => {
    e.preventDefault();
    const {  amount, remark,drSelectOption,crSelectOption, formErrors } = this.state
    formErrors.drAccountCodeError = drSelectOption == '' || drSelectOption == null || drSelectOption == undefined ? 'You must make a selection' : this.state.formErrors.drAccountCodeError;
    formErrors.crAccountCodeError = crSelectOption == '' || crSelectOption == null || crSelectOption == undefined ? 'You must make a selection' : this.state.formErrors.crAccountCodeError;
    formErrors.amountError = amount == '' || amount == "0" || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError
    formErrors.remarkError = remark == '' || remark == null || remark == undefined ? 'Remark is Required' : this.state. formErrors.remarkError

    if (formValid(this.state.formErrors)) {
      const formData = new FormData();
      formData.append("drAccountCode", this.state.drSelectOption && this.state.drSelectOption.value)
      formData.append("crAccountCode",  this.state.crSelectOption && this.state.crSelectOption.value)
      formData.append("transactionType", this.state.transactionType)
      formData.append("amount", this.state.amount)
      formData.append("remark", this.state.remark)
      formData.append("userId", localStorage.userId)
      
      this.setState({ isLoadingPop: true})
      PostAccountService.createPostAccounts(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.state.crSelectOption = null
            this.state.drSelectOption = null
            this.setState({ isLoadingPop: false})
           e.target.reset();
            
          },
          (err) => {
            NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
     
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_INFO}`, 3000);
      this.setState({
        formErrors
      })
    }
  };



  cancel() {
    this.props.history.push('/ledgerTranList')
  }

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'amount':
        formErrors.amountError =
          value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100000000" : ""
        break;
    
      case 'remark':
        formErrors.remarkError =
        value.length == 0 || !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;
    }

    this.setState({
      debitStatus: this.state.debitStatus,
      creditStatus: this.state.creditStatus,
      formErrors, 
      [name]: value
    })
  }


  render() {
    const { formErrors } = this.state


    return (

      <div >
        {/* <Header />
        <Menu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Post Account Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Post Account Information
                      </h4>
                    </div>

                    <form onSubmit={this.handleSubmit} noValidate>

                      <div>
                        <label htmlFor='drAccount'>Dr. Account :</label>
                        <div style={{ fontSize: 'smaller' }}>
                          <AsyncSelect id='drAccount'
                            className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                            placeholder={"Search by account code"}
                            isClearable={true}
                            loadOptions={this.drFatchData}
                            isLoading={this.state.drSelectOption == {} &&
                              this.state.drSelectOption === undefined &&
                              this.state.drSelectOption === null ? true : false
                            }
                            onChange={(e) => {
                              this.onDrSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                          {formErrors.drAccountCodeError.length > 0 && (
                            <span style={{ color: "#F61C04" }}>{formErrors.drAccountCodeError}</span>
                          )}

                        </div>

                      </div>

                      <div>
                        <label htmlFor='crAccount'>Cr. Account :</label>
                        <div style={{ fontSize: 'smaller' }}>
                          <AsyncSelect id='crAccount'
                            className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                            placeholder={"Search by account code"}
                            isClearable={true}
                            loadOptions={this.CrFatchData}
                            isLoading={this.state.crSelectOption == {} &&
                              this.state.crSelectOption === undefined &&
                              this.state.crSelectOption === null ? true : false
                            }
                            onChange={(e) => {
                              this.onCrSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                          {formErrors.crAccountCodeError.length > 0 && (
                            <span style={{ color: "#F61C04" }}>{formErrors.crAccountCodeError}</span>
                          )}

                        </div>

                      </div>

                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='amount'>Amount:</label>
                        <input  type="text" className="form-control" id='debit'
                          onChange={this.handelChange} onBlur={this.handelChange} name='amount'
                          placeholder="Amount" />
                        {formErrors.amountError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.amountError}</span>
                        )}
                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='remark'>Remark:</label>
                        <textarea type="text" className="form-control" id='remark'
                          onChange={this.handelChange} onBlur={this.handelChange} name='remark'
                          placeholder="Remark" />
                        {formErrors.remarkError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.remarkError}</span>
                        )}
                      </div>

                      <div className="row  d-flex justify-content-left ">
                        <button type="submit" disabled={this.state.isLoading}
                          className="btn btn-success px-4 py-2" style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={this.cancel.bind(this)}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}



export default AddPostAccount;