import React, { Component } from 'react';
import ProvidersSettingService from '../../../services/etopup/ProvidersSettingService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import Dropzone from 'react-dropzone';
import ReactLoading from "react-loading";
import TopupMenu from '../../etopup/TopupMenu';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import LoadingPopup from '../../wallet/LoadingPopup';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{3,50}$/u)
const codeRegEx = RegExp(/^[a-zA-Z]{2,3}$/u)
const perfixReqEx = RegExp(/^[?=1-9]+[0-9/]{2,50}$/u)

class EditProvidersSetting extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingPop: false,
      id: this.props.location.state.id,
      selectedFilesIdentity: undefined,
      currentFileIdentity: undefined,
      selectOption:null,
      providersSettingData: {
        providerName: "",
        code: ""
      },
      formErrors: {
        mobilePrefixError:'',
        countryError:'',
        providerNameError: "",
        codeError: "",
        logoError: ""
      },
    }
    this.onDropIdentity = this.onDropIdentity.bind(this);
  }

  componentDidMount() {
    ProvidersSettingService.getProvidersSetting(this.state.id, localStorage.userId)
      .then(response => {
        this.setState({
          isLoading: true,
          providersSettingData: response.data
        });
        

      })
      .catch(e => {
        
      });
  }



  cancel() {
    this.props.history.push('/providerSettingList')
  }

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'providerName':
        this.state.providersSettingData.providerName = value
        formErrors.nameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphabetic and (/_) characters with min 3 and max 50" : ""
        break;
      case 'code':
        this.state.providersSettingData.code = value
        formErrors.codeError =
          !codeRegEx.test(value) ? name + " value sholud only alphabetic characters with min 2 and max 3" : ""
        break;
        case 'mobilePrefix':
          this.state.providersSettingData.mobilePrefix = value
          formErrors.mobilePrefixError =
            !perfixReqEx.test(value) ? name + " value sholud only digites with min 2 and max 50" : ""
          break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }



  updateCustomer() {
    const { providersSettingData, currentFileIdentity, formErrors } = this.state


    formErrors.providerNameError = providersSettingData.providerName === '' || providersSettingData.providerName === null ? "Provider Name is Required" : this.state.formErrors.providerNameError;
    formErrors.codeError = providersSettingData.code === '' || providersSettingData.code === null ? "Code is Required" : this.state.formErrors.codeError;
    formErrors.logoError = (currentFileIdentity === '' || currentFileIdentity === null) && (providersSettingData.logo === undefined || providersSettingData.logo === null) ? "logo is Required" : this.state.formErrors.logoError;

    if (formValid(this.state.formErrors)) {
      const providersSettingData = new FormData();
      providersSettingData.append("providerName", this.state.providersSettingData.providerName);
      providersSettingData.append("code", this.state.providersSettingData.code)
      providersSettingData.append("id", this.state.id)
      providersSettingData.append("logo", currentFileIdentity === undefined || currentFileIdentity === '' || currentFileIdentity === null ? this.state.providersSettingData.logo : this.state.currentFileIdentity)
      providersSettingData.append("countryId", this.state.selectOption && this.state.selectOption.value);
      providersSettingData.append("mobilePrefix", this.state.providersSettingData.mobilePrefix);

      this.setState({ isLoadingPop: true})
      ProvidersSettingService.updateProvidersSetting(localStorage.userId, providersSettingData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            this.cancel()
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  }

  onDropIdentity(files) {

    if (files.length > 0) {
      this.setState({ selectedFilesIdentity: files });
      this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[ 0 ] });
    }
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCountryByCountryName/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.countryName, value: element.id })
            });
            callback(temp)
            this.setState({
              CountryList: data,

            })

          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    if (selectOption) {
      this.setState({
        selectOption
      });

    }
  }



  render() {
    const { providersSettingData, selectedFilesIdentity, formErrors, isLoading } = this.state

    if(providersSettingData != null && providersSettingData.countryId != undefined){
      this.state.selectOption ={
        label: providersSettingData.countryId.countryName + " - " + providersSettingData.countryId.countryCode,
        value: providersSettingData.countryId.id
      }
    }

    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <TopupMenu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div >
          {/* <Header />
          <TopupMenu /> */}
          <NotificationContainer />
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Edit Providers Setting Information</li>

                    </ol>
                  </div>

                </div>
                <div >
                  <div className="modal-body">

                    <div className='col-1'></div>
                    <div className='col-7 bg-light p-4'>

                      <div class="modal-header">
                        <h4 class="modal-title" >
                          Edit Providers Setting Information
                        </h4>
                      </div>

                      {/*<h2>Register</h2> */ }

                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='providerName'>Name:</label>
                        <input type="text" className="form-control" id='providerName'
                          value={ providersSettingData.providerName } name="providerName"
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Provider Name" required="required" />
                        { formErrors.providerNameError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.providerNameError }</span>
                        ) }
                      </div>



                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='code'>code:</label>
                        <input type="" className="form-control" id='code'
                          value={ providersSettingData.code } name='code'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="code" />
                        { formErrors.codeError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.codeError }</span>
                        ) }
                      </div>
                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Country'>Country Name:</label>
                        <AsyncSelect id='CountryList'
                          required
                          placeholder={"Search country by name "}
                          value={this.state.selectOption}
                          isClearable={true}
                          loadOptions={this.fatchData}
                          isLoading={this.state.selectOption === {} &&
                            this.state.selectOption === undefined &&
                            this.state.selectOption === null ? true : false
                          }
                          onChange={(e) => {
                            this.onSearchChange(e)
                          }}
                          defaultOptions={false}
                        />
                        {formErrors.countryError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.countryError}</span>
                        )}
                      </div>
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Country'>Mobile Prefix:</label>
                        <input type="text" className="form-control " id='mobilePrefix' name='mobilePrefix'
                         value={providersSettingData.mobilePrefix}
                         onChange={this.handelChange} onBlur={this.handelChange} />
                          <label style={ { fontSize: 'smaller' } } >N.B Separate by "/" for more than one mobile prefixs</label>
                        {formErrors.mobilePrefixError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.mobilePrefixError}</span>
                        )}
                      </div>
                      <div className='row'>
                        <div className=" form-group col-12 col-sm-8 col-md-8 col-lg-8">
                          <label htmlFor='profilePic'>Logo:</label>
                          <img id="logo" className="mx-auto d-block " style={ { width: '100px', height: '70px', margin: 'auto', alignSelf: 'center' } } src={ "data:image/png;base64," + providersSettingData.logo }></img>


                          <Dropzone id="logo" accept={ ".png, .jpg  " }
                            onDrop={ this.onDropIdentity }
                            multiple={ false }>

                            { ({ getRootProps, getInputProps }) => (
                              <section>
                                <div { ...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" }) } style={ { border: "thin dashed black" } }>
                                  <input { ...getInputProps() } />
                                  { selectedFilesIdentity && selectedFilesIdentity[ 0 ].name ? (
                                    <div className="selected-file">
                                      { selectedFilesIdentity && selectedFilesIdentity[ 0 ].name }
                                    </div>
                                  ) : (
                                    "Drag and drop file here, or click to select file"
                                  ) }
                                </div>
                                <aside className="selected-file-wrapper">

                                </aside>
                              </section>
                            ) }
                          </Dropzone>
                          <label style={ { fontSize: 'smaller' } } >N.B upload image with .png or .jpg file only</label>
                          { formErrors.logoError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.logoError }</span>
                          ) }
                        </div>
                      </div>



                      <div className="row  d-flex justify-content-left ">
                        <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }
                          onClick={ () => this.updateCustomer() }>Update</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>
                    </div>

                  </div>
                </div>
                {this.state.isLoadingPop && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>

            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}


export default EditProvidersSetting;