export const LOCALES = {
    CASH_IN_TYPE: 1,
    CASH_OUT_TYPE: 2,
    CASH_METHOD_ID: 2,
    CASH_METHOD: 1,
    DIPOSIT_METHOD: 2,
    BANK_TRASFER_METHOD: 3,
    DEFAULT_CUSTOMER: 1,
    RECEIVER_BANK_ID: 1,
    RECEVER_BANK_ACCOUNT: 1000003465,
    PAGE_SIZE: 20,
    PERMISSION_PAGE_SIZE: 20,
    CASH_STATUS_REGISTERED: 0,
    CASH_STATUS_VERIFIED: 1,
    CASH_STATUS_APPROVED: 2,
    COMVIVA_PROVIDER: 2,
    ETSALAT_PROVIDER: 1,
    MTN_PROVIDER: 3,
    AGENT: "Agent",
    RETAILER: "Retailer",
    MERCHANT: "Merchant",
    USER: "User",
    SALES: "Sales",
    MAIN_DEALER:'Main Dealer',
    SMARTPAY_CASH_VERIFIED:1,
    SMARTPAY_CASH_VERIFIEDREJECT:2,
    SMARTPAY_CASH_APPROVED:3,
    SMARTPAY_CASH_APPROVEREJECT:4,
    SMARTPAY_ROLLBACK_PROCESS:5
};