import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../../components/wallet/Menu';
import { format } from 'date-fns';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import OverDraftService from '../../../services/common/OverDraftService';
import Header from '../Header';
import ReactLoading from "react-loading";
import NumberFormat from 'react-number-format';

class OverDraftList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            isLoading: false,
            OverDraftList: [],
            counter: 1
        }

    }

    componentDidMount() {

        OverDraftService.getAllOverDraft(localStorage.userId)
            .then(response => {
                this.setState({
                    OverDraftList: response.data,
                    isLoading: true
                });
                //   
            })
            .catch(e => {
                this.setState({ isLoading: true });
            });
    }


    // Delete overdraft
    async deleteoverdraft(mobileNo) {
        try {
            const response = await OverDraftService.deleteCustomer(mobileNo, localStorage.userId);
            NotificationManager.success('Deleted Successfully',`${process.env.REACT_APP_MSG_TITLE}`, 3000);
        } catch (error) {
            NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
    }

    render() {
        const { OverDraftList, isLoading } = this.state;
        this.state.counter=1;

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu /> */}
                    <NotificationContainer />
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Overdraft List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Overdrafts Information</div>

                                        <Link to={ { pathname: '/addOverDraft', state: { type: "overdraft", flag: "1" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } }>
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>No.</th>
                                                    <th>Full Name</th>
                                                    <th>Target</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    {/* <th>Sold Amount</th> */ }
                                                    <th>Achievement</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { OverDraftList.map(overdraft =>

                                                    <tr>
                                                        <td className='p-0 '>{this.state.counter++}</td>
                                                        <td className='p-0 '>{ overdraft.fullName }</td>
                                                        <td className='p-0 '>
                                                            <NumberFormat value={ overdraft.soldAmount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td className='p-0 '>{ format(overdraft.startDate, 'yyyy-MM-dd') }</td>
                                                        <td className='p-0 '>{ format(overdraft.endDate, 'yyyy-MM-dd') }</td>
                                                        <td className='p-0 '>
                                                            <NumberFormat value={ overdraft.achievement.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td className='p-0'>

                                                            <Link to={ { pathname: '/viewOverDraft', state: { operationType: "View", id: overdraft.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                            </Link>
                                                            <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteoverdraft(overdraft.mobileNo) } className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default OverDraftList