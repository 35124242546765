import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  withCredentials: false,
  headers: {
    'X-Content-Type-Options': 'nosniff',
    'X-XSS-Protection': '1',
    'mode': 'block',
    'access-Control-Allow-Origin': '*',
    'access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});


export default API;