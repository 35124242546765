
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ReactLoading from "react-loading";
import Image from "react-image-enlarger";
import CustomersService from '../../services/common/CustomersService';
import Header from '../common/Header';
import Menu from '../wallet/Menu';

export default class ViewCompanies extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            zoomed: false,
            id: this.props.location.state.id,
            isLoading: false,
            resultArray: [
                "fullName",
                "mobileNo",
                "type",
                "companyName",
                "commissionType",
                "accountNo",
                "balance",
                "attachment",
                "identityCard",
                "countryName",
                "province",
                "city",
                "street",
                "email",
                "website",
                "bankName",
                "bankAccount",
                "ledgerAccount"

            ]
        };
        this.zoomOut = this.zoomOut.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
    }
    Cancel = (e) => {
        this.props.history.push('/companyProfileList');
    }
    componentDidMount() {
        this.getCustomerByMobileNumber(this.props.location.state.mobileNo, this.props.location.state.operationType);
    }

    getCustomerByMobileNumber(mobileNo, operationType) {

        CustomersService.getCustomerByMobileNumber(mobileNo, operationType, localStorage.userId)
            .then(response => {
                this.setState({
                    resultArray: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {
                
            });
    }
    editCustomer(customerMobileNo) {
        this.props.history.push(`/editCompanies/${customerMobileNo}`);
    }
    zoomOut() {
        this.setState({ zoomed: false })
    }
    zoomIn() {
        this.setState({ zoomed: true })
    }


    render() {
        const { resultArray, isLoading, zoomed } = this.state;
        // const props = { width: 200, height: 100, zoomWidth: 50, zoomPosition: "original", scale: 1.5, img: "data:image/png;base64," + resultArray[ resultArray.length - 1 ][ "identityCard" ] };
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Company Detail Information</li>

                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        {/*
                                       <div className="form-title" >Customer Detail</div>
                                       <hr />  
                                    */}
                                        <div className='row'>
                                            <div className='col sm-12 col md-7 col lg-7'>
                                                <div className="table-responsive w-100">


                                                    <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                                        <tr >
                                                            <td style={ { borderRightStyle: 'hidden' } }>
                                                                <div className='center'> <img id="logo" className="mx-auto d-block rounded-circle" style={ { width: '90px', height: '90px', margin: 'auto', alignSelf: 'center' } } src={ "data:image/png;base64," + resultArray[ resultArray.length - 1 ][ "attachment" ] }></img> </div>
                                                            </td>
                                                            <td style={ { borderLeftStyle: 'hidden' } }>
                                                                <label>Account No:</label> { resultArray[ resultArray.length - 1 ][ "accountNo" ] } <hr />
                                                                <label>Balance:</label> { resultArray[ resultArray.length - 1 ][ "balance" ] }
                                                            </td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colspan="2" className="text-blue py-4"> Basic Information</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder" >Company Name.</td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "companyName" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder" >Mobile No</td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "mobileNo" ] }</td>
                                                        </tr>
                                                        <tr >
                                                            <td className="text-left font-weight-bolder" >Type</td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "type" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Ledger Account</td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "ledgerAccount" ] }</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colspan="2" className="text-blue py-4 "> Address Information</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Country </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "countryName" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Province </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "province" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">City </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "city" ] == "null" ? "" : resultArray[ resultArray.length - 1 ][ "city" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Street </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "street" ] == "null" ? "" : resultArray[ resultArray.length - 1 ][ "street" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Email </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "email" ] == "null" ? "" : resultArray[ resultArray.length - 1 ][ "email" ] }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Website </td>
                                                            <td className="text-justify">{ resultArray[ resultArray.length - 1 ][ "website" ] == "null" ? "" : resultArray[ resultArray.length - 1 ][ "website" ] }</td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colspan="2" className="text-blue py-4 "> Bank Information</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Bank Name </td>
                                                            <td className="text-justify">Bank Account</td>
                                                        </tr>

                                                        { resultArray.length > 0 && resultArray.map((item, i) =>
                                                            <tr key={ i }>
                                                                <td className="text-left font-weight-bolder"> { resultArray[ i ][ "bankName" ] }</td>
                                                                <td className="text-justify">{ resultArray[ i ][ "bankAccount" ] }</td>
                                                            </tr>
                                                        ) }
                                                        <tr style={ { textAlign: 'center' } } >
                                                            <button type="submit" className="btn btn-success auto mt-4 px-4 py-1" onClick={ () => this.Cancel() }>Back</button>
                                                          
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col sm-12 col md-2 col lg-2 w-50' id="img-container">
                                                <div style={ { position: "absolute" } }>
                                                    {/* <ReactImageZoom { ...props } /> */ }
                                                    <Image
                                                        style={ { width: "100px", height: "auto" } }
                                                        zoomed={ zoomed }
                                                        src={ "data:image/png;base64," + resultArray[ resultArray.length - 1 ][ "identityCard" ] }
                                                        alt="Identity Card"
                                                        onClick={ () => this.zoomIn() }
                                                        onRequestClose={ () => this.zoomOut() }
                                                    />
                                                    {/* <img id="logo" className="d-block" style={ { width: '60%', height: '50%' } } src={ "data:image/png;base64," + resultArray[ resultArray.length - 1 ][ "identityCard" ] }></img> */ }
                                                </div>

                                            </div>

                                        </div>

                                        {/* <div className="table-responsive w-10">
                                        <p>Pic</p>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-2'></div>
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>

            )
        }
    }
}

