import React, { Component } from 'react';
import './PrivacyPolicy.css'; // Import your CSS file

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="privacy-container">
                <h1>Privacy Policy</h1>
                <p>Last updated: 8 -04-2023</p>

                <p>SmatPay we operates http://smartpaytek.com. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the our App and Site.</p>

                <h2>Information Collection and Use</h2>

                <p>While using our Site or APP, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, and phone number ("Personal Information").</p>

                <h2>Camera Usage</h2>
                <p>This component might utilize your device's camera to capture photos for profile images and scan QR code. We value your privacy and ensure that the camera access is only used for this specific purpose within this component. We do not store any photos captured using your camera on our servers or share them with third parties.</p>

                <h2>Security</h2>

                <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information.</p>

                <h2>Changes to This Privacy Policy</h2>

                <p>This Privacy Policy is effective as of 8-4-2023 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

                <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions about this Privacy Policy, please contact us at  it@smartpay-tech.com.</p>

              </div>
        );
    }
}

export default PrivacyPolicy;
