
import OverDraftService from '../../../services/common/OverDraftService'
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'
import Header from '../Header';
import ReactLoading from "react-loading";

export default class OverDraftDetail extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            isLoading: false,

            overDraftData: {
                fullName: '',
                amount: '',
                startDate: '',
                endDate: '',
                soldAmount: '',
                remark: '',
            }
        };
    }

    componentDidMount() {
        this.getOverDraftById(this.props.location.state.id);
    }

    getOverDraftById(id) {

        OverDraftService.getOverDraftById(id, localStorage.userId)
            .then(response => {
                this.setState({
                    overDraftData: response.data,
                    isLoading: true,
                });
            
            })
            .catch(e => {
                
                this.setState({ isLoading: true });
            });
    }

    render() {
        const { overDraftData, isLoading } = this.state;

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">OverDraft Detail</li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        {/*
                                       <div className="form-title" >Customer Detail</div>
                                       <hr />  
                                    */}
                                        <div className="table-responsive w-50">

                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                                {/* <tr >
                                                <td style={{borderRightStyle:'hidden'}}>
                                                <div className='center'> <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '90px', height: '90px',margin: 'auto',alignSelf: 'center' }} src={"data:image/png;base64," +resultArray[resultArray.length - 1]["attachment"]}></img> </div>
                                                </td>
                                                <td style={{borderLeftStyle:'hidden'}}>
                                                    <label>Account No:</label> {resultArray[resultArray.length - 1]["accountNo"]} <hr /> 
                                                    <label>Balance:</label> {resultArray[resultArray.length - 1]["balance"]}
                                                </td>
                                            </tr> */}
                                                <tr className="subheaders bg-light">
                                                    <td colspan="2" className="text-blue py-4">Overdraft Information</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Full Name:</td>
                                                    <td className="text-justify">{ overDraftData.fullName }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Target:</td>
                                                    <td className="text-justify">{ overDraftData.balance }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Start Date:</td>
                                                    <td className="text-justify">{ overDraftData.startDate }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >End Date:</td>
                                                    <td className="text-justify">{ overDraftData.endDate } </td>

                                                </tr>


                                                <tr>
                                                    <td className="text-left font-weight-bolder">Achievement:</td>
                                                    <td className="text-justify">{ overDraftData.soldAmount }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder">Remark:</td>
                                                    <td className="text-justify">{ overDraftData.remark }</td>
                                                </tr>
                                                <tr style={ { textAlign: 'center' } }>
                                                    <Link to="/overDraftList" className='pl-5'>
                                                        <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                    </Link>

                                                </tr>
                                                {/*
                                            <tr className="subheaders">
                                                <td colspan="2" className="text-blue py-4"> Personal Information</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder"> Name</td>
                                                <td className="text-justify">{customerData.fullName}</td>
                                            </tr>

                                            <tr>
                                                <td className="text-left font-weight-bolder"> Bank Account</td>
                                                <td className="text-justify">{customerData.personalBankAccount}</td>
                                            </tr>
                                          
                                            <tr className="subheaders">
                                                <td colspan="2" className="text-blue py-4"> Sender Bank Information</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Bank Name</td>
                                                <td className="text-justify">{customerData.senderBankName}</td>
                                            </tr>

                                            <tr>
                                                <td className="text-left font-weight-bolder" >Back Account</td>
                                                <td className="text-justify">{customerData.senderBankAccount}</td>
                                            </tr>
                                          
                                            <tr className="subheaders">
                                                <td colspan="2" className="text-blue py-4"> Receiver Bank Information</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder">Back Name</td>
                                                <td className="text-justify">{customerData.receiverBankName}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder">Back Account</td>
                                                <td className="text-justify">{customerData.receiverBankAccount}</td>
                                            </tr>
                                             */}
                                            </table>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='col-2'></div>




                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>

            )
        }
    }
}