import API from '../../../src/http-common';

class SubRegionService {
    createSubRegion(subRegionData) {
        return API.post('/api/v1/createSubRegion', subRegionData);
    }
    getSubRegionById(id) {
        return API.get('/api/v1/getSubRegionById/' + id);
    }

    updateSubRegion(subRegionData) {
        return API.get('/api/v1/updateSubRegion', subRegionData);
    }

    getAllSubRegions(pageNo, pageSize, subRegionInfo) {
        return API.put("/api/v1/getAllSubRegions/" + pageNo + "/" + pageSize, subRegionInfo);
    }
    getSubRegionBySearchParam(searchParam) {
        return API.get('/api/v1/getSubRegionBySearchParam', searchParam);
    }

}
export default new SubRegionService()