import API from '../../../src/http-common';

class KioskService {

    getKioskById(id, userId) {
        return API.get('/api/v1/getKioskById/' + id + '/' + userId);
    }

    getKioskByKioskId(kioskId, userId) {
        return API.get('/api/v1/getKioskByKioskId/' + kioskId + '/' + userId);
    }

    getAllKiosks(pageNo, pageSize, userId, kioskFilter) {
        return API.put('/api/v1/getAllKiosks/' + pageNo + '/' + pageSize + '/' + userId, kioskFilter);
    }
    getTotalKiosks(userId) {
        return API.get('/api/v1/getTotalKiosks/'  + userId);
    }

    createKiosk(kioskData) {
        return API.post('/api/v1/createKiosk', kioskData);
    }

    updateKiosk(kioskData) {
        return API.put('/api/v1/updateKiosk', kioskData);
    }

    changeKioskStatus(id, status, userId) {
        return API.get('/api/v1/changeKioskStatus/' + id + '/' + status + '/' + userId);
    }

}
export default new KioskService()