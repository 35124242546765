import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import LoginService from '../../../../services/common/LoginService';
import PasswordRecoveryService from '../../../../services/common/PasswordRecoveryService';
import PasswordRecovery from './PasswordRecovery';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z0-9_\\/ ]{3,20}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\\/ ]{0,20}$/u)
const emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OTPValue: '',
            middleName: '',
            lastName: '',
            email: null,
            identityCard: '',
            mobileNo: this.props.location.state.mobileNo,
            companyProfile: [],
            selectedFilesIdentity: undefined,
            currentFileIdentity: undefined,
            formErrors: {
                OTPValueError: "",
                middleNameError: "",
                lastNameError: "",
                identityCardError: '',
                emailError: "",
                mobileNoError: ""
            },
        }

        this.onDropIdentity = this.onDropIdentity.bind(this);

    }

    handleSubmit = e => {
        e.preventDefault();
        const { mobileNo,  OTPValue,  formErrors } = this.state
        formErrors.mobileNoError = mobileNo == '' || mobileNo == null ? "Phone number is Required" : this.state.formErrors.mobileNoError;
        formErrors.OTPValueError = OTPValue == '' || OTPValue == null ? "FOTP Value is Required" : this.state.formErrors.OTPValueError;


        if (formValid(this.state.formErrors)) {
            let loginId = new FormData();
            loginId.append('loginId', mobileNo);
            loginId.append('email', mobileNo);
            loginId.append('OTPValue', OTPValue);


            PasswordRecoveryService.authenticatOTPRecovery(loginId)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_PASSWORD_RECOVERY}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.props.history.push('/login')
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });

        }
        this.setState({
            formErrors
        })
    };

    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'OTPValue':
                formErrors.OTPValueError =
                    value.length == 0 ? name + " value sholud only numeric with min 6 and max 8" : ""
                    // || 
                    // !nameRegEx.test(value) 
                break;
          
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    onChangeReceiverMobileNo = mobile => {
        const { formErrors } = this.state
        formErrors.mobileNoError =
            !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites" : ""
        this.setState({
            mobileNo: mobile,
            formErrors
        })
    }
    componentDidMount() {
        LoginService.companyProfileInfo()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    companyProfile: data
                });
            });
    }


    onDropIdentity(files) {

        if (files.length > 0) {
            this.setState({ selectedFilesIdentity: files });
            this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[0] });
        }
    }


    render() {
        const { formErrors, selectedFilesIdentity, companyProfile } = this.state

        return (
            <div >
                <NotificationContainer />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                    <div className='col-12 col-sm-8 col-md-6 col-lg-8 bg-light p-4'>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0  bg-blue">

                                                <table style={{ border: 'none' }}>
                                                    <tr style={{ border: 'none' }}>
                                                        <td style={{ border: 'none', width: '30%', alignSelf: 'center' }}>
                                                            <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={{ width: '60%', height: '40%', alignSelf: 'center' }}

                                                            />
                                                        </td>
                                                        <td style={{ border: 'none' }}>
                                                            <h6 className='justify-content-left' >Welcome To {companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table>


                                            </div>
                                            <div className="card-body justify-content-center px-4 py-2 ">
                                                <form onSubmit={this.handleSubmit} noValidate>

                                                   
                                                    <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='OTPValue'>OTP Value:</label>
                                                            <input type="text" className="form-control " id='OTPValue'
                                                                name='OTPValue'
                                                                onChange={this.handelChange}
                                                                onBlur={this.handelChange} />

                                                            {formErrors.OTPValueError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.OTPValueError}</span>
                                                            )}
                                                        </div>
                                                    <div className="clearfix">&nbsp;</div>

                                                

                                                    <div className="clearfix">&nbsp;</div>

                                                    <div className="row float-left justify-content-right: space-between; ">
                                                    <button type="submit" title={"Submit"} className="btn btn-sm btn-success  "
                                                            style={{ marginLeft: "30px" }}> Submit
                                                        </button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default Confirmation;