import API from '../../http-common';

class WalletBalanceService {
    getWalletByCustomerId(customerId, userId) {
        return API.get('/api/v1/getWalletByCustomerId/' + customerId + "/" + userId);
    }

    getWalletBalanceByCustomerId(customerId, userId) {
        return API.get('/api/v1/getWalletBalanceByCustomerId/' + customerId + "/" + userId);
    }
}
export default new WalletBalanceService()