import SmsService from '../../../services/etopup/SmsService';
import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';

export default class ViewSmsNotification extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            notificationId: this.props.match.params.notificationId,
            notificationData: {
                title: '',
                senderMessage: '',
                receiverMessage: '',
                trxnType: '',
                remark: '',
            }
        };
    }

    componentDidMount() {
        this.getSmsNotificationById(this.props.location.state.notificationId);
    }

    getSmsNotificationById(notificationId) {

        SmsService.getSmsNotificationById(notificationId, localStorage.userId)
            .then(response => {
                this.setState({
                    notificationData: response.data
                });
              
            })
            .catch(e => {
                
            });
    }

    render() {
        const { notificationData } = this.state;


        //   console.log(resultArray[0]["bankName"]);
        return (
            <div>
                {/* <Header />
                <TopupMenu /> */}
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">

                        <div className="row mb-2">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">SMS Notification </li>
                                </ol>
                            </div>

                        </div>

                        <div >
                            <div className="card ">
                                <div className="card-body">
                                    {/*
                                       <div className="form-title" >Customer Detail</div>
                                       <hr />  
                                    */}
                                    <div className="table-responsive w-50">

                                        <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                            {/* <tr >
                                                <td style={{borderRightStyle:'hidden'}}>
                                                <div className='center'> <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '90px', height: '90px',margin: 'auto',alignSelf: 'center' }} src={"data:image/png;base64," +resultArray[resultArray.length - 1]["attachment"]}></img> </div>
                                                </td>
                                                <td style={{borderLeftStyle:'hidden'}}>
                                                    <label>Account No:</label> {resultArray[resultArray.length - 1]["accountNo"]} <hr /> 
                                                    <label>Balance:</label> {resultArray[resultArray.length - 1]["balance"]}
                                                </td>
                                            </tr> */}
                                            <tr className="subheaders bg-light">
                                                <td colspan="2" className="text-blue py-4">Detail View</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Title:</td>
                                                <td className="text-justify">{ notificationData.title }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Sender Message:</td>
                                                <td className="text-justify">{ notificationData.senderMessage }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Receiver Message:</td>
                                                <td className="text-justify">{ notificationData.receiverMessage }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Notification Type:</td>
                                                <td className="text-justify">{ notificationData.trxnType }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Remark:</td>
                                                <td className="text-justify">{ notificationData.remark } </td>

                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Date:</td>
                                                <td className="text-justify">{ notificationData.createdAt } </td>

                                            </tr>

                                            <tr style={ { textAlign: 'center' } }>
                                                <Link to="/smsNotificationList" className='pl-5'>
                                                    <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                </Link>

                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className='col-2'></div>




                        {/* /.row */ }
                    </div>
                    {/* /.container-fluid */ }
                </div>
            </div>

        )
    }
}