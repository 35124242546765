import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';

import CashInfoService from '../../../services/etopup/CashInfoService';
import CashMethodService from '../../../services/etopup/CashMethodService';
import CustomerListService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import AsyncSelect from 'react-select/async';
import Header from '../../common/Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const phoneNoRegEX = RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);

class AddCashOut extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      cashMethod: [],
      balanceInfo: [],
      customerId: undefined,
      customerBank: [],
      bankInfoId: [],
      accountNo: '',
      senderMobileNo: '',
      receiverMobileNo: '',
      isLoading: false,
      amount: '',
      description: '',
      cashMethodId: '',
      personalName: '',
      personalBankName: '',
      personalBankAccount: '',
      cashTypeId: LOCALES.CASH_OUT_TYPE,
      searchValue: null,
      formErrors: {
        cashMethodError: "",
        phoneNumberError: "",
        customerNotFoundError: "",
        amountError: "",
        descriptionError: ""
      },
    }
    
  }



  componentDidMount() {
    CashMethodService.getCashMethods()
      .then(response => {
        this.setState({
          cashMethod: response.data
        });
        
      })
      .catch(e => {
        
      });

  }

  onChangeReceiverMobileNo = e => {
    debugger
    const { formErrors } = this.state
    this.state.customerId = undefined
    this.state.balanceInfo = undefined
    this.state.receiverMobileNo = e
    formErrors.phoneNumberError =
      e.length == 0 || e.length < 6 || !phoneNoRegEX.test(e) ? "Phone Number sholud be digites with a min 6 & max 10" : ""
    if (formErrors.phoneNumberError.length === 0) {


      let mobilePhone = e
      CustomerListService.getCustomer(e, localStorage.userId)
        .then(response => {
          this.setState({
            customerId: response.data
          });
        })
        .catch(e => {
        });

      this.getCustomerBankInfo(mobilePhone)
    }

    this.setState({
      formErrors
    })


  }


  getCustomerBankInfo(mobileNo) {
    this.state.customerId = undefined
    this.state.bankInfoId = undefined
    this.state.customerBank = undefined
    CustomerListService.getCustomerBankInfoByMobile(mobileNo, LOCALES.DEFAULT_CUSTOMER, localStorage.userId)   // 1 indicaties defult bank information
      .then(response => {
        this.setState({
          customerBank: response.data,
          customerId: response.data.customerId,
          bankInfoId: response.data.bankInfoId
        });
        
      })
      .catch(e => {
        
      });

  }


  handleSubmit = e => {
    e.preventDefault();
    const { searchValue, amount, cashMethodId, formErrors } = this.state
    formErrors.customerNotFoundError = searchValue == "" || searchValue == undefined || searchValue == null ? "Phone number is Required" : this.state.formErrors.customerNotFoundError;
    formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
    formErrors.cashMethodError = cashMethodId == '' || cashMethodId == null || cashMethodId == undefined ? 'You must make a selection' : this.state.formErrors.cashMethodError;

    if (formValid(this.state.formErrors)) {
      if (this.state.balanceInfo >= this.state.amount) {
        debugger
        const formData = new FormData();
        formData.append("amount", this.state.amount);
        formData.append("cashMethodId", this.state.cashMethodId)
        formData.append("cashTypeId", this.state.cashTypeId)
        formData.append("senderCustomerId", localStorage.customerIdentity)
        formData.append("description", this.state.description)
        formData.append("receiverCustomerId", searchValue.value )
        formData.append("userId", localStorage.userId)

        this.setState({ isLoading: true})  
        CashInfoService.createCashOutWithFile(formData)
          .then(
            (res) => {
              NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
              this.setState({ isLoading: false})  
              e.target.reset();
              
            },
            (err) => {
              NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
              this.setState({ isLoading: false})  
            })
          .catch(function (response) {
             NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
             this.setState({ isLoading: false})  
          });
      } else {
        NotificationManager.info(`${process.env.REACT_APP_CHECK_BALANCE}`, `${process.env.REACT_APP_MSG_TITLE_INFO}`, 3000);
        this.setState({ isLoading: false})  
      }
      this.setState({
        formErrors
      })
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  
  cancel() {
    this.props.history.push('/cashOutList')
  }

  selectVaue(e) {
    const { formErrors } = this.state
    let selectedBankInfoId = e.target.value
    this.state.cashMethodId = ''
    if (selectedBankInfoId == "----please select---")
      selectedBankInfoId = ''

    formErrors.cashMethodError = selectedBankInfoId == 0 ? 'You must make a selection' : "";
    this.setState({
      cashMethodId: selectedBankInfoId,
      formErrors
    });
  }

  handelChange = e => {
    e.preventDefault()
    const { customerId } = this.state
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'amount':
        formErrors.amountError =
          value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 3 & max 50" : ""
        break;
      // case 'phoneNumber':
      //   formErrors.phoneNumberError =
      //     value.length == 0 || value.length < 10 || !phoneNoRegEX.test(value) ? name + " sholud be digites with a min 1 & max 10" : ""
      //   if (formErrors.phoneNumberError.length == 0) {
      //     this.onChangeReceiverMobileNo(value)
      //     formErrors.customerNotFoundError =
      //       customerId == null || customerId == undefined ?
      //         "With this " + name + " there is no registered customer " : ""
      //   }
      //   break;
      case 'description':
        formErrors.descriptionError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;
    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/"+ localStorage.userId)    
      .then((response) =>               
         response.data
      )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
              
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }


  onSearchChange = (searchValue) => {
    const { formErrors } = this.state

    formErrors.customerNotFoundError =
       customerRegEx.test(searchValue) ? " Please insert customer Name or account no or mobile number" : ""

   if (searchValue) {
    CustomerListService.getBalance(searchValue.value, localStorage.userId)
    .then(response => {
      this.setState({
        balanceInfo: response.data
      });
    })
    .catch(e => {

    });

      this.setState({
        searchValue,
        formErrors
      });
    }
  }


  render() {

    const
      {
        cashMethod, balanceInfo, customerId,
        customerBank, bankInfoId, formErrors
      } = this.state

    let cashMethodList = cashMethod.length > 0
      && cashMethod.map((item, i) => {
        return (

          <option key={ i } value={ item.id }>{ item.name }</option>
        )
      }, this);


    return (

      <div >
        {/* <Header />
        <Menu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash Out Information</li>

                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Cash Out Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit } noValidate>

                      {/*<h2>Register</h2> */ }
                      <div className=" form-group col-12 col-sm-12 col-md-8 col-lg-8 ">
                        <label htmlFor='cashMethodList'>Cash Method:</label>
                        <select className="form-control "
                          value={ this.state.cashMethodId && this.state.cashMethodId.id }
                          onChange={ e => this.selectVaue(e) } id='cashMethodList'>
                          <option value={ null }>----please select---</option>
                          { cashMethodList }
                        </select>
                        { formErrors.cashMethodError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.cashMethodError }</span>
                        ) }
                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor='CustomerList'>Customer:</label>
                        <div style={{ fontSize: 'smaller' }}>
                          <AsyncSelect id='CustomerList' 
                            placeholder={"Search by name, account no or mobile number"}
                          
                            isClearable={true}
                            loadOptions={this.fatchData}
                            isLoading={this.state.searchValue === {} &&
                              this.state.searchValue === undefined &&
                              this.state.searchValue === null ? true : false
                            }
                         
                            onChange={(e) => {
                              this.onSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                          { formErrors.customerNotFoundError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.customerNotFoundError }</span>
                        ) }

                      </div>
                      </div>
                    
                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Balance'>Balance:</label>
                              <input type="text" className="form-control " id='Balance'
                                placeholder="Balance" value={ balanceInfo } readOnly />
                            </div>
                          
                        
                      
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Amount'>Amount:</label>
                        <input type="text" className="form-control" id='Amount'
                          onChange={ this.handelChange } onBlur={ this.handelChange } name='amount'
                          placeholder="Amount" />
                        { formErrors.amountError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                        ) }
                      </div>



                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Description'>Description:</label>
                        <textarea type="" className="form-control" id='Description' name='description'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Description" />
                        { formErrors.descriptionError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.descriptionError }</span>
                        ) }
                      </div>



                      <div className="row  d-flex justify-content-left ">
                        <button type="submit" disabled={this.state.isLoading} className="btn btn-success px-4 py-2 "
                          style={ { marginLeft: "30px" } }>Cash out</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              <NotificationContainer />
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}

export default AddCashOut;
