import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import TargetCommissionService from '../../../services/etopup/TargetCommissionService';
import ProviderService from '../../../services/etopup/ProviderService';
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";

class EditTargetCommission extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            TargetCommissionId: this.props.location.state.TargetCommissionId,
            isLoading: false,
            totalPurchase: '',
            commission: '',
            commissionAmount: '',
            selectedFile: undefined,
            attachment: '',
            TargetCommissionData: {
                id: '',
                providerId: '',
                amount: ''

            },
            providersList: [],
        };
        this.onChangeProviderId = this.onChangeProviderId.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
    
    }
 
    onDrop(files) {
        if (files.length > 0) {
            this.setState({ selectedFile: files });
            this.setState({ attachment: this.state.selectedFile[ 0 ] });
        }
    }
    handleChange(value) {
        this.setState({ month: value });
    }

    onChangeProviderId(e) {
        const providerId = e.target.value;

        this.setState(function (prevState) {
            return {
                TargetCommissionData: {
                    ...prevState.TargetCommissionData,
                    providerId: providerId
                }
            };
        });
    }
    onChangeAmount(e) {
        const amount = e.target.value;

        this.setState(function (prevState) {
            return {
                TargetCommissionData: {
                    ...prevState.TargetCommissionData,
                    amount: amount
                }
            };
        });
    }
   
    Cancel = (e) => {
        this.props.history.push('/TargetCommissionList');
    }



    componentDidMount() {
        this.getTargetCommissionById(this.props.location.state.TargetCommissionId, localStorage.userId);


        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    getTargetCommissionById(purchaseOrderId) {
        TargetCommissionService.getTargetCommissionModelById(purchaseOrderId, localStorage.userId)
            .then(response => {
                this.setState({
                    //  purchaseOrderData: response.data
                    TargetCommissionData: response.data,
                    isLoading: true,
                });
              
            })
            .catch(e => {
                
                this.setState({ isLoading: true })
            });
    }
   
    handleSubmit = e => {
        e.preventDefault();
        var data = new FormData();        
        data.append("tableId", this.state.TargetCommissionData.id);
        data.append("amount", this.state.TargetCommissionData.amount);
        data.append("providerId", this.state.TargetCommissionData.providerId);
        data.append("attachment", this.state.attachment);
        data.append("userId", localStorage.userId);
  
        TargetCommissionService.updateTargetCommission(data)
            .then(
            (response) => {
                NotificationManager.success('Updated Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.Cancel()
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            });

    }


    render() {

        const { TargetCommissionData, isLoading, providersList, selectedFile } = this.state;


        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Target Commission</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Provider Name:</label>
                                                            <select value={ TargetCommissionData.providerId } 
                                                                 onChange={ this.onChangeProviderId } 
                                                                 className="form-control" required="required">
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control "  id='amount'
                                                            placeholder="Target commission" value={ TargetCommissionData.amount }
                                                            
                                                            onChange={ this.onChangeAmount } />
                                                        </div>
                                                      
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >File Attachment </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div class='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Attachment'>Attachment:</label>
                                                            <img id="Attachment" className="mx-auto d-block " 
                                                            style={ { width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' } } 
                                                            src={ "data:image/png;base64," + TargetCommissionData.attachment }></img>
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">

                                                            <Dropzone onDrop={ this.onDrop }
                                                                multiple={ false }>
                                                                { ({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div { ...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" }) } style={ { border: "thin dashed black" } }>
                                                                            <input { ...getInputProps() } />
                                                                            { selectedFile && selectedFile[ 0 ].name ? (
                                                                                <div className="selected-file border-radius-1">
                                                                                    { selectedFile && selectedFile[ 0 ].name }
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop here or click to select file"
                                                                            ) }
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">
                                                                        </aside>
                                                                    </section>
                                                                ) }
                                                            </Dropzone>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>


                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditTargetCommission);