import API from '../../../src/http-common';

class OverDraftService {
    getAllOverDraft(userId) {
        return API.get('/api/v1/getAllOverdrafts/' + userId);
    }
    getOverDraftById(id, userId)
    {
        return API.get('/api/v1/getOverDraftById/'+id + "/" + userId) ;
    }

    createOverDraft(overdraft) {
        return API.post("/api/v1/createOverDraft", overdraft);
    }
    // deleteMerchant(mobileNo) {
    //     return API.delete("/api/v1/deleteMerchant/" + mobileNo);
    // }
    // updateMerchant(merchant) {
    //     return API.put("api/v1/updateMerchant", merchant);
    // }
}
export default new OverDraftService()