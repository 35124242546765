import TopupOrderService from '../../../services/etopup/TopupOrderService';
import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { format } from "date-fns";
import NumberFormat from 'react-number-format';
import TargetCommissionService from '../../../services/etopup/TargetCommissionService';
import ReactLoading from "react-loading";
import Image from "react-image-enlarger";
//export default class ViewPartnerProviderBalance extends React.Component {

export default class ViewTargetCommission extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            zoomed: false,
            TargetCommissionId: this.props.location.state.TargetCommissionId,
            isLoading: false,
            TargetCommissionData: {
                providerId: '',
                amount: '',
                commissionRate: '',
                createdAt: '',
                attachment: ''
            }
        };
        this.zoomOut = this.zoomOut.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
    }

    componentDidMount() {
        this.getTargetCommissionById(this.props.location.state.TargetCommissionId);
    }

    getTargetCommissionById(targetCommissionId) {

        TargetCommissionService.getTargetCommissionModelById(targetCommissionId, localStorage.userId)
            .then(response => {
                this.setState({
                    TargetCommissionData: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {
                this.setState({ isLoading: true })
                
            });
    }

   
    zoomOut() {
        this.setState({ zoomed: false })
    }
    zoomIn() {
        this.setState({ zoomed: true })
    }

    render() {
        const { isLoading, zoomed, TargetCommissionData } = this.state;


        //   console.log(resultArray[0]["bankName"]);
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Target Commission </li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className='row'>
                                    <div className='col sm-12 col md-7 col lg-7'>
                                        <div className="table-responsive w-100">
                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>

                                                <tr className="subheaders bg-light">
                                                    <td colspan="2" className="text-blue py-4">Target Commission Detail</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Provider Name:</td>
                                                    <td className="text-justify">{ TargetCommissionData.providerName }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Amount:</td>
                                                    <td><NumberFormat value={ TargetCommissionData.amount } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></td>
                                                </tr>
                                               
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Created Date:</td>
                                                    <td className="text-justify">{ format(TargetCommissionData.createdAt,'yyyy-MM-dd') }</td>
                                                </tr>

                                                <tr style={ { textAlign: 'center' } }>
                                                    <Link to="/targetCommissionList" className='pl-5'>
                                                        <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                    </Link>

                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                    <div className='col sm-12 cw-50' id="img-container">
                                        <div style={ { position: "absolute" } }>
                                            {/* <ReactImageZoom { ...props } /> */ }
                                            <Image

                                                style={ { width: "100px", height: "auto", alignSelf: 'center' } }
                                                zoomed={ zoomed }
                                                src={ "data:image/png;base64," + TargetCommissionData.attachment }
                                                alt="Attachment"

                                                onClick={ () => this.zoomIn() }
                                                onRequestClose={ () => this.zoomOut() }
                                            />
                                            {/* <img id="logo" className="d-block" style={ { width: '60%', height: '50%' } } src={ "data:image/png;base64," + resultArray[ resultArray.length - 1 ][ "identityCard" ] }></img> */ }
                                        </div>

                                    </div>
                                </div>



                            </div>

                            <div className='col-2'></div>




                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>
            )
        }
    }
}