import API from '../../http-common';

class CustomersService {
    getCustomers(userId) {
        return API.get('/api/v1/allCustomers/' + userId);
    }

    getTotalCustomer(userId) {
        return API.get('/api/v1/TotalCustomer/' + userId);
    }

    getTotalMerchant(userId) {
        return API.get('/api/v1/TotalMerchant/' + userId);
    }

    getFilterdCustomerWithPagination(pageNo, pageSize, userId, customerInfo) {
        return API.put("/api/v1/getFilterdCustomerWithPagination/" + pageNo + "/" + pageSize + "/" + userId, customerInfo);
    }
    getAllCustomers(pageNo, pageSize, topupStatus) {
        return API.get("/api/v1/getAllCustomers/" + pageNo + "/" + pageSize + "/" + topupStatus);
    }
    getCustomerById(id, userId) {
        return API.get("/api/v1/getCustomerById/" + id + "/" + userId);
    }

    getMainCompanyInfo(pageNo, pageSize, userId, customerInfo) {
        return API.put("/api/v1/getMainCompanyInfo/" + pageNo + "/" + pageSize + "/" + userId, customerInfo);
    }

    createCustomer(customer) {
        return API.post('/api/v1/createCustomer', customer, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
/**
 * To display customer detail view
 * 
 * @param {*} customerId 
 * @param {*} userId 
 * @returns 
 */
    getCustomerByCustomerId(customerId ,userId) {
        return API.get('/api/v1/getCustomerByCustomerId/' + customerId  +"/"+ userId);
    }

    /**
     *  To get customer by Id for update
     * @param {*} customerId 
     * @param {*} userId 
     * @returns 
     */
    findCustomerByCustomerId(customerId, userId) {
        return API.get('/api/v1/findCustomerByCustomerId/' + customerId + "/" + userId);
    }

    getCustomer(mobileNo, userId) {
        return API.get("/api/v1/getCustomerInfo" + "/" + mobileNo + "/" + userId)
    }

    getBalance(id, userId) {
        return API.get("/api/v1/getBalance" + "/" + id + "/" + userId);
    }

    updateCustmer(updateCustomer) {
        return API.put("/api/v1/updateCustomer", updateCustomer);
    }


    getCustomerBankInfo(id, status) {
        return API.get("/api/v1/getCustomerBankInfo/" + id + "/" + status);
    }


    getCustomerBankInfoByMobile(mobileNo, status, userId) {
        return API.get("/api/v1/getCustomerBankByCustomerByMobileWithDefult/" + mobileNo + "/" + status + "/" + userId);
    }


    deleteCustomer(mobileNo, userId) {
        return API.delete("/api/v1/deleteCustomerByMobileNo/" + mobileNo + "/" + userId);
    }

    getCustomersForCBI(userId) {
        return API.get("/api/v1/getCustomers/" + userId)
    }

    generateQRCode(customerId) {
        return API.get("/api/v1/generateQRCode/" + customerId)
    }

    generateQRCodeByByte(customerId) {
        return API.get("/api/v1/generateQRCodeByByte/" + customerId)
    }
    

    getCustomersForCBI(inputValue, userId) {
        return API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + userId)
    }

    findCustomersByCustomerType(userId) {
        return API.get("/api/v1/findCustomersByCustomerType/" + userId)
    }

    getGoogleApiKey(userId) {
        return API.get("/api/v1/getGoogleApiKey/" + userId)
    }

    AdditionalMobileNo(userId,mobileInfo){
        return API.post("/api/v1/createAdditionalMobileNo/" + userId, mobileInfo);
    }

    ResetPasswordPortal(passwordInfo){
        return API.post("/api/v1/resetPasswordPortal/" , passwordInfo);
    }

    ResetPINPortal(PINInfo){
        return API.post("/api/v1/resetPinPortal/" , PINInfo);
    }

    ChangeMobileNumber(mobileNoInfo){
        return API.post("/api/v1/changeMobileNumber/" , mobileNoInfo);
    }

}
export default new CustomersService()