import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt, faopeningBalanceCircle, faopeningBalance, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactLoading from "react-loading";
import AsyncSelect from 'react-select/async'
import Popup from '../../wallet/Popup';
import { LOCALES } from '../../common/Constant';
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import OpeningBalanceService from '../../../services/etopup/OpeningBalanceService';
import LoginService from '../../../services/common/LoginService';
import API from '../../../http-common';
import Menu from '../../wallet/Menu';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class OpeningBalanceList extends Component {

  constructor(props) {

    super(props)

    this.state = {
      isLoading: false,
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      id: '',
      openingBalanceList: [],
      OpeningBalance: null,     
      counter: 1,
      pageCounter: 1,      
      balance:null,
      isExpand: true,
      setIsOpen: false,
      isOpen: false,
      
      btnValue: "Save",
      accountStatus: false,
      onEdit: false,
      searchValue: null,
      drSelectOption: null,
      formErrors: {
        drAccountCodeError:'',
        opeingBalanceError: ''
      },
    }
    this.addopeningBalance = this.addopeningBalance.bind(this);

  }

  gotoEnteredPage = (pageNumber) => {
    this.state.pageNo = pageNumber
    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachopeningBalancefoBasedOnFilter(customerId)
    this.render()
  }

  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachopeningBalancefoBasedOnFilter(customerId)
    this.render()

  }

  gotoPageFirst = () => {
    this.state.pageNo = 1
    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachopeningBalancefoBasedOnFilter(customerId)
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
      this.seachopeningBalancefoBasedOnFilter(customerId)
      this.render()

    }
  }

  nextPage = () => {
    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
      this.seachopeningBalancefoBasedOnFilter(customerId)
      this.render()
    }
  }


  addopeningBalance() {
    this.props.history.push(`/addOpeningBalance`);
  }

  openingBalanceDetail(id) {
    this.props.history.push({pathname: "/viewOpeningBalance", state: {id: id}});
  }

  seachopeningBalancefoBasedOnFilter() {
    this.openingBalanceList = null;
    OpeningBalanceService.getOpeningBalances()
      .then(response => response.data)
      .then((data) => {
        this.setState({
          isLoading: true,
          openingBalanceList: data,
          isOpen: false,
          totalPage: data.totalPages,
          totalelement: data.totalelement,
          pageNo: data.pageNumber + 1
        });
      });
 
  }

  clear() {
    this.setState({
      id: '',
      drSelectOption: null,
      balance: '',
    })
  }

 

  componentDidMount() {
    this.seachopeningBalancefoBasedOnFilter()
  }
 


  togglePopup = (id) => {
    this.state.drSelectOption = null
    this.state.balance = null
    this.state.id = ''
    this.state.onEdit = false
    this.state.btnValue = "Save"
    if (id != null || id != undefined) {
      this.state.btnValue = "update"
      this.state.onEdit = true
      OpeningBalanceService.getOpeningBalanceById(id)
        .then(response => response.data)
        .then((data) => {
          this.setState({
            OpeningBalance: data
          });
        });


    }
    if (this.state.id == '' || this.state.id == null || this.state.id == undefined) {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }


  handelChange = e => {
    e.preventDefault();
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'balance':
        formErrors.opeingBalanceError =
          value.length == 0 || !amountRegEx.test(value) ? " Opening balance value sholud only digites with a min 1 and max 10 " : ""
        break;
      default:
        break;
    }


    this.setState({
      formErrors, [name]: value
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    const { balance, drSelectOption, formErrors } = this.state
    formErrors.opeingBalanceError = balance == '' || balance == null || balance.length <= 0 ? "Opening Balance is Required" : this.state.formErrors.opeingBalanceError;
    formErrors.drAccountCodeError = drSelectOption == '' || drSelectOption == null ? "Ledger acoount is Required" : this.state.formErrors.drAccountCodeError;


    if (formValid(this.state.formErrors)) {      
      let newopeningBalanceInfo = {
      id: this.state.id,
      ledgerAccountId: this.state.drSelectOption && this.state.drSelectOption.value,
      balance : this.state.balance
      }
      OpeningBalanceService.createOpeningBalance(newopeningBalanceInfo)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.seachopeningBalancefoBasedOnFilter()

          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        });

      this.state.id ? this.state.isOpen = true : this.state.isOpen = false
      this.setState({
        isOpen: !this.state.isOpen,
        
      })
    }

    this.setState({
      formErrors
    })

    
  }

  searchData = e => {
    this.seachopeningBalancefoBasedOnFilter()

  }

 
  clearValue = e => {
    this.state.drSelectOption = null
    this.seachopeningBalancefoBasedOnFilter()
  }

  drFatchData = (inputValue, callback) => {
    if (!inputValue) {
      callback({})
    } else {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
            });
            callback(temp)
          })
          .catch((error) => {
          })
      })
    }
  }

  onDrSearchChange = (drSelectOption) => {
    this.setState({
      drSelectOption: drSelectOption
    });

  }

  render() {
    const { openingBalanceList, onEdit, OpeningBalance, formErrors, pageNo, totalPage, pageSize, isLoading } = this.state;
    this.state.counter = 1
    // pageNo == 1 ? this.state.counter = pageNo : this.state.counter = (pageSize * (pageNo - 1)) + 1

    if (onEdit && OpeningBalance != null && OpeningBalance != undefined && OpeningBalance != '') {

      this.state.id = OpeningBalance.id
      this.state.drSelectOption = {
        label: OpeningBalance.ledgerAccountId.accountCode + " - " + OpeningBalance.ledgerAccountId.name,
        value: OpeningBalance.ledgerAccountId.id
      } 
      this.state.balance = OpeningBalance.balance
      this.state.onEdit = false
    }
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu/> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >Opening Balance Info List</li>
                    </ol>
                  </div>

                </div>
                <div className="card" >
                  <div className="card-body">
                    <div className="form-title" >Display Opening Balance Information</div>
                    <button title={"New openingBalance"} type={"button"}
                      style={{ marginLeft: "2px" }}
                      onClick={() => this.togglePopup(null)}
                      className="btn btn-sm btn-success primary m-1  float-left">
                      <i className="fas fa-plus" /> Add
                    </button>
                    <table className=" table table-striped table-birdered" style={{ fontSize: 'smaller' }} >
                      <thead className=' thead-light'>
                        <tr style={{ marginTop: 0 }}>
                          <th>No.</th>
                          <th>Account</th>
                          <th>Opening balance</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className='content-wrapper '>

                        {openingBalanceList && openingBalanceList.map(openingBalance =>

                          <tr key={openingBalance.id}>
                            <td className='p-1 '>{this.state.counter++}</td>
                            <td className='p-1 '>{openingBalance.ledgerAccountId.accountCode + " " + openingBalance.ledgerAccountId.name}</td>
                            <td className='p-1 '>{openingBalance.balance}</td>
                            <td className='p-1'>


                              <button title={"Edit"}
                                style={{ marginLeft: "2px" }}
                                onClick={() => this.togglePopup(openingBalance.id)}
                                className="btn btn-warning btn-sm">
                                <i className="nav-icon fas fa-edit fa-sm" />
                              </button>
                            </td>
                          </tr>


                        )}
                      </tbody>
                    </table>

                    <div className="clearfix">&nbsp;</div>
                    {/* <div className=" float-sm-center">
                      <span>
                        page{''}
                        <strong>
                          {pageNo} of {totalPage}
                        </strong>
                        {''}
                      </span>
                      <span>
                        | Go To Page : {''}
                        <input type='number' defaultValue={pageNo}
                          onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          }} />
                      </span>
                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackward} />
                      </button>

                      <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " disabled={'true'} value={pageNo} />

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                        <FontAwesomeIcon icon={faForward} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                        <FontAwesomeIcon icon={faForwardFast} />
                      </button>
                    </div> */}
                    {this.state.isOpen && <Popup
                      content={<>
                        <form className='' onSubmit={this.handleSubmit} noValidate>
                          <div className="card">
                            <div className="card-body" >
                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-12">
                                <label htmlFor='CustomerList'>Ledger Account:</label>
                                <div style={{ fontSize: 'smaller' }}>
                                  <AsyncSelect id='drAccount'
                                    placeholder={"Search by account code"}
                                    value={this.state.drSelectOption}
                                    isClearable={true}
                                    loadOptions={this.drFatchData}
                                    isLoading={this.state.drSelectOption === {} &&
                                      this.state.drSelectOption === undefined &&
                                      this.state.drSelectOption === null ? true : false
                                    }
                                    onChange={(e) => {
                                      this.onDrSearchChange(e)
                                    }}
                                    defaultOptions={false}
                                  />
                                  {formErrors.drAccountCodeError.length > 0 && (
                                    <span style={{ color: "#F61C04" }}>{formErrors.drAccountCodeError}</span>
                                  )}

                                </div>
                              </div>


                              <div className=" form-group col-12 col-sm-6 col-md-6 col-lg-12">
                                <label htmlFor='balance'>Opening balance:</label>
                                <input type="text" className="form-control " id='balance'
                                  name='balance' value={this.state.balance}
                                  onChange={this.handelChange}
                                  onBlur={this.handelChange} />

                                {formErrors.opeingBalanceError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.opeingBalanceError}</span>
                                )}
                              </div>






                              <div className="row  d-flex justify-content-left ">
                                <button type="submit" style={{ marginLeft: "20px" }}
                                  className="btn btn-success px-4 py-1">{this.state.btnValue}</button>
                                <button type="submit" onClick={() => this.togglePopup()}
                                  className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>}
                    />}
                  </div>
                  <NotificationContainer />
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>


            {/* /.content */}
          </div>
        </div>
      )
    }
  }
}
export default OpeningBalanceList