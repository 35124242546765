import API from '../../http-common';

class TopupSalesHistoryService {
    getAllTopupSalesHistory(pageNo, pageSize, userId, serachParams) {
        return API.put('/api/v1/getAllTopupSalesHistory/' + pageNo + "/" + pageSize + "/" + userId, serachParams);
    }
    getProvidersLogHistory(pageNo, pageSize, userId, serachParams) {
        return API.put('/api/v1/getProvidersLogHistory/' + pageNo + "/" + pageSize + "/" + userId, serachParams);
    }
    
    getProvidersMonthlySales(userId) {
        return API.get('/api/v1/getProvidersMonthlySales/' + userId);
    }

    getProvidersDailySales(userId) {
        return API.get('/api/v1/getProvidersDailySales/' + userId);
    }

    
    sumOfProvidersMonthlySales(userId) {
        return API.get('/api/v1/sumOfProvidersMonthlySales/' + userId);
    }

    sumOfProvidersDailySales(userId) {
        return API.get('/api/v1/sumOfProvidersDailySales/' + userId);
    }

    dailyTopupTrxns(date, userId) {
        return API.get('/api/v1/dailyTopupTrxns/' + date + "/" + userId);
    }

    reRunFailedRechared(salesHistoryId, userId){
        return API.get('/api/v1/reRunFailedRechared/' + salesHistoryId + "/" + userId);
    }

}
export default new TopupSalesHistoryService()