import API from '../../http-common';

class KioskCustomerAssignmentService {

    getKioskAssignmentByKioskId(kioskId, userId) {
        return API.get('/api/v1/getKioskBalanceByKioskId/' + kioskId + '/' + userId);
    }
    getKioskAssignmentByCustomerId(customerId, userId) {
        return API.get('/api/v1/getKioskBalanceByCustomerId/' + customerId + '/' + userId);
    }
    createKioskCustomerAssignment(kioskCustomerData) {
        return API.post('/api/v1/createKioskCustomerBalance', kioskCustomerData);
    }
    removeKioskAssignmenet(kioskId, userId) {
        return API.delete('/api/v1/removeKioskAssignmenet/' + kioskId + '/' + userId);
    }

    getKioskCashAssignmentByKioskId(kioskId, userId) {
        return API.get('/api/v1/getKioskCashAssignmentByKioskId/' + kioskId + '/' + userId);
    }

    findAssignedKiosks(pageNo, pageSize, userId, SearchParam) {
        return API.put('/api/v1/findAssignedKiosks/' + pageNo + '/' + pageSize + '/' + userId, SearchParam);
    }
    findKioskAssignmentByCashCollectorId(cashCollectorId,pageNo, pageSize, userId) {
        return API.get('/api/v1/findKioskAssignmentByCashCollectorId/' + cashCollectorId + '/'+ pageNo + '/' + pageSize + '/' + userId);
    }
}
export default new KioskCustomerAssignmentService()