import React, { Component } from 'react'
import { Router } from 'react-router';
import Header from './Header'
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';
import '../common/ConnectionLost.css'
import Menu from '../wallet/Menu';
import TopupMenu from '../etopup/TopupMenu';

const history = createBrowserHistory();
export default class ConnectionLost extends Component {

    render() {
        return (
            <>
            {/* <Header/> */}
            {/* {localStorage.walletMenu  == "true" ?
            <Menu/>
            :
            <TopupMenu/>
           } */}
            <div className='content-wrapper'>
                {/* <Menus/> */}
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-5 col-lg-5'></div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                            {/* <h2>This site can’t be reached </h2>
              <p>Check if there is a typo in browser or </p> 
              <p>Check if you have permmision to access this page</p>
              <button type="submit" title={ "Reload" } className="btn btn-sm btn-info " onClick={() => history.goBack()}> Back </button> */}
                            <h1>Not Found!</h1>
                            {/* <div >
                                <span>4</span>
                                <span class="circle">0</span>
                                <span>4</span>
                            </div> */}
                            <p>We are unable to find the page<br />you're looking for. Please try again</p>
                            <div>
                                <p>Go  <a style={{color: 'blue', cursor: 'pointer'}} onClick={() => history.goBack()}>Back</a> </p>
                            </div>

                        </div>


                    </div>


                </div>
                <div className='row' style={{ margin: "50px" }}></div>
            </div>
            </>

        )
    }
}
