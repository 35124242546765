import React,{Component} from 'react'
import { Link } from "react-router-dom";
import Header from '../../common/Header';
import Menu from '../TopupMenu';
export default class RetailerList extends Component {

    state = {
	    agentList: [],
      counter:1
	  };
   async componentDidMount() {
    const agentsResponse = await fetch('/api/v1/getAllRetailers');
    const agents = await agentsResponse.json();
    this.setState({ agentList: agents});
    }
          

  render(){
  const {agentList}=this.state;
     return (
  <div>
    {/* <Header/>
    <Menu /> */}
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper bg-white">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
        
	          <div className="col-sm-6">
	            <ol className="breadcrumb float-sm-left">
	              <li className="breadcrumb-item">
	                <a href="/#">Home</a>
	              </li>
	              <li className="breadcrumb-item active">Retailer List</li>
	            </ol>
	          </div>

         </div>

	    <h3 className="m-0 float-left" style={{ fontFamily: 'normal'}}>Retailers</h3>

      <Link to={{ pathname: '/addCustomer', state: { type: "Retailer",formName:"Retailer Registration"} }}>
			            <button type="button" className="btn btn-sm btn-success float-right mb-2">
		                  <i className="fas fa-plus" /> Add
		                </button>
	     </Link>
       <table className="table table-hover">
        <thead className='thead-light'> 
          <tr>
            <th>Full Name</th>
            <th>Mobile Number</th>
            <th>Location</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {agentList.map(agent =>
	          <tr>
	            <td>{agent.fullName}</td>
	            <td>{agent.mobileNo}</td>
	            <td>{agent.city}</td>
	            <td>{agent.type}</td>
                <td>
                    <button onClick={ () => this.viewEmployee(agent.id)} className="btn-info"><i className="nav-icon fa fa-eye" /> </button>
                    <button onClick={ () => this.editEmployee(agent.id)} className="btn-warning"><i className="nav-icon fas fa-edit" /> </button>
                    <button onClick={ () => this.deleteEmployee(agent.id)} className="btn-danger"><i className="nav-icon fa fa-trash" /> </button>
                </td>
	          </tr>
          )}
        </tbody>
      </table>
 
       
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </div>
    
    {/* /.content */}
  </div>
</div>

     )
  }
  }