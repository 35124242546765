import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import KioskTrxnHistoryService from '../../../services/wallet/KioskTrxnHistoryService';
import ProviderService from '../../../services/etopup/ProviderService';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class KioskTrxnHistory extends Component {

    constructor (props) {

        super(props)
        this.state = {
            kId: this.props.match.params.kId,
            searchKioskId: this.props.match.params.searchKioskId,
            isLoading: false,

            kioskId: null,
            providerId: null,
            receiverMobileNo: null,
            receiverAccountNo:null,
            billType:null,
            billPeriod:null,
            trxnType:null,
            startDate: null,
            endDate: null,

            
            kioskAccounNoSelectOption: null,
            kioskBillPeriodSelectOption: null,
            kioskTrxnHistoryList: [],  
            providersList: [],
            allProviders:[],

            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            formErrors: {
                kioskIdError: "",
                providerIdError: "",
                mobileNumebrError: "",
                startDateError: "",
                endDateError: ""
            },

        }
        this.clearValue = this.clearValue.bind(this);

    }



    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.searchKioskSalesHistoryBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchKioskSalesHistoryBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchKioskSalesHistoryBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchKioskSalesHistoryBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.searchKioskSalesHistoryBasedOnFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.kioskId = null
        this.state.providerId = null
        this.state.receiverMobileNo = null
        this.state.receiverAccountNo=null
        this.state.billPeriod=null
        this.state.billType=null
        this.state.startDate = null
        this.state.endDate = null
        // this.state.trxnType=null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.searchKioskSalesHistoryBasedOnFilter()
        }
    } 
    fetchKisokTrxnBillPeriod = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getKioskTrxnHistoryBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.billPeriod, value: element.billPeriod })
                        });
                        callback(temp)
                        this.setState({ 
                            billPeriodList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onBillPeriodChange = (kioskBillPeriodSelectOption) => {
        if (kioskBillPeriodSelectOption) {
            this.state.kioskBillPeriodSelectOption = kioskBillPeriodSelectOption
            this.setState({
                billPeriod: kioskBillPeriodSelectOption.value
            });
        }
    }

    fetchKisokAccountNo = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getKioskTrxnHistoryBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountNo, value: element.accountNo })
                        });
                        callback(temp)
                        this.setState({ 
                            accountList: data,

                        })
                    })
                    .catch((error) => {
                 
                    })
            })
        }
    }

    onAccountNoChange = (kioskAccounNoSelectOption) => {
        if (kioskAccounNoSelectOption) {
            this.state.kioskAccounNoSelectOption = kioskAccounNoSelectOption
            this.setState({
                receiverAccountNo: kioskAccounNoSelectOption.value
            });
        }
    }
    componentDidMount() {
        this.searchKioskSalesHistoryBasedOnFilter(this.state.kId)
        ProviderService.getAllProviders(localStorage.userId)
        .then(response => {
            this.setState({    
                allProviders: response.data
            });
        })
        .catch(e => {
            
        });
        
        this.setState({
            searchKioskId : this.props.location.state.searchKioskId,
            kId:this.props.location.state.kId
        })
       
    }

    searchKioskInfo(e) {
        this.searchKioskSalesHistoryBasedOnFilter(this.props.location.state.kId)
       
        
    }

    searchKioskSalesHistoryBasedOnFilter(kId) {

        if (this.state.startDate != null && this.state.endDate == null) {
            this.setState({ endDate: moment().format("yyyy-MM-DD") });
        }
        else if (this.state.startDate == null && this.state.endDate != null) {
            NotificationManager.error("Please enter the start date",`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {
      
            if (this.state.kioskId == null) {
                this.state.kioskId = this.props.location.state.kId
            }
            
            if (this.state.billType == "AllBillTypes") {
                this.state.billType = null
            }
            if (this.state.trxnType == "AllTrxns") {
                this.state.trxnType = null
            }
          
            let kioskTrxnSearchParams = {

                kioskId: this.state.kioskId,
                providerId: this.state.providerId,
                receiverMobileNo: this.state.receiverMobileNo,
                receiverAccountNo:this.state.receiverAccountNo,
                billPeriod:this.state.billPeriod,
                billType:this.state.billType,
                trxnType:this.state.trxnType,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }

           

            KioskTrxnHistoryService.getAllKioskTrxnHistory(
                this.state.pageNo, this.state.pageSize, localStorage.userId, kioskTrxnSearchParams)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        isLoading: true,
                        kioskTrxnHistoryList: data.content,
                        isOpen: false,
                        totalPage: data.totalPages,
                        totalelement: data.pageable.totalelement,
                        pageNo: data.pageable.pageNumber + 1
                    });
                   
                this.setState({ isLoading: true })
                });
        }
    }
  
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }



    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.receiverMobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }
        this.setState({
            formErrors
        })
    }

    render() {
        const { searchKioskId,allProviders, kioskTrxnHistoryList, isLoading, pageNo, totalPage, providersList, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
        
        // for (const obj of this.state.kioskTrxnHistoryList) {
        //     console.log(obj.providerName)
        // }
        //   console.log(this.state.kioskTrxnHistoryList)
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Kiosk Transaction History</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={ { fontFamily: 'normal' } }>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                    { this.state.trxnType==null &&
                                        <div className="form-title">Kiosk History  </div>
                                    }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` &&
                                        <div className="form-title">top Up History  </div>
                                    }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` &&
                                        <div className="form-title">cash In History </div>
                                     }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` &&
                                        <div className="form-title">pay Bill History </div>
                                     }
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` &&
                                            <div className="row" hidden={ this.state.isExpand }>
                            
                                                <div className="col-md-4">
                                                    <label htmlFor='receiverMobileNo'>Mobile No</label>
                                                    <PhoneInput countryCodeEditable={ false } value={ this.state.receiverMobileNo }
                                                        country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                        placeholder="Enter phone number" id='mobileNo'
                                                        inputStyle={ { width: '100%', height: 'auto' } }
                                                        onChange={ phone => this.onChangeMobileNo(phone) } />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='startDate' >From:</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='startDate'
                                                            onChange={ e => this.state.startDate = e.target.value } />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='endDate' >To:</label>
                                                    <div className="w-90 pull-left">

                                                        <input type="date" className="form-control" id='endDate'
                                                            onChange={ e => this.state.endDate = e.target.value } />
                                                    </div>
                                                </div>
                                            </div>
                                           }
                                           { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` &&
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">
                                                    <label htmlFor='receiverAccountNo'>AccountNo</label>
                                                    <AsyncSelect id='accountList'
                                                            required
                                                            placeholder={ "Search by account no" }
                                                            value={ this.state.kioskAccounNoSelectOption } 
                                                            isClearable={ true } 
                                                            loadOptions={ this.fetchKisokAccountNo } kioskAccounNoSelectOption
                                                            isLoading={ this.state.kioskAccounNoSelectOption === {} &&
                                                                this.state.kioskAccounNoSelectOption === undefined &&  
                                                                this.state.kioskAccounNoSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onAccountNoChange(e)  
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='startDate' >From:</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='startDate'
                                                            onChange={ e => this.state.startDate = e.target.value } />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='endDate' >To:</label>
                                                    <div className="w-90 pull-left">

                                                        <input type="date" className="form-control" id='endDate'
                                                            onChange={ e => this.state.endDate = e.target.value } />
                                                    </div>
                                                </div>
                                            </div>
                                           }
                                { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` &&
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-3">
                                                    <div>
                                                        <label htmlFor='accountNo' > AccountNo</label>
                                                        <AsyncSelect id='accountList'
                                                            required
                                                            placeholder={ "Search by account " }
                                                            value={ this.state.kioskAccounNoSelectOption } 
                                                            isClearable={ true } 
                                                            loadOptions={ this.fetchKisokAccountNo } kioskAccounNoSelectOption
                                                            isLoading={ this.state.kioskAccounNoSelectOption === {} &&
                                                                this.state.kioskAccounNoSelectOption === undefined &&  
                                                                this.state.kioskAccounNoSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onAccountNoChange(e)  
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='billType'>Bill Type</label>
                                                    <select onChange={ e => this.state.billType = e.target.value }  className="form-control" name='billType'>
                                                        <option value={ "AllBillTypes"}>Select bill Type</option>
                                                        <option value={ `${process.env.REACT_APP_BILLTYPE_WATER}` }>{ `${process.env.REACT_APP_BILLTYPE_WATER}` }</option>
                                                        <option value={ `${process.env.REACT_APP_BILLTYPE_ELECTRICITY}` }>{ `${process.env.REACT_APP_BILLTYPE_ELECTRICITY}` }</option>
                                                    </select>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <label htmlFor='billPeriods'>Bill Period</label>
                                                    <AsyncSelect id='billPeriodList'
                                                            required
                                                            placeholder={ "Search by bill period" }
                                                            value={ this.state.kioskBillPeriodSelectOption } 
                                                            isClearable={ true } 
                                                            loadOptions={ this.fetchKisokTrxnBillPeriod } kioskBillPeriodSelectOption
                                                            isLoading={ this.state.kioskBillPeriodSelectOption === {} &&
                                                                this.state.kioskBillPeriodSelectOption === undefined &&  
                                                                this.state.kioskBillPeriodSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onBillPeriodChange(e)  
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                </div> */}
                                                <div className="col-md-3">
                                                    <label htmlFor='startDate' >From:</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='startDate'
                                                            onChange={ e => this.state.startDate = e.target.value } />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='endDate' >To:</label>
                                                    <div className="w-90 pull-left">

                                                        <input type="date" className="form-control" id='endDate'
                                                            onChange={ e => this.state.endDate = e.target.value } />
                                                    </div>
                                                </div>
                                            </div>
                                           }
                                            <br />
                                           { this.state.trxnType!==null &&
                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchKioskInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                            }
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/kioskCustomerBalanceList', state: { type: "Agent", formName: "order Registration" } } }>
                                        <button type="button" className="btn btn-md btn-success float-left mb-2" onClick={ this.addCustomer } style={ { height: '33px' } } title="Back to Balance">
                                            <i className="fa fa-arrow-circle-left" />
                                        </button>
                                        </Link>
                                        { this.state.trxnType!==null &&
                                                <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                                    className="btn btn-sm btn-success primary float-left mb-2"
                                                    onClick={ () => this.filterToggle() }>
                                                    <FontAwesomeIcon icon={ faFilter } />
                                                    Filter
                                                </button>
                                         }
                                        <label className='float-right light ml-3'> <span style={ { fontWeight: "lighter" } }> Kiosk Id :</span><span style={ { fontWeight: "bolder" } }>{ searchKioskId }</span></label>
                                        <div className='row  no-gutters float-right'>
                                        
                                       <div className='col-4 sm-12 lg-4'> <label><span style={ { fontWeight: "lighter" } }>Trxn Type:</span></label> </div>
                                       <div className='col-8 sm-12 lg-8'>
                                        <select  onClick={ () => this.searchKioskInfo() } onChange={ e => this.state.trxnType = e.target.value }  className="form-control" name='trxnType' style={{ height:"95%",width:"auto"}}>
                                            <option value={ "AllTrxns"}>Select Trxn Type</option>
                                            <option value={ `${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` }>{ `${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` }</option>
                                            <option value={ `${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` }>{ `${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` }</option>
                                            <option value={ `${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` }>{ `${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` }</option>
                                        </select>
                                        </div>
                                    </div>
                                {  this.state.trxnType===null  &&
                                
                                        <table className="table table-striped table-birdered pt-2" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Kiosk ID</th> */ }
                                                    <th>Acount</th>
                                                    <th>Amount</th>
                                                    <th>Transaction Ref.</th>
                                                    <th>Transaction Type</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { kioskTrxnHistoryList !== undefined && kioskTrxnHistoryList.map(list =>

                                                    <tr>
                                                         
                                            {list.mobileNo &&
                                                <td className='p-1 '>{ list.mobileNo }  </td>
                                                }
                                                  {list.accountNo &&
                                                <td className='p-1 '>{ list.accountNo }</td>
                                                }
                                                          <td className='p-1 '>
                                                             <NumberFormat value={ list.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                                        </td>
                                                        <td className='p-1 '>{ list.senderTrxnRefId.trxnRefId }</td> 
                                                        <td className='p-1 '>{ list.trxnType }</td> 
                                                       
                                                      
                                                        <td className='p-1 '>{ moment(list.createdAt).format('yyyy-MM-DD hh:mm')  }</td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    }
                         { this.state.trxnType===`${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` &&
                                    <table className="table table-striped table-birdered pt-2" >
                                    <thead className='thead-light'>
                                        <tr style={ { marginTop: 0 } }>
                                            {/* <th>Provider Name</th> */}
                                            <th>Mobile Number</th> 
                                            <th>Amount</th>
                                            <th>Transaction Ref.</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        { kioskTrxnHistoryList !== undefined && kioskTrxnHistoryList.map(list =>
                                            <tr>
                                                {/* <td className='p-1 '>{  list.providerName}  </td> */}
                                                <td className='p-1 '>{ list.mobileNo }  </td>
                                                <td className='p-1 '>
                                                    <NumberFormat value={ list.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                                </td>
                                                <td className='p-1 '>{ list.senderTrxnRefId.trxnRefId }</td> 
                                                <td className='p-1 '>{ moment(list.createdAt).format('yyyy-MM-DD hh:mm')  }</td>
                                            </tr>
                                           
                                        ) }
                                    </tbody>
                                </table>
                                    }
                                     { this.state.trxnType===`${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` &&
                                     <table className="table table-striped table-birdered pt-2" >
                                     <thead className='thead-light'>
                                         <tr style={ { marginTop: 0 } }>
                                             {/* <th>Kiosk ID</th> */ }
                                             <th>Account</th>
                                             <th>Amount</th>
                                             <th>Transaction Ref.</th>
                                             <th>Date</th>
                                         </tr>
                                     </thead>
                                     <tbody>

                                         { kioskTrxnHistoryList !== undefined && kioskTrxnHistoryList.map(list =>

                                             <tr>
                                             
                                                 <td className='p-1 '>{ list.accountNo }</td>
                                                   <td className='p-1 '>
                                                      <NumberFormat value={ list.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                                 </td>
                                                 <td className='p-1 '>{ list.senderTrxnRefId.trxnRefId }</td> 
                                                 <td className='p-1 '>{ moment(list.createdAt).format('yyyy-MM-DD hh:mm')  }</td>
                                             </tr>

                                         ) }
                                     </tbody>
                                 </table>
                                    }
                                    { this.state.trxnType===`${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` &&
                                    <table className="table table-striped table-birdered pt-2" >
                                    <thead className='thead-light'>
                                        <tr style={ { marginTop: 0 } }>
                                            {/* <th>Kiosk ID</th> */ }
                                            <th>Account</th>
                                            <th>Amount</th>
                                            <th>Transaction Ref.</th>
                                            <th>Bill Type</th>
                                            {/* <th>Bill Period</th> */}
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        { kioskTrxnHistoryList !== undefined && kioskTrxnHistoryList.map(list =>

                                            <tr>
                                                <td className='p-1 '>{ list.accountNo }</td>
                                                  <td className='p-1 '>
                                                     <NumberFormat value={ list.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                                </td>
                                                <td className='p-1 '>{ list.senderTrxnRefId.trxnRefId }</td> 
                                                <td className='p-1 '>{ list.billType }</td> 
                                                {/* <td className='p-1 '>{ list.billPeriod }</td>  */}
                                                <td className='p-1 '>{ moment(list.createdAt).format('yyyy-MM-DD hh:mm')  }</td>
                                            </tr>

                                        ) }
                                    </tbody>
                                </table>
                                    }
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >

            )
        }
    }
}
export default KioskTrxnHistory