import API from '../../../src/http-common';

class WalletTrxnService {
    getWalletTrxns(searchInput, userId) {
        return API.get('/api/v1/getAllWalletTrxns/' + userId, searchInput);
    }
    getRecentTrxns(customerId, userId) {
        return API.get('/api/v1/getRecentTrxns/'+ customerId + "/" + userId);
    }
    getFilteredWalletTrxnsWithPagination(pageNo, pageSize, userId, walletSearchInfo) {
        return API.put("/api/v1/getAllWalletTransactionsWithPagination/" + pageNo + "/" + pageSize + "/" + userId, walletSearchInfo);
    }

    walletToTopupTransfer(customerId, amount, userId) {
        return API.get('/api/v1/walletToTopupTransfer/' + customerId + '/' + amount + '/' + userId + '/');
    }

    SalesWalletToTopupTransfer(customerId, amount, userId) {
        return API.get('/api/v1/salesWalletToTopupTransfer/' + customerId + '/' + amount + '/' + userId + '/');
    }

    

    walletToWalletTransfer(transferData) {
        return API.post('/api/v1/walletToWalletTransfer', transferData);
    }

    
}
export default new WalletTrxnService()