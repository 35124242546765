import API from '../../http-common';

class OpeningBalanceService {
  
  // get all OpeningBalance Information 
  getOpeningBalances() {
    return API.get("/api/v1/getOpeningBalances");
  }
 
   // create  OpeningBalanceI nformation 
  createOpeningBalance(openingBalance) {
    return API.post("/api/v1/createOpeningBalance", openingBalance);
  }

    // create  OpeningBalance Information 
  updateOpeningBalance(openingBalance) {
    return  API.post("/api/v1/updateOpeningBalance", openingBalance);
  }

   // get single OpeningBalanceInformation 
   getOpeningBalanceById(id) {
    return API.get("/api/v1/getOpeningBalanceById/" + id);
  }  

   // remove  OpeningBalanceInformation 
  deleteOpeningBalance(id) {
    return API.delete('/api/v1/deleteOpeningBalance' + '/' + id);
  }
}
export default new OpeningBalanceService()