import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import DirectTopupService from '../../../services/etopup/DirectTopupService';
import PhoneInput from 'react-phone-input-2';
import LoadingPopup from '../../wallet/LoadingPopup';
import ReactLoading from "react-loading"; 

const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const mobileNoRegEX = RegExp(/^[0-9\b]+$/u)
const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

class DirectTopup extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            isLoading: false,
            amount: 0,
            mobileNo: null,
            providersList: [],
            formErrors: {

                amountError: "",
                mobileNoError: ""
            },
        }
        this.handleSubmit=this.handleSubmit.bind(this)
    }


    componentDidMount() {

        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });

        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId
                });
            })
            .catch(e => {
                
            });
    }
    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNo = mobile
        formErrors.mobileNoError =
            mobile.length == 0 || mobile.length < 6 || !mobileNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.mobileNoError.length === 0) {
            let mobilePhone = mobile
        }
        this.setState({
            formErrors
        })
    }
    Cancel = (e) => {
        this.props.history.push('/etopupDashboard');
    }
      handleSubmit(e) {
        e.preventDefault()
        const { amount, mobileNo, formErrors } = this.state
        // formErrors.providerIdError = this.state.providerId === "" || this.state.providerId === null || this.state.providerId === undefined ? "Provider is Required" : "";
        formErrors.amountError = this.state.amount === "" || this.state.amount === null || this.state.amount === undefined || this.state.amount === 0? "Amount is Required" : this.state.formErrors.amountError;
        formErrors.mobileNoError = this.state.mobileNo === "" || this.state.mobileNo === null || this.state.mobileNo === undefined ? "Mobile No is Required" : this.state.formErrors.mobileNoError;
        
        if (formValid(this.state.formErrors)) {

            var directTopupData = new FormData();
            directTopupData.append("senderId", localStorage.customerIdentity);
            directTopupData.append("mobileNo", this.state.mobileNo);
            directTopupData.append("amount", this.state.amount);
            directTopupData.append("userId", localStorage.userId); 
            
            this.setState({ isLoading: true})  
            DirectTopupService.directTopup(localStorage.customerIdentity, this.state.mobileNo, this.state.amount, localStorage.userId)
                .then(
                    (response)=> {
                    NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.props.history.push('/directTopupHistoryList');
                   this.setState({ isLoading: false})  
                }, 
                (err) => {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})  
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                  //  this.setState({ isLoading: false})  
                });
             

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };


    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target
        
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'amount':
                formErrors.amountError =!amountRegEx.test(value) ? "" + " Amount sholud only be digits." : ""
                break;
            default:
                break;
        }
        this.setState({
            formErrors, [ name ]: value
        })
    }
    render() {
        const { providersList, providerCommission, amount, formErrors } = this.state;



        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Direct Topup</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Direct Topup Form </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Provider Name:</label>
                                                            <select onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" required name='providerId'>
                                                                <option value=""> Choose provider</option>
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>

                                                        </div> */}
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Receiver Mobile No:</label>
                                                            <PhoneInput countryCodeEditable={ false } value={ this.state.mobileNo }
                                                                country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                                placeholder="Enter mobile number" id='mobileNo' name='mobileNo'
                                                                inputStyle={ { width: '100%', height: 'auto' } }
                                                                onChange={ phone => this.onChangeMobileNo(phone) }
                                                            />
                                                            { formErrors.mobileNoError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.mobileNoError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                               
                                                                onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>


                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading } type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Send</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>



                                        </form>
                                    </div>
                                </div>
                            </div>

{this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(DirectTopup);
