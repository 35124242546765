import TopupOrderService from '../../../services/etopup/TopupOrderService';
import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { format } from "date-fns";
import NumberFormat from 'react-number-format';
import PartnerTargetService from '../../../services/etopup/PartnerTargetService';
import ReactLoading from "react-loading";

//export default class ViewPartnerProviderBalance extends React.Component {

export default class ViewPartnerTarget extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            // partnerProviderBalanceId: this.props.match.params.partnerProviderBalanceId,
            partnerTargetId: this.props.match.params.partnerTargetId,
            isLoading: false,
            partnerTargetData: {
                providerId: '',
                target: '',
                targetAchieved: '',
                createdAt: '',
                month: ''
            }
        };
    }

    componentDidMount() {
        this.getPartnerTargetById(this.props.location.state.partnerTargetId);
    }

    getPartnerTargetById(partnerTargetId) {

        PartnerTargetService.getPartnerTargetById(partnerTargetId, localStorage.userId)
            .then(response => {
                this.setState({
                    partnerTargetData: response.data,
                    isLoading: true,
                });
                
            })
            .catch(e => {
                
            });
    }

    render() {
        const { isLoading, partnerTargetData } = this.state;


        //   console.log(resultArray[0]["bankName"]);
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Partner Target </li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">

                                        <div className="table-responsive w-50">

                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>

                                                <tr className="subheaders bg-light">
                                                    <td colspan="2" className="text-blue py-4">Partner Target Detail</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Provider Name:</td>
                                                    <td className="text-justify">{ partnerTargetData.providerId.providerName }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Month:</td>
                                                    <td className="text-justify">{ partnerTargetData.month }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Target:</td>
                                                    <NumberFormat value={ partnerTargetData.target } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Target Achieved:</td>
                                                    <td className="text-justify">{ partnerTargetData.targetAchieved }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Created Date:</td>
                                                    <td className="text-justify">{ partnerTargetData.createdAt }</td>
                                                </tr>

                                                <tr style={ { textAlign: 'center' } }>
                                                    <Link to="/partnerTargetList" className='pl-5'>
                                                        <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                    </Link>

                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='col-2'></div>




                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>
            )
        }
    }
}