import { faBackward, faBackwardFast, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AuthService from '../../../../services/common/AuthService';
//import TopupMenu from '../../../etopup/TopupMenu';
import Menu from '../../../wallet/Menu';
import { LOCALES } from '../../Constant';
import Header from '../../Header';
import ReactLoading from "react-loading";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}


class UserRoleList extends Component {
    constructor (props) {

        super(props)

        this.state = {
            id: this.props.location.state.id,
            userRoleList: [],
            roleId: null,
            roleIds: [],
            counter: 1,
            isLoading: false,
            formErrors: {
                roleIdError: ""
            },
        }


    }



    componentDidMount() {
        AuthService.getRoles()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    roleIds: data,
                    isLoading: true
                });
            });

        this.searchUserRoles()


    }


    searchUserRoles() {
        AuthService.getUserRoles(this.state.id)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    userRoleList: data
                });

            });
    }



    handleSubmit = e => {
        const { roleId, formErrors } = this.state
        formErrors.roleIdError = roleId == '' || roleId == null || roleId == undefined ? 'You must make a selection' : this.state.formErrors.roleIdError;


        if (formValid(this.state.formErrors)) {
            let userRole = {
                loginUserId: localStorage.userId,
                userId: this.state.id,
                roleId: roleId
            }

            AuthService.createUserRole(userRole)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.searchUserRoles()
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };

    async deleteUserRole(id) {
        if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
            try {
                const response = await AuthService.deleteUserRole( localStorage.userId,id);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
                this.searchUserRoles()
            } catch (error) {
                NotificationManager.error(`${process.env.REACT_APP_DELETE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
            }
        }
    }

    RoleValueChange = e => {
        const { formErrors } = this.state
        let selectedRoleId = e.target.value
        this.state.roleId = ""
        if (selectedRoleId == "----please select---")
            selectedRoleId = ""

        formErrors.roleIdError = selectedRoleId == "" ? 'You must make a selection' : "";

        this.setState({
            roleId: selectedRoleId,
            formErrors
        });
    }

    render() {
        const { userRoleList, roleIds, roleId, formErrors, isLoading } = this.state;
        this.state.counter = 1

        let roleList = roleIds.length > 0
            && roleIds.map((item, i) => {
                return (
                    <option key={ i } value={ item.id }>{ item.name }</option>
                )
            }, this);
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <NotificationContainer />
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" >UserRole List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Display UserRole Information</div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label htmlFor='RoleList'>Select Role:</label>
                                                <select className="form-control "
                                                    id='RoleList' value={ this.state.roleId }
                                                    onChange={ this.RoleValueChange }>

                                                    <option value={ null }>----please select---</option>
                                                    { roleList }
                                                </select>
                                                { formErrors.roleIdError.length > 0 && (
                                                    <span style={ { color: "#F61C04" } }>{ formErrors.roleIdError }</span>
                                                ) }

                                            </div>

                                            <button type="submit" onClick={ this.handleSubmit } className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Add</button>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                        <table className=" table table-striped table-birdered " style={ { height: '30px', fontSize: 'smaller' } } >
                                            <thead className=' thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>No.</th>
                                                    <th>RoleName</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '>

                                                { userRoleList.map(userRole =>

                                                    <tr key={ userRole.id }>
                                                        <td>{ this.state.counter++ }</td>
                                                        <td className='p-1 '>{ userRole.name }</td>
                                                        <td className='p-1 '>
                                                            <button style={ { marginLeft: "2px" } } title={ "Delete" }
                                                                onClick={ () => this.deleteUserRole(userRole.id) }
                                                                className="btn btn-danger btn-sm">
                                                                <i className="nav-icon fa fa-trash fa-sm" />
                                                            </button>
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>


                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>
                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}


export default UserRoleList;