import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../../components/wallet/Menu';
import { format } from 'date-fns';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import OverdraftSettlementService from '../../../services/common/OverdraftSettlementService';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import Header from '../Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const overdueError = RegExp(/^\d{1,10}(\.\d{1,4})?$/);

class overdraftSettlementSettlementList extends Component {

    constructor (props) {

        super(props);
        this.state = {
            balance: '',
            achievement: '',
            customerId: '',
            overdue: '',
            remark: '',
            userId: '',
            isLoading: false,
            salesWalletById: '',
            salesList: [],
            selectOption: null,
            recentTrxns: [],
            formErrors: {
                overdueError: "",
            },
        }
        this.handelInputChange = this.handelInputChange.bind(this);
    }
     
    componentDidMount() {

    }
    handelInputChange = e => {
        e.preventDefault();
        const { customerIdformErrors } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'overdue':
                formErrors.overdueError =
                    value.length == 0 || !overdueError.test(value) ? " " + "overdue must be only digits" : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getSalesTeamOnlyBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (selectOption) => {
        if (selectOption) {
            this.state.selectOption = selectOption
            this.getSalesListByCustomerId(selectOption.value)
            this.getRecentOverdraftTransactions(selectOption.value, localStorage.userId)
        }
    }

    getSalesListByCustomerId(id) {

        OverdraftSettlementService.getWalletByCustomerId(id, localStorage.userId)
            .then(response => {
                this.setState({
                    salesWalletById: response.data,
                });
                
            })
            .catch(e => {
                
            });
    }

    getRecentOverdraftTransactions(customerId, userId) {
        OverdraftSettlementService.getRecentWalletTrxnByCustomerId(customerId, userId)
            .then(response => {
                this.setState({
                    recentTrxns: response.data,
                });
                
            })
            .catch(e => {
                
            });
    }

    async deleteoverdraftSettlement(mobileNo) {
        try {
            const response = await OverdraftSettlementService.deleteOverdraftSettlement(mobileNo);
            NotificationManager.success('Deleted Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        } catch (error) {
            NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
    }
    handleSubmit = e => {

        e.preventDefault();
        if(this.state.selectOption===null)
        {
           NotificationManager.error("Please select the sales and enter the overdue", "Aelaf Smart Pay", 5000);
        }
        else if (this.state.overdue>0 && this.state.overdue>this.state.salesWalletById.balance)
        {
          NotificationManager.error("Please try again, the overdue must be less than the target", "Aelaf Smart Pay", 5000);   
        }
        else
        {
        var overdraftSettlementData = new FormData();
        overdraftSettlementData.append("customerId ", this.state.selectOption && this.state.selectOption.value);
        overdraftSettlementData.append("balance", this.state.salesWalletById.balance);
        overdraftSettlementData.append("achievement", this.state.salesWalletById.achievement);
        overdraftSettlementData.append("overdue", this.state.overdue);
        overdraftSettlementData.append("userId", localStorage.userId);

        if (this.remark) {
            overdraftSettlementData.append("remark", this.remark);
        } else {
            overdraftSettlementData.append("remark", this.state.remark);
        }

       
        this.setState({ isLoading: true})
        OverdraftSettlementService.createOvedraftSettlement(overdraftSettlementData)
            .then(response => {
                NotificationManager.success("Successfully setteled", `${process.env.REACT_APP_MSG_TITLE}`, 3000); 
             //   this.setState({ isLoading: false})
                e.target.reset();
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000); 
              //  this.setState({ isLoading: false})
            });
      }
    }

    render() {
        const { recentTrxns, salesWalletById, formErrors } = this.state;
        return (

            <div>
                {/* <Header />
                <Menu /> */}
                <NotificationContainer />
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Overdraft Settlement</li>
                                    </ol>
                                </div>

                            </div>
                            <form onSubmit={ this.handleSubmit }>
                                {/*Basic Information*/ }
                                <div className="card  border-radius-0" >
                                    <div className="card-header border-radius-0 bg-gradient">
                                        <h6 class="title " >Overdraft Settlement </h6>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>

                                            <div className=" form-group col-12 col-sm-12 col-md-10 col-lg-10">
                                                <label htmlFor='customerId'>Sales List:</label>
                                                <div style={ { fontSize: 'smaller' } }>

                                                    <AsyncSelect id='CustomerList'
                                                        className=" form-group col-12 col-sm-12 col-md-6 col-lg-6  "
                                                        placeholder={ "Search by name, account no or mobile number" }
                                                        value={ this.state.selectOption }
                                                        isClearable={ true }
                                                        loadOptions={ this.fatchData }
                                                        isLoading={ this.state.selectOption === {} &&
                                                            this.state.selectOption === undefined &&
                                                            this.state.selectOption === null ? true : false
                                                        }
                                                        onChange={ (e) => {
                                                            this.onSearchChange(e)
                                                        } }
                                                        defaultOptions={ false }
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='row'>

                                            <div className=" form-group col-12 col-sm-12 col-md-3 col-lg-3">
                                                <label htmlFor='customerId'>Full Name:</label>
                                                <input type="text" className="form-control " id='fullName'
                                                    value={ salesWalletById.fullName } onChange={ e => this.id = e.target.value }
                                                    readOnly />
                                            </div>
                                            <div className=" form-group col-12 col-sm-12 col-md-2 col-lg-2">
                                                <label htmlFor='customerId'>Target:</label>
                                                <input type="text" className="form-control " id='fullName'
                                                    value={ salesWalletById.balance } onChange={ e => this.customerId = e.target.value } readOnly required="required" />
                                            </div>
                                            <div className=" form-group col-12 col-sm-12 col-md-2 col-lg-2">
                                                <label htmlFor='customerId'>Achievement:</label>
                                                <input type="text" className="form-control " id='fullName'
                                                    value={ salesWalletById.achievement } onChange={ e => this.customerId = e.target.value } readOnly required="required" />
                                            </div>
                                            <div className=" form-group col-12 col-sm-12 col-md-2 col-lg-2">
                                                <label htmlFor='overdue'>Overdue:</label>
                                                <input type="text" className="form-control " id='overdue' name='overdue'
                                                    onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                { formErrors.overdueError.length > 0 && (
                                                    <span style={ { color: "#F61C04" } }>{ formErrors.overdueError }</span>
                                                ) }
                                            </div>

                                            <div className=" form-group col-12 col-sm-12 col-md-2 col-lg-2">
                                                <label htmlFor='customerId'>Remark:</label>
                                                <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='fullName' onChange={ e => this.remark = e.target.value } />
                                            </div>
                                            <div className=" form-group col-12 col-sm-12 col-md-1 col-lg-1 pt-2">
                                                {/* <button type="submit" class="btn btn-success mt-4 " >Settle</button> */ }
                                                <button className="btn btn-success btn-md mt-4"><i class="fas fa-check-circle" title="Settle" /> </button>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </form>

                            <div className="small-box">
                                <div className="inner card-header bg-gradient">
                                    <i className="fas fa-arrow-circle-right" /> Recent Overdraft Transactions

                                </div>
                                <table className="table border-0" >
                                    {/* <thead className='thead-light'> */ }
                                    <tr style={ { padding: 0 } }>
                                        <th>Full Name</th>
                                        <th>Amount</th>
                                        <th>remark</th>
                                        <th>Date</th>
                                    </tr>
                                    {/* </thead> */ }
                                    <tbody>

                                        { recentTrxns.map(trxns =>

                                            <tr className='table-tr'>
                                                <td className='p-0 '>{ trxns.fullName }</td>
                                                <td className='p-0 '>

                                                    <NumberFormat value={ trxns.balance + ".00" } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                </td>
                                                <td className='p-0 '>{ trxns.remark }</td>
                                                <td className='p-0 '>{ format(trxns.createdAt, 'yyyy-MM-dd HH:mm') }</td>

                                            </tr>

                                        ) }
                                    </tbody>
                                </table>
                            </div>

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}
export default overdraftSettlementSettlementList