import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BankInfoService from '../../../services/common/BankInfoService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";

class BankInfoList extends Component {
    constructor (props) {

        super(props)

        this.state = {
            isLoading: false,
            bankInfoList: [],
            counter: 1,
            id: 0
        }


        this.editBankInfo = this.editBankInfo.bind(this);
        this.bankInfoDetail = this.bankInfoDetail.bind(this);
        this.addBankInfo = this.addBankInfo.bind(this);

    }

    addBankInfo() {
        this.props.history.push(`/addBankInfo`);
    }

    bankInfoDetail(id) {
        this.props.history.push({pathname: "/viewBankInfo", state: {id: id}});
    }

    editBankInfo(id) {
        this.props.history.push( {pathname: "/editBankInfo", state: {id: id}});
    }



    componentDidMount() {
        this.searchCashBankInfos()
    }

    searchCashBankInfos() {
        BankInfoService.getBankInfos()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    bankInfoList: data
                });
                this.setState({ isLoading: true });
            });
    }


    async deleteBankInfo(id) {
        if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
            try {
                const response = await BankInfoService.deleteBankInfo(id);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
            }
        }


    }

    render() {
        const { bankInfoList, isLoading } = this.state;
        this.state.counter = 1
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" >Bank List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Display Bank Information</div>

                                        <button type="button" title={ "Add" }
                                            className="btn btn-sm btn-success primary m-1  float-left"
                                            onClick={ (e) => this.addBankInfo() }>
                                            <i className="fas fa-plus" /> Add
                                        </button>


                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                            <thead className=' thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>No.</th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '>

                                                { bankInfoList.map(bankInfo =>

                                                    <tr key={ bankInfo.id }>
                                                        <td>{ this.state.counter++ }</td>
                                                        <td>{ bankInfo.name }</td>
                                                        <td>{ bankInfo.description }</td>
                                                        <td>


                                                            <button style={ { marginLeft: "2px" } } title={ "Edit" }
                                                                onClick={ () => this.editBankInfo(bankInfo.id) }
                                                                className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>

                                                            <button style={ { marginLeft: "2px" } } title={ "Delete" }
                                                                onClick={ () => this.deleteBankInfo(bankInfo.id) }
                                                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <NotificationContainer />
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>
                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}


export default BankInfoList;