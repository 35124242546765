import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import RegionService from '../../../services/common/RegionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";

class RegionListing extends Component {

  constructor(props) {

    super(props)
    this.state = {
      isLoading: false,
      RegionList: [],
    }

    this.editRegion = this.editRegion.bind(this);
  }


  componentDidMount() {
    RegionService.getAllRegions(localStorage.userId)
      .then(response => {
        this.setState({
          isLoading: true,
          RegionList: response.data
        });
      })
      .catch(e => {
        
      });
  }

  editRegion(id) {
    this.props.history.push({pathname: "/editprovince", state: {id: id}});
  }

  // Delete customer
  async deleteRegion(id) {
    if (window.confirm("Are you sure you want to delete this region?")) {
      try {
        const response = await RegionService.deleteRegionById(id, localStorage.UserId);
        //toast.success("Deleted successfully");
        NotificationManager.success("Deleted successfully", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
      } catch (error) {
        NotificationManager.error(error.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      }
    }
  }

  render() {
    const { RegionList, isLoading } = this.state;
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div>
          {/* <Header />
          <Menu /> */}
          <NotificationContainer />
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Region List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Region List</div>

                    <Link to={{ pathname: '/addprovince', state: { type: "Agent", formName: "region Registration" } }}>
                      <button style={{ marginLeft: "3px" }} type="button" className="btn btn-sm btn-success float-left mb-2" onClick={this.addregion}>
                        <i className="fas fa-plus" /> Add
                      </button>
                    </Link>

                    <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }}>
                      <thead className='thead-light'>
                        <tr style={{ marginTop: 0 }}>
                          <th>Region Name</th>
                          {/* <th>Major City</th> */}
                          {/* <th>Region Zone</th> */}
                          <th>Remark</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {RegionList.map(region =>

                          <tr>
                            <td className='p-1 '>{region.region}</td>
                            {/* <td className='p-1 '>{ region.majorCity }</td> */}
                            {/* <td className='p-1 '>{ region.regionZone }</td> */}
                            <td className='p-1 '>{region.remark}</td>

                            <td className='p-1'>

                              <Link to={{ pathname: '/regiondetail', state: { id: region.id } }}>
                                {/* <button className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" /> </button> */}
                              </Link>
                              {/* <Link to={{ pathname: '/editRegion', state: { id: region.id } }}> */}
                              <button style={{ marginLeft: "2px" }}
                                onClick={() => this.editRegion(region.id)}
                                className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                              {/* </Link> */}
                              {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteRegion(region.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */}
                            </td>
                          </tr>

                        )}
                      </tbody>
                    </table>
                  
              
              
              </div>
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

            {/* /.content */ }
          </div >
        </div >
      )
    }
  }
}
export default RegionListing