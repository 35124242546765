import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import KioskSalesHistoryService from '../../../services/wallet/KioskSalesHistoryService';
import ProviderService from '../../../services/etopup/ProviderService';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class KioskPayBillHistoryList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            kId: this.props.match.params.kId,
            searchKioskId: this.props.match.params.searchKioskId,
            isLoading: false,
            kioskSelectOption: null,
            kioskTopupHistory: [],
            kioskId: null,
            accountNo: null,
            billType:null,
            startDate: null,
            endDate: null,

            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            formErrors: {
                kioskIdError: "",
                providerIdError: "",
                mobileNumebrError: "",
                startDateError: "",
                endDateError: ""
            },

        }
        this.clearValue = this.clearValue.bind(this);

    }



    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.searchKioskPayBillHistoryBasedOnFilter() 
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchKioskPayBillHistoryBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchKioskPayBillHistoryBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchKioskPayBillHistoryBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.searchKioskPayBillHistoryBasedOnFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.kioskId = null
        this.state.billType = null
        this.state.accountNo = null
        this.state.startDate = null
        this.state.endDate = null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.searchKioskPayBillHistoryBasedOnFilter()
        }
    }
    fetchKisokData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findKioskBySearchParam/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.kioskId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            kioskList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onSearchChange = (kioskSelectOption) => {
        if (kioskSelectOption && this.props.location.state.kId) {
            this.state.kioskSelectOption = kioskSelectOption
            this.setState({
                kioskId: kioskSelectOption.value
            });
        }
        if (kioskSelectOption == null && this.props.location.state.kId) {
            this.setState({
                kioskId: this.props.location.state.kId
            });
        }

    }

    componentDidMount() {
        this.searchKioskPayBillHistoryBasedOnFilter(this.props.location.state.kId)
    }

    searchKioskPayBillInfo(e) { 
        this.searchKioskPayBillHistoryBasedOnFilter(this.props.location.state.kId)
    }

    searchKioskPayBillHistoryBasedOnFilter(kId) {

        if (this.state.startDate != null && this.state.endDate == null) {
            this.setState({ endDate: moment().format("yyyy-MM-DD") });
        }
        else if (this.state.startDate == null && this.state.endDate != null) {
            NotificationManager.error("Please enter the start date",`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {
            if (this.state.kioskId == null) {
                this.state.kioskId = kId
            }

            let kisokPayBillHistory = { 
                kioskId: this.state.kioskId,
                providerId: this.state.providerId,
                mobileNumebr: this.state.mobileNumebr,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }
           

            KioskSalesHistoryService.getAllKioskBillPaymentHistory(
                this.state.pageNo, this.state.pageSize, localStorage.userId, kisokPayBillHistory)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        isLoading: true, 
                        kioskTopupHistory: data.content,
                        isOpen: false,
                        totalPage: data.totalPages,
                        totalelement: data.pageable.totalelement,
                        pageNo: data.pageable.pageNumber + 1
                    });
                    this.setState({ isLoading: true })
                    
                });
        }
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }



    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNumebr = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }
        this.setState({
            formErrors
        })
    }

    render() {
        const { searchKioskId, kioskTopupHistory, isLoading, pageNo, totalPage, providersList, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
        this.state.searchKioskId = this.props.location.state.searchKioskId
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Kiosk Topup Sales History</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={ { fontFamily: 'normal' } }>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Histor List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                {/* <div className="col-md-4">

                                                    <div>
                                                        <label htmlFor='kioskId' > Kiosk</label>
                                                        <AsyncSelect id='kioskList'
                                                            required
                                                            placeholder={ "Search by kioskId or serialNo" }
                                                            value={ this.state.kioskSelectOption }
                                                            isClearable={ true }
                                                            loadOptions={ this.fetchKisokData } kioskSelectOption
                                                            isLoading={ this.state.kioskSelectOption === {} &&
                                                                this.state.kioskSelectOption === undefined &&
                                                                this.state.kioskSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onSearchChange(e)
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-3">

                                                    <div>
                                                        <label htmlFor='providerId' > Provider</label>

                                                        <select onChange={ e => this.state.providerId = e.target.value } className="form-control" name='providerId'>
                                                            <option value=""> Choose provider</option>
                                                            { providersList.map(provider =>
                                                                <option value={ provider.id }>{ provider.providerName }</option>
                                                            ) }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='mobileNumebr'>Mobile No</label>
                                                    <PhoneInput countryCodeEditable={ false } value={ this.state.mobileNo }
                                                        country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                        placeholder="Enter phone number" id='mobileNo'
                                                        inputStyle={ { width: '100%', height: 'auto' } }
                                                        onChange={ phone => this.onChangeMobileNo(phone) } />
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='startDate' >From:</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='startDate'
                                                            onChange={ e => this.state.startDate = e.target.value } />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='endDate' >To:</label>
                                                    <div className="w-90 pull-left">

                                                        <input type="date" className="form-control" id='endDate'
                                                            onChange={ e => this.state.endDate = e.target.value } />
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchKioskPayBillInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/kioskCustomerBalanceList', state: { type: "Agent", formName: "order Registration" } } }>
                                            <button type="button" className="btn btn-md btn-success float-left mb-2" onClick={ this.addCustomer } style={ { height: '33px' } } title="Back to Balance">
                                                <i className="fa fa-arrow-circle-left" />
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>

                                        <label className='float-right light'> <span style={ { fontWeight: "lighter" } }> Kiosk Id :</span><span style={ { fontWeight: "bolder" } }>{ searchKioskId }</span></label>


                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Kiosk ID</th> */ }
                                                    <th>Provider Name</th>
                                                    <th>Receiver Mobile No</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { kioskTopupHistory !== undefined && kioskTopupHistory.map(list =>

                                                    <tr>
                                                        {/* <td className='p-1 '>{ list.kioskId.kioskId }</td> */ }
                                                        <td className='p-1 '>{ list.providerId.providerName }</td>
                                                        <td className='p-1 '>{ list.receiverMobileNo }</td>
                                                        <td className='p-1 '>
                                                            <NumberFormat value={ list.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td className='p-1 '>{ list.createdAt }</td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >

            )
        }
    }
}
export default KioskPayBillHistoryList