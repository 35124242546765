import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import LoginService from '../../../../services/common/LoginService';
import PasswordRecoveryService from '../../../../services/common/PasswordRecoveryService';
import ReactLoading from "react-loading";
import LoadingPopup from '../../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\\/ ]{3,20}$/u)
// const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\\/ ]{0,20}$/u)
const emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class PasswordRecovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: '',
            isLoading: false,
            companyProfile: [],
            formErrors: {
                mobileNoError: ""
            },
        }


    }

    handleSubmit = e => {
        e.preventDefault();
        const { mobileNo, formErrors } = this.state
        formErrors.mobileNoError = mobileNo == '' || mobileNo == null ? "Phone number is Required" : this.state.formErrors.mobileNoError;


        if (formValid(this.state.formErrors)) {
            let loginId = new FormData();
            loginId.append('loginId', mobileNo);
            loginId.append('email', mobileNo);

            this.setState({ isLoading: true})
            PasswordRecoveryService.createPasswordRecoveryInfo(loginId)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SIGNUP_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({ isLoading: false})
                        this.props.history.push('/confirmation')
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false})
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoadingPop: false})
                });

        }
        this.setState({
            formErrors
        })
    };


    onChangeReceiverMobileNo = mobile => {
        const { formErrors } = this.state
        formErrors.mobileNoError =
            !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites" : ""
        this.setState({
            mobileNo: mobile,
            formErrors
        })
    }

    componentDidMount() {
        LoginService.companyProfileInfo()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    companyProfile: data
                });
            });
    }

    redirectPasswordRecovery() {
        localStorage.setItem('path', "/emailPasswordRecovery")
    }


    redirectConfirmation() {

        const { mobileNo, formErrors, isLoading } = this.state
     
        formErrors.mobileNoError = mobileNo == '' || mobileNo == null ? "Phone number is Required" : this.state.formErrors.mobileNoError;


        if (formValid(this.state.formErrors)) {
            this.state.isLoading = false;
            let loginId = new FormData();
            loginId.append('loginId', mobileNo);
            loginId.append('email', mobileNo);


            PasswordRecoveryService.createPasswordRecoveryInfo(loginId)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SEND_OTPVALUE}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.state.isLoading = true;
                        localStorage.setItem('path', "/confirmation")
                        this.props.history.push({ pathname: '/confirmation', state: { mobileNo: this.state.mobileNo } })
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });
                this.setState({
                    isLoading: this.state.isLoading
                })
        }
        this.setState({
            formErrors
        })
    }
    render() {
        const { formErrors, companyProfile, isLoading } = this.state

        return (
            <div >
                <NotificationContainer />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                <div className='col-12 col-sm-8 col-md-6 col-lg-6 text-muted p-2'>
                                <div className="d-flex justify-content-center">
                                        <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="SmartPay"
                                                                style={{ width: '30%', height: '10%', alignSelf: 'center' }}

                                                            />
                                        </div>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0 bg-blue ">
                                            <h6 className='d-flex justify-content-center' >Welcome to {companyProfile.companyName} </h6>
                                             
                                    {/* <div className='col-12 col-sm-8 col-md-6 col-lg-8 bg-light p-4'>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0  bg-blue">

                                                <table style={{ border: 'none' }}>
                                                    <tr style={{ border: 'none' }}>
                                                        <td style={{ border: 'none', width: '30%', alignSelf: 'center' }}>
                                                            <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={{ width: '60%', height: '40%', alignSelf: 'center' }}

                                                            />
                                                        </td>
                                                        <td style={{ border: 'none' }}>
                                                            <h6 className='justify-content-left' >Welcome To {companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table> */}


                                            </div>
                                            <div className="card-body justify-content-center px-4 py-2 ">
                                                <div className='justify-content-center'>
                                                    <h6 className="text-center" >Get Your Password</h6>
                                                </div>

                                        
                                                    <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                        <label htmlFor='mobileNo'>Enter your phone number :</label>
                                                        <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                                            country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                                            inputStyle={{ width: '100%', height: 'auto' }}
                                                            placeholder="Enter phone number" id='mobileNo'
                                                            onChange={phone => this.onChangeReceiverMobileNo(phone)} />
                                                        {formErrors.mobileNoError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.mobileNoError}</span>
                                                        )}
                                                    </div>
                                                    
                                                
                                                <div className="clearfix">&nbsp;</div>
                                                <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                    <Link onClick={() => this.redirectPasswordRecovery()} to={{
                                                        pathname: '/emailPasswordRecovery'
                                                    }}>
                                                        {'Other'}
                                                    </Link>

                                                </div>

                                                <div className="clearfix">&nbsp;</div>

                                                <div className="form-group col-12 col-sm-8 col-md-6 col-lg-6 ">
                                                <div className='justify-content-right: space-betwen;'>
                                                    <Link onClick={() => this.redirectConfirmation()} >
                                                        {'Next'}

                                                    </Link>
</div>
                                                    {/* // <div className='row'>
                                                    //     <div >
                                                    //         <ReactLoading type="bars" color="#0000FF" height={30} width={20} />
                                                    //     </div>
                                                    // </div> */}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default PasswordRecovery;