import React, { Component } from 'react'
import { Navigate, withRouter } from 'react-router-dom';
import CommissionService from '../../../services/etopup/CommissionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoginService from '../../../services/common/LoginService';
import 'react-phone-input-2/lib/style.css';
import TopupMenu from '../../etopup/TopupMenu';
import Header from '../../../components/common/Header';
import AsyncSelect from 'react-select/async'
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const commissionAssignmentRegEx = RegExp(/^(?!\d+$)\w+\S+/)

class AddCommission extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            customerById: '',
            commissionPercent: 0,
            commissionAssignment: '',
            customerId: '',
            isLoading: false,
            remark: '',
            selectOption: null,
            customerTypes: [],
            customerId: '',
            fullName: '',
            customerType: '',
            formErrors: {
              commissionPercentError: "",
              commissionAssignmentError: "",
              selectOptionError:"",
              amountError: ""
          },
        }
        
    }
  
    Cancel = e => {
        this.props.history.push('/dealersCommissionList');
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length  >= 3) {
          setTimeout(() => {
            API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
              .then((response) => response.data)
              .then((data) => {
                const temp = [];
                data.forEach((element) => {
                  temp.push({ label: element.fullName, value: element.id })
                });
                callback(temp)
             
              })
              .catch((error) => {
              })
          })
        }else {
          callback([])
        }
      }
    
      onSearchChange = (selectOption) => {
        this.setState({
          selectOption: selectOption
        });
    
      }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
        .then(response => {
          this.setState({
            fullName: response.data.customerFullName,
            customerType: response.data.type
          });
        })
        .catch(e => {
        });
    }
    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'commissionAssignment':
                formErrors.commissionAssignmentError =
                value.length == 0 || !commissionAssignmentRegEx.test(value) ? "" + "Commission assignment should only be alphabets" : ""
                break;
            case 'commissionPercent':
                formErrors.commissionPercentError =
                value.length == 0 || !amountRegEx.test(value) ? "" + " Commission percent sholud only be digits " : ""
                break;
                default:
                break;
                    }
                this.setState({
                    formErrors, [ name ]: value
                })
        }

    handleSubmit = e => {
        e.preventDefault();
        const { commissionPercent,commissionAssignment,selectOption,commissionRateId, formErrors } = this.state
        formErrors.selectOptionError = this.state.selectOption === "" || this.state.selectOption === null || this.state.selectOption === undefined ? "Please select customer!" : this.state.formErrors.selectOptionError;
        formErrors.commissionPercentError = this.state.commissionPercent === "" || this.state.commissionPercent === null || this.state.commissionPercent === undefined || this.state.commissionPercent === 0 ? "A commission percent is required!" : this.state.formErrors.commissionPercentError;
        formErrors.commissionAssignmentError = this.state.commissionAssignment   === "" || this.state.commissionAssignment === null || this.state.commissionAssignment === undefined ? "Please enter the type to whom you need to assign!" : this.state.formErrors.commissionAssignmentError;

        if (formValid(this.state.formErrors)) {
        var commissionData = new FormData();
          commissionData.append("customerId", this.state.selectOption && this.state.selectOption.value);
          commissionData.append("commissionPercent", this.state.commissionPercent);
          commissionData.append("commissionAssignment", this.state.commissionAssignment);
          commissionData.append("remark", this.state.remark);
          commissionData.append("userId", localStorage.userId);
    
          this.setState({ isLoading: true})  
          CommissionService.createCommission(commissionData)
            .then(response => {
                NotificationManager.success("Successfully Created!!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.props.history.push('/dealersCommissionList');
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                
              });
          } else {
            //NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    }


    render() {
        const {  customerType, fullName, selectOption, customerTypes,formErrors } = this.state;

        if (selectOption === null && fullName !== '') {
            this.state.selectOption = {
              label: fullName,
              value: localStorage.customerIdentity
              }
          }

        return (

            <div>
                {/* <Header />
                <TopupMenu />
                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    <div className="content-header">
                        <div className="container-fluid">
                     
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add Commission</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Commission Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                    { customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                                                          <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='CustomerList'>Customer:</label>
                                                            <div style={ { fontSize: 'smaller' } }>
                                                              <AsyncSelect id='CustomerList'
                                                                placeholder={ "Search by name, account no or mobile number" }
                                                                value={ this.state.selectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchData }
                                                                isLoading={ this.state.selectOption === {} &&
                                                                  this.state.selectOption === undefined &&
                                                                  this.state.selectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                  this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                              />
                                                            </div>
                                                            { formErrors.selectOption > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                            ) }
                                                          </div>
                                                          :
                                                          <div>
                                                          </div>
                                                        }
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Commission in %:</label>
                                                            <input type="text" className="form-control " id='commissionPercent' name='commissionPercent'
                                                             onKeyPress={this.handleKeyPress}
                                                             onChange={ this.handelInputChange } onBlur={ this.handelInputChange }  />
                                                  
                                                           { formErrors.commissionPercentError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.commissionPercentError }</span>
                                                            ) }

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Assigned To:</label>
                                                            <input type="text" className="form-control " id='commissionAssignment' name='commissionAssignment'
                                                               onChange={ this.handelInputChange } onBlur={ this.handelInputChange }   />
                                                           { formErrors.commissionAssignmentError.length>0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.commissionAssignmentError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Remark:</label>
                                                            <input type="text" className="form-control " id='remark' name='remark'
                                                            onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading } type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddCommission);
