import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from '../Header';
import ReactLoading from "react-loading";
import { get } from 'jquery';

class CustomerTypeList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            isLoading: false,
            CustomerTypeList: [],
            counter:1
        }

    }

    componentDidMount() {
        this.getCustomerTypeInfo();
    }

    getCustomerTypeInfo(){
        CustomerTypeService.getCustomerTypes(localStorage.userId)
        .then(response => {
            this.setState({
                isLoading: true,
                CustomerTypeList: response.data
            });
        })
        .catch(e => {
            this.setState({ isLoading: true });
            
        });
    }

    async deleteStatusSetting(id) {
        if (window.confirm("Are you sure you want to delete the data?")) {
            try {
                const response = await CustomerTypeService.deleteCustomerType(id, localStorage.userId);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.getCustomerTypeInfo();
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            }
        }

    }

    render() {
        const { CustomerTypeList, isLoading } = this.state;
        this.state.counter = 1;
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Customer Type List</li>
                                        </ol>
                                    </div>

                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Customer Types</div>

                                        <Link to={ { pathname: '/addCustomerTypes' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>No.</th>
                                                    <th>Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { CustomerTypeList.map(list =>

                                                    <tr>
                                                        <td>{ this.state.counter++ }</td>
                                                        <td>{ list.type }</td>

                                                        <td>
                                                        <button style={ { marginLeft: "2px" } } title={ "Delete" }
                                                                onClick={ () => this.deleteStatusSetting(list.id) }
                                                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default CustomerTypeList