
import React, { Component } from 'react'
import ReactLoading from "react-loading";
import LoginService from '../../../../services/common/LoginService';
//import TopupMenu from '../../../etopup/TopupMenu';
import Menu from '../../../wallet/Menu';
import Header from '../../Header';


export default class ViewUserInfo extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            id: this.props.location.state.id,
            isLoading: false,
            users: ''
        };
    }
    Cancel = (e) => {
        this.props.history.push('/usersList');
    }
    componentDidMount() {
        const reloadCount = localStorage.getItem('reloadCount');
        if(reloadCount == "firstTime") {
          localStorage.removeItem('reloadCount');
          window.location.reload();
        }  
        LoginService.getUserInfo(this.state.id)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    users: data,
                    isLoading: true
                });
            });
    }



    render() {
        const { users, isLoading } = this.state;
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User Detail Information</li>

                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col sm-12 col md-7 col lg-7'>
                                                <div className="table-responsive w-100">


                                                    <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                                        <tr >
                                                            <td style={ { borderRightStyle: 'hidden' } }>
                                                             <div className='center'> 
                                                             { users &&
                                                                <img id="logo" 
                                                                    className="mx-auto d-block rounded-circle"
                                                                    style={ { width: '90px', height: '90px', margin: 'auto', alignSelf: 'center' } }
                                                                    src={ "data:image/png;base64," + users.attachment }>
                                                                </img> 
                                                            }
                                                             </div>
                                                            </td>
                                                            <td style={ { borderLeftStyle: 'hidden' } }>
                                                                <label>LoginId:</label> { users && users.loginId } <hr />
                                                                <label>Email:</label> { users && users.email }
                                                            </td>
                                                        </tr>
                                                        <tr className="subheaders bg-light">
                                                            <td colspan="2" className="text-blue py-4"> Basic Information</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder" >Full Name.</td>
                                                            <td className="text-justify">{ users && users.fullName }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">Account Status</td>
                                                            <td className="text-justify">{ users && users.accountLocked ? "true" : "false" }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">is SuperUser</td>
                                                            <td className="text-justify">{ users && users.superUser ? "true" : "false" }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">is Administrator</td>
                                                            <td className="text-justify">{ users && users.administrator ? "true" : "false" }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-left font-weight-bolder">is Normal user</td>
                                                            <td className="text-justify">{ users && users.normalUser ? "true" : "false" }</td>
                                                        </tr>
                                                        <tr style={ { textAlign: 'center' } }>
                                                            <button type="submit" className="btn btn-success auto mt-3 px-5 py-2" onClick={ () => this.Cancel() }>Back</button>

                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                        {/* <div className="table-responsive w-10">
                                        <p>Pic</p>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-2'></div>
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>

            )
        }
    }
}

