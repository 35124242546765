import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AsyncSelect from 'react-select/async'
import 'react-notifications/lib/notifications.css';
import PostAccountService from '../../../services/etopup/PostAccountService';
import Header from '../../common/Header';
import API from '../../../http-common';
import Menu from '../../wallet/Menu';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class EditPostAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location.state.id,
      postAccountList:[],
      openingBalance: 0,
      debit: 0,
      credit: 0,
      creditStatus:false,
      debitStatus:false,
      transactionType:0,
      amount: 0,
      remark: "",
      formErrors: {
        remarkError: "",
        creditError: "",
        openingBalanceError: "",
        debitError: "",
        customerError: ""
      },
      customerList: [],
      selectOption: null,
      customerId: '',
      fullName: ''
    }

  }




  fatchData = (inputValue, callback) => {
    if (!inputValue) {
      callback({})
    } else {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data,

            })
          })
          .catch((error) => {
          })
      })
    }
  }

  onSearchChange = (selectOption) => {
    const { formErrors } = this.state
    formErrors.customerError = selectOption == "" || selectOption == null ? 'You must make a selection' : "";



    this.setState({
      selectOption: selectOption,
      formErrors
    });

  }





  componentDidMount() { 

    PostAccountService.getPostAccountsById(this.state.id)
    .then(response => {
      this.setState({
        isLoading: true,
        postAccountList: response.data
      });
      
    })
    .catch(e => {
      
      this.setState({ isLoading: true });
    });
  }




  handleSubmit = e => {
    e.preventDefault();
    const {  selectOption, postAccountList, formErrors } = this.state
    formErrors.customerError = selectOption == '' || selectOption == null || selectOption == undefined ? 'You must make a selection' : this.state.formErrors.customerError;
    formErrors.openingBalanceError = postAccountList.openingBalance == '' || postAccountList.openingBalance == "0" || postAccountList.openingBalance == undefined ? 'Opening Balance is Required' : this.state.formErrors.openingBalanceError ;
    
    if(!this.state.debitStatus){
      formErrors.debitError = postAccountList.debit == '' || postAccountList.debit == "0" || postAccountList.debit == undefined ? 'Debit is Required' : this.state.formErrors.debitError
    };

    if(!this.state.creditStatus){
    formErrors.creditError = postAccountList.credit == '' || postAccountList.credit == "0" || postAccountList.credit == undefined ? 'Credit is Required' : this.state.formErrors.creditError;
    }

    if (formValid(this.state.formErrors)) {
      if (postAccountList.openingBalance >= postAccountList.credit) {
      const formData = new FormData();
      formData.append("id", postAccountList.id )
      formData.append("customerId", postAccountList.ledgerAccountId )
      formData.append("openingBalance", postAccountList.openingBalance)
      formData.append("amount", postAccountList.debit === 0 ? postAccountList.credit : postAccountList.debit)
      formData.append("debit", postAccountList.debit === null || postAccountList.debit === "" ? "0" : postAccountList.debit)
      formData.append("credit",  postAccountList.credit === null || postAccountList.credit === "" ? "0": postAccountList.credit)
      formData.append("remark", postAccountList.remark)

      PostAccountService.updatePostAccounts(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            e.target.reset();
            this.cancel()
          },
          (err) => {
            NotificationManager.error(typeof err == 'string' ? err : err.message, 'ASPay', 3000);
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        });
      } else {
        NotificationManager.info(`${process.env.REACT_APP_CHECK_BALANCE}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);

      }
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
      this.setState({
        formErrors
      })
    }
  };



  cancel() {
    this.props.history.push('/ledgerTranList')
  }

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'openingBalance':
        this.state.postAccountList.openingBalance = value;
        formErrors.openingBalanceError =
          value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100000000" : ""
        break;
      case 'debit':
        this.state.postAccountList.debit = value
        this.state.creditStatus = false;
        formErrors.creditError = ""
        if(this.state.debit !== undefined && this.state.debit !== "" && this.state.debit !== "0"){
        this.state.creditStatus = true;
        }
        if(this.state.creditStatus){
          formErrors.debitError =
            value.length === 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100000000" : ""
        }
        break;
      case 'credit':
        this.state.postAccountList.credit = value
        this.state.debitStatus = false;
        formErrors.debitError = ""
        if(this.state.credit !== undefined && this.state.credit !== "" && this.state.credit !== "0"){
          this.state.debitStatus = true;
        }
      
        if(this.state.debitStatus){
          formErrors.creditError =
            value.length === 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100000000" : ""
        }
        break;
      case 'remark':
        this.state.postAccountList.remark = value
        this.state.remark = value;
        formErrors.remarkError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;
    }

    this.setState({
      debitStatus: this.state.postAccountList.debit === "0" ? true : false,
      creditStatus: this.state.postAccountList.credit === "0" ? true : false,
      formErrors, 
      [name]: value
    })
  }


  render() {
    const { postAccountList, formErrors } = this.state
    this.state.debitStatus = postAccountList.debit === 0 ? true : false;
    this.state.creditStatus = postAccountList.credit === 0 ? true : false;

    this.state.selectOption = 

    this.state.selectOption = {
      label: postAccountList.fullName ,
      value: postAccountList.ledgerAccountId
    }

    return (

      <div >
        {/* <Header />
        <Menu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Modify Post Account Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Modify Post Account Information
                      </h4>
                    </div>

                    <form onSubmit={this.handleSubmit} noValidate>

                      <div  className=" col-12 col-sm-12 col-md-6 col-lg-6"> 
                        <label htmlFor='CustomerList'>Customer :</label>
                        <div style={{ fontSize: 'smaller' }}>
                          <AsyncSelect id='CustomerList'
                            placeholder={"Search by account code "}
                            value={this.state.selectOption}
                            isClearable={true}
                            loadOptions={this.fatchData}
                            isLoading={this.state.selectOption === {} &&
                              this.state.selectOption === undefined &&
                              this.state.selectOption === null ? true : false
                            }
                            onChange={(e) => {
                              this.onSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                          {formErrors.customerError.length > 0 && (
                            <span style={{ color: "#F61C04" }}>{formErrors.customerError}</span>
                          )}

                        </div>

                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='openingBalance'>Opening Balance:</label>
                        <input type="text" className="form-control" id='openingBalance'
                           value={postAccountList.openingBalance}
                          onChange={this.handelChange} onBlur={this.handelChange} name='openingBalance'
                          placeholder="Opening Balance" />
                        {formErrors.openingBalanceError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.openingBalanceError}</span>
                        )}
                      </div>

                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='debit'>Debit:</label>
                        <input disabled={this.state.debitStatus} type="text" className="form-control" id='debit'
                        value={postAccountList.debit}
                          onChange={this.handelChange} onBlur={this.handelChange} name='debit'
                          placeholder="Debit" />
                        {formErrors.debitError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.debitError}</span>
                        )}
                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='credit'>Credit:</label>
                        <input disabled={this.state.creditStatus} type="text" className="form-control" id='credit'
                        value={postAccountList.credit}
                          onChange={this.handelChange} onBlur={this.handelChange} name='credit'
                          placeholder="credit" />
                        {formErrors.creditError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.creditError}</span>
                        )}
                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='remark'>Remark:</label>
                        <textarea type="text" className="form-control" id='remark'
                        value={postAccountList.remark}
                          onChange={this.handelChange} onBlur={this.handelChange} name='remark'
                          placeholder="Remark" />
                        {formErrors.remarkError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.remarkError}</span>
                        )}
                      </div>

                      <div className="row  d-flex justify-content-left ">
                        <button type="submit"
                          className="btn btn-success px-4 py-2" style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={this.cancel.bind(this)}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}



export default EditPostAccount;