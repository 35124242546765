import React, { Component } from 'react'
import WalletTrxnService from '../../services/wallet/WalletTrxnService';
import { format } from 'date-fns'
import MerchantService from '../../services/wallet/MerchantService';
import AgentService from '../../services/common/AgentService';
import CustomersService from '../../services/common/CustomersService';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import ReactLoading from "react-loading";
import RefundWalletService from '../../services/wallet/RefundWalletService';
import Header from '../common/Header';
import Menu from './Menu';
import CustomerService from '../../services/etopup/CustomerService';
import KioskService from '../../services/wallet/KioskService';
import CashInfoService from '../../services/etopup/CashInfoService';
import moment from 'moment';
import TopupTransactionService from '../../services/etopup/TopupTransactionService';
import LoadingPopup from './LoadingPopup';

class Dashboard extends Component {

  constructor (props) {

    super(props)
    this.state = {
      totalMerchants: 0,
      isLoading: false,
      displayMonthlyWalletToWallet: true,
      displayMonthlyWalletToTopupTransfers: true,
      displayRecenttrxns: true,
      displayTotalCashInfo: true,
      displayMerchantList: true,
      displayMonthlyWalletToTopupTransfers: true,
      currentDate:new Date(),
      monthlyWalletToTopupTransfers: [],
      monthlyWalletToWalletTransfers: [],
      Recenttrxns: [],
      CustomerList: 0,
      MerchantList: [],
      AgentList: [],
      totalKiosks:[],
      totalCashInfo:[],
      totalCustomerBalance:[],
      counter: 1
    }

  }

  componentDidMount() {
     const reloadCount = localStorage.getItem('reloadCount');
    if(reloadCount == "firstTime") {
      localStorage.removeItem('reloadCount');
      window.location.reload();
    } 
   this.setState({ isLoading: true})
    if(localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`){
    
      {/*Get total merchants*/ }
      MerchantService.getMerchants(localStorage.userId)
        .then(response => {
          this.setState({
            MerchantList: response.data
          });
        },
        (err) => {
          if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
            this.setState({ displayMerchantList: false})
          }
        })
        .catch(e => {
          
        });
        
           {/*Get monthly wallet to topup transfer*/ }
           TopupTransactionService.getMonthlyWalletToTopupTransfers(localStorage.customerIdentity,localStorage.userId)
           .then(response => {
             this.setState({
               monthlyWalletToTopupTransfers: response.data
             });
           },
           (err) => {
            if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
              this.setState({ displayMonthlyWalletToTopupTransfers: false})
            }
          })
           .catch(e => {
             
           });
     {/*Get monthly wallet to topup transfer*/ }
     TopupTransactionService.getTotalCustomerBalance(localStorage.userId)
     .then(response => {
       this.setState({
        totalCustomerBalance: response.data
       });
     },
     (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayMonthlyWalletToTopupTransfers: false})
      }
    })
     .catch(e => {
       
     });

  
      {/*Get the daily cashIn and cashOut */ }
      CashInfoService.findDailyCashInfo(moment(this.state.currentDate).format('yyyy-MM-DD'),localStorage.userId)
      .then(response => {
          this.setState({
            totalCashInfo: response.data
          });
      },
      (err) => {
       if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
         this.setState({ displayTotalCashInfo: false})
       }
     })
        .catch(e => {
      });
      {/*Get total customers*/ }
  
      CustomersService.getTotalCustomer(localStorage.userId)
        .then(response => {
          this.setState({
            CustomerList: response.data
          });
        },
        (err) => {
         if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
           this.setState({ displayCustomerList: false})
         }
       })        
        .catch(e => {
          
        });

        CustomersService.getTotalMerchant(localStorage.userId)
        .then(response => {
          this.setState({
            totalMerchants: response.data
          });
        },
        (err) => {
         if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
           this.setState({ displayCustomerList: false})
         }
       })        
        .catch(e => {
          
        });
  
        {/*Get the total number of kiosk machines */ }
      KioskService.getTotalKiosks(localStorage.userId)
      .then(response => {
          this.setState({
            totalKiosks: response.data
          });
      },
      (err) => {
       if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
         this.setState({ displayTotalKiosks: false})
       }
     })    
        .catch(e => {
      });
    }

    {/*Get monthly wallet to wallet transfers*/ }
    RefundWalletService.getMonthlyWalletToWalletTransfer(localStorage.customerIdentity, localStorage.userId)
      .then(
        (response) => {
        this.setState({          
          monthlyWalletToWalletTransfers: response.data
        }); 
      },   
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE'){
          this.setState({ displayMonthlyWalletToWallet: false})
        }
      })
      .catch(e => {
        
      });

    
    WalletTrxnService.getRecentTrxns(localStorage.customerIdentity, localStorage.userId)
      .then(
      (response) => {
        this.setState({
          Recenttrxns: response.data,
          isLoading: false
        });     
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE'){
          this.setState({ 
            displayRecenttrxns: false,
            isLoading: false
          })
        }
      })
      .catch(eerr=> {
        this.setState({ isLoading: false})
      });

    
  }


  render() {
    const { totalCustomerBalance, Recenttrxns, displayMonthlyWalletToWallet,monthlyWalletToTopupTransfers, CustomerList, monthlyWalletToWalletTransfers } = this.state;
    const{totalCashInfo,totalKiosks}=this.state
 
    return (
      <div>
         {/* <Header />
         <Menu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white" >
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2 ">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>
                {/* /.col */ }
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
                {/* /.col */ }
              </div>
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>
          {/* /.content-header */ }
          {/* Main content */ }
          <section className="content">
            <div className="container-fluid">
              {/* Small boxes (Stat box) */ }
              <div className="row ">
              
                <div className="col-lg-5 col-5">
                  {/* small box */ }
                  <div className="small-box bg-white">
                  {displayMonthlyWalletToWallet ?
                  <>
                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label " style={ { textAlign: 'center' } }> Wallet to Wallet transfers </div>
                      </div>
                    
                      
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Trxns </div>
                        <div className="col-lg-8 col-8 dashboard-label"> Total Trxn amount </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ parseInt(monthlyWalletToWalletTransfers.numberOfTrxns) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-8 col-8 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ parseFloat(monthlyWalletToWalletTransfers.totalTrxnAmunt).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                   
                   
                 
                       </div>  
                        <Link to="/walletTrxnList" className="small-box-footer bg-light text-dark dashboard-label">
                        More info <i className="fas fa-arrow-circle-right " />
                      </Link> 
                      </>
                       :
                    ""
                      }
                    {/* <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div> */}
                   
                  </div>
                </div>
                {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                <div className="col-lg-4 col-2">
                  {/* small box */ }
                  <div className="small-box bg-white">
                    <div className="inner">
                      <div className='dashboard-label' style={ { textAlign: 'left' } }>Total Customers</div>
                      <div className='dashboard-balance' style={ { textAlign: 'left' } }>{ this.state.CustomerList}</div>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div> */}
                    <Link to="/customerList" className="small-box-footer bg-light text-black dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                :
                ""
              }
              {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                <div className="col-lg-3 col-6 ">
                  <div className="small-box bg-white">
                    <div className="inner">
                      <div className='dashboard-label' style={ { textAlign: 'left' } }>Total Merchants</div>
                      <div className='dashboard-balance' style={ { textAlign: 'left', } }>{ this.state.totalMerchants }</div>
                    </div>
                    <Link to="/customerList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                :
                ""
                  } 
              </div>

              <div className="row ">
   
        {/* small box */ }
        {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
        <div className="col-lg-3 col-6">
             <div className="small-box bg-white">
                    <div className="inner">
                      <div className='dashboard-label' style={ { textAlign: 'left' } }>Daily CashIn</div>
                      <div className='dashboard-balance' style={ { textAlign: 'left' } }><NumberFormat value={ parseFloat(totalCashInfo['totalCashIn']).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></div>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-bag" />
                    </div> */}
                    <Link to="/cashInList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
          </div>
          :
                ""
                  }    
          {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && this.state.displayTotalCashInfo ?
                <div className="col-lg-3 col-6">
                  {/* small box */ }
                  <div className="small-box bg-white">
                    <div className="inner">
                      <div className='dashboard-label' style={ { textAlign: 'left'} }>Daily CashOut</div>
                      <div className='dashboard-balance' style={ { textAlign: 'left' } }><NumberFormat value={parseFloat(totalCashInfo['totalCashOut']).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></div>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-bag" />
                    </div> */}
                    <Link to="/cashOutList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                :
                ""
                  }
                {/* ./col */ }
                {/* ./col */ }
                {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && this.state.displayMonthlyWalletToTopupTransfers ?
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-white">
                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" style={ { textAlign: 'center' } }> Wallet to Topup transfers </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Trxns </div>
                        <div className="col-lg-8 col-8 dashboard-label"> Total Trxn amount </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ monthlyWalletToTopupTransfers.numberOfTrxns } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-8 col-8"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ parseFloat(monthlyWalletToTopupTransfers.totalTrxnAmunt).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                    </div>
                    <Link to="/transactionMonitoringList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right " />
                    </Link> 
                  </div>
                </div> 
                :
                ""
                }
                 {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && this.state.displayMonthlyWalletToTopupTransfers ?
                <div className="col-lg-3 col-3">
                  <div className="small-box bg-white">
                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" style={ { textAlign: 'center' } }> Total customer balance </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> Wallet $ </div>
                        <div className="col-lg-8 col-8"> <div className='mt-0 dashboard-balance'>   <NumberFormat value={parseFloat(totalCustomerBalance.walletBalance).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                      <div className='row pl-1'>
                      <div className="col-lg-4 col-4 dashboard-label"> eTopup $ </div>
                     
                        <div className="col-lg-8 col-8"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ parseFloat(totalCustomerBalance.topupBalance).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                    </div>
                  </div>
                </div> 
                :
                ""
                }
                {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && this.state.displayTotalKiosks?
                
                <div className="col-lg-3 col-6">
                  {/* small box */ }
                  <div className="small-box bg-white">
                    <div className="inner">
                      <div className='dashboard-label' style={ { textAlign: 'left'} }>#Kiosk Machines</div>
                      { totalKiosks.length>0 &&
                      <div className='dashboard-balance' style={ { textAlign: 'left' } }>{ totalKiosks.length }</div>
                     }
                    { !totalKiosks &&
                      <div className='dashboard-balance' style={ { textAlign: 'left' } }>{ 0 }</div>
                    }
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div> */}
                    <Link to="/kioskList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>

                :
                <div ></div>
                  }
              </div>
              <div className="row">
              {this.state.displayRecenttrxns ?
                <div className="col-lg-6 col-6">
                  {/* small box */ }
                  <div className="small-box bg-light text-dark">
                    <div className="inner dashboard-label">
                      <i className="fas fa-arrow-circle-right" /> Recent Transactions
                    </div>
                    <div className="icon">

                    </div>
                    <a href="/#" className="small-box-footer bg-white">
                      <table className="table border-0 " >
                        <tr className='dashboard-label'>
                          <th>Sender</th>
                          <th>Receiver</th>
                          <th>Amount</th>
                          <th>Trxn Ref #</th>
                        </tr>

                        { Recenttrxns.map(recenttrxn =>

                          <tr className='dashboard-label' key={ recenttrxn.id + recenttrxn.receiverMobileNo + recenttrxn.senderMobileNo }>
                            <td className='p-1 '>{ recenttrxn.senderMobileNo }</td>
                            <td className='p-1 '>{ recenttrxn.receiverMobileNo }</td>
                            <td className='p-1 '>{ recenttrxn.amount }</td>
                             <td className='p-1 '><Moment format="YYYYMMDDhhmmssms">{ recenttrxn.createdAt }</Moment></td> 
                          </tr>

                        ) }

                      </table>

                    </a>
                  </div>
                </div>
                :
                ""
                }
                {this.state.displayRecenttrxns ?
                <div className="col-lg-6 col-6">
                  {/* small box */ }
                  <div className="small-box bg-warning text-light">
                    <div className="inner dashboard-label">
                      <i className="fas fa-arrow-right" /> Cash In/Out

                    </div>
                    <div className="icon">

                    </div>
                    
                    <a href="/#" className="small-box-footer bg-white">
                      <table className="table border-0 table-striped " >
                        <tr className='dashboard-label'>
                          <th>Mobile</th>
                          <th>Cr.</th>
                          <th>Dr.</th>
                          <th>Trxn Ref #</th>
                        </tr>

                        { Recenttrxns.map(recenttrxn =>

                          <tr className='dashboard-label' key={ recenttrxn.id + recenttrxn.senderMobileNo }>
                            <td className='p-1 '>{ recenttrxn.senderMobileNo }</td>
                            <td className='p-1 '>{ recenttrxn.amount }</td>
                            <td className='p-1 '>{ recenttrxn.amount }</td>
                            <td className='p-1 '><Moment format="YYYYMMDDhhmmssms">{ recenttrxn.createdAt }</Moment></td>

                          </tr>

                        ) }

                      </table>

                    </a>
                  </div>
                </div>
                :
                ""
                        }

                {/* ./col */ }
              </div>


              {/* <div className='row '>
                <div className='col-md-4 '></div>
                <div className='col-md-4 '><div className='dashboard-label' style={ {backgroundColor: "ButtonFace", textAlign: 'center' } }>Recent Transactions</div></div>
                <div className='col-md-2 '></div>
              </div> */}
              {/* <div className='row '>
                <table className="table border-0 " >
                  <tr className='dashboard-label'>
                    <th>Full Name</th>
                    <th>Sender Mobile</th>
                    <th>Receiver Mobile</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>

                  { Recenttrxns.map(recenttrxn =>

                    <tr className='dashboard-label' key={ recenttrxn.id + recenttrxn.senderMobileNo + recenttrxn.receiverMobileNo } >
                      <td className='p-1 '>{ recenttrxn.name }</td>
                      <td className='p-1 '>{ recenttrxn.senderMobileNo }</td>
                      <td className='p-1 '>{ recenttrxn.receiverMobileNo }</td>
                      <td className='p-1 '>{ recenttrxn.amount }</td>
                      <td className='p-1 '><Moment format="YYYYMMDDhhmmssms">{ recenttrxn.createdAt }</Moment></td>
                    </tr>
                  ) }
                </table>
              </div> */}
            </div>
            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
            {/* /.container-fluid */ }
          </section>
          {/* /.content */ }
        </div >
      </div >

      )
  //  }
  }
}

export default Dashboard