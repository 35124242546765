import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import {  withRouter } from 'react-router-dom';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import RegionService from '../../../services/common/RegionService';
import Header from '../Header';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';


class AddRegion extends Component {

    constructor () {

        super();

        this.state = {
            region: '',
            isLoading: false,
            majorCity: '',
            regionZone: '',
            remark: ''
        }

    }

    Cancel = e => {
        this.props.history.push('/provinceList');
    }

    handleSubmit = e => {
        e.preventDefault();
        var regionData = new FormData();

        if (this.region) {
            regionData.append("region", this.region);
        }
        else {
            regionData.append("region", this.state.region);
        }
        if (this.majorCity) {
            regionData.append("majorCity", this.majorCity);
        } else {
            regionData.append("majorCity", this.state.majorCity);
        }
        if (this.regionZone) {
            regionData.append("regionZone", this.regionZone);
        } else {
            regionData.append("regionZone", this.state.regionZone);
        }
        if (this.remark) {
            regionData.append("remark", this.remark);
        } else {
            regionData.append("remark", this.state.remark);
        }

        regionData.append("userId", localStorage.userId);
  
        this.setState({ isLoading: true})
        RegionService.createRegion(regionData)
            //response.data.success === "Success"
            .then(
                (response) => {
                NotificationManager.success("Successfully Registered", `${process.env.REACT_APP_MSG_TITLE}`, 3000);               
                this.setState({ isLoading: false})
                e.target.reset();

            },
            (error) =>{
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoading: false})
            })
            .catch(function (err) {
                NotificationManager.error("Something went wrong !!", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoading: false})
            });
    };


    render() {

        //const { bankList } = this.state;

        return (

            <div>
                {/* <Header />
                <Menu /> */}
                {/* <NotificationContainer /> */}
                {/* Content Wrapper. Contains page content */ }
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">
                            {/*  
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash In Information</li>

                  </ol>
                </div>
              </div> 
            */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add Region</li>

                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Region Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>Region Name:</label>
                                                            <input type="text" className="form-control " id='fullName'

                                                                onChange={ e => this.region = e.target.value } required="required" />
                                                        </div>


                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='companyName'>Remark:</label>
                                                            <input type="text" className="form-control " id='mobileNo' onChange={ e => this.remark = e.target.value } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }


                                            <div class="btn-group d-flex justify-content-center clear-fix">
                                                <button disabled={this.state.isLoading} type="submit" class="btn btn-success mr-5 ml-5 mt-4 pl-2">Save</button>
                                                <button type="submit" class="btn btn-warning mr-5 mt-4 px-2" onClick={ () => this.Cancel() }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                      </>}
                    />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddRegion);
