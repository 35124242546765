import { faBackward, faBackwardFast, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import AuthService from '../../../../services/common/AuthService';
//import TopupMenu from '../../../etopup/TopupMenu';
import Menu from '../../../wallet/Menu';

import { LOCALES } from '../../Constant';
import Header from '../../Header';
import ReactLoading from "react-loading";
import LoadingPopup from '../../../wallet/LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}


class PermissionList extends Component {
    constructor(props) {

        super(props)

        this.state = {
            pageNo: 1,
            pageSize: LOCALES.PERMISSION_PAGE_SIZE,
            isLoading: false,
            totalPage: 0,
            totalelement: 0,
            resourceList: [],
            permissionList: [],
            permissionDB: [
                {
                    selected: ''
                }
            ],
            roleId: null,
            roleIds: [],
            counter: 1,
            rolePermission: 0,
            formErrors: {
                roleIdError: ""
            },
        }


    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.onClickChange(this.state.roleId)

    }

    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.onClickChange(this.state.roleId)
       
    }

    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.onClickChange(this.state.roleId)
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.onClickChange(this.state.roleId)
            this.render()

        }
    }

    nextPage = () => {
       
            this.state.pageNo = this.state.pageNo + 1
            this.onClickChange(this.state.roleId)
      
        
    }

    componentDidMount() {
        AuthService.getRoles()
            .then(response => response.data)
            .then((data) => {
                let roleErrorDisplay = "please select role first"
                this.setState({
                    roleIds: data,
                    formError:{
                        roleIdError : roleErrorDisplay
                    }
                });
            });
            
       this.onClickChange(this.state.roleId)

    }

    onClickChange(roleId) {
        // if (roleId === null) {
        //     this.searchCashPermissions()
        // } else {
             
            this.searchSelectedRolePermissions(roleId)
        // }
    }
    searchCashPermissions() {
        this.state.permissionList = null
        AuthService.getPermissions(this.state.pageNo, this.state.pageSize)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    permissionList: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });

            });
    }

    searchSelectedRolePermissions(roleId) {
        const { pageNo, pageSize } = this.state
        this.state.permissionList = null
        this.state.rolePermission = 1;
        AuthService.getPermissionByRoleId(pageNo, pageSize, roleId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    permissionList: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.numberOfElements,
                    pageNo: data.pageable.pageNumber + 1
                });

            });

        this.state.rolePermission = 0;
    }

    RoleValueChange = e => {
        const { formErrors } = this.state
        let selectedRoleId = e.target.value
        this.state.roleId = ""
        if (selectedRoleId == "----please select---") {
            selectedRoleId = ""
            formErrors.roleIdError = selectedRoleId == "" ? 'You must make a selection' : "";
        } else {
            this.state.rolePermission = 1;
            this.onClickChange(e.target.value)
        }
        this.setState({
            roleId: selectedRoleId,
            formErrors
        });
    }

    handleSubmit = e => {
        const { roleId, formErrors } = this.state
        formErrors.roleIdError = roleId == '' || roleId == null || roleId == undefined ? 'You must make a selection' : this.state.formErrors.roleIdError;


        if (formValid(this.state.formErrors)) {
            let permission = {
                permissions: this.state.permissionList
            }
   
            this.setState({ isLoadingPop: true})
            AuthService.createPermission(this.state.roleId, permission)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_PRIV_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({ isLoadingPop: false})
                        this.searchSelectedRolePermissions(this.state.roleId)
                       
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoadingPop: false})
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoadingPop: false})
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };

    onChangeValue() {
        this.setState(function (prevState) {
            return {
                permissionDB: {
                    ...prevState.permissionDB,
                    selected: !this.state.permissionDB.selected
                },

            };
        });
    }

    render() {
        const { permissionList, totalelement, permissionDB, roleIds, roleId, pageNo, pageSize, totalPage, formErrors } = this.state;
        pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1

   
        let roleList = roleIds.length > 0
            && roleIds.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);


        return (
            <div>
                {/* <Header />
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" >Permission List</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" >Display Permission Information</div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor='RoleList'>Select Role:</label>
                                        <select className="form-control "
                                            value={this.state.roleId}
                                            onChange={this.RoleValueChange} id='RoleList'>
                                            <option value={null}>----please select---</option>
                                            {roleList}
                                        </select>
                                        {formErrors.roleIdError.length > 0 && (
                                            <span style={{ color: "#F61C04" }}>{formErrors.roleIdError}</span>
                                        )}
                                    </div>
                                    <div className="clearfix">&nbsp;</div>
                                    <table className=" table table-striped table-birdered " style={{ height: '30px', fontSize: 'smaller' }} >
                                        <thead className=' thead-light'>
                                            <tr style={{ marginTop: 0 }}>
                                                <th>No.</th>
                                                <th>Resouces</th>
                                                <th>Search/View</th>
                                                <th>Create</th>
                                                <th>Update</th>
                                                <th>Delete</th>
                                                <th>Approve</th>
                                                <th>Verify</th>
                                            </tr>
                                        </thead>
                                        <tbody className='content-wrapper '>

                                            {permissionList && permissionList.map(permission =>

                                                <tr key={permission.resourceId}>
                                                    <td>{this.state.counter++}</td>
                                                    <td className='p-1 '>{permission.resourceName}</td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canSearch} onChange={e => { e.target.checked ? permission.canSearch = true : permission.canSearch = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canCreate} onChange={e => { e.target.checked ? permission.canCreate = true : permission.canCreate = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canUpdate} onChange={e => { e.target.checked ? permission.canUpdate = true : permission.canUpdate = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canDelete} onChange={e => { e.target.checked ? permission.canDelete = true : permission.canDelete = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canApprove} onChange={e => { e.target.checked ? permission.canApprove = true : permission.canApprove = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                    <td className='p-1 '>
                                                        <input checked={permissionDB.selected = permission.canVerify} onChange={e => { e.target.checked ? permission.canVerify = true : permission.canVerify = false; this.onChangeValue() }} type={'checkbox'} />
                                                    </td>
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>
                                    <div className="clearfix">&nbsp;</div>
                                    <div className=" float-sm-center">
                                        <span>
                                            page{''}
                                            <strong>
                                                {pageNo} of {6}
                                            </strong>
                                            {''}
                                        </span>
                                        <span>
                                            | Go To Page : {''}
                                            <input type='number' defaultValue={pageNo}
                                                onChange={(e) => {
                                                    const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                    this.gotoEnteredPage(pageNumber)
                                                }} />
                                        </span>
                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                            <FontAwesomeIcon icon={faBackwardFast} />
                                        </button>

                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                            <FontAwesomeIcon icon={faBackward} />
                                        </button>

                                        <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={totalelement === 0  ? true : false}>
                                            <FontAwesomeIcon icon={faForward} />
                                        </button>

                                        {/* <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={totalelement === 0  ? true : false} >
                                            <FontAwesomeIcon icon={faForwardFast} />
                                        </button> */}
                                    </div>

                                    <div className="row  d-flex justify-content-left ">
                                        <button disabled={this.state.isLoading } type="submit" onClick={this.handleSubmit} className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                                    </div>
                                </div>
                            </div>
                            <NotificationContainer />
                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }
}


export default PermissionList;