import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import CustomersService from '../../../services/common/CustomersService';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faKey, faMobile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../Constant';
import PhoneInput from 'react-phone-input-2';
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import Header from '../Header';
import ReactLoading from "react-loading";
import Popup from '../../wallet/Popup';
import LoadingPopup from '../../wallet/LoadingPopup';
import NumberFormat from 'react-number-format';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)

class Customers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountNo: null,
      firstName: null,
      mobileNo: null,
      dateFrom: null,
      dateTo: null,
      customerType: null,
      customerId: '',
      isLoading: false,
      isLoadingPop: false,
      isResetOpen: false,
      addMobileNo: false,
      changeMobileNoPrimery: false,
      isCompany: false,
      idNumber: '',
      pageNoDB: '',
      registryNo: '',
      volumeNo: '',
      //googleMapKeyData:[],
      isNewId: true,
      isOldId: false,
      isResetPIN: false,
      issueDate: undefined,
      allCustomerTypes: [],
      CustomerList: [],
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      isOpen: false,
      totalPage: 0,
      totalelement: 0,
      counter: 1,
      customerId: '',
      id: 0,
      flag: false,
      isExpand: true,
      addtionalMobileNo: '',
      isResetOpen: false,
      newMobileNo: '',
      formErrors: {
        ChangedMobileNoError: "",
        newPasswordError: "",
        confirmPasswordError: "",
        passwordError: "",
        phoneNumberError: "",
        accountNoError: "",
        fullNameError: "",
        dateToError: "",
        dateFromError: "",
        idNumberError: "",
        pageNoError: "",
        registryNoError: "",
        volumeNoError: "",
        issueDateError: "",
        addtionalMobileNoError: ""
      },
    }
    this.clearValue = this.clearValue.bind(this);
    this.editCustomer = this.editCustomer.bind(this);
    this.viewLocation = this.viewLocation.bind(this)
  }

  gotoEnteredPage = (pageNumber) => {

    this.state.pageNo = pageNumber
    this.searchCustomerBasedOnFilter()
    this.render()
  }
  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    this.searchCustomerBasedOnFilter()
    this.render()

  }
  gotoPageFirst = () => {
    this.state.pageNo = 1
    this.searchCustomerBasedOnFilter()
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      this.searchCustomerBasedOnFilter()
      this.render()

    }
  }

  nextPage = () => {

    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      this.searchCustomerBasedOnFilter()
      this.render()
    }
  }


  clearValue() {
    // should not reload
    this.state.firstName = null
    this.state.mobileNo = null
    this.state.accountNo = null
    this.state.dateFrom = null
    this.state.dateTo = null
    this.state.customerType = null
    this.state.clear = 1
  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.state.clear = 0
      this.searchCustomerBasedOnFilter()

    }
  }

  onChangeMobileNo = mobile => {

    const { formErrors } = this.state

    this.state.mobileNo = mobile
    formErrors.phoneNumberError =
      mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""

    this.setState({
      formErrors
    })
  }


  searchCustomerInfo(e) {

    const { formErrors, dateFrom, dateTo } = this.state
    formErrors.dateFromError = ''
    formErrors.dateToError = ''

    if (dateFrom != null) {
      formErrors.dateToError =
        dateTo == null ? 'date to is required' : ''
    }

    if (dateTo != null) {
      formErrors.dateFromError =
        dateFrom == null ? 'date from is required' : ''
    }

    if (this.state.dateFrom != null && this.state.dateTo != null) {
      if (this.state.dateFrom > this.state.dateTo) {
        formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
      }
    }

    if (formValid(this.state.formErrors)) {

      this.state.clear = 0;
      this.state.pageNo = 1;
      this.searchCustomerBasedOnFilter()

    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  }

  searchCustomerBasedOnFilter() {
    let mobileNo =   localStorage.type !== `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? localStorage.mobileNo : this.state.mobileNo;
    this.CustomerList = null;
    let FilterCustomerInfo = {
      accountNo: this.state.accountNo,
      firstName: this.state.firstName,
      mobileNo: mobileNo,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      customerType: this.state.customerType,
      flag: true
    }

    this.setState({ isLoadingPop: true })
    CustomersService.getFilterdCustomerWithPagination(
      this.state.pageNo, this.state.pageSize, localStorage.userId, FilterCustomerInfo)
      .then(response => response.data)
      .then((data) => {
        if (data.customers) {
          this.setState({
            isLoading: true,
            isLoadingPop: false,
            CustomerList: data.customers,
            isOpen: false,
            totalPage: data.totalPages,
            totalelement: data.totalelement,
            pageNo: data.pageNumber + 1
          })

        }
        if (data.customers === undefined) {
          this.setState({
            isLoading: true,
            isLoadingPop: false
          });
          NotificationManager.warning("No Customer is Registered ", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
      });

  }


  componentDidMount() {
    const reloadCount = localStorage.getItem('reloadCount');
    if (reloadCount == "firstTime") {
      localStorage.removeItem('reloadCount');
      window.location.reload();
    }
    this.searchCustomerBasedOnFilter()

    // CustomersService.getGoogleApiKey(localStorage.userId)
    // .then(response => {
    //     if (response) {
    //     this.setState({
    //         googleMapKeyData: response.data
    //     });
    //     }
    //      console.log(this.state.googleMapKeyData);
    // })
    // .catch(e => {
    // });


    // Get List of customer types
    CustomerTypeService.getCustomerTypes(localStorage.userId)
      .then(response => {
        if (response) {
          this.setState({
            allCustomerTypes: response.data
          });
        }
      })
      .catch(e => {

      });

    this.setState({
      isCompany: localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
    });
  }

  editCustomer(id) {
    this.props.history.push({ pathname: "/editCustomer", state: { id: id } })
  }

  viewLocation(latitude, longitude) {

    this.props.history.push({ pathname: "/customerGoogleMapLocationList", state: { lat: latitude, lng: longitude } })

  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }


  async disableCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to disable this customer?")) {
      try {
        const response = await CustomerVerificationService.disableCustomerAccount(mobileNo, localStorage.userId);
        NotificationManager.success("Disabled successfully", `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCustomerBasedOnFilter()
      } catch (error) {
        NotificationManager.error("Something went wrong !!", `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }

  }

  async linkCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to change link information?")) {
      try {
        const response = await CustomerVerificationService.linkTopup(mobileNo, localStorage.userId);
        NotificationManager.success("Link information is successfully changed", `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCustomerBasedOnFilter()
      } catch (error) {
        NotificationManager.error("Transaction failed: please try again. !!", `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }

  }

  //Activate New customer 
  async ActivateNewAccount(id) {

    if (window.confirm("Are you sure you want to activate the customer?")) {
      try {
        let customerIdentity = {}
        this.setState({ isLoadingPop: true })
        const response = await CustomerVerificationService.activateAccount(id, localStorage.userId, customerIdentity);

        this.searchCustomerBasedOnFilter();
        //  toast.success("Disabled successfully");

        NotificationManager.success("Account Created  successfully", `${process.env.REACT_APP_MSG_TITLE}`);
        this.setState({ isLoadingPop: false })
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
        this.setState({ isLoadingPop: false })
      }
    }
  }


  // Disable for customer account customer
  async enableCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to enable this customer?")) {
      try {
        const response = await CustomerVerificationService.enableCustomerAccount(mobileNo, localStorage.userId);
        NotificationManager.success("Enabled successfully", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        this.searchCustomerBasedOnFilter()
      } catch (error) {
        // toast.error("Something went wrong !!");
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }
    else {
    }

  }

  // Delete customer
  async deleteCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        const response = await CustomersService.deleteCustomer(mobileNo, localStorage.userId);
        NotificationManager.success("Deleted successfully", `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCustomerBasedOnFilter();
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }
  }


  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'idNumber':
        formErrors.idNumberError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;

      case 'pageNoDB':
        formErrors.pageNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'registryNo':
        formErrors.registryNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'volumeNo':
        formErrors.volumeNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'issueDate':
        formErrors.issueDateError =
          value.length == 0 ? "" + name + " sholud contain date" : ""
        break;
      case 'confirmPassword':
        if (this.state.isResetOpen) {
          formErrors.confirmPasswordError =
            value.length == 0 ? "" + name + " sholud contain password" : ""
        } else {
          formErrors.confirmPasswordError =
            value.length == 0 ? "" + name + " sholud contain PIN" : ""
        }
        break;
      case 'newPassword':
        if (this.state.isResetOpen) {
          formErrors.newPasswordError =
            value.length == 0 ? "" + name + " sholud contain password" : ""
        } else {
          formErrors.newPasswordError =
            value.length == 0 ? "" + name + " sholud contain PIN" : ""
        }
        break;

      default:
        break;

    }

    this.setState({
      formErrors, [name]: value
    })
  }

  toggleActivation = id => {
    this.setState({
      id: id,
      isOpen: !this.state.isOpen
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { isNewId, pageNoDB, id, volumeNo, idNumber, issueDate, registryNo, formErrors } = this.state

    formErrors.idNumberError = idNumber === "" || idNumber === null || idNumber === undefined ? "ID number is Required" : this.state.formErrors.idNumberError;
    formErrors.issueDateError = issueDate === "" || issueDate === null || issueDate === undefined ? "Issue Date value is Required" : this.state.formErrors.issueDateError;
    if (!isNewId) {
      formErrors.pageNoError = pageNoDB === "" || pageNoDB === null || pageNoDB === undefined ? "Page No Value is Required" : this.state.formErrors.pageNoError;

      formErrors.registryNoError = registryNo === "" || registryNo === null || registryNo === undefined ? "Registry No value is Required" : this.state.formErrors.registryNoError;

      formErrors.volumeNoError = volumeNo === "" || volumeNo === null || volumeNo === undefined ? "Volume No is Required" : this.state.formErrors.volumeNoError;
    }

    if (formValid(this.state.formErrors)) {
      let customerIdentityData = {
        issueDate: issueDate,
        pageNo: pageNoDB,
        registryNo: registryNo,
        idNumber: idNumber,
        volumeNo: volumeNo,
        idType: isNewId
      }


      this.setState({ isLoadingPop: true })
      CustomerVerificationService.activateAccount(id, localStorage.userId, customerIdentityData)
        .then(
          (res) => {
            this.state.isOpen = false;
            this.setState({ isLoadingPop: false })
            this.searchCustomerBasedOnFilter();
            NotificationManager.success("Account activated  successfully.", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false })
          })
        .catch(function (error) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false })
        });

    }
    this.setState({
      isOpen: this.state.isOpen,
      formErrors
    })
  };


  onChangeSuper() {
    this.setState({
      isOldId: this.state.isNewId,
      isNewId: !this.state.isNewId,
      formErrors: {
        phoneNumberError: "",
        accountNoError: "",
        fullNameError: "",
        dateToError: "",
        dateFromError: "",
        idNumberError: "",
        pageNoError: "",
        registryNoError: "",
        volumeNoError: "",
        issueDateError: ""
      },
    })
  }

  onChangeAdmin() {
    this.setState({
      isNewId: this.state.isOldId,
      isOldId: !this.state.isOldId,
      formErrors: {
        phoneNumberError: "",
        accountNoError: "",
        fullNameError: "",
        dateToError: "",
        dateFromError: "",
        idNumberError: "",
        pageNoError: "",
        registryNoError: "",
        volumeNoError: "",
        issueDateError: ""
      },
    })
  }

  actionPageSize(e) {
    this.state.pageSize = e.target.value;
    this.setState({
      isLoading: false,
      pageSize: e.target.value
    });

    this.searchCustomerBasedOnFilter()
  }

  toggleAdditionalMobileNo = id => {
    this.setState({
      customerId: id,
      addMobileNo: !this.state.addMobileNo
    });
  }

  toggleChangeMobileNo = id => {
    this.setState({
      customerId: id,
      changeMobileNoPrimery: !this.state.changeMobileNoPrimery
    });
  }

  addAdditionalMobileNo = e => {
    e.preventDefault();
    const { addtionalMobileNo, formErrors } = this.state
    formErrors.addtionalMobileNoError = addtionalMobileNo == '' || addtionalMobileNo == null ? "Mobile No is Required" : this.state.formErrors.addtionalMobileNoError;

    if (formValid(this.state.formErrors)) {
      let addMobileNo = {
        customerId: this.state.customerId,
        mobileNo: addtionalMobileNo
      }
      CustomersService.AdditionalMobileNo(localStorage.userId, addMobileNo)
        .then(
          (res) => {
            NotificationManager.success(res.data.message, `${process.env.REACT_APP_MSG_TITLE}`);
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        });

    }
    this.setState({
      formErrors
    })
  };

  onChangeMobileNoPop = mobile => {
    const { formErrors } = this.state
    formErrors.addtionalMobileNoError =
      mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites with a min 12 " : ""
    this.setState({
      addtionalMobileNo: mobile,
      formErrors
    })

  }

  changeMobileNo = e => {
    e.preventDefault();
    const { newMobileNo, formErrors } = this.state
    formErrors.ChangedMobileNoError = newMobileNo == '' || newMobileNo == null ? "Mobile No is Required" : this.state.formErrors.addtionalMobileNoError;

    if (formValid(this.state.formErrors)) {
      let changeInfo = new FormData();
      changeInfo.append("userId", localStorage.userId)
      changeInfo.append("customerId", this.state.customerId)
      changeInfo.append("mobileNo", newMobileNo)
      this.setState({ isLoadingPop: true, changeMobileNoPrimery: false })
      CustomersService.ChangeMobileNumber(changeInfo)
        .then(
          (res) => {
            NotificationManager.success(res.data.message, `${process.env.REACT_APP_MSG_TITLE}`);
            this.setState({ isLoadingPop: false })
            this.searchCustomerBasedOnFilter();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        });

    }
    this.setState({
      formErrors
    })
  };

  onChangeMobileNoPrimery = mobile => {
    const { formErrors } = this.state
    formErrors.ChangedMobileNoError =
      mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites with a min 12 " : ""
    this.setState({
      newMobileNo: mobile,
      formErrors
    })

  }


  toggleChangePassword = id => {

    this.setState({
      id: id,
      isResetOpen: !this.state.isResetOpen
    });
  }

  toggleChangePIN = id => {

    this.setState({
      id: id,
      isResetPIN: !this.state.isResetPIN
    });
  }

  resetPassword(id) {

    if (window.confirm("Are you sure you want to reset password for this customer?")) {


      let changeInfo = new FormData();
      changeInfo.append("userId", localStorage.userId)
      changeInfo.append("customerId", id)

      this.setState({ isLoadingPop: true })
      CustomersService.ResetPasswordPortal(changeInfo)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_RESET_PASSWORD_SUCCESS_MEG}`, `${process.env.REACT_APP_MSG_TITLE}`);
            this.setState({ isLoadingPop: false })
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false })
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        });


    }
  };


  resetPIN(id) {
    if (window.confirm("Are you sure you want to reset PIN for this customer?")) {
      let changeInfo = new FormData();
      changeInfo.append("userId", localStorage.userId)
      changeInfo.append("customerId", id)
      this.setState({ isLoadingPop: true })
      CustomersService.ResetPINPortal(changeInfo)
        .then(
          (res) => {
            NotificationManager.success(res.data.message, `${process.env.REACT_APP_MSG_TITLE}`);
            this.setState({ isLoadingPop: false })
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false })
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        });

    }

  };


  render() {
    const { isNewId, CustomerList, formErrors, pageNo, pageSize, isLoading, totalPage, allCustomerTypes, isCompany } = this.state;
    pageNo == 1 ? this.state.counter = pageNo : this.state.counter = (pageSize * (pageNo - 1)) + 1



    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        < div >
          {/* <Header />
          <Menu /> */}
          <NotificationContainer />
          {/* Content Wrapper. Contains page content */}

          <div className="content-wrapper bg-white">

            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">

                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Customer List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Customer List Information</div>
                    {isCompany ?
                      <>
                        <form onSubmit={this.clear} hidden={this.state.isExpand} >
                          <div className="row" hidden={this.state.isExpand}>
                            <div className="col-md-4">
                              <div>

                                <label htmlFor='mobileNo' >Mobile No</label>

                                <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                  country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                  placeholder="Enter phone number" id='mobileNo'
                                  inputStyle={{ width: '100%', height: 'auto' }}
                                  onChange={phone => this.onChangeMobileNo(phone)} />
                              </div>
                              <div>
                                <label htmlFor='accountNo' > Account No</label>
                                <input type="text" className="form-control" id='accountNo'
                                  onChange={e => this.state.accountNo = e.target.value}
                                  placeholder="Account No" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor='fullName'>Full Name</label>
                              <div className="w-90 pull-left">
                                <input type="text" className="form-control" id='fullName'
                                  onChange={e => this.state.firstName = e.target.value}
                                  placeholder="Name" />
                              </div>
                              <label htmlFor='customerType'>Customer Type</label>
                              <div className="w-90 pull-left">
                                <select onChange={e => this.state.customerType = e.target.value} className="form-control">
                                  <option value=""> Choose type</option>
                                  {allCustomerTypes.map(type =>
                                    <option value={type.id}>{type.type}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor='dateFrom' >Date From</label>
                              <div className="w-90 pull-left">
                                <input type="date" className="form-control" id='dateFrom'
                                  onChange={e => this.state.dateFrom = e.target.value}
                                  placeholder="Date" />
                                {formErrors.dateFromError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                )}
                              </div>

                              <label>Date To</label>
                              <div className="w-90 pull-left">
                                <input type="date" className="form-control" id='dateFrom'
                                  onChange={e => this.state.dateTo = e.target.value}
                                  placeholder="Date" />
                                {formErrors.dateToError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                )}
                              </div>
                            </div>
                          </div>


                          <br />

                          <div>
                            <button type="button" title={"Search"}
                              className="btn btn-sm btn-success primary m-1 float-right"
                              onClick={() => this.searchCustomerInfo()}>Search</button>
                            <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                              className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                          </div>
                        </form>
                        <div style={{ clear: 'both' }}></div>
                        <br />
                        {isCompany &&
                          <Link to={{ pathname: '/addCustomer', state: { type: "Agent", formName: "Customer Registration" } }}>
                            <button type="button" className="btn btn-sm btn-success float-left mb-2" >
                              <i className="fas fa-plus" /> Add
                            </button>
                          </Link>
                        }
                        <button type='button' style={{ marginLeft: "3px" }} title={"Show/Hide Filter"}
                          className="btn btn-sm btn-success primary float-left mb-2"
                          onClick={() => this.filterToggle()}>
                          <FontAwesomeIcon icon={faFilter} />
                          Filter
                        </button>
                        <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                          <label htmlFor='Size'>Row #:</label>
                          <select value={pageSize}
                            onChange={e => this.actionPageSize(e)} name='Size'>
                            <option key={"20"} value={20}>20</option>
                            <option key={"50"} value={50}>50</option>
                            <option key={"500"} value={500}>500</option>
                            <option key={"1000"} value={1000}>1000</option>
                            <option key={"10000"} value={10000}>10000</option>
                          </select>
                        </div>
                        <div style={{ clear: 'both' }}></div>

                      </>
                      :
                      ''
                    }

                    {isLoading &&
                      <div className='tableContainer'>
                        <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }}  >
                          <thead className='thead-light'>
                            <tr style={{ marginTop: 0 }}>
                              <th>No.</th>
                              <th>Full Name</th>
                              <th>Account #</th>
                              <th>Mobile #</th>
                              <th>Wallet $</th>
                              <th>Topup $</th>
                              <th>Type</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>

                            {CustomerList != "" && CustomerList.map(customer =>
                            
                              <tr key={customer.id} >
                                <td>{this.state.counter++}</td>

                                <td>
                                  <Link to={{ pathname: '/viewCustomer', state: { CustomerType: "Agent", operationType: "View", mobileNo: customer.mobileNo } }}>{customer.fullName}</Link>
                                </td>
                                <td>
                                  <Link to={{ pathname: '/viewCustomer', state: { CustomerType: "Agent", operationType: "View", mobileNo: customer.mobileNo } }}>{customer.accountCode}</Link>
                                </td>
                                <td>{customer.mobileNo}</td>
                                <td style={{ textAlign: "right" }}><NumberFormat value={parseFloat(customer.walletBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} /></td>

                                <td style={{ textAlign: "right" }}><NumberFormat value={parseFloat(customer.topupBaseBalance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
                                <td>{customer.type}</td>
                                <td>
                                  {isCompany &&
                                    <button disabled={this.state.isLoadingPop || customer.status !== 0} style={{ marginLeft: "2px" }}
                                      onClick=
                                      {() => customer.createdBy !== null && customer.createdBy !== undefined ?
                                        this.ActivateNewAccount(customer.id) : this.toggleActivation(customer.id)}
                                      className="btn btn-success btn-sm action-buttons"
                                      title={"Activate New Account"} ><i className="nav-icon fas fa-unlock fa-sm action-button-icon" /> </button>
                                  }
                                  {isCompany && localStorage.status === 'true' && customer.status !== 0 &&
                                    <button title={"Chamge Mobile No"}
                                      style={{ marginLeft: "2px" }}
                                      onClick={() => this.toggleChangeMobileNo(customer.id)}
                                      className="btn btn-danger btn-sm action-buttons">
                                      <i className="nav-icon fas fa-mobile " />
                                    </button>
                                  }
                                   {isCompany &&
                                    <button disabled={customer.status !== 0} style={{ marginLeft: "2px" }}                                    
                                    onClick={() => this.deleteCustomer(customer.id)} className="btn btn-danger btn-sm action-buttons" title={"Delete"} ><i className="nav-icon fa fa-trash fa-sm" /> 
                                    </button>
    
                                  }
                                  {isCompany && customer.status === 1 &&
                                    <Link to={{ pathname: '', state: { mobileNo: customer.mobileNo, operation: "deactivation" } }}>
                                      <button style={{ marginLeft: "2px" }} onClick={() => this.disableCustomer(customer.mobileNo)} className="btn btn-success btn-sm action-buttons"><i className="nav-icon fa fa-thumbs-up action-button-icon" title="Disable Account" /> </button>
                                    </Link>
                                  }
                                  {isCompany && customer.status === 2 &&
                                    <Link to={{ pathname: '', state: { mobileNo: customer.mobileNo, operation: "deactivation" } }}>
                                      <button style={{ marginLeft: "2px" }} onClick={() => this.enableCustomer(customer.mobileNo)} className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-thumbs-up action-button-icon" title="Enable Account" /> </button>
                                    </Link>
                                  }
                                  {
                                    <Link to={{ pathname: '/viewCustomer', state: { CustomerType: "Agent", id: customer.id, mobileNo: customer.mobileNo } }}>
                                      <button className="btn btn-info btn-sm action-buttons" style={{ marginLeft: "2px" }} title="View detail"><i className="nav-icon fa fa-eye fa-sm action-button-icon" /> </button>
                                    </Link>
                                  }
                                  {customer.status !== 0 &&
                                    <button style={{ marginLeft: "2px" }} onClick={() => this.editCustomer(customer.id)} title="Edit Customer" className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm action-button-icon" /> </button>
                                  }
                                  {isCompany && customer.status !== 0 &&
                                    <Link to={"/customerGoogleMapLocationList?lat=" + `${customer.latitude}` + "&lng=" + `${customer.longitude}`} target={'_blank'}>
                                      <button style={{ marginLeft: "2px" }} className="btn btn-primary btn-sm action-buttons"><i className="nav-icon fas fa-map-marker-alt" aria-hidden="true" title='View Location' /> </button>
                                    </Link>
                                  }
                                  {isCompany && customer.status !== 0 &&
                                    <button title={"Additional Mobile No"}
                                      style={{ marginLeft: "2px" }}
                                      onClick={() => this.toggleAdditionalMobileNo(customer.id)}
                                      className="btn btn-primary btn-sm action-buttons">
                                      <i className="nav-icon fas fa-mobile " />
                                    </button>
                                  }
                                 
                                   {isCompany && customer.status === 1 &&
                                    <Link to={{ pathname: '', state: { mobileNo: customer.mobileNo, operation: "link" } }}>
                                      <button style={{ marginLeft: "2px" }} onClick={() => this.linkCustomer(customer.mobileNo)} 
                                      className={ customer.topupLinked ?  "btn btn-success btn-sm action-buttons" : "btn btn-warning btn-sm action-buttons"}>
                                      <i className="nav-icon fa fa-link action-button-icon" title={ customer.topupLinked ?"unLink topup": "Link topup"} /> </button>
                                    </Link>
                                  }
                                  {isCompany && customer.status !== 0 &&
                                    <button title={"Reset PIN"}
                                      style={{ marginLeft: "2px" }}
                                      onClick={() => this.resetPIN(customer.id)}
                                      className="btn btn-danger btn-sm action-buttons">
                                      <i className="nav-icon fas fa-key fa-sm" />
                                    </button>
                                  }
                                  {isCompany && customer.status !== 0 &&
                                    <button title={"Reset password"}
                                      style={{ marginLeft: "2px" }}
                                      onClick={() => this.resetPassword(customer.id)}
                                      className="btn btn-danger btn-sm action-buttons">
                                      <span class="fa-passwd-reset fa-stack fa-sm ">
                                        <i class="nav-icon fa fa-undo fa-sm fa-stack-2x "></i>
                                        <i class=" nav-icon fa fa-lock fa-sm fa-stack-1x "></i>
                                      </span>
                                    </button>
                                  }

                                  {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.viewLocation(customer.latitude,customer.longitude) } target="_blank" className="btn btn-primary btn-sm action-buttons"><i className="nav-icon fas fa-map-marker-alt" aria-hidden="true" title='View Location'/> </button>  */}

                                </td>



                              </tr>

                            )
                            }

                          </tbody>
                        </table>
                      </div>
                    }
                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{''}
                        <strong>
                          {pageNo} of {totalPage}
                        </strong>
                        {''}
                      </span>
                      <span>
                        | Go To page : {''}
                        <input type='number' defaultValue={pageNo}
                          onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          }} />
                      </span>
                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackward} />
                      </button>

                      <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                        <FontAwesomeIcon icon={faForward} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                        <FontAwesomeIcon icon={faForwardFast} />
                      </button>
                    </div>


                    {this.state.isOpen && <Popup
                      content={<>

                        <form onSubmit={this.handleSubmit} noValidate>
                          <div className="card my-4 border-radius-0" >
                            <div className="card-header border-radius-0 bg-light">
                              <h6 class="title" >Additional Identity Information </h6>
                            </div>
                            <div className="card-body">
                              <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='serviceCode'> Id Number:</label>
                                <input type="text" id='idNumber' className="form-control" name='idNumber'
                                  onChange={this.handelChange} onBlur={this.handelChange}
                                  placeholder="Id Number" />

                                {formErrors.idNumberError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.idNumberError}</span>
                                )}
                              </div>

                              <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12" >
                                <label style={{ marginLeft: "20px" }} htmlFor='nwId'>New Id Card:  </label>
                                <input type="Checkbox" style={{ marginLeft: "10px" }} id="newId"
                                  checked={this.state.isNewId}
                                  onChange={e => { this.onChangeSuper(); this.state.isNewId = e.target.checked }}
                                />

                                <label style={{ marginLeft: "20px" }} htmlFor='oldId'>Old Id Card: </label>
                                <input type="Checkbox" style={{ marginLeft: "10px" }} id="IsOldId"
                                  checked={this.state.isOldId}
                                  onChange={e => { this.onChangeAdmin(); this.state.isOldId = e.target.checked }}
                                />

                              </div>
                              <div className='row mb-2'>

                                <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                  <label htmlFor='issueDate'> Issue Date:</label>

                                  <input type="date" id='issueDate' className="form-control" name='issueDate'
                                    onChange={this.handelChange} onBlur={this.handelChange}
                                    placeholder="Issue Date" />

                                  {formErrors.issueDateError.length > 0 && (
                                    <span style={{ color: "#F61C04" }}>{formErrors.issueDateError}</span>
                                  )}
                                </div>


                                {!isNewId ?
                                  <>

                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='pageNoDB'>Page No:</label>
                                      <input type="text" id='pageNoDB' className="form-control" name='pageNoDB'
                                        onChange={this.handelChange} onBlur={this.handelChange}
                                        placeholder="Page No" />

                                      {formErrors.pageNoError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.pageNoError}</span>
                                      )}
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='registryNo'>Registry No:</label>
                                      <input type="text" id='registryNo' className="form-control" name='registryNo'
                                        onChange={this.handelChange} onBlur={this.handelChange}
                                        placeholder="Registry No" />

                                      {formErrors.registryNoError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.registryNoError}</span>
                                      )}
                                    </div>
                                    <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='currency'>Volume No:</label>
                                      <input type="text" id='volumeNo' className="form-control" name='volumeNo'
                                        onChange={this.handelChange} onBlur={this.handelChange}
                                        placeholder="Volume No" />

                                      {formErrors.volumeNoError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.volumeNoError}</span>
                                      )}

                                    </div>
                                  </>

                                  :
                                  ""
                                }
                              </div>
                            </div>
                            <div className="row  justify-content: space-between; ">
                              <button disabled={this.state.isLoadingPop} type="submit" title={"Save and activate"}
                                className="btn btn-sm btn-success  "
                                style={{ marginLeft: "30px" }}>
                                <i className="nav-icon  fas fa-unlock fa-sm" style={{ marginRight: "5 px" }} />
                                Save and activate
                              </button>
                              <button type="submit" onClick={() => this.toggleActivation()}
                                className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                            </div>
                          </div>
                        </form>

                      </>}
                    />}
                  </div>
                </div>
                {this.state.addMobileNo && <Popup
                  content={<>

                    <form onSubmit={this.addAdditionalMobileNo} noValidate>
                      <div className="card">
                        <div className="card-body" >

                          <div className="form-group col-12 col-sm-6 col-md-6 col-lg-12">
                            <label htmlFor='mobileNo' >Mobile No:</label>
                            <PhoneInput countryCodeEditable={false}
                              value={this.state.mobileNo}
                              country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                              inputStyle={{ width: '100%', height: 'auto' }}
                              placeholder="Enter phone number" id='mobileNo'
                              onChange={phone => this.onChangeMobileNoPop(phone)} />
                            {formErrors.addtionalMobileNoError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.addtionalMobileNoError}</span>
                            )}

                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row  justify-content: space-between; ">
                            <button type="submit" title={"Add Mobile #"}
                              className="btn btn-sm btn-success  "
                              style={{ marginLeft: "30px" }}>
                              Add
                              <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faMobile} />
                            </button>
                            <button type="submit" onClick={() => this.toggleAdditionalMobileNo()}
                              className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                          </div>

                        </div>
                      </div>

                    </form>

                  </>}
                />}


                {this.state.changeMobileNoPrimery && <Popup
                  content={<>

                    <form onSubmit={this.changeMobileNo} noValidate>
                      <div className="card">
                        <div className="card-body" >

                          <div className="form-group col-12 col-sm-6 col-md-6 col-lg-12">
                            <label htmlFor='mobileNo' >Mobile No:</label>
                            <PhoneInput countryCodeEditable={false}
                              value={this.state.newMobileNo}
                              country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                              inputStyle={{ width: '100%', height: 'auto' }}
                              placeholder="Enter phone number" id='mobileNo'
                              onChange={phone => this.onChangeMobileNoPrimery(phone)} />
                            {formErrors.ChangedMobileNoError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.ChangedMobileNoError}</span>
                            )}

                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row  justify-content: space-between; ">
                            <button type="submit" title={"Change Mobile #"}
                              className="btn btn-sm btn-success  "
                              style={{ marginLeft: "30px" }}>
                              Change
                              <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faMobile} />
                            </button>
                            <button type="submit" onClick={() => this.toggleChangeMobileNo()}
                              className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                          </div>

                        </div>
                      </div>

                    </form>

                  </>}
                />}


                {this.state.isResetOpen && <Popup
                  content={<>

                    <form className='' onSubmit={this.resetPassword} noValidate>
                      <div className="card">
                        <div className="card-body" >

                          <div>
                            {formErrors.passwordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.passwordError}</span>
                            )}
                          </div>
                          <div className="clearfix">&nbsp;</div>

                          <div className="input-group justify-content-left">
                            <div className="input-group-text  bg-transparent p-0">
                              <FontAwesomeIcon icon={faKey} />
                            </div>
                            <div className="input-group-append p-0">
                              <input type="password" className="form-control" id='newPassword'
                                onChange={this.handelChange} onBlur={this.handelChange} name='newPassword'
                                placeholder="New Password" />


                            </div>
                            {formErrors.newPasswordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.newPasswordError}</span>
                            )}
                          </div>
                          <div className="clearfix">&nbsp;</div>
                          <div className="input-group justify-content-left">
                            <div className="input-group-text  bg-transparent p-0">
                              <FontAwesomeIcon icon={faKey} />
                            </div>
                            <div className="input-group-append p-0">
                              <input type="password" className="form-control" id='confirmPassword'
                                onChange={this.handelChange} onBlur={this.handelChange} name='confirmPassword'
                                placeholder="Confirm Password" />


                            </div>
                            {formErrors.confirmPasswordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.confirmPasswordError}</span>
                            )}
                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row  justify-content: space-between; ">
                            <button type="submit" title={"Reset Password"}
                              className="btn btn-sm btn-success  "
                              style={{ marginLeft: "30px" }}>
                              Reset Password
                              <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faKey} />
                            </button>
                            <button type="submit" onClick={() => this.toggleChangePassword()}
                              className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                          </div>

                        </div>
                      </div>

                    </form>

                  </>}
                />}

                {this.state.isResetPIN && <Popup
                  content={<>

                    <form className='' onSubmit={this.resetPIN} noValidate>
                      <div className="card">
                        <div className="card-body" >

                          <div>
                            {formErrors.passwordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.passwordError}</span>
                            )}
                          </div>
                          <div className="clearfix">&nbsp;</div>

                          <div className="input-group justify-content-left">
                            <div className="input-group-text  bg-transparent p-0">
                              <FontAwesomeIcon icon={faKey} />
                            </div>
                            <div className="input-group-append p-0">
                              <input type="password" className="form-control" id='newPassword'
                                onChange={this.handelChange} onBlur={this.handelChange} name='newPassword'
                                placeholder="New PIN" />


                            </div>
                            {formErrors.newPasswordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.newPasswordError}</span>
                            )}
                          </div>
                          <div className="clearfix">&nbsp;</div>
                          <div className="input-group justify-content-left">
                            <div className="input-group-text  bg-transparent p-0">
                              <FontAwesomeIcon icon={faKey} />
                            </div>
                            <div className="input-group-append p-0">
                              <input type="password" className="form-control" id='confirmPassword'
                                onChange={this.handelChange} onBlur={this.handelChange} name='confirmPassword'
                                placeholder="Confirm PIN" />


                            </div>
                            {formErrors.confirmPasswordError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.confirmPasswordError}</span>
                            )}
                          </div>

                          <div className="clearfix">&nbsp;</div>

                          <div className="row  justify-content: space-between; ">
                            <button type="submit" title={"Reset PIN"}
                              className="btn btn-sm btn-success  "
                              style={{ marginLeft: "30px" }}>
                              Reset PIN
                              <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faKey} />
                            </button>
                            <button type="submit" onClick={() => this.toggleChangePIN()}
                              className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                          </div>

                        </div>
                      </div>

                    </form>

                  </>}
                />}


                {this.state.isLoadingPop && <LoadingPopup
                  content={<>
                    <form>
                      <ReactLoading type="bars" color="#0000FF" height={50} width={50} />
                    </form>
                  </>}
                />}
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            {/* /.content */}
          </div >
        </div >
      )
    }
  }
}
export default Customers