import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../Menu';
import RegionService from '../../../services/common/RegionService';
import MerchantService from '../../../services/wallet/MerchantService';

import Header from '../../common/Header';


export default class EditMerchant extends React.Component {
    constructor() {
        super();

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeTel = this.onChangeTel.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeStreet = this.onChangeStreet.bind(this);
        this.onChangeLogo = this.onChangeLogo.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);

        this.editMerchant = this.editMerchant.bind(this);

        this.handleAttachment = this.handleAttachment.bind(this);

        this.state = {
            merchantData: {
                name: "",
                province: '',
                city: "",
                tel: "",
                email: "",
                street: "",
                website: "",
                logo: ""
            },
            source: null,
            regionList: []
        };
    }

    handleAttachment(event) {
        this.setState({
            logo: event.target.files[0],
        })
    }

    onChangeName(e) {
        const name = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    name: name
                }
            };
        });
    }

    onChangeProvince(e) {
        const province = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    province: province
                }
            };
        });
    }

    onChangeCity(e) {
        const city = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    city: city
                }
            };
        });
    }
    onChangeTel(e) {
        const tel = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    tel: tel
                }
            };
        });
    }
    
    onChangeEmail(e) {
        const email = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    email: email
                }
            };
        });
    }

 

    onChangeStreet(e) {
        const street = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    street: street
                }
            };
        });
    }


    onChangeWebsite(e) {
        const website = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    website: website
                }
            };
        });
    }
    onChangeLogo(e) {
        const logo = e.target.value;

        this.setState(function (prevState) {
            return {
                merchantData: {
                    ...prevState.merchantData,
                    logo: logo
                }
            };
        });
    }
    componentDidMount() {
      
        this.getMerchantByTel(this.props.location.state.mobileNo);

        RegionService.getRegions()
        .then(response => {
          this.setState({
            regionList: response.data
            });
            
          })
          .catch(e => {
            
          });

    }
    
    getMerchantByTel(mobileNo) {
      
        MerchantService.getMerchantByTel(mobileNo)
            .then(response => {
                this.setState({
                    merchantData: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    editMerchant() {

        var data = new FormData();
        data.append("name", this.state.merchantData.name);
        data.append("province", this.state.merchantData.province);
        data.append("tel", this.state.merchantData.tel);
        data.append("city", this.state.merchantData.city);
        data.append("email", this.state.merchantData.email);
        data.append("street", this.state.merchantData.street);
        data.append("website", this.state.merchantData.website);
        data.append('logo', this.state.logo);

        //  console.log("Data"+data);
        MerchantService.updateMerchant(data)
            .then(res => {
                //console.log(res.data);
               // alert("Updated successfully !!");
               // e.target.reset();
            })
            .catch(function (response) {
                //handle error
                //alert("Something went wrong !!");
            });
    }

    //     //  for (var pair of object.entries()) {
    //     //      console.log(pair[0]+ ', ' + pair[1]); 
    //     //  }
    render() {
     
        const { merchantData } = this.state;
        const {regionList} =this.state;
       
        return (

            <div>
                {/* <Header/>
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Merchant</li>
                                    </ol>
                                </div>

                            </div>
                            <div >
                                <div className="row">

                                    <div className='col-2'></div>
                                    <div className='bg-light p-4'>
                                        <h4 className='mb-4 ' style={{ fontFamily: 'serif',textAlign:'center' }}> <img className="rounded-circle" style={{ width: '85px', height: '85px',margin: 'auto',alignSelf: 'center' }} src={"data:image/png;base64," +merchantData.logo}></img></h4>
                                        <form onSubmit={this.handleSubmit}>
                                            {/*<h2>Register</h2> */}

                                            <div className="row  " >
                                                <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                    <input type="text" className="form-control " placeholder="Full Name" value={merchantData.name} onChange={this.onChangeName} required="required" />
                                                </div>
                                                <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                    {/* <input type="text" className="form-control " placeholder="Province" value={merchantData.province} onChange={this.onChangeProvince} required="required" /> */}
                                                    <select value={merchantData.regionId} onChange={this.onChangeProvince} className="form-control" >
                                                        <option value="1">Oromia</option>
                                                        <option value="2">Amhara</option>
                                                        <option value="3">Tigray</option>
                                                       {/*} {regionList.map(getregion =>
                                                        <option value={getregion.id}>{getregion.region}</option>
                                                        )} */}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                                    <input type="text" className="form-control" value={merchantData.tel} onChange={this.onChangeTel} placeholder="Mobile Number" required="required" />
                                                </div>
                                                <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                    <input type="text" className="form-control" value={merchantData.city} onChange={this.onChangeCity} placeholder="City" required="required" />
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                                    <input type="text" className="form-control" value={merchantData.email} onChange={this.onChangeEmail} placeholder="Email" required="required" />
                                                </div>
                                                <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                    <input type="text" className="form-control" value={merchantData.street} onChange={this.onChangeStreet} placeholder="Street" required="required" />
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                                    <input type="text" className="form-control" value={merchantData.website} onChange={this.onChangeWebsite} placeholder="Website" required="required" />
                                                </div>
                                                <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <input type="file" className="form-control" placeholder="Logo" onChange={this.handleAttachment} required="required" />
                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-center ">

                                                <button type="submit" className="btn btn-success  px-5 py-2" onClick={() => this.editMerchant()}>Save</button>

                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-2'></div>

                                </div>
                            </div>


                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}
