import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import {  withRouter } from 'react-router-dom';


import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import OverDraftService from '../../../services/common/OverDraftService';
import CustomersService from '../../../services/common/CustomersService';
import AsyncSelect from "react-select/async";
import Header from '../Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddOverDraft extends React.Component {

    constructor () {

        super();

        this.state = {
            customerList: [],
            amount: 0,
            customerId: '',
            startDate: '',
            endDate: '',
            remark: '',
            isLoading: false,
            selectOption: null,
            formErrors: {
                selectOptionError:"",
                amountError: "",
                startDateError:"",
                endDateError:""
            },
        }
 }
    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getSalesTeamOnlyBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (selectOption) => {
        if (selectOption) {

            this.state.selectOption = selectOption
            this.setState({
                customerId: selectOption.value
            });

        }

    }

    componentDidMount() {
        //alert(this.props.location.state.flag)
        //this.props.location.state.flag
        CustomersService.getCustomers(localStorage.userId)
            .then(response => {

                this.setState({
                    customerList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }
    Cancel = e => {
        this.props.history.push('/overDraftList');
    }
    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target

        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                value.length == 0 || !amountRegEx.test(value) ? "" + " Target should only be digits" : ""
                break;
            case 'startDate':
                formErrors.startDateError =
                value.length == 0 || this.state.startDate>this.state.endDate ? "" + " Start date must be less than end date" : ""
                break;
            case 'endDate':
                formErrors.endDateError =
                value.length == 0 || this.state.startDate>this.state.endDate ? "" + " Start date must be less than end date" : ""
                break;
                default:
                break;
                    }
                this.setState({
                    formErrors, [ name ]: value
                })
           }
    handleSubmit = e => {
        e.preventDefault();

        const { amount,selectOption,startDate,endDate, formErrors } = this.state

        formErrors.amountError = this.state.amount === "" || this.state.amount === null || this.state.amount === undefined || this.state.amount === 0 ? "Please select amount " : this.state.formErrors.amountError;
        formErrors.selectOptionError = this.state.selectOption === "" || this.state.selectOption === null || this.state.selectOption === undefined ? "Please select sales" : this.state.formErrors.selectOptionError;
        formErrors.startDateError = this.state.startDate === "" || this.state.startDate === null || this.state.startDate === undefined ? "Start date is Required" : this.state.formErrors.startDateError;
        formErrors.endDateError = this.state.endDate === "" || this.state.endDate === null || this.state.endDate === undefined ? "End date is Required" : this.state.formErrors.endDateError;

        if (formValid(this.state.formErrors)) {
        const OverDraftData = new FormData();
        OverDraftData.append("amount", this.state.amount);
        OverDraftData.append("customerId", this.state.customerId);
        OverDraftData.append("startDate", this.state.startDate);
        OverDraftData.append("endDate", this.state.endDate);
        OverDraftData.append("remark", this.state.remark);
        OverDraftData.append("userId", localStorage.userId);
        if(this.state.startDate > this.state.endDate){
            NotificationManager.error("End date must be greater than start date",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);                
           }
        else{
            this.setState({ isLoading: true})
        OverDraftService.createOverDraft(OverDraftData)
            .then(
                (response) => {
                NotificationManager.success("Successfully Created",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);                
               // this.setState({ isLoading: false})
                e.target.reset();
            },
            (error)=> {
                NotificationManager.error(error.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000 );
             //   this.setState({ isLoading: false})
            })
            .catch(function (error) {
                NotificationManager.error(error.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000 );
              //  this.setState({ isLoading: false})
            });
           }
        } else {
           // NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    };


    render() {
        const { formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <Menu /> */}
                <NotificationContainer />
         
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">
         
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add Overdraft</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                    <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Overdraft Information </h6>
                                                </div>
                                                <div className="card-body bg-light">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-10 col-lg-10">
                                                            <label htmlFor='customerId'>Sales Name:</label>
                                                            <div style={ { fontSize: 'smaller' } }>
                                                                <AsyncSelect id='CustomerList'
                                                                    className=" form-group col-10 col-sm-10 col-md-10 col-lg-10  "
                                                                    placeholder={ "Search by name, account no or mobile number" }
                                                                    value={ this.state.selectOption }
                                                                    isClearable={ true }
                                                                    loadOptions={ this.fatchData }
                                                                    isLoading={ this.state.selectOption === {} &&
                                                                        this.state.selectOption === undefined &&
                                                                        this.state.selectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false } 
                                                                />

                                                                  { formErrors.selectOptionError.length > 0 && (
                                                                   <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                                   ) }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Target:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='startDate'>From:</label>
                                                            <input type="date" className="form-control " id='startDate' placeholder="Start Date" name="startDate"

                                                                    onChange={ this.handelInputChange } onBlur={ this.handelInputChange }  />
                                                               { formErrors.startDateError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.startDateError }</span>
                                                            ) }
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='endDate'>To:</label>
                                                            {/* <PhoneInput maxLength={13} onChange={e => this.mobileNo = e.target.value}/> */ }
                                                            <input type="date" className="form-control " id='endDate' placeholder="End Date" 
                                                            onChange={ this.handelInputChange } onBlur={ this.handelInputChange } name="endDate" />
                                                            { formErrors.endDateError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.endDateError }</span>
                                                            ) }
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='remark'>Remark :</label>
                                                            <input type="text" className="form-control " id='remark' onChange={ e => this.state.remark = e.target.value } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button  disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddOverDraft);
