import API from '../../../src/http-common';

class CountryService {
    getCountries(userId) {
        return API.get('/api/v1/getAllCountries/' + userId);
    }
    getCountryByCountryCode(countryCode, userId)
    {
        return API.get('/api/v1/getCountryByCountryCode/'+countryCode + "/" + userId);
    }

    createCountry(country) {
        return API.post("/api/v1/createCountry", country);
    }
    // deleteMerchant(mobileNo) {
    //     return API.delete("/api/v1/deleteMerchant/" + mobileNo);
    // }
    // updateMerchant(merchant) {
    //     return API.put("api/v1/updateMerchant", merchant);
    // }
}
export default new CountryService()