
import CashInfoService from '../../../services/etopup/CashInfoService';
import CustomerListService from '../../../services/common/CustomersService';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Link } from 'react-router-dom';
import { LOCALES } from '../../common/Constant'
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';

export default class CashInDetail extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            id: this.props.location.state.id,
            mobileNo: this.props.location.state.mobileNo,
            customerBank: [],
            bankInfoId: [],
            verifierUserName:'',
            appoverUserName:'',
            createrUserName:'',
            cashInData: [{
                customerId: [],
                senderBankId: [],
                receiverBankId: [],
                accountNo: "",
                senderMobileNo: "",
                receiverMobileNo: "",
                amount: "",
                description: "",
                personalName: "",
                personalBankName: "",
                personalBankAccount: "",
                senderBankName: "",
                senderBankAccount: "",
                receiverBankName: "",
                receiverBankAccount: "",


                // attachment: ""
            }]
        };
    }

    componentDidMount() {
        this.getCustomerBankInfo(this.state.mobileNo)
        this.getCashInfoById(this.state.id);

    }

    getVerifierUserInfo(userId) {
        LoginService.getUserInfo(userId)
            .then(response => {
                this.setState({
                    verifierUserName: response.data.fullName
                });

            })
            .catch(e => {
                
            });
    }

    getApproverUserInfo(userId) {
        LoginService.getUserInfo(userId)
            .then(response => {
                this.setState({
                    appoverUserName: response.data.fullName
                });

            })
            .catch(e => {
                
            });
    }

    getCreaterUserInfo(userId) {
        LoginService.getUserInfo(userId)
            .then(response => {
                this.setState({
                    createrUserName: response.data.fullName
                });

            })
            .catch(e => {
                
            });
    }

    getCustomerBankInfo(mobileNo) {
        CustomerListService.getCustomerBankInfoByMobile(mobileNo, LOCALES.DEFAULT_CUSTOMER, localStorage.userId)
            .then(response => {
                this.setState({
                    customerBank: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    getCashInfoById(id) {
        CashInfoService.getCashIn(id, localStorage.userId)
            .then(response => {
                this.setState({
                    cashInData: response.data
                });
            })
            .catch(e => {
                
            });


    }

    render() {
        const { cashInData,appoverUserName, verifierUserName, createrUserName, customerBank, bankInfoId } = this.state;

        if (cashInData !== null && (cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED ||
            cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT || 
            cashInData.verifyBy !== null)) {
            this.getVerifierUserInfo(cashInData.verifyBy)
        }
      
        if (cashInData !== null && (cashInData.cashStatus === LOCALES.SMARTPAY_CASH_APPROVED ||
            cashInData.cashStatus === LOCALES.SMARTPAY_CASH_APPROVEREJECT)) {
            this.getApproverUserInfo(cashInData.approveBy)
        }

        if (cashInData !== null ) {
            this.getCreaterUserInfo(cashInData.createrUserId)
        }



        return (
            <div>
                {/* <Header />
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">

                        <div className="row mb-2">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Cash In Detail
                                    </li>
                                </ol>
                            </div>

                        </div>

                        <div >
                            <div className="card ">
                                <div className="card-body">
                                    <div className="form-title" >Cash In Detail</div>
                                    <hr />
                                    <div className="table-responsive w-50">
                                        <table className="table table-bordered mb-5" style={{ fontSize: 'smaller' }}>
                                            <tr className="subheaders">
                                                <td colspan="2" className="text-white py-4" > Cash In Detail Information</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Mobile No.</td>
                                                <td className="text-justify">{cashInData.receiverMobileNo}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Account No</td>
                                                <td className="text-justify">{cashInData.accountCode}</td>
                                            </tr>
                                            <tr >
                                                <td className="text-left font-weight-bolder" >Amount</td>
                                                <td className="text-justify">{cashInData.amount}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder">Description</td>
                                                <td className="text-justify">{cashInData.description}</td>
                                            </tr>
                                            <tr >
                                                <td className="text-left font-weight-bolder" > Registered By </td>
                                                <td className="text-justify">{ createrUserName }</td>
                                            </tr>
                                            <tr >
                                                <td className="text-left font-weight-bolder" > Registered Date </td>
                                                <td className="text-justify">{  cashInData.createdAt }</td>
                                            </tr> 
                                            <tr className="subheaders">
                                                <td colspan="2" className="text-blue py-4"> Personal Information</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder"> Name</td>
                                                <td className="text-justify">{cashInData.receiverFullName}</td>
                                            </tr>

                                            <tr>
                                                <td className="text-left font-weight-bolder" > Bank Name</td>
                                                <td className="text-justify">{customerBank.bankName}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder"> Bank Account</td>
                                                <td className="text-justify">{customerBank.bankAccount}</td>
                                            </tr>
                                            <tr className="subheaders" hidden={cashInData.senderBankId == null}>
                                                <td colspan="2" className="text-blue py-4"> Sender Bank Information</td>
                                            </tr>
                                            <tr hidden={cashInData.senderBankId == null}>
                                                <td className="text-left font-weight-bolder" >Bank Name</td>
                                                <td className="text-justify">{ cashInData.senderBankName}</td>
                                            </tr>

                                            <tr hidden={cashInData.senderBankId == null}>
                                                <td className="text-left font-weight-bolder" >Back Account</td>
                                                <td className="text-justify">{cashInData.senderBankAccount}</td>
                                            </tr>

                                            <tr className="subheaders" hidden={cashInData.receiverBankId == null}>
                                                <td colspan="2" className="text-blue py-4"> Receiver Bank Information</td>
                                            </tr>
                                            <tr hidden={cashInData.receiverBankId == null}>
                                                <td className="text-left font-weight-bolder">Back Name</td>
                                                <td className="text-justify">{ cashInData.receiverBankName}</td>
                                            </tr >
                                            <tr hidden={cashInData.receiverBankId == null}>
                                                <td className="text-left font-weight-bolder">Back Account</td>
                                                <td className="text-justify">{cashInData.receiverBankAccount}</td>
                                            </tr>
                                            <tr className="subheaders" hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED}>
                                                <td colspan="2" className="text-blue py-4"> Verification Process  Information</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED}>
                                                <td className="text-left font-weight-bolder" > Verified By </td>
                                                <td className="text-justify"> {verifierUserName}
                                                </td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED}>
                                                <td className="text-left font-weight-bolder" > Verification Status </td>
                                                <td className="text-justify">{cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ? "Rejected" : "Verified"}</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED}>
                                                <td className="text-left font-weight-bolder" > Verification Date </td>
                                                <td className="text-justify">{cashInData.verifyDate }</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED}>
                                                <td className="text-left font-weight-bolder" > Description </td>
                                                <td className="text-justify">{cashInData.verifyDescription }</td>
                                            </tr>

                                            <tr className="subheaders" hidden={
                                                cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED}>
                                                <td colspan="2" className="text-blue py-4"> Approval Process  Information</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED
                                            }>
                                                <td className="text-left font-weight-bolder" > Approved By </td>
                                                <td className="text-justify">{appoverUserName}</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED}>
                                                <td className="text-left font-weight-bolder" > Approve Status </td>
                                                <td className="text-justify">{cashInData.cashStatus === LOCALES.SMARTPAY_CASH_APPROVED ? "Approved" : "Rejected"}</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED}>
                                                <td className="text-left font-weight-bolder" > Approved Date </td>
                                                <td className="text-justify">{cashInData.approveDate }</td>
                                            </tr>
                                            <tr hidden={cashInData.cashStatus === LOCALES.CASH_STATUS_REGISTERED ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIEDREJECT ||
                                                cashInData.cashStatus === LOCALES.SMARTPAY_CASH_VERIFIED}>
                                                <td className="text-left font-weight-bolder" > Approve Description </td>
                                                <td className="text-justify">{ cashInData.approveDescription }</td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className="row  d-flex justify-content-left px-4 py-2  ">

                                <Link to="/cashInList">
                                    <button type="submit" className="btn btn-success  px-5 py-2">Back</button>
                                </Link>
                            </div>
                        </div>

                        <div className='col-2'></div>




                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
            </div>

        )
    }
}