import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import SalesTeamAssignmentService from '../../../services/etopup/SalesTeamAssignmentService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddSalesTeamAssignment extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            createdBy: '',
            selectOption: null,
            mainDealerSelectOption: null,
            salesAssignmentList: [],
            isLoading: false,
            pageNo: 1,
            customerId: '',
            createdBy: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                selectOptionError: "",
                mainDealerSelectOptionError: ""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);
        // this.onChangeMainDealers = this.onChangeMainDealers.bind(this)

    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;
        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    fetchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getSalesTeamOnlyBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (selectOption) => {
        if (selectOption) {

            this.state.selectOption = selectOption
            this.setState({
                customerId: selectOption.value
            });
            this.getSalesTeamAssignmentBySalesTeamId(this.state.selectOption.value, localStorage.userId)
        }

    }

    fetchMainDealers = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getMainDealers/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            mainDealersList: data,
                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
          }
    }

    onMainDealerSearchChange = (mainDealerSelectOption) => {
        if (mainDealerSelectOption) {
            this.state.mainDealerSelectOption = mainDealerSelectOption
            this.setState({
                customerId: mainDealerSelectOption.value
            });
        }
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });
    }

    getSalesTeamAssignmentBySalesTeamId(customerId, userId) {
       
        if (customerId) {
            SalesTeamAssignmentService.getSalesTeamAssignmentBySalesTeamId(customerId, userId)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        salesAssignmentList: response.data
                    });
                    
                })
                .catch(e => {
                    
                });


        }
    }

    Cancel = (e) => {
        this.props.history.push('/salesTeamAssignmentList');
    }

    handleSubmit(e) {   
        e.preventDefault();
        const { providerId, amount, mainDealerSelectOption, selectOption, formErrors } = this.state

        formErrors.selectOptionError = selectOption == '' || selectOption == null || selectOption == undefined ? 'Please select sales' : this.state.formErrors.selectOptionError;
        formErrors.mainDealerSelectOptionError = mainDealerSelectOption == '' || mainDealerSelectOption == null || mainDealerSelectOption == undefined ? 'Please select main dealer' : this.state.formErrors.mainDealerSelectOptionError;

     

        if (formValid(this.state.formErrors)) {

            var data = new FormData();
            data.append("salesTeamId", this.state.selectOption.value);
            data.append("mainDealerId", this.state.mainDealerSelectOption.value);
            data.append("userId", localStorage.userId);
            data.append("createdBy", localStorage.customerIdentity);

            this.setState({ isLoading: true})
            SalesTeamAssignmentService.createSalesTeamAssignment(data)
                .then(response => {
                    NotificationManager.success("Assigned successfully !! ",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    //this.Cancel()
                    this.setState({
                        mainDealerSelectOption: ''                       
                    })
                    this.setState({ isLoading: false})
                    this.getSalesTeamAssignmentBySalesTeamId(this.state.selectOption && this.state.selectOption.value, localStorage.userId)
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    };
    async RemoveAssignment(mainDealerId) {
        if (window.confirm("Are you sure you want to remove this assignment?")) {

            const response = await SalesTeamAssignmentService.removeSalesTeamAssignment(mainDealerId, localStorage.userId);
            NotificationManager.success('Deleted Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.getSalesTeamAssignmentBySalesTeamId(this.state.selectOption && this.state.selectOption.value, localStorage.userId)
        } 
    }

    handleSubmitAssi = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
      };

    render() {
        const { salesAssignmentList, mainDealers, providersList, formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <Menu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Sales Team Assignment</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-10 bg-light p-3'>

                                        
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Sales Team  </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-5 col-lg-5">
                                                            <label htmlFor='Country'>Select Sales:</label>
                                                            <AsyncSelect id='CustomerList'
                                                                required
                                                                placeholder={ "Search  by name or mobile" }
                                                                value={ this.state.selectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchData }
                                                                isLoading={ this.state.selectOption == {} &&
                                                                    this.state.selectOption === undefined &&
                                                                    this.state.selectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Main Dealer </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-5 col-lg-5 border">
                                                            <label htmlFor='salesTeamAssignment'>Select Main Dealer:</label>
                                                            <AsyncSelect id='mainDealersList'
                                                                required
                                                                placeholder={ "Search  by name or mobile" }
                                                                value={ this.state.mainDealerSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchMainDealers }
                                                                isLoading={ this.state.mainDealerSelectOption == {} &&
                                                                    this.state.mainDealerSelectOption === undefined &&
                                                                    this.state.mainDealerSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onMainDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                        
                                                        <div className=" form-group col-12 col-sm-12 col-md-1 col-lg-1 border ">
                                                        <form onSubmit={ this.handleSubmit }>
                                                            <button disabled={this.state.disabled} type="submit" className="btn btn-success btn-sm mt-4" style={ { width: "140%" } } title="Click here to assign"><i class="fas fa-angle-double-right" style={ { fontSize: "120%" } }></i></button>
                                                            </form>
                                                        </div>
                                                        
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 border">
                                                            <form onSubmit={this.handleSubmitAssi}>
                                                            <div className='tableContainer'>
                                                                <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                                                    <thead >
                                                                        <tr style={ { marginTop: 0 } }>
                                                                            <th colspan="2" className='text-center'>Assigned Dealers</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        { salesAssignmentList !== undefined && salesAssignmentList.map(assignmentList =>

                                                                            <tr key={ assignmentList.id } style={ { height: "20px" } }>
                                                                                <td className='p-1 '>{ assignmentList.delaerFullName}</td>
                                                                                <button disabled={this.state.isLoading}   type="button" // Change or remove the type attribute
                                                                                onClick={ () => this.RemoveAssignment(assignmentList.mainDealerId) } 
                                                                                className="btn btn-danger btn-sm m-1" style={ { width: "55%" } } 
                                                                                title="Remove dealer"><i class="fa fa-minus" style={ { fontSize: "80%" } }></i></button>
                                                                            </tr>

                                                                        ) }

                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </form>
                                                        </div>


                                                    </div>
                                                    <div className="row  d-flex justify-content-center clear-fix ">
                                               
                                                               <button type="submit" onClick={ () => this.Cancel() } className="btn btn-success mr-5 pr-2" title="Return to list " >Back</button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                        </form>
                                </>}
                             />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default AddSalesTeamAssignment;
