import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../../components/wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import KioskService from '../../../services/wallet/KioskService';
import moment from 'moment';
import KioskCustomerAssignmentService from '../../../services/wallet/KioskCustomerAssignmentService';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class KioskList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            currentDate:new Date(),
            date:moment(new Date()).format('yyyy-MM-DD hh:mm:ss'),
            isLoading: false,
            kiosks: [],
            cashCollectorList:[],
            serialNumber: null,
            kioskId: null,
            status: null,
            location: null,
            latitude: null,
            longitude: null,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            type: LOCALES.AGENT,
            formErrors: {
                serialNumberError: "",
                accountNoError: "",
                statusError: "",
                locationError: ""
            },
            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);

    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.seachKioskBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachKioskBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachKioskBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachKioskBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachKioskBasedOnFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.kioskId = null
        this.state.serialNumber = null
        this.state.status = null
        this.state.latitude = null
        this.state.longitude = null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.seachKioskBasedOnFilter()
        }
    }

    searchKioskInfo(e) {
        this.seachKioskBasedOnFilter()
    }

    seachKioskBasedOnFilter() {
        if (this.state.latitude != null && this.state.longitude == null) {
            NotificationManager.error("Please enter the longitude",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        if (this.state.latitude == null && this.state.longitude != null) {
            NotificationManager.error("Please enter the latitude",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        if (this.state.latitude == null && this.state.longitude == null) {
            this.setState({ location: null })
        }
        if (this.state.latitude != null && this.state.longitude != null) {
            this.setState({ location: this.state.latitude + ',' + this.state.longitude })
        }

        let kioskFilter = {
            kioskId: this.state.kioskId,
            serialNumber: this.state.serialNumber,
            status: this.state.status,
            location: this.state.location,
        }
      

        KioskService.getAllKiosks(
            this.state.pageNo, this.state.pageSize, localStorage.userId, kioskFilter)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    kiosks: data.content,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
                this.setState({ isLoading: true })
            
          
                });
           }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }


    componentDidMount() {
        this.seachKioskBasedOnFilter()
    }
    async ActivateKioskStatus(id, status) {
        if (window.confirm("Are you sure you want to activate the status?")) {
            try {
                const response = await KioskService.changeKioskStatus(id, status, localStorage.userId);
                NotificationManager.success("Status activated  successfully", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.seachKioskBasedOnFilter()
            } catch (error) {
                NotificationManager.error(error.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            }
        }
    }
    async DeactivateKioskStatus(id, status) {
        if (window.confirm("Are you sure you want to deactivate the status?")) {
            try {

                const response = await KioskService.changeKioskStatus(id, status, localStorage.userId);
                NotificationManager.success("Status deactivated  successfully",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.seachKioskBasedOnFilter();
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            }
        }
    }
    // async deleteAgent(mobileNo) {
    //     try {
    //         const response = await TopupOrderService.deleteOrder(mobileNo);
    //         NotificationManager.success('Deleted Successfully', 'Agent deletion');
    //     } catch (error) {
    //         //toast.error("Something went wrong !!");
    //         NotificationManager.error('Something went wrong', 'Error Message');
    //     }
    // }

    render() {
        const { date,kiosks, isLoading, pageNo, totalPage, totalelement, personalInfo } = this.state;
        const {currentDate,currentDate1}=this.state;
        const currentDateInMinute=Math.round(currentDate.getTime()/ (1000 * 60) % 60);
      
        this.state.counter = pageNo
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Kiosk List</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Kiosk List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">

                                                    <div>
                                                        <label htmlFor='serialNumber' > Kiosk Id</label>
                                                        <input type="text" className="form-control" id='serialNumber'
                                                            onChange={ e => this.state.kioskId = e.target.value }
                                                            placeholder="Serial No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">

                                                    <div>
                                                        <label htmlFor='serialNumber' > Serial No</label>
                                                        <input type="text" className="form-control" id='serialNumber'
                                                            onChange={ e => this.state.serialNumber = e.target.value }
                                                            placeholder="Serial No" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <label htmlFor='latitude'>Latitude</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='latitude'
                                                            onChange={ e => this.state.latitude = e.target.value }
                                                            placeholder="Latitude" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label htmlFor='longitude' >Longitude</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='longitude'
                                                            onChange={ e => this.state.longitude = e.target.value }
                                                            placeholder="Longitude" />
                                                    </div>
                                                 </div> */}
                                                <div className="col-md-4">
                                                    <label htmlFor='dateFrom' >Status</label>
                                                    <div className="w-90 pull-left">

                                                        <select id='status' onChange={ e => this.state.status = e.target.value } className="form-control" >
                                                            <option value=""> Select status</option>
                                                            <option value="Active"> Active</option>
                                                            <option value="Inactive"> Inactive</option>
                                                            <option value="Warning"> Warning</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchKioskInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/AddKiosk', state: { type: "Agent", formName: "order Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Name</th> */ }
                                                    <th>Kiosk ID</th>
                                                    <th>Serial No</th>
                                                    <th>Address</th>
                                                    {/* <th>Location</th> */}
                                                    <th>Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { kiosks !== undefined && kiosks.map(list =>

                                                    <tr className='table-tr'>
                                                        {/* <td className='p-0 '>{ list.name }</td> */ }
                                                        <td className='p-0 '>{ list.kioskId }</td>
                                                        <td className='p-0 '>{ list.serialNumber }</td>
                                                        <td className='p-0 '>{ list.address }</td>
                                                        <td className='p-0 '>{ list.createdAt }</td> 
                                                        {/* <td className='p-0 '>{ list.location }</td> */}
                                                        <td className='p-0'> 
                                                             { ((moment(list.recentRequestDate).format('hh') * 60)+ moment(list.recentRequestDate).format('mm') - currentDateInMinute) >= (`${process.env.REACT_APP_STATUS_CHECKING_INTERVAL_TIME_IN_MINUTE}` + `${process.env.REACT_APP_STATUS_CHECKING_LATENCY_TIME_IN_MINUTE}`) &&
                                                                <button style={ { marginLeft: "2px" } }  className="btn btn-success btn-sm active action-buttons"><i className="nav-icon fa fa-check " title="Active" /> </button>
                                                            }
                                                            {((moment(list.recentRequestDate).format('hh') * 60) + moment(list.recentRequestDate).format('mm') - currentDateInMinute) < (`${process.env.REACT_APP_STATUS_CHECKING_INTERVAL_TIME_IN_MINUTE}` + `${process.env.REACT_APP_STATUS_CHECKING_LATENCY_TIME_IN_MINUTE}`) &&
                                                                <button style={ { marginLeft: "2px" } }  className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-times " title="Inactive" /> </button>
                                                            }
                                                            {/*
                                                            { list.status == "Warning" &&
                                                                <button style={ { marginLeft: "2px" } } onClick={ () => this.ActivateKioskStatus(list.id, list.status) } className="btn btn-warning btn-sm"><i className="nav-icon fa fa-exclamation-triangle " title="Click here to activate" /> </button>
                                                            } */}
                                                            <Link to={ { pathname: '/viewKiosk', state: { id: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" title="View kiosk" /> </button>
                                                            </Link>
                                                            <Link to={ { pathname: '/editKiosk', state: { id: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" title="Edit Koisk" /> </button>
                                                            </Link>
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >

            )
        }
    }
}
export default KioskList