import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import CustomersService from '../../../services/common/CustomersService';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faPlay, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-input-2';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import Header from '../../common/Header';
import TopupSalesHistoryService from '../../../services/etopup/TopupSalesHistoryService';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import LoginService from '../../../services/common/LoginService';
import PreviewTable from '../../Reports/PreviewTable';
import { } from '@fortawesome/free-solid-svg-icons';
import ProvidersService from '../../../services/common/ProvidersService';
import Popup from '../../wallet/Popup';
import ReactXmlViewer from 'react-xml-viewer';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

function pollForUpdates(callback, interval) {
    setInterval(callback, interval);
}


class ProvidersLogsMonitor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerId: null,
            trxnRefId: null,
            trxnType: null,
            mobileNo: null,
            dateFrom: null,
            dateTo: null,
            customerType: null,
            isOpen: false,
            requestValue: null,
            responseValue: null,
            isLoading: false,
            responseType: "json",

            customerSelectOption: null,
            trxnRefSelectOption: null,

            providerId: 1,
            providerIds: [],

            onProviderStatus: true,
            onSystemStatus: false,


            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupSalesHistoryList: [],
            // topupStatus: 0,
            counter: 1,
            id: 0,
            flag: false,
            isExpand: true,
            columns: [
                {
                    Header: 'Name',
                    accessor: 'providerName',
                },
                {
                    Header: 'Account #',
                    accessor: 'accountNo',
                },
                {
                    Header: 'Sender Mobile',
                    accessor: 'senderMobileNo',
                },
                {
                    Header: 'Receiver Mobile',
                    accessor: 'mobileNo'
                },
                {
                    Header: 'Date',
                    accessor: 'createdAt',

                },
                {
                    Header: '$',
                    accessor: 'amount',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value}`,
                },

                {
                    Header: 'Trxn Status',
                    accessor: 'statusValue',

                },
                {
                    Header: 'Detail',
                    accessor: '',
                    Cell: ({ row }) => {
                        const value = row.original.requestValue;
                        const value2 = row.original.responseValue;

                        return (
                            <>
                                <FontAwesomeIcon
                                    label={"Detail"}
                                    icon={faEye}
                                    onClick={() => {
                                        this.handleReRun(value, value2);
                                    }}
                                    className="btn btn-info btn-sm action-buttons nav-icon "
                                />
                            </>
                        );
                    }
                }




            ],
            reportData: [],
            formErrors: {

                dateToError: "",
                dateFromError: ""
            },
        }
        this.clearValue = this.clearValue.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachTopupSalesHistoryOnFilter(this.state.customerType)
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachTopupSalesHistoryOnFilter(this.state.customerType)


    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachTopupSalesHistoryOnFilter(this.state.customerType)

    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachTopupSalesHistoryOnFilter(this.state.customerType)


        }
    }

    nextPage = () => {

        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachTopupSalesHistoryOnFilter(this.state.customerType)

        }
    }


    clearValue() {
        // should not reload
        this.state.customerId = null
        this.state.mobileNo = null
        this.state.dateFrom = null
        this.state.dateTo = null
        this.state.customerSelectOption = null;
        this.state.providerId = 1;
        this.state.onProviderStatus = false;
        this.state.onSystemStatus = false;
        this.state.clear = 1
        this.state.pageNo = 1;
    }


    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            this.seachTopupSalesHistoryOnFilter(this.state.customerType)

        }
    }

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }
        this.setState({
            formErrors
        })
    }

    fatchCustomerData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customersList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        } else {
            callback([])
        }
    }

    onCustomerSearchChange = (customerSelectOption) => {
        if (customerSelectOption) {
            this.setState({
                customerSelectOption
            });

        }
    }

    fatchTrxnRefData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/findTransactionRefBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.trxnRefId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            trxnRefList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        } else {
            callback([])
        }
    }

    onTrxnRefSearchChange = (trxnRefSelectOption) => {
        if (trxnRefSelectOption) {
            this.setState({
                trxnRefSelectOption
            });

        }
    }

    searchSalesHistoryInfo(e) {

        const { formErrors, dateFrom, dateTo } = this.state
        formErrors.dateFromError = ''
        formErrors.dateToError = ''


        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
            }
        }

        if (formValid(this.state.formErrors)) {
            this.state.clear = 0;
            this.state.pageNo = 1;
            this.seachTopupSalesHistoryOnFilter(this.state.customerType)
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }

    seachTopupSalesHistoryOnFilter(customerType) {
        let customerId = localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? this.state.customerSelectOption && this.state.customerSelectOption.value : localStorage.customerIdentity

        let FilterSalsInfo = {
            //customerId: this.state.customerSelectOption === null ? localStorage.customerIdentity : this.state.customerSelectOption.value,
            customerId: customerId,
            trxnRefId: this.state.trxnRefSelectOption === null ? this.state.trxnRefSelectOption : this.state.trxnRefSelectOption.value,
            trxnType: this.state.trxnType,
            mobileNo: this.state.mobileNo,
            providerId: this.state.providerId,
            onProviderStatus: this.state.onProviderStatus,
            onSystemStatus: this.state.onSystemStatus,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            flag: true
        }

        this.setState({ isLoading: false })
        TopupSalesHistoryService.getProvidersLogHistory(
            this.state.pageNo, this.state.pageSize, localStorage.userId, FilterSalsInfo)
            .then(response => response.data)
            .then((data) => {

                this.setState({
                    isLoading: true,
                    reportData: data.topupSaleHistory == undefined ? [] : data.topupSaleHistory,
                    topupSalesHistoryList: data.topupSaleHistory,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });


                this.setState({ isLoading: true })


            });

    }

    componentDidMount() {
        ProvidersService.getProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providerIds: response.data
                });

            })
            .catch(e => {

            });


        // LoginService.getUserInfo(localStorage.userId)
        // .then(response => {
        //   this.setState({
        //     fullName: response.data.customerFullName,
        //     customerType: response.data.type
        //   });
        //   this.seachTopupSalesHistoryOnFilter(this.state.customerType)
        // })
        // .catch(e => {
        // });
        this.seachTopupSalesHistoryOnFilter(localStorage.type)
        // this.poller = setInterval(, 1000);
    }

    // componentWillUnmount() {
    //     clearInterval(this.poller);
    // }

    editCustomer(customerMobileNo) {
        this.props.history.push(`/editCustomer/${customerMobileNo}`);
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    actionPageSize(e) {
        this.state.pageSize = e.target.value;
        this.setState({
            isLoading: false,
            pageSize: e.target.value
        });

        this.seachTopupSalesHistoryOnFilter(this.state.customerType);
    }

    handleReRun = (requestValue, responseValue) => {
        this.setState({
            isOpen: true,
            requestValue: requestValue,
            responseValue: responseValue
        })




    };

    providerValue = e => {

        let selectedProviderId = e.target.value
        this.state.providerId = ""
        if (selectedProviderId == "----please select---") selectedProviderId = ""
        let responseType = "json";
        if(selectedProviderId === 3){
            responseType = "xml"; 
        }
        this.setState({
            providerId: selectedProviderId,
            responseType: responseType
        });
    }

    togglePopup = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const { providerIds, columns, reportData, formErrors, pageNo, pageSize, isLoading, totalPage } = this.state;
        this.state.counter = pageNo

        let providerInfoList = providerIds.length > 0
            && providerIds.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.providerName}</option>

                )
            }, this);


        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                < div >
                    {/* <Header />
                    <Menu />

                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */}

                    <div className="content-wrapper bg-white">

                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">

                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Providers log History</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        {/* <div className="form-title" >Customer List Information</div> */}
                                        <form onSubmit={this.clear} hidden={this.state.isExpand} >
                                            <div className="row" hidden={this.state.isExpand}>
                                                <div className=" form-group col-2 col-sm-2 col-md-2 col-lg-2  ">
                                                    <label htmlFor='ProviderList'>Providers:</label>
                                                    <select className="form-control "
                                                        value={this.state.providerId}
                                                        onChange={this.providerValue} id='ProviderList'>
                                                        <option key={null} value={null}>----please select---</option>
                                                        {providerInfoList}
                                                    </select>
                                                </div>

                                                <div className='col-12 col-sm-8 col-md-3 col-lg-3'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label htmlFor='mobileNo' >Customer</label>
                                                            <AsyncSelect id='customersList'
                                                                // className="select"
                                                                placeholder={"Search  by account or mobile no"}
                                                                isClearable={true}

                                                                value={this.state.customerSelectOption}
                                                                loadOptions={this.fatchCustomerData}
                                                                isLoading={this.state.customerSelectOption == {} &&
                                                                    this.state.customerSelectOption === undefined &&
                                                                    this.state.customerSelectOption === null ? true : false
                                                                }
                                                                onChange={(e) => {
                                                                    this.onCustomerSearchChange(e)
                                                                }}
                                                                defaultOptions={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-3 col-lg-3'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label htmlFor='mobileNo' >Mobile No</label>
                                                            <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                                                country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                                                placeholder="Enter phone number" id='mobileNo'
                                                                inputStyle={{ width: '100%', height: 'auto' }}
                                                                onChange={phone => this.onChangeMobileNo(phone)} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-3 col-sm-2 col-md-2 col-lg-2'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label htmlFor='dateFrom' >Date From</label>
                                                            <div>
                                                                <input type="date" className="form-control" id='dateFrom'
                                                                    onChange={e => this.state.dateFrom = e.target.value}
                                                                    placeholder="Date" />
                                                                {formErrors.dateFromError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-3 col-sm-2 col-md-2 col-lg-2'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label>Date To</label>
                                                            <div >
                                                                <input type="date" className="form-control" id='dateFrom'
                                                                    onChange={e => this.state.dateTo = e.target.value}
                                                                    placeholder="Date" />
                                                                {formErrors.dateToError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ clear: 'both' }}></div>
                                                <div >
                                                    <button type="button" title={"Search"}
                                                        className="btn btn-sm btn-success primary m-1 float-right"
                                                        onClick={() => this.searchSalesHistoryInfo()}>Search</button>
                                                    <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                                                        className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                </div>
                                            </div>



                                        </form>
                                        <div style={{ clear: 'both' }}></div>
                                        <br />

                                        {/* <Link to={ { pathname: '/directTopup', state: { type: "Agent", formName: "Customer Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link> */}
                                        <button type='button' style={{ marginLeft: "3px" }} title={"Show/Hide Filter"}
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={() => this.filterToggle()}>
                                            <FontAwesomeIcon icon={faFilter} />
                                            Filter
                                        </button>
                                        <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                                            <label htmlFor='Size'>Row #:</label>
                                            <select value={pageSize}
                                                onChange={e => this.actionPageSize(e)} name='Size'>
                                                <option key={"20"} value={20}>20</option>
                                                <option key={"50"} value={50}>50</option>
                                                <option key={"500"} value={500}>500</option>
                                                <option key={"1000"} value={1000}>1000</option>
                                                <option key={"10000"} value={10000}>10000</option>
                                            </select>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>

                                        {isLoading &&
                                            <div className='tableContainer2'>

                                                <PreviewTable columns={columns} data={reportData} />
                                            </div>

                                        }
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{''}
                                                <strong>
                                                    {pageNo} of {totalPage}
                                                </strong>
                                                {''}
                                            </span>
                                            <span>
                                                | Go To Page : {''}
                                                <input type='number' defaultValue={pageNo}
                                                    onChange={(e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    }} />
                                            </span>
                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackwardFast} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackward} />
                                            </button>

                                            <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                                <FontAwesomeIcon icon={faForward} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                                <FontAwesomeIcon icon={faForwardFast} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {this.state.isOpen && (
                                        <div className="popup">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h2>Request and Response Data</h2>
                                                    <div>
                                                        <h3>Request:</h3>
                                                        <pre>{this.state.requestValue}</pre>
                                                    </div>
                                                    <div>
                                                        <h3>Response:</h3>                                                        
                                                            <pre>
                                                               {this.state.responseValue} 
                                                            </pre>
                                                    
                                                    </div>
                                                    <div className="row d-flex justify-content-left">
                                                        <button
                                                            type="submit"
                                                            onClick={this.togglePopup}
                                                            className="btn btn-warning px-3 py-2"
                                                            style={{ marginLeft: "10px" }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div >
            )
        }
    }
}
export default ProvidersLogsMonitor