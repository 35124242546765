import React, { Component } from 'react'
import { faBackward, faBackwardFast, faDownload, faFileExcel, faFilePdf, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';
import ReportService from '../../services/Reports/ReportService';
import Header from '../common/Header';
import AsyncSelect from 'react-select/async'
import Menu from '../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../../http-common';
import PreviewTable from './PreviewTable';
import { LOCALES } from '../common/Constant';
import ReactLoading from "react-loading";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Link } from 'react-router-dom';
import { downloadExcel } from "react-export-table-to-excel";


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

export class AccountReport extends Component {
    constructor(props) {

        super(props)
        pdfjs.GlobalWorkerOptions.workerSrc =
            `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
        this.state = {
            CashReport: [],
            searchValue: undefined,
            cashTypeId: undefined,
            transactionCode: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            reportType: 'pdf',
            numPages: null,
            vewierBtn: false,
            reportVewier: false,
            reportDownload: false,
            generateData: 1,
            pageNumber: 1,
            pageNo: 1,
            isLoading: false,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            selectOption: null,
            columns: [
                {
                    Header: 'Main Account Code',
                    accessor: 'parentId',
                },
                {
                    Header: 'Name',
                    accessor: 'name'
                }
            ],
            reportData: [],
            formErrors: {
                reportTypeError: "",
                dateToError: "",
                dateFromError: ""
            },
        }

        this.reportStatus = this.reportStatus.bind(this);

    }

    getReportData() {
        let FilterCashInfo = {
            reportFormat: this.state.reportType,
            user: localStorage.userName,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            accountCode: this.state.selectOption && this.state.selectOption.value,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }

        ReportService.previewAccountReport(FilterCashInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    reportData: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1,
                });

            })
            .catch(e => {

            });

    }

    getGenerateCashReport = e => {
        e.preventDefault();
        const { formErrors, reportType, reportDownload } = this.state
        this.state.pageNumber = 1
        this.state.generateData = 1
        formErrors.reportTypeError = reportType === '' || reportType === null || reportType === undefined ? 'You must make a selection' : this.state.formErrors.reportTypeError;

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'

            }
        }

        if (formValid(this.state.formErrors)) {
          if(reportDownload){
            this.reportDownloadNew();
          } else {
            this.getReportData();
            this.setState({
                reportVewier: !this.state.reportVewier
            });
        }



        }

        this.setState({
            formErrors
        });

    }

    reportDownloadNew(){
        let FilterCashInfo = {
            reportFormat: this.state.reportType,
            user: localStorage.userName,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            accountCode: this.state.selectOption && this.state.selectOption.value,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }
        ReportService.getGenerateAccountReport(FilterCashInfo)
            .then(
                (res) => {
                        var oReq = new XMLHttpRequest();
                        // Server 196.189.116.26/AelafSmartPay                         
                        if (this.state.reportType === 'pdf') {
                            var URLToPDF = `${process.env.REACT_APP_SERVER_URL}` + "files/LedgerAccountReport.pdf";
                            var type = 'application/pdf'
                        }
                        if (this.state.reportType === 'xlsx') {
                            var URLToPDF = `${process.env.REACT_APP_SERVER_URL}` + "files/LedgerAccountReport.xlsx";
                            var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }

                        oReq.open("GET", URLToPDF, true);
                        oReq.withCredentials = false
                        oReq.setRequestHeader('Authorization', localStorage.jwtToken)
                        oReq.setRequestHeader('Access-Control-Allow-Origin', '*')
                        oReq.setRequestHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
                        oReq.responseType = "blob";

                        oReq.onload = function () {
                            // Once the file is downloaded, open a new window with the PDF
                            // Remember to allow the POP-UPS in your browser
                            const file = new Blob([oReq.response], { type: type });

                            const fileURL = URL.createObjectURL(file);

                            window.open(fileURL, "_blank");
                        };

                        oReq.send();
                        this.setState({
                            isLoading: true
                        });
                   
                },
                (err) => {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({
                        reportVewier: false
                    });
                })
            .catch(function (response) {
                NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`,`${process.env.REACT_APP_MSG_TITLE_ERROR}` , 3000);
            });
    }


    componentDidMount() {
        this.getReportData()
    }

  

    reportType(e) {
        const { formErrors } = this.state
        let selectedReportType = e.target.value
        this.state.reportType = ''
        if (selectedReportType === "----please select---")
            selectedReportType = ""

        formErrors.reportTypeError = selectedReportType === "" ? 'You must make a selection' : "";
        this.setState({
            reportType: selectedReportType,
            formErrors
        });
    }

    selectVaue(e) {
        let selectedCode = e.target.value
        this.state.transactionCode = undefined
        if (selectedCode === "----please select---")
            selectedCode = undefined

        this.setState({
            transactionCode: selectedCode,
            reportVewier: false
        });
    }



    onDocumentLoad = ({ numPages }) => {
        if (this.state.generateData === 1)
            this.setState({ numPages });
        this.state.generateData = 2
    }


    reportStatus() {
        this.state.isLoading = false;
        this.state.reportData = []
        this.setState({
            reportDownload: false
        })
    }

    downloadStatus() {
        this.state.isLoading = false;
        this.setState({
            reportDownload: true
        })
    }

    fatchData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
                        });
                        callback(temp)
                    })
                    .catch((error) => {
                    
                    })
            })
        }
    }

    onSearchChange = (selectOption) => {
        this.setState({
            selectOption: selectOption
        });

    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.getReportData()
    }

    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.getReportData()

    }

    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.getReportData()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.getReportData()


        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.getReportData()

        }
    }

    exportPDF = () => {
        const {reportData, reportformat } = this.state
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
        doc.setFont('Lato-Regular', 'normal');
    
        const title = "Ledger Account Report ";
        const headers = [["Main Account Code","Name"]];
        
        const data = []
        
        reportData.forEach((element, index,array) => {
            data.push ([element.parentId, 
                element.name ])
        });
            // }
    
        let content = {
          startY: 50,          
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content );
        doc.save("LedgerAccountReport.pdf")
    }


    exportToEXcel(){
        const {reportData, reportformat } = this.state
        const header =  ["Main Account Code","Name"];
    
        const body = [];

        reportData.forEach((element, index,array) => {
            body.push ([element.parentId, 
                element.name ])
        });

        downloadExcel({
            fileName: "LedgerAccountReport",
            sheet: "LedgerAccountReport",
            tablePayload: {
              header,
              body: body
            },
          });
    }


    render() {
        const { formErrors, isLoading, columns, reportData, pageNo, totalPage } = this.state

        return (
            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item" >Reports</li>
                                        <li className="breadcrumb-item active" >Account Ledger</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" >Display report Information</div>
                                    <form onSubmit={this.getGenerateCashReport} noValidate>

                                        <div className='row '>
                                            <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                <label htmlFor='drAccount'> Account :</label>
                                                <div style={{ fontSize: 'smaller' }}>
                                                    <AsyncSelect id='drAccount'
                                                        placeholder={"Search by account code"}
                                                        isClearable={true}
                                                        loadOptions={this.fatchData}
                                                        isLoading={this.state.selectOption === {} &&
                                                            this.state.selectOption === undefined &&
                                                            this.state.selectOption === null ? true : false
                                                        }
                                                        onChange={(e) => {
                                                            this.onSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>

                                            </div>



                                            <div className=" form-group col-2 col-sm-2 col-md-2 col-lg-2 ">
                                                <label htmlFor='reportType'>Report Type:</label>
                                                <select className="form-control "
                                                    value={this.state.reportType}
                                                    onChange={e => this.reportType(e)} id='reportType'>
                                                    <option key={null} value={null}>----please select---</option>
                                                    <option key={"xlsx"} value={"xlsx"}>xlsx</option>
                                                    <option key={"pdf"} value={"pdf"}>pdf</option>
                                                </select>
                                                {formErrors.reportTypeError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.reportTypeError}</span>
                                                )}

                                            </div>

                                        </div>
                                        <br />
                                        <div>
                                            <div className="row  d-flex justify-content-left ">

                                                <button type='submit' title={"Preview"} style={{ marginLeft: "10px" }}
                                                    className="btn btn-sm btn-success primary m-1  float-left"
                                                    onClick={() => this.reportStatus()}>
                                                    <i className="fa fa-eye fa-sm" /> Generate Report
                                                </button>

                                                <button type='submit' title={"Download"}
                                                    onClick={() => this.downloadStatus()}
                                                    className="btn btn-sm btn-success primary m-1  float-left">
                                                    <i className="fa fa-download fa-sm" /> Download
                                                </button>
                                            </div>
                                            <Link  className=" float-right m-1"
                                                 onClick={() => this.exportToEXcel()}>                                    
                                                    <FontAwesomeIcon icon={faDownload} title="download as excel" />
                                                    <FontAwesomeIcon icon={faFileExcel} title="download as excel" />
                                             </Link>
                                          
                                             <Link  className=" float-right m-1"
                                                 onClick={() => this.exportPDF()}>                                    
                                                    <FontAwesomeIcon icon={faDownload} title="download as pdf" />
                                                    <FontAwesomeIcon icon={faFilePdf} title="download as pdf" />
                                             </Link>
                                        </div>
                                    </form>

                                    {!isLoading ?
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                                            </div>
                                        </div>

                                        :
                                        <>
                                    <PreviewTable columns={columns} data={reportData} />
                                    <div className="clearfix">&nbsp;</div>
                                    <div className=" float-sm-center">
                                        <span>
                                            page{''}
                                            <strong>
                                                {pageNo} of {totalPage}
                                            </strong>
                                            {''}
                                        </span>
                                        <span>
                                            | Go To Page : {''}
                                            <input type='number' defaultValue={pageNo}
                                                onChange={(e) => {
                                                    const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                    this.gotoEnteredPage(pageNumber)
                                                }} />
                                        </span>
                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                            <FontAwesomeIcon icon={faBackwardFast} />
                                        </button>

                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                            <FontAwesomeIcon icon={faBackward} />
                                        </button>

                                        <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                            <FontAwesomeIcon icon={faForward} />
                                        </button>

                                        <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                            <FontAwesomeIcon icon={faForwardFast} />
                                        </button>

                                    </div>
                                    </>
                                 }
                                </div>
                                
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }

}

export default AccountReport