import React, { Component } from 'react'
import Menu from '../../../components/wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import ProviderService from '../../../services/etopup/ProviderService';
import "react-datepicker/dist/react-datepicker.css";
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/); 

class EditProviderCommission extends React.Component {
    constructor (props) {
        super(props);

        this.state = {

            providerCommissionId: this.props.match.params.providerCommissionId,
            isLoading: false,
            isLoadingPop: false,
            providerCommissionData: {
                id: '',
                providerId: '',
                onPurchase: '',
                onTarget: '',
                quarterly: '',
                remark: ''
            },
            providersList: [],
            formErrors: {
                onPurchaseError: "",
                onTargetError:"",
                quarterlyError:"",
                providerIdError:""
            },
        };
        this.onChangeProviderId = this.onChangeProviderId.bind(this);
    }

    onChangeProviderId(e) {
        const providerId = e.target.value;
        const { formErrors } = this.state
        formErrors.providerIdError = providerId == "" ? 'Please select provider' : "";
        this.setState(function (prevState) {
            return {
                providerCommissionData: {
                    ...prevState.providerCommissionData,
                    providerId: providerId
                },
                formErrors
            };
        });
    }
 
    handelChange = e => {
        e.preventDefault();
        const { providerCommissionData } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'onPurchase':
                this.state.providerCommissionData.onPurchase = value
                formErrors.onPurchaseError =
                    value.length == 0 || !amountRegEx.test(value) ? " " + "On purchase sholud be digits only" : ""
                break;
            case 'onTarget':
                this.state.providerCommissionData.onTarget = value
                formErrors.onTargetError =
                    !amountRegEx.test(value) ? " " + "On target Sholud be only digits " : ""
                break;
            case 'quarterly':
                this.state.providerCommissionData.quarterly = value
                formErrors.quarterlyError =
                    !amountRegEx.test(value) ? " " + "On target sholud be only digits " : ""
                break;
            default:
                break;
           }

        this.setState({
            formErrors, [name]: value
        })
    }
    Cancel = (e) => {
        this.props.history.push('/providersCommissionList');
    }

    componentDidMount() {
        this.getProviderCommissionById(this.props.location.state.providerCommissionId);
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    getProviderCommissionById(providerCommissionId) {
        ProviderCommissionService.getProviderCommissionById(providerCommissionId,localStorage.userId)
            .then(response => {
                this.setState({
                    providerCommissionData: response.data,
                    isLoading: true,
                });
                
            })
            .catch(e => {
                this.setState({ isLoading: true })
                
            });
    }
    handleSubmit = e => {
        e.preventDefault();
        const { providerCommissionData, onPurchase,providerId, formErrors } = this.state
        formErrors.providerIdError = this.state.providerCommissionData.providerId.id == "" || this.state.providerCommissionData.providerId.id === undefined ||  this.state.providerCommissionData.providerId.id == null ? "Provider is Required" : this.state.formErrors.providerIdError;
        formErrors.onPurchaseError = providerCommissionData.onPurchase == '' || providerCommissionData.onPurchase == null || providerCommissionData.onPurchase == undefined ? 'On purchase commission is Required' : this.state.formErrors.onPurchaseError;
       

     if (formValid(this.state.formErrors)) {
        var data = new FormData();
        data.append("id", this.props.location.state.providerCommissionId);
        data.append("providerId", this.state.providerCommissionData.providerId.id);
        data.append("onPurchase", this.state.providerCommissionData.onPurchase);
        data.append("onTarget", this.state.providerCommissionData.onTarget);
        data.append("quarterly", this.state.providerCommissionData.quarterly);
        data.append("remark", this.state.providerCommissionData.remark);
        data.append("userId", localStorage.userId);

        this.setState({ isLoading: true})
        ProviderCommissionService.updateProviderCommission(data)
        .then(
        (response) => {
            NotificationManager.success("Successfully updated ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            this.props.history.push('/providersCommissionList')
        },
        (error) => {
            NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
        })
        .catch(function (err) {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
        });
    } else {
        NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
        this.setState({
          formErrors
        })
      }
    }

    render() {

        const { providerCommissionData, isLoading, providersList,formErrors } = this.state;
       
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Provider Commission</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">

                                                            <label htmlFor='Country'>Provider Name:</label>


                                                            <select value={ providerCommissionData.providerId.id } onChange={ this.onChangeProviderId } className="form-control" >
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                            { formErrors.providerIdError.length>0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='onPurchase'>On Purchase:</label>
                                                            <input type="text" className="form-control " value={ providerCommissionData.onPurchase } name='onPurchase' onChange={this.handelChange} onBlur={this.handelChange} />
                                                            { formErrors.onPurchaseError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.onPurchaseError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='onTarget'>On Target:</label>
                                                            <input type="text" className="form-control " value={ providerCommissionData.onTarget } name='onTarget' onChange={this.handelChange} onBlur={this.handelChange} />
                                                            { formErrors.onTargetError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.onTargetError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='quarterly'>Quarterly:</label>
                                                            <input type="text" className="form-control " value={ providerCommissionData.quarterly } name='quarterly' onChange={this.handelChange} onBlur={this.handelChange} />
                                                            { formErrors.quarterlyError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.quarterlyError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='remark'>Remark:</label>
                                                            <input type="text" className="form-control " value={ providerCommissionData.remark } name='remark' onChange={this.handelChange} onBlur={this.handelChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                               <div >
                             </div>
                             {this.state.isLoadingPop && <LoadingPopup

                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditProviderCommission);