import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { withRouter } from 'react-router-dom';
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../Header';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
      val.length > 0 && (valid = false);
  });
  return valid;
}

const typeRegEx = RegExp(/^(?!\d+$)\w+\S+/)

class AddCustomerType extends React.Component {

  constructor () {

    super();

    this.state = {
      type: '',
      isLoading: false,
      formErrors: {
        typeError: ""
    },
    }
  }
  handelInputChange = e => {
    e.preventDefault();
    const { customerId } = this.state
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
        case 'type':
            formErrors.typeError =
            value.length == 0 || !typeRegEx.test(value) ? "" + "type assignment should only be alphabets" : ""
            break;
            default:
            break;
                }
            this.setState({
                formErrors, [ name ]: value
            })
    }
  Cancel = e => {
    this.props.history.push('/customerTypesList');
  }
  handleSubmit = e => {
    e.preventDefault();
    //  CustomerService.createAgent(object)
    const { type, formErrors } = this.state
    formErrors.typeError = type === "" || type === null || type === undefined ? "Type is required!" : this.state.formErrors.typeError;

    if (formValid(this.state.formErrors)) {
      this.setState({ isLoadingPop: true})
        CustomerTypeService.createCustomerType(localStorage.userId,this.state.type)
          .then(
          (response )=> {
            NotificationManager.success("Successfully Registered !!",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            this.props.history.push('/customerTypesList');
        },
        (err) => {
          NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        })
          .catch(function (err) {
            NotificationManager.error("Network problem" , `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 1000 );
            this.setState({ isLoadingPop: false})
          });
        } else {
          NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
          this.setState({
            formErrors
          })
        }
  };


  render() {
const{formErrors}=this.state

    return (

      <div>
        {/* <Header />
        <Menu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */ }
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              {/*  
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash In Information</li>

                  </ol>
                </div>
              </div> 
            */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add Customer Type</li>
                </ol>
              </div>
              <div >
                <div className="modal-body pt-5 ">

                  <div className='col-1'></div>
                  <div className='col-8 bg-light p-3'>
                    {/*
                    <div class="modal-header">
                      <h4 class="modal-title" >
                         Agent Registration
                      </h4>
                    </div> */}
                    <form onSubmit={ this.handleSubmit }>
                      {/*Basic Information*/ }
                      <div className="card  border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Customer Type </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>


                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='type'>Customer Type:</label>
                              <input type="text" className="form-control " id='type' name='type'
                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange }  />
                                  { formErrors.typeError.length>0 && (
                                      <span style={ { color: "#F61C04" } }>{ formErrors.typeError }</span>
                                  ) }
                            </div>

                          </div>

                        </div>
                      </div>

                      <div className="row  d-flex justify-content-left clear-fix ">
                          <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                          <button type="submit" onClick={ () => this.Cancel() }
                              className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}


              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}

export default withRouter(AddCustomerType);
