import React, { Component } from 'react'
import { Link } from "react-router-dom";
import TopupMenu from '../TopupMenu';
import CustomersService from '../../../services/common/CustomersService';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import ProviderService from '../../../services/etopup/ProviderService';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import PurchaseOrderService from '../../../services/etopup/PurchaseOrderService';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
//class PartnerProviderBalanceList extends Component {

class PurchaseOrderList extends Component {

    constructor (props) {
        super(props)
        this.state = {
            // For search purposes
            providerId: null,
            dateFrom: null,
            dateTo: null,

            isLoading: false,
            pageNo: 1,
            purchaseOrdersList: [],
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 0,
            providersList: [],
            id: 0,
            flag: false,
            isExpand: true,
            formErrors: {
                dateToError: "",
                dateFromError: ""
            },
        }
        //this.clearValue.bind(this)
        this.clearValue = this.clearValue.bind(this);
        this.editPurchaseOrder = this.editPurchaseOrder.bind(this);
        //  this.activation = this.activation.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachPurchaseOrderOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachPurchaseOrderOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 0
        this.seachPurchaseOrderOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 0
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachPurchaseOrderOnFilter()
            this.render()

        }
    }

    nextPage = () => {

        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 0
            this.seachPurchaseOrderOnFilter()
            this.render()
        }
    }


    clearValue() {

        this.state.providerId = null
        this.state.dateFrom = null
        this.state.dateTo = null

        this.state.clear = 0
    }

    clear = e => {
        e.preventDefault()
        if (this.state.clear == 0) {
            e.target.reset();
            this.state.clear = 0
            this.seachPurchaseOrderOnFilter()

        }
    }

    searchPurchaseOredrInfo(e) {

        const { formErrors, dateFrom, dateTo } = this.state
        formErrors.dateFromError = ''
        formErrors.dateToError = ''

        if (dateFrom != null) {
            formErrors.dateToError =
                dateTo == null ? 'date to is required' : ''
        }

        if (dateTo != null) {
            formErrors.dateFromError =
                dateFrom == null ? 'date from is required' : ''
        }

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
            }
        }

        if (formValid(this.state.formErrors)) {

            this.state.clear = 0;
            this.state.pageNo = 0;
            this.seachPurchaseOrderOnFilter()

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    }
    seachPurchaseOrderOnFilter() {

        this.purchaseOrdersList = null;
        let searchPara = {
            providerId: this.state.providerId,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }
 
        PurchaseOrderService.getAllPurchaseOrders(
            this.state.pageNo, this.state.pageSize, localStorage.userId, searchPara)
            .then(response => response.data)
            .then((data) => {
                if (data.content) {
                    this.setState({
                        purchaseOrdersList: data.content,
                        isOpen: false,
                        isLoading: true,
                        totalPage: data.totalPages,
                        isLoading: true,
                        totalelement: data.pageable.totalelement,
                        pageNo: data.pageable.pageNumber + 1
                    });
                }
            });

    }
    // componentDidUpdate() {
    //   this.seachPurchaseOrderOnFilter()
    // }

    componentDidMount() {
        this.seachPurchaseOrderOnFilter()
        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    // activation(customerMobileNo) {
    //   this.props.history.push({ pathname: '/verifyCustomer', state: { customerMobileNo: customerMobileNo } });
    // }

    // Approve order
    async approveOrder(orderId, userId) {
        if (window.confirm("Are you sure you want to approve this order?")) {
            PurchaseOrderService.approvePurchaseOrder(orderId, userId)
                .then(
                    (response )=> {
                    NotificationManager.success("Approved successfully",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                     //window.location.reload();
                     
                    this.seachPurchaseOrderOnFilter()
                }, 
                (err) => {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                })

                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);

                });

            // toast.error("Something went wrong !!")
        }

    }

    editPurchaseOrder(purchaseOrderId) {
        this.props.history.push({pathname: "/editPurchaseOrder", state: {purchaseOrderId: purchaseOrderId}});
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }


   

    // Delete customer
    async deletePurchaseOrder(orderId) {
        if (window.confirm("Are you sure you want to delete this order ?")) {
            try {
                const response = await PurchaseOrderService.deleteOrder(orderId,localStorage.userId);
                NotificationManager.success("Deleted successfully", "Aelaf Smart Pay");
                this.seachPurchaseOrderOnFilter()
            } catch (error) {
                NotificationManager.error("Deletion error, please check and try again !!", "Aelaf Smart Pay");
            }
        }
    }

    render() {

        const { purchaseOrdersList, formErrors, pageNo, isLoading, totalPage, providersList } = this.state;
        this.state.counter = pageNo
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    <div className="content-wrapper bg-white">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Purchase Order</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>

                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='provider'>Provider Name</label>
                                                    <div >
                                                        <select onChange={ e => this.state.providerId = e.target.value } className="form-control" >
                                                            <option value=""> Choose provider</option>
                                                            { providersList.map(provider =>
                                                                <option value={ provider.id }>{ provider.providerName }</option>
                                                            ) }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='dateFrom' > From</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateFrom = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateFromError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label> To</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateTo = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateToError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                                <div className="className='col-12 col-sm-8 col-md-2 col-lg-2 pt-3 '">
                                                    <button type="button" title={ "Search" }
                                                        className="btn btn-sm btn-success primary m-0 float-right mt-4"
                                                        onClick={ () => this.searchPurchaseOredrInfo() }>Search</button>
                                                    <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                        className="btn btn-sm btn-danger primary m-0 float-right mt-4 "  >Clear</button>
                                                </div>
                                            </div>

                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                    
                                        <Link to={ { pathname: '/addPurchaseOrder' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>

                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>

                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } }  >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Provider Name</th>
                                                    <th>Amount</th>
                                                    <th>Total Purchase</th>
                                                    <th>Commission</th>
                                                    <th>Commission Amount</th>
                                                    <th>Date</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { purchaseOrdersList !== undefined && purchaseOrdersList.map(orderList =>

                                                    <tr>
                                                        <td>{ orderList.providerId.providerName }</td>

                                                        <td>
                                                            <NumberFormat value={ orderList.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td>
                                                            <NumberFormat value={ +orderList.amount.toFixed(2) + +((orderList.amount.toFixed(2) / 100) * orderList.commissionId.onPurchase).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td>{ orderList.commissionId.onPurchase + '%' }</td>
                                                        <td>
                                                            <NumberFormat value={ +((orderList.amount.toFixed(2) / 100) * orderList.commissionId.onPurchase).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        <td>   { format(orderList.createdAt, 'yyyy-MM-dd') } </td>
                                                        <td className='p-0'>
                                                            { orderList.status == 0 &&

                                                                <button className="btn btn-danger btn-sm action-buttons" onClick={ () => this.approveOrder(orderList.id, localStorage.userId) } title="Approve Order">
                                                                 <FontAwesomeIcon icon={faCheckDouble} />
                                                                </button>

                                                            }
                                                            { orderList.status == 1 &&

                                                                <button className="btn btn-success btn-sm action-buttons" title="Approved" disabled>
                                                                 <FontAwesomeIcon icon={faCheckDouble} />
                                                                </button>

                                                            }
                                                            <Link to={ { pathname: '/viewPurchaseOrder', state: { purchaseOrderId: orderList.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons" title="View detail"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                            </Link>
                                                            { orderList.status == 0 &&
                                                            <Link to={ { pathname: '/editPurchaseOrder', state: { purchaseOrderId: orderList.id } } }>
                                                                <button style={ { marginLeft: "2px" } } title="Edit order" className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                            </Link>
                                                            }
                                                            { orderList.status == 1 &&
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm action-buttons" disabled><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                            }
                                                            <button style={ { marginLeft: "2px" } }  title="Delete order" onClick={ () => this.deletePurchaseOrder(orderList.id) } className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button> 
                                                        </td>
                                                    </tr>

                                                )
                                                }

                                            </tbody>
                                        </table>

                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 0 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.privPage() } disabled={ pageNo === 0 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-0 col-sm-0 col-md-0 col-lg-0 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default PurchaseOrderList