import API from '../../http-common';

class SettingService {
  // get all status setting
  getStatusSettings(userId) {
    return API.get("/api/v1/getStatusSettings/" + userId);
  }

   // get all type setting
   getTypeSettings(userId) {
    return API.get("/api/v1/getTypeSettings/" + userId);
    }

    getConfigurationValues(){
      return API.get("/api/v1/getConfigurationValues");
    }

    getAutoConfigures(){
      return API.get("/api/v1/getAutoConfigures");
    }

    getAutoConfigureById(id){
      return API.get("/api/v1/getAutoConfigureById/" + id);
    }

    createAutoConfigure(configuration){
      return API.post("/api/v1/createAutoConfigure" , configuration);
    }

    updateAutoConfigure(configuration, id){
      return API.get("/api/v1/updateAutoConfigure/" + id , configuration);
    }
  
}


export default new SettingService()