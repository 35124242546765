import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProvidersService from '../../../services/common/ProvidersService';
//import Menu from '../../wallet/Menu';
import TopupMenu from '../../../components/etopup/TopupMenu';
import BankInfoService from '../../../services/common/BankInfoService';
import CustomersService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant'
import Header from '../Header';
import SettingService from '../../../services/common/SettingService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';
import IntegrationTypeService from '../../../services/etopup/IntegrationTypeService';
import IntegrationTypeList from '../../etopup/IntegrationType/IntegrationTypeList';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const startCommRegEx = RegExp(/^(?=[1-9])[0-9]{3,4}$/u)
const commandRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const urlRegEx = RegExp(/^[\p{L} \p{N}_@.:/]{15,30}$/u)
const serviceCodeRegEx = RegExp(/^[a-zA-Z_\-\/]{2,15}$/u)
const bearerCodeRegEx = RegExp(/^[a-zA-Z_\-\/]{2,15}$/u)
const languageRegEx = RegExp(/^[a-zA-Z\-]{2,15}$/u)
const currencyRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const IPAddressRegEx = RegExp(/^[a-zA-Z0-9.]{1,40}$/u)
const portRegEx = RegExp(/^[0-9:]{0,4}$/u)
const addtionalPathReqEx = RegExp(/^(?=[\/])[a-zA-Z0-9\/]{1,50}$/u)
const clientUserIdRegEx = RegExp(/^[a-zA-Z0-9_\-\/ ]{3,30}$/u)
const initiatorPrincipalIdRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const channelRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const clientReferenceRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const passwordRegEx = RegExp(/^[a-zA-Z0-9_#@&%\-\/]{3,30}$/u)
const clientRequestTimeoutRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddProviders extends Component {

  constructor (props) {
    super(props);
    this.state = {
      providerIds: [],
      isLoading: false,
      integrationTypeList: [],
      status:[],
      types:[],
      providerId: '',
      startCommand: '',
      command: '',
      IPAddress: '',
      port: '',
      addtionalPath: '',
      serviceCode: '',
      bearerCode: '',
      language: '',
      currency: '',
      clientId: '',
      clientUserId: '',
      initiatorPrincipalId: '',
      statusId:'',
      statusValue:'',
      typeId:'',
      typeValue:'',
      channel: '',
      clientReference: '',
      password: '',
      clientRequestTimeout: 0,
      integrationTypeId:'',

      formErrors: {
        providerIdError: "",
        statusError:"",
        typeError:"",
        startCommandError: "",
        commandError: "",
        ammountError: "",
        IPAddressError: "",
        portError: "",
        addtionalPathError: "",
        serviceCodeError: "",
        bearerCodeError: "",
        languageError: "",
        currencyError: "",
        clientIdError: "",
        clientUserIdError: "",
        initiatorPrincipalIdError: "",
        channelError: "",
        clientReferenceError: "",
        passwordError: "",
        clientRequestTimeoutError: "",
        statusError:"",
        typeError:"",
        integrationTypeError:""

      },
      isCOMVIVA: false,
      isMTN: false,
      isEtsalat: false

    }

  }

  componentDidMount() {
    ProvidersService.getProviders(localStorage.userId)
      .then(response => {
        this.setState({
          providerIds: response.data
        });
        
      })
      .catch(e => {
        
      });

      IntegrationTypeService.getAllIntegrationTypes(localStorage.userId)
      .then(response => {
          this.setState({
              integrationTypeList: response.data,
          });
      })
      .catch(e => {                
      });

    SettingService.getStatusSettings(localStorage.userId)
      .then(response => {
        this.setState({
          status: response.data
        });
        
      })
      .catch(e => {
        
      });

      SettingService.getTypeSettings(localStorage.userId)
      .then(response => {
        this.setState({
          types: response.data
        });
      })
      .catch(e => {
        
      });
  } 

  providerValue = e => {
    const { formErrors } = this.state
   // debugger
    let isCOMVIVA = false
    let isMTN = false
    let isEtsalat = false
    this.state.providerId = e.target.value
    let selectedProviderId = e.target.value
    this.state.providerId = ""
    if (selectedProviderId === null) selectedProviderId = ""

    if (selectedProviderId == LOCALES.COMVIVA_PROVIDER) {
      isCOMVIVA = true
    }
    if (selectedProviderId == LOCALES.MTN_PROVIDER) {
      isMTN = true;
    }

    if (selectedProviderId == LOCALES.ETSALAT_PROVIDER) {
      isEtsalat = true;
    }

    formErrors.providerIdError = selectedProviderId === "" ? 'You must make a selection' : "";

    this.setState({
      providerId: selectedProviderId,
      isCOMVIVA: isCOMVIVA,
      isMTN: isMTN,
      isEtsalat: isEtsalat,
      formErrors
    });
  }

  ClientIdValue(e) {
    const { formErrors } = this.state

    this.state.clientId = e.target.value
    let selectedProviderId = e.target.value
    this.state.clientId = ""
    if (selectedProviderId === "----please select---") selectedProviderId = ""

    formErrors.clientIdError = selectedProviderId === "" ? 'You must make a selection' : "";

    this.setState({
      clientId: selectedProviderId,
      formErrors
    });
  }

  StatusValue = e => {
    const { formErrors } = this.state
    var index = e.nativeEvent.target.selectedIndex;
    var text =e.nativeEvent.target[index].text;
    this.state.statusId = e.target.value
    this.state.statusValue = text
    let selectedValue = e.target.value
    this.state.statusId = ""
    if (selectedValue === null) selectedValue = ""

    formErrors.statusError = selectedValue === "" ? 'You must make a selection' : "";

    this.setState({
      statusId: selectedValue,
      statusValue: this.state.statusValue,
      formErrors
    });
  }

  TypeValue = e => {
    const { formErrors } = this.state

    var index = e.nativeEvent.target.selectedIndex;
    var text =e.nativeEvent.target[index].text;
    this.state.typeId = e.target.value
    this.state.typeValue = text
    let selectedValue = e.target.value
    
    this.state.typeId = ""
    if (selectedValue === null) selectedValue = ""

    formErrors.typeError = selectedValue === "" ? 'You must make a selection' : "";

    this.setState({
      typeId: selectedValue,
      typeValue: this.state.typeValue,
      formErrors
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    
    const {
      IPAddress, addtionalPath, startCommand, command,
      serviceCode, bearerCode, language, currency, providerId,
      clientId, clientUserId, initiatorPrincipalId, channel,
      clientReference, password, clientRequestTimeout,integrationTypeId,
      formErrors, statusId, typeId
    } = this.state

    
    
    formErrors.IPAddressError = IPAddress === '' ? "IP address is Required" : this.state.formErrors.IPAddressError;
    formErrors.addtionalPathError = addtionalPath === '' ? "Address Path is Required" : this.state.formErrors.addtionalPathError;
    formErrors.providerIdError = providerId === '' || providerId === null || providerId === undefined ? 'You must make a selection' : this.state.formErrors.providerIdError;
    formErrors.statusError = statusId === '' || statusId === null || statusId === undefined ? 'You must make a selection' : this.state.formErrors.statusError;
    formErrors.typeError = typeId === '' || typeId === null || typeId === undefined ? 'You must make a selection' : this.state.formErrors.typeError;
    formErrors.integrationTypeError = integrationTypeId === '' || integrationTypeId === null || integrationTypeId === undefined ? 'You must make a selection' : this.state.formErrors.integrationTypeError;
 
    if (providerId !== '' && providerId === LOCALES.COMVIVA_PROVIDER) {
      formErrors.serviceCode = serviceCode === '' || serviceCode === null || serviceCode === undefined ? 'Service Code is Required' : this.state.formErrors.serviceCode;
      formErrors.bearerCodeError = bearerCode === '' || bearerCode === null || bearerCode === undefined ? 'Bearer Code is Required' : this.state.formErrors.bearerCodeError;
      formErrors.languageError = language === '' || language === null || language === undefined ? 'Language is Required' : this.state.formErrors.languageError;
      formErrors.currencyError = currency === '' || currency === null || currency === undefined ? 'Currency is Required' : this.state.formErrors.currencyError;
    }

    if (providerId !== '' && providerId === LOCALES.MTN_PROVIDER) {
      formErrors.clientIdError = clientId === '' || clientId === null || clientId === undefined ? 'You must make a selection' : this.state.formErrors.clientIdError;
      formErrors.clientUserIdError = clientUserId === '' || clientUserId === null || clientUserId === undefined ? 'Client User Id is Required' : this.state.formErrors.clientUserIdError;
      formErrors.clientReferenceError = clientReference === '' || clientReference === null || clientReference === undefined ? 'Client Reference is Required' : this.state.formErrors.clientReferenceError;
      formErrors.clientRequestTimeoutError = clientRequestTimeout === '' || clientRequestTimeout === null || clientRequestTimeout === undefined ? 'Client Request Timeout is Required' : this.state.formErrors.clientRequestTimeoutError;
      formErrors.initiatorPrincipalIdError = initiatorPrincipalId === '' || initiatorPrincipalId === null || initiatorPrincipalId === undefined ? 'Initiator Principal Id is Required' : this.state.formErrors.initiatorPrincipalIdError;
      formErrors.channelError = channel === '' || channel === null || channel === undefined ? 'Channel is Required' : this.state.formErrors.channelError;
      formErrors.passwordError = password === '' || password === null || password === undefined ? 'Password is Required' : this.state.formErrors.passwordError;

    }

    if (providerId !== '' && providerId === LOCALES.ETSALAT_PROVIDER) {
      formErrors.startCommandError = startCommand === '' || startCommand === null || startCommand === undefined ? 'Start Command is Required' : this.state.formErrors.startCommandError;
      formErrors.commandError = command === '' || command === null || command === undefined ? 'Command is Required' : this.state.formErrors.commandError;

    }

    if (formValid(this.state.formErrors)) {
      const providerInfo = new FormData()
      providerInfo.append("providerId", this.state.providerId)
      providerInfo.append("ipAddress", this.state.IPAddress)
      providerInfo.append("port", this.state.port)
      providerInfo.append('startCommand', this.state.startCommand === '' ? 0 : this.state.startCommand)
      providerInfo.append("command", this.state.command)
      providerInfo.append("serviceCode", this.state.serviceCode)
      providerInfo.append("bearerCode", this.state.bearerCode)
      providerInfo.append("language", this.state.language)
      providerInfo.append("currency", this.state.currency)
      providerInfo.append("addtionalPath", this.state.addtionalPath)
      providerInfo.append("clientId", this.state.clientId)
      providerInfo.append("clientReference", this.state.clientReference)
      providerInfo.append('clientRequestTimeout', this.state.clientRequestTimeout)
      providerInfo.append("clientUserId", this.state.clientUserId)
      providerInfo.append("channel", this.state.channel)
      providerInfo.append("password", this.state.password)
      providerInfo.append('initiatorPrincipalId',this.state.initiatorPrincipalId)
      providerInfo.append('status',this.state.statusId)
      providerInfo.append('statusValue',this.state.statusValue)
      providerInfo.append('type',this.state.typeId)
      providerInfo.append('typeValue',this.state.typeValue)
      providerInfo.append('integrationTypeId',this.state.integrationTypeId)
      providerInfo.append("userId", localStorage.userId)

     

      this.setState({ isLoading: true})
      ProvidersService.createProvidersInfos(providerInfo)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoading: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'IPAddress':
        formErrors.IPAddressError =
          value.length === 0 || !IPAddressRegEx.test(value) ? name + " Sholud be a valid IP Address " : ""
        break;
      case 'port':
        formErrors.portError =
          !portRegEx.test(value) ? name + " Sholud be a valid Port " : ""
        break;
      case 'addtionalPath':
        formErrors.addtionalPathError =
          value.length === 0 || !addtionalPathReqEx.test(value) ? name + " Sholud be a valid Path " : ""
        break;
      case 'serviceCode':
        formErrors.serviceCodeError =
          value.length === 0 || value.length < 2 || !serviceCodeRegEx.test(value) ? name + " sholud be alphabetic with a min 3 & max 4 " : ""
        break;
      case 'bearerCode':
        formErrors.bearerCodeError =
          value.length === 0 || !bearerCodeRegEx.test(value) ? name + " sholud be alphabetic with a min 4 & max 4 " : ""
        break;
      case 'language':
        formErrors.languageError =
          value.length === 0 || value.length < 2 || !languageRegEx.test(value) ? name + " sholud be alphabetic with a min 2 & max 4 " : ""
        break;
      case 'currency':
        formErrors.currencyError =
          value.length === 0 || value.length < 3 || !currencyRegEx.test(value) ? name + " sholud be digites with a min 3 & max 5 " : ""
        break;
      case 'startCommand':
        formErrors.startCommandError =
          value.length === 0 || value.length < 3 || !startCommRegEx.test(value) ? name + " sholud be digites with a min 3 & max 4 " : ""
        break;
      case 'command':
        formErrors.commandError =
          value.length === 0 || !commandRegEx.test(value) ? name + " sholud be a valid provider command " : ""
        break;
      case 'clientUserId':
        formErrors.clientUserIdError =
          value.length === 0 || !clientUserIdRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'initiatorPrincipalId':
        formErrors.initiatorPrincipalIdError =
          value.length === 0 || !initiatorPrincipalIdRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'channel':
        formErrors.channelError =
          value.length === 0 || !channelRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'clientReference':
        formErrors.clientReferenceError =
          value.length === 0 || !clientReferenceRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'password':
        formErrors.passwordError =
          value.length === 0 || !passwordRegEx.test(value) ? name + " sholud be valid password with a min 3 & max 30 " : ""
        break;

        case 'integrationTypeId':
        formErrors.integrationTypeError =
          value.length === 0  ? "Please select the integration type." : ""
        break;

      case 'clientRequestTimeout':
        formErrors.clientRequestTimeoutError =
          value.length === 0 || !clientRequestTimeoutRegEx.test(value) ? name + " sholud be digites with a min 1 & max 10 " : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  cancel() {
    this.props.history.push('/providersList')
  }


  render() {
    const { providerIds,integrationTypeList, status, types, formErrors } = this.state

   

    let providerInfoList = providerIds.length > 0
      && providerIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.providerName }</option>

        )
      }, this);

      let providerStatus = status.length > 0
      && status.map((item, i) => {
        return (
          <option key={ i }  label={ item.statusValue } value={ item.id }>{ item.statusValue }</option>

        )
      }, this);

      let providerType = types.length > 0
      && types.map((item, i) => {
        return (
          <option key={ i }  label={ item.type } value={ item.id }>{ item.type }</option>

        )
      }, this);

    return (

      <div >
        {/* <Header />
        <TopupMenu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Provider Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Provider Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit } noValidate>
                      <div className='row mb-2'>
                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6  ">
                          <label htmlFor='ProviderList'>Provider Company Name:</label>
                          <select className="form-control "
                            value={ this.state.providerId }
                            onChange={ this.providerValue } id='ProviderList'>
                            <option key={ null } value={ null }>----please select---</option>
                            { providerInfoList }
                          </select>

                          { formErrors.providerIdError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                          ) }
                        </div>
                        <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor='IPAddress'> IP Address:</label>
                          <input type="text" id='IPAddress' className="form-control" name='IPAddress'
                            onChange={ this.handleChange } onBlur={ this.handleChange }
                            placeholder="IP Address" />

                          { formErrors.IPAddressError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.IPAddressError }</span>
                          ) }
                        </div>
                      </div>
                      <div className='row mb-2'>

                        <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor='port'> Port:</label>
                          <input type="text" id='port' className="form-control" name='port'
                            onChange={ this.handleChange } onBlur={ this.handleChange }
                            placeholder="Port" />

                          { formErrors.portError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.portError }</span>
                          ) }
                        </div>
                        <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor='addtionalPath'> Addtional Path:</label>
                          <input type="text" id='addtionalPath' className="form-control" name='addtionalPath'
                            onChange={ this.handleChange } onBlur={ this.handleChange }
                            placeholder="Addtional Path" />

                          { formErrors.addtionalPathError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.addtionalPathError }</span>
                          ) }
                        </div>
                      </div>
                      <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='status'> Status:</label>
                              <select className="form-control "
                                value={ this.state.statusId }
                                onChange={ this.StatusValue } id='status'>
                                <option key={ null } value={ null }>----please select---</option>
                               {providerStatus}
                                </select>

                              { formErrors.statusError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.statusError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='type'> Type:</label>
                              <select className="form-control "
                                value={ this.state.type }
                                onChange={ this.TypeValue } id='type'>
                                <option key={ null } value={ null }>----please select---</option>
                                  {providerType}
                                </select>
                              { formErrors.typeError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.typeError }</span>
                              ) }
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='integrationTypeId'>Integration Type:</label>

                              <select id='integrationTypeId'  onChange={this.handleChange} onBlur={this.handleChange} className="form-control" name='integrationTypeId'>
                                <option value=""> Select integration type</option>
                                {integrationTypeList.map(integrationType =>
                                  <option value={integrationType.id}>{integrationType.type}</option>
                                )}
                              </select>
                              {formErrors.integrationTypeError > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.integrationTypeError}</span>
                              )}
                            </div>

                            </div>
                      
                      <div hidden={ !this.state.isCOMVIVA } className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >COMVIVA Related Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='serviceCode'> Service Code:</label>
                              <input type="text" id='serviceCode' className="form-control" name='serviceCode'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Service Code" />

                              { formErrors.serviceCodeError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.serviceCodeError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='bearerCode'> Bearer Code:</label>
                              <input type="text" id='bearerCode' className="form-control" name='bearerCode'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Bearer Code" />

                              { formErrors.bearerCodeError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.bearerCodeError }</span>
                              ) }
                            </div>

                          </div>
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='language'> Language:</label>
                              <input type="text" id='language' className="form-control" name='language'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="language" />

                              { formErrors.languageError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.languageError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='currency'>Currency:</label>
                              <input type="text" id='currency' className="form-control" name='currency'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="currency" />

                              { formErrors.currencyError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.currencyError }</span>
                              ) }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div hidden={ !this.state.isMTN } className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >MTN Related Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='clientId'> Client Id:</label>
                              <select className="form-control "
                                value={ this.state.clientId }
                                onChange={ e => this.ClientIdValue(e).bind(this) } id='ProviderList'>
                                <option key={ null } value={ null }>----please select---</option>
                                <option key='TERMINALID' value='TERMINALID'>TERMINALID</option>
                                <option key='ADMINUSER' value='ADMINUSER'>ADMINUSER</option>
                                <option key='RESELLERMSISDN' value='RESELLERMSISDN'>RESELLERMSISDN</option>
                                <option key='RESELLERUSER' value='RESELLERUSER'>RESELLERUSER</option>
                              </select>

                              { formErrors.clientIdError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.clientIdError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='clientUserId'> Client User Id:</label>
                              <input type="text" id='clientUserId' className="form-control" name='clientUserId'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Client User Id " />

                              { formErrors.clientUserIdError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.clientUserIdError }</span>
                              ) }
                            </div>

                          </div>
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='initiatorPrincipalId'> Initiator Principal Id:</label>
                              <input type="text" id='initiatorPrincipalId' className="form-control" name='initiatorPrincipalId'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Initiator Principal Id" />

                              { formErrors.initiatorPrincipalIdError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.initiatorPrincipalIdError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='channel'>Channel:</label>
                              <input type="text" id='channel' className="form-control" name='channel'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="channel" />  

                              { formErrors.channelError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.channelError }</span>
                              ) }
                            </div>
                          </div>
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='clientReference'> Client Reference:</label>
                              <input type="text" id='clientReference' className="form-control" name='clientReference'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Client Reference" />

                              { formErrors.clientReferenceError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.clientReferenceError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='password'>Password:</label>
                              <input type="text" id='password' className="form-control" name='password'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="password" />

                              { formErrors.passwordError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.passwordError }</span>
                              ) }
                            </div>
                          </div>
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='clientRequestTimeout'>Client Request Timeout:</label>
                              <input type="text" id='clientRequestTimeout' className="form-control" name='clientRequestTimeout'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Client Request Timeout" />

                              { formErrors.clientRequestTimeoutError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.clientRequestTimeoutError }</span>
                              ) }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div hidden={ !this.state.isEtsalat } className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Etsalat Related Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='startCommand'>Start Command:</label>
                              <input type="text" id='startCommand' className="form-control" name='startCommand'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Start Command" />

                              { formErrors.startCommandError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.startCommandError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='command'>Command:</label>
                              <input type="text" id='command' className="form-control" name='command'
                                onChange={ this.handleChange } onBlur={ this.handleChange }
                                placeholder="Command" />

                              { formErrors.commandError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.commandError }</span>
                              ) }
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row  d-flex justify-content-left ">
                        <button disabled={this.state.isLoading} type="submit"
                          className="btn btn-success px-4 py-2" style={ { marginLeft: "30px" } }>Save</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
          
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}



export default AddProviders;