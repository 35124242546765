import API from '../../../src/http-common';

class OverdraftSettlementService {
    getRecentOvedraftSettlements() {
        return API.get('/api/v1/getRecentOvedraftSettlements');
    }
    getWalletByCustomerId(id, userId) {
        return API.get('/api/v1/getWalletByCustomerId/' + id + "/" + userId);
    }
    getRecentWalletTrxnByCustomerId(id, userId) {
        return API.get('/api/v1/getRecentWalletTrxnByCustomerId/' + id + "/" + userId);
    }

    createOvedraftSettlement(OvedraftSettlement) {
        return API.post("/api/v1/createOverdraftSettlement", OvedraftSettlement);
    }

}
export default new OverdraftSettlementService()