import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import moment from 'moment';
import NumberFormat from 'react-number-format'; 

export default class ViewBanksInfo extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            id: this.props.match.params.id,
            companyBankInfo: [{
                bankInfoId: [],
                customerId: [],
                bankAccount:"",
                balance: "",
                createdAt: "",
            }]
        };
    }

    componentDidMount() {
        this.getCustomerBankInfo(this.props.location.state.id);
    }

    getCustomerBankInfo(id) {

        CustomerBankInfoService.getCustomerBankInfo(id, localStorage.userId)
            .then(response => {
                this.setState({
                    companyBankInfo: response.data
                });
            })
            .catch(e => {
            });
    }

    render() {
        const { companyBankInfo } = this.state;

        return (
            <div>
                {/* <Header />
                <Menu /> */}
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">

                        <div className="row mb-2">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Company Bank Info Detail </li>
                                </ol>
                            </div>

                        </div>

                        <div >
                            <div className="card ">
                                <div className="card-body">

                                    <div className="form-title" >Detail View</div>
                                    <br />

                                    <div className="table-responsive w-50">

                                        <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                            {/* <tr className='no-gutters'>
                                                <td className="text-left font-weight-bolder" >Name</td>
                                                <td className="text-justify ">{ companyBankInfo.name }</td>
                                            </tr> */}
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Bank Name</td> 
                                                <td className="text-justify">{ companyBankInfo.bankInfoId && companyBankInfo.bankInfoId.name  }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Bank Account</td>
                                                <td className="text-justify">{ companyBankInfo.bankAccount }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Ledger Account</td> 
                                                <td className="text-justify">{ companyBankInfo.customerId && companyBankInfo.customerId.ledgerAccountId && companyBankInfo.customerId.ledgerAccountId.accountCode   }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Balance</td>
                                                <td className="text-justify"> 
                                                <NumberFormat value={ companyBankInfo.balance+".00" } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                </td>

                                            </tr>
                                      
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Date</td>
                                                { companyBankInfo.createdAt && 
                                                <td className="text-justify">{ moment(companyBankInfo.createdAt).format('YYYY-MM-DD') } </td>
                                                }
                                                 { !companyBankInfo.createdAt && 
                                                <td className="text-justify">{""} </td>
                                                }
                                            </tr>
                                       
                                            <tr style={ { textAlign: 'center' } }>
                                                <Link to="/companyBanksInfoList" className='pl-5'>
                                                    <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                </Link>

                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className='col-2'></div>




                        {/* /.row */ }
                    </div>
                    {/* /.container-fluid */ }
                </div>
            </div>

        )
    }
}