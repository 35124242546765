import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import CustomersService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import TopupOrderService from '../../../services/etopup/TopupOrderService';
import ProviderService from '../../../services/etopup/ProviderService';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import LoginService from '../../../services/common/LoginService';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class EditTopupOrder extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            topupOrderId: this.props.match.params.topupOrderId,

            topupOrderData: {
                id: '',
                dealerCustomerList: [],
                parentCustomerId: '',
                selecteDealerIds: null,
               // dealerCustomerId: '',
                amount: '',
                providerId: '',
                userId: '',
                customerDealers: {
                    dealerCustomerId: [
                      { id: 0 }
                    ],
                  },
                selectOption: null,
                formErrors: {
                    amountError: "",
                    selectOptionError: ""
                },
            },
            providersList: [],
            customerId:''
        };
        this.onChangeDealer = this.onChangeDealer.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);
    }


    onChangeDealer(e) {
        const dealerCustomerId = e.target.value;

        this.setState(function (prevState) {
            return {
                topupOrderData: {
                    ...prevState.topupOrderData,
                    dealerCustomerId: dealerCustomerId
                }
            };
        });
    }
    onChangeAmount(e) {
        const amount = e.target.value;

        this.setState(function (prevState) {
            return {
                topupOrderData: {
                    ...prevState.topupOrderData,
                    amount: amount
                }
            };
        });
    }
    fatchDealerCustomerData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
          setTimeout(() => {
            API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
              .then((response) =>
                response.data
              )
              .then((data) => {
                const temp = [];
                data.forEach((element) => {
                  temp.push({ label: element.fullName, value: element.id })
                });
                callback(temp)
                this.setState({
                  dealerCustomerList: data,
                })
              })
              .catch((error) => {
              })
          })
        }else {
            callback([])
          }
      }
    
      onDealerSearchChange = (selecteDealerIds) => {
        const { formErrors } = this.state
        if (selecteDealerIds) {
          formErrors.dealerCustomerError = selecteDealerIds == "" ? 'You must make a selection' : "";
          this.setState({
            selecteDealerIds:this.state.selecteDealerIds.value,
            formErrors
          });
        }
      }
    
    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }

    Cancel = (e) => {
        this.props.history.push('/ordersList');
    }

    componentDidMount() {
        this.getTopupOrderById(this.props.location.state.topupOrderId);
        
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });

            LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerType: response.data.type
                });
            })
            .catch(e => {
                
            });
    }


    getTopupOrderById(topupOrderId) {
        TopupOrderService.getTopupOrderById(topupOrderId, localStorage.userId)
            .then(response => {
                this.setState({
                    //  topupOrderData: response.data
                    topupOrderData: response.data,
                    dealerCustomerId: response.data.dealerCustomerId,
                    isLoading: true,
                });
           
            })
            .catch(e => {
                
                this.setState({ isLoading: true })
            });
    }
    handleSubmit = e => {
        const {  selecteDealerIds, formErrors } = this.state
        e.preventDefault();
        
        var data = new FormData();
        data.append("id", this.props.location.state.topupOrderId);
        data.append("amount", this.state.topupOrderData.amount);
        data.append("dealerCustomerId", selecteDealerIds && selecteDealerIds.value);
        data.append("parentCustomerId",  localStorage.customerIdentity);
        data.append("userId", localStorage.userId);



        TopupOrderService.updateTopupOrder(data)
            .then(
                (response) => {
                NotificationManager.success('Updated Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.Cancel()
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            });

    }


    render() {

        const { topupOrderData, isLoading, selecteDealerIds,customerDealers,formErrors, providersList } = this.state;
    
        if (selecteDealerIds === null && customerDealers.dealerCustomerId.id) {
            this.state.selecteDealerIds = {
              label: customerDealers.dealerCustomerId && customerDealers.dealerCustomerId.fullName,
              value: customerDealers.dealerCustomerId && customerDealers.dealerCustomerId.id
            }
          }

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">

                                                        <label htmlFor='DealerCustomerList'>Dealer :</label>
                                                            <AsyncSelect id='DealerCustomerList'
                                                                    className=" form-group"
                                                                    placeholder={"Search by name or mobile number"}
                                                                    value={this.state.selecteDealerIds}
                                                                    isClearable={true}
                                                                    loadOptions={this.fatchDealerCustomerData}
                                                                    isLoading={this.state.selecteDealerIds === {} &&
                                                                        this.state.selecteDealerIds === undefined &&
                                                                        this.state.selecteDealerIds === null ? true : false
                                                                    }

                                                                    onChange={(e) => {
                                                                        this.onDealerSearchChange(e)
                                                                    }}
                                                                    defaultOptions={false}
                                                                    />
                                                                
                                                                {/* {formErrors.dealerCustomerError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.dealerCustomerError}</span>
                                                                )} */}

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='FullName'>Amount:</label>
                                                            <input type="text" className="form-control " placeholder="amount" value={ topupOrderData.amount } onChange={ this.onChangeAmount } required="required" />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>


                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditTopupOrder);