import API from '../../http-common';

class CustomerService {
  getCountOfAllCustomers(userId) {
    return API.get("/api/v1/allCustomers/" + userId);
  }

  getFilterdCustomerWithPagination(pageNo, pageSize, customerInfo) {
    // return fetch('/api/v1/allCustomers').then((res => res.json()));
    return API.get("/api/v1/getFilterdCustomerWithPagination/" + pageNo + "/" + pageSize, customerInfo);
  }
  getCustomerById(customerId, userId){
    return API.get("/api/v1/getCustomerById/" + customerId + "/" + userId);
    
  }

  getCustomerModelById(customerId, userId){
    return API.get("/api/v1/getCustomerModelById/" + customerId + "/" + userId);
    
  }

  createCustomer(customer) {
    // return fetch('/api/v1/createAgent',agent);
    return API.post("/api/v1/createAgent", customer);
  }

  getCustomerByMobileNo(mobileNo) {
    return API.get("/api/v1/getAgentByMobileNo/" + mobileNo);
  }


  getAllTopupCustomers(userId) {
    return API.get("/api/v1/getAllTopupCustomers/" + userId);
  }

  deleteCustomer(mobileNo) {
    //  return fetch('/api/deleteAgent' + '/' + id);
    return API.delete('/api/v1/deleteCustomerByMobileNo' + '/' + mobileNo);
  }
  getDailyTopupCustomers(date, userId) {
    return API.get("/api/v1/getDailyTopupCustomers/" + date + "/" + userId);
  }

}

export default new CustomerService()