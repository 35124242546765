import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import TopupMenu from '../../etopup/TopupMenu';
import Header from '../../../components/common/Header';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import LoginService from '../../../services/common/LoginService';
import ProviderService from '../../../services/etopup/ProviderService';

import SubRegionService from '../../../services/wallet/SubRegionService';
import API from '../../../http-common';
import AsyncSelect from 'react-select/async';

class SubRegionList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            id: null,
            regionId: null,
            subRegionId: null,
            isLoading: false,
            pageNo: 1,
            providersList: [],
            selectOption: null,
            subRegionsList: [],
            searchParam: [],
            providerId: null,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            targetList: [],
            isExpand: true,
        }

        this.editPartnetTarget = this.editPartnetTarget.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(value) {
        this.setState({ searchMonth: value });
    }
    clearValue(e) {
        e.preventDefault();
        this.state.searchMonth = null
        this.state.providerId = null
        e.target.reset();
    }
    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.searchSubRegionOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchSubRegionOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchSubRegionOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchSubRegionOnFilter()
            this.render()

        }
    }

    fatchData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getSubRegionBySearchParam/" + inputValue)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.subRegionName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            amCodeList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }
    onSearchChange = (selectOption) => {
        const { formErrors } = this.state
        formErrors.amCodeIdError = selectOption == "" || selectOption == null ? 'You must make a selection' : "";

        this.setState({
            selectOption: selectOption,
            formErrors
        });
    }

    searchSubRegionInfo(e) {
        this.searchSubRegionOnFilter(this.state.searchMonth)
    }
    searchSubRegionOnFilter(searchMonth) {
        if (searchMonth === null) {
            //  this.setState({ currentMonth: moment().format("yyyy-MM-DD") })
        } else {
            this.setState({ currentMonth: null })
        }
        let searchParam = {
            // searchMonth: moment(this.state.searchMonth).format('yyyy-MM-DD'),
            providerId: this.state.providerId,
            currentMonth: this.state.currentMonth
        }

        SubRegionService.getAllSubRegions(this.state.pageNo, this.state.pageSize, searchParam)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    subRegionsList: data.content,
                    isLoading: true,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
                // console.log(data.content)
                this.setState({ isLoading: true })
            });
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    componentDidMount() {
        this.searchSubRegionOnFilter()

        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId
                });

            })
            .catch(e => {
                
            });

        {/*To get list of regions*/ }
        ProviderService.getAllProviders()
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }



    editPartnetTarget(partnerTargetId) {
        this.props.history.push(`/editPartnerTarget/${partnerTargetId}`);
    }


    // Delete customer
    async deleteCommission(id) {
        if (window.confirm("Are you sure you want to delete this commission?")) {
            try {
                //  const response = await RegionService.deleteRegionById(id);
                //toast.success("Deleted successfully");
                NotificationManager.success("Deleted successfully", "Commission Deletion");
            } catch (error) {
                //toast.error("Something went wrong !!");
                NotificationManager.error("Something went wrong !!", "Commission Deletion");
            }
        }
    }

    render() {
        const { subRegionsList, pageNo, selectOption, totalPage, id, regionName, isLoading, providersList } = this.state;

      
        if (selectOption === null && id !== '' && regionName !== '') {
            this.state.selectOption = {
                label: regionName,
                value: id
            }
        }
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <NotificationContainer />
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Sub Regions </li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>

                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='provider'>Region Name</label>
                                                    <div >
                                                        {/* <select onChange={ e => this.state.providerId = e.target.value } className="form-control" >
                                                            <option value=""> Choose provider</option>
                                                            { providersList.map(provider =>
                                                                <option value={ provider.id }>{ provider.providerName }</option>
                                                            ) }
                                                        </select> */}
                                                        <AsyncSelect id='CustomerList'
                                                            className=" form-group col-12 col-sm-12 col-md-12 col-lg-12  "
                                                            placeholder={ "Search By Region Name" }
                                                            value={ this.state.value }
                                                            isClearable={ true }
                                                            loadOptions={ this.fatchData }

                                                            isLoading={ this.state.selectOption === {} &&
                                                                this.state.selectOption === undefined &&
                                                                this.state.selectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onSearchChange(e)
                                                            } }
                                                            defaultOptions={ true }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='month' >SubRegion Name</label>
                                                    <div >
                                                        {/* <input type="date" className="form-control" id='searchMonth'
                                                            onChange={ e => this.state.searchMonth = e.target.value }
                                                            placeholder="Month" /> */}
                                                        <AsyncSelect id='CustomerList'
                                                            className=" form-group col-12 col-sm-12 col-md-12 col-lg-12  "
                                                            placeholder={ "Search By Sub Region Name" }
                                                            value={ this.state.value }
                                                            isClearable={ true }
                                                            loadOptions={ this.fatchData }

                                                            isLoading={ this.state.selectOption === {} &&
                                                                this.state.selectOption === undefined &&
                                                                this.state.selectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onSearchChange(e)
                                                            } }
                                                            defaultOptions={ true }
                                                        />

                                                    </div>
                                                </div>
                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3 '">
                                                    <button type="button" title={ "Search" }
                                                        className="btn btn-sm btn-success primary float-right mt-4 pt-2 ml-2"
                                                        onClick={ () => this.searchSubRegionInfo() }>Search</button>
                                                    <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                        className="btn btn-sm btn-danger primary  float-right mt-4 pt-2"  >Clear</button>
                                                </div>


                                            </div>

                                            <br />

                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />

                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>

                                        <Link to={ { pathname: '/addPartnerTarget', state: { type: "Agent", formName: "region Registration" } } }>
                                            <button style={ { marginLeft: "3px" } } type="button" className="btn btn-sm btn-success float-right mb-2" onClick={ this.addregion }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>

                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Customer Name</th> */ }
                                                    <th>Region Name</th>
                                                    <th>Sub Region Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                { subRegionsList.length > 0 ?
                                                    subRegionsList.map(list =>

                                                        <tr>
                                                            {/* <td className='p-1 '>{target.fullName}</td> */ }
                                                            <td className='p-1 '>{ list.regionId.region }</td>
                                                            <td className='p-1 '>{ list.subRegionName }</td>
                                                            <td className='p-1'>

                                                                <Link to={ { pathname: '/viewSubRegion', state: { subRegionId: list.id } } }>
                                                                    <button className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                                </Link>
                                                                <Link to={ { pathname: '/editSubRegion', state: { subRegionId: list.id } } }>
                                                                    <button style={ { marginLeft: "2px" } }
                                                                        // onClick={ () => this.editPartnetTarget(list.id) }
                                                                        className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                                </Link>
                                                                {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteCommission(list.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */ }
                                                            </td>
                                                        </tr>

                                                    ) :
                                                    <div>{ "" }</div>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default SubRegionList