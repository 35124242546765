import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PartnerTargetService from '../../../services/etopup/PartnerTargetService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import TopupMenu from '../../etopup/TopupMenu';
import Header from '../../../components/common/Header';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import LoginService from '../../../services/common/LoginService';
import ProviderService from '../../../services/etopup/ProviderService';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import '../../../assets/css/style.css';

class MonthlyTarget extends Component {

    constructor (props) {

        super(props)
        this.state = {
            currentMonth: null,
            isLoading: false,
            pageNo: 1,
            providersList: [],
            customerId: '',
            searchMonth: new Date(),
            providerId: null,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            targetList: [],
            isExpand: true,
        }

        this.editPartnetTarget = this.editPartnetTarget.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(value) {
        this.setState({ searchMonth: value });
    }
    clearValue(e) {
        this.state.searchMonth = null
        this.state.providerId = null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.seachPartnerTargetOnFilter()

        }
    }
    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachPartnerTargetOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachPartnerTargetOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachPartnerTargetOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachPartnerTargetOnFilter()
            this.render()

        }
    }
    searchPartnerTargetInfo(e) {
        this.seachPartnerTargetOnFilter(this.state.searchMonth)
    }
    seachPartnerTargetOnFilter(searchMonth) {
        if (searchMonth === null) {
            this.setState({ currentMonth: moment().format("yyyy-MM-DD") })
        } else {
            this.setState({ currentMonth: null })
        }
        let searchParam = {
            searchMonth: moment(this.state.searchMonth).format('yyyy-MM-DD'),
            providerId: this.state.providerId,
            currentMonth: this.state.currentMonth
        }

        PartnerTargetService.getPartnerTargetByMonth(this.state.pageNo, this.state.pageSize, localStorage.userId, searchParam)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    targetList: data.content,
                    isLoading: true,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
                this.setState({ isLoading: true })
            });
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    componentDidMount() {
        this.seachPartnerTargetOnFilter()

        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }



    editPartnetTarget(partnerTargetId) {
        this.props.history.push(`/editPartnerTarget/${partnerTargetId}`);
    }


    // Delete customer
    async deleteCommission(id) {
        if (window.confirm("Are you sure you want to delete this commission?")) {
            try {
                //  const response = await RegionService.deleteRegionById(id);
                //toast.success("Deleted successfully");
                NotificationManager.success("Deleted successfully", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            } catch (error) {
                //toast.error("Something went wrong !!");
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            }
        }
    }

    render() {
        const { targetList, pageNo, searchMonth, totalPage, isLoading, providersList } = this.state;

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Monthly Targets</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body pt-0">
                                        {/* <div className="form-title" >Partner Monthly Targets </div> */}

                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>

                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='provider'>Provider Name</label>
                                                    <div >
                                                        <select onChange={ e => this.state.providerId = e.target.value } className="form-control" >
                                                            <option value=""> Choose provider</option>
                                                            { providersList.map(provider =>
                                                                <option value={ provider.id }>{ provider.providerName }</option>
                                                            ) }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3'">
                                                    <label htmlFor='month' >Month</label>
                                                    <div >
                                                        {/* <input type="date" className="form-control" id='searchMonth'
                                                            onChange={ e => this.state.searchMonth = e.target.value }
                                                            placeholder="Month" /> */}
                                                        <DatePicker
                                                            shouldCloseOnSelect={ true }
                                                            onChange={ (event) => this.handleChange(event) }
                                                            selected={ this.state.searchMonth }
                                                            dateFormat="yyyy-MM"
                                                            showMonthYearPicker
                                                        />

                                                    </div>
                                                </div>
                                                <div className="className='col-12 col-sm-8 col-md-3 col-lg-3 '">
                                                    <button type="button" title={ "Search" }
                                                        className="btn btn-sm btn-success primary float-right mt-4 pt-2 ml-2"
                                                        onClick={ () => this.searchPartnerTargetInfo() }>Search</button>
                                                    <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                        className="btn btn-sm btn-danger primary  float-right mt-4 pt-2"  >Clear</button>
                                                </div>


                                            </div>

                                            <br />

                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />

                                        <Link to={ { pathname: '/addPartnerTarget', state: { type: "Agent", formName: "region Registration" } } }>
                                            <button style={ { marginLeft: "3px" } } type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addregion }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Customer Name</th> */ }
                                                    <th>Provider Name</th>
                                                    <th>Target</th>
                                                    <th>Target Achieved </th>
                                                    <th>Month </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                { targetList.length > 0 ?
                                                    targetList.map(partnertarget =>

                                                        <tr >
                                                            {/* <td className='p-1 '>{target.fullName}</td> */ }
                                                            <td>{ partnertarget.providerId.providerName }</td>
                                                            <td>
                                                                <NumberFormat value={ partnertarget.target.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                            </td>
                                                            <td>
                                                                <NumberFormat value={ partnertarget.targetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                            </td>
                                                            <td>{ format(partnertarget.month, 'yyyy-MM') } </td>
                                                            < td >

                                                                <Link to={ { pathname: '/viewPartnerTarget', state: { partnerTargetId: partnertarget.id } } }>
                                                                    <button className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                                </Link>
                                                                <Link to={ { pathname: '/editPartnerTarget', state: { partnerTargetId: partnertarget.id } } }>
                                                                    <button style={ { marginLeft: "2px" } }
                                                                        // onClick={ () => this.editPartnetTarget(partnertarget.id) }
                                                                        className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                                </Link>
                                                                {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteCommission(partnertarget.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */ }
                                                            </td>
                                                        </tr>

                                                    ) :
                                                    <div>{ "" }</div>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default MonthlyTarget