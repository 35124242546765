import API from '../../../src/http-common';

class KioskCustomerBalanceService {

    getAllKioskCustomerBalance(pageNo, pageSize, userId, SearchParam) {
        return API.put('/api/v1/getAllKioskCustomerBalance/' + pageNo + '/' + pageSize + '/' + userId, SearchParam);
    }

    findAssignedKiosks(pageNo, pageSize, userId, SearchParam) {
        return API.put('/api/v1/findAssignedKiosks/' + pageNo + '/' + pageSize + '/' + userId, SearchParam);
    }
}
export default new KioskCustomerBalanceService()