import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import KioskCustomerBalanceService from '../../../services/wallet/KioskCustomerBalanceService';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import Moment from 'react-moment';


class KioskCustomerBalanceList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            isLoading: false,
            cashCollectorId: null,
            kioskSelectOption: null,
            userSelectOption: null,
            cashCollectorSelectionOption: null,
            kioskCustomerBalanceList: [],

            kioskId: null,
            userId: null,
            startDate: null,
            endDate: null,

            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,

            counter: 1,
            id: 0,
            isExpand: true,

            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);

    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.searchKioskCustomerBalanceListBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchKioskCustomerBalanceListBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchKioskCustomerBalanceListBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchKioskCustomerBalanceListBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.searchKioskCustomerBalanceListBasedOnFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.kioskId = null
        this.state.cashCollectorId = null
        this.state.clear = 1
    }

    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.searchKioskCustomerBalanceListBasedOnFilter()
        }
    }


    fetchUsers = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getUsersBySearchParam/" + inputValue)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            usersList: data,
                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }
    }

    onUserSearchChange = (cashCollectorSelectionOption) => {
        if (cashCollectorSelectionOption) {

            this.state.cashCollectorSelectionOption = cashCollectorSelectionOption
            this.setState({
                cashCollectorId: cashCollectorSelectionOption.value
            });
        }

    }


    fetchKisokData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findKioskBySearchParam/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.kioskId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            kioskList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onSearchChange = (kioskSelectOption) => {
        if (kioskSelectOption) {

            this.state.kioskSelectOption = kioskSelectOption
            this.setState({
                kioskId: kioskSelectOption.value
            });

        }

    }
    searchKioskInfo(e) {
        this.searchKioskCustomerBalanceListBasedOnFilter()
    }

    searchKioskCustomerBalanceListBasedOnFilter() {
        if (this.state.startDate != null && this.state.endDate == null) {
            this.setState({ endDate: moment().format("yyyy-MM-DD") });
        }
        else if (this.state.startDate == null && this.state.endDate != null) {
            NotificationManager.error("Please enter the start date", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {
            if (localStorage.customerIdentity != 1) {
                this.state.cashCollectorId = localStorage.userId
            }

            let searchParams = {
                kioskId: this.state.kioskId,
                userId: this.state.cashCollectorId,
            }

            KioskCustomerBalanceService.getAllKioskCustomerBalance(
                this.state.pageNo, this.state.pageSize, localStorage.userId, searchParams)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        isLoading: true,
                        kioskCustomerBalanceList: data.kioskBalanceList,
                        isOpen: false,
                        totalPage: data.totalPages,
                        totalelement: data.totalelement,
                        pageNo: data.pageNumber + 1
                    });
                
                    this.setState({ isLoading: true })
                });
        }
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    kioskHistory(kId,kioskId) {
        this.props.history.push({pathname: "/kioskSalesHistoryList", state: {kId: kId, searchKioskId: kioskId}})
     //  console.log(kId)  
     //  console.log(kioskId)  
      }
    componentDidMount() {
        this.searchKioskCustomerBalanceListBasedOnFilter(localStorage.userId)
    }


    render() {
        const { kioskCustomerBalanceList, isLoading, pageNo, totalPage, providersList, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active"> Kiosk Sales</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={ { fontFamily: 'normal' } }>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Sales List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-3">

                                                    <div>
                                                        <label htmlFor='kioskId' > Kiosk</label>
                                                        <AsyncSelect id='kioskList'
                                                            required
                                                            placeholder={ "Search kioskId" }
                                                            value={ this.state.kioskSelectOption }
                                                            isClearable={ true }
                                                            loadOptions={ this.fetchKisokData } kioskSelectOption
                                                            isLoading={ this.state.kioskSelectOption === {} &&
                                                                this.state.kioskSelectOption === undefined &&
                                                                this.state.kioskSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onSearchChange(e)
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">

                                                    <div>
                                                        <label htmlFor='usersList'> Cash Collector </label>
                                                        <AsyncSelect id='usersList'
                                                            required
                                                            placeholder={ "Search  user" }
                                                            value={ this.state.cashCollectorSelectionOption }
                                                            isClearable={ true }
                                                            loadOptions={ this.fetchUsers }
                                                            isLoading={ this.state.cashCollectorSelectionOption === {} &&
                                                                this.state.cashCollectorSelectionOption === undefined &&
                                                                this.state.cashCollectorSelectionOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onUserSearchChange(e)
                                                            } }
                                                            defaultOptions={ false }
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-2 mt-4 pt-1">
                                                    <div>
                                                        <button title={ "Search" }
                                                            className="btn btn-sm btn-success primary m-1 float-right"
                                                            onClick={ () => this.searchKioskInfo() }>Search</button>
                                                        <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                            className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        {/* <Link to={ { pathname: '/AddKiosk', state: { type: "Agent", formName: "order Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link> */}
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    {/* <th>Full Name</th> */ }
                                                    <th>KioskId</th>
                                                    <th>Kiosk Sales</th>
                                                    {/* <th>Date</th> */ }
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { kioskCustomerBalanceList !== undefined && kioskCustomerBalanceList.map(list =>

                                                    <tr className='table-tr'>
                                                        {/* <td className='p-0 '>{ list.fullName }</td> */ }
                                                        <td className='p-0 '>{ list.kioskId }</td>
                                                        <td className='p-0 '>
                                                            <NumberFormat value={ list.balance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
       
                                                        < td className='p-0' >
                                                            <button onClick={ () => this.kioskHistory(list.kId,list.kioskId) } className="btn btn-info btn-sm ml-1 action-buttons"><i className="nav-icon fa fa-eye" title='Click here for Detail History' /> </button>
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >

            )
        }
    }
}
export default KioskCustomerBalanceList