import React, { Component } from 'react';
import BankInfoService from '../../../services/common/BankInfoService';
import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';
import CustomersService from '../../../services/common/CustomersService';
import Menu from '../../wallet/Menu';
import AsyncSelect from 'react-select/async'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import API from '../../../http-common';
import ReactLoading from "react-loading";
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const accountNoRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u)
const balanceRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u)

class EditCustomerBankInfo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingPop: false,
      id: this.props.location.state.id,
      customerIds: [],
      bankInfoIds: [],
      customerList: [],
      selectOption: null,
      isCompany: false,
      bankInfoData: {
        customerId: [
          { id: 0 }
        ],
        bankInfoId: [
          { id: 0 }
        ],
        bankAccount: 0,
        defultStatus: 0
      },
      formErrors: {
        bankAccount: "",
        balanceError:"",
        bankInfoIdError: "",
        customerError: ""
      }
    }


  }

  fatchData = (inputValue, callback) => {

    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    const { formErrors } = this.state
    if (selectOption) {
      formErrors.customerError = selectOption == "" ? 'You must make a selection' : "";
      CustomersService.getCustomerById(selectOption.value, localStorage.userId)
      .then(response => {
        this.setState(function (prevState) {
          return {
            bankInfoData: {
              ...prevState.bankInfoData,
              customerId: response.data
            },
            formErrors
          };
        });     
     })
     .catch(e => {
       
     });
      this.setState({
        selectOption,
        formErrors
      });
    }
  }

  componentDidMount() {
    BankInfoService.getBankInfos()
      .then(response => {
        this.setState({
          bankInfoIds: response.data
        });
        
      })
      .catch(e => {
        
      });
    this.getCustomerBankInfoById(this.state.id)
    this.setState({
      isCompany:  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
    });

  }

  getCustomerBankInfoById(id) {
    CustomerBankInfoService.getCustomerBankInfo(id, localStorage.userId)
      .then(response => {
        this.setState({
          bankInfoData: response.data,
          bankInfoId: response.data.bankInfoId,
          customerId: response.data.customerId,
          isLoading: true
        });
      })
      .catch(e => {
      });

  }


  OnChangeBankValue = e => {
    const { formErrors } = this.state
    this.state.bankInfoData.bankInfoId.id = e.target.value;
    let bankInfoId = e.target.value;
    if (bankInfoId == "----please select---") bankInfoId = ""
    formErrors.bankInfoIdError = bankInfoId == "" ? 'You must make a selection' : "";
    this.setState(function (prevState) {
      return {
        bankInfoId: {
          ...prevState.bankInfoId,
          id: bankInfoId
        },
        formErrors
      };
    });

  }


  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    let formErrors = this.state.formErrors;
    switch (name) {
      case 'bankAccount':
        this.state.bankInfoData.bankAccount = value
        formErrors.bankAccount =
          value.length == 0 || value.length < 10 || !accountNoRegEx.test(value) ? name + " Sholud be digites with a min 10 & max 50" : "";
        break;
        case 'balance':
          this.state.bankInfoData.balance = value
          formErrors.balanceError =
             !balanceRegEx.test(value) ? name + " Sholud be digites with a min 1 & max 1000000000" : ""
          break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  cancel() {
    this.props.history.push('/customerBankInfoList')
  }


  updateCustomer() {
    const { bankAccount, bankInfoId, selectOption, formErrors, isCompany } = this.state
    formErrors.bankAccount = bankAccount == 0 ? "Account No is Required" : this.state.formErrors.bankAccount;
    formErrors.bankInfoIdError = bankInfoId.id == '' || bankInfoId.id == null || bankInfoId.id == undefined ? 'You must make a selection' : this.state.formErrors.bankInfoIdError;
    if(isCompany){
    formErrors.customerError = selectOption == '' || selectOption == null || selectOption == undefined ? 'You must make a selection' : this.state.formErrors.customerError;
    }
    if (formValid(this.state.formErrors)) {

      const formData = new FormData();
      formData.append("id", this.state.id)
      formData.append("customerId", this.state.selectOption && this.state.selectOption.value);
      formData.append("bankInfoId", this.state.bankInfoData.bankInfoId.id)
      formData.append("bankAccount", this.state.bankInfoData.bankAccount)
      formData.append("balance", this.state.bankInfoData.balance)
      formData.append("defultStatus", this.state.bankInfoData.defultStatus)
      formData.append("userId", localStorage.userId)

      this.setState({ isLoadingPop: true})
      CustomerBankInfoService.updateCustomerBankInfo(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            this.cancel()
          },
          (err) => {
            NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  }


  render() {
    const { bankInfoData, isLoading, bankInfoIds, formErrors, isCompany } = this.state

    this.state.selectOption = {
      label: bankInfoData.customerId.fullName,
      value: bankInfoData.customerId.id
    }

    let bankList = bankInfoIds.length > 0
      && bankInfoIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>

        )
      }, this);
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div >
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Modify Customer Bank Information</li>

                    </ol>
                  </div>

                </div>
                <div >
                  <div className="modal-body">

                    <div className='col-1'></div>
                    <div className='col-7 bg-light p-4'>

                      <div class="modal-header">
                        <h4 class="modal-title" >
                          Modify Customer Bank Information
                        </h4>
                      </div>
{isCompany ?
                      <div className=" form-group col-10 col-sm-10 col-md-10 col-lg-10  ">
                        <label htmlFor='CustomerList'>Customer Name:</label>
                        <div style={ { fontSize: 'smaller' } }>
                          <AsyncSelect id='CustomerList' name=''
                            value={ this.state.selectOption }
                            placeholder={ "Search by name, account no or mobile number" }
                            loadOptions={ this.fatchData }
                            className=""
                            onChange={ (e) => {
                              this.onSearchChange(e)
                            } }
                            defaultOptions={ false }
                          />
                          { formErrors.customerError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.customerError }</span>
                          ) }
                        </div>
                      </div>
                      :
                      ''
                  }
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='BankList'>Bank Name:</label>
                        <select className="form-control " value={ bankInfoData.bankInfoId.id }
                          onChange={ this.OnChangeBankValue } id='BankList'>
                          <option value={ null }>----please select---</option>
                          { bankList }
                        </select>
                        { formErrors.bankInfoIdError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.bankInfoIdError }</span>
                        ) }
                      </div>
                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='AccountNo'>Account No:</label>
                        <input type="text" className="form-control" id='AccountNo'
                          value={ bankInfoData.bankAccount } name='bankAccount'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Account No" required="required" />
                        { formErrors.bankAccount.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.bankAccount }</span>
                        ) }
                      </div>
                      { bankInfoData.customerId.customerType &&  bankInfoData.customerId.customerType.type ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='balance'>Balance:</label>
                        <input type="text" id='balance' className="form-control" name='balance'
                        value={ bankInfoData.balance}
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          // onChange={e => this.state.bankAccount = e.target.value}
                          placeholder="Balance" required={ true } />

                        { formErrors.balanceError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.balanceError }</span>
                        ) }
                      </div>
                      :
                      <></>
                      }

                      <div className="row  d-flex justify-content-left ">

                        <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }
                          onClick={ () => this.updateCustomer() }>Update</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>


                    </div>

                  </div>
                </div>
                <NotificationContainer />
                {this.state.isLoadingPop && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>

            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}


export default EditCustomerBankInfo;