import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import RegionService from '../../../services/common/RegionService';
import CustomersService from '../../../services/common/CustomersService';
import CountryService from '../../../services/common/CountryService';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Dropzone from "react-dropzone";
import { withRouter } from "react-router-dom";
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import CommissionService from '../../../services/etopup/CommissionService';
import Header from '../Header';
import ReactLoading from "react-loading";
import API from '../../../http-common';
import AsyncSelect from 'react-select/async';
import moment from 'moment';
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{3,50}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u)
const emailRegExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)

const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)
const OptionalRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,50}$/u)
const mobileNoRegEX = RegExp(/^[0-9\b]+$/u)
const fileTypes = ["JPEG ", "jpeg", "JPG", "jpg", "PNG", "png", "GIF", "gif"];
const amountRegEx = RegExp(/^(?=[1-9])[0-9\.]{1,20}$/u)

class EditCustomer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toCustomers: false,
            // For profilepic and Identity purpose
            selectedFiles: undefined,
            currentFile: undefined,
            fileInfos: [],
            selectedFilesIdentity: undefined,
            currentFileIdentity: undefined,
            attachment: '',
            identityCard: '',
            amCodeList: [],
            selectOption: null,
          //  customerMobileNo: this.props.location.state && this.props.location.state.customerMobileNo,
            id: this.props.location.state && this.props.location.state.id,
            isTopup: 0,
            isLoading: false,
            isLoadingPop: false,
            isCompany: false,
            birthDay: undefined,
            gender: '',
            palceofBirth: "",
            customerData: {
                regionId: '',
                countryId: '',
                firstName: '',
                middleName: '',
                lastName: '',
                mobileNo: '',
                regionId: '',
                countryId: '',
                customerType:'',
                email: null,
                city: '',
                street: '',
                companyName: '',
                website: '',
                account: '',
                isTopup: '',
                gender:"",
                placeofBirthValue:"",
                birthDate:"",
                id: '',
                latitude:'',
                longitude:''
            },
            formErrors: {
                firstNameError: "",
                middleNameError: "",
                lastNameError: "",
                mobileNoError: "",
                regionIdError: "",
                countryIdError: "",
                customerTypeError: "",
                emailError: "",
                longitudeError:"",
                latitudeError:"",
                cityError: "",
                streetError: "",
                companyNameError: "",
                websiteError: "",
                amCodeIdError: "",
                idNumberError: "",
                issueDateError: "",
                pageNoError: "",
                registryNoError: "",
                volumeNoError: "",
                birthDayError: "",
                placeofBirthError: "",
                oldAccountNoError: "",
                genderError: ""
            },
            source: null,
            regionList: [],
            countryList: [],
            customerTypes: [],
            commissionList: []
        };
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeMobileNo = this.onChangeMobileNo.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeCountryId = this.onChangeCountryId.bind(this);
        this.onChangeRegionId = this.onChangeRegionId.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeStreet = this.onChangeStreet.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);
        this.onChangeCustomerType = this.onChangeCustomerType.bind(this);
        this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
        this.onChangePlaceofBirth = this.onChangePlaceofBirth.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeLatitude = this.onChangeLatitude.bind(this)
        this.onChangeLongitude = this.onChangeLongitude.bind(this)
        // this.editCustomer = this.editCustomer.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.onDropIdentity = this.onDropIdentity.bind(this);
    }

    onDrop(files) {

        if (files.length > 0) {
            this.setState({ selectedFiles: files });
            this.setState({ currentFile: this.state.selectedFiles[0] });
        }
    }

    onDropIdentity(files) {

        if (files.length > 0) {
            this.setState({ selectedFilesIdentity: files });
            this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[0] });
        }
    }


    onChangeFirstName(e) {
        const { formErrors } = this.state
        const firstName = e.target.value;

        formErrors.firstNameError = firstName != undefined && nameRegEx.test(firstName) ? "" : " First name sholud only contain alphabets"


        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    firstName: firstName
                }
            };
        });
    }
    onChangeMiddleName(e) {
        const middleName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    middleName: middleName
                }
            };
        });
    }

    onChangeBirthDate(e) {
        const { formErrors } = this.state
        const birthDate = e.target.value;
        formErrors.birthDayError = birthDate != undefined && birthDate != null && birthDate != "" ? "" : " Insert date of birth"
        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    birthDate: birthDate
                }
            };
        });
    }

    onChangePlaceofBirth(e) {
        const { formErrors } = this.state
        const placeofBirthValue = e.target.value;

        formErrors.placeofBirthError = placeofBirthValue != undefined && numberRegEx.test(placeofBirthValue) ? "" : " place of birth sholud contain alphanumeric with amin 3 and max 50"

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    placeofBirthValue: placeofBirthValue
                }
            };
        });
    }

    onChangeGender(e) {
        const { formErrors } = this.state
        const gender = e.target.value;

        if (gender == "----please select---")
            gender = ""

        formErrors.genderError = gender == "" ? "Gender value is Required" : "";

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    gender: gender
                },
                formErrors
            };
        });
    }

    onChangeLastName(e) {
        const { formErrors } = this.state

        const lastName = e.target.value;
        formErrors.lastNameError = lastName != undefined && nameRegEx.test(lastName) ? "" : " Last name sholud only contain alphabets"

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    lastName: lastName
                }
            };
        });
    }
    onChangeMobileNo(e) {
        const mobileNo = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    mobileNo: mobileNo
                }
            };
        });
    }

    onChangeCustomerType(e) {
        const { formErrors } = this.state

        const customerType = e.target.value;
        if (customerType == "----please select---")
        customerType = ""
        formErrors.customerTypeError = customerType != "" ? "" : " select customer type"

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    customerType: customerType
                }
            };
        });
    }

    onChangeCompanyName(e) {
        const companyName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    companyName: companyName
                }
            };
        });
    }

    onChangeCountryId(e) {
        const { formErrors } = this.state


        const countryId = e.target.value;
        if (countryId == "----please select---")
            countryId = ""

        formErrors.countryIdError = countryId != "" ? "" : " select country information"
        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    countryId: countryId
                }
            };
        });
    }
    onChangeRegionId(e) {
        const { formErrors } = this.state
        const regionId = e.target.value;

        if (regionId == "----please select---")
            regionId = ""

        formErrors.regionIdError = regionId != "" ? "" : " please select Province"
        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    regionId: regionId
                }
            };
        });
    }
    onChangeCity(e) {
        const { formErrors } = this.state
        const city = e.target.value;

        formErrors.cityError = city != undefined && nameRegEx.test(city) ? "" : " city sholud contain alphanumeric with amin 3 and max 50"



        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    city: city
                }
            };
        });
    }

    onChangeEmail(e) {
        const { formErrors } = this.state
        const email = e.target.value;

        formErrors.emailError = email != undefined && emailRegExp.test(email) ? "" : " Wrong email format"

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    email: email
                }
            };
        });
    }
    onChangeStreet(e) {
        const street = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    street: street
                }
            };
        });
    }
    onChangeWebsite(e) {
        const website = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    website: website
                }
            };
        });
    }

    onChangeLogo(e) {
        const logo = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    logo: logo
                }
            };
        });
    }

    onChangeLatitude(e) {
        const { formErrors } = this.state
        const latitude = e.target.value;

        formErrors.latitudeError = latitude != undefined && amountRegEx.test(latitude) ? "" : " latitude sholud contain decimal number only"

       
        
        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    latitude: latitude
                }
            };
        });
    }

    onChangeLongitude(e) {
        const { formErrors } = this.state
        const longitude = e.target.value;

        formErrors.longitudeError = longitude != undefined && amountRegEx.test(longitude) ? "" : " longitude sholud contain decimal number only"

        

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    longitude: longitude
                }
            };
        });
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountCode, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            amCodeList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
          }
    }
    onSearchChange = (selectOption) => {

        this.setState({
            selectOption: selectOption,

        });

    }
    componentDidMount() {

        CustomersService.findCustomerByCustomerId
            (this.state.id, localStorage.userId)
            .then(response => {
                console.log(response.data.birthDate)
                this.setState({
                    customerData: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {

            });

        {/*To get list of regions/provinces*/ }
        RegionService.getAllRegions(localStorage.userId)
            .then(response => {
                this.setState({
                    regionList: response.data
                });
                //  
            })
            .catch(e => {

            });
        {/*To get list of countries*/ }
        CountryService.getCountries(localStorage.userId)
            .then(response => {
                this.setState({
                    countryList: response.data
                });
                // 
            })
            .catch(e => {

            });
        {/*To get Customer types*/ }
        CustomerTypeService.getCustomerTypes(localStorage.userId)
            .then(response => {
                this.setState({
                    customerTypes: response.data
                });
                //   
            })
            .catch(e => {

            });

        this.setState({
            isCompany: localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
        });

    }


    handleSubmit = e => {
        e.preventDefault();
        const { formErrors } = this.state

        formErrors.firstNameError = this.state.customerData.firstName === "" || this.state.customerData.firstName === null || this.state.customerData.firstName === undefined ? "First name is Required" : this.state.formErrors.firstNameError;
        // formErrors.middleNameError = this.state.middleName === "" || this.state.middleName === null || this.state.middleName === undefined ? "Middle name is Required" : "";
        formErrors.lastNameError = this.state.customerData.lastName === "" || this.state.customerData.lastName === null || this.state.customerData.lastName === undefined ? "Last name is Required" : this.state.formErrors.lastNameError;
        formErrors.mobileNoError = this.state.customerData.mobileNo === "" || this.state.customerData.mobileNo === null || this.state.customerData.mobileNo === undefined ? "Mobile number is Required" : this.state.formErrors.mobileNoError ;
        formErrors.regionIdError = this.state.customerData.regionId === "" || this.state.customerData.regionId === null || this.state.customerData.regionId === undefined ? "Province is Required" : this.state.formErrors.regionIdError;
        formErrors.countryIdError = this.state.customerData.countryId === "" || this.state.customerData.countryId === null || this.state.customerData.countryId === undefined ? "Country is Required" : this.state.formErrors.countryIdError;
        formErrors.customerTypeError = this.state.customerData.customerType === "" || this.state.customerData.customerType === null || this.state.customerData.customerType === undefined ? "Customer type is Required" : this.state.formErrors.customerTypeError;
        formErrors.cityError = this.state.customerData.city === "" || this.state.customerData.city === null || this.state.customerData.city === undefined ? "City is Required" : this.state.formErrors.cityError;
        formErrors.placeofBirthError = this.state.customerData.placeofBirthValue === "" || this.state.customerData.placeofBirthValue === null || this.state.customerData.placeofBirthValue === undefined ? "Place of Birth value is Required" : this.state.formErrors.placeofBirthError;
        formErrors.birthDayError = this.state.customerData.birthDate === "" || this.state.customerData.birthDate === null || this.state.customerData.birthDate === undefined ? "Birthday value is Required" : this.state.formErrors.birthDayError;
        formErrors.genderError = this.state.customerData.gender === "" || this.state.customerData.gender === null || this.state.customerData.gender === undefined ? "Gender value is Required" : this.state.formErrors.genderError;
        formErrors.latitudeError = this.state.customerData.latitude === "" || this.state.customerData.latitude === null || this.state.customerData.latitude === undefined ? "Latitude value is Required" : this.state.formErrors.latitudeError;
        formErrors.longitudeError = this.state.customerData.longitude === "" || this.state.customerData.longitude === null || this.state.customerData.longitude === undefined ? "Longitude value is Required" : this.state.formErrors.longitudeError;


        if (formValid(this.state.formErrors)) {

            var data = new FormData();
            data.append("firstName", this.state.customerData.firstName);
            data.append("middleName", this.state.customerData.middleName);
            data.append("lastName", this.state.customerData.lastName);
            data.append("mobileNo", this.state.customerData.mobileNo);
            data.append("regionId", this.state.customerData.regionId);
            data.append("countryId", this.state.customerData.countryId);
            data.append("type", this.state.customerData.customerType);
            data.append("city", this.state.customerData.city);
            data.append("email", this.state.customerData.email);
            data.append("street", this.state.customerData.street);
            data.append("birthDate", this.state.customerData.birthDate!=null ? moment(this.state.customerData.birthDate).format('yyyy-MM-DD'):null);
            data.append("placeofBirthValue", this.state.customerData.placeofBirthValue);
            data.append("gender", this.state.customerData.gender);
            data.append("website", this.state.customerData.website);
            data.append("companyName", this.state.customerData.companyName);
            data.append("attachment", this.state.currentFile!=undefined ? this.state.currentFile : null);
            data.append("identityCard", this.state.currentFileIdentity!=undefined ? this.state.currentFileIdentity : null);
            data.append('isTopup', this.state.customerData.isTopup ? 1 : 0);
            data.append("userId", localStorage.userId);
            data.append("latitude", this.state.customerData.latitude);
            data.append("longitude", this.state.customerData.longitude);
 
            this.setState({ isLoadingPop: true})
            CustomersService.updateCustmer(data)
                .then(response => {
                    NotificationManager.success('Updated Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({ isLoadingPop: false})
                    this.props.history.push('/customerList');
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
                   // this.setState({ isLoadingPop: false})
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }


    onChangeValue() {
        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    isTopup: !this.state.customerData.isTopup
                },

            };
        });
    }

    actionGender(e) {
        const { formErrors } = this.state
        let selectedGender = e.target.value
        this.state.gender = null
        if (selectedGender == "----please select---")
            selectedGender = ""

        formErrors.genderError = selectedGender == "" ? "Gender value is Required" : "";

        // this.setState({
        //     : ,
        //     formErrors
        // });

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    gender: selectedGender
                },
                formErrors
            };
        });
    }

    Cancel = (e) => {
        this.props.history.push('/customerList');
    }

    render() {

        const { customerData, selectedFiles, isLoading, selectedFilesIdentity, isCompany, accountCode, customerTypes, selectOption } = this.state;
        const { regionList } = this.state;
        const { countryList, formErrors } = this.state;

        if (customerData.ledgerAccountId !== undefined) {
            this.state.selectOption = {
                label: customerData && customerData.ledgerAccountId.accountCode,
                value: customerData && customerData.ledgerAccountId.id
            }
        }


        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={150} width={150} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <Menu /> */}
                    <NotificationContainer />
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        {/*
                                    <div class="modal-header">
                                        <h4 class="modal-title" >
                                        Agent Registration
                                        </h4>
                                    </div> */}
                                        <form onSubmit={this.handleSubmit}>
                                            {/*Basic Information*/}
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='firstName'>First Name:</label>
                                                            <input type="text" id="firstName" className="form-control " placeholder="First Name"
                                                                value={customerData.firstName} onChange={this.onChangeFirstName} required="required" />
                                                            {formErrors.firstNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.firstNameError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='middleName'>Middle Name:</label>
                                                            <input type="text" id="middleName" className="form-control " placeholder="Middle Name"
                                                                value={customerData.middleName} onChange={this.onChangeMiddleName} />
                                                            {formErrors.middleNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.middleNameError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='LastName'>Last Name:</label>
                                                            <input type="text" id="LastName" className="form-control " placeholder="Last Name"
                                                                value={customerData.lastName} onChange={this.onChangeLastName} required="required" />
                                                            {formErrors.lastNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.lastNameError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='MobileNumber'>Mobile Number:</label>

                                                            <input type="text" className="form-control " value={customerData.mobileNo} onChange={this.onChangeMobileNo} readOnly required="required" />
                                                            {formErrors.mobileNoError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.mobileNoError}</span>
                                                            )}
                                                        </div>
                                                        {isCompany ?
                                                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                                <label htmlFor='customerType'>Customer Type:</label>

                                                           <select value={customerData.customerType}
                                                                onChange={this.onChangeCustomerType} className="form-control"
                                                                required="required">
                                                                <option value=""> Customer Type</option>
                                                                {customerTypes.map(getType =>
                                                                    <option value={getType.id}>{getType.type}</option>
                                                                )}
                                                             </select>

                                                                {formErrors.customerTypeError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.customerTypeError}</span>
                                                                )}
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='companyName'>Company Name:</label>
                                                            <input type="text" className="form-control " id='companyName'
                                                                placeholder="Optional"
                                                                value={customerData.companyName}
                                                                onChange={this.onChangeCompanyName} />
                                                        </div>
                                                        <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='birthDay'> Date of Birth :</label>

                                                            <input type="date" id='birthDay' className="form-control" name='birthDay'                                                            
                                                                value={moment(customerData.birthDate).format('YYYY-MM-DD')}
                                                                onChange={this.onChangeBirthDate}
                                                                placeholder="Date of Birth" />

                                                            {formErrors.birthDayError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.birthDayError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6">
                                                            <label htmlFor='palceofBirth'>Palce of Birth:</label>
                                                            <input type="text" className="form-control " id='palceofBirth' name='palceofBirth'
                                                                value={customerData.placeofBirthValue}
                                                                placeholder="Palce of birth"
                                                                onChange={this.onChangePlaceofBirth} />

                                                            {formErrors.placeofBirthError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.placeofBirthError}</span>
                                                            )}

                                                        </div>
                                                        <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3">
                                                            <label htmlFor='gender'>Gender:</label>
                                                            <select value={customerData.gender}
                                                                onChange={this.onChangeGender} className="form-control" name='gender'>
                                                                <option key={null} value={""}>----please select---</option>
                                                                <option key={"Male"} value={"Male"}>Male</option>
                                                                <option key={"Female"} value={"Female"}>Female</option>
                                                                <option key={"Other"} value={"Other"}>Other</option>
                                                            </select>
                                                            {formErrors.genderError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.genderError}</span>
                                                            )}
                                                        </div>

                                                        {  /* 
                            <div className=" card-header form-group col-12 col-sm-12 col-md-6 col-lg-6"> */}

                                                        {isCompany ?
                                                            <div className="form-group col-8 col-sm-6 col-md-6 col-lg-6">
                                                                <label htmlFor='status'><span style={{ color: 'blue' }}>Is eTop up customer?: </span></label>
                                                                <input className="form-control"
                                                                    checked={customerData.isTopup}
                                                                    onChange={e => { this.onChangeValue() }} type="checkbox" />

                                                            </div>

                                                            :
                                                            ''
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                            {/*Basic Information*/}
                                            {/*Address Information*/}
                                            <div className="card my-4 border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Address Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Country:</label>
                                                            <select value={customerData.countryId} onChange={this.onChangeCountryId} className="form-control" required="required">
                                                                <option value=""> Choose Country</option>
                                                                {countryList.map(country =>
                                                                    <option value={country.id}>{country.countryName}</option>
                                                                )}
                                                            </select>
                                                            {formErrors.countryIdError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.countryIdError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Province'>Province:</label>
                                                            <select value={customerData.regionId}
                                                                onChange={this.onChangeRegionId} className="form-control"
                                                                required="required">
                                                                <option value=""> Choose province</option>
                                                                {regionList.map(getregion =>
                                                                    <option value={getregion.id}>{getregion.region}</option>
                                                                )}
                                                            </select>
                                                            {formErrors.regionIdError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.regionIdError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='city'>City:</label>
                                                            <input type="text" className="form-control " id='city'
                                                                value={customerData.city} onChange={this.onChangeCity} />
                                                            {formErrors.cityError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.cityError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='email'>Email:</label>
                                                            <input type="text" className="form-control " id='email'
                                                                value={customerData.email} onChange={this.onChangeEmail} />
                                                            {formErrors.emailError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.emailError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Street'>Street:</label>
                                                            <input type="text" className="form-control " id='street'
                                                                placeholder="Optional"
                                                                value={customerData.street} onChange={this.onChangeStreet} />
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='website'>Website:</label>
                                                            <input type="text" className="form-control " id='website'
                                                                placeholder="Optional"
                                                                value={customerData.website} onChange={this.onChangeWebsite} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/*Address Information*/ }
                                             {/*Location */}
                                             {isCompany ?
                                                <div className="card my-4 border-radius-0" >
                                                    <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Location </h6>
                                                    </div>
                                                    <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='latitude'>Latitude:</label>
                                                        <input type="text" className="form-control" 
                                                        value={customerData.latitude}
                                                        onChange={this.onChangeLatitude}  /> 
                                                           {formErrors.latitudeError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.latitudeError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='longitude'>Longitude:</label>
                                                        <input type="text" className="form-control" 
                                                        value={customerData.longitude}
                                                        onChange={this.onChangeLongitude} /> 
                                                           {formErrors.longitudeError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.longitudeError}</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                             }
                                                {/* Location*/}
                                            {/*File attachment*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='profilePic'>Profile Picture:</label>
                                                            <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' }} src={"data:image/png;base64," + customerData.attachment}></img>
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='profilePic'>Identity Card:</label>
                                                            <img id="logo" className="mx-auto d-block " style={{ width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' }} src={"data:image/png;base64," + customerData.identityCard}></img>
                                                        </div>


                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <Dropzone accept={".JPEG,.jpeg,.JPG,.jpg,.PNG,.png,.GIF,.gif"}
                                                            onDrop={this.onDrop}
                                                                multiple={false}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" })} style={{ border: "thin dashed black" }}>
                                                                            <input {...getInputProps()} />
                                                                            {selectedFiles && selectedFiles[0].name ? (
                                                                                <div className="selected-file border-radius-1">
                                                                                    {selectedFiles && selectedFiles[0].name}
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop file here, or click to select file"
                                                                            )}
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">
                                                                        </aside>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <label style={{ fontSize: 'smaller' }} >N.B upload  image file only</label>
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            {/* <label htmlFor='Province'>Indentity Card:</label> */}
                                                            {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleIdentityChange} /> */}
                                                            <Dropzone accept={".JPEG,.jpeg,.JPG,.jpg,.PNG,.png,.GIF,.gif"}
                                                                onDrop={this.onDropIdentity}
                                                                multiple={false}>

                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" })} style={{ border: "thin dashed black" }}>
                                                                            <input {...getInputProps()} />
                                                                            {selectedFilesIdentity && selectedFilesIdentity[0].name ? (
                                                                                <div className="selected-file">
                                                                                    {selectedFilesIdentity && selectedFilesIdentity[0].name}
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop file here, or click to select file"
                                                                            )}
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">

                                                                        </aside>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            <label style={{ fontSize: 'smaller' }} >N.B upload  image file only</label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*File attachment */}
                                            <div class="row  justify-content: space-between;">

                                                <button disabled={this.state.isLoadingPop} type="submit" title={"Save and activate"} className="btn btn-sm btn-success  px-3 py-2" style={{ marginLeft: "30px" }}>
                                                    <i style={{ marginRight: "5 px" }} /> Update </button>
                                                <button type="submit" className="btn btn-warning  px-3 py-2" onClick={() => this.Cancel()} style={{ marginLeft: "10px" }}>Cancel</button>

                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>

                                {this.state.isLoadingPop && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div>

            )
        }
    }
}
export default EditCustomer;