import API from '../../http-common';

class CashInfoService {
  // get all Cash Information 
  getCashIns() {
    return API.get("/api/v1/getCashIns");
  }

  // get all Cash Information 
  searchCashCashIns(cashTypeId, pageNo, pageSize) {
    return API.get("/api/v1/searchCashCashIns"+"/"+ cashTypeId +"/"+pageNo+"/"+pageSize);
  }
 
   // get all Cash Information based on Cash Type 
   getCashInByCashTypeId(cashTypeId) {
    return API.get("/api/v1/getCashInByCashTypeId" + '/' + cashTypeId);
  }
  
   // create  Cash Information 
  createCashIn(cashInfo) {
    return API.post("/api/v1/createCashIn", cashInfo);
  }

  // create  Cash Information with attached file
  createCashInWithFile(cashInfo) {
    return API.post("/api/v1/createCashInWithFile", cashInfo);
  }

   // get single Cash Information 
  getCashIn(id, userId) {
    return API.get("/api/v1/getCashIn" + '/' + id + "/" + userId);
  }

  // update  Cash Information 
  updateCashIn(id, cashInfo) {
    return API.put("/api/v1/updateCashIn" +"/" + id, cashInfo);
  }

  // update  Cash Information with attached file
  updateCashInWithFile(cashInfo) {
    return API.post("/api/v1/updateCashInWithFile", cashInfo);
  }

   // remove  Cash Information 
  deleteCashIn(id, userId) {
    return API.delete('/api/v1/deleteCashIn' + '/' + id + "/" + userId);
  }

  // download  Cash Information 
  async downloadCashInfo(id, contentType) {
    const response = await API.get('/api/v1/downloadCashInfoFile/' + id,
    {
      responseType: "blob"
    }
  );
  const file = new Blob(
    [response.data], 
    { type: contentType});
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  window.open(fileURL);
  }  

  // get list of Cash Information 
  getFilteredCashIns(cashTypeId, pageNo, pageSize, CashInfo) 
  {
  return API.put("/api/v1/getFilteredCashIns" + "/" + cashTypeId + "/" + pageNo + "/" + pageSize, CashInfo);
  }

  // Verify Cash Information 
  processCashIn(id, cashInfo) {
    return API.put("/api/v1/processCashIn" +"/" + id, cashInfo);
  }

  // Approve Cash Information 
  approveCashIn(id, cashInfo) {
    return API.put("/api/v1/approveCashIn" +"/" + id, cashInfo);
  }


   // get all Cash Information 
   getCashOuts() {
    return API.get("/api/v1/getCashOuts");
  }

  // get all Cash Information 
  searchCashCashOuts(cashTypeId, pageNo, pageSize) {
    return API.get("/api/v1/searchCashCashOuts"+"/"+ cashTypeId +"/"+pageNo+"/"+pageSize);
  }
 
   // get all Cash Information based on Cash Type 
   getCashOutByCashTypeId(cashTypeId) {
    return API.get("/api/v1/getCashOutByCashTypeId" + '/' + cashTypeId);
  }
  
   // create  Cash Information 
  createCashOut(cashInfo) {
    return API.post("/api/v1/createCashOut", cashInfo);
  }

  // create  Cash Information with attached file
  createCashOutWithFile(cashInfo) {
    return API.post("/api/v1/createCashOutWithFile", cashInfo);
  }

   // get single Cash Information 
  getCashOut(id, userId) {
    return API.get("/api/v1/getCashOut" + '/' + id + "/" + userId);
  }

  // update  Cash Information 
  updateCashInfo(id, cashInfo) {
    return API.put("/api/v1/updateCashOut" +"/" + id, cashInfo);
  }

  // update  Cash Information with attached file
  updateCashOutWithFile(cashInfo) {
    return API.post("/api/v1/updateCashOutWithFile", cashInfo);
  }

   // remove  Cash Information 
  deleteCashOut(id, userId) {
    return API.delete('/api/v1/deleteCashOut' + '/' + id + "/" + userId);
  }

  // get list of Cash Information 
  getFilteredCashOuts(cashTypeId, pageNo, pageSize, CashInfo) 
  {
  return API.put("/api/v1/getFilteredCashOuts" + "/" + cashTypeId + "/" + pageNo + "/" + pageSize, CashInfo);
  }

  // Verify Cash Information 
  processCashOut(id, cashInfo) {
    return API.put("/api/v1/processCashOut" +"/" + id, cashInfo);
  }

  // Approve Cash Information 
  approveCashOut(id, cashInfo) {
    return API.put("/api/v1/approveCashOut" +"/" + id, cashInfo);
  }
    // Get daily cash info 
    findDailyCashInfo(currentDate, userId) {
      return API.get("/api/v1/findDailyCashInfo/"+ currentDate+"/"+userId);
    }
}
export default new CashInfoService()