import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LedgerAccountService from '../../../services/etopup/LedgerAccountService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../../common/Header';
import LoadingPopup from '../../wallet/LoadingPopup';
import ReactLoading from "react-loading";
//import TopupMenu from '../../etopup/TopupMenu';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z0-9_\- ]{3,50}$/u)
const accountCodeRegEx = RegExp(/^[a-zA-Z0-9_-]{3,50}$/u)
const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)

class AddLedgerAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: this.props.location.state && this.props.location.state.legerAccountId,
      name: '',
      description: '',
      accountCode: '',
      isLoading: false,
      parentAccountCode: '',
      inputLebel: 'main account ledger',
      formErrors: {
        nameError: "",
        accountCodeError: "",
        descriptionError: "",
        customerError: ""
      },
    }

  }
  componentDidMount() {
    if(this.state.parentId != null){
    LedgerAccountService.getLedgerAccount(this.state.parentId, localStorage.userId)
      .then(response => {
        this.setState({
          parentAccountCode: response.data.accountCode
        });
      })
      .catch(e => {
      });
    }
  }
  

  handleSubmit = e => {
    e.preventDefault();
    const { name, accountCode, description, parentId, formErrors } = this.state
    if (parentId === undefined || parentId === null) {
      formErrors.accountCodeError = accountCode == '' || accountCode == null ? "transaction code is Required" : this.state.formErrors.accountCodeError;
    }
    formErrors.nameError = name == '' || name == null ? "Name is Required" : this.state.formErrors.nameError;

    if (formValid(this.state.formErrors)) {
      var loadingValue = true;
      let amCodeData = {
        parentId: this.state.parentId,
        accountCode: parentId ? this.state.parentAccountCode.substring(0,2) + accountCode : accountCode,
        name: name,
        description: description,
        userId: localStorage.userId
      }

      LedgerAccountService.createLedgerAccount(amCodeData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            e.target.reset();
            this.setState({ isLoading: false  })
          },
          (error) => {
            this.setState({ isLoading: false  })
            NotificationManager.error(error.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          loadingValue = false
        });

        this.setState({
          isLoading: loadingValue
        })

    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }

    
  };

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'accountCode':
        formErrors.accountCodeError =
          value.length == 0 || !accountCodeRegEx.test(value) ? name + " value sholud only alphanumeric with min 3 and max 50" : ""
        break;
      case 'name':
        formErrors.nameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphabetic and (-_) characters with min 3 and max 50" : ""
        break;
      case 'description':
        formErrors.descriptionError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [name]: value
    })
  }

  cancel() {
    if (this.state.parentId != null || this.state.parentId != undefined) {
      this.props.history.push({pathname: "/accountList", state: {legerAccountId: this.state.parentId}})
    } else {
      this.props.history.push(`/accountList`)
    }
  }


  render() {
    const { formErrors, parentId, parentAccountCode } = this.state

    return (

      <div >
        {/* <Header />
        <Menu /> */}
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add  Chart of account management Information</li>

                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Chart of account management Information
                      </h4>
                    </div>

                    <form onSubmit={this.handleSubmit} noValidate>
                      {/*<h2>Register</h2> */}

                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">

                        {parentId === undefined || parentId === null ?
                          <>
                            <label htmlFor='accountCode'>Ledger Account:</label>
                            <input type="text" className="form-control" id='accountCode'
                              name="accountCode"
                              onChange={this.handelChange} onBlur={this.handelChange}
                              placeholder="Account Code" />

                            {formErrors.accountCodeError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.accountCodeError}</span>
                            )}

                          </>
                          :
                          <>
                        <label htmlFor='parentAccount'>Parent Account:</label>
                        <input disabled={parentId === null ||  parentId === undefined ? false : true} type="text" className="form-control" id='accountCode'
                          value={ parentAccountCode.substring(0,2) } name="parentAccount" />
                      
                      <label htmlFor='accountCode'>Ledger Account:</label>
                            <input type="text" className="form-control" id='accountCode'
                              name="accountCode"
                              onChange={this.handelChange} onBlur={this.handelChange}
                              placeholder="Account Code" />

                            {formErrors.accountCodeError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.accountCodeError}</span>
                            )}


                          </>
                        }

                      </div>

                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Name'>Name:</label>
                        <input type="text" className="form-control" id='Name'
                          onChange={this.handelChange} onBlur={this.handelChange} name='name'
                          placeholder="Name" />

                        {formErrors.nameError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.nameError}</span>
                        )}
                      </div>



                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Description'>Description:</label>
                        <textarea type="" className="form-control" id='Description'
                          onChange={this.handelChange} onBlur={this.handelChange} name='description'
                          placeholder="Description" />
                        {formErrors.descriptionError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.descriptionError}</span>
                        )}
                      </div>



                      <div className="row  d-flex justify-content-left ">

                        <button type="submit" disabled={this.state.isLoading} className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={this.cancel.bind(this)}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              <NotificationContainer />
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}



export default AddLedgerAccount;