import { faExchange, faKey, faSignIn, faUser, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoginService from '../../../../services/common/LoginService';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}


const passwordRegEx = RegExp(/^[\p{L} \p{N}_!@#$%&]{8,20}$/u)


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            oldPassword: '',
            confirmPassword: '',
            companyProfile:[],
            formErrors: {
                newPasswordError: "",
                oldPasswordError: "",
                confirmPasswordError: "",
                passwordError: ""
            },
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        const { oldPassword, newPassword, confirmPassword, formErrors } = this.state
        formErrors.passwordError = ''
        formErrors.newPasswordError = newPassword == '' || newPassword == null ? "New Password is Required" : this.state.formErrors.newPasswordError;
        formErrors.oldPasswordError = oldPassword == '' || oldPassword == null ? "Old Password is Required" : this.state.formErrors.oldPasswordError;
        formErrors.confirmPasswordError = confirmPassword == '' || confirmPassword == null ? "Confirm Password is Required" : this.state.formErrors.confirmPasswordError;

        if (formValid(this.state.formErrors)) {
            if (newPassword == confirmPassword) {
                let changeInfo = new FormData();
                changeInfo.append("userId", localStorage.userId)
                changeInfo.append("newPassword", newPassword)
                changeInfo.append("oldPassword", oldPassword)
                LoginService.ChangePassword(localStorage.userId, newPassword, oldPassword)
                    .then(
                        (res) => {
                            if (localStorage.isProfileUpdated === 'true') {
                                localStorage.removeItem('jwtToken')
                                localStorage.removeItem('userId')
                                localStorage.removeItem('userIdentity')
                                localStorage.removeItem('customerIdentity')
                                localStorage.removeItem('userName')
                                localStorage.removeItem('status')   
                                localStorage.removeItem('isProfileUpdated')
                                localStorage.clear();
                                this.props.history.push('/login');
                            } else {
                                NotificationManager.info("please updated your profile", `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000)
                                this.props.history.push('/updateProfile/' + localStorage.customerIdentity)
                            }
                        },
                        (err) => {
                            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        })
                    .catch(function (response) {
                        NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`,3000);
                    });
            } else {
                formErrors.passwordError = "password mismatch error"
                this.setState({
                    formErrors
                })
            }
        }
        this.setState({
            formErrors
        })
    };

    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'oldPassword':
                formErrors.oldPasswordError =
                    value.length == 0 || !passwordRegEx.test(value) ? name + " Should be a valid password" : ""
                break;
            case 'newPassword':
                formErrors.newPasswordError =
                    value.length == 0 || !passwordRegEx.test(value) ? name + " Should be a valid password" : ""
                break;
            case 'confirmPassword':
                formErrors.confirmPasswordError =
                    value.length == 0 || !passwordRegEx.test(value) ? name + " Should be a valid password" : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    componentDidMount() {
        const reloadCount = localStorage.getItem('reloadCount');
        if(reloadCount == "firstTime") {
          localStorage.removeItem('reloadCount');
          window.location.reload();
        }   
        LoginService.companyProfileInfo()
        .then(response => response.data)
        .then((data) => {
            this.setState({
                companyProfile: data
            });
        });
    }


    render() {
        const { formErrors, companyProfile } = this.state

        return (
            <div >
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                <div className='col-12 col-sm-8 col-md-6 col-lg-6 text-muted p-2'>
                                <div className="d-flex justify-content-center">
                                        <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="SmartPay"
                                                                style={{ width: '30%', height: '10%', alignSelf: 'center' }}

                                                            />
                                        </div>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0 bg-blue ">
                                            <h6 className='d-flex justify-content-center' >Welcome to {companyProfile.companyName} </h6>
                                           
                                    {/* <div className='col-7 bg-light p-4'>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0  bg-blue">

                                                <table style={{border: 'none'}}>
                                                    <tr style={{border: 'none'}}>
                                                    <td style={ { border: 'none', width: '30%', alignSelf: 'center' } }>
                                                        <img
                                                                src={"data:image/png;base64," +  companyProfile.attachment} 
                                                                 // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={ { width: '60%', height: '40%', alignSelf: 'center' } }

                                                            />
                                                        </td>
                                                        <td style={{border: 'none'}}>
                                                            <h6 className='justify-content-left' >Welcome To { companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table>*/}


                                            </div> 
                                     
                                            <div className="card-body d-flex justify-content-center px-4 py-2 ">
                                                <form className='' onSubmit={this.handleSubmit} noValidate>
                                                    <div>
                                                        {formErrors.passwordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.passwordError}</span>
                                                        )}
                                                    </div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <div className="input-group justify-content-left">
                                                        <div className="input-group-text  bg-transparent p-0">
                                                            <FontAwesomeIcon icon={faKey} />
                                                        </div>
                                                        <div className="input-group-append p-0">
                                                            <input type="password" className="form-control" id='oldPassword'
                                                                onChange={this.handelChange} onBlur={this.handelChange} name='oldPassword'
                                                                placeholder="Old Password" />


                                                        </div>
                                                        {formErrors.oldPasswordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.oldPasswordError}</span>
                                                        )}
                                                    </div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <div className="input-group justify-content-left">
                                                        <div className="input-group-text  bg-transparent p-0">
                                                            <FontAwesomeIcon icon={faKey} />
                                                        </div>
                                                        <div className="input-group-append p-0">
                                                            <input type="password" className="form-control" id='newPassword'
                                                                onChange={this.handelChange} onBlur={this.handelChange} name='newPassword'
                                                                placeholder="New Password" />


                                                        </div>
                                                        {formErrors.newPasswordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.newPasswordError}</span>
                                                        )}
                                                    </div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <div className="input-group justify-content-left">
                                                        <div className="input-group-text  bg-transparent p-0">
                                                            <FontAwesomeIcon icon={faKey} />
                                                        </div>
                                                        <div className="input-group-append p-0">
                                                            <input type="password" className="form-control" id='confirmPassword'
                                                                onChange={this.handelChange} onBlur={this.handelChange} name='confirmPassword'
                                                                placeholder="Confirm Password" />


                                                        </div>
                                                        {formErrors.confirmPasswordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.confirmPasswordError}</span>
                                                        )}
                                                    </div>

                                                    <div className="clearfix">&nbsp;</div>

                                                    <div className="row  justify-content: space-between; ">
                                                        <button type="submit" title={"Change Password"} className="btn btn-sm btn-success  "
                                                            style={{ marginLeft: "30px" }}>
                                                            Change Password
                                                            <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faExchange} />
                                                        </button>

                                                    </div>



                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NotificationContainer />

                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default ChangePassword;