import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import CustomerService from '../../../services/etopup/CustomerService';
import CountryService from '../../../services/common/CountryService';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import Header from '../Header';
import ReactLoading from "react-loading";



class CountryList extends Component {

    constructor(props) {

        super(props)
        this.state = {
            isLoading: false,
            CountryList: [],
            counter:1
        }

    }

    componentDidMount() {
        CountryService.getCountries(localStorage.userId)
            .then(response => {
                this.setState({
                    isLoading: true,
                    CountryList: response.data
                });
            })
            .catch(e => {
                this.setState({ isLoading: true });
                
            });
    }

    async deleteCountry(mobileNo) {
        try {
            const response = await CountryService.deleteCountry(mobileNo, localStorage.userId);
            NotificationManager.success("Deleted successfully", `${process.env.REACT_APP_MSG_TITLE}`);
        } catch (error) {
            NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
        }
    }

    render() {
        const { CountryList, isLoading } = this.state;
        this.state.counter = 1;
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <NotificationContainer/>
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Country List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Countries List</div>


                                        <Link to={{ pathname: '/addCountry' }}>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={this.addCustomer}>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }} >
                                            <thead className='thead-light'>
                                                <tr style={{ marginTop: 0 }}>
                                                   <th> No.</th>
                                                    <th> Code</th>
                                                    <th> Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {CountryList.map(country =>

                                                    <tr>
                                                         <td className='p-1'>{this.state.counter++}</td>
                                                        <td className='p-1 '>{country.countryCode}</td>
                                                        <td className='p-1 '>{country.countryName}</td>

                                                        <td className='p-1'>
                                                            {/* <Link to={{ pathname: '/countryDetail', state: { countryCode: country.countryCode } }}>
                                                                <button className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                            </Link> */}
                                                            {/* <Link to={ { pathname: '/editMerchant', state: { mobileNo: country.mobileNo } } }>
                                                        <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                    </Link> */}
                                                            {/* <button style={{ marginLeft: "2px" }} onClick={() => this.deleteMerchant(country.mobileNo)} className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */}
                                                        </td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div>

            )
        }
    }
}
export default CountryList