import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';

import CashInfoService from '../../../services/etopup/CashInfoService';
import CashMethodService from '../../../services/etopup/CashMethodService';
import CustomerListService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BankInfo from '../../wallet/BankInfo';
import BankInfoService from '../../../services/common/BankInfoService';
import { LOCALES } from '../../common/Constant'
import AsyncSelect from 'react-select/async'
import Header from '../../common/Header';
import API from '../../../http-common';
import LoadingPopup from '../../wallet/LoadingPopup';
import ReactLoading from "react-loading"; 

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const accountNoRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,20}$/u)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);

class AddCashIn extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      cashMethod: [],
      senderBankInfo: [],
      receiverBankInfo: [],
      balanceInfo: undefined,
      customerBank: undefined,
      bankInfoId: undefined,
      isLoading: false,
      accountNo: '',
      senderMobileNo: '',
      receiverMobileNo: '',
      amount: '',
      description: '',
      personalName: '',
      personalBankName: '',
      personalBankAccount: '',
      senderBankName: '',
      senderBankAccount: '',
      receiverBankAccount: '',
      customerId: null,
      cashMethodId: '',
      cashTypeId: LOCALES.CASH_IN_TYPE,
      senderBankId: null,
      receiverBankId: null,
      testBool: false,
      isExpand: true,
      receiverBankName: `${process.env.REACT_APP_RECEIVER_BANK_NAME}`,
      receiverDBankId: `${process.env.REACT_APP_RECEIVER_BANK_ID}`,
      receiverBankAccount: `${process.env.REACT_APP_RECEIVER_BANK_ACCOUNT}`,
      searchValue: null,
      // Initially, no file is selected
      attachment: null,
      formErrors: {
        cashMethodError: "",
        customerNotFoundError: "",
        amountError: "",
        descriptionError: "",
        senderBankError: "",
        senderAccountNoError: "",
        receiverBankError: "",
        receiverAccountNoError: "",
        attachmentError: ""
      },

    
    }
  }



  componentDidMount() {
    CashMethodService.getCashMethods()
      .then(response => {
        this.setState({
          cashMethod: response.data
        });
        
      })
      .catch(e => {
        
      });

    BankInfoService.getBankInfos()
      .then(response => {
        this.setState({
          senderBankInfo: response.data,
          receiverBankInfo: response.data
        });
        
      })
      .catch(e => {
        
      });
  }

  // On file select (from the pop up)
  onFileChange = event => {
    // Update the state
    const { formErrors } = this.state
    formErrors.attachmentError = event.target == null || event.target.files[ 0 ] == null || event.target.files[ 0 ] == '' ? 'Attach payment slip' : "";
    this.setState({
      attachment: event.target.files[ 0 ],
      formErrors
    });
  };



  getCustomerBankInfo(mobileNo) {

    CustomerListService.getCustomerBankInfoByMobile(mobileNo, LOCALES.DEFAULT_CUSTOMER, localStorage.userId)   // 1 indicaties defult bank information
      .then(response => {

        this.setState({
          customerBank: response.data,
          customerId: response.data.customerId,
          bankInfoId: response.data.bankInfoId
        });
        debugger
        
      })
      .catch(e => {
        
      });

  }


  handleSubmit = e => {
    e.preventDefault();
    const { searchValue, attachment, senderBankId, receiverBankId, amount, cashMethodId, senderBankAccount, receiverBankAccount, formErrors } = this.state
    formErrors.customerNotFoundError = searchValue == "" || searchValue == null || searchValue == undefined ? 'Receiver customer is Required' : this.state.formErrors.customerNotFoundError
    formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
    formErrors.cashMethodError = cashMethodId == '' || cashMethodId == null || cashMethodId == undefined ? 'You must make a selection' : this.state.formErrors.cashMethodError;
    
    if (cashMethodId == LOCALES.DIPOSIT_METHOD ||
      cashMethodId == LOCALES.BANK_TRASFER_METHOD) {      
      formErrors.senderBankError = senderBankId == '' || senderBankId == null || senderBankId == undefined ? 'You must make a selection' : this.state.formErrors.senderBankError;
      formErrors.receiverBankError = receiverBankId == '' || receiverBankId == null || receiverBankId == undefined ? 'You must make a selection' : this.state.formErrors.receiverBankError;
      formErrors.senderAccountNoError = senderBankAccount == '' || senderBankAccount == null || senderBankAccount == undefined ? 'Sender account No is Required' : this.state.formErrors.senderAccountNoError;
      formErrors.receiverAccountNoError = receiverBankAccount == '' || receiverBankAccount == null || receiverBankAccount == undefined ? 'Receiver account No is Required' : this.state.formErrors.receiverAccountNoError;
      formErrors.attachmentError = attachment == '' || attachment == null || attachment == undefined ? 'Attach payment slip' : this.state.formErrors.attachmentError;
    }else{
      formErrors.senderBankError = ''
    formErrors.receiverBankError = ''
    formErrors.senderAccountNoError = ''
    formErrors.receiverAccountNoError = ''
    formErrors.attachmentError = ''
    }

    if (formValid(this.state.formErrors)) {
      // Create an object formData
      const formData = new FormData();
      formData.append("amount", this.state.amount);
      formData.append("attachment", this.state.attachment)
      formData.append("cashMethodId", this.state.cashMethodId)
      formData.append("cashTypeId", this.state.cashTypeId)
      formData.append("senderCustomerId", localStorage.customerIdentity)
      formData.append("description", this.state.description)
      formData.append("receiverCustomerId", searchValue.value )
      formData.append("userId", localStorage.userId)
      if (cashMethodId == LOCALES.BANK_TRASFER_METHOD) {
        formData.append("senderBankId", this.state.senderBankId)
        formData.append("senderBankAccount", this.state.senderBankAccount)
        formData.append("receiverBankId", this.state.receiverBankId)
        formData.append("receiverBankAccount", this.state.receiverBankAccount)
      }
      if (cashMethodId == LOCALES.DIPOSIT_METHOD) {
        formData.append("senderBankId", this.state.senderBankId)
        formData.append("senderBankAccount", this.state.senderBankAccount)
        formData.append("receiverBankId", `${process.env.REACT_APP_RECEIVER_BANK_ID}`)
        formData.append("receiverBankAccount", `${process.env.REACT_APP_RECEIVER_BANK_ACCOUNT}`)
      }
      this.setState({ isLoading: true})  
      CashInfoService.createCashInWithFile(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})  
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})  
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoading: false})  
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  cancel() {
    this.props.history.push('/cashInList')
  }

  OnChangeSelectedValue(e) {
    const { formErrors } = this.state
    let selectedValeChanged
    let cashMethodChanged = e.target.value
    this.state.cashMethodId = ""
    if (cashMethodChanged == "----please select---") {
      cashMethodChanged = ""
    } else {
      this.state.receiverBankId = null
      this.state.receiverBankAccount = ''
      this.state.cashMethodId = cashMethodChanged
      if (cashMethodChanged == LOCALES.DIPOSIT_METHOD) {
        this.state.isExpand = true;
        this.state.receiverBankId = LOCALES.RECEIVER_BANK_ID// this will be taken from propetiy file 
        this.state.receiverBankAccount = LOCALES.RECEVER_BANK_ACCOUNT   // this will be taken from propetiy file 
      } else {
        this.state.receiverBankAccount =''
        this.state.isExpand = false;
      }
      if (cashMethodChanged == LOCALES.BANK_TRASFER_METHOD ||
        cashMethodChanged == LOCALES.DIPOSIT_METHOD) {
        selectedValeChanged = true
      } else {
        selectedValeChanged = false
      }

      this.setState({
        testBool: selectedValeChanged
      })
    }
    formErrors.cashMethodError = cashMethodChanged == "" ? 'You must make a selection' : "";
    this.setState({
      formErrors
    });

  }

  OnChangeSenderBankInfo(e) {
    const { formErrors } = this.state
    let selectedValeChanged = e.target.value
    if (selectedValeChanged == "----please select---")
      selectedValeChanged = ""

    formErrors.senderBankError = selectedValeChanged == "" ? 'You must make a selection' : "";
    this.setState({
      senderBankId: selectedValeChanged,
      formErrors
    })

  }

  OnChangeReceiverBankInfo(e) {
    const { formErrors } = this.state
    let selectedValeChanged = e.target.value
    if (selectedValeChanged == "----please select---")
      selectedValeChanged = ""
    formErrors.receiverBankError = selectedValeChanged == "" ? 'You must make a selection' : "";
    this.setState({
      receiverBankId: selectedValeChanged,
      formErrors
    })

  }

  handelChange = e => {
    e.preventDefault();
    const { customerId } = this.state
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'amount':
        formErrors.amountError =
          value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100,000" : ""
        break;
      // case 'phoneNumber':
      //   debugger
      //   formErrors.phoneNumberError =
      //     value.length == 0 || value.length < 10 || !phoneNoRegEX.test(value) ? name + " sholud be digites with a min 1 & max 10" : ""
      //   if (formErrors.phoneNumberError.length == 0) {
      //     this.onChangeReceiverMobileNo(value)
      //     formErrors.customerNotFoundError =
      //     customerId == null || customerId == undefined ?
      //     "With this " + name +" there is no registered customer " : ""      
      //   }
      //   break;
      case 'description':
        formErrors.descriptionError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      case 'senderBankAccount':
        formErrors.senderAccountNoError =
          !accountNoRegEx.test(value) ? name + " Sholud be digites with a min 10 & max 50" : ""
        break;
      case 'receiverBankAccount':
        formErrors.receiverAccountNoError =
          !accountNoRegEx.test(value) ? name + " Sholud be digites with a min 10 & max 50" : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/"+ localStorage.userId)    
      .then((response) =>               
         response.data
      )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {              
              temp.push({ label: element.fullName, value: element.id })
              
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (searchValue) => {
    const { formErrors } = this.state

    formErrors.customerNotFoundError =
       customerRegEx.test(searchValue) ? " Please insert customer Name or account no or mobile number" : ""

  if (searchValue) {
   
      this.setState({
        searchValue,
        formErrors
      });
    }
  }

  render() {
    const { senderBankInfo, receiverBankInfo, cashMethod, isExpand, customerId, customerBank, bankInfoId, formErrors } = this.state

    let cashMethodList = cashMethod.length > 0
      && cashMethod.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>
        )
      }, this);

    let receiverBankList = receiverBankInfo.length > 0
      && receiverBankInfo.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>
        )
      }, this);


    let senderBankList = senderBankInfo.length > 0
      && senderBankInfo.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>
        )
      }, this);
  

    return (
      <div >
        {/* <Header />
        <Menu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash In Information</li>

                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Cash In Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit } noValidate>
                      {/*<h2>Register</h2> */ }
                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='cashMethodList'>Cash Method:</label>
                        <select className="form-control "
                          onChange={ e => this.OnChangeSelectedValue(e) } id='cashMethodList'>
                          <option value={ null }>----please select---</option>
                          { cashMethodList }
                        </select>
                        { formErrors.cashMethodError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.cashMethodError }</span>
                        ) }
                      </div>
                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 ">
                        <label htmlFor='CustomerList'>Customer:</label>
                        <div style={{ fontSize: 'smaller' }}>
                          
                          <AsyncSelect id='CustomerList' 
                            placeholder={"Search by name, account no or mobile number"}  

                            isClearable={true}
                            loadOptions={this.fatchData}
                            isLoading={this.state.searchValue == {} &&
                              this.state.searchValue === undefined &&
                              this.state.searchValue === null ? true : false
                            }
                         
                            onChange={(e) => {
                              this.onSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                          { formErrors.customerNotFoundError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.customerNotFoundError }</span>
                        ) }
                       </div>
                       
                      </div>
                        

                      

                      {/* </div> */}

                      {/* <div className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Personal Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Name'>Name:</label>
                              <input type="text" className="form-control " id='Name'
                                placeholder="Name" readOnly
                                value={ customerId !== null && customerId !== '' && customerId !== undefined ? customerId.fullName : "" } required="required" />
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='AccountName'>AccountNo:</label>
                              <input type="text" className="form-control " id='AccountName'
                                placeholder="Account No" readOnly
                                value={ customerId !== null && customerId !== '' && customerId !== undefined ? customerId.accountNo : "" } required="required" />
                            </div>


                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Name'>Bank Name:</label>
                              <input type="text" className="form-control " id='Name'
                                placeholder="Name" readOnly
                                value={ bankInfoId !== null && bankInfoId !== '' && bankInfoId !== undefined ? bankInfoId.name : "" } required="required" />
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='AccountName'>Bank Account:</label>
                              <input type="text" className="form-control " id='AccountName'
                                placeholder="Account No" readOnly
                                value={ customerBank !== null && customerBank !== '' && customerBank !== undefined ? customerBank.bankAccount : "" } required="required" />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      { this.state.testBool && <BankInfo
                        content={ <>
                         
                            <>
                              <div className="card my-4 border-radius-0">
                                <div className="card-header border-radius-0 bg-light">
                                  <h6 class="title " >Sender Bank Information </h6>
                                </div>
                                <div className="card-body">
                                  <div className="row" >
                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='senderBankInfoList'>Bank Name:</label>
                                      <select className="form-control "
                                        onChange={ e => this.OnChangeSenderBankInfo(e) } id='senderBankInfoListList'>
                                        <option value={ null }>----please select---</option>
                                        { senderBankList }
                                      </select>
                                      { formErrors.senderBankError.length > 0 && (
                                        <span style={ { color: "#F61C04" } }>{ formErrors.senderBankError }</span>
                                      ) }
                                    </div>


                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='AccountName'>Bank Account:</label>
                                      <input type="text" className="form-control " id='AccountName'
                                        placeholder="Account No" name='senderBankAccount'
                                        onChange={ this.handelChange } onBlur={ this.handelChange }
                                        required="required" />
                                      { formErrors.senderAccountNoError.length > 0 && (
                                        <span style={ { color: "#F61C04" } }>{ formErrors.senderAccountNoError }</span>
                                      ) }
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </>

                          
                          <div>

                            <div className="card my-4 border-radius-0">
                              <div className="card-header border-radius-0 bg-light">
                                <h6 class="title " >Receiver Bank  Information</h6>
                              </div>
                              <div className="card-body">
                                <div className="row" >

                                  { isExpand ?
                                    <>
                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                        <label htmlFor='Name'>Bank Name:</label>
                                        <input type="text" className="form-control " id='Name'
                                          placeholder="Bank Name" disabled={ isExpand }
                                          value={ this.state.receiverBankName }
                                          required="required" />
                                      </div>

                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                        <label htmlFor='AccountName2'>Bank Account:</label>
                                        <input type="text" className="form-control" id='AccountName2'
                                          placeholder="Account No" disabled={ isExpand }
                                          value={ this.state.receiverBankAccount } required="required" />
                                      </div>

                                    </>
                                    :
                                    <>
                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                        <label htmlFor='receiverBankInfoList'>Bank Name:</label>
                                        <select className="form-control "
                                          onChange={ e => this.OnChangeReceiverBankInfo(e) } id='receiverBankInfoList'>
                                          <option value={ null }>----please select---</option>
                                          { receiverBankList }
                                        </select>
                                        { formErrors.receiverBankError.length > 0 && (
                                          <span style={ { color: "#F61C04" } }>{ formErrors.receiverBankError }</span>
                                        ) }
                                      </div>



                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                        <label htmlFor='AccountName'>Bank Account:</label>
                                        <input type="text" className="form-control" id='AccountName'
                                          onChange={ this.handelChange } onBlur={ this.handelChange }
                                          placeholder="Account No" name='receiverBankAccount'
                                        />
                                        { formErrors.receiverAccountNoError.length > 0 && (
                                          <span style={ { color: "#F61C04" } }>{ formErrors.receiverAccountNoError }</span>
                                        ) }
                                      </div>
                                    </>

                                  }
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="card my-4 border-radius-0">
                                <div className="card-header border-radius-0 bg-light">
                                  <h6 class="title " >Upload payment slip</h6>
                                </div>
                                <div className="card-body">
                                  <div className="row" >
                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <input type="file" onChange={ this.onFileChange } name='attachment' />
                                      { formErrors.attachmentError !== undefined && formErrors.attachmentError.length > 0 && (
                                        <span style={ { color: "#F61C04" } }>{ formErrors.attachmentError }</span>
                                      ) }
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                        </> }
                      /> }


                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Amount'>Amount:</label>
                        <input type="text" className="form-control" id='Amount' name='amount'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Amount" required="required" />
                        { formErrors.amountError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                        ) }
                      </div>



                      <div className="form-group col-15 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Description'>Description:</label>
                        <textarea type="text" className="form-control" id='Description'
                          onChange={ this.handelChange } onBlur={ this.handelChange } name='description'
                          placeholder="Description" />
                        { formErrors.descriptionError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.descriptionError }</span>
                        ) }
                      </div>



                      <div className="row  d-flex justify-content-left ">

                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Cash in</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>
                  <NotificationContainer />
                  {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
               />}
                </div>
              </div>


              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}

export default AddCashIn;
