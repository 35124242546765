import axios from 'axios';
import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
import RegionService from '../../../services/common/RegionService';
import CustomersService from '../../../services/common/CustomersService';
import CountryService from '../../../services/common/CountryService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Dropzone from "react-dropzone";
import { withRouter } from "react-router-dom";
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import CommissionService from '../../../services/etopup/CommissionService';
import Header from '../../common/Header';
import ReactLoading from "react-loading";


class EditTopupCustomer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            toCustomers: false,
            isLoading: false,
            // For profilepic and Identity purpose
            selectedFiles: undefined,
            currentFile: undefined,

            fileInfos: [],

            selectedFilesIdentity: undefined,
            currentFileIdentity: undefined,
            attachment: '',
            identityCard: '',

            customerMobileNo: this.props.match.params.customerMobileNo,
            isTopup: 1,
            customerData: {
                firstName: '',
                middleName: '',
                lastName: '',
                mobileNo: '',
                regionId: '',
                countryId: '',
                type: '',
                email: '',
                city: '',
                street: '',
                companyName: '',
                website: ''
            },
            source: null,
            regionList: [],
            countryList: [],
            customerTypes: [],
            commissionList: []
        };
        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeMiddleName = this.onChangeMiddleName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeMobileNo = this.onChangeMobileNo.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onChangeCountryId = this.onChangeCountryId.bind(this);
        this.onChangeRegionId = this.onChangeRegionId.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeStreet = this.onChangeStreet.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);
        this.onChangeCustomerType = this.onChangeCustomerType.bind(this);
        // this.editCustomer = this.editCustomer.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.onDropIdentity = this.onDropIdentity.bind(this);
    }

    onDrop(files) {

        if (files.length > 0) {
            this.setState({ selectedFiles: files });
            this.setState({ currentFile: this.state.selectedFiles[ 0 ] });
        }
    }

    onDropIdentity(files) {

        if (files.length > 0) {
            this.setState({ selectedFilesIdentity: files });
            this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[ 0 ] });
        }
    }


    onChangeFirstName(e) {
        const firstName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    firstName: firstName
                }
            };
        });
    }
    onChangeMiddleName(e) {
        const middleName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    middleName: middleName
                }
            };
        });
    }
    onChangeLastName(e) {
        const lastName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    lastName: lastName
                }
            };
        });
    }
    onChangeMobileNo(e) {
        const mobileNo = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    mobileNo: mobileNo
                }
            };
        });
    }
    onChangeCustomerType(e) {
        const type = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    type: type
                }
            };
        });
    }

    onChangeCompanyName(e) {
        const companyName = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    companyName: companyName
                }
            };
        });
    }

    onChangeCountryId(e) {
        const countryId = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    countryId: countryId
                }
            };
        });
    }
    onChangeRegionId(e) {
        const regionId = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    regionId: regionId
                }
            };
        });
    }
    onChangeCity(e) {
        const city = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    city: city
                }
            };
        });
    }
    onChangeEmail(e) {
        const email = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    email: email
                }
            };
        });
    }
    onChangeStreet(e) {
        const street = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    street: street
                }
            };
        });
    }
    onChangeWebsite(e) {
        const website = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    website: website
                }
            };
        });
    }
    Cancel = (e) => {
        this.props.history.push('/customers');
    }
    onChangeLogo(e) {
        const logo = e.target.value;

        this.setState(function (prevState) {
            return {
                customerData: {
                    ...prevState.customerData,
                    logo: logo
                }
            };
        });
    }
    componentDidMount() {

        CustomersService.getCustomerByMobileNumberforUpdate(this.state.customerMobileNo)
            .then(response => {
                this.setState({
                    //  customerData: response.data
                    customerData: response.data,
                    isLoading: true,
                });
              
            })
            .catch(e => {
                
                this.setState({ isLoading: true })
            });

        // alert(this.state.mobileNo)
        {/*To get list of regions/provinces*/ }
        RegionService.getAllRegions()
            .then(response => {
                this.setState({
                    regionList: response.data
                });
                //  
            })
            .catch(e => {
                
            });
        {/*To get list of countries*/ }
        CountryService.getCountries()
            .then(response => {
                this.setState({
                    countryList: response.data
                });
                // 
            })
            .catch(e => {
                
            });
        {/*To get Customer types*/ }
        CustomerTypeService.getCustomerTypes()
            .then(response => {
                this.setState({
                    customerTypes: response.data
                });
                //   
            })
            .catch(e => {
                
            });
        {/*To get Commission Infos*/ }
        CommissionService.getAllCommisions()
            .then(response => {
                this.setState({
                    commissionList: response.data
                });
                //    
            })
            .catch(e => {
                
            });
    }


    handleSubmit = e => {
        e.preventDefault();
        var data = new FormData();
        data.append("firstName", this.state.customerData.firstName);
        data.append("middleName", this.state.customerData.middleName);
        data.append("lastName", this.state.customerData.lastName);
        data.append("mobileNo", this.state.customerData.mobileNo);
        data.append("regionId", this.state.customerData.regionId);
        data.append("countryId", this.state.customerData.countryId);
        data.append("type", this.state.customerData.type);
        data.append("city", this.state.customerData.city);
        data.append("email", this.state.customerData.email);
        data.append("street", this.state.customerData.street);
        data.append("website", this.state.customerData.website);
        data.append("companyName", this.state.customerData.companyName);
        data.append('attachment', this.state.currentFile);
        data.append('identityCard', this.state.currentFileIdentity);
        if (this.isTopup) {
            data.append('isTopup', this.isTopup);
        } else {
            data.append('isTopup', this.state.isTopup);
        }

        CustomersService.updateCustmer(data)
            .then(response => {
                //  NotificationManager.success('Updated Successfully', 'Customer Update');
                this.props.history.push('/topupCustomers');
            })
            .catch(function (err) {
                if (err.response.status === 406) {
                    //toast.error('File extension not allowed !!');
                    NotificationManager.error("File extension not allowed");
                }
                if (err.response.status === 413) {
                    // toast.error('File size must be less than 2 MB !!');
                    NotificationManager.error("File size must be less than 2 MB");
                }
                else {
                    // toast.error('Something went wrong !!');
                    NotificationManager.error("Something went wrong");
                }
            });

    }


    render() {

        const { customerData, selectedFiles, isLoading, selectedFilesIdentity, customerTypes, commissionList } = this.state;
        const { regionList } = this.state;
        const { countryList } = this.state;

      
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        {/*
                                    <div class="modal-header">
                                        <h4 class="modal-title" >
                                        Agent Registration
                                        </h4>
                                    </div> */}
                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>First Name:</label>
                                                            <input type="text" className="form-control " placeholder="Full Name" value={ customerData.firstName } onChange={ this.onChangeFirstName } required="required" />
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>Middle Name:</label>
                                                            <input type="text" className="form-control " placeholder="Full Name" value={ customerData.middleName } onChange={ this.onChangeMiddleName } required="required" />
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>Last Name:</label>
                                                            <input type="text" className="form-control " placeholder="Full Name" value={ customerData.lastName } onChange={ this.onChangeLastName } required="required" />
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='MobileNumber'>Mobile Number:</label>

                                                            <input type="text" className="form-control " value={ customerData.mobileNo } onChange={ this.onChangeMobileNo } readOnly required="required" />
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>Customer Type:</label>

                                                            <select value={ customerData.type } onChange={ this.onChangeCustomerType } className="form-control" required="required">
                                                                <option value=""> Choose type</option>
                                                                { customerTypes.map(type =>
                                                                    <option value={ type.id }>{ type.type }</option>
                                                                ) }
                                                            </select>
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='companyName'>Company Name:</label>
                                                            <input type="text" className="form-control " id='companyName'
                                                                placeholder="Optional"
                                                                value={ customerData.companyName } onChange={ this.onChangeCompanyName } />
                                                        </div>

                                                        <div className=" card-header form-group col-6 col-sm-12 col-md-6 col-lg-6">

                                                            <input className="form-check-input mt-5  ml-3" onChange={ e => this.isTopup = e.target.value } onBlur={ this.handelInputChange } type="checkbox" />
                                                            <label className="form-check-label   pt-4 mt-3 ml-5"><span style={ { color: 'blue' } }>Is eTop up customer?</span></label>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/*Basic Information*/ }
                                            {/*Address Information*/ }
                                            <div className="card my-4 border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Address Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Country:</label>
                                                            <select value={ customerData.countryId } onChange={ this.onChangeCountryId } className="form-control" required="required">
                                                                {/* <option value=""> Choose Country</option> */ }
                                                                { countryList.map(country =>
                                                                    <option value={ country }>{ country.countryName }</option>
                                                                ) }
                                                            </select>
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Province'>Province:</label>
                                                            <select value={ customerData.regionId }
                                                                onChange={ this.onChangeRegionId } className="form-control"
                                                                required="required">
                                                                {/* <option value=""> Choose province</option> */ }
                                                                { regionList.map(getregion =>
                                                                    <option value={ getregion.id }>{ getregion.region }</option>
                                                                ) }
                                                            </select>
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='city'>City:</label>
                                                            <input type="text" className="form-control " id='city'
                                                                value={ customerData.city } onChange={ this.onChangeCity } />
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='email'>Email:</label>
                                                            <input type="text" className="form-control " id='email'
                                                                value={ customerData.email } onChange={ this.onChangeEmail } />
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Street'>Street:</label>
                                                            <input type="text" className="form-control " id='street'
                                                                placeholder="Optional"
                                                                value={ customerData.street } onChange={ this.onChangeStreet } />
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='website'>Website:</label>
                                                            <input type="text" className="form-control " id='website'
                                                                placeholder="Optional"
                                                                value={ customerData.website } onChange={ this.onChangeWebsite } />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/*Address Information*/ }
                                            {/*File attachment*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='profilePic'>Profile Picture:</label>
                                                            <img id="logo" className="mx-auto d-block rounded-circle" style={ { width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' } } src={ "data:image/png;base64," + customerData.attachment }></img>
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='profilePic'>Identity Card:</label>
                                                            <img id="logo" className="mx-auto d-block " style={ { width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' } } src={ "data:image/png;base64," + customerData.identityCard }></img>
                                                        </div>

                                                        {/* <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                    <label htmlFor='attachment'>Update attachment:</label>
                                                    <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleInputChange} />
                                                </div> */}
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <Dropzone onDrop={ this.onDrop }
                                                                multiple={ false }>
                                                                { ({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div { ...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" }) } style={ { border: "thin dashed black" } }>
                                                                            <input { ...getInputProps() } />
                                                                            { selectedFiles && selectedFiles[ 0 ].name ? (
                                                                                <div className="selected-file border-radius-1">
                                                                                    { selectedFiles && selectedFiles[ 0 ].name }
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop file here, or click to select file"
                                                                            ) }
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">
                                                                        </aside>
                                                                    </section>
                                                                ) }
                                                            </Dropzone>
                                                        </div>
                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            {/* <label htmlFor='Province'>Indentity Card:</label> */ }
                                                            {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleIdentityChange} /> */ }
                                                            <Dropzone
                                                                onDrop={ this.onDropIdentity }
                                                                multiple={ false }>

                                                                { ({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div { ...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" }) } style={ { border: "thin dashed black" } }>
                                                                            <input { ...getInputProps() } />
                                                                            { selectedFilesIdentity && selectedFilesIdentity[ 0 ].name ? (
                                                                                <div className="selected-file">
                                                                                    { selectedFilesIdentity && selectedFilesIdentity[ 0 ].name }
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop file here, or click to select file"
                                                                            ) }
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">

                                                                        </aside>
                                                                    </section>
                                                                ) }
                                                            </Dropzone>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/*File attachment */ }
                                            <div class="btn-group d-flex justify-content-center clear-fix">
                                                <button type="submit" class="btn btn-success mr-5 ml-5 mt-4 pl-2">Update</button>

                                                <button type="submit" class="btn btn-warning mr-5 mt-4 px-2" onClick={ () => this.Cancel() }>Cancel</button>

                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>


                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditTopupCustomer);