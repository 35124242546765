import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import TopupOrderService from '../../../services/etopup/TopupOrderService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class SendSmsBroadcastMessage extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            providerId: '',
            providersList: [],
            selectOption: null,
            pageNo: 1,
            customerId: '',
            createdBy: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                amountError: "",
                selectOptionError: ""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);

    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;

            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    fatchData = (inputValue, callback) => {
        if (inputValue.length >=3) {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (selectOption) => {
        if (selectOption) {
            this.setState({
                selectOption
            });

        }
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });

        {/*To get list of providers*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });

    }

    Cancel = (e) => {
        this.props.history.push('/ordersList');
    }

    handleSubmit(e) {   
        e.preventDefault();
        const { providerId, amount, selectOption, formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError ;
        formErrors.selectOptionError = selectOption == '' || selectOption == null || selectOption == undefined ? 'Please select the dealer' : this.state.formErrors.selectOptionError;
        
        if (formValid(this.state.formErrors)) {
            var data = new FormData();
            data.append("dealerCustomerId", this.state.selectOption.value);
            data.append("amount", this.state.amount);
            data.append("userId", localStorage.userId);
            data.append("createdBy", localStorage.customerIdentity);


            TopupOrderService.addTopupOrder(data)
                .then(response => {
                    NotificationManager.success("Order is successfull ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.Cancel()
                })
                .catch(function (err) {

                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);

                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    };


    render() {
        const { providersList, formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">SMS Broadcasting</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-6 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit } >
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0 ">
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Send SMS Message </h6>
                                                </div>
                                                <div className="card-body ">
                                                    <div className='row center'>


                                                        <div className=" form-group col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <label htmlFor='Country'>Title:</label>
                                                            <textarea rows={ 1 } cols={ 4 } type="text" className="form-control " id='fullName' onChange={ e => this.remark = e.target.value } />
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <label htmlFor='Country'>Body:</label>
                                                            <textarea rows={ 3 } cols={ 4 } type="text" className="form-control " id='fullName' onChange={ e => this.remark = e.target.value } />
                                                        </div>
                                                    {/*
                                                        <div className=" form-group col-12 col-sm-12 col-md-8 col-lg-8">
                                                            <label htmlFor='amount'>To:</label>
                                                            <AsyncSelect id='CustomerList'
                                                                required
                                                                placeholder={ "Search by name or mobile" }
                                                                value={ this.state.selectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchData }
                                                                isLoading={ this.state.selectOption === {} &&
                                                                    this.state.selectOption === undefined &&
                                                                    this.state.selectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                         </div> 
                                                    */}
                                                        <div className=" form-group col-12 col-sm-12 col-md-4 col-lg-4">
                                                            <label htmlFor='amount'>All</label>
                                                            <input type="checkbox" style={ { width: '20px', height: '2opx' } } className="form-control " id='overdue' name='overdue' />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Send</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(SendSmsBroadcastMessage);
