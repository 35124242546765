import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../../components/wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import Header from '../../common/Header';
import SalesTeamAssignmentService from '../../../services/etopup/SalesTeamAssignmentService';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

class SalesTeamAssignmentList extends Component {
    constructor (props) {
        super(props)
        this.state = {

            mainDealerId: null,
            salesTeamId: null,
            isLoading: false,
            mainDealeraSelectOption:null,
            salesTeamSelectOption: null,
            selectedId:null,
            masterPageNo: 1,
            masterPageSize:LOCALES.PAGE_SIZE,

            detailPageNo:1,
            detailPageSize:LOCALES.PAGE_SIZE,

            masterTotalPage: 0, 
            masterTotalElement: 0,
             
            detailTotalPage:0,
            detailTotalElement:0,

            assignedDealers:[],
            allSalesTeamList:[],
            parentCounter: 1, 
            childCounter:1,
            isChildExist:false,
            displayPaginationStatus:0,
            id: 0,
            flag: false,
            isExpand: true,
            isListExpand:true,
            isClicked:false
        }
        this.clearValue = this.clearValue.bind(this);
    }
    gotoDetailEnteredPage = (pageNumber,salesTeamId) => {    

        this.state.detailPageNo = pageNumber
        this.seachAssignedMainDealerOnFilter(salesTeamId)
        this.render()
        this.setState({
            isClicked:true
        })
    }
    gotoDetailPageLast = (salesTeamId) => {
        this.state.detailPageNo = this.state.detailTotalPage
        this.seachAssignedMainDealerOnFilter(salesTeamId)
        this.render()

        this.setState({
            isClicked:true
        })
    }
    gotoDetailPageFirst = (salesTeamId) => {
        this.state.detailPageNo = 0
         this.seachAssignedMainDealerOnFilter(salesTeamId)
        this.render()

        this.setState({
            isClicked:true
        })
    }

    privDetailPage = (salesTeamId) => {
        let pages = 0
        if (this.state.detailPageNo > pages) {
            this.state.detailPageNo = this.state.detailPageNo - pages
            this.seachAssignedMainDealerOnFilter(salesTeamId)
            this.render()

        }
    }

    nextDetailPage = (salesTeamId) => {
        let totalPages = this.state.detailTotalPage;
        if (this.state.detailPageNo < totalPages) {
            this.state.detailPageNo = this.state.detailPageNo + 0
            this.seachAssignedMainDealerOnFilter(salesTeamId)
            this.render()
        }
    
        this.setState({
            isClicked:true
        })
    }


    gotoMasterEnteredPage = (pageNumber) => {   
        this.state.masterPageNo = pageNumber
        this.searchSalesTeamAssignmentOnFilter()
        this.render()
    }
    gotoMasterPageLast = () => {
        this.state.masterPageNo = this.state.masterTotalPage
        this.searchSalesTeamAssignmentOnFilter()
        
        this.render()

    }
    gotoMasterPageFirst = () => {
        this.state.masterPageNo = 0
        this.searchSalesTeamAssignmentOnFilter()
        this.render()
    }

    privMasterPage = () => {
        let pages = 0
        if (this.state.masterPageNo > pages) {
            this.state.masterPageNo = this.state.masterPageNo - pages
            this.searchSalesTeamAssignmentOnFilter()
            this.render()

        }
    }

    nextMasterPage = () => {

        let totalPages = this.state.masterTotalPage;
        if (this.state.masterPageNo < totalPages) {
            this.state.masterPageNo = this.state.masterPageNo + 0
            this.searchSalesTeamAssignmentOnFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.salesTeamId = null
        this.state.clear = 0
    }


    clear = e => {
        e.preventDefault()
        if (this.state.clear == 0) {
            e.target.reset();
            this.state.clear = 0
            this.searchSalesTeamAssignmentOnFilter()
        }
    }
    
    fetchSalesTeam = (inputValue, callback) => { 
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findSalesTeamAssignmentBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.salesTeamId.fullName, value: element.salesTeamId.id })
                        });
                        callback(temp)
                        this.setState({
                            salesTeamList: data,  
                        })
                    })
                    .catch((error) => {
                    })
            })
        }
    }

    onSalesTeamChange = (salesTeamSelectOption) => { 
        
        this.state.salesTeamSelectOption = salesTeamSelectOption
        if (salesTeamSelectOption) {
            this.setState({
                salesTeamSelectOption:salesTeamSelectOption.value
            });
        }
    }

    searchCashCollectorAssignmentInfo(e) {
        this.searchSalesTeamAssignmentOnFilter()
    }
 
    searchSalesTeamAssignmentOnFilter() { 
         if(this.state.salesTeamSelectOption!==null)
            {
            this.state.salesTeamId=this.state.salesTeamSelectOption
            }
        let FilterSalesInfo = {
              salesTeamId:  this.state.salesTeamId ,
              mainDealerId: this.state.mainDealerId
              }

              SalesTeamAssignmentService.findAssignedSalesTeams(
            this.state.masterPageNo, this.state.masterPageSize, localStorage.userId, FilterSalesInfo)
            .then(response => response.data)
            .then((data) => {
                    this.setState({
                        isLoading: true,
                        allSalesTeamList: data.content,
                        isOpen: false,
                        masterTotalPage: data.totalPages,
                        masterTotalElement: data.totalElements,
                        masterPageNo: data.pageable.pageNumber+1
                    });
            
                
                if (data.content.length < 0) {
                    NotificationManager.error("Sales team list not found",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                }
                this.setState({ isLoading: true })
            });

    }

    componentDidMount() {
        this.searchSalesTeamAssignmentOnFilter()
        // this.seachAssignedMainDealerOnFilter()
        
    }

    searchMainDealersInfo(salesTeamId){
        this.seachAssignedMainDealerOnFilter(salesTeamId)
        this.setState({
            isListExpand: !this.state.isListExpand,
        })
        }

     seachAssignedMainDealerOnFilter(salesTeamId) {
   
            let FilterSalsInfo = {
                salesTeamId:  salesTeamId ,
            }

        SalesTeamAssignmentService.findSalesTeamAssignmentBySalesTeamId(
            this.state.detailPageNo, this.state.detailPageSize, localStorage.userId, FilterSalsInfo)
            .then(response => response.data)
            .then((data) => {
                if (data.salesTeamAssignments) {
                    this.setState({
                        isLoading: true,
                        assignedDealers: data.salesTeamAssignments,
                        selectedId:salesTeamId,
                        isClicked:true,
                        isOpen: false,
                        detailTotalPage: data.totalPages,
                        detailTotalElement: data.totalElement,
                        detailPageNo: data.pageNumber + 1
                    });
                }
                if (data.salesTeamAssignments.length < 0) {
                    NotificationManager.error("Sales team list not found",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                }
                this.setState({ isLoading: true })
                
            });

    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    render() {
        const { allSalesTeamList,assignedDealers,selectedId, formErrors, masterPageNo,masterPageSize,detailPageNo,detailPageSize, isLoading, masterTotalPage,detailTotalPage } = this.state;
    
        masterPageNo == 1 ? this.state.parentCounter = 1 : this.state.parentCounter = (masterPageSize * (masterPageNo - 1)) + 1
        detailPageNo == 1 ? this.state.childCounter = 1 : this.state.childCounter = (detailPageSize * (detailPageNo - 1)) + 1

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                < div >
                    {/* <Header />
                    <Menu />

                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }

                    <div className="content-wrapper bg-white">

                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">

                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Sales Team</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                         <div className="form-title" >Team List</div> 
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-5">
                                                  <div>
                                                        <label htmlFor='salesTeamList' >Sales Team</label>
                                                        <AsyncSelect id='salesTeamList'
                                                            className="select"
                                                            placeholder={ "Search  by name or mobile" }
                                                            isClearable={ true }

                                                            loadOptions={ this.fetchSalesTeam }
                                                            isLoading={ this.state.salesTeamSelectOption === {} &&
                                                                this.state.salesTeamSelectOption === undefined &&
                                                                this.state.salesTeamSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onSalesTeamChange(e)  
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                    </div> 
                                                </div>

                                                <div className='mt-4 pt-2'>
                                                <button type="button" title={ "Search" }   
                                                    className="btn btn-sm btn-success primary m-0 float-right"
                                                    onClick={ () => this.searchCashCollectorAssignmentInfo(this.state.mainDealerId,this.state.salesTeamId) }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-0 float-right"  >Clear</button>
                                                </div>
                                            </div>

                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />

                                        <Link to={ { pathname: '/addSalesTeamAssignment' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        
                                            <table className="table  border" style={ { fontSize: 'smaller' } }  >
                                                <thead className='thead-light'>
                                                    <tr style={ { marginTop: 0 } }>
                                                        <th style={{width:'5%'}}></th>
                                                        <th style={{width:'5%'}}>No.</th>
                                                        <th style={{width:'30%'}}>Full Name</th>
                                                        <th>Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { allSalesTeamList && allSalesTeamList.map(salesList =>
                                                     <>
                                                        <tr key={ salesList.id } >
                                                            <td className='p-0 ' style={{width:'5%',textAlign:'top'}}>{                
                                                                <button style={{padding:'0px 1px 0px 3px',textAlign:'top',marginLeft: "2px" } } 
                                                                 onClick={ () => this.searchMainDealersInfo(salesList.salesTeamId) }  
                                                                 className="btn btn-light btn-sm" title="View detail">
                                                                    {!this.state.isListExpand && this.state.selectedId===salesList.salesTeamId ? <i className="nav-icon fa fa-minus" style={{fontSize:'smaller'}}/>  : <i className="nav-icon fa fa-plus" style={{fontSize:'smaller'}}/> }
                                                                </button>
                                                                    }
                                                            </td>
                                                            <td className='p-0 ' style={{width:'5%'}}>{this.state.parentCounter++}</td>
                                                            <td className='p-0 ' style={{width:'30%'}}>{ salesList.fullName }</td>
                                                            <td className='p-0 '>{ salesList.count}</td>
                                                        </tr>
                                                        <tr  hidden={ this.state.isListExpand}  >
                                                           { this.state.selectedId===salesList.salesTeamId &&
                                                            <td colSpan='4' style={{padding:'0px',border:'none', borderSpacing:'0px', }}>
                                                             <table className='table border-bottom' style={{tableLayout:'fixed',width:'100%', cellspacing:"0", cellpadding:"0"}}> 
                                                                 
                                                                { assignedDealers  && assignedDealers.map(dealers =>
                                                                 <tbody  key={ dealers.salesTeamId }> 
                                                                 { salesList.salesTeamId===dealers.salesTeamId &&
                                                                  <tr>
                                                                    <td style={{width:'5%'}}></td>
                                                                    <td style={{width:'5%'}}></td> 
                                                                    <td className='p-0 ' style={{width:'5%'}}>{this.state.childCounter++}</td> 
                                                                    <td className='p-0 ' style={{width:'20%'}}>{ dealers.delaerFullName }</td>
                                                                    <td className='p-0 ' style={{width:'10%'}}>{ dealers.rate + " %" } </td>
                                                                    <td className='p-0 '></td>
                                                                  </tr>
                                                                 }
                                                                 </tbody> 
                                                                 )}
                                                                 </table> 
                                                            </td>
                                                             }
                                                        </tr>
                                                         <tr  hidden={ this.state.isListExpand}>
                                                             { this.state.selectedId===salesList.salesTeamId &&
                                                              <td colSpan='4'  style={{padding:'0px',border:'none', borderSpacing:'0px', paddingRight:'25px',textAlign:'left'}} >                                                                
                                                                <div>
                                                                    <div >
                                                                        <span>
                                                                            page{ '' }
                                                                            <strong>
                                                                                { detailPageNo } of { detailTotalPage }
                                                                            </strong>
                                                                            { '' }
                                                                        </span>
                                                                        <span>
                                                                            | Go To Page : { '' }
                                                                            <input type='number' defaultValue={ detailPageNo }
                                                                                onChange={ (e) => {
                                                                                    const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                                                    this.gotoDetailEnteredPage(pageNumber,salesList.salesTeamId)
                                                                                } } />
                                                                        </span>
                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.gotoDetailPageFirst(salesList.salesTeamId) } disabled={ detailPageNo === 0 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackwardFast } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.privDetailPage(salesList.salesTeamId) } disabled={ detailPageNo === 0 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackward } />
                                                                        </button>

                                                                        <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-0 col-sm-0 col-md-0 col-lg-0 " defaultValue={ detailPageNo } value={ detailPageNo } readOnly />

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.nextDetailPage(salesList.salesTeamId) } disabled={ detailPageNo === detailTotalPage ? true : false }>
                                                                            <FontAwesomeIcon icon={ faForward } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0 " onClick={ () => this.gotoDetailPageLast(salesList.salesTeamId) } disabled={ detailPageNo === detailTotalPage ? true : false } >
                                                                            <FontAwesomeIcon icon={ faForwardFast } />
                                                                        </button>
                                                                    </div>
                                                                    </div> 
                                                                </td>
                                                              }
                                                         </tr>
                                                        
                                                        </>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center" style={{fontSize:'smaller'}} hidden={ !this.state.isListExpand}>
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { masterPageNo } of { masterTotalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ masterPageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoMasterEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.gotoMasterPageFirst() } disabled={ masterPageNo === 0 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.privMasterPage() } disabled={ masterPageNo === 0 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-0 col-sm-0 col-md-0 col-lg-0 " defaultValue={ masterPageNo } value={ masterPageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0  " onClick={ () => this.nextMasterPage() } disabled={ masterPageNo === masterTotalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-0 py-0 " onClick={ () => this.gotoMasterPageLast() } disabled={ masterPageNo === masterTotalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >
            )
        }
    }
}
export default SalesTeamAssignmentList