import React, { Component } from 'react';
import ProvidersSettingService from '../../../services/etopup/ProvidersSettingService';
import Dropzone from 'react-dropzone';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import TopupMenu from '../../etopup/TopupMenu';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const providerNameRegEx = RegExp(/^[a-zA-Z_\-/ ]{3,50}$/u)
const codeRegEx = RegExp(/^[a-zA-Z]{2,3}$/u)
const perfixReqEx = RegExp(/^[?=1-9]+[0-9/]{2,50}$/u)

class AddProvidersSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerName: '',
      isLoading: false,
      code: '',
      selectedFilesIdentity: undefined,
      currentFileIdentity: undefined,
      mobilePrefix:'',
      selectOption:'',

      formErrors: {
        providerNameError: "",
        codeError: "",
        mobilePrefixError:"",
        countryError:"",
        logoError: '',
        customerError: ""
      },
    }

    this.onDropIdentity = this.onDropIdentity.bind(this);

  }

  handleSubmit = e => {
    e.preventDefault();
    const { providerName, code, mobilePrefix, selectOption, currentFileIdentity, formErrors } = this.state
    formErrors.providerNameError = providerName == '' || providerName == null ? "Provider Name is Required" : this.state.formErrors.providerNameError;
    formErrors.codeError = code == '' || code == null ? "Code is Required" : this.state.formErrors.codeError;
    formErrors.logoError = currentFileIdentity == '' || currentFileIdentity == null ? "logo is Required" : this.state.formErrors.logoError;
    formErrors.countryError = selectOption == '' || selectOption == null ? "cuntry is Required" : this.state.formErrors.countryError;
    formErrors.mobilePrefixError = mobilePrefix == '' || mobilePrefix == null ? "mobile Prefix is Required" : this.state.formErrors.mobilePrefixError;

    if (formValid(this.state.formErrors)) {
      const providersSettingData = new FormData();
      providersSettingData.append("providerName", this.state.providerName);
      providersSettingData.append("code", this.state.code);
      providersSettingData.append("logo", this.state.currentFileIdentity);
      providersSettingData.append("countryId", this.state.selectOption && this.state.selectOption.value);
      providersSettingData.append("mobilePrefix", this.state.mobilePrefix);

      this.setState({ isLoading: true})
      ProvidersSettingService.createProvidersSetting(localStorage.userId, providersSettingData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoading: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'providerName':
        formErrors.providerNameError =
          value.length == 0 || !providerNameRegEx.test(value) ? name + " value sholud only alphabetic and (/_) characters with min 3 and max 50" : ""
        break;
      case 'code':
        formErrors.codeError =
          !codeRegEx.test(value) ? name + " value sholud only alphabetic characters with min 2 and max 3" : ""
        break;
      case 'mobilePrefix':
        formErrors.mobilePrefixError =
          !perfixReqEx.test(value) ? name + " value sholud only digites with min 2 and max 50" : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [name]: value
    })
  }

  cancel() {
    this.props.history.push('/providerSettingList')
  }

  onDropIdentity(files) {

    if (files.length > 0) {
      this.setState({ selectedFilesIdentity: files });
      this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[0] });
    }
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCountryByCountryName/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.countryName + " - " + element.countryCode, value: element.id })
            });
            callback(temp)
            this.setState({
              CountryList: data,

            })

          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    if (selectOption) {
      this.setState({
        selectOption
      });

    }
  }


  render() {
    const { formErrors, selectedFilesIdentity } = this.state

    return (

      <div >
        {/* <Header />
        <TopupMenu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Provider Setting Information</li>

                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Providers Setting Information
                      </h4>
                    </div>

                    <form onSubmit={this.handleSubmit} noValidate>
                      {/*<h2>Register</h2> */}

                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='providerName'>Name:</label>
                        <input type="text" className="form-control" id='providerName'
                          onChange={this.handelChange} onBlur={this.handelChange} name='providerName'
                          placeholder="Provider Name" />

                        {formErrors.providerNameError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.providerNameError}</span>
                        )}
                      </div>
                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='code'>code:</label>
                        <input type="" className="form-control" id='code'
                          onChange={this.handelChange} onBlur={this.handelChange} name='code'
                          placeholder="code" />
                        {formErrors.codeError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.codeError}</span>
                        )}
                      </div>
                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Country'>Country Name:</label>
                        <AsyncSelect id='CountryList'
                          required
                          placeholder={"Search country by name "}
                          value={this.state.selectOption}
                          isClearable={true}
                          loadOptions={this.fatchData}
                          isLoading={this.state.selectOption === {} &&
                            this.state.selectOption === undefined &&
                            this.state.selectOption === null ? true : false
                          }
                          onChange={(e) => {
                            this.onSearchChange(e)
                          }}
                          defaultOptions={false}
                        />
                        {formErrors.countryError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.countryError}</span>
                        )}
                      </div>
                      <div className=" form-group col-12 col-sm-12 col-md-8 col-lg-8">
                        <label htmlFor='Country'>Mobile Prefix:</label>
                        <input type="text" className="form-control " id='mobilePrefix' name='mobilePrefix'
                          onChange={this.handelChange} onBlur={this.handelChange} />
                        <label style={ { fontSize: 'smaller' } } >N.B Separate by "/" for more than one mobile prefixs</label>
                        {formErrors.mobilePrefixError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.mobilePrefixError}</span>
                        )}
                      </div>
                      <div className="form-group col-8 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='logo'>Logo:</label>
                        <Dropzone id="logo" accept={".png, .jpg  "}
                          onDrop={this.onDropIdentity} 
                          multiple={false}>

                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" })} style={{ border: "thin dashed black" }}>
                                <input {...getInputProps()} />
                                {selectedFilesIdentity && selectedFilesIdentity[0].name ? (
                                  <div className="selected-file">
                                    {selectedFilesIdentity && selectedFilesIdentity[0].name}
                                  </div>
                                ) : (
                                  "Drag and drop file here, or click to select file"
                                )}
                              </div>
                              <aside className="selected-file-wrapper">

                              </aside>
                            </section>
                          )}
                        </Dropzone>
                        <label style={{ fontSize: 'smaller' }} >N.B upload image with .png or .jpg file only</label>
                        {formErrors.logoError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.logoError}</span>
                        )}
                      </div>



                      <div className="row  d-flex justify-content-left ">

                        <button  disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={this.cancel.bind(this)}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div >
      </div >

    )
  }
}



export default AddProvidersSetting;