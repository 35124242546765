import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import KioskService from '../../../services/wallet/KioskService';
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from "react-loading"; 
import LoadingPopup from '../LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

class AddKiosk extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            name: null,
            kioskId: '',
            serialNumber: '',
            address: '',
            latitude: '',
            longitude: '',
            status: `${process.env.REACT_APP_KIOSK_DEFAULT_STATUS}`   ,


            getKiosk: [],

            formErrors: {
                kioskIdError: "",
                serialNumberError: "",
                // statusError: ""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);
    }

    handelInputChange = e => {
        e.preventDefault();
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'kioskId':
                formErrors.kioskIdError =
                    value.length == 0 ? "" + " Please enter the Kiosk ID " : ""
                break;
            case 'serialNumber':
                formErrors.serialNumberError =
                    value.length == 0 ? "" + " Please enter the serial number " : ""
                break;

            default:
                break;
        }
        this.setState({
            formErrors, [ name ]: value
        })
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/kioskList');
    }

    handleSubmit(e) {   //

        e.preventDefault();

        const { kioskId, serialNumber, status, formErrors } = this.state
        formErrors.kioskIdError = kioskId === '' || kioskId === null || kioskId === undefined ? 'Kiosk Id should not be empty' : this.state.formErrors.kioskIdError;
        formErrors.serialNumberError = serialNumber === '' || serialNumber === null || serialNumber === undefined ? 'Serial number should not be empty' : this.state.formErrors.serialNumberError;
       

         if (formValid(this.state.formErrors)) {

            var data = new FormData();
            data.append("name", this.state.name);
            data.append("kioskId", this.state.kioskId);
            data.append("serialNumber", this.state.serialNumber);
            data.append("address", this.state.address);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            data.append("status", this.state.status);
            data.append("userId", localStorage.userId);

            this.setState({ isLoading: true})
            KioskService.getKioskByKioskId(this.state.kioskId, localStorage.userId)
                .then(response => {
                    this.setState({
                        isLoading: false,
                        getKiosk: response.data
                    });
                    
                    if (this.state.getKiosk) {
                        if (window.confirm("Kiosk already exists ! Are you sure you want to update it?")) {
                            KioskService.createKiosk(data)
                                .then(
                                (response) => {
                                    NotificationManager.success("Successfully created ",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                                    this.setState({ isLoading: false})
                                }, 
                                (err) => {
                                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000); 
                                    this.setState({ isLoading: false})
                                })
                                .catch(function (err) {
                                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                                    this.setState({ isLoading: false})
                                });
                        }
                    }
                    else {
                        KioskService.createKiosk(data)
                            .then(
                            response => {
                                NotificationManager.success("Successfully created ",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                                this.setState({ isLoading: false})
                            },
                            (err) =>{
                                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000); 
                                this.setState({ isLoading: false})
                            })
                            .catch(function (err) {
                                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                                this.setState({ isLoading: false})
                            });
                    }

                })
                .catch(e => {
                    this.setState({ isLoading: false})
                });

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    };


    render() {
        const { formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <Menu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active"> Kiosk Registration</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit } >
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0 ">
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add Kiosk</h6>
                                                </div>
                                                <div className="card-body ">
                                                    <div className='row'>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='name'>Name:</label>
                                                            <input type="text" id="name" className="form-control " name="name" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                        </div> */}
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='kioskId'>Kiosk ID:</label>
                                                            <input type="text" id="kioskId" className="form-control " name="kioskId" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.kioskIdError  && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.kioskIdError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='serialNumber'>Serial No:</label>
                                                            <input type="text" id="serialNumber" className="form-control " name="serialNumber" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.serialNumberError  && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.serialNumberError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                            
                                                    <div className='row'>

                                                        <div className="form-group form-group-sm col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='location' className='ml-5 pl-5'>Location:</label>
                                                            <div className="row no-gutters">
                                                                <label htmlFor="latitude" class="col-sm-5 col-form-label">Latitude</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" id="latitude" name="latitude" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters mt-3">
                                                                <label htmlFor="longitude" className="col-sm-5 col-form-label">Longitude</label>
                                                                <div className="col-sm-7">
                                                                    <input type="text" className="form-control" id="longitude" name="longitude" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='address'>Address:</label>
                                                            <input type="text" id="address" className="form-control " name="address" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                        </div>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='status'>Status:</label>
                                                            <select id='status' name="status" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" >
                                                                <option value=""> Select status</option>
                                                                <option value="Active"> Active</option>
                                                                <option value="Inactive"> Inactive</option>
                                                                <option value="Warning"> Warning</option>
                                                            </select>
                                                            { formErrors.statusError == "" && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.statusError }</span>
                                                            ) }
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ml-5">
                                                <button  disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div >
            </div >

        )
    }
}

export default withRouter(AddKiosk);
