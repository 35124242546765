import React, { Component } from 'react'
import TopupMenu from '../../etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import AccumulatedBalanceTransferService from '../../../services/etopup/AccumulatedBalanceTransferService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
class AccumulatedBalanceTransfer extends React.Component {

    constructor () {

        super();

        this.state = {
            customerId: '',
            amount: '',
            userId: '',
            isLoading: false,
            getWalletByCustomerId: [],
            formErrors: {
                amountError: ""
            },
        }
        this.handelInputChange = this.handelInputChange.bind(this);
        this.handleKeyPress=this.handleKeyPress.bind(this);
    }
    handleKeyPress = (event) => {
      const allowedChars = /[0-9\.]/;
      if (!allowedChars.test(event.key)) {
        event.preventDefault();
      }
    };
    Cancel = e => {
        this.props.history.push('/transactionMonitoringList');
    }
    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    componentDidMount() {
        // WalletBalanceService.getWalletByCustomerId(localStorage.customerIdentity, localStorage.userId)
        //     .then(response => {
        //         this.setState({
        //             getWalletByCustomerId: response.data
        //         });
        //         
        //     })
        //     .catch(e => {
        //         
        //     });
    }
    handleSubmit = e => {
        e.preventDefault();
        const { amount, formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
      
        if (formValid(this.state.formErrors)) {
            this.setState({ isLoading: true})
            AccumulatedBalanceTransferService.accumulatedBalanceTransfer(localStorage.customerIdentity, this.state.amount, localStorage.userId)
                .then(response => {
                    NotificationManager.success("Successfully transfered !!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({ isLoading: false})
                    this.props.history.push('/transactionMonitoringList');
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                 //   this.setState({ isLoading: false})                   
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };


    render() {
        const { getWalletByCustomerId, formErrors } = this.state

        return (

            <div>
                {/* <Header />
                <TopupMenu />
                <NotificationContainer /> */}

                <div className="content-wrapper bg-white">
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">

                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" >Accumulated Balance Transfer  </li>

                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    {/* <h6 class="title " >Customer Type </h6> */ }
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>


                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='type'>Amount :</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading } type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                              content={<>
                                    <form>
                                            <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                        </form>
                                 </>}
                            />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AccumulatedBalanceTransfer);
