import API from '../../../src/http-common';

class IntegrationTypeService {

    createIntegrationType(userId,IntegrationTypeData) {
        return API.post('/api/v1/createIntegrationType/'+userId,IntegrationTypeData);
    }
    getAllIntegrationTypes(userId) {
        return API.get('/api/v1/findAllIntegrationTypes/'+userId);
    }

}
export default new IntegrationTypeService()