
import CustomerService from '../../../services/etopup/CustomerService';
import MerchantService from '../../../services/wallet/MerchantService';
import React, { Component } from 'react'
import Menu from '../Menu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';

export default class MerchantDetail extends React.Component {
    constructor() {

        super();
        this.state = {
            merchantData: {
                name: "",
                accountNo: "",
                balance: "",
                tel:"",
                province:"",
                city: "",
                street:""
            }
        };
    }

    componentDidMount() {
        this.getMerchantByTel(this.props.location.state.mobileNo);
    }

    getMerchantByTel(mobileNo) {
        MerchantService.getMerchantByTel(mobileNo)
            .then(response => {
                this.setState({
                    merchantData: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    render() {
        const { merchantData } = this.state;
       {/* const base64String = btoa(String.fromCharCode(...new Uint8Array(merchantData.logo))); */}
        return (
            <div>
                {/* <Header/>
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Merchant Detail</li>
                                    </ol>
                                </div>

                            </div>
                            <div >
                        {/*
                                <div className="row ">

                                    <div className='col-3'></div>

                                    <div className='col-6 p-5 border'>
                                        <h4 className='mb-3' style={{ fontFamily: 'Times New Roman' }}>Merchant Detail View</h4>
                                   

                                        <div className="row  " >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Account No :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.accountNo}
                                            </div>
                                        </div>
                                        <div className="row  " >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Balance :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.balance}
                                            </div>
                                        </div>

                                        <div className="row  " >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Website :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.website}
                                            </div>
                                        </div>
                                        <div className="row  " >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Logo :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                            
                                            <img style={{display: 'block', width: '100px', height: '100px'}} id="base64image" src="data:image/jpeg;base64, {merchantData.logo}...<!-- base64 data -->" /> 
                                            </div>
                                        </div>
                                      
                                        <div className="row  d-flex justify-content-center ">

                                            
                                        </div>
                                    </div>

                                    <div className='col-2'></div>

                                </div>
                                */}
           

                <div className="row mb-5">

                  <div className='col-2'></div>
                  <div className='col-8  p-4  border border-1 rounded-3'>

                      {/*<h2>Register</h2> */}
                           
                      <div className="row  " >
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  image-container">

                         <div className='center'> <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '85px', height: '85px',margin: 'auto',alignSelf: 'center' }} src={"data:image/png;base64," +merchantData.logo}></img> </div>
                       
                         </div>
                          <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                      <div className="row  ml-2" >
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Account No :{merchantData.accountNo}</label>
                                        </div>
                                        <div className="row  ml-2" >
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Balance :{merchantData.balance}</label>
                                        </div>
                          </div>
                      </div>

                      <div className="row  " >
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                  <div className="row container" >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily:'Times New Roman',fontSize: "17px" }}>Full Name :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.name}
                                            </div>
                                    </div>
                        </div>
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                      <div className="row  container" >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Province :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.province}
                                            </div>
                                        </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                     <div className="row container" >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Mobile No:</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.tel}
                                            </div>
                                        </div>
                        </div>
                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                     <div className="row  container" >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>City :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.city}
                                            </div>
                                        </div>
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6"   >
                        <div className="row  container" >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Street :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.street}
                                            </div>
                                        </div>
                        </div>
                        <div className="form-group col-6 "   >
                        <div className="row container " >
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                <label style={{ fontFamily: 'Times New Roman', fontSize: "17px" }}>Website :</label>
                                            </div>
                                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                                                {merchantData.website}
                                            </div>
                                        </div>
                        </div>
                      </div>

                      <div className="row  d-flex justify-content-center ">

                      <Link to="/merchantList"> <button type="submit" className="btn btn-success  px-5 py-2 mb-2">Back</button> </Link>

                      </div>
                  </div>
                  <div className='col-2'></div>

                </div>
                            </div>


                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>
        )
    }
}