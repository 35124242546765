import { faKey, faSignIn, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoginService from '../../services/common/LoginService';
import authToken from '../util/authToken';
import PhoneInput from 'react-phone-input-2';
import axios from "axios";
import ReactLoading from "react-loading";
import Popup from '../wallet/Popup';
import LoadingPopup from '../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const loginIdRegEx = RegExp(/^[a-zA-Z0-9_]{3,12}$/u)
const passwordRegEx = RegExp(/^[\p{L} \p{N}_@,.&%]{8,20}$/u)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            isLoading: false,
            ip: '',
            latitude: null,
            longitude: null,
            customerId: '',
            companyProfile: [],
            formErrors: {
                userError: "",
                passwordError: "",
                userOrPasswordError: ""
            },
        }

    }



    componentDidMount() {
        localStorage.removeItem('walletMenu');    

        LoginService.companyProfileInfo()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    companyProfile: data
                });
        });

        axios.get('https://ipapi.co/json/')
            .then(res => {
                this.setState({
                    ip: res.data.ip,
                    latitude: res.data.latitude,
                    longitude: res.data.longitude
                });

            })
            .catch(e => {

            });



    }

    handleSubmit = e => {
        e.preventDefault();
        const { userName, password, formErrors } = this.state
        formErrors.userOrPasswordError = ''
        formErrors.userError = userName === '' || userName === null ? "Mobile number or username is required." : this.state.formErrors.userError;
        formErrors.passwordError = password === '' || password === null ? "Password is Required." : this.state.formErrors.passwordError;

        if (formValid(this.state.formErrors)) {
            this.state.isLoading = true;
            let loginInfo = {
                loginId: this.state.userName,
                password: this.state.password,
                ip: this.state.ip,
                latitude: this.state.latitude,
                longitude: this.state.longitude
            }

            this.setState({ isLoading: true})
            LoginService.signin(loginInfo)
                .then(
                    (res) => {
                        let token = res.data.accessToken
                        let tokenType = res.data.tokenType
                        let userId = res.data.id
                        let LoginId = res.data.loginId
                        let firstTimeLogin = res.data.firstTimeLogin
                        let isProfileUpdated = res.data.profileUpdated
                        let customerId = res.data.customerId
                        let customerTypeId = res.data.customerType
                        let customerMobileNo = res.data.customerMobileNo
                        let supperUser = res.data.supperUser                        
                        localStorage.setItem('jwtToken', tokenType + " " + token)
                        localStorage.setItem('userId', userId)
                        localStorage.setItem('userIdentity', LoginId)
                        localStorage.setItem('customerIdentity', customerId)
                        localStorage.setItem('isProfileUpdated', isProfileUpdated)
                        localStorage.setItem('type', customerTypeId)
                        localStorage.setItem('status', supperUser)                        
                        localStorage.setItem('mobileNo', customerMobileNo)
                        localStorage.setItem('reloadCount', "firstTime")
                        localStorage.setItem('loginDash', "firstTime")

                        authToken(localStorage.jwtToken)

                        if (firstTimeLogin) {
                            this.props.history.push('/changePassword')
                        }
                        else if (!isProfileUpdated) {
                            NotificationManager.info("please updated your profile", `${process.env.REACT_APP_MSG_TITLE_INFO}`, 3000)
                            this.props.history.push(`/updateProfile?id=${customerId}`);
                        }
                        else {
                            this.props.history.push('/')
                        }
                         
                    },
                    (err) => {
                        formErrors.userOrPasswordError = err.response.data.error
                        this.setState({ isLoading: false})
                    })
                .catch(function (response) {
                    NotificationManager.error('Please Check Your Network or Contact System Administrator', `${process.env.REACT_APP_MSG_TITLE}`);
                });

        }
        this.setState({
            formErrors
        })
    };

    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'userName':
                // formErrors.userError =
                //     value.length === 0 || !loginIdRegEx.test(value) ? "LoginId Value sholud be a Phone number or Use name " : ""
                break;
            case 'password':
                // formErrors.passwordError =
                //     value.length == 0 || !passwordRegEx.test(value) ? name + " value sholud be a strong password that include Capital letter and a special characters with min 8" : ""
                break;
            default:

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    onChangeReceiverMobileNo = mobile => {
        const { formErrors } = this.state
        formErrors.userError =
            !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits" : ""
        this.setState({
            userName: mobile,
            formErrors
        })
    }

    redirectSignupInfo() {
        localStorage.setItem('path', "/signup")
    }

    redirectPasswordRecovery() {
        localStorage.setItem('path', "/passwordRecovery")
    }

    render() {
        const { formErrors, companyProfile , isLoading} = this.state

        return (
            <div >
                {/* <Menus/> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                    <div className='col-12 col-sm-8 col-md-6 col-lg-6 text-muted p-2'>
                                        <div className="d-flex justify-content-center">
                                        <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="SmartPay"
                                                                style={{ width: '30%', height: '10%', alignSelf: 'center' }}

                                                            />
                                        </div>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0 bg-blue ">
                                            <h6 className='d-flex justify-content-center' >Welcome to {companyProfile.companyName} </h6>
                                                        
                                                {/* <table style={{ border: 'none' }}>
                                                    <tr style={{ border: 'none' }}>
                                                        <td style={{ border: 'none', width: '30%', alignSelf: 'center' }}>
                                                            <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={{ width: '60%', height: '40%', alignSelf: 'center' }}

                                                            />
                                                        </td>
                                                        <td style={{ border: 'none' }}>
                                                            <h6 className='justify-content-left' >Welcome To {companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table> */}


                                            </div>
                                            <div className="card-body d-flex justify-content-center px-4 py-2 ">

                                                <form onSubmit={this.handleSubmit}>

                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 badge justify-content-left text-wrap" >
                                                        {formErrors.userOrPasswordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.userOrPasswordError}</span>
                                                        )}
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 input-group  justify-content-left">

                                                        {/* <div className="input-group-text  bg-transparent p-0">
                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div> */}
                                                        <div class="input-group-append p-0">
                                                            <div class="input-group-text border-left-0 border-radius-0 bg-transparent p-0">
                                                                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 input-group-append p-0">
                                                                    <PhoneInput countryCodeEditable={false} value={this.state.userName}
                                                                        country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                                                        inputStyle={{ width: '100%', height: 'auto' }}
                                                                        placeholder="Enter phone number" id='mobileNo'
                                                                        onChange={phone => this.onChangeReceiverMobileNo(phone)} />
                                                                </div> */}

                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 input-group-append p-0"  >
                                                                    <input type="loginId" className="form-control" id='userName'
                                                                        onChange={this.handelChange} onBlur={this.handelChange} name='userName'
                                                                        placeholder="Mobile # or Username" />
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 badge   justify-content-left text-wrap" >
                                                        {formErrors.userError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.userError}</span>
                                                        )}
                                                    </div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <div className=" col-12 col-sm-12 col-md-12 col-lg-12 input-group justify-content-left">

                                                        {/* <div className="col-2 col-sm-2 col-md-2 col-lg-2 input-group-text  bg-transparent p-0 " >
                                                            <FontAwesomeIcon icon={faKey} />
                                                        </div> */}
                                                        <div class="input-group-append p-0">
                                                            <div class="input-group-text border-left-0 border-radius-0 bg-transparent p-0"   >

                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 input-group-append p-0"  >
                                                                    <input type="password" className="form-control" id='Password'
                                                                        onChange={this.handelChange} onBlur={this.handelChange} name='password'
                                                                        placeholder="Password" />
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                   
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 badge justify-content-left text-wrap" >
                                                        {formErrors.passwordError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.passwordError}</span>
                                                        )}
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 input-group justify-content-left">
                                                        <p>
                                                            <a onClick={() => this.redirectPasswordRecovery().bind(this)} href={"/passwordRecovery"} >Forget your password?</a>
                                                        </p>
                                                    </div>
                                                    <div className="clearfix">&nbsp;</div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 justify-content-left">
                                                   
                                                    
                                                    <button disabled={isLoading} type="submit" title={"Signin"} 
                                                       className="form-control btn btn-sm btn-success " >
                                                         Signin
                                                         <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faSignIn} />
                                                            
                                                    </button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-8 col-md-6 col-lg-6 p-2'>
                                        <div className="card border-radius-0">
                                            <div className="card-body d-flex justify-content-center px-4 py-2 ">
                                                <div className="row  justify-content: space-between; ">
                                                    <strong>
                                                        New to SmartPay? <a onClick={() => this.redirectSignupInfo().bind(this)} href={"/signup"} >Create New Account</a>.
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                             content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                            </>}
                           />}
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default Login;