import API from '../../http-common';

class BankInfoService {
  // get all Cash Information 
  getBankInfos() {
    return API.get("/api/v1/getBankInfos");
  }

  // create  Cash Information 
  createBankInfo(customerBankInfo) {
    return API.post("/api/v1/createBankInfo", customerBankInfo);
  }

  // get single Cash Information 
  getBankInfo(id) {
    return API.get("/api/v1/getBankInfoById/" + id);
  }  

  // remove  Cash Information 
  deleteBankInfo(id) {
    return API.delete('/api/v1/deleteBankInfo' + '/' + id);
  }

  // update  Cash Method Information 
  updateBankInfo(id, customerBankInfo) {
    return API.put("/api/v1/updateBankInfo" +"/" + id, customerBankInfo);
  }
  
}


export default new BankInfoService()