import React, { Component } from 'react'
import { Link } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import SmsService from '../../../services/etopup/SmsService';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import PhoneInput from 'react-phone-input-2';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import NumberFormat from 'react-number-format';
import ReactLoading from "react-loading";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class smsNotificationList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            notificationsList: [],
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isLoading: false,
            // Customer Id of the loggedIn user
            isExpand: true,

            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachSmsNotificationsBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachSmsNotificationsBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachSmsNotificationsBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachSmsNotificationsBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachSmsNotificationsBasedOnFilter()
            this.render()
        }
    }

    clearValue() {
        // should not reload
        this.state.clear = 1
    }

    searchNotificationInfo(e) {
        this.seachSmsNotificationsBasedOnFilter()
    }
    seachSmsNotificationsBasedOnFilter() {

        SmsService.allNotification(
            this.state.pageNo, this.state.pageSize, localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    notificationsList: data.content,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1,
                    isLoading: true,
                });
                this.setState({ isLoading: true })
            
            });
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }



    componentDidMount() {
        this.seachSmsNotificationsBasedOnFilter();
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId
                });
                this.getAllTopupOrders(this.state.pageNo, this.state.pageSize, this.state.customerId, this.state.status, localStorage.userId)
            })
            .catch(e => {
                
            });
    }

    render() {
        const { notificationsList, formErrors, pageNo, isLoading, totalPage, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    {/* notificationsList.length > 0 && */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">SMS Notifications</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >SMS Notification List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>

                                                <div className="col-md-4">
                                                    <label htmlFor='fullName'>Full Name</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='fullName'
                                                            onChange={ e => this.state.fullName = e.target.value }
                                                            placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <br />
                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchNotificationInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/addSmsNotification', state: { type: "Agent", formName: "list Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addNotification }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>

                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Title</th>
                                                    <th>Sender Message</th>
                                                    <th>Receiver Message</th>
                                                    <th>Notification Type</th>
                                                    <th>Date </th>
                                                    <th>Actions </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { notificationsList !== undefined && notificationsList.map(list =>

                                                    <tr>
                                                        <td className='p-1 '>{ list.title }</td>
                                                        <td className='p-1 '>{ list.senderMessage }</td>
                                                        <td className='p-1 '>{ list.receiverMessage }</td>
                                                        <td className='p-1 '>{ list.trxnType }</td>
                                                        <td className='p-1 '>{ list.createdAt }</td>
                                                        <td className='p-1'>
                                                            { list.status === 0 &&
                                                                <button style={ { marginLeft: "2px" } } onClick={ () => this.approveOrder(list.id, list.parentId.id, list.dealerId.id, list.amount, list.providerId.id, localStorage.userId) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-check" title="Approve Order" /> </button>
                                                            }
                                                            { list.status === 1 &&
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-success btn-sm"><i className="nav-icon fa fa-check" title="Approved" /> </button>
                                                            }

                                                            <Link to={ { pathname: '/viewSmsNotification', state: { notificationId: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" title="View Detaile" /> </button>
                                                            </Link>
                                                            <Link to={ { pathname: '/editSmsNotification', state: { notificationId: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" title="Edit Message" /> </button>
                                                            </Link>
                                                            {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteOrder(list.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" title="Delete list" /> </button> */ }
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>

                    {/* { notificationsList.length < 1 &&
                        
                        <div class=" content-wrapper alert alert-danger alert-dismissible fade show" role="alert">
                            
                            <strong>Ohh Nope!</strong> Order not exist !
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    } */}
                </div>

            )
        }
    }
}
export default smsNotificationList