import React, { Component } from 'react'
import { Link, NavLink } from "react-router-dom";
import MenuService from '../../services/etopup/MenuService';
import SmartPayTechnology from '../../../src/images/SmartPayTechnology.png';
import LoginService from '../../services/common/LoginService';


function TopupMenu()  {
  // constructor (props) {
  //   super(props)
  //   this.state =
  //   {
  //     Menus: [],
  //     SubMenus: [],
  //     companyProfile:[],
  //     isExpand: 0
  //   };
  // }

  const [ menus, setMenus ] = React.useState([]);
  const [ subMenus, setSubMenus ] = React.useState([]);
  const [ companyProfile, setCompanyProfile ] = React.useState([]);

  React.useEffect(() => {
    
      LoginService.companyProfileInfo()
      .then(response => response.data)
      .then((data) => {
        setCompanyProfile(data)        
      });
  
      MenuService.getTopupMenus(localStorage.userId)
        .then(response => {
          setMenus(response.data)
          // localStorage.setItem('walletMenu', false)
          // 
        })
        .catch(e => {
          
        });
  
      MenuService.getTopupSubMenus(localStorage.userId)
        .then(response => {
          setSubMenus(response.data)
        
          // 
        })
        .catch(e => {
          
        });
    
      
    
  }, [])

  


    return (

      <div>
        {/* Main Sidebar Container */ }
        <aside className="main-sidebar sidebar-white nav-pills">
          {/* Brand Logo */ }
          <Link to="/etopupDashboard" className="brand-link">
            <img
             src={"data:image/png;base64," +  companyProfile.attachment} 
             alt="Smart Pay Logo"
             className="brand-image img-circle elevation-0"
             style={ { width: '25%', height: '20%', paddingTop: '2%',opacity: ".6"  } }
            />
            <span className="brand-text font-weight-white">eTop Up</span>
          </Link>
          {/* Sidebar */ }
          <div className="sidebar">
        
            {/* Sidebar Menu */ }
            <nav className="mt-2 nav navbar-nav navbar-left" >
              <ul  className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="true">

                { menus.map(menu =>                   
                  <li key={menu.menuname}  className="nav-item">         
                    <Link key={menu.appmenuId + menu.menuname } to={ { pathname: menu.url } } 
                       className="nav-link bg-light active elevation-0">
                      <i className={ menu.styleClass } />
                      <p className="col-sm-5 pl-3">
                        { menu.menuname }
                      </p>  
                      <i className="right fas fa-angle-right fa-rotate-90" />
                    </Link>
                    
                    { subMenus.map(submenu =>
                      <ul  className="nav nav-treeview ml-3" key={ submenu.menuname }>
                        { menu.appmenuId === submenu.parentId &&
                          <li className="nav-item">
                            <Link key={ submenu.appmenuId + submenu.menuname } to={ { pathname: submenu.url, state: { fromDashboard: true } } } className="nav-link bg-light  active elevation-0">
                              <i className={ submenu.styleClass } />
                              <p className="col-sm-5 pl-3">
                                { submenu.menuname }</p>
                            </Link>
                          </li>
                        }
                      </ul>
                             
                    ) }
                    
                    

                  </li>
                ) }
              </ul>
            </nav>


            {/* /.sidebar-menu */ }
          </div>
          {/* /.sidebar */ }
        </aside>
      </div>

    )
  }

export default TopupMenu
