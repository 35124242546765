import API from '../../../src/http-common';

class AgentService {
    getAgents(userId) {
        return API.get('/api/v1/getAllAgents/' + userId);
    }
    // getMerchantByTel(mobileNo)
    // {
    //     return API.get('/api/v1/getMarchantByTel/'+mobileNo);
    // }

    // createMerchant(merchant) {
    //     return API.post("/api/v1/createMerchant", merchant);
    // }
    // deleteMerchant(mobileNo) {
    //     return API.delete("/api/v1/deleteMerchant/" + mobileNo);
    // }
    // updateMerchant(merchant) {
    //     return API.put("api/v1/updateMerchant", merchant);
    // }
}
export default new AgentService()