import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AsyncSelect from 'react-select/async'
import 'react-notifications/lib/notifications.css';
import CommissionTypeService from '../../../services/common/CommissionTypeService';
import CommissionService from '../../../services/etopup/CommissionService';
import CustomerDealerService from '../../../services/etopup/CustomerDealerService';
import API from '../../../http-common';
import CustomerService from '../../../services/etopup/CustomerService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

class AddCustomerDealer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      parentDealerCustomerIds: [],
      dealerCustomerIds: [],
      commissionReateIds: [],
      commissionReateId: "",
      commissionTypeIds: [],
      commissionTypeId: '',
      rateValue: '',
      formErrors: {
        commisionRangeError: "",
        dealerCustomerError: "",
        commissionTypeError: "",
        parentCustomerError: ""
      },
      customerList: [],
      selectOption: null,
      customerDealerId: null,
      selecteDealerIds: null,
      customerId: '',
      fullName: '',
      customerType: '',
      customerTypeSlectedCustomer:''
    }
  this.commissionTypeChange=this.commissionTypeChange.bind(this)
  }

  fetchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id,isFixed: false, })
            });
            callback(temp)
            this.setState({
              customerList: data,
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    const { formErrors } = this.state
    formErrors.parentCustomerError = selectOption == "" || selectOption == null ? 'You must make a selection' : "";

    if (selectOption !== null || selectOption !== "") {     
      this.getCommission(selectOption.value, localStorage.userId)
      this.getCustomerInfo(selectOption.value,localStorage.userId)
      this.getSubDealerInfo(selectOption.value);
    }

    this.setState({
      selectOption: selectOption,
      customerDealerId: selectOption && selectOption.value,
      formErrors
    });

  }

  fetchDealerCustomerData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {        
        API.get("/api/v1/findCustomersWithSimilarTypeBySearchValue/" + inputValue + "/" + this.state.customerDealerId + "/" + localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data,
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onDealerSearchChange = (selecteDealerIds) => {
    const { formErrors } = this.state

    formErrors.dealerCustomerError = selecteDealerIds == "" || selecteDealerIds == null ? 'You must make a selection' : "";
    this.setState({
      selecteDealerIds: selecteDealerIds,
      formErrors
    });

  }

  getCommission(id) {
    this.state.commissionReateIds = []
    CommissionService.getCommissionModelByCustomerId(id, localStorage.userId)
      .then(response => {
        this.setState({
          commissionReateIds: response.data
        });
        
      })
      .catch(e => {
        
      });
  }

  componentDidMount() {
    this.getCommission(localStorage.customerIdentity, localStorage.userId)
    this.getSubDealerInfo(localStorage.customerIdentity)
    CommissionTypeService.getCommissionTypes(localStorage.userId)
      .then(response => {
        this.setState({
          commissionTypeIds: response.data
        });
        
      })
      .catch(e => {
        
      });

    CustomerService.getCustomerModelById(localStorage.customerIdentity, localStorage.userId)
    .then(response => {
        this.setState({
          fullName: response.data.fullName,
          customerTypeSlectedCustomer:response.data.type,
          customerType: response.data.type,
          customerDealerId: localStorage.customerIdentity
        });
      })
      .catch(e => {
      });

  }

  getSubDealerInfo(customerId){
    this.state.commissionTypeId = ""
    CustomerDealerService.getSubDealerInfo(customerId, localStorage.userId)
      .then(response => {
       
       let  commissionTypeId = response.data.commissionTypeId
       this.state.formErrors.commissionTypeError = commissionTypeId === undefined && this.state.customerTypeSlectedCustomer !==  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? 'Provide commmision type' : "";

        this.setState({          
          commissionTypeId: response.data.commissionTypeId,
          
        });
      })
      .catch(e => {
        
      });
  }

  getCustomerInfo(customerId, userId){
    CustomerService.getCustomerModelById(customerId, userId)
    .then(response => {
        this.setState({
          customerTypeSlectedCustomer: response.data.type
        });
      })
      .catch(e => {
      });
  }
  commissionRageChange = e => {
    const { formErrors } = this.state
    let selectedCommissionRateId = e.target.value
    this.state.commissionReateId = ""
    if (selectedCommissionRateId === "----please select---") {
      selectedCommissionRateId = ""
    } else {

      CommissionService.getCommissionModelById(selectedCommissionRateId, localStorage.userId)
        .then(response => {
          this.setState({
            rateValue: response.data.commissionPercent
          });
        })
        .catch(e => {
          
        });
    }

    formErrors.commisionRangeError = selectedCommissionRateId == "" ? 'You must make a selection' : "";

    this.setState({
      commissionReateId: selectedCommissionRateId,
      formErrors
    });

  }

  commissionTypeChange(e) {
    const { formErrors } = this.state
    let selectedCommissionTypeId = e.target.value
    this.state.commissionTypeId = ""
    if (selectedCommissionTypeId == "----please select---") selectedCommissionTypeId = ""

    formErrors.commissionTypeError = selectedCommissionTypeId == "" ? 'You must make a selection' : "";

    this.setState({
      commissionTypeId: selectedCommissionTypeId,
      formErrors
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { commissionTypeId, commissionReateId, selectOption, selecteDealerIds, formErrors } = this.state
    formErrors.commissionTypeError = commissionTypeId == '' || commissionTypeId == null || commissionTypeId == undefined  ? 'Provide commmision type' : this.state.formErrors.commissionTypeError;
    formErrors.commisionRangeError = commissionReateId == '' || commissionReateId == null || commissionReateId == undefined ? 'You must make a selection' : this.state.formErrors.commisionRangeError;
    formErrors.parentCustomerError = selectOption == '' || selectOption == null || selectOption == undefined ? 'You must make a selection' : this.state.formErrors.parentCustomerError ;
    formErrors.dealerCustomerError = selecteDealerIds == '' || selecteDealerIds == null || selecteDealerIds == undefined ? 'You must make a selection' : this.state.formErrors.dealerCustomerError;


    if (formValid(this.state.formErrors)) {
      const formData = new FormData();
      formData.append("parentDealerId", this.state.selectOption && this.state.selectOption.value)
      formData.append("subDealerId", this.state.selecteDealerIds && this.state.selecteDealerIds.value)
      formData.append("commissionRate", this.state.commissionReateId)
      formData.append("commissionType", this.state.commissionTypeId.id === undefined ? this.state.commissionTypeId : this.state.commissionTypeId.id )
      formData.append("userId", localStorage.userId)

      this.setState({ isLoading: true})  
      CustomerDealerService.createCustomerDealers(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})  
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})  
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoading: false})  
        });
        this.setState({
          formErrors
        })
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };



  cancel() {
    this.props.history.push('/dealersList')
  }


  render() {
    const { customerType, customerTypeSlectedCustomer, fullName, selectOption, commissionReateIds, commissionTypeIds, rateValue, formErrors } = this.state

    if (selectOption === null && fullName !== '') {

      this.state.selectOption = {
        label: fullName,
        value: localStorage.customerIdentity
      }


    }

    let commissionType = commissionTypeIds.length > 0
      && commissionTypeIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>

        )
      }, this);


    let commissionRate = commissionReateIds.length > 0
      && commissionReateIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.commissionAssignment }</option>
        )
      }, this);

    return (

      <div >
        {/* <Header />
        <TopupMenu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Customer Dealer Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Customer Dealer Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit } noValidate>

                      { customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                        <div>
                          <label htmlFor='CustomerList'>Customer :</label>
                          <div style={ { fontSize: 'smaller' } }>
                            <AsyncSelect id='CustomerList'
                              className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                              placeholder={ "Search by name, account no or mobile number" }
                              value={ this.state.selectOption }
                             // isMulti={true}
                              isClearable={ true }
                              loadOptions={ this.fetchData }
                              isLoading={ this.state.selectOption === {} &&
                                this.state.selectOption === undefined &&
                                this.state.selectOption === null ? true : false
                              }
                              onChange={ (e) => {
                                this.onSearchChange(e)
                              } }
                              defaultOptions={ false }
                            />
                            { formErrors.parentCustomerError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.parentCustomerError }</span>
                            ) }

                          </div>

                        </div>

                        :
                        <div>
                          <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor='Name'>Dealer Customer:</label>
                            <input type="text" className="form-control " id='Name'
                              placeholder="Customer " disabled={ true }
                              value={ this.state.fullName }
                            />
                          </div>
                        </div>

                      }

                      <div >
                        <label htmlFor='DealerCustomerList'>Sub Dealer Customer :</label>
                        <div style={ { fontSize: 'smaller' } }>

                          <AsyncSelect id='DealerCustomerList'
                            className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                            placeholder={ "Search by name, account no or mobile number" }
                            value={ this.state.selecteDealerIds }
                            isClearable={ true }
                            loadOptions={ this.fetchDealerCustomerData }
                            isLoading={ this.state.selecteDealerIds === {} &&
                              this.state.selecteDealerIds === undefined &&
                              this.state.selecteDealerIds === null ? true : false
                            }

                            onChange={ (e) => {
                              this.onDealerSearchChange(e)
                            } }
                            defaultOptions={ false }
                          />
                        </div>
                        { formErrors.dealerCustomerError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.dealerCustomerError }</span>
                        ) }

                      </div>
                   { customerTypeSlectedCustomer === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='CommissionTypeist'>Commission Type:</label>
                        <select className="form-control "
                          value={ this.state.commissionTypeId && this.state.commissionTypeId.id }
                          onChange={this.commissionTypeChange} id='CommissionTypeist'>
                          <option key={ null } value={ null }>----please select---</option>
                          { commissionType }
                        </select>
                        {/* <input type='hidden' value={this.state.bankInfoId.id} name='bankInfoId' onChange={this.handelChange}/> */ }
                        { formErrors.commissionTypeError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.commissionTypeError }</span>
                        ) }
                      </div>
                      :
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                      <label htmlFor='CommissionType'>Commission Type:</label>
                       < input  type={'text'} className="form-control "  disabled={ true } id='CommissionType' 
                       value={ this.state.commissionTypeId && this.state.commissionTypeId.name }/>
                       { formErrors.commissionTypeError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.commissionTypeError }</span>
                        ) }
                      </div>
  }

                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='CommissionRateist'>Commission Rate:</label>
                        <select className="form-control "
                          value={ this.state.commissionReateId && this.state.commissionReateId.id }
                          onChange={ this.commissionRageChange } id='CommissionRateist'>
                          <option key={ null } value={ null }>----please select---</option>
                          { commissionRate }
                        </select>
                        {/* <input type='hidden' value={this.state.bankInfoId.id} name='bankInfoId' onChange={this.handelChange}/> */ }
                        { formErrors.commisionRangeError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.commisionRangeError }</span>
                        ) }
                      </div>

                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label >Rate in %:</label>
                        <input type="text" disabled={ true } id='Rate' value={ rateValue } />
                      </div>

                      <div className="row  d-flex justify-content-left ">
                        <button type="submit" disabled={this.state.isLoading}
                          className="btn btn-success px-4 py-2" style={ { marginLeft: "30px" } }>Save</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

              <NotificationContainer />

              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}



export default AddCustomerDealer;