import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Link } from 'react-router-dom';
import LoginService from '../../../../services/common/LoginService';
import PasswordRecoveryService from '../../../../services/common/PasswordRecoveryService';
import ReactLoading from "react-loading";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\\/ ]{3,20}$/u)
// const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\\/ ]{0,20}$/u)
const emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class EmailPasswordRecovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            isLoading: true,
            companyProfile: [],           
            formErrors: {               
                emailError: ""
            },
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        const { email,  formErrors } = this.state
        formErrors.emailError = email == '' || email == null ? "email is Required" : this.state.formErrors.emailError;
      
        if (formValid(this.state.formErrors)) {
            let loginId = new FormData();
            loginId.append('loginId', email);
            loginId.append('email', email);

            PasswordRecoveryService.createPasswordRecoveryInfo(loginId)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SEND_OTPVALUE}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.props.history.push('/confirmation')
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });

        }
        this.setState({
            formErrors
        })
    };

    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {            
            case 'email':
                formErrors.emailError = ""
                if (value.length != 0) {
                    formErrors.emailError =
                        !emailRegEx.test(value) ? " insert a valid  " + name : ""
                }
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    onChangeReceiverMobileNo = mobile => {
        const { formErrors } = this.state
        formErrors.mobileNoError =
            !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites" : ""
        this.setState({
            mobileNo: mobile,
            formErrors
        })
    }
    componentDidMount() {
        LoginService.companyProfileInfo()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    companyProfile: data
                });
            });
    }

    redirectConfirmation() {
        const { email,  formErrors } = this.state
        formErrors.emailError = email == '' || email == null ? "Email is Required" : this.state.formErrors.emailError;

        if (formValid(this.state.formErrors)) {
            this.state.isLoading = false;
            let loginId = new FormData();
            loginId.append('loginId', email);
            loginId.append('email', email);

            PasswordRecoveryService.createPasswordRecoveryInfo(loginId)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SEND_OTPVALUE}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.state.isLoading = true;
                        localStorage.setItem('path', "/confirmation")
                        this.props.history.push({ pathname: '/confirmation', state: { mobileNo: this.state.email } })
                  
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                });
                this.setState({
                    isLoading: this.state.isLoading
                })

        }
        this.setState({
            formErrors
        })
    }

    render() {
        const { formErrors, isLoading, companyProfile } = this.state

        return (
            <div >
                <NotificationContainer />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                    <div className='col-12 col-sm-8 col-md-6 col-lg-8 bg-light p-4'>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0  bg-blue">

                                                <table style={{ border: 'none' }}>
                                                    <tr style={{ border: 'none' }}>
                                                        <td style={{ border: 'none', width: '30%', alignSelf: 'center' }}>
                                                            <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={{ width: '60%', height: '40%', alignSelf: 'center' }}

                                                            />
                                                        </td>
                                                        <td style={{ border: 'none' }}>
                                                            <h6 className='justify-content-left' >Welcome To {companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table>


                                            </div>
                                            <div className="card-body justify-content-center px-4 py-2 ">
                                            <div className='justify-content-center'>
                                                    <h6 className="text-center" >Get Your Password</h6>
                                                </div>
                                            {isLoading ?
                                                    <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                        <label htmlFor='email'>Enter your Email:</label>
                                                        <input type="text" className="form-control " id='email'
                                                            name='email'
                                                            onChange={this.handelChange}
                                                            onBlur={this.handelChange} />

                                                        {formErrors.emailError.length > 0 && (
                                                            <span style={{ color: "#F61C04" }}>{formErrors.emailError}</span>
                                                        )}
                                                    </div>
                                                    :
                                                    <div >
                                                        <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                                                    </div>
                                                }
                                                    
                                                    <div className="clearfix">&nbsp;</div>

                                                    <div className="row float-right justify-content-right: space-between; ">
                                                        <Link type='submit' to={{
                                                            pathname: '/confirmation',
                                                            state: { mobileNo: this.state.mobileNo }
                                                        }}>
                                                            {'Next'}
                                                        </Link>

                                                    </div>
                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default EmailPasswordRecovery;