import React, { Component } from 'react'
import Header from '../common/Header'
import PurchaseOrderService from '../../services/etopup/PurchaseOrderService';
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import moment from 'moment'
import ProviderService from '../../services/etopup/ProviderService';
import PartnerTargetService from '../../services/etopup/PartnerTargetService';
import CustomerService from '../../services/etopup/CustomerService';
import TopupTransactionService from '../../services/etopup/TopupTransactionService';
import Moment from 'react-moment';
import TopupOrderService from '../../services/etopup/TopupOrderService';
import TopupSalesHistoryService from '../../services/etopup/TopupSalesHistoryService';
import TopupMenu from './TopupMenu';
import ReactLoading from "react-loading";
import CustomersService from '../../services/common/CustomersService';
import LoadingPopup from '../wallet/LoadingPopup';



export default class TopupDashboard extends Component {
  constructor (props) {

    super(props)
    this.state = {
      isLoading: false,
      month: new Date(),
      date: new Date(),
      mtnMonthlySales: 0,
      etisalatMonthlySales: 0,
      roshanMonthlySales: 0,
      salaamMonthlySales: 0,
      KYDC_AWCCMonthlySales: 0,

      mtnDailySales: 0,
      etisalatDailySales: 0,
      roshanDailySales: 0,
      salaamDailySales: 0,
      KYDC_AWCCDailySales: 0,

      monthlyWalletToTopupTransfers: [],
      displayAllDealersTopupTransfersLoading:true,
      numberOfDealers: [],
      MtnBalance: {},
      EtisalatBalance: {},
      RoshanBalance: {},
      SalaamBalance: {},
      totalTarget: 0,
      count: 0,
      totatTargetAchievd: 0,
      totalAvailableBalance: 0,
      sumOfProvidersMonthlySales: [],
      sumOfProvidersDailySales: [],
      totalMerchants: 0,
      totalTopupUsers: [],
      recentTopupTrxns: [],
      pendingTopupOrders: [],
      pendingPurchaseOrders: [],
      dailyTopupUsers: [],
      numberOfCustomers: [],
      providersMonthlySales: [],
      providersDailySales: [],
      totalDailyTopupTrxns: [],
      allDealersTopupTransfers: [],
      
      customerType:'',
      mtnLogo: null,
      EtisalatLogo: null,
      SalaamLogo: null,
      RoshanLogo: null,
      COMVIVALogo: null,

      mtnTarget: 0,
      EtisalatTarget: 0,
      SalaamTarget: 0,
      RoshanTarget: 0,
      COMVIVATarget: 0,

      mtnTargetAchieved: 0,
      EtisalatTargetAchieved: 0,
      SalaamTargetAchieved: 0,
      RoshanTargetAchieved: 0,
      COMVIVATargetAchieved: 0,

      mtnBalance: 0,
      EtisalatBalance: 0,
      SalaamBalance: 0,
      RoshanBalance: 0,
      COMVIVABalance: 0,

      providersList: [],
      partnerMonthlyTargets: [],
      PartnerAvailableBalance: [],
      monthlyPartnerTargetAndBalanceList: [],

      displayRecentTopupTrxns: true,
      displayNumberOfDealers: true,
      displayNumberOfDealersLoading: true,
      displayAllDealersTopupTransfers: true,
      displaySumOfProvidersMonthlySales: true,
      displayProvidersMonthlySales: true,
      displayTotalTopupUsers: true,
      displayNumberOfCustomers: true,
      displayPendingPurchaseOrders: true,
      displayPendingTopupOrders: true,
      displayProvidersList: true,
      displayPartnerMonthlyTargets: true,
      displayPartnerAvailableBalance: true,
      displayRecentTopupTrxns: true,
      displayDailyTopupUsers: true,
      displayTotalDailyTopupTrxns: true
    }

  }

  componentDidMount() {

    const reloadCount = localStorage.getItem('reloadCount');
    if(reloadCount == "firstTime") {
      localStorage.removeItem('reloadCount');
      window.location.reload();
    } 
    
    this.setState({ isLoading: true})
 
 if(localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`){
  
    // {/*Get number of main dealers, sub dealers and retailers*/ }
    CustomersService.findCustomersByCustomerType(localStorage.userId)
      .then(response => {
        this.setState({
          displayNumberOfDealersLoading: false,
          numberOfDealers: response.data
        });
        
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displayNumberOfDealers: false})
        }
      })
      .catch(e => {
        
      });

    {/*All Dealers topup transfr*/ }
    TopupTransactionService.dealersTopupTrxn(moment(this.state.date).format('yyyy-MM-DD'), localStorage.userId)
      .then(response => {
        this.setState({
          displayAllDealersTopupTransfersLoading: false,
          allDealersTopupTransfers: response.data
        });
        
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displayAllDealersTopupTransfers: false})
        }
      })
      .catch(e => {
        
      });

    {/*Total providers monthly sales*/ }
    TopupSalesHistoryService.sumOfProvidersMonthlySales(localStorage.userId)
      .then(response => {
        this.setState({
          sumOfProvidersMonthlySales: response.data         
        });
        
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displaySumOfProvidersMonthlySales: false})
        }
      })
      .catch(e => {
        
      });   

      {/*Total providers daily sales*/ }
    TopupSalesHistoryService.sumOfProvidersDailySales(localStorage.userId)
    .then(response => {
      this.setState({
        sumOfProvidersDailySales: response.data         
      });
      
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displaySumOfProvidersMonthlySales: false})
      }
    })
    .catch(e => {
      
    });   

    {/*Get providers monthly sales*/ }
    TopupSalesHistoryService.getProvidersMonthlySales(localStorage.userId)
      .then(response => {
        this.setState({
          providersMonthlySales: response.data
        });
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displayProvidersMonthlySales: false})
        }
      })
      .catch(e => {
      });

        {/*Get providers daily sales*/ }
    TopupSalesHistoryService.getProvidersDailySales(localStorage.userId)
    .then(response => {
      this.setState({
        providersDailySales: response.data
      });
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayProvidersMonthlySales: false})
      }
    })
    .catch(e => {
    });

      
    // {/*To get list of topup users*/ }
    // CustomerService.getAllTopupCustomers(localStorage.userId)
    //   .then(response => {
    //     this.setState({
    //       totalTopupUsers: response.data
    //     });
        
    //   },
    //   (err) => {
    //     if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
    //       this.setState({ displayTotalTopupUsers: false})
    //     }
    //   })
    //   .catch(e => {
        
    //   });
    // {/*To get list of All customers */ }
    CustomerService.getCountOfAllCustomers(localStorage.userId)
      .then(response => {
        this.setState({
          numberOfCustomers: response.data
        });
        this.setState({ isLoading: false})
      },
      (err) => {
        this.setState({ isLoading: false})
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displayNumberOfCustomers: false})
        }
      })
      .catch(e => {
        
      });
        {/*Pending purchase orders*/ }
    PurchaseOrderService.getAllPendingPurchaseOrders(localStorage.userId)
    .then(response => {
      this.setState({
        pendingPurchaseOrders: response.data
      });
      
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayPendingPurchaseOrders: false})
      }
    })
    .catch(e => {
      
    });

  // {/*Pending topup orders*/ }
  TopupOrderService.getAllPendingTopupOrders(localStorage.userId)
    .then(response => {
      this.setState({
        pendingTopupOrders: response.data
      });
      
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayPendingTopupOrders: false})
      }
    })
    .catch(e => {
      
    });

  {/*To get list of providers*/ }
  ProviderService.getAllProviders(localStorage.userId)
    .then(response => {
      this.setState({
        providersList: response.data
      });
      
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayProvidersList: false})
      }
    })
    .catch(e => {
      
    });

    
  {/*To get list of partner monthly targets*/ }
  PartnerTargetService.getMonthlyPartnerTarget(moment(this.state.month).format('yyyy-MM-DD'), localStorage.userId)
    .then(response => {
      this.setState({
        partnerMonthlyTargets: response.data
      });
      
    },
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayPartnerMonthlyTargets: false})
      }
    })
    .catch(e => {
      
    });
    
  {/*Get partner available balance*/ }
  PurchaseOrderService.getPurchaseOrderBalance(localStorage.userId)
    .then(response => {
      this.setState({
        PartnerAvailableBalance: response.data
      });
      this.setState({ isLoading: false})
    },
    (err) => {
      this.setState({ isLoading: false})
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayPartnerAvailableBalance: false})
      }
    })
    .catch(e => {
      
    });
    }

    // {/*Recent topup trxns*/ }
    TopupTransactionService.recentTopupTrxns(localStorage.customerIdentity, localStorage.userId)
      .then(response => {
        this.setState({
          recentTopupTrxns: response.data
        });
        this.setState({ isLoading: false})   
      },
      (err) => {
        if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
          this.setState({ displayRecentTopupTrxns: false})
        }
      })
      .catch(e => {
        
      });

//     {/*To get list of all daily topup users*/ }
//     CustomerService.getDailyTopupCustomers(moment(this.state.date).format('yyyy-MM-DD'), localStorage.userId)
//       .then(response => {
//         this.setState({
//           dailyTopupUsers: response.data
//         });
        
//       },
//       (err) => {
//         if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
//           this.setState({ displayDailyTopupUsers: false})
//         }
//       })
//       .catch(e => {
        
//       });

//   {/*Total number of daily topup trxns*/ }
//  TopupSalesHistoryService.dailyTopupTrxns(moment(this.state.date).format('yyyy-MM-DD'), localStorage.userId)
//  .then(response => {
//    this.setState({
//      totalDailyTopupTrxns: response.data     
//    });
//    this.setState({ isLoading: false})   
//   },
//   (err) => {
//     this.setState({ isLoading: false})
//     if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
//       this.setState({ displayTotalDailyTopupTrxns: false})
//     }
//   })
//  .catch(e => {
   
//  });
  

  }


  render() {
    const { numberOfDealers, recentTopupTrxns, pendingTopupOrders, pendingPurchaseOrders, 
             dailyTopupUsers, numberOfCustomers, providersMonthlySales, providersDailySales, totalDailyTopupTrxns, allDealersTopupTransfers } = this.state
    const { sumOfProvidersMonthlySales, sumOfProvidersDailySales } = this.state
    const {  providersList, partnerMonthlyTargets, PartnerAvailableBalance } = this.state;
     const { totalTopupUsers , customerType,monthlyWalletToTopupTransfers,isLoading} = this.state
   

    if(this.state.displayPartnerAvailableBalance){
    for (const partner of PartnerAvailableBalance) {
      //console.log(partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`)
      if (partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_MTN}`) {
        this.state.mtnBalance = partner.balance
      }
      if (partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ETISALAT}`) {
        this.state.EtisalatBalance = partner.balance
      }
      if (partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_SALAAM}`) {
        this.state.SalaamBalance = partner.balance
      }
      if (partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ROSHAN}`) {
        this.state.RoshanBalance = partner.balance
      }
      if (partner.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`) {
        this.state.COMVIVABalance = partner.balance
      }
    }
  }

  if(this.state.displayProvidersList){
    for (const obj of providersList) {
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_MTN}`) {
        this.state.mtnLogo = obj.logo
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ETISALAT}`) {
        this.state.EtisalatLogo = obj.logo
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_SALAAM}`) {
        this.state.SalaamLogo = obj.logo
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ROSHAN}`) {
        this.state.RoshanLogo = obj.logo
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`) {
        this.state.COMVIVALogo = obj.logo
      }
    }
  }

  if(this.state.displayProvidersList){
    for (const val of partnerMonthlyTargets) {

      if (val.providerName === `${process.env.REACT_APP_PROVIDER_NAME_MTN}`) {
        this.state.mtnTarget = val.target
        this.state.mtnTargetAchieved = val.targetAchieved
      }
      if (val.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ETISALAT}`) {
        this.state.EtisalatTarget = val.target
        this.state.EtisalatTargetAchieved = val.targetAchieved
      }
      if (val.providerName === `${process.env.REACT_APP_PROVIDER_NAME_SALAAM}`) {
        this.state.SalaamTarget = val.target
        this.state.SalaamTargetAchieved = val.targetAchieved
      }
      if (val.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ROSHAN}`) {
        this.state.RoshanTarget = val.target
        this.state.RoshanTargetAchieved = val.targetAchieved
      }
      if (val.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`) {
        this.state.COMVIVATarget = val.target
        this.state.COMVIVATargetAchieved = val.targetAchieved
      }
    }
    this.state.totalTarget = +this.state.mtnTarget + +this.state.EtisalatTarget + +this.state.SalaamTarget + +this.state.RoshanTarget + +this.state.COMVIVATarget;
    this.state.totatTargetAchievd = +this.state.mtnTargetAchieved + +this.state.EtisalatTargetAchieved + +this.state.SalaamTargetAchieved + +this.state.RoshanTargetAchieved + +this.state.COMVIVATargetAchieved;
    this.state.totalAvailableBalance = +this.state.mtnBalance + +this.state.EtisalatBalance + +this.state.SalaamBalance + +this.state.RoshanBalance + +this.state.COMVIVABalance;
  }
  if(this.state.displayNumberOfCustomers){
    for (const obj of numberOfCustomers) {
      if (obj.type === `${process.env.REACT_APP_CUSTOMER_TYPE_MERCHANT}`) {
        this.state.totalMerchants = +1;
      }
    }
  }
  if(this.state.displayProvidersMonthlySales){
    for (const obj of providersMonthlySales) {
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_MTN}`) {
        this.state.mtnMonthlySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ETISALAT}`) {
        this.state.etisalatMonthlySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ROSHAN}`) {
        this.state.roshanMonthlySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_SALAAM}`) {
        this.state.salaamMonthlySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`) {
        this.state.KYDC_AWCCMonthlySales = obj.amount
      }
    }
  }

  if(this.state.displayProvidersMonthlySales){
    for (const obj of providersDailySales) {
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_MTN}`) {
        this.state.mtnDailySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ETISALAT}`) {
        this.state.etisalatDailySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_ROSHAN}`) {
        this.state.roshanDailySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_SALAAM}`) {
        this.state.salaamDailySales = obj.amount
      }
      if (obj.providerName === `${process.env.REACT_APP_PROVIDER_NAME_COMVIVA}`) {
        this.state.KYDC_AWCCDailySales = obj.amount
      }
    }
  }
    return (
      <div>
         {/* <Header/>
         <TopupMenu/> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white" >

          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>
                {/* /.col */ }
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
                {/* /.col */ }
              </div>
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>
          {/* /.content-header */ }
          {/* Main content */ }
          <section className="content">
            <div className="container-fluid">
            {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && 
              this.state.displayPartnerMonthlyTargets ?
              <div className="row ">
                
                <div className="col-lg-4 col-4">
                  <div className="small-box">
                    
                    <div className='div-providers-logo'>
                      <img id="logo" className="mx-auto d-block img-providers-logo" src={ "data:image/png;base64," + this.state.mtnLogo }></img>
                    </div>
                    <div >
                      <div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Target </div>
                          <div className="col-lg-6 col-6 dashboard-label"> Target Achieved </div>
                        </div>
                        <div className='row pl-1 pt-0 mt-0' >
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.mtnTarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.mtnTargetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Avail Balance </div>
                          <div className="col-lg-6 col-6">  </div>
                        </div>
                        <div className='row pl-1 mt-0'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.mtnBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="icon">
                      <i className="ion ion-bag" />
                    </div> */}
                    <Link to="/partnerTargetList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
            

                <div className="col-lg-4 col-4">
                  <div className="small-box">
                    <div className="div-providers-logo">
                      <img id="logo" className="mx-auto d-block img-providers-logo" src={ "data:image/png;base64," + this.state.EtisalatLogo }></img>
                    </div>
                    <div >
                      <div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Target </div>
                          <div className="col-lg-6 col-6 dashboard-label"> Target Achieved </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.EtisalatTarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.EtisalatTargetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Avail Balance </div>
                          <div className="col-lg-6 col-6">  </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.EtisalatBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                      </div>
                    </div>
                    <Link to="/partnerTargetList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="small-box">
                    <div className="div-providers-logo">
                      <img id="logo" className="mx-auto d-block img-providers-logo"  src={ "data:image/png;base64," + this.state.SalaamLogo }></img>
                    </div>
                    <div >
                      <div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Target </div>
                          <div className="col-lg-6 col-6 dashboard-label"> Target Achieved </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.SalaamTarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.SalaamTargetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Avail Balance </div>
                          <div className="col-lg-6 col-6">  </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.SalaamBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                      </div>
                    </div>
                    <Link to="/partnerTargetList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
              </div>
              :
              ""
          }
    {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && 
     this.state.displayPartnerMonthlyTargets ?
              <div className="row ">
                <div className="col-lg-4 col-4">
                  <div className="small-box">
                    <div className="div-providers-logo">
                      <img id="logo" className="mx-auto d-block img-providers-logo"  src={ "data:image/png;base64," + this.state.RoshanLogo }></img>
                    </div>
                    <div >
                      <div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Target </div>
                          <div className="col-lg-6 col-6 dashboard-label"> Target Achieved </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.RoshanTarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.RoshanTargetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Avail Balance </div>
                          <div className="col-lg-6 col-6">  </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.RoshanBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                      </div>
                    </div>
                    <Link to="/partnerTargetList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="small-box">
                    <div className="div-providers-logo">
                      <img id="logo" className="mx-auto d-block img-providers-logo"  src={ "data:image/png;base64," + this.state.COMVIVALogo }></img>
                    </div>
                    <div >
                      <div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Target </div>
                          <div className="col-lg-6 col-6 dashboard-label"> Target Achieved </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.COMVIVATarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.COMVIVATargetAchieved.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-label"> Avail Balance </div>
                          <div className="col-lg-6 col-6">  </div>
                        </div>
                        <div className='row pl-1'>
                          <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.COMVIVABalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        </div>
                      </div>
                    </div>
                    <Link to="/partnerTargetList" className="small-box-footer bg-light text-dark dashboard-label">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="small-box bg-white">
                    {/* <div className="inner"> */ }

                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" 
                        style={ { fontWeight: "lighter", textAlign: 'center' } }> 
                        Sum of All Providers Trxns 
                        </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-6 col-6 dashboard-label"> T. Target </div>
                        <div className="col-lg-6 col-6 dashboard-label"> T.Target Achieved </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.totalTarget.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.totatTargetAchievd.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-6 col-6 dashboard-label"> T.Avail balance </div>
                        <div className="col-lg-6 col-6 dashboard-label">  </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-6 col-6 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.totalAvailableBalance.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                    </div>


                    {/* <div className="icon">
                           <i className="ion ion-bag" />
                         </div> 
                    */}
                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                       </Link> 
                  */}
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  {/* small box */ }
                  <div className="small-box bg-white">
                    <div className='row '><div className='ml-5 center dashboard-label'> Dealers Category By No. </div></div>
                   
                    {this.state.displayNumberOfDealersLoading ?

                      <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />

                    :
                    
                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Main  </div>
                        <div className="col-lg-4 col-4 dashboard-label"> #Sub  </div>
                        <div className="col-lg-4 col-4 dashboard-label"> #Retailers </div>
                      </div>

                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> { numberOfDealers.mainDealersCount }</div>
                        <div className="col-lg-4 col-4 dashboard-balance"> { numberOfDealers.subDealersCount } </div>
                        <div className="col-lg-4 col-4 dashboard-balance"> { numberOfDealers.retailersCount } </div>
                      </div>
                    </div>
                    }
                  
                    {/* <div className="icon">
                      <i className="ion ion-person-add" />
                    </div> */}
                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
              </div>
                  :
                  ""
              }
              {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
              <div className="row ">
                <div className="col-lg-4 col-6 ">
                  <div className="small-box bg-white">
                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" style={ {textAlign: 'center' } }> Main Dealers Monthly Topup Transfer </div>
                      </div>
                      {this.state.displayAllDealersTopupTransfersLoading ?

                        <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />

                      :
                      <>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Trxns </div>
                        <div className="col-lg-8 col-8 dashboard-label"> Total Trxn Amount </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ allDealersTopupTransfers.TotalNumberOfMainDearTrxns } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-8 col-8 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ parseFloat(allDealersTopupTransfers.totalMainDealersTransferAmount).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                      </>
  }
                    </div>

                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
                <div className="col-lg-4 col-6 ">
                  <div className="small-box bg-white">

                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" style={ {textAlign: 'center' } }> Sub Dealers Monthly Topup Transfer </div>
                      </div>
                      {this.state.displayAllDealersTopupTransfersLoading ?        
                        <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />

                      :
                      <>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Trxns </div>
                        <div className="col-lg-8 col-8 dashboard-label"> Total Trxn Amount </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ allDealersTopupTransfers.TotalNumberOfSubDearTrxns } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-8 col-8 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ parseFloat(allDealersTopupTransfers.totalSubDealersTransferAmount).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                      </>
  }
                    </div>

                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
                <div className="col-lg-4 col-6 ">
                  <div className="small-box bg-white">

                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-12 col-12 dashboard-label" style={ {textAlign: 'center' } }> Retailers Daily Topup Transfers </div>
                      </div>
                      {this.state.displayAllDealersTopupTransfersLoading ?

<ReactLoading  type="bars" color="#0000FF" height={20} width={20} />

                      :
                      <>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> #Trxns </div>
                        <div className="col-lg-8 col-8 dashboard-label"> Total Trxn Amount </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ allDealersTopupTransfers.TotalNumberOfRetailerTrxns } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-8 col-8 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ parseFloat(allDealersTopupTransfers.totalRetailersTransferAmount).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                      </>
  }
                    </div>


                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
              </div>
                :
                ""
            }
            
            {localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && 
               this.state.displaySumOfProvidersMonthlySales ?
              <div className="row ">
                {this.state.displaySumOfProvidersMonthlySales &&
                <>
                <div className="col-lg-5 col-5 ">
                  <div className="small-box bg-white">

                    <div className='bg-white'>
                      <div className='row '><div className='ml-5 center dashboard-label'> Providers Total Monthly Sales: </div><div className='mt-0 dashboard-balance'> 
                       <NumberFormat value={ sumOfProvidersMonthlySales.totalSales && sumOfProvidersMonthlySales.totalSales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div></div>
                    </div>

                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> MTN </div>
                        <div className="col-lg-4 col-4 dashboard-label"> Etisalat </div>
                        <div className="col-lg-4 col-4 dashboard-label"> Salaam </div>
                      </div>

                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.mtnMonthlySales.toFixed(2)} displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.etisalatMonthlySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.salaamMonthlySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>


                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> Roshan </div>
                        <div className="col-lg-4 col-4 dashboard-label"> KYDC-AWCC </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ this.state.roshanMonthlySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ this.state.KYDC_AWCCMonthlySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                    </div>
                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
             
                <div className="col-lg-5 col-5 ">
                  <div className="small-box bg-white">

                    <div className='bg-white'>
                      <div className='row '><div className='ml-5 center dashboard-label'> Providers Total Daily Sales: </div><div className='mt-0 dashboard-balance'> 
                       <NumberFormat value={ sumOfProvidersDailySales.totalSales && sumOfProvidersDailySales.totalSales.toFixed(2)} displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div></div>
                    </div>

                    <div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> MTN </div>
                        <div className="col-lg-4 col-4 dashboard-label"> Etisalat </div>
                        <div className="col-lg-4 col-4 dashboard-label"> Salaam </div>
                      </div>

                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.mtnDailySales.toFixed(2)} displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.etisalatDailySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4 dashboard-balance"> <div className='mt-0'>  <NumberFormat value={ this.state.salaamDailySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>


                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4 dashboard-label"> Roshan </div>
                        <div className="col-lg-4 col-4 dashboard-label"> KYDC-AWCC </div>
                      </div>
                      <div className='row pl-1'>
                        <div className="col-lg-4 col-4"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ this.state.roshanDailySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                        <div className="col-lg-4 col-4"> <div className='mt-0 dashboard-balance'>  <NumberFormat value={ this.state.KYDC_AWCCDailySales.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> </div> </div>
                      </div>
                    </div>
                    {/* <Link to="/etopupDashboard" className="small-box-footer bg-light text-dark">
                      More info <i className="fas fa-arrow-circle-right" />
                    </Link> */}
                  </div>
                </div>
                </>
                  }
               
                  
              </div>
              :
                  ""
              }
              <div className="row ">
                {/* ./col */ }
                
               
               
              </div>
            
          
              <div className="row">
              {/* {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                <div className="col-lg-6 col-6">
                  <div className="small-box bg-light text-dark">
                    <div className="inner bg-warning">
                      <i className="fas fa-arrow-circle-right" /> Pending Purchase Orders
                    </div>
                    <a href="/#" className="small-box-footer bg-white">
                      <table className="table border-0 " >
                        <tr style={ { marginTop: 0, fontFamily: 'serif' } }>
                          <th>Provider Name</th>
                          <th>Commission </th>
                          <th>Amount</th>
                          <th>Date</th>
                        </tr>

                        { pendingPurchaseOrders.map(recenttrxn =>

                          <tr >
                            <td className='p-1 '>{ recenttrxn.providerId.providerName }</td>
                            <td className='p-1 '>{ recenttrxn.commissionId.onPurchase }</td>
                            <td className='p-1 '>
                              <NumberFormat value={ recenttrxn.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                            </td>
                            <td className='p-1 '>{ recenttrxn.createdAt }</td>
                          </tr>
                        ) }

                      </table>
                    </a>
                  </div>
                </div>
                 :
                 ""
             } */}
                {/* {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                <div className="col-lg-6 col-6">
                  <div className="small-box bg-light text-dark">
                    <div className="inner bg-primary">
                      <i className="fas fa-arrow-circle-right" /> Pending Topup Orders
                    </div>
                    <a href="/#" className="small-box-footer bg-white">
                      <table className="table border-0 " >
                        <tr style={ { marginTop: 0, fontFamily: 'serif' } }>
                          <th>Main Dealer</th>
                          <th>Sub Dealer </th>
                          <th>Amount</th>
                          <th>Date</th>
                        </tr>
                        { pendingTopupOrders.map(recenttrxn =>
                          <tr >
                            <td className='p-1 '>{ recenttrxn.parentId.firstName + " " + recenttrxn.parentId.middleName }</td>
                            <td className='p-1 '>{ recenttrxn.dealerId.firstName + " " + recenttrxn.dealerId.middleName }</td>
                            <td className='p-1 '>
                              <NumberFormat value={ recenttrxn.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                            </td>
                            <td className='p-1 '>{ recenttrxn.createdAt }</td>
                          </tr>
                        ) }
                      </table>
                    </a>
                  </div>
                </div>
                    :
                    ""
                } */}
                <div className="col-lg-12 col-12">
                  {/* small box */ }
                  <div className="small-box bg-light text-dark">
                    <div className="inner dashboard-label">
                      <i className="fas fa-arrow-circle-right" /> Recent Topup Trxns
                    </div>

                    <a href="/#" className="small-box-footer bg-white">
                      <table className="table border-0 text-left" >
                        <tr className='dashboard-label' style={ { marginTop: 0, fontFamily: 'serif' } }>
                          <th>Account #</th>
                          <th>Sender Mobile </th>
                          <th>Receiver Mobile </th>
                          <th>Trxn Ref # </th>
                          <th>$</th>
                          <th>Status</th>
                        </tr>

                        { recentTopupTrxns.map(recenttrxn =>

                          <tr  className='dashboard-label' style={{margin:"0px 2px 0px 2px"}}>
                            <td className='p-1 text-left'>{ recenttrxn.accountNo }</td>
                            <td className='p-1 text-left'>{ recenttrxn.senderMobileNo }</td>
                            <td className='p-1 text-left'>{ recenttrxn.mobileNo }</td>
                            <td className='p-1 text-left'>{ recenttrxn.senderRefNumber }</td>
                            <td className='p-1 text-left'>
                              <NumberFormat value={ recenttrxn.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                            </td>

                            <td className='p-1 text-left'>{ recenttrxn.statusValue }</td>

                          </tr>

                        ) }

                      </table>

                    </a>
                  </div>
                </div>
                {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                {/* ./col */ }
              </div>


            </div>
            {/* /.container-fluid */ }
          </section>
          {/* /.content */ }
        </div >
      </div >
       )
  //  }
  }
}