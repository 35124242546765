import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast, faCheckSquare, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Menu from '../../wallet/Menu';
import 'react-notifications/lib/notifications.css';
import AsyncSelect from 'react-select/async'
import { LOCALES } from '../../common/Constant'
import PostAccountService from '../../../services/etopup/PostAccountService';
import Header from '../../common/Header';
import API from '../../../http-common';
import ReactLoading from "react-loading";
import TopupMenu from '../../etopup/TopupMenu';
import NumberFormat from 'react-number-format';
import PreviewTable from '../../Reports/PreviewTable';
import _ from "lodash";
import PreviewTransTable from '../../Reports/PreviewTransTable';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u);
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u);

class PostAccountList extends Component {
  constructor (props) {

    super(props)

    this.state = {
      isLoading: false,
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      postAccountList: [],
      counter: 1,
      id: 0,
      isExpand: true,
      drSelectOption: null,
      customerList: "",
      fromDate: null,
      toDate: null,
      debitSum: 0,
      reportData:[],
      formErrors: {
        drAccountCodeError: '',
        customerError: '',
        dateToError: "",
        dateFromError: ""
      },
      customerId: '',
      fullName: '',
      customerType: ''

    }


    this.editPostAccount = this.editPostAccount.bind(this);
    this.PostAccountDetail = this.PostAccountDetail.bind(this);
    this.addPostAccount = this.addPostAccount.bind(this);

  }

  gotoEnteredPage = (pageNumber) => {
    this.state.pageNo = pageNumber
    this.searchCashPostAccountsBasedOnFilter()
    this.render()
  }

  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    this.searchCashPostAccountsBasedOnFilter()
    this.render()

  }

  gotoPageFirst = () => {
    this.state.pageNo = 1
    this.searchCashPostAccountsBasedOnFilter()
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      this.searchCashPostAccountsBasedOnFilter()
      this.render()

    }
  }

  nextPage = () => {
    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      this.searchCashPostAccountsBasedOnFilter()
      this.render()
    }
  }

  addPostAccount() {
    this.props.history.push(`/addLedgerTran`);
  }

  PostAccountDetail(id) {
    this.props.history.push({pathname: "/viewLedgerTran", state: {id: id}});
    
  }

  editPostAccount(id) {
    this.props.history.push({pathname: "/editLedgerTran", state: {id: id}});
  }

  drFatchData = (inputValue, callback) => {
    if (!inputValue) {
      callback({})
    } else {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data,

            })
          })
          .catch((error) => {
          })
      })
    }
  }

  onDrSearchChange = (drSelectOption) => {
    this.setState({
      drSelectOption: drSelectOption
    });

  }

  componentDidMount() {

    this.searchCashPostAccountsBasedOnFilter()
  }

  clearValue() {
    this.state.drSelectOption = null   
    this.state.fromDate = null
    this.state.toDate = null
    this.state.pageNo = 1;
    this.state.clear = 1
  }

  searchCashPostAccountsBasedOnFilter() {
    this.state.postAccountList = [];
    let FilterValue = {
      ledgerAccountId: this.state.drSelectOption && this.state.drSelectOption.value,
      dateFrom: this.state.fromDate,
      dateTo: this.state.toDate
    }
this.setState({isLoading: false})
    PostAccountService.getFilteredPostAccounts(
      FilterValue, this.state.pageNo, this.state.pageSize, localStorage.userId)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          postAccountList: data.content,
          totalPage: data.totalPages,
          totalelement: data.pageable.totalelement,
          pageNo: data.pageable.pageNumber + 1,
          isLoading: true
        });
        this.setState({isLoading: true})
      });

  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.searchCashPostAccountsBasedOnFilter()
    }
  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }

  async deletePostAccount(id) {
    if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
      try {
        const response = await PostAccountService.deletePostAccounts(id, localStorage.userId);
        NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCashPostAccountsBasedOnFilter()
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }
  }

  filterPostAccount(e) {
    const { formErrors } = this.state
    formErrors.dateFromError = ''

    if (this.state.fromDate != '' && this.state.toDate != '') {
      if (this.state.fromDate > this.state.toDate) {
        formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
      }
    }

    if (formValid(this.state.formErrors)) {

      this.state.clear = 0;
      this.state.pageNo = 1;

      this.searchCashPostAccountsBasedOnFilter();
    

    }

    this.setState({
      formErrors
    })

  }

  actionPageSize(e) {
    this.state.pageSize = e.target.value;
    this.setState({
      isLoading: false,
      pageSize: e.target.value
    });

    this.searchCashPostAccountsBasedOnFilter();
  }

  handelInputChange = e => {

    e.preventDefault()
    const { name, value } = e.target    
    this.setState({
      [name]: value
    })
  }

  render() {
    const { postAccountList, debitSum, isLoading, pageNo, pageSize, totalPage, formErrors, toDate, fromDate } = this.state;
    pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1
    
  

    if (this.props.location.state !== undefined) {
      this.state.drSelectOption = {
        label: this.props.location.state.accountCode + " - " + this.props.location.state.accountName,
        value: this.props.location.state.ledgerAccountId
      }
      this.props.location.state = undefined
    }



    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div>
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >Post Account Information List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Display Post Account Information</div>


                    <form onSubmit={ this.clear } hidden={ this.state.isExpand } noValidate>

                      <div className='row' hidden={ this.state.isExpand }>
                        <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6 ">
                          <label htmlFor='CustomerList'>Ledger Account:</label>
                          <div style={ { fontSize: 'smaller' } }>
                            <AsyncSelect id='drAccount'
                              className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                              placeholder={ "Search by account code" }
                              value={ this.state.drSelectOption }
                              isClearable={ true }
                              loadOptions={ this.drFatchData }
                              isLoading={ this.state.drSelectOption == {} &&
                                this.state.drSelectOption === undefined &&
                                this.state.drSelectOption === null ? true : false
                              }
                              onChange={ (e) => {
                                this.onDrSearchChange(e)
                              } }
                              defaultOptions={ false }
                            />
                            { formErrors.drAccountCodeError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.drAccountCodeError }</span>
                            ) }

                          </div>
                        </div>



                        <div className="  form-group col-3 col-sm-3 col-md-3 col-lg-3">
                          <label htmlFor='dateFrom' >Date From</label>

                          <input type="date" value={fromDate} className="form-control" id='dateFrom' name='fromDate'
                            onChange={ this.handelInputChange }
                            placeholder="Date" />
                          { formErrors.dateFromError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                          ) }

                        </div>
                        <div className="  form-group col-3 col-sm-3 col-md-3 col-lg-3">
                          <label htmlFor='dateTo'>Date To</label>
                          <input type="date" value={toDate} className="form-control" id='dateTo' name='toDate'
                            onChange={ this.handelInputChange }
                            placeholder="Date" />
                          { formErrors.dateToError.length > 0 && (
                            <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                          ) }
                        </div>

                      </div>
                      <br />
                      <div>
                        <button title={ "Search" }
                          className="btn btn-sm btn-success primary m-1 float-right"
                          onClick={ () => this.filterPostAccount() }>Search</button>
                        <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                          className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                      </div>
                    </form>
                    <div style={ { clear: 'both' } }></div>
                    <br />


                    <button type="button" title={ "Add" }
                      className="btn btn-sm btn-success primary m-1  float-left"
                      onClick={ (e) => this.addPostAccount() }>
                      <i className="fas fa-plus" /> Add
                    </button>

                    <button type='button' title={ "Show/Hide Filter" }
                      className="btn btn-sm btn-success primary m-1 float-left"
                      onClick={ () => this.filterToggle() }>
                      <FontAwesomeIcon icon={ faFilter } />
                      Filter
                    </button>
                    <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                      <label htmlFor='Size'>Row #:</label>
                      <select value={pageSize}
                        onChange={e => this.actionPageSize(e)} name='Size'>                             
                        <option key={"20"} value={20}>20</option>
                        <option key={"50"} value={50}>50</option>
                        <option key={"500"} value={500}>500</option>
                        <option key={"1000"} value={1000}>1000</option>
                        <option key={"10000"} value={10000}>10000</option>
                      </select>
                    </div>
                      <div style={{ clear: 'both' }}></div>
                      <div className='tableContainer'>
                  <PreviewTransTable
                  data={postAccountList }
                    columns={
                    [  
                      {
                        Header: '#',
                        accessor: (_row, i) => i + this.state.counter ,
                        disableSortBy: true
                      },  
                      {
                        Header: 'Ledger Account',        
                        accessor: 'accountCode',
                        filterable: true,
                      },              
                      {
                          Header: 'Transaction Ref',
                          Footer:'Total: ',
                          accessor: 'transactionRef',
                      },      
                      {
                          Header: 'Opening Balance',                          
                          accessor: 'openingBalance',
                          aggregate: 'sum',
                          Aggregated: ({ value }) => `${parseFloat(value).toFixed(2)}` ,
                        },  
                        {
                          Header: 'Debit',           
                          accessor: 'debit',
                          aggregate: 'sum',
                          Aggregated: ({ value }) => `${parseFloat(value).toFixed(2)} `,
                          Footer: <span>
                                   <NumberFormat  value={parseFloat(_.sum(_.map(postAccountList, d => d.debit))).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                  </span>
                        }, 
                        {
                          Header: 'Credit',
                          accessor: 'credit',         
                          aggregate: 'sum',
                          Aggregated: ({ value }) => `${parseFloat(value).toFixed(2)} `,
                          Footer: <span>
                                   <NumberFormat  value={parseFloat(_.sum(_.map(postAccountList, d =>  d.credit))).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />   
                                  </span>
                        }, 
                        {
                          Header: 'Closing Balance',
                          accessor: 'closingBalance',
                          aggregate: 'sum',
                          Aggregated: ({ value }) => `${parseFloat(value).toFixed(2)} `,
                        },
                        {
                          Header: 'Remark',
                          accessor: 'remark',
                      }             
                    
                    ]
                  } />
                  </div>
                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{ '' }
                        <strong>
                          { pageNo } of { totalPage }
                        </strong>
                        { '' }
                      </span>
                      <span>
                        | Go To Page : { '' }
                        <input type='number' defaultValue={ pageNo }
                          onChange={ (e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          } } />
                      </span>
                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackwardFast } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackward } />
                      </button>

                      <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                        <FontAwesomeIcon icon={ faForward } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                        <FontAwesomeIcon icon={ faForwardFast } />
                      </button>
                    </div>
                  </div>
                </div>
                <NotificationContainer />
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>
            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}


export default PostAccountList;