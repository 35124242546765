import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommissionService from '../../../services/etopup/CommissionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import TopupMenu from '../../etopup/TopupMenu';
import Header from '../../../components/common/Header';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import LoginService from '../../../services/common/LoginService';
import AsyncSelect from 'react-select/async'
import API from '../../../http-common';

class CommissionList extends Component {

    constructor(props) {

        super(props)
        this.state = {
            isLoading: false,
            pageNo: 1,
            customerId: '',
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            commissionList: [],
            searchValue: null,
            isExpand: true,
            fullName: '',
            customerType: ''
        }

    }
    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }


    componentDidMount() {
        this.getAllCommisions(this.state.pageNo, this.state.pageSize, localStorage.customerIdentity)
        this.getCustomerInfoByUserId()
    }

    getCustomerInfoByUserId() {

        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    fullName: response.data.customerFullName,
                    customerType: response.data.type
                });
                
            })
            .catch(e => {
                
            });
    }


    getAllCommisions(pageNo, pageSize, customerId) {
        CommissionService.getAllCommisions(
            pageNo, pageSize, localStorage.userId, customerId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    commissionList: data.dealerCommsionModel,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });


                //   if (data.content.length < 1) {
                //     NotificationManager.error("Customer not found", "AsPay");
                //   }
            });

    }

    fatchData = (inputValue, callback) => {
        
        if (inputValue.length >=3 ) {
           
        // } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) =>
                        response.data
                    )
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data
                        })
                    })
                    .catch((error) => {
                    })
            })
        }else{
            callback([])
        }
    }

    onSearchChange = (searchValue) => {

        if (searchValue) {
            this.setState({
                fullName: '',
                searchValue
            });
        }
    }

    filterCustomerDealer(e) {
        this.state.clear = 0;
        this.state.pageNo = 1;
        this.state.fullName = ''


        let customerId = this.state.searchValue && this.state.searchValue.value
        this.getAllCommisions(this.state.pageNo, this.state.pageSize, customerId)

    }

    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
        }
    }


    clearValue() {
        this.getCustomerInfoByUserId()
        this.state.searchValue = null
        if (this.state.customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && this.state.fullName !== '') {
            this.state.searchValue = {
                label: this.state.fullName,
                value: localStorage.customerIdentity
            }
        }
        this.state.clear = 1
        this.getAllCommisions(this.state.pageNo, this.state.pageSize, localStorage.customerIdentity)
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    // Delete customer
    async deleteCommission(id) {
        if (window.confirm("Are you sure you want to delete this commission?")) {
            try {
                NotificationManager.success("Deleted successfully", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            }
        }
    }

    render() {
        const { commissionList, fullName, customerType, pageNo, totalPage, isLoading } = this.state;

        if (customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` && fullName !== '') {
            this.state.searchValue = {
                label: fullName,
                value: localStorage.customerIdentity
            }
        }

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Commission List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Commission List</div>

                                        <form onSubmit={this.clear} hidden={this.state.isExpand} noValidate>
                                            <div className="row" hidden={this.state.isExpand}>
                                                <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6  ">
                                                    {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                                                        <div>
                                                            <label htmlFor='CustomerList'>Customer:</label>
                                                            <div style={{ fontSize: 'smaller' }}>
                                                                <AsyncSelect id='CustomerList'
                                                                    placeholder={"Search by name, account no or mobile number"}
                                                                    value={this.state.searchValue}
                                                                    isClearable={true}
                                                                    loadOptions={this.fatchData}
                                                                    isLoading={this.state.searchValue === {} &&
                                                                        this.state.searchValue === undefined &&
                                                                        this.state.searchValue === null ? true : false
                                                                    }

                                                                    onChange={(e) => {
                                                                        this.onSearchChange(e)
                                                                    }}
                                                                    defaultOptions={false}
                                                                />

                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                            {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                                                <div>
                                                    <button title={"Search"}
                                                        className="btn btn-sm btn-success primary m-1 float-right"
                                                        onClick={() => this.filterCustomerDealer()}>Search</button>
                                                    <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                                                        className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </form>
                                        <div style={{ clear: 'both' }}></div>
                                        <br />


                                        <Link to={{ pathname: '/addDealersCommission', state: { type: "Agent", formName: "region Registration" } }}>
                                            <button style={{ marginLeft: "3px" }} type="button" className="btn btn-sm m-1 btn-success float-left" onClick={this.addregion}>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?

                                            <button type='button' title={"Show/Hide Filter"}
                                                className="btn btn-sm btn-success primary m-1 float-left"
                                                onClick={() => this.filterToggle()}>
                                                <FontAwesomeIcon icon={faFilter} />
                                                Filter
                                            </button>
                                            :
                                            <></>
                                        }


                                        <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }} >
                                            <thead className='thead-light'>
                                                <tr style={{ marginTop: 0 }}>
                                                    {/* <th>Customer Name</th> */}
                                                    <th>Assigned As</th>
                                                    <th>Commission in %</th>
                                                    <th>Remark</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                               
                                                   { commissionList && commissionList.map(commissions =>

                                                        <tr>
                                                            {/* <td >{commissions.fullName}</td> */}
                                                            <td >{commissions.commissionAssignment}</td>
                                                            <td >{commissions.commissionPercent}</td>
                                                            <td >{commissions.remark}</td>
                                                            <td>

                                                                <Link to={{ pathname: '/viewDealersCommission', state: { commissionId: commissions.id } }}>
                                                                    <button className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                                </Link>
                                                                <Link to={{ pathname: '/editDealersCommission', state: { commissionId: commissions.id } }}>
                                                                    <button style={{ marginLeft: "2px" }}
                                                                        className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                                </Link>
                                                                {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteCommission(commissions.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */}
                                                            </td>
                                                        </tr>

                                                    
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{''}
                                                <strong>
                                                    {pageNo} of {totalPage}
                                                </strong>
                                                {''}
                                            </span>
                                            <span>
                                                | Go To Page : {''}
                                                <input type='number' defaultValue={pageNo}
                                                    onChange={(e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    }} />
                                            </span>
                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackwardFast} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackward} />
                                            </button>

                                            <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                                <FontAwesomeIcon icon={faForward} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                                <FontAwesomeIcon icon={faForwardFast} />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div>

            )
        }
    }
}
export default CommissionList