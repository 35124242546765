import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Header from '../common/Header';
import Menus from '../etopup/Menus';

export class DashboardTwo extends Component {

  constructor (props) {
    super(props)
    
  }

  componentDidMount() {
    localStorage.removeItem('walletMenu');    
    
  }

  dashbord = e => {
    this.props.history.push(`/eWalletDashboard`);
  }

  eTopupDashbord = e => {
    this.props.history.push(`/etopupDashboard`);
  }

  topup() {
    localStorage.setItem('walletMenu', false)
    localStorage.setItem('reloadCount', "firstTime")
  }

  wallet() {
    localStorage.setItem('walletMenu', true)
    localStorage.setItem('reloadCount', "firstTime")
  }

  render() {
    return (
      <>
      {/* <Header /> */}
      {/* <Menus/> */}
      <div className='wrapper'>
      
        <div className='container'>
          <div className='row' style={ { margin: "30px" } }></div>
          <div className='row'>
            <div className='col-md-2 center-block p-3'></div>
            <div className='col-md-4 bg-info rounded center-block text-center min-vh-90'>
              <Link 
              onClick={ this.topup}  
              to="/etopupDashboard">
                <img src="dist/img/etopup-logo.png"
                  alt="eTop Up Logo"
                  className="rounded-circle ml-5"
                  style={ { height: "120px", width: "120px", margin: "50px" } } />
              </Link>
            </div>

            <div className='col-md-1'></div>
            <div className='col-md-4 bg-success rounded center-block text-center'>
              <Link 
               onClick={this.wallet} 
              to="/eWalletDashboard">
                <img src="dist/img/ewallet-logo.png"
                  alt="Wallet Logo" className="rounded-circle ml-5"
                  style={ { height: "120px", width: "120px", margin: "50px" } } />
              </Link>
            </div>

          </div>
          <div className='row' style={ { margin: "130px" } }></div>
        </div>
      </div>
      </>
    )
  }
}
export default DashboardTwo