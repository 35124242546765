import API from '../../http-common';

class ProvidersSettingService {
  // get all Cash Information 
  getProvidersSettings(uerId) {
    return API.get("/api/v1/getProvidersSettings/" + uerId);
  }

  // create  Cash Information 
  createProvidersSetting(userId, ProvidersSetting) {
    return API.post("/api/v1/createProvidersSetting/" + userId, ProvidersSetting);
  }

  // get single Cash Information 
  getProvidersSetting(id, userId) {
    return API.get("/api/v1/getProvidersSettingById/" + id + "/" + userId);
  }

  // remove  Cash Information 
  deleteProvidersSetting(id, userId) {
    return API.delete('/api/v1/deleteProvidersSetting' + '/' + id + "/" + userId);
  }

  // update  Cash Method Information 
  updateProvidersSetting(userId, ProvidersSetting) {
    return API.put("/api/v1/updateProvidersSetting/" + userId, ProvidersSetting);
  }

}


export default new ProvidersSettingService()