import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';
import Menu from '../../wallet/Menu';
import BankInfoService from '../../../services/common/BankInfoService';
import CustomersService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AsyncSelect from 'react-select/async'
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const accountNoRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{10,50}$/u)
const balanceRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u)

class AddCustomerBankInfo extends Component {
  constructor (props) {
    super(props);

    this.state = {
      customerIds: [],
      bankInfoIds: [],
      bankInfoId: "",
      customerId: '',
      defultStatus: 0,
      bankAccount: 0,
      balance:0,
      isCompany:false,
      isLoading: false,
      formErrors: {
        bankAccountError: "",
        balanceError: "",
        bankInfoIdError: "",
        customerError: ""
      },


      customerList: [],
      selectOption: null
    }

  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({              
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    const { formErrors} = this.state
    if (selectOption) {
      formErrors.customerError = selectOption == "" ? 'You must make a selection' : "";
     
      CustomersService.getCustomerById(selectOption.value, localStorage.userId)
     .then(response => {
      this.setState({
        customerList: response.data,
        selectOption,
        formErrors
      });
      
    })
    .catch(e => {
      
    });

     
    }
  }



  componentDidMount() {

    BankInfoService.getBankInfos()
      .then(response => {
        this.setState({
          bankInfoIds: response.data

        });
        
      })
      .catch(e => {
        
      });

      this.setState({
        isCompany:  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
      });
  }

  bankValue(e) {
    const { formErrors } = this.state
    let selectedBankInfoId = e.target.value
    this.state.bankInfoId = ""
    if (selectedBankInfoId == "----please select---") selectedBankInfoId = ""

    formErrors.bankInfoIdError = selectedBankInfoId == "" ? 'You must make a selection' : "";

    this.setState({
      bankInfoId: selectedBankInfoId,
      formErrors
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { bankAccount, isCompany, bankInfoId, selectOption, formErrors } = this.state
    formErrors.bankAccountError = bankAccount == 0 ? "Account No is Required" : this.state.formErrors.bankAccountError;
    formErrors.bankInfoIdError = bankInfoId == '' || bankInfoId == null || bankInfoId == undefined ? 'You must make a selection' : this.state.formErrors.bankInfoIdError;
    if(isCompany){
    formErrors.customerError = selectOption == '' || selectOption == null || selectOption == undefined ? 'You must make a selection' : this.state.formErrors.customerError
    }
    if (formValid(this.state.formErrors)) {
      let customerId = isCompany ? this.state.selectOption && this.state.selectOption.value : localStorage.customerIdentity
      const formData = new FormData();
      formData.append("customerId", customerId)
      formData.append("bankInfoId", this.state.bankInfoId)
      formData.append("defultStatus", this.state.defultStatus)
      formData.append("balance", this.state.balance)
      formData.append("bankAccount", this.state.bankAccount)
      formData.append("userId", localStorage.userId)
     
      this.setState({ isLoading: true})
      CustomerBankInfoService.createCustomerBankInfo(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error,`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoading: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'bankAccount':
        formErrors.bankAccountError =
          value.length == 0 || value.length < 10 || !accountNoRegEx.test(value) ? name + " Sholud be digits with a min 10 & max 50" : ""
        break;
        case 'balance':
          formErrors.balanceError =
             !balanceRegEx.test(value) ? name + " Sholud be digits with a min 1 & max 1000000000" : ""
          break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  cancel() {
    this.props.history.push('/customerBankInfoList')
  }


  render() {
    const { bankInfoIds,customerList, formErrors, isCompany } = this.state
    let bankList = bankInfoIds.length > 0
      && bankInfoIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>

        )
      }, this);

    return (

      <div >
        {/* <Header />
        <Menu /> */}
        {/* <NotificationContainer /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Customer Bank Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Customer Bank Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit } noValidate>

{isCompany ?
                      <div >
                        <label htmlFor='CustomerList'>Customer :</label>
                        <div style={ { fontSize: 'smaller' } }>

                          <AsyncSelect id='CustomerList' className=" form-group col-12 col-sm-10 col-md-10 col-lg-10  "
                            placeholder={ "Search by name, account no or mobile number" }
                        
                            isClearable={ true }
                            loadOptions={ this.fatchData }
                            isLoading={ this.state.selectOption === {} &&
                              this.state.selectOption === undefined &&
                              this.state.selectOption === null ? true : false
                            }
                            onChange={ (e) => {
                              this.onSearchChange(e)
                            } }
                            defaultOptions={ false }
                          />
                        </div>
                        { formErrors.customerError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.customerError }</span>
                        ) }

                      </div>
                      :
                      ""
                        }

                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='BankList'>Bank Name:</label>
                        <select className="form-control "
                          value={ this.state.bankInfoId && this.state.bankInfoId.id }
                          onChange={ e => this.bankValue(e).bind(this) } id='BankList'>
                          <option key={ null } value={ null }>----please select---</option>
                          { bankList }
                        </select>
                        {/* <input type='hidden' value={this.state.bankInfoId.id} name='bankInfoId' onChange={this.handelChange}/> */ }
                        { formErrors.bankInfoIdError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.bankInfoIdError }</span>
                        ) }
                      </div>
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='AccountNo'>Account No:</label>
                        <input type="text" id='AccountNo' className="form-control" name='bankAccount'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          // onChange={e => this.state.bankAccount = e.target.value}
                          placeholder="Account No" required={ true } />

                        { formErrors.bankAccountError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.bankAccountError }</span>
                        ) }
                      </div>
                     {customerList.customerType && customerList.customerType.type ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='balance'>Balance:</label>
                        <input type="text" id='balance' className="form-control" name='balance'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          // onChange={e => this.state.bankAccount = e.target.value}
                          placeholder="Balance" required={ true } />

                        { formErrors.balanceError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.balanceError }</span>
                        ) }
                      </div>
                      :
                      <></>
                      }
                      <div className="row  d-flex justify-content-left ">
                        <button disabled={this.state.isLoading} type="submit"
                          className="btn btn-success px-4 py-2" style={ { marginLeft: "30px" } }>Save</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>         
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}



export default AddCustomerBankInfo;