import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';

import CashInfoService from '../../../services/etopup/CashInfoService';
import CashMethodService from '../../../services/etopup/CashMethodService';
import CustomerListService from '../../../services/common/CustomersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BankInfo from '../../wallet/BankInfo';
import BankInfoService from '../../../services/common/BankInfoService';
import { LOCALES } from '../../common/Constant'
import AsyncSelect from 'react-select/async'
import Header from '../../common/Header';
import API from '../../../http-common';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const accountNoRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,20}$/u)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);

export default class EditCashIn extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeReceiverMobileNo = this.onChangeReceiverMobileNo.bind(this);
        this.onChangeCashMethod = this.onChangeCashMethod.bind(this);

        this.state = {
            cashMethod: [],
            personalInfo: [],
            balanceInfo: [],
            senderBankInfo: [],
            receiverBankInfo: [],
            customerBank: [],
            bankInfoId: [],
            customerId: [],
            isLoading: false,
            searchValue: null,
            id: this.props.location.state.id,
            mobileNo: this.props.location.state.mobileNo,
            selectedCashMethoId: this.props.location.state.cashMethodId,
            cashInfoData: {
                customerId: [],
                accountNo: "",
                senderMobileNo: "",
                receiverMobileNo: "",
                amount: "",
                description: "",
                personalName: "",
                personalBankName: "",
                personalBankAccount: "",
                senderBankName: "",
                senderBankId: [
                    { id: 0 }
                ],
                senderBankAccount: "",
                receiverBankName: "",
                receiverBankId: [
                    { id: 0 }
                ],
                receiverBankAccount: "",
                cashMethodId: '',
                cashTypeId: LOCALES.CASH_IN_TYPE,
                receiverBankName: `${process.env.REACT_APP_RECEIVER_BANK_NAME}`,
                // Initially, no file is selected
                attachment: null

            },

            testBool: false,
            isExpand: true,
            formErrors: {
                cashMethodError: "",
                phoneNumberError: "",
                customerNotFoundError: "",
                amountError: "",
                descriptionError: "",
                senderBankError: "",
                senderAccountNoError: "",
                receiverBankError: "",
                receiverAccountNoError: "",
                attachmentError: ""
            },


        };


    }


    cancel() {
        this.props.history.push('/cashInList')
    }

    // On file select (from the pop up)
    onFileChange = event => {
        // Update the state
        const { formErrors, cashInfoData } = this.state
        cashInfoData.attachment = event.target.files[0]
        formErrors.attachmentError = event.target == null || event.target.files[0] == null || event.target.files[0] == '' ? 'Attach payment slip' : "";
        this.setState({
            attachment: event.target.files[0],
            formErrors
        });
    };


    OnChangeReceiverBankInfo = e => {
        const { formErrors } = this.state
        let selectedValeChanged = e.target.value
        this.state.cashInfoData.receiverBankId = e.target.value
        if (selectedValeChanged == "----please select---")
            selectedValeChanged = ""
        formErrors.receiverBankError = selectedValeChanged == "" ? 'You must make a selection' : "";
        this.setState(function (prevState) {
            return {
                cashInfoData: {
                    ...prevState.cashInfoData,
                    receiverBankId: selectedValeChanged
                },
                formErrors
            };
        });

    }


    onChangeReceiverMobileNo = mobileNo => {
        const { formErrors } = this.state
        this.state.customerBank = undefined
        this.state.customerId = undefined
        this.state.bankInfoId = undefined
        this.state.cashInfoData.receiverMobileNo = mobileNo
        formErrors.phoneNumberError =
            mobileNo.length == 0 || mobileNo.length < 6 || !phoneNoRegEX.test(mobileNo) ? "Phone Number sholud be digites with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            const receiverMobileNo = mobileNo;
            this.getCustomerBankInfo(receiverMobileNo)
        }


        this.setState({
            formErrors
        })

    }


    onChangeSenderBankName = e => {

        const { formErrors } = this.state
        let selectedValeChanged = e.target.value;
        this.state.cashInfoData.senderBankId = e.target.value
        if (selectedValeChanged == "----please select---")
            selectedValeChanged = ""
        formErrors.senderBankError = selectedValeChanged == "" ? 'You must make a selection' : "";
        this.setState(function (prevState) {
            return {
                senderBankId: {
                    ...prevState.senderBankId,
                    id: selectedValeChanged
                },
                formErrors
            };
        });
    }


    onChangeReceiverBankId(e) {
        const receiverBankId = e.target.value;
        this.setState(function (prevState) {
            return {
                cashInfoData: {
                    ...prevState.cashInfoData,
                    receiverBankId: receiverBankId
                }

            };
        });
    }

    onChangeReceiverBankAccount(e) {
        const receiverBankAccount = e.target.value;
        this.setState(function (prevState) {
            return {
                cashInfoData: {
                    ...prevState.cashInfoData,
                    receiverBankAccount: receiverBankAccount
                }

            };
        });
    }

    onChangeCashMethod(e) {
        const cashMethodId = e.target.value
        this.OnChangeSelectedValue(cashMethodId)
        this.setState(function (prevState) {
            return {
                cashInfoData: {
                    ...prevState.cashInfoData,
                    cashMethodId: cashMethodId
                }
            };
        });
    }

    OnChangeSenderBank() {
        BankInfoService.getBankInfos()
            .then(response => {
                this.setState({
                    senderBankInfo: response.data
                });
                
            })
            .catch(e => {
                
            });

    }

    OnChangeReceiverBankName() {
        BankInfoService.getBankInfos()
            .then(response => {
                this.setState({
                    receiverBankInfo: response.data
                });
                
            })
            .catch(e => {
                
            });

    }

    OnChangeSelectedValue(cashMethodIdCopy) {
        const { formErrors } = this.state
        let selectedValeChanged
        let cashMethodChanged = cashMethodIdCopy
        this.state.cashInfoData.cashMethodId = ""
        if (cashMethodChanged == "----please select---") {
            cashMethodChanged = ""
        } else {
            this.state.cashInfoData.receiverBankId = ''
            this.state.cashInfoData.receiverBankAccount = ''
            if (cashMethodIdCopy == LOCALES.DIPOSIT_METHOD) {
                this.state.isExpand = true;
                this.state.cashInfoData.receiverBankId = LOCALES.RECEIVER_BANK_ID
                this.state.cashInfoData.receiverBankAccount = LOCALES.RECEVER_BANK_ACCOUNT
            } else {
                this.state.isExpand = false;
            }
            if (cashMethodIdCopy == LOCALES.DIPOSIT_METHOD ||
                cashMethodIdCopy == LOCALES.BANK_TRASFER_METHOD) {
                selectedValeChanged = true
            } else {
                selectedValeChanged = false
            }

            this.setState({
                testBool: selectedValeChanged
            })
        }
        formErrors.cashMethodError = cashMethodChanged == "" ? 'You must make a selection' : "";
        this.setState({
            formErrors
        });

    }

    componentDidMount() {
        this.getCashInById(this.state.id);

        CashMethodService.getCashMethods()
            .then(response => {
                this.setState({
                    cashMethod: response.data
                });
            })
            .catch(e => {
                
            });

        this.OnChangeReceiverBankName()
        this.OnChangeSenderBank()

        this.OnChangeSelectedValue(this.state.selectedCashMethoId)
        this.getCustomerBankInfo(this.state.mobileNo)
    }


    getCustomerBankInfo(mobileNo) {

        CustomerListService.getCustomerBankInfoByMobile(mobileNo, LOCALES.DEFAULT_CUSTOMER, localStorage.userId)
            .then(response => {
                this.setState({
                    customerBank: response.data,
                    customerId: response.data.customerId,
                    bankInfoId: response.data.bankInfoId
                });
            })
            .catch(e => {
                
            });
    }

    getCashInById(id) {

        CashInfoService.getCashIn(id, localStorage.userId)
            .then(response => {
                this.setState({
                    cashInfoData: response.data
                });
                
                
            })
            .catch(e => {
                
            });
    }

    updateCustomer() {
        debugger
        const { cashInfoData, searchValue, formErrors } = this.state
        formErrors.customerNotFoundError = searchValue == "" || searchValue === undefined ||  searchValue == null ? "Customer is Required" : this.state.formErrors.customerNotFoundError;
        formErrors.amountError = cashInfoData.amount == '' || cashInfoData.amount == null || cashInfoData.amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
        formErrors.cashMethodError = cashInfoData.cashMethodId == '' || cashInfoData.cashMethodId == null || cashInfoData.cashMethodId == undefined ? 'You must make a selection' : this.state.formErrors.cashMethodError;
    
        if (cashInfoData.cashMethodId == LOCALES.DIPOSIT_METHOD ||
            cashInfoData.cashMethodId == LOCALES.BANK_TRASFER_METHOD) {
            formErrors.senderBankError = cashInfoData.senderBankId == '' || cashInfoData.senderBankId == null || cashInfoData.senderBankId == undefined ? 'You must make a selection' : this.state.formErrors.senderBankError;
            formErrors.receiverBankError = cashInfoData.receiverBankId == '' || cashInfoData.receiverBankId == null || cashInfoData.receiverBankId == undefined ? 'You must make a selection' : this.state.formErrors.receiverBankError;
            formErrors.senderAccountNoError = cashInfoData.senderBankAccount == '' || cashInfoData.senderBankAccount == null || cashInfoData.senderBankAccount == undefined ? 'Sender account No is Required' : this.state.formErrors.senderAccountNoError;
            formErrors.receiverAccountNoError = cashInfoData.receiverBankAccount == '' || cashInfoData.receiverBankAccount == null || cashInfoData.receiverBankAccount == undefined ? 'Receiver account No is Required' : this.state.formErrors.receiverAccountNoError;
            formErrors.attachmentError = cashInfoData.contentType == '' || cashInfoData.contentType == null || cashInfoData.contentType == undefined ? 'Attach payment slip' : this.state.formErrors.attachmentError;
        }else{
            formErrors.senderBankError = ''
            formErrors.receiverBankError = ''
            formErrors.senderAccountNoError = ''
            formErrors.receiverAccountNoError = ''
            formErrors.attachmentError = ''
        }

        if (formValid(this.state.formErrors)) {
            debugger
            // Create an object formData
            const formData = new FormData();

            formData.append("amount", this.state.cashInfoData.amount);
            formData.append("attachment", this.state.cashInfoData.attachment)
            formData.append("cashMethodId", this.state.cashInfoData.cashMethodId)
            formData.append("cashTypeId", this.state.cashInfoData.cashTypeId)
            formData.append("senderCustomerId", localStorage.customerIdentity)
            formData.append("description", this.state.cashInfoData.description)
            formData.append("receiverCustomerId", this.state.searchValue && this.state.searchValue.value)
            formData.append("userId", localStorage.userId)

            if (cashInfoData.cashMethodId == LOCALES.BANK_TRASFER_METHOD) {
                formData.append("senderBankId", this.state.cashInfoData.senderBankId)
                formData.append("senderBankAccount", this.state.cashInfoData.senderBankAccount)
                formData.append("receiverBankId", this.state.cashInfoData.receiverBankId)
                formData.append("receiverBankAccount", this.state.cashInfoData.receiverBankAccount)
            }
            if (cashInfoData.cashMethodId == LOCALES.DIPOSIT_METHOD) {
                formData.append("senderBankId", this.state.cashInfoData.senderBankId)
                formData.append("senderBankAccount", this.state.cashInfoData.senderBankAccount)
                formData.append("receiverBankId", `${process.env.REACT_APP_RECEIVER_BANK_ID}`)
                formData.append("receiverBankAccount", `${process.env.REACT_APP_RECEIVER_BANK_ACCOUNT}`)
            }
            formData.append("id", this.state.id)
  
            this.setState({ isLoading: true})  
            CashInfoService.updateCashInWithFile(formData)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({ isLoading: false})  
                        this.cancel()
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false})  
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})  
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };

    handelChange = e => {
        e.preventDefault();
        const { cashInfoData, customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                this.state.cashInfoData.amount = value
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud be digites with a min 1 & max 100,000" : ""
                break;
            // case 'phoneNumber':
            //     this.state.cashInfoData.receiverMobileNo = value
            //     formErrors.phoneNumberError =
            //         value.length == 0 || value.length < 10 || !phoneNoRegEX.test(value) ? name + " sholud be digites with a min 1 & max 10" : ""

            //     if (formErrors.phoneNumberError.length == 0) {
            //         this.onChangeReceiverMobileNo(value)
            //         formErrors.customerNotFoundError =
            //         customerId == null || customerId == undefined ?
            //                 "With this " + name + " there is no registered customer " : ""
            //     }
            //     break;
            case 'description':
                this.state.cashInfoData.description = value
                formErrors.descriptionError =
                    !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
                break;
            case 'senderBankAccount':
                this.state.cashInfoData.senderBankAccount = value
                formErrors.senderAccountNoError =
                    !accountNoRegEx.test(value) ? name + " sholud be digites with a min 1 & max 20" : ""
                break;
            case 'receiverBankAccount':
                this.state.cashInfoData.receiverBankAccount = value
                formErrors.receiverAccountNoError =
                    !accountNoRegEx.test(value) ? name + " Sholud be digites with a min 1 & max 20" : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
          setTimeout(() => {
            API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/"+ localStorage.userId)    
          .then((response) =>               
             response.data
          )
              .then((data) => {
                const temp = [];
                data.forEach((element) => {
                  temp.push({ label: element.fullName, value: element.id })
                    
                });
                callback(temp)               
              })
              .catch((error) => {
              })
          })
        }
      }
    
      onSearchChange = (searchValue) => {
        const { formErrors } = this.state
    
        formErrors.customerNotFoundError =
           customerRegEx.test(searchValue) ? " Please insert customer Name or account no or mobile number" : ""
    
      if (searchValue) {
          this.setState({
            searchValue,
            formErrors
          });
        }
      }

    render() {
        const { formErrors, searchValue, senderBankInfo, receiverBankInfo, cashInfoData, cashMethod, isExpand, personalInfo, balanceInfo, customerId, customerBank, bankInfoId } = this.state

        let cashMethodList = cashMethod.length > 0
            && cashMethod.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>

                )
            }, this);


        let receiverBankList = receiverBankInfo.length > 0
            && receiverBankInfo.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);


        let senderBankList = senderBankInfo.length > 0
            && senderBankInfo.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);

           
    if (searchValue === null && cashInfoData.customerId != '' ) {
        this.state.searchValue = {
          label: cashInfoData.receiverFullName,
          value: cashInfoData.customerId
        }
    }

        return (


            <div >
                {/* <Header />
                <Menu /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Modify Cash In Information</li>

                                    </ol>
                                </div>

                            </div>
                            <div >
                                <div className="modal-body">

                                    <div className='col-1'></div>
                                    <div className='col-7 bg-light p-4'>

                                        <div class="modal-header">
                                            <h4 class="modal-title" >
                                                Modifiy Cash In Information
                                            </h4>
                                        </div>

                                        {/*<h2>Register</h2> */}
                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                                            <label htmlFor='cashMethodList'>Cash Method:</label>
                                            <select className="form-control "
                                                value={cashInfoData.cashMethodId}
                                                onChange={this.onChangeCashMethod} id='cashMethodList'>
                                                <option value={null}>----please select---</option>
                                                {cashMethodList}
                                            </select>
                                            {formErrors.cashMethodError.length > 0 && (
                                                <span style={{ color: "#F61C04" }}>{formErrors.cashMethodError}</span>
                                            )}
                                        </div>
                                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                            <label htmlFor='CustomerList'>Customer:</label>
                                            <div style={{ fontSize: 'smaller' }}>
                                                <AsyncSelect id='CustomerList'
                                                    placeholder={"Search by name, account no or mobile number"}
                                                    value={this.state.searchValue}
                                                    isClearable={true}
                                                    loadOptions={this.fatchData}
                                                    isLoading={this.state.searchValue === {} &&
                                                        this.state.searchValue === undefined &&
                                                        this.state.searchValue === null ? true : false
                                                    }

                                                    onChange={(e) => {
                                                        this.onSearchChange(e)
                                                    }}
                                                    defaultOptions={false}
                                                />
                                                {formErrors.customerNotFoundError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.customerNotFoundError}</span>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="card my-4 border-radius-0" >
                                            <div className="card-header border-radius-0 bg-light">
                                                <h6 class="title " >Personal Information </h6>
                                            </div>
                                            <div className="card-body">
                                                <div className='row'>

                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='Name'>Name:</label>
                                                        <input type="text" className="form-control" id='Name'
                                                            placeholder="Name" readOnly
                                                            value={customerId !== null && customerId !== undefined ? customerId.fullName : ''}
                                                            required="required" />
                                                    </div>

                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='AccountNo'>Account No:</label>
                                                        <input type="text" className="form-control" id='AccountNo'
                                                            placeholder="Account No" readOnly
                                                            value={customerId !== null && customerId !== undefined ? customerId.accountNo : ''}
                                                            required="required" />
                                                    </div>


                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='bankName'>Bank Name:</label>
                                                        <input type="text" className="form-control" id='bankName'
                                                            placeholder="Bank Name" readOnly
                                                            value={bankInfoId !== null && bankInfoId !== undefined ? bankInfoId.name : ''}
                                                            required="required" />
                                                    </div>

                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                        <label htmlFor='AccountName'>Bank Account:</label>
                                                        <input type="text" className="form-control " id='AccountName'
                                                            placeholder="Bank Account" readOnly
                                                            value={customerBank !== null && customerBank !== undefined ? customerBank.bankAccount : ''}
                                                            required="required" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {this.state.testBool && <BankInfo
                                            content={<>
                                                {isExpand ?
                                                    <>
                                                        <div>
                                                        <div className="card my-4 border-radius-0">
                                                            <div className="card-header border-radius-0 bg-light">
                                                                <h6 class="title " >Sender Bank Information </h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row" >
                                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                        <label htmlFor='senderBankInfoList'>Bank Name:</label>
                                                                        <select className="form-control "
                                                                            value={cashInfoData.senderBankId}
                                                                            onChange={this.onChangeSenderBankName} id='senderBankInfoListList'>
                                                                            <option value={null}>----please select---</option>
                                                                            {senderBankList}
                                                                        </select>
                                                                        {formErrors.senderBankError.length > 0 && (
                                                                            <span style={{ color: "#F61C04" }}>{formErrors.senderBankError}</span>
                                                                        )}
                                                                    </div>


                                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                        <label htmlFor='AccountName'>Bank Account:</label>
                                                                        <input type="text" className="form-control "
                                                                            id='AccountName' value={cashInfoData.senderBankAccount} name='senderBankAccount'
                                                                            onChange={this.handelChange} onBlur={this.handelChange}
                                                                            required="required" />
                                                                        {formErrors.senderAccountNoError.length > 0 && (
                                                                            <span style={{ color: "#F61C04" }}>{formErrors.senderAccountNoError}</span>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                <div>

                                                    <div className="card my-4 border-radius-0">
                                                        <div className="card-header border-radius-0 bg-light">
                                                            <h6 class="title " >Receiver Bank  Information</h6>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row" >

                                                                {isExpand ?
                                                                    <>
                                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                            <label htmlFor='Name'>Bank Name:</label>
                                                                            <input type="text" className="form-control " id='Name'
                                                                                placeholder="Bank Name" disabled={isExpand}
                                                                                value={cashInfoData.receiverBankName}
                                                                            />
                                                                        </div>
                                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                            <label htmlFor='AccountName'>Bank Account:</label>
                                                                            <input type="text" className="form-control" id='AccountName'
                                                                                placeholder="Account No" disabled={isExpand}
                                                                                value={cashInfoData.receiverBankAccount} required="required" />
                                                                        </div>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                            <label htmlFor='receiverBankInfoList'>Bank Name:</label>
                                                                            <select className="form-control "
                                                                                value={cashInfoData.receiverBankId}
                                                                                onChange={this.OnChangeReceiverBankInfo} id='receiverBankInfoList'>
                                                                                <option value={null}>----please select---</option>
                                                                                {receiverBankList}
                                                                            </select>
                                                                            {formErrors.receiverBankError.length > 0 && (
                                                                                <span style={{ color: "#F61C04" }}>{formErrors.receiverBankError}</span>
                                                                            )}
                                                                        </div>

                                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                            <label htmlFor='AccountName'>Bank Account:</label>
                                                                            <input type="text" className="form-control" id='AccountName'
                                                                                value={cashInfoData.receiverBankAccount}
                                                                                onChange={this.handelChange} onBlur={this.handelChange}
                                                                                placeholder="Account No" name='receiverBankAccount'
                                                                            />
                                                                            {formErrors.receiverAccountNoError.length > 0 && (
                                                                                <span style={{ color: "#F61C04" }}>{formErrors.receiverAccountNoError}</span>
                                                                            )}
                                                                        </div>
                                                                    </>

                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="card my-4 border-radius-0">
                                                            <div className="card-header border-radius-0 bg-light">
                                                                <h6 class="title " >Upload payment slip</h6>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row" >
                                                                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                                        <input type="file"
                                                                            onChange={this.onFileChange} name='attachment' />
                                                                        {formErrors.attachmentError !== undefined &&
                                                                            formErrors.attachmentError.length > 0 && (
                                                                                <span style={{ color: "#F61C04" }}>{formErrors.attachmentError}</span>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </>}
                                        />}

                                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                            <label htmlFor='Amount'>Amount:</label>
                                            <input type="text" className="form-control" id='Amount'
                                                value={cashInfoData.amount}
                                                name='amount'
                                                onChange={this.handelChange} onBlur={this.handelChange}
                                                placeholder="Amount" required="required" />
                                            {formErrors.amountError.length > 0 && (
                                                <span style={{ color: "#F61C04" }}>{formErrors.amountError}</span>
                                            )}
                                        </div>


                                        <div>
                                            <div className="form-group col-15 col-sm-8 col-md-8 col-lg-8">
                                                <label htmlFor='Description'>Description:</label>
                                                <textarea type="" className="form-control" id='Description'
                                                    value={cashInfoData.description}
                                                    onChange={this.handelChange} onBlur={this.handelChange} name='description'
                                                    placeholder="Description" />
                                                {formErrors.descriptionError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.descriptionError}</span>
                                                )}
                                            </div>
                                        </div>


                                        <div className="row  d-flex justify-content-left ">

                                            <button disabled={this.state.isLoading} type="submit" style={{ marginLeft: "15px" }} className="btn btn-success  px-3 "
                                                onClick={() => this.updateCustomer()}>Update</button>
                                            <button type="submit" onClick={this.cancel.bind(this)}
                                                className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                                        </div>



                                    </div>
                                    <NotificationContainer />
                                    {this.state.isLoading && <LoadingPopup
                                    content={<>
                                            <form>
                                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                </form>
                                    </>}
                                    />}
                                </div>
                            </div>


                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>



        )
    }
}
