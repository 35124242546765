import { faArrowDown, faArrowUp, faMinus, faPlus, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useExpanded, useGroupBy, useSortBy, useTable } from 'react-table';
import '../../report.css';

const phoneNoRegEX = RegExp(/^[0-9\b-]+$/u);



export default function PreviewTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable({ columns, data }, useGroupBy, useSortBy, useExpanded);

  return (
    <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }}  
      {...getTableProps()}>
      <thead className='thead-light'>
        {headerGroups.map((headerGroup) => (
          <tr style={{ marginTop: 0 }} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>            
                {column.canGroupBy && column.id === 'type' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'cashMethod' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                 {column.canGroupBy && column.id === 'providerName' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'senderName' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}
                 {column.canGroupBy && column.id === 'senderAccount' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}
                
                 {column.canGroupBy && column.id === 'receiverName' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}
                  {column.canGroupBy && column.id === 'receiverAccount' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'rechargedFromValue' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'dealerName' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'parentAccountCode' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'accountCode' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'parentId' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}

                {column.canGroupBy && column.id === 'fullName' ? (
                  <span {...column.getGroupByToggleProps()}>
                    {' '}
                    {column.isGrouped ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus}/>}
                  </span>
                ) : null}


                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp}/>) : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                     
                     { phoneNoRegEX.test(cell.value) && cell.column.id != 'transactionRef' && 
                        cell.column.id != 'registeredDate' && cell.column.id != 'createdAt' && 
                        cell.column.id != '#' &&  cell.column.id != 'status' && cell.column.id !='rechargedFrom' &&  cell.column.id != ''   && cell.value.length == undefined   ?
                       <>
                         <NumberFormat  value={parseFloat(cell.value).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />              
                       </>            
                      :
                      <>
                    {cell.isGrouped ? (
                      // If it's a grouped cell, add an expander and row count
                      <>
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? '-' : '+'}
                        </span>{' '}
                        {cell.render('Cell')} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                      // Otherwise, just render the regular cell
                      cell.render('Cell')
                    )}
                    </>
                    }
                  </td>
                  
                );
              })}
            </tr>
          );
        })}
      </tbody>    
    </table>
  );
}
