import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./loading.css"
 
const LoadingPopup = props => {
  return (
    <div className="loading-parent">
    <div className="loading-box">
      <div className="lading">   
        {props.content}
      </div>
    </div>
    </div>
  );
};
 
export default LoadingPopup;