import KioskService from '../../../services/wallet/KioskService';
import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import moment from 'moment';

export default class ViewKiosk extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            id: this.props.match.params.id,
            kioskData: {
                name: '',
                kioskId: '',
                serialNumber: '',
                address: '',
                latitude: '',
                longitude: '',
                status: '',
            }
        };
    }

    componentDidMount() {
        this.getKioskById(this.props.location.state.id);
    }

    getKioskById(id) {

        KioskService.getKioskById(id, localStorage.userId)
            .then(response => {
                this.setState({
                    kioskData: response.data
                });
              
            })
            .catch(e => {
                
            });
    }

    render() {
        const { kioskData } = this.state;
        // let location = kioskData.location;


      


        return (
            <div>
                {/* <Header />
                <TopupMenu /> */}
                {/* Content Wrapper. Contains page content */ }
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">

                        <div className="row mb-2">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Kiosk Detail </li>
                                </ol>
                            </div>

                        </div>

                        <div >
                            <div className="card ">
                                <div className="card-body">

                                    <div className="form-title" >Kiosk Detail View</div>
                                    <br />

                                    <div className="table-responsive w-50">

                                        <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>
                                            {/* <tr className='no-gutters'>
                                                <td className="text-left font-weight-bolder" >Name</td>
                                                <td className="text-justify ">{ kioskData.name }</td>
                                            </tr> */}
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Kiosk Id</td>
                                                <td className="text-justify">{ kioskData.kioskId }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Serial No</td>
                                                <td className="text-justify">{ kioskData.serialNumber }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Latitude</td>
                                                <td className="text-justify">{ kioskData.latitude }</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Longitude</td>
                                                <td className="text-justify">{ kioskData.longitude } </td>

                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Address</td>
                                                <td className="text-justify">{ kioskData.address } </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Date</td>
                                                <td className="text-justify">{ moment(kioskData.createdAt).format('YYYY-MM-DD') } </td>
                                            </tr>
                                            <tr>
                                                <td className="text-left font-weight-bolder" >Status</td>
                                                <td className="text-justify">{ kioskData.status } </td>
                                            </tr>
                                            <tr style={ { textAlign: 'center' } }>
                                                <Link to="/kioskList" className='pl-5'>
                                                    <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                </Link>

                                            </tr>

                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className='col-2'></div>




                        {/* /.row */ }
                    </div>
                    {/* /.container-fluid */ }
                </div>
            </div>

        )
    }
}