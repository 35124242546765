import { exists } from 'i18next';
import React, { Component } from 'react'
import { Link, NavLink, withRouter } from "react-router-dom";
import MenuService from '../../services/wallet/MenuService';
import SmartPayTechnology from '../../../src/images/SmartPayTechnology.png';
import LoginService from '../../services/common/LoginService';
import { PureComponent } from 'react';


class Menu extends PureComponent {

  constructor (props) {

    super(props)
    this.state = {
      Menus: [],
      SubMenus: [],
      companyProfile:[]
    }
  }
  

  componentDidMount() {
    LoginService.companyProfileInfo()
    .then(response => response.data)
    .then((data) => {
        this.setState({
            companyProfile: data
        });
    });

    MenuService.getWalletMenus(localStorage.userId)
      .then(response => {
        this.setState({
          Menus: response.data
        });
        // localStorage.setItem('walletMenu', true)
      })
      .catch(e => {
      });

    MenuService.getWalletSubMenus(localStorage.userId)
      .then(response => {
        this.setState({
          SubMenus: response.data
        });
      })
      .catch(e => {
        
      });

  }


  //  async componentDidMount() {
  //   const menuResponse = await fetch('/api/v1/getWalletMenus');
  //   const menus = await menuResponse.json();
  //   this.setState({ Menus: menus});

  //   const subMenuResponse = await fetch('/api/v1/getWalletSubMenus');
  //   const submenus = await subMenuResponse.json();
  //   this.setState({ SubMenus: submenus});
  // }

  render() {
    const { Menus, SubMenus, companyProfile } = this.state;
   
    return (

      <div>
        
        {/* Main Sidebar Container */ }
        <aside className="main-sidebar sidebar-white nav-pills">
          {/* Brand Logo */ }
          <Link to="/eWalletDashboard" className="brand-link">
            <img
              //  src="dist/img/SmartPay-Technolohy.JPG"
              src={"data:image/png;base64," +  companyProfile.attachment} 
              alt="Smart Pay Logo"
              className="brand-image img-circle elevation-0"
              style={ { width: '25%', height: '20%', paddingTop: '2%',opacity: ".6"  } }
            />
            <span style={ {  alignSelf: 'center' } } className="brand-text font-weight-white">eWallet</span>
          </Link>
          {/* Sidebar */ }
          <div className="sidebar" >

            {/* Sidebar Menu */ }
            <nav id={'nav1'} className="mt-2 nav navbar-nav navbar-left" >
              <ul id={'nav2'}
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">

                { Menus.map(menu =>
                  <li className="nav-item " key={ menu.appmenuId +  menu.menuname} >
                    <Link key={menu.url } to={ { pathname: menu.url } } className="nav-link bg-light active elevation-0">
                      <i className={ menu.styleClass } />
                      <p className="col-sm-5 pl-3">
                        { menu.menuname }
                      </p>
                      <i className="right fas fa-angle-right fa-rotate-90" />
                    </Link>
                    { SubMenus.map(submenu =>
                      <ul className="nav nav-treeview ml-3" key={ submenu.parentId + submenu.menuname } >
                        { menu.appmenuId === submenu.parentId &&
                          <li className="nav-item" >
                            <Link  key={ submenu.url  }  to={ { pathname: submenu.url } } className="nav-link bg-light  active elevation-0" >
                              <i className={ submenu.styleClass } />
                              <p className="col-sm-5 pl-3">
                                { submenu.menuname }</p>
                            </Link>
                          </li>
                        }
                      </ul>

                    ) }
                  </li>
                ) }

              </ul>

            </nav>
            {/* </BrowserRouter> */ }


            {/* /.sidebar-menu */ }
          </div>
          {/* /.sidebar */ }
        </aside>
      </div>
    )
  }
}
export default withRouter(Menu);
