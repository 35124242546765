import API from '../../../src/http-common';

class TopupTransactionService {
    getTopupTransactions(pageNo, pageSize, trxnType, userId) {
        return API.get('/api/v1/getTopupTransactions/' + pageNo + "/" + pageSize + "/" + trxnType + "/" + userId);
    }

    getAllTopupTransactionsWithPagination(pageNo, pageSize, userId, topupInfo) {
        return API.put("/api/v1/getAllTopupTransactionsWithPagination/" + pageNo + "/" + pageSize + "/" + userId, topupInfo);
    }

    recentTopupTrxns(customerId, userId) {
        return API.get('/api/v1/recentTopupTrxns/' + customerId +"/" + userId);
    }


    getMonthlyWalletToTopupTransfers(customerId, userId) {
        return API.get('/api/v1/getMonthlyWalletToTopupTransfers/' + customerId + "/" + userId);
    }

    getTotalCustomerBalance( userId) {
        return API.get('/api/v1/getTotalCustomerBalance/' +  userId);
    }
        
    dealersTopupTrxn(date, userId) {
        return API.get('/api/v1/dealersTopupTrxn/' + date + "/" + userId);
    }
    getNumberOfDealers(userId) {
        return API.get('/api/v1/getNumberOfDealers/' + userId);
    }

    // approveTopupOrder(orderId, parentCustomerId, dealerCustomerId, amount) {
    //     return API.get('/api/v1/approveTopupOrder/' + orderId + "/" + parentCustomerId + "/" + dealerCustomerId + "/" + amount);
    // }
    // deleteOrder(orderId) {
    //     return API.delete('/api/v1/deleteTopupOrder/' + orderId);
    // }
}
export default new TopupTransactionService()