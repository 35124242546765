import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CashMethodService from '../../../services/etopup/CashMethodService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{3,50}$/u)
const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)

class AddCashMethod extends Component {
  constructor (props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      isLoading: false,
      formErrors: {
        nameError: "",
        descriptionError: "",
        customerError: ""
      },
    }

  }

  handleSubmit = e => {
    e.preventDefault();
    const { name, description, selectOption, formErrors } = this.state
    formErrors.nameError = name == '' || name == null ? "Name is Required" : this.state.formErrors.nameError;

    if (formValid(this.state.formErrors)) {
      let cashMethodData = {
        name: this.state.name,
        description: this.state.description
      }

      this.setState({ isLoadingPop: true})
      CashMethodService.createCashMethod(cashMethodData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'name':
        formErrors.nameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphabetic and (/_) characters with min 3 and max 50" : ""
        break;
      case 'description':
        formErrors.descriptionError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  cancel() {
    this.props.history.push('/cashMethodList')
  }


  render() {
    const { formErrors } = this.state

    return (

      <div >
        {/* <Header />
        <Menu /> */}
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash Method Information</li>

                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Add Cash Method Information
                      </h4>
                    </div>

                    <form onSubmit={ this.handleSubmit }>
                      {/*<h2>Register</h2> */ }

                      <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Name'>Name:</label>
                        <input type="text" className="form-control" id='Name'
                          onChange={ this.handelChange } onBlur={ this.handelChange } name='name'
                          placeholder="Name" />

                        { formErrors.nameError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.nameError }</span>
                        ) }
                      </div>



                      <div className="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Description'>Description:</label>
                        <textarea type="" className="form-control" id='Description'
                          onChange={ this.handelChange } onBlur={ this.handelChange } name='description'
                          placeholder="Description" />
                        { formErrors.descriptionError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.descriptionError }</span>
                        ) }
                      </div>



                      <div className="row  d-flex justify-content-left ">

                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              <NotificationContainer />
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
  }
}



export default AddCashMethod;