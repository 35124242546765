import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';
import AgentService from '../../../services/common/AgentService';
import CustomersService from '../../../services/common/CustomersService';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import PhoneInput from 'react-phone-input-2';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class Agents extends Component {

    constructor (props) {

        super(props)
        this.state = {
            // For search purposes
            accountNo: null,
            fullName: null,
            mobileNo: null,
            dateFrom: null,
            dateTo: null,
            isLoading: false,
            AgentList: [
                {
                    regionId: [],
                    countryId: [],
                    customerType: []
                }
            ],
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            type: LOCALES.AGENT,
            formErrors: {
                phoneNumberError: "",
                accountNoError: "",
                fullNameError: "",
                dateToError: "",
                dateFromError: ""
            },
            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachCustomerBasedOnFilter()
            this.render()
        }
    }

    clearValue() {
        // should not reload
        this.state.fullName = ""
        this.state.mobileNo = ""
        this.state.accountNo = ""
        this.state.dateFrom = ""
        this.state.dateTo = ""
        this.state.clear = 1
    }

    searchCustomerInfo(e) {
        this.seachCustomerBasedOnFilter()
    }
    seachCustomerBasedOnFilter() {

        this.CustomerList = null;
        let FilterCustomerInfo = {
            accountNo: this.state.accountNo,
            fullName: this.state.fullName,
            mobileNo: this.state.mobileNo,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }

        CustomersService.getFilterdCustomerWithPagination(
            this.state.pageNo, this.state.pageSize, this.state.topupStatus, FilterCustomerInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    AgentList: data.content,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
              
            });
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }

        this.setState({
            formErrors
        })
    }
    editCustomer(customerMobileNo) {
        this.props.history.push(`/editCustomer/${customerMobileNo}`);
    }
    componentDidMount() {
        CustomersService.getAllCustomers(this.state.pageNo, this.state.pageSize, this.state.topupStatus)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    AgentList: data.content,
                    isLoading: true,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
                this.setState({ isLoading: true })
            });
    }
    // Enable for disabled customer
    async disableCustomer(mobileNo) {
        if (window.confirm("Are you sure you want to disable this customer?")) {
            try {
                const response = await CustomerVerificationService.disableCustomerAccount(mobileNo);
                //  toast.success("Disabled successfully");
                NotificationManager.success("Disabled successfully", "Account Disabling");
            } catch (error) {
                // toast.error("Something went wrong !!");
                NotificationManager.error("Something went wrong !!", "Account Disabling");
            }
        }
        else {
        }
    }
    // Disable for customer account customer
    async enableCustomer(mobileNo) {
        if (window.confirm("Are you sure you want to enable this customer?")) {
            try {
                const response = await CustomerVerificationService.enableCustomerAccount(mobileNo);
                NotificationManager.success("Enabled successfully", "Account Enabling");
            } catch (error) {
                // toast.error("Something went wrong !!");
                NotificationManager.error("Something went wrong !!", "Account Enabling");
            }
        }
        else {
        }
    }

    async deleteAgent(mobileNo) {
        try {
            const response = await CustomersService.deleteCustomer(mobileNo);
            NotificationManager.success('Deleted Successfully', 'Agent deletion');
        } catch (error) {
            //toast.error("Something went wrong !!");
            NotificationManager.error('Something went wrong', 'Error Message');
        }
    }

    render() {
        const { AgentList, isLoading, formErrors, pageNo, totalPage, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Agent List</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Agent List Information</div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">
                                                    <div>
                                                        <label htmlFor='mobileNo' >Mobile No</label>
                                                        <PhoneInput countryCodeEditable={ false } value={ this.state.mobileNo }
                                                            country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                            placeholder="Enter phone number" id='mobileNo'
                                                            inputStyle={ { width: '100%', height: 'auto' } }
                                                            onChange={ phone => this.onChangeMobileNo(phone) } />
                                                    </div>
                                                    <div>
                                                        <label htmlFor='accountNo' > Account No</label>
                                                        <input type="text" className="form-control" id='accountNo'
                                                            onChange={ e => this.state.accountNo = e.target.value }
                                                            placeholder="Account No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='fullName'>Full Name</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='fullName'
                                                            onChange={ e => this.state.fullName = e.target.value }
                                                            placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='dateFrom' >Date From</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateFrom = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateFromError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                                                        ) }
                                                    </div>

                                                    <label>Date To</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateTo = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateToError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchCustomerInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/newcustomer', state: { type: "Agent", formName: "agent Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Full Name</th>
                                                    <th>Account No</th>
                                                    <th>Mobile Number</th>
                                                    {/* <th>Balance</th> */ }
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { AgentList !== undefined && AgentList.map(agent =>

                                                    <tr>
                                                        <td className='p-1 '>{ agent.firstName + " " + agent.middleName + " " + agent.lastName }</td>
                                                        <td className='p-1 '>{ agent.accountNo }</td>
                                                        <td className='p-1 '>{ agent.mobileNo }</td>
                                                        {/* <td className='p-1 '>{agent.balance}</td> */ }

                                                        <td className='p-1'>
                                                            { agent.status === 0 &&
                                                                <Link to={ { pathname: '/verifyCustomer', state: { mobileNo: agent.mobileNo, operation: "activation" } } }>
                                                                    <button style={ { marginLeft: "2px" } } className="btn btn-secondary btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Activate Account" /> </button>
                                                                </Link>
                                                            }
                                                            { agent.status === 1 &&
                                                                <Link to={ { pathname: '', state: { mobileNo: agent.mobileNo, operation: "deactivation" } } }> <button style={ { marginLeft: "2px" } } onClick={ () => this.disableCustomer(agent.mobileNo) } className="btn btn-success btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Disable Account" /> </button>
                                                                </Link>
                                                            }
                                                            { agent.status === 2 &&
                                                                <Link to={ { pathname: '', state: { mobileNo: agent.mobileNo, operation: "deactivation" } } }> <button style={ { marginLeft: "2px" } } onClick={ () => this.enableCustomer(agent.mobileNo) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Enable Account" /> </button>
                                                                </Link>
                                                            }
                                                            {/* <button className="btn btn-warning btn-sm"><i className=" btn-warning btn-sm tip" /> </button> */ }
                                                            <Link to={ { pathname: '/customerdetail', state: { CustomerType: "Agent", operationType: "View", mobileNo: agent.mobileNo } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                            </Link>
                                                            <button style={ { marginLeft: "2px" } } onClick={ () => this.editCustomer(agent.mobileNo) } className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                            <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteAgent(agent.mobileNo) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button>
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default Agents