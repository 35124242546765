import React, { Component } from 'react'
import LoginService from '../../services/common/LoginService';

export default class Footer extends Component {
  
  
  constructor (props) {
    super(props);
    this.state = {
        companyProfile:[]
    }

}

  componentDidMount() {
    LoginService.companyProfileInfo()
    .then(response => response.data)
    .then((data) => {
        this.setState({
            companyProfile: data
        });
    });
}
  render() {
    const {companyProfile} = this.state
    return (
      <div className='footer'>
        <footer className="main-footer">
          <strong>
            Copyright© <a href="http://www.smartpay-tech.com">{companyProfile.companyName}</a>.
          </strong>
        </footer>
      </div>
    )
  }
}