import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./popup.css"
 
const PopupUserReq = props => {
  return (
    <div className="user-box">
      <div className="box">   
        {props.content}
      </div>
    </div>
  );
};
 
export default PopupUserReq;