import React, { Component } from 'react'

import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withRouter } from "react-router-dom";
import Menu from '../../wallet/Menu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import "react-datepicker/dist/react-datepicker.css"
import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';
import BankInfoService from '../../../services/common/BankInfoService';
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
      val.length > 0 && (valid = false);
    });
    return valid;
  }
  
  const accountNoRegEx = RegExp(/^(?=[1-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u)
  const balanceRegEx = RegExp(/^(?=[0-9])([\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{0,50}$/u)

class EditBanksInfo extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            id: this.props.location.state.id,
            isLoading: false,
            isLoadingPop: false,
            bankInfoIds: [],
            defultStatus: 0,

            companyBankInfoData: {
                customerId: [
                  { id: 0 }
                ],
                bankInfoId: [
                  { id: 0 }
                ],
                bankAccount: 0,
                balance: "",
                defultStatus: 0
              },
              formErrors: {
                bankAccountError: "",
                balanceError:"",
                bankInfoIdError: ""
              }
            
        };    
        this.onChangeCompanyBankInfo = this.onChangeCompanyBankInfo.bind(this);
        this.onChangeCompanyBankAccount = this.onChangeCompanyBankAccount.bind(this);
        this.onChangeCompanyBankBalance = this.onChangeCompanyBankBalance.bind(this);
    }
  
    onChangeCompanyBankInfo(e) {
        const bankInfoId = e.target.value;

        this.setState(function (prevState) {
            return {
                companyBankInfoData: {
                    ...prevState.companyBankInfoData,
                    bankInfoId: bankInfoId
                }
            };
        });
    }
    onChangeCompanyBankAccount(e) {
        const bankAccount = e.target.value;
        this.setState(function (prevState) {
            return {
                companyBankInfoData: {
                    ...prevState.companyBankInfoData,
                    bankAccount: bankAccount
                }
            };
        });
    }
    onChangeCompanyBankBalance(e) {
        const balance = e.target.value;
        this.setState(function (prevState) {
            return {
                companyBankInfoData: {
                    ...prevState.companyBankInfoData,
                    balance: balance
                }
            };
        });
    }
    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
    
        let formErrors = this.state.formErrors;
        switch (name) {
        case 'bankAccount':
            this.state.bankInfoData.bankAccount = value
            formErrors.bankAccount =
              value.length == 0 || value.length < 10 || !accountNoRegEx.test(value) ? name + " Sholud be digits with a min 10 & max 50" : "";
            break;
        case 'balance':
            this.state.bankInfoData.balance = value
            formErrors.balanceError =
                !balanceRegEx.test(value) ? name + " Sholud be digits with a min 0 & max 1000000000" : ""
            break;
          default:
            break;
    
        }
    
        this.setState({
          formErrors, [ name ]: value
        })
      }



    Cancel = (e) => {
        this.props.history.push('/companyBanksInfoList');
    }


    OnChangeBankValue = e => {
        const { formErrors } = this.state
        this.state.companyBankInfoData.bankInfoId.id = e.target.value;
        let bankInfoId = e.target.value;
        if (bankInfoId == "----please select---") bankInfoId = ""
        formErrors.bankInfoIdError = bankInfoId == "" ? 'You must make a selection' : "";
        this.setState(function (prevState) {
          return {
            bankInfoId: {
              ...prevState.bankInfoId,
              id: bankInfoId
            },
            formErrors
          };
        });
    
      }
    componentDidMount() {
        this.getCustomerBankInfo(this.props.location.state.id);  
        BankInfoService.getBankInfos()
        .then(response => {
          this.setState({
            bankInfoIds: response.data
  
          });
        })
        .catch(e => {
        });
    }



    getCustomerBankInfo(id) {

        CustomerBankInfoService.getCustomerBankInfo(id, localStorage.userId)
        .then(response => {
          this.setState({
            companyBankInfoData: response.data,
            bankInfoId: response.data.bankInfoId,
            customerId: response.data.customerId,
            isLoading: true
          });
        })
        .catch(e => {
        });
      }



    handleSubmit = e => {
        e.preventDefault();

        const { bankAccount, bankInfoId, balance, formErrors } = this.state
        formErrors.bankAccountError = bankAccount == '' || bankAccount == null || bankAccount == undefined ? 'Please enter the account number' : "";
        formErrors.bankInfoIdError = bankInfoId.id == '' || bankInfoId.id == null || bankInfoId.id == undefined ? 'You must make a selection' : "";
        formErrors.balanceError = balance == '' || balance == null || balance == undefined ? 'Please enter the balance' : "";
        var data = new FormData();
        data.append("id", this.props.location.state.id);
        data.append("customerId", localStorage.customerIdentity);
        data.append("bankInfoId", this.state.bankInfoId.id);
        data.append("defultStatus", this.state.defultStatus);
        data.append("balance", this.state.companyBankInfoData.balance);
        data.append("bankAccount", this.state.companyBankInfoData.bankAccount);
        data.append("userId", localStorage.userId);

        this.setState({ isLoadingPop: true})
        CustomerBankInfoService.updateCustomerBankInfo(data)
            .then(
            (response) => {
                NotificationManager.success('Updated Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 1000);
                this.setState({ isLoadingPop: false})
                this.Cancel()
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 1000);
                this.setState({ isLoadingPop: false})
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 1000);
                this.setState({ isLoadingPop: false})
            });
    }


    render() {

        const { companyBankInfoData,bankInfoIds, isLoading, formErrors, } = this.state;
   
        this.state.selectOption = {
            label: companyBankInfoData.customerId.fullName,
            value: companyBankInfoData.customerId.id
          }
      
          let bankList = bankInfoIds.length > 0
            && bankInfoIds.map((item, i) => {
              return (
                <option key={ i } value={ item.id }>{ item.name }</option>
      
              )
            }, this);

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Info</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                           
                                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6  ">
                                            <label htmlFor='BankList'>Bank Name:</label>
                                                <select className="form-control " value={ companyBankInfoData.bankInfoId.id }
                                                onChange={ this.OnChangeBankValue } id='BankList'>
                                                <option value={ null }>----please select---</option>
                                                { bankList }
                                                </select>
                                                { formErrors.bankInfoIdError.length > 0 && (
                                                <span style={ { color: "#F61C04" } }>{ formErrors.bankInfoIdError }</span>
                                                ) }
                                            </div>
                                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                <label htmlFor='AccountNo'>Account No:</label>
                                                <input type="text" id='AccountNo' className="form-control" 
                                                value={ companyBankInfoData.bankAccount } onChange={ this.onChangeCompanyBankAccount } required="true"
                                                // onChange={e => this.state.bankAccount = e.target.value}
                                                placeholder="Account No"  />

                                                { formErrors.bankAccountError.length > 0 && (
                                                <span style={ { color: "#F61C04" } }>{ formErrors.bankAccountError }</span>
                                                ) }
                                            </div>
                                            {/* {customerList.customerType && customerList.customerType.type ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? */}
                                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                <label htmlFor='balance'>Balance:</label>
                                                <input type="text" id='balance' className="form-control" name='balance'
                                                 value={ companyBankInfoData.balance } onChange={ this.onChangeCompanyBankBalance } required="true"
                                                // onChange={e => this.state.bankAccount = e.target.value}
                                                placeholder="Balance"  />

                                                { formErrors.balanceError.length > 0 && (
                                                <span style={ { color: "#F61C04" } }>{ formErrors.balanceError }</span>
                                                ) }
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>
                                {this.state.isLoadingPop && <LoadingPopup
                                    content={<>
                                            <form>
                                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                </form>
                                    </>}
                                />}

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(EditBanksInfo);