import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast, faCheckSquare, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Menu from '../../wallet/Menu';
import 'react-notifications/lib/notifications.css';
import AsyncSelect from 'react-select/async'
import BankInfoService from '../../../services/common/BankInfoService';
import { LOCALES } from '../../common/Constant'
import Header from '../Header';
import API from '../../../http-common';
import ReactLoading from "react-loading";

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u)
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class CustomerBankInfoList extends Component {
  constructor (props) {

    super(props)

    this.state = {
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      customerBankInfoList: [],
      counter: 1,
      id: 0,
      isExpand: true,
      selectOption: '',
      customerList: "",
      bankInfoIds: [],
      bankInfoId: '',
      searchValue: null,
      isLoading: false,

      fromDate: '',
      toDate: '',
      isCompany: false,

      formErrors: {
        customerError: '',
        bankNameError: "",
        dateToError: "",
        dateFromError: ""
      },

    }


    this.editCustomerBankInfo = this.editCustomerBankInfo.bind(this);
    this.customerBankInfoDetail = this.customerBankInfoDetail.bind(this);
    this.addCustomerBankInfo = this.addCustomerBankInfo.bind(this);

  }

  gotoEnteredPage = (pageNumber) => {
    this.state.pageNo = pageNumber
    this.searchCashCustomerBankInfosBasedOnFilter()
    this.render()
  }

  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    this.searchCashCustomerBankInfosBasedOnFilter()
    this.render()

  }

  gotoPageFirst = () => {
    this.state.pageNo = 1
    this.searchCashCustomerBankInfosBasedOnFilter()
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      this.searchCashCustomerBankInfosBasedOnFilter()
      this.render()

    }
  }

  nextPage = () => {
    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      this.searchCashCustomerBankInfosBasedOnFilter()
      this.render()
    }
  }

  addCustomerBankInfo() {
    this.props.history.push(`/addCustomerBankInfo`);
  }

  customerBankInfoDetail(id) {
    this.props.history.push({pathname: "/viewCustomerBankInfo", state: {id: id}})
  }

  editCustomerBankInfo(id) {
    this.props.history.push({pathname: "/editCustomerBankInfo", state: {id: id}})
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (searchValue) => {
    const { formErrors } = this.state
    formErrors.customerError =
      searchValue.length < 4 || customerRegEx.test(searchValue) ? " Please insert custmer Name or account no or mobile number" : ""

    if (searchValue) {
      this.setState({
        searchValue,
        formErrors
      });
    }
  }

  bankValue(e) {
    this.state.bankInfoId = e.target.value
    this.state.bankInfoId.id = e.target.value
  }


  componentDidMount() {

    

    this.searchCashCustomerBankInfosBasedOnFilter()

    BankInfoService.getBankInfos()
      .then(response => {
        this.setState({
          bankInfoIds: response.data
        });
        
      })
      .catch(e => {
        
      });

     this.setState({
      isCompany:  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
    });

   
  }

  filterCashOutInfo(e) {
    const { formErrors, fromDate, toDate } = this.state
    formErrors.dateFromError = ''
    formErrors.dateToError = ''

    if (fromDate != '') {
      formErrors.dateToError =
        toDate == '' ? 'date to is required' : ''
    }

    if (toDate != '') {
      formErrors.dateFromError =
        fromDate == '' ? 'date from is required' : ''
    }
    if (this.state.fromDate != '' && this.state.toDate != '') {
      if (this.state.fromDate > this.state.toDate) {
        formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
      }
    }

    if (formValid(this.state.formErrors)) {

      this.state.clear = 0;
      this.state.pageNo = 1;
      this.searchCashCustomerBankInfosBasedOnFilter();


    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }

  }

  clearValue() {
    this.state.searchValue = null
    this.state.bankInfoId = ''
    this.state.fromDate = ''
    this.state.toDate = ''
    this.state.clear = 1
  }

  searchCashCustomerBankInfosBasedOnFilter() {
    let customerId =  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? this.state.searchValue && this.state.searchValue.value : localStorage.customerIdentity

    this.state.customerBankInfoList = null;
    let FilterValue = {
      customerId: customerId,
      bankInfoId: this.state.bankInfoId,
      dateFrom: this.state.fromDate,
      dateTo: this.state.toDate,
      userId: localStorage.userId
    }

    CustomerBankInfoService.getCustomerBankInfosBasedOnFilter(
      FilterValue, this.state.pageNo, this.state.pageSize)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          customerBankInfoList: data.customerBankClone,
          totalPage: data.totalPages,
          isLoading: true,
          totalelement: data.totalelement,
          pageNo: data.pageNumber + 1
        });
        this.setState({ isLoading: true })
      });

  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.state.clear = 0
      this.searchCashCustomerBankInfosBasedOnFilter()

    }
  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }

  async deleteCustomerBankInfo(id) {
    if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
      try {
        const response = await CustomerBankInfoService.deleteCustomerBankInfo(id);
        NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCashCustomerBankInfosBasedOnFilter()
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }
  }

  async defultCustomerBankInfo(id) {
    debugger
    if (window.confirm(`${process.env.REACT_APP_DEFAULT_CONFIRM_MSG}` + "?")) {
      try {
        const response = await CustomerBankInfoService.changedefultCustomerBankInfo(id, localStorage.userId);
        NotificationManager.success(`${process.env.REACT_APP_DEFAULT_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
        this.searchCashCustomerBankInfosBasedOnFilter()
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
      }
    }
  }

  render() {
    const { customerBankInfoList, bankInfoIds, isLoading, pageNo, pageSize, totalPage, formErrors,isCompany } = this.state;
    pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1
    let bankList = bankInfoIds.length > 0
      && bankInfoIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>

        )
      }, this);
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >Customer Bank Information List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Display Customers Bank Information</div>

                    
                    <form onSubmit={ this.clear } hidden={ this.state.isExpand } noValidate>
                      <div className="row" hidden={ this.state.isExpand }>
                        {isCompany ?
                        <>
                        <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6  ">
                          <label htmlFor='CustomerList'>Customer:</label>
                          <div style={ { fontSize: 'smaller' } }>
                            <AsyncSelect id='CustomerList'
                              placeholder={ "Search by name, account no or mobile number" }
                              value={ this.state.searchValue }
                              isClearable={ true }
                              loadOptions={ this.fatchData }
                              isLoading={ this.state.searchValue === {} &&
                                this.state.searchValue === undefined &&
                                this.state.searchValue === null ? true : false
                              }

                              onChange={ (e) => {
                                this.onSearchChange(e)
                              } }
                              defaultOptions={ false }
                            />
                            { formErrors.customerError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.customerError }</span>
                            ) }
                          </div>
                        </div>
                      
                        <div className="col-md-3">
                          <label htmlFor='BankList'>Bank Name:</label>
                          <select className="form-control "
                            value={ this.state.bankInfoId.id }
                            onChange={ e => this.bankValue(e).bind(this) } id='BankList'>
                            <option value={ null }>----please select---</option>
                            { bankList }
                          </select>                          
                        </div>
                        
                        <div className="col-md-3">
                          <label htmlFor='dateFrom' >Date From</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateFrom'
                              onChange={ e => this.state.fromDate = e.target.value }
                              placeholder="Date" />
                            { formErrors.dateFromError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                            ) }

                          </div>

                          <label htmlFor='dateTo'>Date To</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateTo'
                              onChange={ e => this.state.toDate = e.target.value }
                              placeholder="Date" />
                            { formErrors.dateToError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                            ) }
                          </div>
                          
                        </div>
                        </>
                         :
                         <>
                         <div className="col-md-3">
                         <label htmlFor='BankList'>Bank Name:</label>
                         <select className="form-control "
                           value={ this.state.bankInfoId.id }
                           onChange={ e => this.bankValue(e).bind(this) } id='BankList'>
                           <option value={ null }>----please select---</option>
                           { bankList }
                         </select>                          
                       </div>
                         <label htmlFor='dateFrom' >Date From</label>
                         <div className="w-90 pull-left">
                           <input type="date" className="form-control" id='dateFrom'
                             onChange={ e => this.state.fromDate = e.target.value }
                             placeholder="Date" />
                           { formErrors.dateFromError.length > 0 && (
                             <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                           ) }

                         </div>

                         <label htmlFor='dateTo'>Date To</label>
                         <div className="w-90 pull-left">
                           <input type="date" className="form-control" id='dateTo'
                             onChange={ e => this.state.toDate = e.target.value }
                             placeholder="Date" />
                           { formErrors.dateToError.length > 0 && (
                             <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                           ) }
                         </div>
                        </>
                        }
                      </div>
                     

                      <br />

                      <div>
                        <button title={ "Search" }
                          className="btn btn-sm btn-success primary m-1 float-right"
                          onClick={ () => this.filterCashOutInfo() }>Search</button>
                        <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                          className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                      </div>
                    </form>
                    <div style={ { clear: 'both' } }></div>
                    <br />


                    <button type="button" title={ "Add" }
                      className="btn btn-sm btn-success primary m-1  float-left"
                      onClick={ (e) => this.addCustomerBankInfo() }>
                      <i className="fas fa-plus" /> Add
                    </button>

                    <button type='button' title={ "Show/Hide Filter" }
                      className="btn btn-sm btn-success primary m-1 float-left"
                      onClick={ () => this.filterToggle() }>
                      <FontAwesomeIcon icon={ faFilter } />
                      Filter
                    </button>

                    <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                      <thead className=' thead-light'>
                        <tr style={ { marginTop: 0 } }>
                          <th>No.</th>
                          <th>Customer Name</th>
                          <th>Bank Name</th>
                          <th>Account No</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className='content-wrapper '>

                        { customerBankInfoList && customerBankInfoList.map(customerBankInfo =>

                          <tr key={ customerBankInfo.id }>
                            <td>{ this.state.counter++ }</td>
                            <td>{ customerBankInfo.fullName }</td>
                            <td>{ customerBankInfo.bankName }</td>
                            <td>{ customerBankInfo.bankAccount }</td>
                            <td>


                              <button title={ "Edit" }
                                onClick={ () => this.editCustomerBankInfo(customerBankInfo.id) }
                                className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                              <button style={ { marginLeft: "2px" } }
                                title={ customerBankInfo.defultStatus === 1 ? "Default" : "Make Default" }
                                disabled={ customerBankInfo.defultStatus === 1 ? true : false }
                                onClick={ () => this.defultCustomerBankInfo(customerBankInfo.id) }
                                className="btn btn-success btn-sm action-buttons">
                                <FontAwesomeIcon icon={ faCheckSquare } />
                              </button>

                              <button style={ { marginLeft: "2px" } } title={ "Delete" } disabled={ customerBankInfo.defultStatus === 1 ? true : false }
                                onClick={ () => this.deleteCustomerBankInfo(customerBankInfo.id) }
                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                            </td>
                          </tr>

                        ) }
                      </tbody>
                    </table>
                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{ '' }
                        <strong>
                          { pageNo } of { totalPage }
                        </strong>
                        { '' }
                      </span>
                      <span>
                        | Go To Page : { '' }
                        <input type='number' defaultValue={ pageNo }
                          onChange={ (e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          } } />
                      </span>
                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackwardFast } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackward } />
                      </button>

                      <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                        <FontAwesomeIcon icon={ faForward } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                        <FontAwesomeIcon icon={ faForwardFast } />
                      </button>
                    </div>
                  </div>
                </div>
                <NotificationContainer />
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>
            {/* /.content */ }
          </div>
        </div>
      )
    }
  }
}


export default CustomerBankInfoList;