import API from '../../http-common';

class CustomerBankInfoService{

  // get all customer related bank  Information 
  getCustomerBankInfos() {
    return API.get("/api/v1/getCustomerBanks");
  }

  // create  customer related bank  Information 
  createCustomerBankInfo(customerBankInfo) {
    return API.post("/api/v1/createCustomerBank", customerBankInfo);
  }

  // get single customer related bank  Information 
  getCustomerBankInfo(id, userId) {
    return API.get("/api/v1/getCustomerBankById/" + id + "/" + userId);
  }  

  // remove  customer related bank  Information 
  deleteCustomerBankInfo(id,userId) {
    return API.delete('/api/v1/deleteCustomerBank' + '/' + id+'/'+userId);
  }

  // update  customer related bank  Information 
  updateCustomerBankInfo(customerBankInfo) {
    return API.put("/api/v1/updateCustomerBank" , customerBankInfo);
  }

   // update  customer related bank  Information 
   changedefultCustomerBankInfo(id, userId) {
    return API.get("/api/v1/defultCustomerBank" + "/" + id + "/" + userId) ;
  }

    // get  customer related bank Information 
    getCustomerBankInfosBasedOnFilter(filterValue,pageNo, pageSize) {
      return API.put("/api/v1/getFilteredCustomerBankInfos/" + pageNo + "/" + pageSize, filterValue);
    }

       // get  customer related bank Information 
       getCompanyBankInfosBasedOnFilter(filterValue,pageNo, pageSize) {
        return API.put("/api/v1/getCompanyBankInfos/" + pageNo + "/" + pageSize, filterValue);
      }
}

export default new CustomerBankInfoService()