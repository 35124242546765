import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../../components/wallet/Menu';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactLoading from "react-loading";
import { LOCALES } from '../../common/Constant';
import Header from '../../common/Header';
import KioskCustomerAssignmentService from '../../../services/wallet/KioskCustomerAssignmentService';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';

class CashCollectorList extends Component {
    constructor (props) {
        super(props)
        this.state = {

            kioskId: null,
            userId: null,
            isLoading: false,
            kioskSelectOption:null,
            userIdSelectOption: null, 
            selectedId:null,
            masterPageNo: 1,
            masterPageSize:LOCALES.PAGE_SIZE,

            detailPageNo:1,
            detailPageSize:LOCALES.PAGE_SIZE,

            masterTotalPage: 0, 
            masterTotalElement: 0,
             
            detailTotalPage:0,
            detailTotalElement:0,

            assignedCashCollectorList:[], 
            assignedKiosks:[],
            parentCounter: 1, 
            childCounter:1,
            isChildExist:false,
            displayPaginationStatus:1,
            id: 0,
            flag: false,
            isExpand: true,
            isListExpand:true,
            isClicked:false
        }
        this.clearValue = this.clearValue.bind(this);
    }
    gotoDetailEnteredPage = (pageNumber,cashCollectorId) => {     

        this.state.detailPageNo = pageNumber
        this.searchAssignedKiosksOnFilter(cashCollectorId)
        this.render()
        this.setState({
            isClicked:true
        })
    }
    gotoDetailPageLast = (cashCollectorId) => {
        this.state.detailPageNo = this.state.detailTotalPage
        this.searchAssignedKiosksOnFilter(cashCollectorId)
        this.render()

        this.setState({
            isClicked:true
        })
    }
    gotoDetailPageFirst = (cashCollectorId) => {
        this.state.detailPageNo = 1
         this.searchAssignedKiosksOnFilter(cashCollectorId)
        this.render()

        this.setState({
            isClicked:true
        })
    }

    privDetailPage = (cashCollectorId) => {
        let pages = 1
        if (this.state.detailPageNo > pages) {
            this.state.detailPageNo = this.state.detailPageNo - pages
            this.searchAssignedKiosksOnFilter(cashCollectorId)
            this.render()

        }
    }

    nextDetailPage = (cashCollectorId) => {
      
        let totalPages = this.state.detailTotalPage;
        if (this.state.detailPageNo < totalPages) {
            this.state.detailPageNo = this.state.detailPageNo + 1
            this.searchAssignedKiosksOnFilter(cashCollectorId)
            this.render()
        }
    
        this.setState({
            isClicked:true
        })
    }


    gotoMasterEnteredPage = (pageNumber) => {   
        this.state.masterPageNo = pageNumber
        this.searchKioskCashCollectorAssignmentOnFilter()
        this.render()
    }
    gotoMasterPageLast = () => {
        this.state.masterPageNo = this.state.masterTotalPage
        this.searchKioskCashCollectorAssignmentOnFilter()
        
        this.render()

    }
    gotoMasterPageFirst = () => {
        this.state.masterPageNo = 1
        this.searchKioskCashCollectorAssignmentOnFilter()
        this.render()
    }

    privMasterPage = () => {
        let pages = 1
        if (this.state.masterPageNo > pages) {
            this.state.masterPageNo = this.state.masterPageNo - pages
            this.searchKioskCashCollectorAssignmentOnFilter()
            this.render()
        }
    }

    nextMasterPage = () => {

        let totalPages = this.state.masterTotalPage;
        if (this.state.masterPageNo < totalPages) {
            this.state.masterPageNo = this.state.masterPageNo + 1
            this.searchKioskCashCollectorAssignmentOnFilter()
            this.render()
        }
    }
    clearValue() {
        this.state.userId = null
        this.state.clear = 1
    }


    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.searchKioskCashCollectorAssignmentOnFilter()
            this.searchAssignedKiosksOnFilter()
        }
    }

    fatchUser = (inputValue, callback) => { 
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getUsersBySearchParam/" + inputValue )
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({ 
                            userList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }else {
            callback([])
          }
    }

    onUserChange = (userIdSelectOption) => { 
        if (userIdSelectOption) {
            this.setState({
                userIdSelectOption:userIdSelectOption.value
            });
        }
    }

    searchKioskAssignmentInfo(e) {
        this.searchKioskCashCollectorAssignmentOnFilter()
    }
 
    searchKioskCashCollectorAssignmentOnFilter() {
       // console.log(this.state.kioskSelectOption)
          if(this.state.userIdSelectOption!==null)
          {
            this.state.userId=this.state.userIdSelectOption
          }
        let FilterSalsInfo = {
            kioskId:  this.state.kioskId,
            userId: this.state.userId,
            }

        KioskCustomerAssignmentService.findAssignedKiosks(
            this.state.masterPageNo, this.state.masterPageSize, localStorage.userId, FilterSalsInfo)
            .then(response => response.data)
            .then((data) => {
                    this.setState({
                        isLoading: true,
                        assignedCashCollectorList: data.kioskBalanceList,
                        isOpen: false,
                        masterTotalPage: data.totalPages,
                        masterTotalElement: data.totalelement,
                        masterPageNo: data.pageNumber + 1
                    });
               
               
                this.setState({ isLoading: true })
            });

    }

    componentDidMount() { 
        this.searchKioskCashCollectorAssignmentOnFilter()
        this.searchAssignedKiosksOnFilter()  
    }

    searchKioskInfo(cashCollectorId){  
        this.searchAssignedKiosksOnFilter(cashCollectorId)
        this.setState({
            isListExpand: !this.state.isListExpand,
        })
    }
    searchAssignedKiosksOnFilter(cashCollectorId)
       {
        if(cashCollectorId===undefined)
        {
            cashCollectorId=1
        }
         KioskCustomerAssignmentService.findKioskAssignmentByCashCollectorId(cashCollectorId,this.state.detailPageNo, this.state.detailPageSize, localStorage.userId)
                .then(response => response.data)
                .then((data) => {
                        this.setState({
                            isLoading: true,
                            selectedId:cashCollectorId,
                            isClicked:true,
                            assignedKiosks: data.kioskBalanceList,
                            isOpen: false,
                            detailTotalPage: data.totalPages,
                            detailTotalElement: data.totalelement,
                            detailPageNo: data.pageNumber + 1
                        });
                
                    this.setState({ isLoading: true })
                });
       }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    render() {
        const { assignedCashCollectorList,assignedKiosks,selectedId, formErrors, masterPageNo,masterPageSize,detailPageNo,detailPageSize, isLoading, masterTotalPage,detailTotalPage } = this.state;


        masterPageNo == 1 ? this.state.parentCounter = 1 : this.state.parentCounter = (masterPageSize * (masterPageNo - 1)) + 1
        detailPageNo == 1 ? this.state.childCounter = 1 : this.state.childCounter = (detailPageSize * (detailPageNo - 1)) + 1

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                < div >
                    {/* <Header />
                    <Menu />

                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }

                    <div className="content-wrapper bg-white">

                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">

                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Cash Collectors</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                         <div className="form-title" >Cash Collectors List</div> 
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">
                                                    <div>
                                                        <label htmlFor='mobileNo' >Sales Team</label>
                                                        <AsyncSelect id='userList'
                                                            className="select"
                                                            placeholder={ "Search  by name or mobile" }
                                                            isClearable={ true }

                                                            loadOptions={ this.fatchUser }
                                                            isLoading={ this.state.userIdSelectOption === {} &&
                                                                this.state.userIdSelectOption === undefined &&
                                                                this.state.userIdSelectOption === null ? true : false
                                                            }
                                                            onChange={ (e) => {
                                                                this.onUserChange(e)
                                                            } }
                                                            defaultOptions={ false }
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-4 pt-2'>
                                                <button type="button" title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"  
                                                    onClick={ () => this.searchKioskAssignmentInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                </div>
                                            </div>

                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />

                                        <Link to={ { pathname: '/addKioskCustomerAssignmentList' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button>
                                        { isLoading &&
                                            <table className="table  border" style={ { fontSize: 'smaller' } }  >
                                                <thead className='thead-light'>
                                                    <tr style={ { marginTop: 0 } }>
                                                        <th style={{width:'5%'}}></th>
                                                        <th style={{width:'5%'}}>No.</th>
                                                        <th style={{width:'30%'}}>Full Name</th>
                                                        <th>Count</th>
                                                    </tr>
                                                </thead>
                                                <tbody>            
                                                    { assignedCashCollectorList  && assignedCashCollectorList.map(assignedCashCollector =>
                                                     <>
                                                        <tr key={ assignedCashCollector.cashCollectorId } >
                                                            <td className='p-0 ' style={{width:'4%'}}>{                
                                                                <button style={{padding:'0px 1px 0px 3px',textAlign:'top',marginLeft: "2px" } } onClick={ () => this.searchKioskInfo(assignedCashCollector.cashCollectorId) }  className="btn btn-light btn-sm" title="View detail">
                                                                    {!this.state.isListExpand && this.state.selectedId===assignedCashCollector.cashCollectorId ? <i className="nav-icon fa fa-minus" style={{fontSize:'smaller'}}/>  : <i className="nav-icon fa fa-plus" style={{fontSize:'smaller'}}/> }
                                                                </button>
                                                                    }
                                                            </td>
                                                            <td className='p-0 ' style={{width:'5%'}}>{this.state.parentCounter++}</td>
                                                            <td className='p-0 ' style={{width:'30%'}}>{ assignedCashCollector.fullName }</td>
                                                            <td className='p-0 '>{ assignedCashCollector.count}</td>
                                                        </tr>
                                                        <tr  hidden={ this.state.isListExpand}  >
                                                           { this.state.selectedId===assignedCashCollector.cashCollectorId &&
                                                            <td colSpan='4' style={{padding:'0px',border:'none', borderSpacing:'0px', }}>
                                                             <table className='table border-top border-bottom' style={{tableLayout:'fixed',width:'100%', cellspacing:"0", cellpadding:"0"}}> 
                                                                 
                                                                { assignedKiosks && assignedKiosks.map(assignedKiosk =>
                                                                 <tbody  key={ assignedCashCollector.cashCollectorId }> 
                                                                 { assignedCashCollector.cashCollectorId===assignedKiosk.userId &&
                                                                  <tr>
                                                                    <td style={{width:'5%'}}></td>
                                                                    <td className='p-0 ' style={{width:'5%'}}>{this.state.childCounter++}</td> 
                                                                    <td className='p-0 ' style={{width:'30%'}}>{ "Kiosk - " + assignedKiosk.kioskId }</td>
                                                                    <td className='p-0 '></td>
                                                                  </tr>
                                                                 }
                                                                 </tbody> 
                                                                 )}
                                                                 </table> 
                                                            </td>
                                                             }
                                                        </tr>
                                                         <tr className=" float-sm-center" hidden={ this.state.isListExpand}>
                                                             { this.state.selectedId===assignedCashCollector.cashCollectorId &&
                                                              <td colSpan='4'  style={{padding:'0px',border:'none', borderSpacing:'0px', paddingRight:'25px'}} >                                                                
                                                                <div>
                                                                    <div >
                                                                        <span>
                                                                            page{ '' }
                                                                            <strong>
                                                                                { detailPageNo } of { detailTotalPage }
                                                                            </strong>
                                                                            { '' }
                                                                        </span>
                                                                        <span>
                                                                            | Go To Page : { '' }
                                                                            <input type='number' defaultValue={ detailPageNo }
                                                                                onChange={ (e) => {
                                                                                    const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                                                    this.gotoDetailEnteredPage(pageNumber,assignedCashCollector.cashCollectorId)
                                                                                } } />
                                                                        </span>
                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoDetailPageFirst(assignedCashCollector.cashCollectorId) } disabled={ detailPageNo === 1 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackwardFast } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privDetailPage(assignedCashCollector.cashCollectorId) } disabled={ detailPageNo === 1 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackward } />
                                                                        </button>

                                                                        <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ detailPageNo } value={ detailPageNo } readOnly />

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextDetailPage(assignedCashCollector.cashCollectorId) } disabled={ detailPageNo === detailTotalPage ? true : false }>
                                                                            <FontAwesomeIcon icon={ faForward } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoDetailPageLast(assignedCashCollector.cashCollectorId) } disabled={ detailPageNo === detailTotalPage ? true : false } >
                                                                            <FontAwesomeIcon icon={ faForwardFast } />
                                                                        </button>
                                                                    </div>
                                                                    </div> 
                                                                </td>
                                                              }
                                                         </tr>
                                                        
                                                        </>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        }
                                        {/* <div className="clearfix">&nbsp;</div> */}
                                        <div className=" float-sm-center pt-2" style={{fontSize:'smaller'}} hidden={ !this.state.isListExpand}>
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { masterPageNo } of { masterTotalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ masterPageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoMasterEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoMasterPageFirst() } disabled={ masterPageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privMasterPage() } disabled={ masterPageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ masterPageNo } value={ masterPageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextMasterPage() } disabled={ masterPageNo === masterTotalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoMasterPageLast() } disabled={ masterPageNo === masterTotalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >
            )
        }
    }
}
export default CashCollectorList