import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import SmsService from '../../../services/etopup/SmsService';
import "react-datepicker/dist/react-datepicker.css";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

class AddSmsNotification extends React.Component {

    constructor (props) {
        super(props);
        this.state = {

            title: '',
            senderMessage: '',
            receiverMessage: '',
            trxnType: '',
            remark: '',
            createdBy: '',
            getSMSMessage: [],

            formErrors: {
                titleError: false,
                senderMessageError: false,
                receiverMessageError: false,
                trxnTypeError: false,

            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);
    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        this.setState({
            formErrors, [ name ]: value
        })
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/smsNotificationList');
    }

    handleSubmit(e) {   // 
              e.preventDefault();
        const { title, senderMessage, receiverMessage, trxnType, formErrors } = this.state
        formErrors.titleError = title == '' || title == null || title == undefined ? 'Title should not be empty' : this.state.formErrors.titleError;
        formErrors.senderMessageError = senderMessage == '' || senderMessage == null || senderMessage == undefined ? 'Sender message should not be empty' : this.state.formErrors.senderMessageError;
        formErrors.receiverMessageError = receiverMessage == '' || receiverMessage == null || receiverMessage == undefined ? 'Receiver message should not be empty' : this.state.formErrors.receiverMessageError;
        formErrors.trxnTypeError = trxnType == '' || trxnType == null || trxnType == undefined ? 'Please select the notification type' : this.state.formErrors.trxnTypeError ;

        if (formValid(this.state.formErrors)) {
            var data = new FormData();
            data.append("title", this.state.title);
            data.append("senderMessage", this.state.senderMessage);
            data.append("receiverMessage", this.state.receiverMessage);
            data.append("trxnType", this.state.trxnType);
            data.append("remark", this.state.remark);
            data.append("createdBy", localStorage.customerIdentity);
            data.append("userId", localStorage.userId);

            SmsService.getNotificationByTrxnType(this.state.trxnType, localStorage.userId)
                .then(response => {
                    this.setState({
                        getSMSMessage: response.data
                    });
                    //    console.log(this.state.getPartnerTarget.id)
                    if (this.state.getSMSMessage) {
                        if (window.confirm("SMS message already exists ! Are you sure you want to update it?")) {
                            SmsService.createSmsNotification(data)
                                .then(
                                (response) => {
                                    NotificationManager.success("Successfully created ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                                    this.props.history.push('/smsNotificationList')
                                },
                                (err) =>{
                                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                                })
                                .catch(function (err) {

                                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);

                                });
                        }
                    }
                    else {
                        SmsService.createSmsNotification(data)
                            .then(response => {
                                NotificationManager.success("Successfully created ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                                this.props.history.push('/smsNotificationList')
                            })
                            .catch(function (err) {
                                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                            });
                    }

                })
                .catch(e => {
                    
                });
            // SmsService.createSmsNotification(data)
            //     .then(response => {
            //         NotificationManager.success("Notification saved successfull !! ", "Aelaf Smart Pay", 3000);
            //         this.Cancel()
            //     })
            //     .catch(function (err) {

            //         NotificationManager.error(err.response.data.error, "Aelaf Smart Pay", 3000);

            //     });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    };


    render() {
        const { formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active"> SMS Notification</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit } >
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0 ">
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add SMS Notification Message</h6>
                                                </div>
                                                <div className="card-body ">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='title'>Title:</label>
                                                            <input type="text" id="title" className="form-control " name="title" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.titleError == "" && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.titleError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Sender Message:</label>
                                                            <textarea rows={ 5 } cols={ 5 } type="text" className="form-control " id='senderMessage' name="senderMessage" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.senderMessageError == undefined && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.senderMessageError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Receiver Message:</label>
                                                            <textarea rows={ 5 } cols={ 5 } type="text" className="form-control " id='receiverMessage' name="receiverMessage" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.receiverMessageError == undefined && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.receiverMessageError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Remark:</label>
                                                            <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='remark' name="remark" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6 ">
                                                            <label htmlFor='trxnType'>Notification Type:</label>
                                                            <div className='row mt-2'>
                                                                {/* <label for="trxnType"><input type="checkbox" id="trxnType" name="trxnType" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className='ml-3' /><span className='ml-2'>Transfer</span></label>
                                                                <label for="trxnType"><input type="checkbox" id="trxnType" name="trxnType" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className='ml-3' /><span className='ml-2'>Topup</span></label>
                                                                { formErrors.trxnTypeError == undefined && (
                                                                    <span style={ { color: "#F61C04" } }>{ formErrors.trxnTypeError }</span>
                                                                ) } */}
                                                                <select id='CustomerType' name="trxnType" onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" >
                                                                    <option value=""> Select notification type</option>
                                                                    <option value="transfer"> Transfer</option>
                                                                    <option value="topup"> Topup</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ml-5">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div >
            </div >

        )
    }
}

export default withRouter(AddSmsNotification);
