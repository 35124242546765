import React, { Component } from 'react';
import AsyncSelect from 'react-select/async'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CustomerDealerService from '../../../services/etopup/CustomerDealerService';
import CommissionService from '../../../services/etopup/CommissionService';
import CommissionTypeService from '../../../services/common/CommissionTypeService';
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import API from '../../../http-common';
import CustomerService from '../../../services/etopup/CustomerService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

class EditCustomerDealer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location.state.id,
      customerId: '',
      parentCustomerList: [],
      dealerCustomerList: [],
      customerDealers: {
        parentCustomerId: [
          { id: 0 }
        ],
        dealerCustomerId: [
          { id: 0 }
        ],
        commissionTypeId: [
          { id: 0 }
        ],
        commissionRateId: [
          { id: 0 }
        ]
      },

      parentDealerCustomerIds: [],
      dealerCustomerIds: [],
      commissionRateIds: [],
      commissionTypeIds: [],
      rateValue: '',
      commissionTypeName:'',
      customerDealerId: null,
      isCompany: true,
      isLoading: false,
      formErrors: {
        commisionRangeError: "",
        dealerCustomerError: "",
        commissionTypeError: "",
        parentCustomerError: ""
      },
      selectOption: null,
      selecteDealerIds: null,
      fullName: '',
      customerType: '',
      customerTypeSlectedCustomer: ''
    }
  }



  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              parentCustomerList: data,

            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (selectOption) => {
    const { formErrors, customerDealers } = this.state
    this.state.rateValue =''
    customerDealers.commissionRateId = []
    formErrors.parentCustomerError = selectOption == "" || selectOption == null ? 'You must make a selection' : "";
    let selectOptionValue = selectOption && selectOption.value
    this.getSubDealerInfo(selectOptionValue)
    this.getCommission(selectOptionValue)
    this.getCustomerInfo(selectOptionValue, localStorage.userId)

    this.setState({
      selectOption: selectOption,
      customerDealerId: selectOption && selectOption.value,
      formErrors
    });


  }

  fatchDealerCustomerData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/findCustomersWithSimilarTypeBySearchValue/" + inputValue + "/" + this.state.customerDealerId + "/" + localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              dealerCustomerList: data,
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onDealerSearchChange = (selecteDealerIds) => {
    const { formErrors } = this.state
    if (selecteDealerIds) {
      formErrors.dealerCustomerError = selecteDealerIds == "" ? 'You must make a selection' : "";
      this.setState({
        selecteDealerIds,
        formErrors
      });
    }
  }

  getCommission(id) {
    
    this.state.commissionRateIds = []
    CommissionService.getCommisionsByCustomerId(id, localStorage.userId)
      .then(response => {
        this.setState({
          commissionRateIds: response.data
        });
      })
      .catch(e => {
        
      });
  }


  componentDidMount() {
    this.getCommission(localStorage.customerIdentity, localStorage.userId)
    CommissionTypeService.getCommissionTypes(localStorage.userId)
      .then(response => {
        this.setState({
          commissionTypeIds: response.data
        });
        
      })
      .catch(e => {
        
      });

    CustomerService.getCustomerModelById(localStorage.customerIdentity, localStorage.userId)
    .then(response => {
        this.setState({
          fullName: response.data.fullName,
          customerTypeSlectedCustomer:response.data.type,
          customerType: response.data.type
        });
      })
      .catch(e => {
      });

   

    this.getCustomerDealersById(this.state.id, localStorage.userId)

  }

  getCustomerDealersById(id) {
    CustomerDealerService.getCustomerDealersById(id, localStorage.userId)
      .then(response => {
        this.setState({
          customerDealers: response.data,
          commissionTypeId: response.data.commissionTypeId,
          commissionRateId: response.data.commissionRateId,
          parentCustomerId: response.data.parentCustomerId,
          dealerCustomerId: response.data.dealerCustomerId,
          rateValue: response.data.commissionRateId.commissionPercent
        });
      })
      .catch(e => {
        
      });

  }

  commissionRageChange = e => {
    const { formErrors, customerDealers, commissionRateId } = this.state
    customerDealers.commissionRateId = commissionRateId
    customerDealers.commissionRateId.id = e.target.value

    let selectedCommissionRateId = e.target.value

    if (selectedCommissionRateId == "----please select---") {
      selectedCommissionRateId = ""
    } else {
      CommissionService.getCommissionModelById(selectedCommissionRateId, localStorage.userId)
        .then(response => {
          this.setState({
            rateValue: response.data.commissionPercent
          });
        })
        .catch(e => {
          
        });


    }

    formErrors.commisionRangeError = selectedCommissionRateId == "" ? 'You must make a selection' : "";

    this.setState({
      formErrors
    });
  }

  commissionTypeChange = e => {
    const { formErrors, customerDealers, commissionTypeId } = this.state
    customerDealers.commissionTypeId = commissionTypeId
    customerDealers.commissionTypeId.id = e.target.value
    let selectedCommissionTypeId = e.target.value
    if (selectedCommissionTypeId == "----please select---") selectedCommissionTypeId = ""

    formErrors.commissionTypeError = selectedCommissionTypeId == "" ? 'You must make a selection' : "";

    this.setState({
      formErrors
    });

  }

  updateCustomer() {
    const { customerDealers, selectOption, selecteDealerIds, formErrors } = this.state
    formErrors.commissionTypeError = customerDealers.commissionTypeId == '' || customerDealers.commissionTypeId == null || customerDealers.commissionTypeId == undefined ? 'Provide commmision type' : this.state.formErrors.commissionTypeError;
    formErrors.commisionRangeError = customerDealers.commissionRateId == '' || customerDealers.commissionRateId == null || customerDealers.commissionRateId == undefined ? 'You must make a selection' : this.state.formErrors.commisionRangeError;
    formErrors.parentCustomerError = selectOption == '' || selectOption == null || selectOption == undefined ? 'You must make a selection' : this.state.formErrors.parentCustomerError;
    formErrors.dealerCustomerError = selecteDealerIds == '' || selecteDealerIds == null || selecteDealerIds == undefined ? 'You must make a selection' : this.state.formErrors.dealerCustomerError;

    if (formValid(this.state.formErrors)) {
      const formData = new FormData();
      formData.append("parentDealerId", selectOption && selectOption.value)
      formData.append("subDealerId", selecteDealerIds && selecteDealerIds.value)
      formData.append("commissionRate", customerDealers.commissionRateId.id)
      formData.append("commissionType", customerDealers.commissionTypeId.id)
      formData.append("id", this.state.id)
      formData.append("userId", localStorage.userId)

      this.setState({ isLoading: true})  
      CustomerDealerService.updateCustomerDealers(formData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoading: false})  
            this.cancel()
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoading: false})  
          })
        .catch(function (response) {
           NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
           this.setState({ isLoading: false})  
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  cancel() {
    this.props.history.push('/dealersList')
  }

  getSubDealerInfo(customerId) {
    const {customerDealers} =  this.state
    customerDealers.commissionTypeId = []
    
    CustomerDealerService.getSubDealerInfo(customerId, localStorage.userId)
      .then(response => {
        let commissionTypeId = response.data.commissionTypeId
        let type = response.data.commissionTypeId.name

        this.state.formErrors.commissionTypeError = commissionTypeId == undefined   ? 'Provide commmision type' : "";

        customerDealers.commissionTypeId = commissionTypeId
        this.setState({
          customerDealers :{
            commissionTypeId: customerDealers.commissionTypeId 
          },
          commissionTypeName: customerDealers.commissionTypeId === undefined ? '': type
        })
      })
      .catch(e => {
        
      });
  }

  getCustomerInfo(customerId, userId) {
    CustomerService.getCustomerModelById(customerId, userId)
      .then(response => {
        this.setState({
          customerTypeSlectedCustomer: response.data.type
        });
      })
      .catch(e => {
      });
  }


  render() {
    const { customerDealers, rateValue, commissionTypeName, customerTypeSlectedCustomer, selectOption, selecteDealerIds, commissionRateIds, commissionTypeIds, formErrors, customerType, fullName } = this.state

    if (selectOption === null && customerDealers.parentCustomerId.id) {
      this.state.customerDealerId = customerDealers.parentCustomerId && customerDealers.parentCustomerId.id
      this.state.selectOption = {
        label: customerDealers.parentCustomerId && customerDealers.parentCustomerId.fullName,
        value: customerDealers.parentCustomerId && customerDealers.parentCustomerId.id
      }

      this.getCommission(customerDealers.parentCustomerId.id, localStorage.userId)
      this.getCustomerInfo(customerDealers.parentCustomerId.id, localStorage.userId)

      if(customerDealers.parentCustomerId.customerType.type !== `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ){  
         this.getSubDealerInfo(customerDealers.parentCustomerId.id)
      }

    }

    if (selecteDealerIds === null && customerDealers.dealerCustomerId.id) {
      this.state.selecteDealerIds = {
        label: customerDealers.dealerCustomerId && customerDealers.dealerCustomerId.fullName,
        value: customerDealers.dealerCustomerId && customerDealers.dealerCustomerId.id
      }
    }

    let commissionType = commissionTypeIds.length > 0
      && commissionTypeIds.map((item, i) => {
        return (
          <option key={i} value={item.id}>{item.name}</option>
        )
      }, this);


    let commissionRate = commissionRateIds.length > 0
      && commissionRateIds.map((item, i) => {
        return (
          <option key={i} value={item.id}>{item.commissionAssignment}</option>
        )
      }, this);

    return (

      <div >
        {/* <Header />
        <TopupMenu /> */}
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Modifiy Customer Dealer Information</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="modal-body">

                  <div className='col-1'></div>
                  <div className='col-7 bg-light p-4'>

                    <div class="modal-header">
                      <h4 class="modal-title" >
                        Modifiy Customer Dealer Information
                      </h4>
                    </div>

                    {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                      <div >
                        <label htmlFor='CustomerList'>Customer :</label>
                        <div style={{ fontSize: 'smaller' }}>

                          <AsyncSelect id='CustomerList'
                            className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                            placeholder={"Search by name, account no or mobile number"}
                            value={this.state.selectOption}
                            isClearable={true}
                            loadOptions={this.fatchData}
                            isLoading={this.state.selectOption === {} &&
                              this.state.selectOption === undefined &&
                              this.state.selectOption === null ? true : false
                            }
                            onChange={(e) => {
                              this.onSearchChange(e)
                            }}
                            defaultOptions={false}
                          />
                        </div>
                        {formErrors.parentCustomerError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.parentCustomerError}</span>
                        )}

                      </div>

                      :
                      <div>
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                          <label htmlFor='Name'>Dealer Customer:</label>
                          <input type="text" className="form-control " id='Name'
                            placeholder="Customer " disabled={true}
                            value={fullName}
                          />
                        </div>
                      </div>



                    }

                    <div >
                      <label htmlFor='DealerCustomerList'>Sub Dealer Customer :</label>
                      <div style={{ fontSize: 'smaller' }}>

                        <AsyncSelect id='DealerCustomerList'
                          className=" form-group col-9 col-sm-9 col-md-9 col-lg-9  "
                          placeholder={"Search by name, account no or mobile number"}
                          value={this.state.selecteDealerIds}
                          isClearable={true}
                          loadOptions={this.fatchDealerCustomerData}
                          isLoading={this.state.selecteDealerIds === {} &&
                            this.state.selecteDealerIds === undefined &&
                            this.state.selecteDealerIds === null ? true : false
                          }

                          onChange={(e) => {
                            this.onDealerSearchChange(e)
                          }}
                          defaultOptions={false}
                        />
                      </div>
                      {formErrors.dealerCustomerError.length > 0 && (
                        <span style={{ color: "#F61C04" }}>{formErrors.dealerCustomerError}</span>
                      )}

                    </div>
                    {customerTypeSlectedCustomer === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='CommissionTypeist'>Commission Type:</label>
                        <select className="form-control "
                          value={customerDealers.commissionTypeId && customerDealers.commissionTypeId.id}
                          onChange={this.commissionTypeChange} id='CommissionTypeist'>
                          <option key={null} value={null}>----please select---</option>
                          {commissionType}
                        </select>
                        {formErrors.commissionTypeError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.commissionTypeError}</span>
                        )}
                      </div>
                      :
                      <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                        <label htmlFor='CommissionType'>Commission Type:</label>
                        < input type={'text'} className="form-control " disabled={true} id='CommissionType'
                          value={commissionTypeName} />
                        {formErrors.commissionTypeError.length > 0 && (
                          <span style={{ color: "#F61C04" }}>{formErrors.commissionTypeError}</span>
                        )}
                      </div>
                    }

                    <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                      <label htmlFor='CommissionRateist'>Commission Rate:</label>
                      <select className="form-control "
                        value={customerDealers.commissionRateId && customerDealers.commissionRateId.id}
                        onChange={this.commissionRageChange} id='CommissionRateist'>
                        <option key={null} value={null}>----please select---</option>
                        {commissionRate}
                      </select>
                      {/* <input type='hidden' value={this.state.bankInfoId.id} name='bankInfoId' onChange={this.handelChange}/> */}
                      {formErrors.commisionRangeError.length > 0 && (
                        <span style={{ color: "#F61C04" }}>{formErrors.commisionRangeError}</span>
                      )}
                    </div>

                    <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                      <label >Rate in %:</label>
                      <input type="text" disabled='true' id='Rate'
                        value={rateValue} />
                    </div>

                    <div className="row  d-flex justify-content-left ">
                      <button type="submit"
                        className="btn btn-success px-4 py-2"
                        onClick={() => this.updateCustomer()}
                        style={{ marginLeft: "30px" }}>Update</button>
                      <button type="submit" onClick={this.cancel.bind(this)}
                        className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                    </div>


                  </div>

                </div>
              </div>
              <NotificationContainer />
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
              />}
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}

export default EditCustomerDealer;