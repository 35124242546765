import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-input-2';
import LoginService from '../../../../services/common/LoginService';
import ReactLoading from "react-loading";
import LoadingPopup from '../../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\\/ ]{3,20}$/u)
// const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\\/ ]{0,20}$/u)
const emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            email: null,
            identityCard: '',
            mobileNo: '',
            companyProfile: [],
            isLoading: false,
            selectedFilesIdentity: undefined,
            currentFileIdentity: undefined,
            formErrors: {
                firstNameError: "",
                middleNameError: "",
                lastNameError: "",
                identityCardError: '',
                emailError: "",
                mobileNoError: ""
            },
        }

        this.onDropIdentity = this.onDropIdentity.bind(this);

    }

    handleSubmit = e => {
        e.preventDefault();

        const { mobileNo, firstName, middleName, lastName, email, currentFileIdentity, formErrors } = this.state
        formErrors.mobileNoError = mobileNo == '' || mobileNo == null ? "Phone number is Required" : this.state.formErrors.mobileNoError;
        formErrors.firstNameError = firstName == '' || firstName == null ? "First Name is Required" : this.state.formErrors.firstNameError;

        formErrors.lastNameError = lastName == '' || lastName == null ? "Last Name is Required" : this.state.formErrors.lastNameError;
        // formErrors.emailError = email == '' || email == null ? "email is Required" : "";
        formErrors.identityCardError = currentFileIdentity == '' || currentFileIdentity == null ? "identity card is Required" : this.state.formErrors.identityCardError;


        if (formValid(this.state.formErrors)) {
            this.state.isLoading = true;
            let signup = new FormData();
            signup.append('mobileNo', mobileNo);
            signup.append('firstName', firstName);
            signup.append('middleName', middleName);
            signup.append('lastName', lastName);
            signup.append('email', email);
            signup.append('identityCard', currentFileIdentity);


            LoginService.signup(signup)
                .then(
                    (res) => {
                        NotificationManager.success(`${process.env.REACT_APP_SIGNUP_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.props.history.push('/login')
                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false })
                    })
                .catch(function (error) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.props.history.push('/login')
                });
        }

        this.setState({
            formErrors
        })
    };

    handelChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'firstName':
                formErrors.firstNameError =
                    value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
                break;
            case 'middleName':
                formErrors.middleNameError =
                    !middleNameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
                break;
            case 'lastName':
                formErrors.lastNameError =
                    value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
                break;
            case 'email':
                formErrors.emailError = ""
                if (value.length != 0) {
                    formErrors.emailError =
                        !emailRegEx.test(value) ? " insert a valid  " + name : ""
                }
                break;

            // case 'idNumber':
            //     formErrors.idNumberError =
            //         value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            // case 'bookNo':
            //     formErrors.bookNoError =
            //         value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            // case 'pageNo':
            //     formErrors.pageNoError =
            //         value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            // case 'registryNo':
            //     formErrors.registryNoError =
            //         value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            // case 'volumeNo':
            //     formErrors.volumeNoError =
            //         value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            // case 'birthDay':
            //     formErrors.birthDayError =
            //         value.length == 0 ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
            //     break;
            default:
                break;

        }

        this.setState({
            formErrors, [name]: value
        })
    }

    onChangeReceiverMobileNo = mobile => {
        const { formErrors } = this.state
        formErrors.mobileNoError =
            !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites" : ""
        this.setState({
            mobileNo: mobile,
            formErrors
        })
    }


    componentDidMount() {
        LoginService.companyProfileInfo()
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: false,
                    companyProfile: data
                });
            });

    }
    onDropIdentity(files) {

        if (files.length > 0) {
            this.setState({ selectedFilesIdentity: files });
            this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[0] });
        }
    }


    cancel() {
        this.props.history.push('/login')
    }

    render() {
        const { formErrors, selectedFilesIdentity, companyProfile, isLoading } = this.state

        return (
            <div >
                <NotificationContainer />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div >
                                <div className="modal-body">
                                    <div className='col-12 col-sm-8 col-md-6 col-lg-6 text-muted p-2'>
                                        {/* <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0  bg-blue"> */}

                                        {/* <table style={{ border: 'none' }}>
                                                    <tr style={{ border: 'none' }}>
                                                        <td style={{ border: 'none', width: '30%', alignSelf: 'center' }}>
                                                            <img
                                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                                alt="Smart Pay"
                                                                style={{ width: '60%', height: '40%', alignSelf: 'center' }}

                                                            />
                                                        </td>
                                                        <td style={{ border: 'none' }}>
                                                            <h6 className='justify-content-left' >Welcome To {companyProfile.companyName}</h6>
                                                        </td></tr>
                                                </table> */}
                                        <div className="d-flex justify-content-center">
                                            <img
                                                src={"data:image/png;base64," + companyProfile.attachment}
                                                // src="dist/img/Aelaf_Smartpay.png"                                                               
                                                alt="SmartPay"
                                                style={{ width: '30%', height: '10%', alignSelf: 'center' }}

                                            />
                                        </div>
                                        <div className="card my-4 border-radius-0">
                                            <div className="card-header border-radius-0 bg-blue ">
                                                <h6 className='d-flex justify-content-center' >Welcome to {companyProfile.companyName} </h6>

                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>
                                            <div className="card-body d-flex justify-content-left px-4 py-2 ">
                                                <form className='' onSubmit={this.handleSubmit} noValidate>
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='firstName'>First Name:</label>
                                                            <input type="text" className="form-control " id='firstName'
                                                                name='firstName'
                                                                onChange={this.handelChange}
                                                                onBlur={this.handelChange} />

                                                            {formErrors.firstNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.firstNameError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='middleName'>Middle Name:</label>
                                                            <input type="text" className="form-control " id='middleName' name='middleName'

                                                                onChange={this.handelChange} onBlur={this.handelChange} />
                                                            {formErrors.middleNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.middleNameError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='lastName'>Last Name:</label>
                                                            <input type="text" className="form-control " id='lastName' name='lastName'
                                                                onChange={this.handelChange} onBlur={this.handelChange} />
                                                            {formErrors.lastNameError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.lastNameError}</span>
                                                            )}
                                                        </div>



                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='mobileNo'>Mobile Number:</label>
                                                            <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                                                country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                                                inputStyle={{ width: '100%', height: 'auto' }}
                                                                placeholder="Enter phone number" id='mobileNo'
                                                                onChange={phone => this.onChangeReceiverMobileNo(phone)} />

                                                            {formErrors.mobileNoError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.mobileNoError}</span>
                                                            )}
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='email'>Email:</label>
                                                            <input type="text" className="form-control " id='email'
                                                                name='email'
                                                                onChange={this.handelChange}
                                                                onBlur={this.handelChange} />

                                                            {formErrors.emailError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.emailError}</span>
                                                            )}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-8 col-md-6 col-lg-6">
                                                            <label htmlFor='IdentityCard'>Indentity Card:</label>

                                                            <Dropzone
                                                                onDrop={this.onDropIdentity}
                                                                multiple={false}>

                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" })} style={{ border: "thin dashed black" }}>
                                                                            <input {...getInputProps()} />
                                                                            {selectedFilesIdentity && selectedFilesIdentity[0].name ? (
                                                                                <div className="selected-file">
                                                                                    {selectedFilesIdentity && selectedFilesIdentity[0].name}
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop file here, or click to select file"
                                                                            )}
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">

                                                                        </aside>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                            {formErrors.identityCardError.length > 0 && (
                                                                <span style={{ color: "#F61C04" }}>{formErrors.identityCardError}</span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="clearfix">&nbsp;</div>

                                                    <div className="row  justify-content: space-between; ">

                                                        <div className=" justify-content-left">
                                                            <button disabled={isLoading} type="submit" title={"Sign up"}
                                                                className="btn btn-sm btn-success"
                                                                style={{ marginLeft: "30px" }}>
                                                                Sign up
                                                                <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faUser} />
                                                            </button>
                                                            <button type="submit" title={"Cancel"}
                                                                className="btn btn-sm btn-warning " onClick={this.cancel.bind(this)}
                                                                style={{ marginLeft: "5px" }}>
                                                                Cancel
                                                            </button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                    <form>
                                        <ReactLoading type="bars" color="#0000FF" height={50} width={50} />
                                    </form>
                                </>}
                            />}
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>

                    {/* /.content */}
                </div>
            </div>

        )
    }
}



export default Signup;