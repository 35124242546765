import React, { Component } from 'react'
import { Link } from "react-router-dom";
import TopupMenu from '../TopupMenu';
import CustomersService from '../../../services/common/CustomersService';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import PhoneInput from 'react-phone-input-2';
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import Moment from 'react-moment';
import e from 'cors';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import NumberFormat from 'react-number-format';
const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
//class PartnerProviderBalanceList extends Component {

class ProviderCommissionList extends Component {

    constructor (props) {
        super(props)
        this.state = {
            // For search purposes

            isLoading: false,
            // For search purposes
            //  CustomerList: [],
            providerId: [],
            commissionRateId: [],
            providerCommissionList: [],
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 1,
            counter: 1,
            id: 0,
            flag: false,
            isExpand: true,
            formErrors: {
                phoneNumberError: "",
                accountNoError: "",
                fullNameError: "",
                dateToError: "",
                dateFromError: ""
            },
        }
        this.clearValue = this.clearValue.bind(this);
        this.editProviderCommission = this.editProviderCommission.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {

        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachCustomerBasedOnFilter()
            this.render()
        }
    }


    clearValue() {
        // should not reload
        this.state.firstName = null
        this.state.mobileNo = null
        this.state.accountNo = null
        this.state.dateFrom = null
        this.state.dateTo = null
        this.state.customerType = null
        this.state.clear = 1
    }


    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.seachCustomerBasedOnFilter()

        }
    }


    searchCustomerInfo(e) {

        const { formErrors, dateFrom, dateTo } = this.state
        formErrors.dateFromError = ''
        formErrors.dateToError = ''

        if (dateFrom != null) {
            formErrors.dateToError =
                dateTo == null ? 'date to is required' : ''
        }

        if (dateTo != null) {
            formErrors.dateFromError =
                dateFrom == null ? 'date from is required' : ''
        }

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
            }
        }

        if (formValid(this.state.formErrors)) {

            this.state.clear = 0;
            this.state.pageNo = 1;
            this.seachCustomerBasedOnFilter()

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }

    }
    seachCustomerBasedOnFilter() {

        this.providerCommissionList = null;

        ProviderCommissionService.getAllProviderCommissions(
            this.state.pageNo, this.state.pageSize, localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                if (data.content) {
                    this.setState({
                        providerCommissionList: data.content,
                        isOpen: false,
                        isLoading: true,
                        totalPage: data.totalPages,
                        isLoading: true,
                        totalelement: data.pageable.totalelement,
                        pageNo: data.pageable.pageNumber + 1
                    });
                }
                if (data.content.length < 1) {
                    //  NotificationManager.error("Partner provider balance not found", "AsPay");
                }
                this.setState({ isLoading: true })
            });

    }
    componentDidMount() {
        this.seachCustomerBasedOnFilter()
    }

    editProviderCommission(providerCommissionId) {
        this.props.history.push({pathname: "/editprovidersCommission", state: {providerCommissionId: providerCommissionId}});
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }  


    render() {

        const { providerCommissionList, formErrors, pageNo, isLoading, totalPage } = this.state;
        this.state.counter = pageNo

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Provider Commissions</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Provider Commission List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>

                                                <div className="col-md-4">
                                                    <label htmlFor='fullName'>Provider Name</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='fullName'
                                                            onChange={ e => this.state.firstName = e.target.value }
                                                            placeholder="Name" />
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='dateFrom' >Date From</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateFrom = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateFromError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Date To</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateTo = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateToError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button type="button" title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchCustomerInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />

                                        <Link to={ { pathname: '/addProvidersCommission' } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>

                                        {/* <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button> */}

                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } }  >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Provider Name</th>
                                                    <th>Purchase(%)</th>
                                                    <th>Target(%)</th>
                                                    <th>Quarterly(%)</th>
                                                    <th>Remark</th>

                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { providerCommissionList !== undefined && providerCommissionList.map(providerCommission =>

                                                    <tr>
                                                        <td >{ providerCommission.providerId.providerName }</td>
                                                        <td>{ providerCommission.onPurchase + ' %' }</td>
                                                        <td>{ providerCommission.onTarget + ' %' }</td>
                                                        <td>{ providerCommission.quarterly + ' %' }</td>
                                                        <td>{ providerCommission.remark }</td>
                                                        <td>

                                                            <Link to={ { pathname: '/viewProvidersCommission', state: { providerCommissionId: providerCommission.id } } }>
                                                                <button className="btn btn-info btn-sm action-buttons" title="View detail"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                                            </Link>
                                                            <Link to={ { pathname: '/editProvidersCommission', state: { providerCommissionId: providerCommission.id } } }>
                                                                <button style={ { marginLeft: "2px" } } title="Edit Customer" className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                            </Link>
                                                            { /*onClick={ () => this.editProviderCommission(providerCommission.id) }*/ }
                                                        </td>
                                                    </tr>

                                                )
                                                }

                                            </tbody>
                                        </table>

                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default ProviderCommissionList