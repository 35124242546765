import API from '../../../src/http-common';

class KioskSalesHistoryService {

    getAllKioskSalesHistory(pageNo, pageSize, userId, searchParam) {
        return API.put('/api/v1/getAllKioskSalesHistory/' + pageNo + '/' + pageSize + '/' + userId, searchParam);
    }

    getAllKioskTopupHistory(pageNo, pageSize, userId, searchParam) {
        return API.put('/api/v1/getAllKioskTopupHistory/' + pageNo + '/' + pageSize + '/' + userId, searchParam);
    }

    getAllKioskCashInHistory(pageNo, pageSize, userId, searchParam) {
        return API.put('/api/v1/getAllKioskCashInHistory/' + pageNo + '/' + pageSize + '/' + userId, searchParam);
    }
    
    getAllKioskBillPaymentHistory(pageNo, pageSize, userId, searchParam) {
        return API.put('/api/v1/getAllKioskBillPaymentHistory/' + pageNo + '/' + pageSize + '/' + userId, searchParam);
    }

}
export default new KioskSalesHistoryService()