import { faKey, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import avater from '../../images/profileAvater.png'
import LoginService from '../../services/common/LoginService';
import NotificationService from '../../services/common/NotificationService';
import WalletBalanceService from '../../services/wallet/WalletBalanceService';
import TopupBalanceService from '../../services/etopup/TopupBalanceService';
import NumberFormat from 'react-number-format';
import ReactLoading from "react-loading";
import { PureComponent } from 'react';
import { createBrowserHistory } from 'history';
import ProviderService from '../../services/etopup/ProviderService';
import PurchaseOrderService from '../../services/etopup/PurchaseOrderService';

const history = createBrowserHistory();

class Header extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      getWalletByCustomerId: 0,
      getTopupByCustomerId: 0,
      getTopupAccByCustomerId: 0,
      isWalletBalanceDisplayed: false,
      isTopupBalanceDisplayed: false,
      customerInfo: '',
      loginId: null,
      customerType: '',
      attachment: null,
      fullName: '',
      customerId: '',
      topupBalance: 0,
      commissionAmount: 0,
      totalWalletBalance: 0,
      displayTopupBalance: true,
      displayWalletBalance: true,
      displayAccBalance: true,
      companyCash: 0,
      email: '',
      didMount: 0,
      isExpand: 0,
      allNotifications: [
        "newCustomerNotificationCount",
        "topupOrderNotificationCount",
        "cashInNotificationCount",
        "cashOutNotificationCount",
        "allNotificationsCount"
      ],
    }

    this.signout = this.signout.bind(this);
    this.isExpand = this.isExpand.bind(this);
  }

  componentDidMount() {

    // const formData = new FormData();
    // formData.append("userId", localStorage.userId);
    this.getTotalCompanyBalance(localStorage.type,localStorage.userId)

    LoginService.getUserInfo(localStorage.userId)
      .then(
        (response) => {
          this.setState({
            customerInfo: response.data,
            loginId: response.data.loginId,
            customerType: response.data.type,
            attachment: response.data.attachment,
            fullName: response.data.fullName,
            customerId: response.data.customerId,
            email: response.data.email
          })
         
          
        },
        (error) => {
          let status = error.response.data.status;
          if (status == undefined) {
            this.signout();
            window.location.reload();
          }

          if (status == '401') {
            this.signout();
            window.location.reload();
          }


        })
      .catch(e => {
        const reloadCount = localStorage.getItem('loginDash');
        if(reloadCount !="firstTime") {
            this.signout();
            localStorage.removeItem('loginDash');
            window.location.reload();
        } 
      });
     
  

    NotificationService.getAllNotifications(localStorage.customerIdentity, localStorage.userId)
      .then(response => {
        this.setState({
          allNotifications: response.data
        });
      })
      .catch(e => {
        
      });
  }
  getTotalCompanyBalance(customerType,userId){
    this.setState({ 
      isWalletBalanceDisplayed: true,
      isTopupBalanceDisplayed: true
    })
    if (customerType ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) {
    PurchaseOrderService.getAirtimeCompanyBalance(localStorage.userId)
    .then(
      (response) => {

      this.setState({
        getTopupByCustomerId: response.data,
        isTopupBalanceDisplayed: false
      });
      
    }, 
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayTopupBalance: false})
      }
    })
    .catch(e => {
      
    });
    }else{
    TopupBalanceService.findTopupBalanceByCustomerId(localStorage.customerIdentity, localStorage.userId)
    .then(
      (response) => {

      this.setState({
        getTopupByCustomerId: response.data,
        isTopupBalanceDisplayed: false
      });
      
    }, 
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayTopupBalance: false})
      }
    })
    .catch(e => {
      
    });
    TopupBalanceService.findTopupAccBalanceByCustomerId(localStorage.customerIdentity, localStorage.userId)
    .then(
      (response) => {

      this.setState({
        getTopupAccByCustomerId: response.data,
        isTopupBalanceDisplayed: false
      });
      
    }, 
    (err) => {
      if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
        this.setState({ displayAccBalance: false})
      }
    })
    .catch(e => {
      
    });
  }

      if (customerType ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) {
  
        TopupBalanceService.getTotalCompanyBalance(userId)
          .then(response => {
            this.setState({
              companyCash: response.data,
              isWalletBalanceDisplayed: false
            })
          },
            (err) => {
              this.setState({isWalletBalanceDisplayed: false});
              if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
                this.setState({ displayWalletBalance: false})
              }
            })
          .catch(e => {            
          });
      }else{
      
        WalletBalanceService.getWalletBalanceByCustomerId(localStorage.customerIdentity, localStorage.userId)
        .then(response => {
          this.setState({
            getWalletByCustomerId: response.data,
            isWalletBalanceDisplayed: false
          });
          
        },
        (err) => {
          this.setState({isWalletBalanceDisplayed: false});
          if(err.response.data.httpStatus == 'SERVICE_UNAVAILABLE' ){
            this.setState({ displayWalletBalance: false})
          }
        })
        .catch(e => {
          
        });
  
     
      }
    } 
    
    
  signout() {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('userIdentity')
    localStorage.removeItem('customerIdentity')
    localStorage.removeItem('userName')
    localStorage.removeItem('isProfileUpdated')
    localStorage.removeItem('type')
    localStorage.removeItem('status')   
    localStorage.removeItem('mobileNo')
    localStorage.removeItem('walletMenu')
    localStorage.clear();
    history.replace('/');
    window.location.reload()
  }

  changePassword() {
    localStorage.removeItem('walletMenu'); 
    localStorage.setItem('reloadCount', "firstTime")

  }

  UserProfile() {
    localStorage.setItem('walletMenu', true)
    localStorage.setItem('reloadCount', "firstTime")
  }


  isExpand() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }

  topup() {
    localStorage.setItem('walletMenu', false);
    localStorage.setItem('reloadCount', "firstTime");
  }

  wallet() {
    localStorage.setItem('walletMenu', true);
    localStorage.setItem('reloadCount', "firstTime");
  }

  render() {
    const { companyCash, getWalletByCustomerId,getTopupAccByCustomerId, getTopupByCustomerId, loginId, isExpand, customerType, attachment, fullName, email, allNotifications } = this.state
  
    
    this.state.topupBalance = parseFloat(getTopupByCustomerId).toFixed(2)

    
    this.state.commissionAmount = parseFloat(getTopupAccByCustomerId).toFixed(2)
    this.state.totalWalletBalance = parseFloat(getWalletByCustomerId).toFixed(2) 
    let totalCompanyCash = companyCash 
    this.state.totalCompanyCash = parseFloat(totalCompanyCash).toFixed(2)

    if (customerType ===  `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) {  
     this.state.companyBalance = parseFloat(getTopupByCustomerId.balance).toFixed(2)
    }


    return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */ }      
         
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="/#" role="button">
                  <i className="fas fa-bars" />
                </a>
              </li>

              <li className="nav-item d-none d-sm-inline-block">
                <Link  onClick={ this.topup}   to="/etopupDashboard" className="nav-link">
                  eTop Up
                </Link>
              </li>

              <li className="nav-item d-none d-sm-inline-block">
                <Link  onClick={ this.wallet}   to="/eWalletDashboard" className="nav-link">
                  eWallet
                </Link>
              </li>

              { window.location.pathname !== `${process.env.REACT_APP_ROOT_COMMONDASHBOARD}` &&
                <>
                { localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                
                <li className="p ml-5">
<>
          {this.state.displayWalletBalance && this.state.displayTopupBalance ?<>Company Balance   <br /></> : ''  }
                 
                 </>
                 {this.state.displayWalletBalance &&
                   <>
                    <b> Cash:  </b>
                       {!this.state.isWalletBalanceDisplayed ? 
                       < NumberFormat style={ { fontWeight: "bold" } } 
                         value={ this.state.totalCompanyCash } displayType={ 'text' } 
                         thousandSeparator={ true } prefix={ '' } />
                       :
                       <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />
                              
                       }
                      
                    </>
                   }
                 { this.state.displayTopupBalance &&
                      <>
                       <b>,  Airtime : </b> 
                     {!this.state.isTopupBalanceDisplayed ? 
                      < NumberFormat style={ { fontWeight: "bold" } } 
                         value={ this.state.companyBalance } displayType={ 'text' } 
                         thousandSeparator={ true } prefix={ '' } />
                       :
                      <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />                              
                      } 
                    </>
                    
                  }
                </li>
                :
             <>
                
             
                <li className="p ml-5">
                   {this.state.displayWalletBalance &&
                   <>
                  { "Wallet Balance" }<br />
                  {!this.state.isWalletBalanceDisplayed ?
                  <> 
                    <h6 style={ { fontWeight: "bold" } }>  
                    < NumberFormat value={ this.state.totalWalletBalance } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></h6>
                                      
                  </>
                  :
                  <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />  
                  }
                  </>
  }
                 </li>
               
              
              
              
              
                <li className="p ml-5">
                {this.state.displayTopupBalance &&
                   <>
                  
                  {"Topup Balance"} <br />
                  {!this.state.isTopupBalanceDisplayed ?
                  <>
                  
                    <h6 >BB:  < NumberFormat style={ { fontWeight: "bold" } } value={ this.state.topupBalance } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                     {this.state.displayAccBalance ?
                      <>
                      , 
                      ACC : < NumberFormat  style={ { fontWeight: "bold" } }value={ this.state.commissionAmount } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                     </>
                     :
                     ''
                     } 
                     </h6>
                   </>
                  :
                  <ReactLoading  type="bars" color="#0000FF" height={20} width={20} />  
                  }
                  </>
  }
                </li>
                
              </>
  }
              
  
                
                </>
  

                }
                  {/* { companyCash.length > 0 || companyCash !== undefined &&
                    <h6 style={ { fontWeight: "bold" } }>< NumberFormat value={ this.state.totalCompanyCash } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                    </h6>
                  }
                  { companyCash.length === 0 &&
                    <h6 style={ { fontWeight: "bold" } }>< NumberFormat value={ "0.00" } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                    </h6>
                  } */}

            </ul>
         
          {/* Right navbar links */ }
          <ul className="navbar-nav ml-auto">
            {/* Navbar Search */ }
            {/* <li className="nav-item">
              <a
                className="nav-link"
                data-widget="navbar-search"
                href="/#"
                role="button"
              >
                <i className="fas fa-search" />
              </a>
              <div className="navbar-search-block">
                <form className="form-inline">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control form-control-navbar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-navbar" type="submit">
                        <i className="fas fa-search" />
                      </button>
                      <button
                        className="btn btn-navbar"
                        type="button"
                        data-widget="navbar-search"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li> */}
            {/* Profilde Dropdown Menu */ }
            <li className="nav-item dropdown">
            </li>
            {/* Notifications Dropdown Menu */ }
            <li className="nav-item dropdown">
              <Link className="nav-link" data-toggle="dropdown" href="/#">
                <i className="far fa-bell fa-2x" />
                <span style={ { textAlign: "right" } } className="badge badge-danger navbar-badge">{ allNotifications[ 0 ][ "allNotificationsCount" ] }</span>
              </Link>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right ">
                <span className="dropdown-item dropdown-header">
                  { allNotifications[ 0 ][ "allNotificationsCount" ] } Notifications
                </span>

                <div className="dropdown-divider" />
                { allNotifications[ 0 ][ "topupOrderNotificationCount" ] > 0 &&
                  <Link to="/topupOrderList" className="dropdown-item">
                    <i className="fas fa-bell mr-2" /> { allNotifications[ 0 ][ "topupOrderNotificationCount" ] } New topup requests
                    {/* <span className="float-right text-muted text-sm">3 mins</span> */ }
                  </Link>
                }

                <div className="dropdown-divider" />
                { allNotifications[ 0 ][ "newCustomerNotificationCount" ] > 0 &&
                  <Link onClick={this.UserProfile } to="/customerList" className="dropdown-item">
                    <i className="fas fa-bell mr-2" /> { allNotifications[ 0 ][ "newCustomerNotificationCount" ] }  New customer
                    {/* <span className="float-right text-muted text-sm">12 hours</span> */ }
                  </Link>
                }
                <div className="dropdown-divider" />
                { allNotifications[ 0 ][ "cashInNotificationCount" ] > 0 &&
                  <Link onClick={this.UserProfile } to="/cashInList" className="dropdown-item">
                    <i className="fas fa-bell mr-2" /> { allNotifications[ 0 ][ "cashInNotificationCount" ] } New cash in
                    {/* <span className="float-right text-muted text-sm">2 days</span> */ }
                  </Link>
                }
                <div className="dropdown-divider" />
                { allNotifications[ 0 ][ "cashOutNotificationCount" ] > 0 &&
                  <Link onClick={this.UserProfile } to="/cashOutList" className="dropdown-item">
                    <i className="fas fa-bell mr-2" /> { allNotifications[ 0 ][ "cashOutNotificationCount" ] } New cash Out
                    {/* <span className="float-right text-muted text-sm">2 days</span> */ }
                  </Link>
                }
              </div>
            </li>

            <li className="nav-item">
              { attachment != null ?
                <img id="logo" onClick={ () => this.isExpand() }
                  className="img-size-50 mr-3 img-circle"
                  src={ "data:image/png;base64," + attachment }>
                </img>
                :
                <img id="logo" onClick={ () => this.isExpand() }
                  className="img-size-50 mr-3 img-circle"
                  src={ avater }>
                </img>
              }

              <div hidden={ isExpand ? false : true }>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right show">
                  <a className="dropdown-item">
                    {/* Message Start */ }
                    <div className="media">
                      { attachment != null ?
                        <img id="logo" onClick={ (e) => this.isExpand().bind(this) }
                          className="img-size-50 mr-3 img-circle"
                          src={ "data:image/png;base64," + attachment }>
                        </img>
                        :
                        <img id="logo" onClick={ (e) => this.isExpand().bind(this) }
                          className="img-size-50 mr-3 img-circle"
                          src={ avater }>
                        </img>
                      }
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          { fullName }
                          <span className="float-right text-sm text-danger">
                            <i className="fas fa-star" />
                          </span>
                        </h3>
                        <p className="text-sm">addtional Info...</p>
                        <p className="text-sm text-muted">
                          <i className="fa fa-envelope mr-1" /> { email }
                        </p>
                        <p className="text-sm text-muted">
                          <i className="fas fa-phone mr-1" /> { loginId }

                        </p>
                      </div>
                    </div>
                    {/* Message End */ }
                  </a>
                  <div className="dropdown-divider" />

                  {/* Message Start */ }
                  <div className="dropdown-item ">
                    <Link  onClick={this.UserProfile } to={ { pathname: '/viewUsers', state: { id: localStorage.userId } } }
                      className="nav-link bg-light active elevation-0" >
                      <p >
                        <FontAwesomeIcon icon={ faUser } />
                        Profile Detail
                      </p>
                    </Link>
                  </div>
                  <div className="dropdown-item ">
                    <Link onClick={this.changePassword} to="/changePassword" className="nav-link bg-light active elevation-0">
                    <p >
                        <FontAwesomeIcon icon={ faKey } />

                        Change Password
                      </p>
                    </Link>

                  </div>

                  <div className="dropdown-item ">
                    <Link onClick={this.signout } to="/login" className="nav-link bg-light active elevation-0">
                      <p >
                        <FontAwesomeIcon icon={ faSignOut } />
                        Sign out
                      </p>
                    </Link>

                  </div>
                  {/* Message End */ }

                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div >
    )
  }

 
}

export default Header;