import API from '../../../src/http-common';

class MenuService {
    getWalletMenus(userId) {
        return API.get('/api/v1/getWalletMenus/' + userId);
    }
    getWalletSubMenus(userId) {
        return API.get('/api/v1/getWalletSubMenus/' + userId);
    }
    // getRecentTrxns()
    // {
    //     return API.get('/api/v1/getRecentTrxns');
    // }
    // createMerchant(merchant) {
    //     return API.post("/api/v1/createMerchant", merchant);
    // }
    // deleteMerchant(accountNo) {
    //     return API.delete("/api/v1/deleteMerchant/" + accountNo);
    // }
    // updateMerchant(merchant) {
    //     return API.put("/api/v1/updateMerchant", merchant);
    // }
}
export default new MenuService()