import axios from 'axios';
import React, { Component } from 'react'
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import RefundWalletService from '../../../services/wallet/RefundWalletService';

import 'react-phone-input-2/lib/style.css';
import AsyncSelect from "react-select/async";
import TopupMenu from '../../../components/etopup/TopupMenu';
import Header from '../../../components/common/Header';
import API from '../../../http-common';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOCALES } from '../../common/Constant';
import WalletTrxnService from '../../../services/wallet/WalletTrxnService';
import NumberFormat from 'react-number-format';
import '../../../assets/css/style.css'
import ReactLoading from "react-loading"; 
import LoadingPopup from '../LoadingPopup';
import RollbackRequestService from '../../../services/wallet/RollbackRequestService';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddRefundWallet extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            senderId: '',
            receiverId: '',
            amount: '',
            remark: null,
            date:null,
           isLoading: true,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            refundedTrxns:[],
            requestData:[],
            senderSelectOption: null,
            receiverselectOption: null,

            formErrors: {
                senderError:"",
                receiverError:"",
                amountError: "",
                dateError:""
            },
        }
        this.handelInputChange = this.handelInputChange.bind(this)
    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    Cancel = e => {
        this.props.history.push('/refundWalletList');
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.searchCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.searchCustomerBasedOnFilter()
            this.render()
        }
    }

    
    fetchSenders = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getApprovedRollbackRequest/" + inputValue +"/"+ `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}` + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.requestId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            sendersList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onSenderChange = (senderSelectOption) => {
        const { formErrors } = this.state
        if (senderSelectOption) {
            this.setState({
                senderSelectOption,
                formErrors
            });
            this.findRollbackRequestById(senderSelectOption.value)
        }

        
    }

    findRollbackRequestById(id){
        RollbackRequestService.getApprovedRollbackById(id,localStorage.userId)
        .then(response => {
            console.log(response.data)
            this.setState({
                requestData: response.data
            });
        })
        .catch(e => {
            
        });
    }
    
   
    searchCustomerInfo(e) {
        const { formErrors } = this.state

        if (formValid(this.state.formErrors)) {

            this.state.clear = 0;
            this.state.pageNo = 1;
            this.searchCustomerBasedOnFilter(this.state.senderSelectOption)

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }
    
    searchCustomerBasedOnFilter(customerId) {

        let walletInfo = {
            senderId: customerId,
            receiverId: this.state.receiverId,
            trxnType: process.env.REACT_APP_STATUS_REFUND_WALLET,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }

        WalletTrxnService.getFilteredWalletTrxnsWithPagination(
            this.state.pageNo, this.state.pageSize, localStorage.userId, walletInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    transactionList: data.walletTransactions,
                    isLoading: false,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });
                
            });
    }

    componentDidMount() {
        {/*To get Customer types*/ }
        // this.searchCustomerBasedOnFilter(localStorage.customerIdentity);

        RefundWalletService.getAllRefundedWalletTransactions(
            this.state.pageNo, this.state.pageSize, localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    refundedTrxns: data.content,
                    isLoading: false,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
                
            });

    }

    handleSubmit = e => {
        e.preventDefault();
        const { amount,senderSelectOption,selectReciver,date, formErrors } = this.state
        formErrors.senderError = senderSelectOption == '' || senderSelectOption == null || senderSelectOption == undefined ? 'Please select request id' : this.state.formErrors.senderError;
       if (formValid(this.state.formErrors)) {
        let walletInfo = {
            senderId:this.state.senderSelectOption && this.state.senderSelectOption.value,
            receiverId: this.state.selectReciver && this.state.selectReciver.value,
            amount:this.state.amount,
            date:this.state.date,
            remark:this.state.remark
         }
         this.setState({ isLoading: true})
        RefundWalletService.refundWallet(localStorage.userId, walletInfo)
            .then(
            (response) => {
                NotificationManager.success("Successfully refunded !!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoading: false})
                this.searchCustomerBasedOnFilter(this.state.customerId);               
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoading: false})
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
              
            });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    }


    render() {

        const { transactionList, refundedTrxns,formErrors, pageNo, totalPage,requestData } = this.state;
       
        return (
            <div>
                {/* <Header />
                <TopupMenu />
                <NotificationContainer /> */}

                <div className="content-wrapper bg-white">
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Refund Wallet</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-12 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  btrxns-radius-0" >
                                                <div className="card-header btrxns-radius-0 bg-light">
                                                    <h6 class="title " >Wallet Refund Management </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='senderId'>Request Id:</label>
                                                            <div className="w-90 pull-left">
                                                                <AsyncSelect id='sendersList'
                                                                    className="select"
                                                                    placeholder={ "Search by request id" }
                                                                    isClearable={ true }

                                                                    value={ this.state.senderSelectOption }
                                                                    loadOptions={ this.fetchSenders }
                                                                    isLoading={this.state.senderSelectOption == {} &&
                                                                        this.state.senderSelectOption === undefined &&
                                                                        this.state.senderSelectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onSenderChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />


                                                            </div>
                                                            { formErrors.senderError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.senderError }</span>
                                                            ) }
                                                        </div>
                                                        <div className="form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='receiverId' >Sender: </label>
                                                            <div className="w-90 pull-left">
                                                            <input readOnly type="text" className="form-control " id='sender' name='sender'
                                                              value={requestData.senderFullName}/>

                                                            </div>
                                                        </div>
                                                        <div className="form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='receiverId' >Receiver: </label>
                                                            <div className="w-90 pull-left">
                                                            <input readOnly type="text" className="form-control " id='receiver' name='receiver'
                                                              value={requestData.recevierFullName}/>

                                                            </div>
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input readOnly type="text" className="form-control " id='amount' name='amount'

                                                               value={requestData.amount}/>
                                                        </div>

                                                        <div className="form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='date' >Date </label>
                                                            <div className="w-90 pull-left">
                                                                <input readOnly type="text" className="form-control" id='date' name="date"
                                                                value={requestData.date}
                                                                    placeholder="Date" />
                                                            </div>
                                                        </div>


                                                        <div className=" form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='Country'>Remark:</label>
                                                            <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='remark' name='remark' onChange={ e => this.state.remark = e.target.value } />
                                                        </div>
                                                        <div className="form-group col-12 col-sm-8 col-md-1 col-lg-1 mt-4  pt-3 float-right">
                                                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success btn-sm"><i class="fas fa-exchange-alt" title="Refund" />Rollback</button>
                                                       </div>

                                                    </div>
                                                  

                                                </div>
                                            </div>
                                        </form>
                                        <div className="small-box">
                                            <div className="inner card-header bg-lightblue">
                                                <i className="fas fa-arrow-circle-right" /> Refunded Transactions

                                            </div>
                                            <table className="table btrxns-0" >
                                                {/* <thead className='thead-light'> */ }
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Sender Name</th>
                                                    <th>Receiver Name</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    {/* <th>Balance</th> */ }
                                                    {/* <th>Actions</th> */ }
                                                </tr>
                                                {/* </thead> */ }
                                                <tbody>

                                                     { refundedTrxns !== undefined && refundedTrxns.map(trxn =>

                                                        <tr key={ trxn.id } className='table-tr'>
                                                            <td className='p-0 '>{ trxn.senderCustomerId.fullName }</td>
                                                            <td className='p-0 '>{ trxn.receiverCustomerId.fullName }</td>
                                                            <td className='p-0 '>
                                                                <NumberFormat value={ trxn.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                            </td>
                                                            { trxn.trxnType == 0 &&
                                                                <td className='p-0 '>{ "Payment" }</td>
                                                            }
                                                            { trxn.trxnType == 1 &&
                                                                <td className='p-0 '>{ "Transfer" }</td>
                                                            }
                                                            { trxn.trxnType == 2 &&
                                                                <td className='p-0 '>{ "CachIn" }</td>
                                                            }
                                                            { trxn.trxnType == 3 &&
                                                                <td className='p-0 '>{ "CachOut" }</td>
                                                            }
                                                            { trxn.trxnType == 6 &&
                                                                <td className='p-0 '>{ "Pay Bill" }</td>
                                                            }
                                                            { trxn.trxnType == 7 &&
                                                                <td className='p-0 '>{ "Topup Fund" }</td>
                                                            }
                                                            { trxn.trxnType == 8 &&
                                                                <td className='p-0 '>{ "Wallet Fund" }</td>
                                                            }
                                                            <td className='p-0 '>{ trxn.createdAt }</td>

                                                        </tr>

                                                    ) } 
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                        </form>
                                </>}
                             />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddRefundWallet);
