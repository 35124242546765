import API from '../../../src/http-common';

class TopupTransferService {
    createTopupTransfer(senderId, receiverId, amount, userId) {
        return API.get('/api/v1/createTopupTransfer/' + senderId + "/" + receiverId + "/" + amount + "/" + userId);
    }

    createSalesTopupTransfer(senderId, receiverId, amount, userId) {
        return API.get('/api/v1/salesTopupTransfer/' + senderId + "/" + receiverId + "/" + amount + "/" + userId);
    }

}
export default new TopupTransferService()