import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import { Navigate, withRouter } from 'react-router-dom';


import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import Header from '../Header';


class CustomerVerification extends React.Component {

  constructor () {

    super();
    this.state = {
      otp: '',
      mobileNo: ''
    }
  }
  cancelVerification = e => {
    this.props.history.push('/customers');
  }
  handleSubmit = e => {
    e.preventDefault();

    CustomerVerificationService.activateCustomerAccount(this.otp, this.props.location.state.mobileNo)
      //response.data.success === "Success"
      .then(response => {
        this.props.history.push('/customers');
        NotificationManager.success('Activated Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        e.target.reset();

      })
      .catch(function (err) {
        if (err.response.status === 404) {
          //  toast.error('Mobile number already exists !!');
          NotificationManager.error("Otp not correct" ,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else if (err.response.status === 406) {
          //toast.error('File extension not allowed !!');
          NotificationManager.error("Please insert otp ",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {
          // toast.error('Something went wrong !!');
          NotificationManager.error("Something went wrong !!",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
      });

  };


  render() {
    return (

      <div>
        {/* <Header/>
        <Menu /> */}
        <NotificationContainer />
        {/* Content Wrapper. Contains page content */ }
        {/* Content Wrapper. Contains page content */ }
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */ }
          <div className="content-header">
            <div className="container-fluid">
              {/*  
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">Add Cash In Information</li>

                  </ol>
                </div>
              </div> 
            */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Customer Verification</li>
                </ol>
              </div>
              <div >
                <div className="modal-body pt-5 ">

                  <div className='col-1'></div>
                  <div className='col-8 bg-light p-3'>
                    {/*
                    <div class="modal-header">
                      <h4 class="modal-title" >
                         Agent Registration
                      </h4>
                    </div> */}
                    <form onSubmit={ this.handleSubmit }>
                      {/*Basic Information*/ }
                      <div className="card  border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          { this.props.location.state.operation === 'deactivation' &&
                            <h6 class="title">Enter OTP to deactivate</h6>
                          }
                          { this.props.location.state.operation !== 'deactivation' &&
                            <h6 class="title">Enter OTP to activate</h6>
                          }
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='OTP'>OTP:</label>
                              {/* <PhoneInput maxLength={13} onChange={e => this.mobileNo = e.target.value}/> */ }
                              <input type="text" className="form-control " id='OTP' minLength={ 6 } maxLength={ 6 } onChange={ e => this.otp = e.target.value } required="required" />
                            </div>
                          </div>

                        </div>
                      </div>


                      {/*Basic Information*/ }

                      <div class="btn-group d-flex justify-content-center clear-fix">
                        <button type="submit" class="btn btn-success mr-5 ml-5 mt-4 pl-2">Verify</button>
                        <button type="submit" class="btn btn-warning mr-5 mt-4 px-2" onClick={ () => this.cancelVerification() } >Cancel</button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>


              {/* /.row */ }
            </div>
            {/* /.container-fluid */ }
          </div>

          {/* /.content */ }
        </div>
      </div>

    )
    
  }
}

export default withRouter(CustomerVerification);
