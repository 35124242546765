import API from '../../../src/http-common';

class SmsService {

    createSmsNotification(notificationData) {
        return API.post('/api/v1/createSmsNotification', notificationData);
    }
    allNotification(pageNo, pageSize, userId) {
        return API.get('/api/v1/getAllNotifications/' + pageNo + '/' + pageSize + '/' + userId);
    }
    getSmsNotificationById(id, userId) {
        return API.get('/api/v1/getSmsNotificationMessage/' + id + '/' + userId);
    }

    updateSmsNotification(notificationData) {
        return API.put('/api/v1/updateSmsNotification', notificationData);
    }
    getNotificationByTrxnType(trxnType, userId) {
        return API.get('/api/v1/getNotificationByTrxnType/' + trxnType + '/' + userId);
    }
}
export default new SmsService()