import API from '../../http-common';


class PasswordRecoveryService {

    // login 
    createPasswordRecoveryInfo(loginInfo) {
      return API.post("/api/aelafAdmin/createPasswordRecoveryInfo", loginInfo);
    }

    authenticatOTPRecovery(loginInfo){
      return API.post('/api/aelafAdmin/authenticatOTPRecovery', loginInfo);
    }
   

}
export default new PasswordRecoveryService()