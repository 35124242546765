import API from '../../http-common';

class CustomerDealerService {
  // get all Commission types
  getCommissionTypes() {
    return API.get("/api/v1/getCommissionTypes");
  }

  // get all Customer Dealers
  getCustomerDealers() {
    return API.get("/api/v1/getCustomerDealers");
  }

  // get Customer Dealers
  getCustomerDealersById(id, userId) {
    return API.get("/api/v1/getCustomerDealersById/" + id + "/" + userId);
  }

  // get all Customer Dealers Information 
  searchCustomerDealerInfo( pageNo, pageSize, userId) {
    return API.get("/api/v1/searchCustomerDealer" +"/"+ pageNo + "/"+ pageSize + "/"+ userId);
  }
  
   // create  Customer Dealer Information 
   createCustomerDealers(customerDealerInfo) {
    return API.post("/api/v1/createCustomerDealers", customerDealerInfo);
  }

   // update  Customer Dealer Information 
  updateCustomerDealers(customerDealerInfo) {
    return API.put("/api/v1/updateCustomerDealers", customerDealerInfo);
  }

   // remove  Cash Information 
  deleteCustomerDealers(id, userId) {
    return API.delete('/api/v1/deleteCustomerDealers' + '/' + id + "/" + userId);
  }

  changeSubDealerStatus(id, delaerId, status, userId){
    return API.put('/api/v1/changeSubDealerStatus' + '/' + id +  '/' + delaerId + '/' + status + "/" + userId);
  }

  // get list of Cash Information 
  getFilteredCustomerDealers( CustomerDealers,pageNo, pageSize, userId)
  {
  return API.put("/api/v1/getFilteredCustomerDealers"  + "/" + pageNo + "/" + pageSize +"/" + userId, CustomerDealers);
  }

  // Verify Customer Dealers bank Transfer Information 
  processCustomerDealers(id, customerDealers) {
    return API.put("/api/v1/processCustomerDealers" +"/" + id, customerDealers);
  }

  // Approve Customer Dealers Bank Transfer Information 
  approveCustomerDealers(id, customerDealers) {
    return API.put("/api/v1/approveCustomerDealers" +"/" + id, customerDealers);
  }

  getSubDealerInfo(customerId, userId){
    return API.get("/api/v1/getSubDealerInfo/"+ customerId + "/" + userId);
  }
  getCustomerDealersByDealerId(dealerId,userId) {
    return API.get("/api/v1/getDealerCustomer/"+dealerId+'/'+userId);
  }

  //Searching main dealers only
  findMainDealersList(serachParam,pageNo, pageSize, userId,) {
    return API.put("/api/v1/findMainDealersList/" + pageNo +'/'+ pageSize + '/' + userId, serachParam);
  }


  //Searching sub dealers only
  findSubDealersList(serachParam,pageNo, pageSize, userId,) {
    return API.put("/api/v1/findSubDealersList/" + pageNo +'/'+ pageSize + '/' + userId, serachParam);
  }

    //Searching retailers only
    findRetailersList(serachParam,pageNo, pageSize, userId,) {
      return API.put("/api/v1/findRetailersList/" + pageNo +'/'+ pageSize + '/' + userId, serachParam);
    }

    getDealersHierarchyByDealerId(dealerId,userId) {
      return API.get("/api/v1/getDealersHierarchyByDealerId/"+dealerId+'/'+userId);
    }
}
export default new CustomerDealerService()