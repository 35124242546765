import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt, faUserCircle, faUser, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../common/Constant'
import PhoneInput from 'react-phone-input-2';
import LoginService from '../../../../services/common/LoginService';
import Popup from '../../../wallet/Popup';
import Header from '../../Header';
import Menu from '../../../wallet/Menu';
import Dropzone from 'react-dropzone';
import ReactLoading from "react-loading";
import TopupMenu from '../../../etopup/TopupMenu';
import AsyncSelect from 'react-select/async'
import API from '../../../../http-common';
import LoadingPopup from '../../../wallet/LoadingPopup';
import PopupUserReq from '../../../wallet/PopupUserReq';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\\/ ]{3,20}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\\/ ]{0,20}$/u)
const emailRegEx = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const loginIdRegEx = RegExp(/^[a-zA-Z0-9_]{3,12}$/u)

class UsersList extends Component {

  constructor(props) {

    super(props)

    this.state = {
      isLoading: false,
      isLoadingPop: false,
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      id: '',
      userList: [],
      users: null,
      customerId: [],
      counter: 1,
      pageCounter: 1,
      mobileNo: null,
      email: null,
      isExpand: true,
      setIsOpen: false,
      isOpen: false,
      isResetOpen: false,
      isNormalUser: true,
      isSuperUser: false,
      isAdministrator: false,
      isAccountLocked: false,
      isOfficeUser:false,
      btnValue: "Save",
      accountStatus: false,
      onEdit: false,
      firstName: "",
      middleName: "",
      lastName: "",
      userName:"",
      selectedFiles: undefined,
      currentFile: undefined,
      attachment: null,
      newPassword: '',
      confirmPassword: '',
      searchValue: null,
      customerType: '',
      formErrors: {
        newPasswordError: "",
        confirmPasswordError: "",
        passwordError: "",
        firstNameError: "",
        userNameError: "",
        middleNameError: "",
        lastNameError: "",
        profilePicError: '',
        loginIdError: "",
        phoneNumberError: "",
        emailError: "",
        userError:""
      },
    }

    this.userDetail = this.userDetail.bind(this);
    this.addUser = this.addUser.bind(this);

    this.onDrop = this.onDrop.bind(this);

    this.AssignRole = this.AssignRole.bind(this);

  }

  gotoEnteredPage = (pageNumber) => {
    this.state.pageNo = pageNumber
    let customerId = this.state.searchValue == null? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachUserfoBasedOnFilter(customerId)
    this.render()
  }

  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachUserfoBasedOnFilter(customerId)
    this.render()

  }

  gotoPageFirst = () => {
    this.state.pageNo = 1
    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
    this.seachUserfoBasedOnFilter(customerId)
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
      this.seachUserfoBasedOnFilter(customerId)
      this.render()

    }
  }

  nextPage = () => {
    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value;
      this.seachUserfoBasedOnFilter(customerId)
      this.render()
    }
  }


  addUser() {
    this.props.history.push(`/addUsers`);
  }

  userDetail(id) {
    this.props.history.push({pathname: "/viewUsers", state: {id: id}});
  }

  AssignRole(id) {
    this.props.history.push({pathname: "/userRoleList", state: {id: id}})
  }


  filterCashOutInfo(e) {
    if (formValid(this.state.formErrors)) {
      this.state.clear = 0;
      let customerId = this.state.searchValue ? localStorage.customerIdentity : this.state.searchValue.value;
      this.seachUserfoBasedOnFilter(customerId)
    }
  }

  seachUserfoBasedOnFilter(customerId) {
    
    this.userList = null;
    LoginService.getUsers(
      customerId, this.state.pageNo, this.state.pageSize)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          isLoading: true,
          userList: data.users,
          isOpen: false,
          totalPage: data.totalPages,
          totalelement: data.totalelement,
          pageNo: data.pageNumber + 1
        });
      });


    LoginService.getUserInfo(localStorage.userId)
      .then(response => {
        this.setState({
          customerType: response.data.type
        });

      })
      .catch(e => {
        
      });
  }

  clear() {
    this.setState({
      id: '',
      mobileNo: '',
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      attachment: null,
      currentFile: undefined,
      selectedFiles: undefined,
      isAccountLocked: false,
      isAdministrator: false,
      isNormalUser: true,
      isSuperUser: false,
      isOfficeUser: false
    })
  }

  resetPassword = e => {
    e.preventDefault();
    const { newPassword, confirmPassword, formErrors } = this.state
    formErrors.passwordError = ''
    formErrors.newPasswordError = newPassword == '' || newPassword == null ? "New Password is Required" : this.state.formErrors.newPasswordError;
    formErrors.confirmPasswordError = confirmPassword == '' || confirmPassword == null ? "Confirm Password is Required" : this.state.formErrors.confirmPasswordError;

    if (formValid(this.state.formErrors)) {
      if (newPassword == confirmPassword) {
        let changeInfo = new FormData();
        changeInfo.append("userId", this.state.id)
        changeInfo.append("newPassword", newPassword)
        LoginService.ResetPassword(this.state.id, newPassword)
          .then(
            (res) => {
              NotificationManager.success(`${process.env.REACT_APP_RESET_PASSWORD_SUCCESS_MEG}`, `${process.env.REACT_APP_MSG_TITLE}`);
            },
            (err) => {
              NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            })
          .catch(function (response) {
            NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
          });
      } else {
        formErrors.passwordError = "password mismatch error"
        this.setState({
          formErrors
        })
      }
    }
    this.setState({
      formErrors
    })
  };

  componentDidMount() {
    this.seachUserfoBasedOnFilter(localStorage.customerIdentity)
  }

  onDrop(files) {

    if (files.length > 0) {
      this.setState({ selectedFiles: files });
      this.setState({ currentFile: this.state.selectedFiles[0] });

    }
  }

  toggleChangePassword = id => {

    this.setState({
      id: id,
      isResetOpen: !this.state.isResetOpen
    });
  }


  togglePopup = (id) => {
    this.state.mobileNo = ''
    this.state.email = ''
    this.state.firstName = ''
    this.state.middleName = ''
    this.state.lastName = ''
    this.state.attachment = null
    this.state.currentFile = undefined
    this.state.selectedFiles = undefined
    this.state.isAccountLocked = false
    this.state.isAdministrator = false
    this.state.isOfficeUser = false
    this.state.isNormalUser = true
    this.state.isSuperUser = false
    this.state.users = null
    this.state.id = ''
    this.state.onEdit = false
    this.state.btnValue = "Save"
    if (id != null || id != undefined) {
      this.state.btnValue = "update"
      this.state.onEdit = true
      LoginService.getUserInfo(id)
        .then(response => response.data)
        .then((data) => {
          this.setState({
            users: data
          });
        });


    }
    if (this.state.id == '' || this.state.id == null || this.state.id == undefined) {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }

  onChangeReceiverMobileNo = mobile => {
    const { formErrors } = this.state
    formErrors.phoneNumberError =
      mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digites with a min 12 " : ""
    this.setState({
      mobileNo: mobile,
      formErrors
    })
  }

  phoneonBlur = e => {
    e.preventDefault();
    let value = e.target.value;
    let formErrors = this.state.formErrors;
    formErrors.phoneNumberError =
      value.length == 0 || value.length < 12 || !phoneNoRegEX.test(value) ? "Phone Number sholud be digites with a min 12 " : ""

    this.setState({
      formErrors
    })
  }

  handelChange = e => {
    e.preventDefault();
    const { name, value } = e.target

    let formErrors = this.state.formErrors;

    switch (name) {
      case 'firstName':
        formErrors.firstNameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
        break;
      case 'middleName':
        formErrors.middleNameError =
          !middleNameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
        break;
      case 'lastName':
        formErrors.lastNameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphanumeric and (/) characters with max 20" : ""
        break;
      case 'userName':
          formErrors.userError =
              value.length === 0 || !loginIdRegEx.test(value) ? "User Name Value sholud only alphanumeric " : ""
          break;
      case 'email':
        formErrors.emailError =""
        if(value.length != 0){
        formErrors.emailError =
          !emailRegEx.test(value) ? " insert a valid " + name : ""
        }
        break;
      case 'mobileNo':
        formErrors.phoneNumberError =
          value.length == 0 || value.length < 12 || !phoneNoRegEX.test(value) ? "Phone Number sholud be digites with a min 12 " : ""
        break;
      default:
        break;
    }


    this.setState({
      formErrors, [name]: value
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    const { email, firstName, lastName, currentFile, mobileNo, formErrors, onEdit } = this.state
    formErrors.phoneNumberError = mobileNo == '' || mobileNo == null || mobileNo.length <= 3 ? "Phone number is Required" : this.state.formErrors.phoneNumberError;
    // formErrors.emailError = email == '' || email == null ? "Email is Required" : "";
    formErrors.firstNameError = firstName == '' || firstName == null ? "First Name is Required" : this.state.formErrors.firstNameError ;
    formErrors.lastNameError = lastName == '' || lastName == null ? "Last Name is Required" : this.state.formErrors.lastNameError;


    if (formValid(this.state.formErrors)) {
      let newUserInfo = new FormData();
      newUserInfo.append("id", this.state.id)
      newUserInfo.append("customerId", this.state.searchValue === null ? localStorage.customerIdentity : this.state.searchValue.value)
      newUserInfo.append("mobileNo", mobileNo)
      newUserInfo.append("email", email)
      newUserInfo.append("isAccountLocked", this.state.isAccountLocked)
      newUserInfo.append("isSuperUser", this.state.isSuperUser)
      newUserInfo.append("isAdministrator", this.state.isAdministrator)
      newUserInfo.append("isNormalUser", this.state.isNormalUser)
      newUserInfo.append('firstName', this.state.firstName);
      newUserInfo.append('middleName', this.state.middleName);
      newUserInfo.append('lastName', this.state.lastName);
      newUserInfo.append('isOfficeUser', this.state.isOfficeUser);
      newUserInfo.append('profilePic', currentFile);
      newUserInfo.append('profilePic', currentFile);
      newUserInfo.append('userName', this.state.userName );
      newUserInfo.append('userId', localStorage.userId);

     this.setState({ isLoadingPop: true})
      LoginService.createEmplyeedAUser(newUserInfo)
        .then(
          (res) => {
            if(!onEdit){
            NotificationManager.success(`${process.env.REACT_APP_SAVE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            }else{
              NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            }
            this.setState({ isLoadingPop: false})
            let customerId = this.state.searchValue === null ? localStorage.customerIdentity : this.state.searchValue.value
            this.seachUserfoBasedOnFilter(customerId)

          },
          (err) => {
            NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`,3000);
          this.setState({ isLoadingPop: false})
        });

      this.state.id ? this.state.isOpen = true : this.state.isOpen = false
      this.setState({
        isOpen: !this.state.isOpen
      })
    }

    this.setState({
      formErrors
    })
  }

  searchData = e => {

    let customerId = this.state.searchValue == null ? localStorage.customerIdentity : this.state.searchValue.value
  
    this.seachUserfoBasedOnFilter(customerId)

  }

  onChangeLocked() {
    this.setState({
      isAccountLocked: !this.state.isAccountLocked
    })
  }

  onChangeUserStatus() {
    this.setState({
      isOfficeUser: !this.state.isOfficeUser
    })
  }

  onChangeSuper() {
    this.setState({
      isNormalUser: this.state.isSuperUser,
      isAdministrator: false,
    })
  }

  onChangeAdmin() {
    this.setState({
      isNormalUser: this.state.isAdministrator,
      isSuperUser: false,
    })
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
    
      setTimeout(() => {
        API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id })
            });
            callback(temp)
            this.setState({
              customerList: data
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (searchValue) => {
    if (searchValue) {
      this.setState({
        searchValue
      });
    }
  }

  clearValue = e => {
    this.state.searchValue = null
    this.seachUserfoBasedOnFilter(localStorage.customerIdentity)
}

  render() {
    const { userList, onEdit, users, customerType, selectedFiles, formErrors, pageNo, totalPage, pageSize, isLoading } = this.state;

    pageNo == 1 ? this.state.counter = pageNo : this.state.counter = (pageSize * (pageNo - 1)) + 1

    if (onEdit && users != null && users != undefined && users != '') {

      this.state.id = users.id
      this.state.mobileNo = users.loginId
      this.state.email = users.email
      this.state.firstName = users.firstName
      this.state.middleName = users.middleName
      this.state.lastName = users.lastName
      this.state.userName = users.userName
      this.state.attachment = users.attachment
      this.state.isOfficeUser = users.officeUser
      this.state.isAccountLocked = users.accountLocked
      this.state.isAdministrator = users.administrator
      this.state.isNormalUser = users.normalUser
      this.state.isSuperUser = users.superUser
      this.state.onEdit = false
    }
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <TopupMenu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >User List</li>
                    </ol>
                  </div>

                </div>
                <div className="card" >
                  <div className="card-body">
                    <div className="form-title" >Display User Information</div>
                    <div className="form-group col-6 col-sm-6 col-md-6 col-lg-6  ">
                      {customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?

                        <div>
                          <label htmlFor='CustomerList'>Customer:</label>

                          <AsyncSelect id='CustomerList'
                            placeholder={"Search by name, account no or mobile number"}
                            value={this.state.searchValue}
                            isClearable={true}
                            loadOptions={this.fatchData}
                            isLoading={this.state.searchValue === {} &&
                              this.state.searchValue === undefined &&
                              this.state.searchValue === null ? true : false
                            }

                            onChange={(e) => {
                              this.onSearchChange(e)
                            }}
                            defaultOptions={false}
                          />

                          <div >
                            <button title={"Search"}
                              className="btn btn-sm btn-success primary m-1 float-right"
                              onClick={this.searchData}>Search</button>
                            <button type='submit' onClick={this.clearValue} title={"Clear"}
                              className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                          </div>
                        </div>

                        :
                        <></>
                      }
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <button title={"New User"} type={"button"}
                      style={{ marginLeft: "2px" }}
                      onClick={() => this.togglePopup(null)}
                      className="btn btn-sm btn-success primary m-1  float-left">
                      <i className="fas fa-plus" /> User <FontAwesomeIcon icon={faUser} />
                    </button>
                    <table className=" table table-striped table-birdered" style={{ fontSize: 'smaller' }} >
                      <thead className=' thead-light'>
                        <tr style={{ marginTop: 0 }}>
                          <th>No.</th>
                          <th>LogId</th>
                          <th>Name</th>
                          <th>email</th>
                          <th>Account Status </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className='content-wrapper '>
                      
                        {userList.map(user =>
                          <>
                          {localStorage.loginId !== user.loginId &&
                          <tr key={user.id}>
                            <td className='p-1 '>{this.state.counter++}</td>
                            <td className='p-1 '>{user.loginId}</td>
                            <td className='p-1 '>{user.fullName}</td>
                            <td className='p-1 '>{user.email}</td>
                            <td className='p-1 '>{user.accountLocked ? "Locked" : "Active"}</td>
                            <td className='p-1'>

                              <button onClick={() => this.userDetail(user.id)} title={"Detail"} className="btn btn-info btn-sm"><i className="nav-icon fa fa-eye fa-sm" /> </button>

                              <button title={"Edit"}
                                style={{ marginLeft: "2px" }}
                                onClick={() => this.togglePopup(user.id)}
                                className="btn btn-warning btn-sm">
                                <i className="nav-icon fas fa-edit fa-sm" />
                              </button>
                              <button title={"add roles"}
                                style={{ marginLeft: "2px" }}
                                onClick={e => this.AssignRole(user.id)}
                                className="btn btn-success btn-sm">
                                <i className="nav-icon 	fas fa-user-shield fa-sm" />
                              </button>
                              <button title={"Reset password"}
                                style={{ marginLeft: "2px" }}
                                onClick={() => this.toggleChangePassword(user.id)}
                                className="btn btn-danger btn-sm">
                                <i className="nav-icon fas fa-unlock fa-sm" />
                              </button>
                            </td>
                          </tr>
    }
                          </>
                        )}
                      </tbody>
                    </table>

                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{''}
                        <strong>
                          {pageNo} of {totalPage}
                        </strong>
                        {''}
                      </span>
                      <span>
                        | Go To Page : {''}
                        <input type='number' defaultValue={pageNo}
                          onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          }} />
                      </span>
                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackward} />
                      </button>

                      <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " disabled={'true'} value={pageNo} />

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                        <FontAwesomeIcon icon={faForward} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                        <FontAwesomeIcon icon={faForwardFast} />
                      </button>
                    </div>
                    {this.state.isOpen && <PopupUserReq
                      content={<>
                        <form className='' onSubmit={this.handleSubmit} noValidate>
                          <div className="card">
                            <div className="card-body" >
                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor='mobileNo' >Mobile No:</label>
                                <PhoneInput countryCodeEditable={false}
                                  value={this.state.mobileNo}
                                  country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                  inputStyle={{ width: '100%', height: 'auto' }}
                                  placeholder="Enter phone number" id='mobileNo'
                                  onChange={phone => this.onChangeReceiverMobileNo(phone)} />
                                {formErrors.phoneNumberError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.phoneNumberError}</span>
                                )}

                              </div>
                              <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='userName'>User Name:</label>
                                      <input type="text" className="form-control " id='userName'
                                        name='userName' value={this.state.userName}
                                        onChange={this.handelChange}
                                        onBlur={this.handelChange} />

                                      {formErrors.userError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.userError}</span>
                                      )}
                                    </div>


                              <div className=" form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor='email'>Email:</label>
                                <input type="text" className="form-control " id='email'
                                  name='email' value={this.state.email}
                                  onChange={this.handelChange}
                                  onBlur={this.handelChange}
                                />
                                {formErrors.emailError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.emailError}</span>
                                )}
                              </div>
                              <div className="card my-4 border-radius-0" >
                                <div className="card-header border-radius-0 bg-light">
                                  <h6 class="title " >Personal Information </h6>
                                </div>
                                <div className="card-body">
                                  <div className='row'>

                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='firstName'>First Name:</label>
                                      <input type="text" className="form-control " id='firstName'
                                        name='firstName' value={this.state.firstName}
                                        onChange={this.handelChange}
                                        onBlur={this.handelChange} />

                                      {formErrors.firstNameError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.firstNameError}</span>
                                      )}
                                    </div>
                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='middleName'>Middle Name:</label>
                                      <input type="text" className="form-control " id='middleName' name='middleName'
                                        value={this.state.middleName}
                                        onChange={this.handelChange} onBlur={this.handelChange} />
                                      {formErrors.middleNameError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.middleNameError}</span>
                                      )}
                                    </div>
                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <label htmlFor='lastName'>Last Name:</label>
                                      <input type="text" className="form-control " id='lastName' name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.handelChange} onBlur={this.handelChange} />
                                      {formErrors.lastNameError.length > 0 && (
                                        <span style={{ color: "#F61C04" }}>{formErrors.lastNameError}</span>
                                      )}
                                    </div>
                                    <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                      <div className='row'>
                                        <label htmlFor='profilePic'>Profile Picture:</label>
                                        <img id="logo" className="mx-auto d-block rounded-circle" style={{ width: '70px', height: '70px', margin: 'auto', alignSelf: 'center' }} src={"data:image/png;base64," + this.state.attachment}></img>

                                        <br />

                                        {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleAttachmentChange} /> */}
                                        {/* <FileUploader type="file"onDrop={this.onDrop} label="Drag and Drop file Or Select " classes="Drag and Drop" maxSize="2mb" onDrop={(file) => console.log(file)} onSelect={(file) => console.log(file)} multiple={false} name="attachment" /> */}

                                        <Dropzone
                                          onDrop={this.onDrop}
                                          multiple={false}>

                                          {({ getRootProps, getInputProps }) => (
                                            <section>
                                              <div {...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" })} style={{ border: "thin dashed black" }}>
                                                <input {...getInputProps()} />
                                                {selectedFiles && selectedFiles[0].name ? (
                                                  <div className="selected-file">
                                                    {selectedFiles && selectedFiles[0].name}
                                                  </div>
                                                ) : (
                                                  "Drag and drop file here, or click to select file"
                                                )}
                                              </div>
                                              <aside className="selected-file-wrapper">
                                              </aside>
                                            </section>
                                          )}
                                        </Dropzone>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor='status'>Account Locked: </label>
                                <input style={{ marginLeft: "10px" }} id="accountStatus"
                                  checked={this.state.isAccountLocked}
                                  type="Checkbox"
                                  onChange={e => { this.onChangeLocked(); this.state.isAccountLocked = e.target.checked }} />
                              </div>

                              <div className="form-group col-12 col-sm-4 col-md-4 col-lg-5">


                                <label style={{ marginLeft: "20px" }} htmlFor='status'>Super User: </label>
                                <input type="Checkbox" style={{ marginLeft: "10px" }} id="status"
                                  checked={this.state.isSuperUser}
                                  onChange={e => { this.onChangeSuper(); this.state.isSuperUser = e.target.checked }}
                                  placeholder="Super user" />

                                <label htmlFor='administrator'>Administrator: </label>
                                <input type="Checkbox" style={{ marginLeft: "10px" }} id="administrator"
                                  checked={this.state.isAdministrator}
                                  onChange={e => { this.onChangeAdmin(); this.state.isAdministrator = e.target.checked }}
                                />

                                <label style={{ marginLeft: "10px" }} htmlFor='normalUser'>Normal User: </label>
                                <input type="Checkbox" style={{ marginLeft: "10px" }}
                                  checked={this.state.isNormalUser}
                                  id="normalUser"
                                  onChange={e => { this.state.isNormalUser = e.target.checked }}
                                />
                              </div>

                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor='officeUser'>Office user: </label>
                                <input style={{ marginLeft: "10px" }} id="officeUser"
                                  checked={this.state.isOfficeUser}
                                  type="Checkbox"
                                  onChange={e => { this.onChangeUserStatus(); this.state.isOfficeUser = e.target.checked }} />
                              </div>

                              <div className="row  d-flex justify-content-left ">
                                <button type="submit" style={{ marginLeft: "20px" }}
                                  className="btn btn-success px-4 py-1">{this.state.btnValue}</button>
                                <button type="submit" onClick={() => this.togglePopup()}
                                  className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>}
                    />}

                    {this.state.isResetOpen && <Popup
                      content={<>

                        <form className='' onSubmit={this.resetPassword} noValidate>
                          <div className="card">
                            <div className="card-body" >

                              <div>
                                {formErrors.passwordError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.passwordError}</span>
                                )}
                              </div>
                              <div className="clearfix">&nbsp;</div>

                              <div className="input-group justify-content-left">
                                <div className="input-group-text  bg-transparent p-0">
                                  <FontAwesomeIcon icon={faKey} />
                                </div>
                                <div className="input-group-append p-0">
                                  <input type="password" className="form-control" id='newPassword'
                                    onChange={this.handelChange} onBlur={this.handelChange} name='newPassword'
                                    placeholder="New Password" />


                                </div>
                                {formErrors.newPasswordError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.newPasswordError}</span>
                                )}
                              </div>
                              <div className="clearfix">&nbsp;</div>
                              <div className="input-group justify-content-left">
                                <div className="input-group-text  bg-transparent p-0">
                                  <FontAwesomeIcon icon={faKey} />
                                </div>
                                <div className="input-group-append p-0">
                                  <input type="password" className="form-control" id='confirmPassword'
                                    onChange={this.handelChange} onBlur={this.handelChange} name='confirmPassword'
                                    placeholder="Confirm Password" />


                                </div>
                                {formErrors.confirmPasswordError.length > 0 && (
                                  <span style={{ color: "#F61C04" }}>{formErrors.confirmPasswordError}</span>
                                )}
                              </div>

                              <div className="clearfix">&nbsp;</div>

                              <div className="row  justify-content: space-between; ">
                                <button type="submit" title={"Reset Password"}
                                  className="btn btn-sm btn-success  "
                                  style={{ marginLeft: "30px" }}>
                                  Reset Password
                                  <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faKey} />
                                </button>
                                <button type="submit" onClick={() => this.toggleChangePassword()}
                                  className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>

                              </div>

                            </div>
                          </div>

                        </form>

                      </>}
                    />}
                  </div>
                  <NotificationContainer />
                </div>
                {this.state.isLoadingPop && <LoadingPopup
                             content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                            </>}
                           />}
                {/* /.row */}
              </div>

              {/* /.container-fluid */}
            </div>


            {/* /.content */}
          </div>
        </div>
      )
    }
  }
}
export default UsersList