import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter, Link } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import RollbackTransactionService from '../../../services/etopup/RollbackTransactionService';
import PhoneInput from 'react-phone-input-2';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import LoginService from '../../../services/common/LoginService'; 
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';
import RollbackRequestService from '../../../services/wallet/RollbackRequestService';

const mobileNoRegEX = RegExp(/^[0-9\b]+$/u)
const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/);

class CreateRollbackTransaction extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            topupSalesHisId: null,
            senderId: localStorage.customerIdentity,
            dealerSelectOption: null, 
            customerId:'',
            receiverMobileNo: '',
            isLoading: false,
            rollbackId:'',
            customerType: '',
            amount: '',
            date: '',
            remark: '',
            customerId: '',
            transactionType:null,
            interuptedTopupTrxns: [],
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            requestData:[],
            allRollBackTrxns: [],


            formErrors: {
                receiverMobileNoError: "",
                dealerSelectOptionError:"",
                dateError:"",
                amountError: "",
                transactionTypeError:""
            },
        }
    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {

        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachCustomerBasedOnFilter()
            this.render()
        }
    }   
    
    
    fatchDealers = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getApprovedRollbackRequest/" + inputValue + "/" + this.state.transactionType +"/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.requestId , value: element.id })
                        });
                        callback(temp)
                        this.setState({ 
                            dealersList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
          }
    }
    
    onDealerSearchChange = (dealerSelectOption) => {
        const { formErrors } = this.state
       
        formErrors.dealerSelectOptionError = dealerSelectOption == "" || dealerSelectOption == null ? 'please select dealer' : "";

        if (dealerSelectOption) {

            this.state.dealerSelectOption = dealerSelectOption
            this.setState({
                dealerSelectOption: dealerSelectOption,
                formErrors
            });
          this.findRollbackRequestById(dealerSelectOption.value)
        }

    }
    
    componentDidMount() {
        this.seachCustomerBasedOnFilter()
        this.getInterruptedTopupsByCustomerId(localStorage.customerIdentity)

        LoginService.getUserInfo(localStorage.userId)
        .then(
          (response) => {
            this.setState({
              customerType: response.data.type,
              loginId: response.data.customerId
            })
          },
          (error) => {
           
  
          })
        .catch(e => {
         
        });
      
    }
    seachCustomerBasedOnFilter() {
        RollbackTransactionService.getAllRollbackHistory(
            this.state.pageNo, this.state.pageSize, localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                if (data.content) {
                    this.setState({
                        isLoading: false,
                        allRollBackTrxns: data.content,
                        isOpen: false,
                        totalPage: data.totalPages,
                        totalelement: data.pageable.totalelement,
                        pageNo: data.pageable.pageNumber + 1
                    });

                }
                this.setState({ isLoading: false })
            });
    }
    handleSubmit = e => {

        e.preventDefault();
        const { amount,dealerSelectOption,receiverMobileNo,date, transactionType, formErrors } = this.state
        formErrors.dealerSelectOptionError = dealerSelectOption == '' || dealerSelectOption == null || dealerSelectOption == undefined ? 'Please select request id' : this.state.formErrors.dealerSelectOptionError;
        formErrors.transactionTypeError =  transactionType == '' || transactionType == null || transactionType == undefined ? 'You must make a selection' : this.state.formErrors.transactionTypeError;
        
        
        if (formValid(this.state.formErrors)) {
           
            if(this.state.customerType==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`)
            {
                this.state.senderId=this.state.dealerSelectOption.value
            }
            if(this.state.customerType!==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`)
            {
                this.state.senderId=localStorage.customerIdentity
            }
        var rollbackTransactionData = new FormData(); 
        rollbackTransactionData.append("requestId", this.state.senderId);
        rollbackTransactionData.append("rollbackId", this.state.rollbackId);
        rollbackTransactionData.append("transactionType", this.state.transactionType);
        rollbackTransactionData.append("remark", this.state.remark);
        rollbackTransactionData.append("userId", localStorage.userId);

    
        const answer = window.confirm("Are you sure you want to rollback ?");
        if (answer) {
            this.setState({ isLoading: true})
            RollbackTransactionService.rollbackTransaction(rollbackTransactionData)
                .then(response => {
                    NotificationManager.success("Successfully rollbacked ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    
                    this.seachCustomerBasedOnFilter()
                }, 
                (err) => {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})
                })

                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})
                });
        }
    } else {
        NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
        this.setState({
          formErrors
        })
      }
    };

    getInterruptedTopupsByCustomerId(customerId) {

        RollbackTransactionService.getInterruptedTopupsByCustomerId(customerId, localStorage.userId)
            .then(response => {
                this.setState({
                    interuptedTopupTrxns: response.data
                });
            })
            .catch(e => {
                
            });
    }
    Cancel = (e) => {
        this.props.history.push('/partnerProviderBalanceList');
    }
    rollbackBalance(topupSalesHisId) {
        if (window.confirm("Are you sure you want to rollback ?")) {

            RollbackTransactionService.rollbackIntruptedTrxn(topupSalesHisId, localStorage.userId)
                .then(
                (response) => {
                    NotificationManager.success("Successfully created ",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                },
                (err) => {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                })
                .catch(function (err) {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                       
                });
        }
    }



    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.receiverMobileNo = mobile
        formErrors.receiverMobileNoError =
            mobile.length == 0 || mobile.length < 6 || !mobileNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""

        this.setState({
            formErrors
        })
    }

    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'receiverMobileNo':
                formErrors.receiverMobileNoError =
                    !mobileNoRegEX.test(value) ? "" + " Mobile number sholud only contain numbers" : ""
                break;
            case 'amount':
                formErrors.amountError =
                    !amountRegEx.test(value) ? "" + " Amount sholud only contain numbers" : ""
                break;
            default:
                break;
        }
        this.setState({
            formErrors, [ name ]: value
        })
    }

    actionType(e) {
        const { formErrors } = this.state
        let selectedTrxnType = e.target.value
        this.state.transactionType = null
        if (selectedTrxnType == "----please select---")
            selectedTrxnType = ""
           
           
        formErrors.transactionTypeError = selectedTrxnType == "" ? 'You must make a selection' : "";
        this.setState({
            transactionType: selectedTrxnType,
            formErrors
        });
    }

    findRollbackRequestById(id){
        RollbackRequestService.getApprovedRollbackById(id,localStorage.userId)
        .then(response => {
            this.setState({
                requestData: response.data
            });
        })
        .catch(e => {
            
        });
    }


    render() {
        const { allRollBackTrxns,requestData, interuptedTopupTrxns, formErrors, pageNo, isLoading, totalPage } = this.state;
        
        return (

            <div>
                {/* <Header />
                <TopupMenu />
                <NotificationContainer /> */}

                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Manage Rollback Transaction</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-12 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  btrxns-radius-0" >
                                                <div className="card-header btrxns-radius-0 bg-light">
                                                    <h6 class="title " >Rollback Management Information </h6>
                                                </div>
                                                <div className="card-body">
                                                <div className='row'>
                                                <div className="form-group col-12 col-sm-8 col-md-2 col-lg-3">
                                                            <label htmlFor='TransactionType'>Transaction Type:</label>
                                                              <select value={this.state.transactionType}
                                                                  onChange={e => this.actionType(e)} className="form-control" name='type'>                      
                                                                  <option key={null} value={null}>----please select---</option>                                                   
                                                                  <option key={"0"} value={`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}`}>eTop-Up Airtime</option>
                                                                  <option key={"1"} value={`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`}>Wallet Airtime</option>
                                                              </select>
                                                              { formErrors.transactionTypeError.length > 0 && (
                                                                      <span style={ { color: "#F61C04" } }>{ formErrors.transactionTypeError }</span>
                                                                  ) }  
                                                    </div>   
                                                
                                                                                                     
                                                      <div className=" form-group col-12 col-sm-12 col-md-4 col-lg-4 ">
                                                            <label htmlFor='salesTeamAssignment'>Request Id:</label>
                                                            <AsyncSelect id='dealersList'
                                                                required
                                                                placeholder={ "Search  by name or mobile" }
                                                                value={ this.state.dealerSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchDealers }
                                                                isLoading={ this.state.dealerSelectOption == {} &&
                                                                    this.state.dealerSelectOption === undefined &&
                                                                    this.state.dealerSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                             { formErrors.dealerSelectOptionError.length>0 > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.dealerSelectOptionError }</span>
                                                            ) } 
                                                        </div>                                                          
                                                        
                                                    
                                                    <div className=" form-group col-12 col-sm-12 col-md-4 col-lg-4 ">
                                                            <label htmlFor='rollbackId'>Rollback ID:</label>
                                                            <input type="text" className="form-control " id='rollbackId' name='rollbackId'
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                </div>
                                                <div className="form-group col-12 col-sm-8 col-md-4 col-lg-4">
                                                            <label htmlFor='receiverId' >Sender: </label>
                                                            <div className="w-90 pull-left">
                                                            <input readOnly type="text" className="form-control " id='sender' name='sender'
                                                              value={requestData.senderFullName}/>

                                                            </div>
                                                        </div>
                                                </div>
                                                 <div className='row'>

                                                        <div className=" form-group col-12 col-sm-8 col-md-3 col-lg-3">
                                                            <label htmlFor='Country'>Mobile Number:</label>
                                                            <input readOnly type="text" className="form-control " id='receiverMobileNo' name='receiverMobileNo'
                                                            value={requestData.receiverMobileNo} />   
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-8 col-md-2 col-lg-2">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input readOnly type="text" className="form-control " id='amount' name='amount'
                                                            value={requestData.amount} />
                                                         
                                                        </div>

                                                        {/* <div className="form-group col-12 col-sm-8 col-md-2 col-lg-3">
                                                            <label htmlFor='date' >Date </label>
                                                            <div className="w-90 pull-left">
                                                                <input readOnly type="text" className="form-control" id='dateFrom' name="date"
                                                                    value={requestData.traxnDate}
                                                                    placeholder="Date" />
                                                            </div>
                                                        </div> */}

                                                        <div className=" form-group col-12 col-sm-8 col-md-3 col-lg-3">
                                                            <label htmlFor='Country'>Remark:</label>
                                                            <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='remark' name='remark' onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-8 col-md-1 col-lg-1 pt-3">
                                                            <button type="submit" className="btn btn-success btn-sm mt-4  "><i class="fas fa-exchange-alt" title="Rollback" /></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                        { interuptedTopupTrxns.length > 0 &&
                                            <div className="small-box">
                                                <div className="inner card-header bg-lightblue">
                                                    <i className="fas fa-arrow-circle-right" /> Recent Failed Transactions
                                                </div>
                                                <table className="table btrxns-0" >
                                                    {/* <thead className='thead-light'> */ }
                                                    <tr style={ { marginTop: 0 } }>
                                                        <th>Mobile No</th>
                                                        <th>Amount</th>
                                                        <th>Provider</th>
                                                        <th>Date</th>
                                                        {/* <th>Balance</th> */ }
                                                        <th>Actions</th>
                                                    </tr>
                                                    {/* </thead> */ }
                                                    <tbody>

                                                        { interuptedTopupTrxns.map(trxns =>

                                                            <tr>
                                                                <td className='p-0 '>{ trxns.receiverMobileNo }</td>
                                                                <td><NumberFormat value={ trxns.amount } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /></td>
                                                                <td className='p-0 '>{ trxns.providerId.providerName }</td>
                                                                <td className='p-0 '>{ trxns.createdAt }</td>

                                                                <td className='p-0'>
                                                                    <button type="submit" disabled={this.state.isLoading} className="btn btn-success btn-sm " onClick={ () => this.rollbackBalance(trxns.id) }><i class="fas fa-exchange-alt" title="Rollback" /></button>
                                                                </td>
                                                            </tr>

                                                        ) }
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        <div className="clearfix">&nbsp;</div>
                                        <div className="small-box">
                                            <div className="inner card-header bg-lightblue">
                                                <i className="fas fa-arrow-circle-right" /> Recent Rollback Transactions

                                            </div>
                                            <table className="table btrxns-0" >
                                                {/* <thead className='thead-light'> */ }
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Mobile No</th>
                                                    <th>Amount</th>
                                                    <th>Provider</th>
                                                    {/* <th>RollBack Type</th> */}
                                                    {/* <th>Remark</th> */}
                                                    <th>Date</th>
                                                </tr>
                                                {/* </thead> */ }
                                                <tbody>

                                                    { allRollBackTrxns.map(trxns =>

                                                        <tr className='table-tr'>
                                                            <td className='p-0 '>{ trxns.receiverMobileNo }</td>
                                                            <td className='p-0 '>{ trxns.amount }</td>
                                                            <td className='p-0 '>{ trxns.providerId.providerName }</td>
                                                            {/* { trxns.rollbackType === `${process.env.REACT_APP_ROLLBACK_TRXN_TYPE_INTERRUPTEDTOPUPS}` &&
                                                                <td className='p-0 '> { process.env.REACT_APP_TOPUP_ROLLBACK_TYPE_FAILED }</td>
                                                            }
                                                            { trxns.rollbackType === `${process.env.REACT_APP_ROLLBACK_TRXN_TYPE_SENTTOWRONGMOBILENO}` &&
                                                                <td className='p-0 '>{ process.env.REACT_APP_TOPUP_ROLLBACK_TYPE_SENDING_TO_WRONG_MOBILE_NO }</td>
                                                            } */}
                                                            {/* <td className='p-0 '>{ trxns.remark }</td> */}
                                                            <td className='p-0 '>{ format(trxns.createdAt, 'yyyy-MM-dd') }</td>

                                                        </tr>

                                                    ) }
                                                </tbody>
                                            </table>

                                            <div className="clearfix">&nbsp;</div>
                                            <div className=" float-sm-center">
                                                <span>
                                                    page{ '' }
                                                    <strong>
                                                        { pageNo } of { totalPage }
                                                    </strong>
                                                    { '' }
                                                </span>
                                                <span>
                                                    | Go To Page : { '' }
                                                    <input type='number' defaultValue={ pageNo }
                                                        onChange={ (e) => {
                                                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                            this.gotoEnteredPage(pageNumber)
                                                        } } />
                                                </span>
                                                <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                    <FontAwesomeIcon icon={ faBackwardFast } />
                                                </button>

                                                <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                    <FontAwesomeIcon icon={ faBackward } />
                                                </button>

                                                <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                                <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                    <FontAwesomeIcon icon={ faForward } />
                                                </button>

                                                <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                    <FontAwesomeIcon icon={ faForwardFast } />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(CreateRollbackTransaction);
