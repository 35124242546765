import API from '../../http-common';

class CommissionTypeService {
  // get all Cash Information 
  getCommissionTypes(userId) {
    return API.get("/api/v1/getCommissionTypes/" +  userId);
  }

  // create  Cash Information 
  createCommissionType(commissionType, userId) {
    return API.post("/api/v1/createCommissionType/" + userId, commissionType);
  }

  // get single Cash Information 
  getCommissionType(id, userId) {
    return API.get("/api/v1/getCommissionTypeById/" + id + "/" + userId);
  }  

  // remove  Cash Information 
  deleteCommissionType(id, userId) {
    return API.delete('/api/v1/deleteCommissionType' + '/' + id + "/" + userId);
  }

  // update  Cash Method Information 
  updateCommissionType(id, userId, commissionType) {
    return API.put("/api/v1/updateCommissionType" +"/" + id + "/" + userId, commissionType);
  }
  
}

export default new CommissionTypeService()