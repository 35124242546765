import API from '../../../src/http-common';

class MenuService {
    getTopupMenus(userId) {
        return API.get('/api/v1/showMenu/' + userId);
    }
    getTopupSubMenus(userId) {
        return API.get('/api/v1/showSubMenu/' + userId);
    }

    getRoutingInfo(userId){
        return API.get('/api/v1/getRoutingInfo/' + userId);
    }
}
export default new MenuService()