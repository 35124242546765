import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast, faCheckSquare, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Menu from '../../wallet/Menu';
import 'react-notifications/lib/notifications.css';
import AsyncSelect from 'react-select/async'
import CommissionTypeService from '../../../services/common/CommissionTypeService';
import { LOCALES } from '../../common/Constant'
import CustomerDealerService from '../../../services/etopup/CustomerDealerService';
import TopupMenu from '../TopupMenu';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import API from '../../../http-common';
import ReactLoading from "react-loading";
import '../../../assets/css/style.css'
const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u);
const customerRegEx = RegExp(/^[_@,.&$%#\s-]{0,50}$/u);
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u);

class CustomerDealerList extends Component {
  constructor (props) {
    super(props)
    this.state = {

      isMainDealerDataLoding:false,
      isSubDealerDataLoading:false,
      isRetailerDataLoding:false,

      mainPageNo: 1,
      mainPageSize: LOCALES.PAGE_SIZE,
      mainDealerType:LOCALES.MAIN_DEALER,
      mainTotalPage: 0,
      mainTotalelement: 0,
      

      subPageNo: 1,
      subPageSize: LOCALES.PAGE_SIZE,
      subTotalPage: 0,
      subTotalelement: 0,

      retailerPageNo: 1,
      retailerPageSize:LOCALES.PAGE_SIZE,
      retailerTotalPage: 0,
      retailerTotalelement: 0,

      customerDealerList: [],
      mainDealersList:[],
      subDealersList:[],
      retailersList:[],

      dealersHierarchyList:[],

      mainDealerIdParam:null,
      subDealerIdParam:null,
      retailerIdParam:null,
       
      
      searchParamCustomerId:null,
      searchParamCustomerType:null,

      mainDealerFilterId:null,
      subDealerFilterId:null,
      retailerFilterId:null,

      counter: 1,
      isCompany: false,

      mainCounter:1,
      subCounter:1,
      retailerCounter:1,
      dealerType: undefined,

      id: 0,
      isExpand: true, 
      isMainDealerListExpand:true,
      isSubDealerListExpand:true,
      isRetailerListExpand:true,
      isClicked:false,
      
      parentCustomerId:null,
      dealerCustomerId:null,

      selectOption: '',
      selectOptionCustomerType:null,
      customerList: "",
      commissionTypeIds: [],
      commissionTypeId: null,
      searchValue: null,
      fromDate: null,
      toDate: null,
      formErrors: {
        customerError: '',
        bankNameError: "",
        dateToError: "",
        dateFromError: ""
      },
      fullName: '',
      customerType: '',
      loggedInCustomerType:null,
    }
    this.editCustomerDealer = this.editCustomerDealer.bind(this);
    this.customerDealerDetail = this.customerDealerDetail.bind(this);
    this.addCustomerDealer = this.addCustomerDealer.bind(this);
    this.enableSubDealer = this.enableSubDealer.bind(this);

  }

  gotoMainEnteredPage = (pageNumber) => {
    this.state.mainPageNo = pageNumber
    this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoMainPageLast = () => {
    this.state.mainPageNo = this.state.mainTotalPage
    this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoMainPageFirst = () => {
    this.state.mainPageNo = 1
    this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  privMainPage = () => {
    let pages = 1
    if (this.state.mainPageNo > pages) {
      this.state.mainPageNo = this.state.mainPageNo - pages
      this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
      this.render()
      this.setState({
        isClicked:true
    })
    }
  }

  nextMainPage = () => {
    let totalPages = this.state.mainTotalPage;
    if (this.state.mainPageNo < totalPages) {
      this.state.mainPageNo = this.state.mainPageNo + 1
      this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
      this.render()
      this.setState({
        isClicked:true
    })
    }
  }


  gotoSubEnteredPage = (pageNumber,mainDealerId) => {
    this.state.subPageNo = pageNumber
    this.searchSubDealersBasedOnFilter(mainDealerId,this.state.subDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoSubPageLast = (mainDealerId) => {
    this.state.subPageNo = this.state.subTotalPage
    this.searchSubDealersBasedOnFilter(mainDealerId,this.state.subDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoSubPageFirst = (mainDealerId) => {
    this.state.subPageNo = 1
    this.searchSubDealersBasedOnFilter(mainDealerId,this.state.subDealerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  privSubPage = (mainDealerId) => {
    let pages = 1
    if (this.state.subPageNo > pages) {
      this.state.subPageNo = this.state.subPageNo - pages
      this.searchSubDealersBasedOnFilter(mainDealerId,this.state.subDealerFilterId)
      this.render()
      this.setState({
        isClicked:true
    })
    }
  }

  nextSubPage = (mainDealerId) => {
    let totalPages = this.state.subTotalPage;
    if (this.state.subPageNo < totalPages) {
      this.state.subPageNo = this.state.subPageNo + 1
      this.searchSubDealersBasedOnFilter(mainDealerId,this.state.subDealerFilterId)
      this.render()
    }
    this.setState({
      isClicked:true
  })
  }


  gotoRetailerEnteredPage = (pageNumber,subDealerId) => {
    this.state.retailerPageNo = pageNumber
    this.searchRetailersBasedOnFilter(subDealerId,this.state.retailerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoRetailerPageLast = (subDealerId) => {
    this.state.retailerPageNo = this.state.retailerTotalPage
    this.searchRetailersBasedOnFilter(subDealerId,this.state.retailerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  gotoRetailerPageFirst = (subDealerId) => {
    this.state.retailerPageNo = 1
    this.searchRetailersBasedOnFilter(subDealerId,this.state.retailerFilterId)
    this.render()
    this.setState({
      isClicked:true
  })
  }

  privRetailerPage = (subDealerId) => {
    let pages = 1
    if (this.state.retailerPageNo > pages) {
      this.state.retailerPageNo = this.state.retailerPageNo - pages
      this.searchRetailersBasedOnFilter(subDealerId,this.state.retailerFilterId)
      this.render()
      this.setState({
        isClicked:true
    })
    }
  }

  nextRetailerPage = (subDealerId) => {
    let totalPages = this.state.retailerTotalPage;
    if (this.state.retailerPageNo < totalPages) {
      this.state.retailerPageNo = this.state.retailerPageNo + 1
      this.searchRetailersBasedOnFilter(subDealerId,this.state.retailerFilterId)
      this.render()
    }
    this.setState({
      isClicked:true
      })
  }

  addCustomerDealer() {
    this.props.history.push(`/addDealers`);
  }

  customerDealerDetail(id) {
    this.props.history.push({pathname: "/viewDealers", state: {id: id}});
  }

  editCustomerDealer(id) {
    this.props.history.push({pathname: "/editDealers", state: {id: id}});
  }

  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getCustomerDealersBySearchValue/" + inputValue +"/"+ localStorage.userId)
          .then((response) =>
            response.data
          )
          .then((data) => {            
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.fullName, value: element.id +','+ element.type })
            });
            callback(temp)
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }

  onSearchChange = (searchValue) => {
    
    const { formErrors } = this.state
    this.state.searchValue = searchValue
    formErrors.customerError =
      customerRegEx.test(searchValue) ? " Please insert customer Name or account no or mobile number" : ""
    if (searchValue) { 
          {/*
          resultArray holds comma separated customer ID and customer type
          The first at index 0 is customerId
          The secodn at index 1 is customer type
        */}
      let resultArray = searchValue.value.split(',');

      this.setState({
        fullName: '',
        searchParamCustomerId:resultArray[0],
        searchParamCustomerType:resultArray[1],
        formErrors
      });
      
    }
  }

  CommissionType(e) {
    const { commissionTypeId } = this.state
    this.state.commissionTypeId = e.target.value
    this.setState({
      commissionTypeId: e.target.value
    });
  }

  componentDidMount() {
    this.setState({
      isCompany: localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
    });
    CommissionTypeService.getCommissionTypes(localStorage.userId)
      .then(response => {
        this.setState({
          commissionTypeIds: response.data
        });
        
      })
      .catch(e => {
        
      });

    LoginService.getUserInfo(localStorage.userId)
      .then(response => {
        this.setState({
          fullName: response.data.customerFullName,
          customerType: response.data.type,
          loggedInCustomerType:response.data.type,
        });
      
      })
      .catch(e => {
        
      });

      this.searchMainDealersBasedOnFilter(localStorage.type,localStorage.customerIdentity)
  }

  filterCustomerDealer(e) {
    // { this.state.searchParamCustomerType 
      // && this.state.searchParamCustomerType===this.state.mainDealerType &&
      // this.searchMainDealersBasedOnFilter(this.state.searchParamCustomerType,this.state.searchParamCustomerId)
    // }
    // { this.state.searchParamCustomerType && this.state.searchParamCustomerType!==this.state.mainDealerType &&
      this.getDealersHierarchyByDealerId(this.state.searchParamCustomerId)
    // }
      
  }
 getDealersHierarchyByDealerId(searchParamCustomerId){
  
  CustomerDealerService.getDealersHierarchyByDealerId(searchParamCustomerId,localStorage.userId)
          .then(response => {
              // if(this.state.dealersHierarchyList.dealerType!=`${process.env.CUSTOMERDEALER_DEALERTYPE_RETAILER_VALUE}` ){
                // this.setState({   
                //  // dealersHierarchyList: response.data,
                //   mainDealerFilterId:response.data.mainDealerId,
                //   subDealerFilterId:response.data.subDealerId
                //   });
              // }
              // if(this.state.dealersHierarchyList.dealerType==`${process.env.CUSTOMERDEALER_DEALERTYPE_RETAILER_VALUE}` ){
           
              this.setState({   
                 // dealersHierarchyList: response.data,
                  dealerType: response.data.dealerType,
                  mainDealerFilterId:response.data.mainDealerId,
                  subDealerFilterId:response.data.subDealerId,
                  retailerFilterId:response.data.retailerId
                  });
              // }

             this.state.customerType=this.state.searchParamCustomerType
             if(this.state.dealerType === 0 ){
             this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId);
             }else if(this.state.dealerType === 1 ){
             this.searchSubDealersBasedOnFilter(this.state.mainDealerFilterId, this.state.subDealerFilterId);
             }else if(this.state.dealerType === 2 ){
             this.searchRetailersBasedOnFilter(this.state.mainDealerFilterId,this.state.subDealerFilterId, this.state.retailerFilterId);
             }
          })
          .catch(e => {
          });
        }

  clearValue() {
    
    this.state.searchValue = null
    this.state.customerType = null
    this.state.mainDealerFilterId=null
    this.state.subDealerFilterId=null
    this.state.retailerFilterId=null
    this.state.isMainDealerListExpand=true;
    this.state.isSubDealerListExpand=true;
    this.state.isRetailerListExpand=true;    
    this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerFilterId)
    
  }

  searchMainDealersBasedOnFilter(customerType,mainDealerFilterId) {

          this.state.parentCustomerId=this.state.parentCustomerId
          this.state.mainDealerType=this.state.mainDealerType
          if(customerType===this.state.mainDealerType && this.state.searchValue==null){
                this.state.parentCustomerId = localStorage.customerIdentity
                this.state.mainDealerType=customerType
              }

          if(this.state.searchValue!=null ){
                this.state.parentCustomerId=mainDealerFilterId
                this.state.mainDealerType=this.state.mainDealerType
          }

          if(customerType==null && mainDealerFilterId==null){
                this.state.parentCustomerId=mainDealerFilterId
                this.state.mainDealerType=this.state.mainDealerType
          }

          let FilterValue = {
            parentCustomerId:this.state.parentCustomerId,
            customerType:this.state.mainDealerType
          }
  
        

    CustomerDealerService.findMainDealersList(
      FilterValue, this.state.mainPageNo, this.state.mainPageSize,localStorage.userId)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          mainDealersList: data.customerDealersList,
          mainTotalPage: data.totalPages,
          mainTotalelement: data.totalelement,
          mainPageNo: data.pageNumber + 1,
          isMainDealerDataLoding:true
        });
      });
  }

  searchSubDealersInfo(mainDealerId,subDealerFilterId){
       this.searchSubDealersBasedOnFilter(mainDealerId,subDealerFilterId)
          this.setState({
              isSubDealerListExpand: !this.state.isSubDealerListExpand,
              })
  } 


  searchSubDealersBasedOnFilter(mainDealerId,subDealerFilterId) {
    
      this.state.dealerCustomerId=this.state.subDealerFilterId
     if(this.state.searchValue){
         this.state.dealerCustomerId=subDealerFilterId;

         let MainFilterValue = {
          parentCustomerId:mainDealerId,
          customerType:this.state.mainDealerType
        }

      

  CustomerDealerService.findMainDealersList(
    MainFilterValue, this.state.mainPageNo, this.state.mainPageSize,localStorage.userId)
    .then(response => response.data)
    .then((data) => {
      this.setState({
        isSubDealerListExpand: false,
        mainDealersList: data.customerDealersList,
        mainTotalPage: data.totalPages,
        mainTotalelement: data.totalelement,
        mainPageNo: data.pageNumber + 1,
        isMainDealerDataLoding:true
      });
    });
        }

      
       
      

    let FilterValue = {
          parentCustomerId:mainDealerId,
          dealerCustomerId:this.state.dealerCustomerId,
          customerType:this.state.mainDealerType,
       }
    CustomerDealerService.findSubDealersList(
      FilterValue, this.state.subPageNo, this.state.subPageSize,localStorage.userId)
       .then(response => response.data)
       .then((data) => {
        this.setState({
          subDealersList: data.customerDealersList,
          subTotalPage: data.totalPages,
          subTotalelement: data.totalelement,
          subPageNo: data.pageNumber + 1,
          isSubDealerDataLoading:true,
          mainDealerIdParam:mainDealerId,
          isClicked:true
        });
      });
  }


  searchRetailersInfo(mainDealerId, subDealerId,retailerFilterId){ 
    this.searchRetailersBasedOnFilter(mainDealerId, subDealerId,retailerFilterId) 
    this.setState({
         isSubDealerListExpand: this.state.isSubDealerListExpand,
         isRetailerListExpand: !this.state.isRetailerListExpand
         })
  }

  searchRetailersBasedOnFilter(mainDealerId, subDealerId,retailerFilterId) {
    if(this.state.searchValue){
       let MainFilterValue = {
        parentCustomerId:mainDealerId,
        customerType:this.state.mainDealerType
      }
 
    
 
 CustomerDealerService.findMainDealersList(
  MainFilterValue, this.state.mainPageNo, this.state.mainPageSize,localStorage.userId)
  .then(response => response.data)
  .then((data) => {
    this.setState({
      isSubDealerListExpand: false,
      mainDealersList: data.customerDealersList,
      mainTotalPage: data.totalPages,
      mainTotalelement: data.totalelement,
      mainPageNo: data.pageNumber + 1,
      isMainDealerDataLoding:true
    });
  });
 
  let SudDealerFilterValue = {
   parentCustomerId:mainDealerId,
   dealerCustomerId:subDealerId,
   customerType:this.state.mainDealerType,
 }
 CustomerDealerService.findSubDealersList(
 SudDealerFilterValue, this.state.subPageNo, this.state.subPageSize,localStorage.userId)
 .then(response => response.data)
 .then((data) => {
 this.setState({
   isRetailerListExpand: false,
   subDealersList: data.customerDealersList,
   subTotalPage: data.totalPages,
   subTotalelement: data.totalelement,
   subPageNo: data.pageNumber + 1,
   isSubDealerDataLoading:true,
   mainDealerIdParam:mainDealerId,
   isClicked:true
 });
 });
      }
    

    let FilterValue = {
      parentCustomerId:subDealerId,
      dealerCustomerId:retailerFilterId,
      customerType:null,
    }

    CustomerDealerService.findRetailersList(
      FilterValue, this.state.retailerPageNo, this.state.retailerPageSize,localStorage.userId)
      .then(response => response.data)
      .then((data) => {
        this.setState({
          retailersList: data.customerDealersList,
          retailerTotalPage: data.totalPages,
          retailerTotalelement: data.totalElements,
          retailerPageNo: data.pageNumber + 1,
          isRetailerDataLoding:true,
          subDealerIdParam:subDealerId,
          isClicked:true
        });
      });
  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.state.clear = 0
        this.setState({
          customerType:null,
          mainDealerId:null,
        })
    this.searchMainDealersBasedOnFilter(this.state.customerType,this.state.mainDealerId)
    }
  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }

  async deleteCustomerDealer(id) {
    if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
      try {
        const response = await CustomerDealerService.deleteCustomerDealers(id,localStorage.userId);
        NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        this.searchMainDealersBasedOnFilter(this.state.customerType)
      } catch (error) {
        NotificationManager.error(`${process.env.REACT_APP_DELETE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      }
    }
  }

  async enableSubDealer(id, delaerId, status) {
    
    if (window.confirm(`${process.env.REACT_APP_DEALERS_CONFIRM_MSG}` + "?")) {
      try {
        let statusDB = status === 1 ?  0 : 1;
        const response = await CustomerDealerService.changeSubDealerStatus(id, delaerId, statusDB, localStorage.userId);
        NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        this.searchMainDealersBasedOnFilter(this.state.customerType)
      } catch (error) {
        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      }
    }
  }

  actionPageSize(e) {
    this.state.mainPageSize = e.target.value;
    this.state.subPageSize = e.target.value;
    this.state.retailerPageSize = e.target.value;
    this.setState({
      isMainDealerDataLoding: false,
      mainPageSize: e.target.value,
      subPageSize:  e.target.value,
      retailerPageSize: e.target.value
    });

    this.searchMainDealersBasedOnFilter(localStorage.type,localStorage.customerIdentity)
  }


  render() {
    const { mainDealersList,subDealersList,retailersList, commissionTypeIds, customerType, mainPageNo, mainPageSize, mainTotalPage, formErrors } = this.state;
    const {subPageNo,subTotalPage,subPageSize,retailerPageNo,retailerTotalPage,retailerPageSize} = this.state
    const{isMainDealerDataLoding,isSubDealerDataLoading,isRetailerDataLoding}=this.state
    
    mainPageNo == 1 ? this.state.mainCounter = 1 : this.state.mainCounter = (mainPageSize * (mainPageNo - 1)) + 1
    subPageNo == 1 ? this.state.subCounter = 1 : this.state.subCounter = (subPageSize * (subPageNo - 1)) + 1
    retailerPageNo == 1 ? this.state.retailerCounter = 1 : this.state.retailerCounter = (retailerPageSize * (retailerPageNo - 1)) + 1

   
    let commissionTypeList = commissionTypeIds.length > 0
      && commissionTypeIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.name }</option>
        )
      }, this);

    // if (!isMainDealerDataLoding) {
    //   return (
    //     <div>
    //       <Header />
    //       <TopupMenu />
    //       <div className='content-wrapper'>
    //         <div className='row'>
    //           <div className='col-sm-12 col-md-5 col-lg-5'></div>
    //           <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
    //             <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
    //           </div>
    //           <div className='col-sm-12 col-md-6 col-lg-6'></div>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }
    
      return (

        <div>
          {/* <Header />
          <TopupMenu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active" >Customer Dealer Information List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Display Customers Dealer Information</div>


                    <form onSubmit={ this.clear } hidden={ this.state.isExpand } noValidate>
                     
                      <div className="row" hidden={ this.state.isExpand }>
                        { localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
                          <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6  ">
                              <div>
                                <label htmlFor='CustomerList'>Customer:</label>
                                <div style={ { fontSize: 'smaller' } }>
                                  <AsyncSelect id='CustomerList'
                                    placeholder={ "Search by name, account no or mobile number" }                                    
                                    isClearable={ true }
                                    loadOptions={ this.fatchData }
                                    isLoading={ this.state.searchValue == {} &&
                                      this.state.searchValue === undefined &&
                                      this.state.searchValue === null ? true : false
                                    }

                                    onChange={ (e) => {
                                      this.onSearchChange(e)
                                    } }
                                    defaultOptions={ false }
                                  />
                                  { formErrors.customerError.length > 0 && (
                                    <span style={ { color: "#F61C04" } }>{ formErrors.customerError }</span>
                                  ) }
                                </div>
                              </div>
                          </div>
                          :
                          <div>
                          </div>
                        }
                        <div>
                        <div className=" form-group ml-3 mt-4 pt-1 ">
                        <button title={ "Search" }
                          className="btn btn-sm btn-success primary m-1 float-right"
                          onClick={ () => this.filterCustomerDealer() }>Search</button>
                        <button type='submit' onClick={()=> this.clearValue() } title={ "Clear" }
                          className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                      </div>
                        </div>
                      </div>
                      <br />
                    </form>
                    <button type="button" title={ "Add" }
                      className="btn btn-sm btn-success primary m-1  float-left"
                      onClick={ (e) => this.addCustomerDealer() }>
                      <i className="fas fa-plus" /> Add
                    </button>
                    {  this.state.loggedInCustomerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` &&
                  <>
                  <button type='button' title={ "Show/Hide Filter" }
                      className="btn btn-sm btn-success primary m-1 float-left"
                      onClick={ () => this.filterToggle() }>
                      <FontAwesomeIcon icon={ faFilter } />
                      Filter
                    </button>
                          <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                          <label htmlFor='Size'>Row #:</label>
                          <select value={this.state.mainPageSize}
                            onChange={e => this.actionPageSize(e)} name='Size'>
                            <option key={"20"} value={20}>20</option>
                            <option key={"50"} value={50}>50</option>
                            <option key={"500"} value={500}>500</option>
                            <option key={"1000"} value={1000}>1000</option>
                            <option key={"10000"} value={10000}>10000</option>
                          </select>
                        </div>
                        </>
                  }
                     <div style={{ clear: 'both' }}></div>
                  { !isMainDealerDataLoding &&
                         <div>
                               <div className='sub-dealers-loading-icon'>
                                 <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                               </div>
                         </div>
                
                }
                  { isMainDealerDataLoding &&
                  <div className='tableContainer'>
                    <table className=" table border-bottom " style={ { fontSize: 'smaller' } } >
                      <thead className='thead-light'>
                        <tr style={ { marginTop: 0 } }>
                          <th></th>
                          <th>No.</th>
                          <th>Full Name</th>
                          <th>Account #</th>
                          <th>Mobile #</th>
                          <th>Wallet $</th>
                          <th>Topup $</th>
                          <th>Type </th>
                          <th>% Type</th>
                          <th>% Rate</th>
                          <th>Count</th>
                          {/* <th>Status</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody className='content-wrapper '>

                        { mainDealersList && mainDealersList.map(mainDealers =>
                         <>
                          <tr key={ mainDealers.childId }  className='border-top border-bottom bg-white'>
                            <td  style={{width:'4%'}}>{                 
                              <button className='show-hide-button btn btn btn-sm' onClick={ () => this.searchSubDealersInfo(mainDealers.childId,this.state.subDealerFilterId) }  title="View sub dealers">
                              {!this.state.isSubDealerListExpand && this.state.mainDealerIdParam===mainDealers.childId ? <i className="nav-icon fa fa-minus show-hide-icon"/>  : <i className="nav-icon fa fa-plus show-hide-icon" aria-hidden="true"/> }
                              </button>
                                  }
                            </td>
                            <td  style={{width:'3%',paddingLeft:'15px'}}>{ this.state.mainCounter++ }</td>
                            <td  style={{width:'15%'}}>{ mainDealers.fullName }</td>
                            <td  style={{width:'10%'}}>{ mainDealers.accountNo }</td>
                            <td  style={{width:'10%'}}>{ mainDealers.mobileNumber }</td>
                            <td  style={{width:'7%'}}>{ mainDealers.walletBalance }</td>
                            <td  style={{width:'7%'}}>{ mainDealers.topupBalance }</td>
                            <td  style={{width:'12%'}}>{ mainDealers.customerType }</td>
                            <td  style={{width:'5%'}}>{ mainDealers.commissionType }</td>
                            <td  style={{width:'3%'}}>{ mainDealers.commissionRate }</td>
                            <td  style={{width:'2%'}}>{ mainDealers.count }</td>
                            <td >
                            <button   style={{marginRight:'5px'}} title={ mainDealers.status === 0 ? "Disable Sub Dealer" : "Enable Sub Dealer"}
                               className={mainDealers.status == 0 ?" btn btn-success btn-sm fa fa-thumbs-up action-buttons"  : " btn btn-danger btn-sm  fa fa-thumbs-up action-buttons"  }
                                onClick={ () => this.enableSubDealer(mainDealers.id, mainDealers.childId, mainDealers.status) }> 
                               </button>
                              <button  style={{marginLeft:'2px'}} title={ "Edit" } 
                                onClick={ () => this.editCustomerDealer(mainDealers.id) }
                                className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm action-button-icon" /> </button>
                              <button style={{marginLeft:'2px'}} title={ "Delete" } disabled={ mainDealers.defultStatus === 1 ? true : false }
                                onClick={ () => this.deleteCustomerDealer(mainDealers.id) }
                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm action-button-icon" /> </button>

                            </td> 
                          </tr>
                          { !isSubDealerDataLoading && !this.state.isSubDealerListExpand &&
                                  <tr>
                                         <td colSpan="8" className='bg-white'>
                                           <div className='sub-dealers-loading-icon'><ReactLoading type="bars"  color="#0000FF" height={ 90 } width={ 90 } /> </div>
                                         </td>
                                   </tr>
                          }
                          { isSubDealerDataLoading &&
                           <tr  hidden={ this.state.isSubDealerListExpand }  className='bg-white'>
                                 { this.state.mainDealerIdParam===mainDealers.childId &&
                                  <td colSpan='11' style={{padding:'0px',borderSpacing:'0px', }}>
                                   <table className='table  table-sub-dealers-list'> 
                                       
                                      { subDealersList && subDealersList.map(subDealers =>
                                       <tbody  key={ subDealers.childId }> 
                                       { mainDealers.childId===subDealers.parentId &&
                                       <>
                                        <tr style={{paddingBottom:'0px'}} className='border-top border-bottom'>
                                        <td  style={{width:'10%'}}></td>
                                          <td  style={{width:'3%'}}>
                                            {                
                                            <button className="btn btn-white btn-sm show-hide-button" style={{fontSize:'20'}} onClick={ () => this.searchRetailersInfo(this.state.mainDealerFilterId, subDealers.childId,this.state.retailerFilterId) }   title="View retailers">
                                              {!this.state.isRetailerListExpand && this.state.subDealerIdParam===subDealers.childId ? <i className="nav-icon fa fa-minus show-hide-icon" />  : <i className="nav-icon fa fa-plus show-hide-icon"/> }
                                            </button>
                                            }
                                          </td>
                                          <td  style={{width:'4%'}}>{this.state.subCounter++}</td> 
                                          <td  style={{width:'15%'}}>{ subDealers.fullName }</td>
                                          <td  style={{width:'10%'}}>{ subDealers.accountNo }</td>
                                          <td  style={{width:'12%'}}>{ subDealers.mobileNumber }</td>
                                          <td  style={{width:'7%'}}>{ subDealers.walletBalance }</td>
                                          <td  style={{width:'7%'}}>{ subDealers.topupBalance }</td>
                                          <td  style={{width:'10%'}}>{ subDealers.customerType }</td>
                                          <td  style={{width:'7%'}}>{ subDealers.commissionType }</td>
                                          <td  style={{width:'3%'}}>{ subDealers.commissionRate }</td>
                                          <td  style={{width:'2%'}}>{ subDealers.count }</td>
                                          <td > 
                                                <button style={{marginLeft: "8px" }}  title={ subDealers.status === 0 ? "Disable Sub Dealer" : "Enable Sub Dealer"}
                                                className={subDealers.status == 0 ?" btn btn-success btn-sm fa fa-thumbs-up action-buttons"  : " btn btn-danger btn-sm  fa fa-thumbs-up action-buttons"  }
                                                  onClick={ () => this.enableSubDealer(subDealers.id, subDealers.childId, subDealers.status) }> 
                                                </button>
                                                <button style={{marginLeft: "2px" }}  title={ "Edit" } 
                                                  onClick={ () => this.editCustomerDealer(subDealers.id) }
                                                  className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm " /> </button>
                                                <button style={{marginLeft: "2px" }}  title={ "Delete" } disabled={ subDealers.defultStatus === 1 ? true : false }
                                                  onClick={ () => this.deleteCustomerDealer(subDealers.id) }
                                                  className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm " /> 
                                                </button>
                                          </td>
                                      </tr>
                                { !isRetailerDataLoding && !this.state.isRetailerListExpand &&
                                         <div>
                                         <div className='content-wrapper'>
                                           <div className='row'>
                                             <div className='col-sm-12 col-md-5 col-lg-5'></div>
                                             <div className='col-sm-12 col-md-6 col-lg-6'>
                                               <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                                             </div>
                                             <div className='col-sm-12 col-md-6 col-lg-6'></div>
                                           </div>
                                         </div>
                                       </div>
                                }
                              { isRetailerDataLoding &&
                                <tr  hidden={  this.state.isRetailerListExpand}  className='bg-white'>
                                 { this.state.subDealerIdParam===subDealers.childId &&
                                <td colSpan='12' style={{padding:'0px',border:'none', borderSpacing:'0px', }}>
                                  <table className='table table-retailers-list'> 
                                    <tbody> 
                                       <>
                                       { retailersList && retailersList.map(retailers =>
                                       <tr key={ retailers.childId } className='border-top border-bottom'> 
                                       { this.state.subDealerIdParam===retailers.parentId &&
                                       <>
                                          <td style={{width:'4%'}}></td>
                                          <td style={{width:'4%'}}></td>
                                          <td style={{width:'4%'}}></td>
                                          <td  style={{width:'3%'}}>{this.state.retailerCounter++}</td> 
                                          <td  style={{width:'17%'}}>{ retailers.fullName }</td>
                                          <td  style={{width:'9%'}}>{ retailers.accountNo }</td>
                                          <td  style={{width:'13%'}}>{ retailers.mobileNumber }</td>
                                          <td  style={{width:'7%'}}>{ retailers.walletBalance }</td>
                                          <td  style={{width:'7%'}}>{ retailers.topupBalance }</td>
                                          <td  style={{width:'8%'}}>{ retailers.customerType }</td>
                                          <td  style={{width:'8%'}}>{ retailers.commissionType }</td>
                                          <td  style={{width:'5%'}}>{  retailers.commissionRate }</td> 
                                          <td > 
                                                <button style={{marginLeft: "8px" }} title={ retailers.status === 0 ? "Disable Sub Dealer" : "Enable Sub Dealer"}
                                                className={retailers.status == 0 ?" btn btn-success btn-sm fa fa-thumbs-up action-buttons"  : " btn btn-danger btn-sm  fa fa-thumbs-up action-buttons"  }
                                                  onClick={ () => this.enableSubDealer(retailers.id, retailers.childId, retailers.status) }> 
                                                </button>
                                                <button style={{marginLeft: "2px" }} title={ "Edit" } 
                                                  onClick={ () => this.editCustomerDealer(retailers.id) }
                                                  className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                <button style={{marginLeft: "2px" }} title={ "Delete" } disabled={ retailers.defultStatus === 1 ? true : false }
                                                  onClick={ () => this.deleteCustomerDealer(retailers.id) }
                                                  className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> 
                                                </button>
                                          </td>
                                          </>
                                       }
                                        </tr>
                                       )}
                                        <tr  className="float-sm-center bg-white " hidden={ this.state.isRetailerListExpand} >
                                                             { this.state.subDealerIdParam===subDealers.childId &&
                                                             
                                                              <td colSpan='9'  className='pt-2 pb-0' style={{border:'1', borderSpacing:'0px'}} >                                                                
                                                                <div>
                                                                    <div >
                                                                        <span>
                                                                            page{ '' }
                                                                              <strong>
                                                                                  { retailerPageNo } of { retailerTotalPage }
                                                                              </strong>
                                                                              { '' }
                                                                        </span>
                                                                        <span>
                                                                            | Go To Page : { '' }
                                                                            <input type='number' defaultValue={ retailerPageNo }
                                                                                onChange={ (e) => {
                                                                                    const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                                                    this.gotoRetailerEnteredPage(pageNumber,subDealers.childId)
                                                                                } } />
                                                                        </span>
                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoRetailerPageFirst(subDealers.childId) } disabled={ retailerPageNo === 1 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackwardFast } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privRetailerPage(subDealers.childId) } disabled={ retailerPageNo === 1 ? true : false }>
                                                                            <FontAwesomeIcon icon={ faBackward } />
                                                                        </button>

                                                                        <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ retailerPageNo } value={ retailerPageNo } readOnly />

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextRetailerPage(subDealers.childId) } disabled={ retailerPageNo === retailerTotalPage ? true : false }>
                                                                            <FontAwesomeIcon icon={ faForward } />
                                                                        </button>

                                                                        <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoRetailerPageLast(subDealers.childId) } disabled={ retailerPageNo === retailerTotalPage ? true : false } >
                                                                            <FontAwesomeIcon icon={ faForwardFast } />
                                                                        </button>
                                                                    </div>
                                                                    </div> 
                                                                </td>
                                                              }
                                                  </tr>
                                 
                                      </>
                                       </tbody> 
                                      
                                       </table> 
                                  </td>
                                   }
                                </tr>
                                }
                                </>
                               }
                              </tbody> 
                              )}
                              </table> 
                           </td>
                            }
                           </tr>
                           }
                           <tr  className="float-sm-center bg-white" hidden={ this.state.isSubDealerListExpand || !this.state.isRetailerListExpand } >
                                  { this.state.mainDealerIdParam===mainDealers.childId &&
                                  <td colSpan='8'>                                                                
                                    <div>
                                        <div >
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { subPageNo } of { subTotalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { ' ' }
                                                <input type='number' defaultValue={ subPageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoSubEnteredPage(pageNumber,mainDealers.parentId)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoSubPageFirst(mainDealers.childId) } disabled={ subPageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privSubPage(mainDealers.childId) } disabled={ subPageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ subPageNo } value={ subPageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextSubPage(mainDealers.childId) } disabled={ subPageNo === subTotalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoSubPageLast(mainDealers.childId) } disabled={ subPageNo === subTotalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                        </div> 
                                    </td>
                                  }
                          </tr>
                          </>

                        ) }
                      </tbody>
                    </table>
                    </div>
                  }
                    <div className=" float-sm-center pt-2" style={{fontSize:'smaller'}} hidden={ (!this.state.isSubDealerListExpand || !this.state.isRetailerListExpand) && this.state.isMainDealerListExpand}>
                      <span>
                        page{ '' }
                        <strong>
                          { mainPageNo } of { mainTotalPage }
                        </strong>
                        { '' }
                      </span>
                      <span>
                        | Go To page : { '' }
                        <input type='number' defaultValue={ mainPageNo }
                          onChange={ (e)=> {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoMainEnteredPage(pageNumber)
                          } } />
                      </span>
                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoMainPageFirst() } disabled={ mainPageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackwardFast} />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privMainPage() } disabled={ mainPageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackward} />
                      </button>

                      <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ mainPageNo } value={ mainPageNo } readOnly />

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextMainPage() } disabled={ mainPageNo === mainTotalPage ? true : false }>
                        <FontAwesomeIcon icon={ faForward} />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoMainPageLast() } disabled={ mainPageNo === mainTotalPage ? true : false } >
                        <FontAwesomeIcon icon={ faForwardFast} />
                      </button>
                    </div>
                  </div>
                </div>
                <NotificationContainer />
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>
            {/* /.content */ }
          </div>
        </div>

      )
    
  }
}


export default CustomerDealerList;