import API from '../../http-common';

class CustomerVerificationService {
    activateCustomerAccount(otp,mobileNo,userId) {
        return API.get('/api/v1/activateCustomer/'+otp+"/"+mobileNo+"/"+userId);
    }

    disableCustomerAccount(mobileNo, userId) {
        return API.get('/api/v1/disableAccount/'+mobileNo+"/"+userId);
    }

    linkTopup(mobileNo, userId) {
        return API.get('/api/v1/linkTopup/'+mobileNo+"/"+userId);
    }

    enableCustomerAccount(mobileNo, userId) {
        return API.get('/api/v1/enableAccount/'+mobileNo + "/" + userId);
    }

    activateAccount(id, userId, customerIdentity) {
        return API.put('/api/v1/ActivateAccount/' + id  + "/" + userId,customerIdentity) ;
    }

    // getMerchantByTel(mobileNo)
    // {
    //     return API.get('/api/v1/getMarchantByTel/'+mobileNo);
    // }

    // createMerchant(merchant) {
    //     return API.post("/api/v1/createMerchant", merchant);
    // }
    // deleteMerchant(mobileNo) {
    //     return API.delete("/api/v1/deleteMerchant/" + mobileNo);
    // }
    // updateMerchant(merchant) {
    //     return API.put("api/v1/updateMerchant", merchant);
    // }
}
export default new CustomerVerificationService()