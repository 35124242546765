import React, { Component } from 'react'
import Menu from '../Menu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import RollbackRequestService from '../../../services/wallet/RollbackRequestService';
import LoginService from '../../../services/common/LoginService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import PhoneInput from 'react-phone-input-2';
import ReactLoading from "react-loading"; 
import moment from 'moment/moment';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const mobileNoRegEX = RegExp(/^[0-9\b]+$/u)
const amountRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/); 

class EditRollbackRequest extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            showSenderCustomer:false,
            showReceiverCustomer:false,
            showKisok:false,
            showReceiverMobileNo:false,
            isLoading: false,
            trxnTypeDefaultValue:0,
            trxnType:0,
            traxnDate:null,
        

            senderCustomerSelectOption:'',
            receiverCustomerSelectOption:'',
            KioskSelectOption: '',
            trxnRefSelectOption: '',
            receiverMobileNo:null,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            requestData:{
                senderId:{
                    id:0
                },
                receiverId:{
                    id:0
                },
                kioskId:{
                    id:0
                },
                trxnRefId:{
                    id:0
                },
                receiverMobileNo:'',
                amount:'',
                trxnType:'',
                trxnDate:'',
                remark:''
            },
            formErrors: {
                amountError: "",
                senderCustomerSelectOptionError: "",
                receiverCustomerSelectOptionError: "",
                kioskSelectOptionError:"",
                receiverMobileNoError:""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);
        
        this.onChangeReceiverMobileNo=this.onChangeReceiverMobileNo.bind(this);
        this.onChangeAmount=this.onChangeAmount.bind(this);
        this.onChangeRemark=this.onChangeRemark.bind(this);
        this.onChangeTrxnType=this.onChangeTrxnType.bind(this);
        this.onChangeTxnDate=this.onChangeTxnDate.bind(this);

    }

    onChangeReceiverMobileNo(e) {
        const receiverMobileNo = e.target.value;
        this.setState(function (prevState) {
            return {
                requestData: {
                    ...prevState.requestData,
                    receiverMobileNo: receiverMobileNo
                }
            };
        });
    }

    onChangeAmount(e) {
        const amount = e.target.value;
        this.setState(function (prevState) {
            return {
                requestData: {
                    ...prevState.requestData,
                    amount: amount
                }
            };
        });
    }
    onChangeRemark(e) {
        const remark = e.target.value;
        this.setState(function (prevState) {
            return {
                requestData: {
                    ...prevState.requestData,
                    remark: remark
                }
            };
        });
    }

    onChangeTrxnType(e) {
        const trxnType = e.target.value;
        this.setState(function (prevState) {
            return {
                requestData: {
                    ...prevState.requestData,
                    trxnType: trxnType
                }
            };
        });
    }

    onChangeTxnDate(e) {
        const trxnDate = e.target.value;
        this.setState(function (prevState) {
            return {
                requestData: {
                    ...prevState.requestData,
                    trxnDate: trxnDate
                }
            };
        });
    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;
        }

        this.setState({
            formErrors, [ name ]: value
        })
    }

    fetchKisokData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findKioskBySearchParam/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.kioskId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            kioskList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onSearchChange = (KioskSelectOption) => {
        const { formErrors } = this.state
        if (KioskSelectOption) {
            this.state.KioskSelectOption = KioskSelectOption
            this.setState({
                KioskSelectOption: KioskSelectOption,
                formErrors
            });
        }
    }
    fetchSenderCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label:element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            senderCustomersList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }
    
    onSenderCustomerSearchChange = (senderCustomerSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = senderCustomerSelectOption == "" || senderCustomerSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            senderCustomerSelectOption: senderCustomerSelectOption,
            formErrors
        });
    }
    

    fetchReceiverCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label:element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            receiverCustomerList: data,

                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }
    }
    
    onReceiverCustomerSearchChange = (receiverCustomerSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = receiverCustomerSelectOption == "" || receiverCustomerSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            receiverCustomerSelectOption: receiverCustomerSelectOption,
            formErrors
        });
    }

    fetchTrxnRefData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findTransactionRefBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label:element.trxnRefId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            trxnRefList: data,
                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }
    }
    
    onTrxnRefSearchChange = (trxnRefSelectOption) => {
        const { formErrors } = this.state       

        formErrors.trxnRefIdError = trxnRefSelectOption == "" || trxnRefSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            trxnRefSelectOption: trxnRefSelectOption,
            formErrors
            //input: trxnRefSelectOption.target.value.replace(/\D/g,'')
        });
    }
    
    componentDidMount() {
        
     this.findRollbackRequestById(this.props.location.state.id)

        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId
                });
            })
            .catch(e => {
                
            });
    }
    findRollbackRequestById(id){
    RollbackRequestService.findRollbackRequestById(id,localStorage.userId)
    .then(response => {
        this.setState({
            requestData: response.data
        });
        // console.log(this.state.rollbackRequest)
    })
    .catch(e => {
        
    });
}

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state

        this.state.receiverMobileNo = mobile
        formErrors.mobileNoError =
            mobile.length == 0 || mobile.length < 6 || !mobileNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.mobileNoError.length === 0) {
            let mobilePhone = mobile
        }
        this.setState({
            formErrors
        })
    }

    Cancel = (e) => {
        this.props.history.push('/rollbackRequestList');
    }

    handleSubmit(e) {   
        e.preventDefault();
        const { providerId, requestData, selectOption, formErrors } = this.state
        formErrors.amountError = requestData.amount == '' || requestData.amount == null || requestData.amount == undefined ? 'Amount is Required' : "";
    
        
     //   if (formValid(this.state.formErrors)) {

            var data = new FormData();
            data.append("id",this.props.location.state.id);
            data.append("senderId", this.state.senderCustomerSelectOption && this.state.senderCustomerSelectOption.value);
            data.append("receiverId", this.state.receiverCustomerSelectOption && this.state.receiverCustomerSelectOption.value);
            data.append("kioskId", this.state.KioskSelectOption && this.state.KioskSelectOption.value );
            data.append("trxnRefId",this.state.trxnRefSelectOption && this.state.trxnRefSelectOption.value);
            data.append("amount", this.state.requestData.amount);
            data.append("receiverMobileNo", this.state.requestData.receiverMobileNo);
            data.append("remark", this.state.requestData.remark);
            data.append("trxnType", this.state.requestData.trxnType);
            // data.append("traxnDate",this.state.requestData.trxnDate);
            
          

            RollbackRequestService.updateRollbackRequest(localStorage.userId,data)
                .then(response => {
                    NotificationManager.success("Request successfully added !! ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.Cancel()
                })
                .catch(function (err) {

                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);

                });
        // } else {
        //     NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
        //     this.setState({
        //         formErrors
        //     })
        // }

    };


    render() {
        const { formErrors,requestData,senderCustomerSelectOption,receiverCustomerSelectOption,KioskSelectOption,trxnRefSelectOption } = this.state; 

// console.log(requestData)

if (senderCustomerSelectOption === '' && requestData.senderId && requestData.senderId.id) {
    this.state.senderCustomerSelectOption = {
      label: requestData.senderId && requestData.senderId.fullName,
      value: requestData.senderId && requestData.senderId.id
    }
}
if (receiverCustomerSelectOption === '' &&  requestData.receiverId && requestData.receiverId.id) {
    this.state.receiverCustomerSelectOption = {
      label: requestData.receiverId && requestData.receiverId.fullName,
      value: requestData.receiverId && requestData.receiverId.id
    }
}

if (KioskSelectOption === '' && requestData.kioskId && requestData.kioskId.id) {
    this.state.KioskSelectOption = {
      label: requestData.kioskId && requestData.kioskId.kioskId,
      value: requestData.kioskId && requestData.kioskId.id
    }
}

if (trxnRefSelectOption === '' && requestData.trxnRefId && requestData.trxnRefId.id) {
    this.state.trxnRefSelectOption = {
      label: requestData.trxnRefId && requestData.trxnRefId.trxnRefId,
      value: requestData.trxnRefId && requestData.trxnRefId.id
    }
}





this.state.showSenderCustomer=requestData.trxnType==this.state.trxnTypeDefaultValue|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}`|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}` || requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}`;

this.state.showReceiverCustomer= requestData.trxnType==this.state.trxnTypeDefaultValue
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}`
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}` 
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`;


this.state.showKisok= requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}` 
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`;

this.state.showReceiverMobileNo=requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}` 
|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}`;


    return (

            <div>
                {/* <Header />
                <Menu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Edit Rollback Request</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-9 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Edit Request </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                      <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                        <label>Trxn Type:</label>
                                                        <select   value={requestData.trxnType} onChange={ this.onChangeTrxnType }   className="form-control" name='trxnType' >
                                                        <option value={ this.state.trxnTypeDefaultValue}>Select Trxn Type</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_LABEL}` }</option>
                                                        <option value={ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}` }>{ `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_LABEL}` }</option>
                                                       </select>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                     { 
                                                     this.state.showSenderCustomer &&
                                                     
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Sender Customer:</label>
                                                            <AsyncSelect id='senderCustomerList'
                                                                    placeholder={ "Search by account or Mobile no" }
                                                                     value={ this.state.senderCustomerSelectOption }
                                                                    isClearable={ true }
                                                                    loadOptions={ this.fetchSenderCustomerData }
                                                                    isLoading={ this.state.senderCustomerSelectOption == {} &&
                                                                        this.state.senderCustomerSelectOption === undefined &&
                                                                        this.state.senderCustomerSelectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onSenderCustomerSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />
                                                            {/* { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                            ) } */}
                                                        </div>
                                                      }
                                                      { this.state.showReceiverCustomer &&  !(requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`|| requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`) &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Receiver Customer:</label>
                                                            <AsyncSelect id='receiverCustomerList'
                                                                    placeholder={ "Search account or Mobile no" }    
                                                                     value={ this.state.receiverCustomerSelectOption }
                                                                    isClearable={ true }
                                                                    loadOptions={ this.fetchReceiverCustomerData }
                                                                    isLoading={ this.state.receiverCustomerSelectOption == {} &&
                                                                        this.state.receiverCustomerSelectOption === undefined &&
                                                                        this.state.receiverCustomerSelectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onReceiverCustomerSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />
                                                            {/* { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                            ) } */}
                                                         </div>
                                                         }
                                                        
                                                    {
                                                      this.state.showKisok &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Kiosk:</label>
                                                             <AsyncSelect id='kioskList'
                                                                required
                                                                placeholder={ "Search by kioskId or serialNo" }
                                                                 value={ this.state.KioskSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchKisokData } KioskSelectOption
                                                                isLoading={ this.state.KioskSelectOption == {} &&
                                                                    this.state.KioskSelectOption === undefined &&
                                                                    this.state.KioskSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            {/* { formErrors.kioskIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.kioskIdError }</span>
                                                            ) } */}
                                                        </div>
                                                      }
                                                 { this.state.showReceiverCustomer &&  (requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}` || requestData.trxnType==`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`) &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Receiver Customer:</label>
                                                            <AsyncSelect id='receiverCustomerList'
                                                                    placeholder={ "Search account or Mobile no" }    
                                                                     value={ this.state.receiverCustomerSelectOption }
                                                                    isClearable={ true }
                                                                    loadOptions={ this.fetchReceiverCustomerData }
                                                                    isLoading={ this.state.receiverCustomerSelectOption == {} &&
                                                                        this.state.receiverCustomerSelectOption === undefined &&
                                                                        this.state.receiverCustomerSelectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onReceiverCustomerSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />
                                                            {/* { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                            ) } */}
                                                         </div>
                                                         }
                                                     {  this.state.showReceiverMobileNo &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Receiver Mobile:</label>
                                                            <PhoneInput countryCodeEditable={ false } 
                                                                country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                                placeholder="Enter mobile number" id='mobileNo' name='mobileNo'
                                                                inputStyle={ { width: '100%', height: 'auto' } }
                                                                value={ requestData.receiverMobileNo } onChange={ this.onChangeMobileNo }
                                                            />
                                                                {/* { formErrors.receiverMobileNoError.length > 0 && (
                                                                    <span style={ { color: "#F61C04" } }>{ formErrors.receiverMobileNoError }</span>
                                                                ) } */}
                                         
                                                        </div>
                                                        }
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                                value={ requestData.amount } onChange={ this.onChangeAmount }
                                                                />
                                                            {/* { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) } */}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Trxn Ref:</label>
                                                            <AsyncSelect id='trxnRefList'
                                                                    placeholder={ "Search by transaction ref" }
                                                                     value={ this.state.trxnRefSelectOption }
                                                                    isClearable={ true }
                                                                    loadOptions={ this.fetchTrxnRefData }
                                                                    isLoading={ this.state.trxnRefSelectOption == {} &&
                                                                        this.state.trxnRefSelectOption === undefined &&
                                                                        this.state.trxnRefSelectOption === null ? true : false
                                                                    }
                                                                    onInputChange={this.handleInputChange}
                                                                    onChange={ (e) => {
                                                                        this.onTrxnRefSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />
                                                        </div>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='trxnDate'>Trxn Date:</label>
                                                             <input type="date" className="form-control" 
                                                             value={moment(requestData.trxnDate).format('YYYY-MM-DD')  } onChange={ this.onChangeTxnDate }
                                                             /> 
                                                            {/* <input type="date" className="form-control" id='trxnDate'
                                                            onChange={ e => this.state.trxnDate = e.target.value } /> */}
                                                            {/* { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) } 
                                                        </div> */}
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='remark'>Remark:</label>
                                                            <textarea cols="6" rows="4" type="text" className="form-control " id='remark' name='remark'
                                                                value={ requestData.remark } onChange={ this.onChangeRemark }
                                                                />
                                                            {/* { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) } */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(EditRollbackRequest);
