import axios from 'axios';
import React, { Component } from 'react'
import { Navigate, withRouter } from 'react-router-dom';
import CommissionService from '../../../services/etopup/CommissionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import RefundWalletService from '../../../services/wallet/RefundWalletService';

import 'react-phone-input-2/lib/style.css';
import AsyncSelect from "react-select/async";
import Menu from '../../../components/wallet/Menu';
import Header from '../../../components/common/Header';
import API from '../../../http-common';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOCALES } from '../../common/Constant';
import WalletTrxnService from '../../../services/wallet/WalletTrxnService';
import NumberFormat from 'react-number-format';
import WalletBalanceService from '../../../services/wallet/WalletBalanceService';
import LoginService from '../../../services/common/LoginService';
import ReactLoading from "react-loading"; 
import LoadingPopup from '../LoadingPopup';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class WalletToWalletTransfer extends React.Component {

    constructor () {

        super();

        this.state = {
            senderId: '',
            receiverId: '',
            amount: 0,
            remark: '',
           isLoading: false,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,

            customerId:'',
            customerType:'',
            senderSelectOption: null,
            reciverselectOption: null,

            formErrors: {
                senderCustomerError: "",
                reciverCustomerError: "",
                receiverMobileNoError: "",
                amountError: "",
            },
        }
        this.handelInputChange = this.handelInputChange.bind(this)
    }
    


    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    Cancel = e => {
        this.props.history.push('/walletTrxnList');
    }


    fatchSenders = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getcustomerByNameOrMobileNo/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            sendersList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
          }
    }

    onSenderChange = (senderSelectOption) => {
        const{formErrors}=this.state
        if (senderSelectOption) {
            this.setState({
                senderSelectOption,
                formErrors
            });

        }
    }

    fatchReciverData = (inputValue, callback) => {

        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getcustomerByNameOrMobileNo/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            reciversList: data,

                        })
                    })
                    .catch((error) => {
                    })
            })
        }else {
            callback([])
          }
    }

    onReciverSearchChange = (selectReciver) => {
        const { formErrors } = this.state
            this.setState({
                selectReciver,
                formErrors
            });
        
    }

    searchCustomerInfo(e) {
        const { formErrors, dateFrom, dateTo } = this.state
        formErrors.dateFromError = ''
        formErrors.dateToError = ''

        if (dateFrom != null) {
            formErrors.dateToError =
                dateTo == null ? 'date to is required' : ''
        }

        if (dateTo != null) {
            formErrors.dateFromError =
                dateFrom == null ? 'date from is required' : ''
        }

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
            }
        }
        if(this.state.amount<=0)

        if (formValid(this.state.formErrors)) {

            this.state.clear = 0;
            this.state.pageNo = 1;
            this.seachCustomerBasedOnFilter(this.state.senderSelectOption)

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }
    seachCustomerBasedOnFilter(customerId) {

        let walletInfo = {
            senderId: customerId,
            receiverId: this.state.receiverId,
            trxnType: 8,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }

        WalletTrxnService.getFilteredWalletTrxnsWithPagination(
            this.state.pageNo, this.state.pageSize, localStorage.userId, walletInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    transactionList: data.walleTrxns,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });
            });
    }

    componentDidMount() {
        
        LoginService.getUserInfo(localStorage.userId)
        .then(response => {
            this.setState({
                customerType: response.data.type
            });
            
            })
            .catch(e => {
            
            });
        {/*To get Customer types*/ }
        this.seachCustomerBasedOnFilter(localStorage.customerIdentity)
        }

    handleSubmit = e => {
            e.preventDefault();
        const { amount, customerType, senderSelectOption, selectReciver,formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined || amount == 0 ? 'Amount is Required' : this.state.formErrors.amountError;
       if( localStorage.type == `${process.env.REACT_APP_CUSTOMER_TYPE_USER}`){
        formErrors.senderCustomerError = senderSelectOption == '' || senderSelectOption == null || senderSelectOption == undefined ? 'Please select the sender' : this.state.formErrors.senderCustomerError;
       }
        formErrors.reciverCustomerError = selectReciver == '' || selectReciver == null || selectReciver == undefined ? 'Please select the receiver' :  this.state.formErrors.reciverCustomerError ;

       
     if (formValid(this.state.formErrors)) {
        if(localStorage.type==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`)
        {
            this.state.customerId= this.state.senderSelectOption && this.state.senderSelectOption.value 
        }else{
            this.state.customerId = localStorage.customerIdentity
        }
        var transferData = new FormData();
        transferData.append("senderId", this.state.customerId);
        transferData.append("receiverId", this.state.selectReciver && this.state.selectReciver.value);
        transferData.append("amount", this.state.amount);
        transferData.append("remark", this.state.remark);
        transferData.append("userId", localStorage.userId);

        this.setState({ isLoading: true})
        WalletTrxnService.walletToWalletTransfer(transferData)
            //response.data.success === "Success"
            .then(
            (response) => {
                NotificationManager.success("Successfully Transfered!!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoading: false})
                this.props.history.push('/walletTrxnList');
            },
            (error) =>{
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoading: false})
            }
            )
            .catch(function (err) {
                NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
               // this.setState({ isLoading: false})
            });
        } else {
          //  NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    }


    render() {

        const {  customerType, formErrors, pageNo, totalPage } = this.state;
      

        return (

            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}

                <div className="content-wrapper bg-white">
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" >Transfer Wallet Balance  </li>

                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  btrxns-radius-0" >
                                                <div className="card-header btrxns-radius-0 bg-light">
                                                    <h6 class="title " style={ {
                                                        display: "inline",
                                                        float: "left"
                                                    } }>Wallet To Wallet Transfer  </h6>

                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                    { customerType === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ?
            

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='senderId'>Sender:</label>
                                                            <div className="w-70 pull-left">
                                                                <AsyncSelect id='sendersList'
                                                                    className="select"
                                                                    placeholder={ "Search by Name or Mobile" }
                                                                    isClearable={ true }

                                                                    value={ this.state.senderSelectOption }
                                                                    loadOptions={ this.fatchSenders }
                                                                    isLoading={ this.state.senderSelectOption === {} &&
                                                                        this.state.senderSelectOption === undefined &&
                                                                        this.state.senderSelectOption === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onSenderChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                />


                                                            </div>
                                                            { formErrors.senderCustomerError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.senderCustomerError }</span>
                                                            ) }

                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='receiverId' >Receiver: </label>
                                                            <div className="w-70 pull-left">
                                                                <AsyncSelect id='CustomerList'
                                                                    className="select"
                                                                    placeholder={ "Search by Name or Mobile" }
                                                                    value={ this.state.selectReciver }
                                                                    isClearable={ true }
                                                                    cacheOptions
                                                                    loadOptions={ this.fatchReciverData }
                                                                    isLoading={ this.state.selectReciver === {} &&
                                                                        this.state.selectReciver === undefined &&
                                                                        this.state.selectReciver === null ? true : false
                                                                    }
                                                                    onChange={ (e) => {
                                                                        this.onReciverSearchChange(e)
                                                                    } }
                                                                    defaultOptions={ false }
                                                                    required
                                                                />

                                                                { formErrors.reciverCustomerError.length > 0 && (
                                                                    <span style={ { color: "#F61C04" } }>{ formErrors.reciverCustomerError }</span>
                                                                ) }

                                                            </div>

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Remark:</label>
                                                            <textarea rows={ 2 } cols={ 4 } type="text" className="form-control " id='remark' name='remark' onChange={ e => this.state.remark = e.target.value } />
                                                        </div>


                                                    </div>
                                                    <div className="row  d-flex justify-content-left clear-fix ">
                                                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                        <button type="submit" onClick={ () => this.Cancel() }
                                                            className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>

                                        <div className="clearfix">&nbsp;</div>
                                    
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div >
            </div >

        )
    }
}

export default withRouter(WalletToWalletTransfer);
