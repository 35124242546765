import API from '../../../src/http-common';

class CustomerTypeService {
    getCustomerTypes(userId) {
        return API.get('/api/v1/getAllCustomerTypes/' + userId);
    }

    getMainCompayCustomerTypes(userId) {
        return API.get('/api/v1/getMainCompayCustomerTypes/' + userId);
    }

    

    createCustomerType(userId, type) {
        return API.post("/api/v1/createCustomerType/" + userId + "/" +  type);
    }


     // remove  Cash Information 
     deleteCustomerType(id, userId) {
    return API.delete('/api/v1/deleteCustomerType' + '/' + id + '/' + userId);
  }
}
export default new CustomerTypeService()