import API from '../../http-common';

class PartnerTargetService {

    createPartnerTarget(partnerTargetData) {
        return API.post("/api/v1/createPartnerTarget", partnerTargetData);
    }
    getPartnerTargetByMonth(pageNo, pageSize, userId, searchPara) {
        return API.put("/api/v1/getPartnerTargetByMonth/" + pageNo + "/" + pageSize + "/" + userId, searchPara);
    }
    getPartnerTargetByMonthAndProviderId(month, providerId, userId) {
        return API.get("/api/v1/getPartnerTargetByMonthAndProviderId/" + month + "/" + providerId + "/" + userId);
    }

    getPartnerTargetById(id, userId) {
        return API.get("/api/v1/getPartnerTargetById/" + id + "/" + userId);
    }
    updatePartnerTarget(partnerTargetData) {
        return API.put("/api/v1/updatePartnerTarget", partnerTargetData);
    }
    getMonthlyPartnerTarget(month, userId) {
        return API.get("/api/v1/getMonthlyPartnerTarget/" + month + "/" + userId);
    }
}


export default new PartnerTargetService()