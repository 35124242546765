import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddProviderCommission extends React.Component {

    constructor (props) {
        super(props);
        this.state = {

            providerId: '',
            onPurchase: 0,
            onTarget: 0,
            quarterly: 0,
            remark: '',
            isLoading: false,
            isProviderExist:[],
            providersList: [],
            getPartnerTarget: [],
            isTargetExist: null,
            pageNo: 1,
            customerId: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                onPurchaseError: "",
                onTargetError:"",
                quarterlyError:"",
                providerIdError:""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/providersCommissionList');
    }
    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target

        let formErrors = this.state.formErrors;
        switch (name) {
            case 'onPurchase':
                formErrors.onPurchaseError =
                value.length == 0 || !amountRegEx.test(value) ? "" + "on purchase commission should only be digits." : ""
                break;
            case 'onTarget':
                formErrors.onTargetError =
                value.length == 0 || !amountRegEx.test(value) ? "" + "on target commission should only be digits." : ""
                break;
            case 'quarterly':
                formErrors.quarterlyError =
                value.length == 0 || !amountRegEx.test(value) ? "" + "quarterly commission should only be digits." : ""
                break;
                default:
                break;
                    }
                this.setState({
                    formErrors, [ name ]: value
                })
           }

    handleSubmit(e) {

        e.preventDefault();

        const { onPurchase,onTarget, providerId,quarterly, formErrors } = this.state
        formErrors.onTargetError = this.state.onTarget === "" || this.state.onTarget === null || this.state.onTarget === undefined || this.state.onTarget === 0? "Please enter the on target commission " : this.state.formErrors.onPurchaseError;
        formErrors.quarterlyError = this.state.quarterly === "" || this.state.quarterly === null || this.state.quarterly === undefined  || this.state.quarterly === 0? "Please enter the on quarterly commission " : this.state.formErrors.onPurchaseError;
        formErrors.onPurchaseError = this.state.onPurchase === "" || this.state.onPurchase === null || this.state.onPurchase === undefined || this.state.onPurchase === 0? "Please enter the on purchase commission " : this.state.formErrors.onPurchaseError;
        formErrors.providerIdError = this.state.providerId === "" || this.state.providerId === null || this.state.providerId === undefined ? "Provider is Required" : this.state.formErrors.providerIdError;
     
      if (formValid(this.state.formErrors)) {
        var data = new FormData();
        data.append("providerId", this.state.providerId);
        data.append("onTarget", this.state.onTarget);
        data.append("onPurchase", this.state.onPurchase);
        data.append("quarterly", this.state.quarterly);
        data.append("remark", this.state.remark);
        data.append("userId", localStorage.userId);

        this.setState({ isLoading: true})
        ProviderCommissionService.getProviderCommissionByProviderId( this.state.providerId, localStorage.userId)
            .then(response => {
                this.setState({
                    isProviderExist: response.data
                });
                //    console.log(this.state.getPartnerTarget.id)
                if (this.state.isProviderExist) {
                    if (window.confirm("Commission already exists ! Are you sure you want to update it?")) {
                        ProviderCommissionService.createProviderCommission(data)
                        .then(
                        (response) => {
                            NotificationManager.success("Successfully created ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                            this.props.history.push('/providersCommissionList')
                            this.setState({ isLoading: false})
                        },
                        (error) => {
                            NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                          //  this.setState({ isLoading: false})
                        })
                        .catch(function (err) {
                            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                            this.setState({ isLoading: false})
                        });
                    }
                }
                else {
                    ProviderCommissionService.createProviderCommission(data)
                    .then(
                    (response) => {
                        NotificationManager.success("Successfully created ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                        this.setState({ isLoading: false})
                        this.props.history.push('/providersCommissionList')
                    },
                    (error) => {
                        NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({ isLoading: false})
                    })
                    .catch(function (err) {
                        NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                       this.setState({ isLoading: false})
                    });
                }

            })
            .catch(e => {
            });
        } else {
           // NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    };

  
    render() {
        const { providersList,formErrors, month } = this.state;

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Provider Commission</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add Provider Commission</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Provider Name:</label>
                                                            <select onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" name='providerId'>
                                                                <option value=""> Choose provider</option>
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                            { formErrors.providerIdError.length>0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='onPurchase'>On Purchase:</label>
                                                            <input type="text" className="form-control " id='onPurchase' name='onPurchase' 
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                              { formErrors.onPurchaseError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.onPurchaseError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='onTarget'>On Target:</label>
                                                            <input type="text" className="form-control " id='onTarget' name='onTarget' 
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                              { formErrors.onTargetError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.onTargetError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='quarterly'>Quarterly:</label>
                                                            <input type="text" className="form-control " id='quarterly' name='quarterly' 
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                              { formErrors.quarterlyError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.quarterlyError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='remark'>Remark:</label>
                                                            <input type="text" className="form-control " id='remark' name='remark'

                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading && <LoadingPopup
                                  content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                     </>}
                             />}
                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddProviderCommission);
