
import API from '../../http-common';

class RegionService {

    getAllRegions(userId) {
        return API.get('/api/v1/getAllRegions/' + userId);
    }
    getRegionById(id, userId) {
        return API.get("/api/v1/getRegionById/"+ id + "/" + userId);
    }
     createRegion(region, userId) {
        return API.post("/api/v1/createRegion",region);
    }
    deleteRegionById(id, userId) {
        return API.delete("/api/v1/deleteRegionById/"+ id + "/" + userId);
    } 
    
    updateRegionById(id,data) {
        return API.put("/api/v1/updateRegionById/"+id,data);
    }
}
export default new RegionService()