import React, { Component } from 'react'
import {Map,GoogleApiWrapper,Marker} from 'google-maps-react';
import CustomersService from '../../../services/common/CustomersService'; 

export class CustomerGoogleMapLocation extends Component {

    constructor (props) {
        super(props)
        this.state = {
            lat:"",
            lng:"",
            googleMapKeyData:[],
        }
    }
    componentDidMount() {

        const param = new URLSearchParams(this.props.location.search);
       
        this.setState({
            lat: param.get('lat'),
            lng: param.get('lng')
        });
        CustomersService.getGoogleApiKey(localStorage.userId)
        .then(response => {
            if (response) {
            this.setState({
                googleMapKeyData: response.data
            });
            }
        })
        .catch(e => {
        });


    }
    render() {
        const{lat, lng }= this.state
      
            return (
                <div>
              { this.state.lat ? 
                <>
                 <Map
                google={this.props.google}
                zoom={14}
                style={{heightt:'100%',width:'100%'}}   
                initialCenter={{lat: lat,
                    lng: lng}}
                 >
                 <Marker position={{ lat: lat, lng: lng}} />
              </Map>
              </>
              :
              ""
                }
                </div>
           );
    }
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyDGy5YmVWOULRJV6rTFPEVZ7wmcYffNSQs"
  })(CustomerGoogleMapLocation);
// const GoogleMap = GoogleApiWrapper({
//     apiKey: "AIzaSyDGy5YmVWOULRJV6rTFPEVZ7wmcYffNSQs"
// })()

//  export default GoogleMap;
