import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import API from '../../../http-common';
import AsyncSelect from 'react-select/async';
import LoginService from '../../../services/common/LoginService';
import WalletTrxnService from '../../../services/wallet/WalletTrxnService';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import PreviewTable from '../../Reports/PreviewTable';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)
const trxnRefRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/);

class WalletTransaction extends Component {

    constructor(props) {

        super(props)
        this.state = {
            isLoading: false,
            // For search purposes
            isCompany: false,
            transactionList: [],
            customerSelectOption: null,
            trxnRefSelectOption: null,

            customerId: null,
            trxnRefId: null,
            trxnType: null,
            dateFrom: null,
            dateTo: null,


            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            columns: [
                {
                    Header: 'Sender Name',
                    accessor: 'senderFullName',
                },
                {
                    Header: 'Sender Account',
                    accessor: 'senderAccount',
                },
                {
                    Header: 'Receiver Name',
                    accessor: 'receiverFullName',
                },
                {
                    Header: 'Receiver Account',
                    accessor: 'receiverAccount',
                },
               
                {
                    Header: 'Amount',
                    accessor: 'amount',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value}`,
                },

                {
                    Header: 'Trxn Type',
                    accessor: 'trxnTypeValue',
                },

                {
                    Header: 'Sender Ref #',
                    accessor: 'senderRefNumber',
                },
                {
                    Header: 'Receiver Ref #',
                    accessor: 'receiverRefNumber',
                },
                {
                    Header: 'Platform Used',
                    accessor: 'platFormUsed',
                }


            ],
            reportData: [],
            formErrors: {
                phoneNumberError: "",
                accountNoError: "",
                fullNameError: "",
                dateToError: "",
                dateFromError: "",
                customerIdError: "",
                trxnRefIdError: ""
            },
        }
        this.clearValue = this.clearValue.bind(this);

    }

    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachCustomerBasedOnFilter()
            this.render()
        }
    }

    handleKeyPress = (event) => {

     
        // if(event.key === 'Enter'){
        //   console.log('enter press here! ')
        // }
    }

    fetchCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {
                       
                    })
            })
        }
    }

    onCustomerSearchChange = (customerSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = customerSelectOption == "" || customerSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            customerSelectOption: customerSelectOption,
            formErrors
        });
    }

    fetchTrxnRefData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findTransactionRefBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.trxnRefId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            trxnRefList: data,
                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }
    }

    onTrxnRefSearchChange = (trxnRefSelectOption) => {
        const { formErrors } = this.state

        formErrors.trxnRefIdError = trxnRefSelectOption == "" || trxnRefSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            trxnRefSelectOption: trxnRefSelectOption,
            formErrors
            // input: trxnRefSelectOption.target.value.replace(/\D/g,'')
        });
    }

    clearValue() {
        // should not reload
        this.state.customerId = null
        this.state.dateFrom = null
        this.state.dateTo = null
        this.state.trxnType = null
        this.state.customerSelectOption = null;
        this.state.trxnRefSelectOption = null;
        this.state.pageNo = 1;
        this.state.clear = 1
    }

    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) { 
            e.target.reset();    
            this.seachCustomerBasedOnFilter()

        }
    }


    searchCustomerInfo(e) {
        const { formErrors, dateFrom, dateTo } = this.state
        formErrors.dateFromError = ''
        formErrors.dateToError = ''


        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
            }
        }

        if (formValid(this.state.formErrors)) {
            this.state.clear = 0;
            this.state.pageNo = 1;
            this.seachCustomerBasedOnFilter()

        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    }

    seachCustomerBasedOnFilter() {
        let customerId =  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? this.state.customerSelectOption && this.state.customerSelectOption.value : localStorage.customerIdentity


        let walletInfo = {
            // customerId: this.state.customerSelectOption === null ? localStorage.customerIdentity : this.state.customerSelectOption.value,
            customerId: customerId,
            trxnRefId:  this.state.trxnRefSelectOption  && this.state.trxnRefSelectOption.value,
            trxnType: this.state.trxnType,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }
        this.setState({ isLoading: false })
        WalletTrxnService.getFilteredWalletTrxnsWithPagination(
            this.state.pageNo, this.state.pageSize, localStorage.userId, walletInfo)
            .then(response => response.data)
            .then((data) => {
               
                this.setState({
                    reportData: data.walletTransactions == undefined ? [] : data.walletTransactions,
                    transactionList: data.walletTransactions,
                    isLoading: true,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });
            
            
            });

            
    }


    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }

        this.setState({
            formErrors
        })
    }

    componentDidMount() {

        this.seachCustomerBasedOnFilter()
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    fullName: response.data.fullName,
                    customerType: response.data.type
                });
                // this.setState({
                //     isCompany:  localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
                //     });

            })
            .catch(e => {
            });
    }

    actionPageSize(e) {
        this.state.pageSize = e.target.value;
        this.setState({
            isLoading: false,
            pageSize: e.target.value
        });

        this.seachCustomerBasedOnFilter();
    }

    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target    
        this.setState({
          [name]: value
        })
      }

    render() {
        const { dateFrom, trxnRefSelectOption, dateTo, formErrors, pageSize, isLoading, columns, reportData, customerId, fullName, customerSelectOption, pageNo, totalPage, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
     

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Wallet Trxn</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */}
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Wallet Trxn List </div>
                                        <form onSubmit={this.clear} hidden={this.state.isExpand} >
                                            <div className="row" hidden={this.state.isExpand}>
                                            { localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` &&
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <div style={{ fontSize: 'smaller' }}>

                                                        <div>
                                                            <label className='ml-2' htmlFor='CustomerList'>Customer :</label>
                                                            <div style={{ fontSize: 'smaller' }}>
                                                                <AsyncSelect id='CustomerList' value={customerSelectOption}
                                                                    placeholder={"Search account or Mobile no"}
                                                                    // value={ this.state.customerSelectOption }
                                                                    isClearable={true}
                                                                    loadOptions={this.fetchCustomerData}
                                                                    isLoading={this.state.customerSelectOption === {} &&
                                                                        this.state.customerSelectOption === undefined &&
                                                                        this.state.customerSelectOption === null ? true : false
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.onCustomerSearchChange(e)
                                                                    }}
                                                                    defaultOptions={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               }
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <div style={{ fontSize: 'smaller' }}>

                                                        <div>
                                                            <label className='ml-2' htmlFor='CustomerList'>Transaction Ref :</label>
                                                            <div>
                                                                <AsyncSelect id='trxnRefList'
                                                                    placeholder={"Search by transaction ref"}
                                                                    value={trxnRefSelectOption}
                                                                    isClearable={true}
                                                                    loadOptions={this.fetchTrxnRefData}
                                                                    isLoading={this.state.trxnRefSelectOption === {} &&
                                                                        this.state.trxnRefSelectOption === undefined &&
                                                                        this.state.trxnRefSelectOption === null ? true : false
                                                                    }
                                                                    onInputChange={this.handleInputChange}
                                                                    onChange={(e) => {
                                                                        this.onTrxnRefSearchChange(e)
                                                                    }}
                                                                    defaultOptions={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-8 col-md-3 col-lg-3">
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label className='ml-2' htmlFor='trxn type'>Trxn Type</label>
                                                            <div className="w-90 pull-left">
                                                                <select onChange={e => this.state.trxnType = e.target.value} className="form-control" name='type'>
                                                                    <option value=""> Choose trxn type</option>
                                                                    <option value={0}>{process.env.REACT_APP_WALLET_TRXN_TYPE_PAYMENT}</option>
                                                                    <option value={1}>{process.env.REACT_APP_WALLET_TRXN_TYPE_TRANSFER}</option>
                                                                    <option value={2}>{process.env.REACT_APP_WALLET_TRXN_TYPE_CASHIN}</option>
                                                                    <option value={3}>{process.env.REACT_APP_WALLET_TRXN_TYPE_CASHOUT}</option>
                                                                    <option value={4}>{process.env.REACT_APP_WALLET_TRXN_TYPE_OVERDRAFT}</option>
                                                                    <option value={6}>{process.env.REACT_APP_WALLET_TRXN_TYPE_PAYBILL}</option>
                                                                    <option value={7}>{process.env.REACT_APP_WALLET_TRXN_TYPE_TOPUPFUND}</option>
                                                                    <option value={8}>{process.env.REACT_APP_WALLET_TRXN_TYPE_WALLETREFUND}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" hidden={this.state.isExpand}>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label htmlFor='dateFrom' >Date From</label>
                                                            <div >
                                                                <input type="date" value={dateFrom}   className="form-control" id='dateFrom' name='dateFrom'
                                                                    onChange={this.handelInputChange}
                                                                    placeholder="Date" />
                                                                {formErrors.dateFromError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <div>
                                                            <label>Date To</label>
                                                            <div >
                                                                <input  type="date" value={dateTo} className="form-control" id='dateFrom' name='dateTo'
                                                                    onChange={this.handelInputChange}
                                                                    placeholder="Date" />
                                                                {formErrors.dateToError.length > 0 && (
                                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='pt-4 mt-2'>
                                                    <button type="button" title={"Search"}
                                                        className="btn btn-sm btn-success primary m-1 float-right"
                                                        onClick={() => this.searchCustomerInfo()}>Search</button>
                                                    <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                                                        className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                </div>
                                            </div>
                                        </form>
                                        <div style={{ clear: 'both' }}></div>
                                        <br />
                                        {/* <Link to={ { pathname: '/newcustomer', state: { type: "Agent", formName: "order Registration" } } }>
                                        <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                            <i className="fas fa-plus" /> Add
                                        </button>
                                    </Link> */}
                                        <button type='button' style={{ marginLeft: "3px" }} title={"Show/Hide Filter"}
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={() => this.filterToggle()}>
                                            <FontAwesomeIcon icon={faFilter} />
                                            Filter
                                        </button>

                                        <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                                            <label htmlFor='Size'>Row #:</label>
                                            <select value={pageSize}
                                                onChange={e => this.actionPageSize(e)} name='Size'>
                                                <option key={"20"} value={20}>20</option>
                                                <option key={"50"} value={50}>50</option>
                                                <option key={"500"} value={500}>500</option>
                                                <option key={"1000"} value={1000}>1000</option>
                                                <option key={"10000"} value={10000}>10000</option>
                                            </select>
                                        </div>
                                        <div style={{ clear: 'both' }}></div>
                                        {/* <div className='tableContainer'>

                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Sender Account</th>
                                                    <th>Sender Name</th>
                                                    <th>Receiver Account</th>
                                                    <th>Receiver Name</th>
                                                    <th>Amount</th>
                                                    <th>Trxn Type</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { transactionList !== undefined && transactionList.map(trxn =>

                                                    <tr key={ trxn.id }>
                                                        <td className='p-0 '>{ trxn.senderAccount }</td>
                                                        <td className='p-0 '>{ trxn.senderFullName }</td>
                                                        <td className='p-0 '>{ trxn.receiverAccount }</td>
                                                        <td className='p-0 '>{ trxn.receiverFullName }</td>
                                                        <td className='p-0 '>
                                                        <NumberFormat value={ trxn.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        </td>
                                                        { trxn.trxnType == 0 &&
                                                            <td className='p-0 '>{ "Payment" }</td>
                                                        }
                                                        { trxn.trxnType == 1 &&
                                                            <td className='p-0 '>{ "Transfer" }</td>
                                                        }
                                                        { trxn.trxnType == 2 &&
                                                            <td className='p-0 '>{ "CachIn" }</td>
                                                        }
                                                        { trxn.trxnType == 3 &&
                                                            <td className='p-0 '>{ "CachOut" }</td>
                                                        }
                                                        { trxn.trxnType == 4 &&
                                                            <td className='p-0 '>{ "Over Draft" }</td>
                                                        }
                                                        { trxn.trxnType == 6 &&
                                                            <td className='p -0 '>{ "Pay Bill" }</td>
                                                        }
                                                        { trxn.trxnType == 7 &&
                                                            <td className='p-0 '>{ "Topup Fund" }</td>
                                                        }
                                                        { trxn.trxnType == 8 &&
                                                            <td className='p-0 '>{ "Wallet Fund" }</td>
                                                        }
                                                           { trxn.trxnType == 9 &&
                                                            <td className='p-0 '>{ "Refunded cash" }</td>
                                                        }
                                                        { trxn.trxnType == '' &&
                                                            <td className='p-0 '>{ "" }</td>
                                                        }
                                                        <td className='p-0 '>{ format(trxn.createdAt, 'yyyy-MM-dd') }</td>

                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        </div> */}
                                        <PreviewTable columns={columns} data={reportData} />
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{''}
                                                <strong>
                                                    {pageNo} of {totalPage}
                                                </strong>
                                                {''}
                                            </span>
                                            <span>
                                                | Go To Page : {''}
                                                <input type='number' defaultValue={pageNo}
                                                    onChange={(e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    }} />
                                            </span>
                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackwardFast} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackward} />
                                            </button>

                                            <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                                <FontAwesomeIcon icon={faForward} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                                <FontAwesomeIcon icon={faForwardFast} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div >
                </div >

            )
        }
    }
}
export default WalletTransaction