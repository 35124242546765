import React, { Component } from 'react'
import { Link } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import Image from "react-image-enlarger";
import TopupOrderService from '../../../services/etopup/TopupOrderService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import PhoneInput from 'react-phone-input-2';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import LoginService from '../../../services/common/LoginService';
import NumberFormat from 'react-number-format';
import ReactLoading from "react-loading";

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)


class TopupOrderList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            // For search purposes
            attachmentzIndex:0,
            zoomedAttachment: false, 
            customerId: '',
            isLoading: false,
            orderList: [],
            accountNo: null,
            fullName: null,
            mobileNo: null,
            dateFrom: null,
            dateTo: null,
            status: 0,
            customerType:'',
            AgentList: [
                {
                    regionId: [],
                    countryId: [],
                    customerType: []
                }
            ],
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            // Customer Id of the loggedIn user
            isExpand: true,
            type: LOCALES.AGENT,
            formErrors: {
                phoneNumberError: "",
                accountNoError: "",
                fullNameError: "",
                dateToError: "",
                dateFromError: ""
            },
            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);
        this.attachmentZoomOut = this.attachmentZoomOut.bind(this); 
        this.attachmentZoomIn = this.attachmentZoomIn.bind(this);
    }
    attachmentZoomOut() {
        this.setState({ zoomedAttachment: false, attachmentzIndex:0 })
    }
    attachmentZoomIn() {
        this.setState({ zoomedAttachment: true ,attachmentzIndex:9999})
    }
    gotoEnteredPage = (pageNumber) => {

        this.state.pageNo = pageNumber
        this.seachCustomerBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.seachCustomerBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.seachCustomerBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.seachCustomerBasedOnFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.seachCustomerBasedOnFilter()
            this.render()
        }
    }

    clearValue() {
        // should not reload
        this.state.fullName = ""
        this.state.mobileNo = ""
        this.state.accountNo = ""
        this.state.dateFrom = ""
        this.state.dateTo = ""
        this.state.clear = 1
    }

    searchCustomerInfo(e) {
        this.seachCustomerBasedOnFilter()
    }
    seachCustomerBasedOnFilter() {

        this.CustomerList = null;
        let FilterCustomerInfo = {
            accountNo: this.state.accountNo,
            fullName: this.state.fullName,
            mobileNo: this.state.mobileNo,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        }

        TopupOrderService.getFilterdCustomerWithPagination(
            this.state.pageNo, this.state.pageSize, this.state.topupStatus, FilterCustomerInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({

                    orderList: data.content,
                    isOpen: false,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
              

            });
    }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.mobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }

        this.setState({
            formErrors
        })
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    customerType: response.data.type
                });
                this.getAllTopupOrders(this.state.pageNo, this.state.pageSize, this.state.customerId, this.state.status, localStorage.userId)
            })
            .catch(e => {
                
            });


    }
    getAllTopupOrders(pageNo, pageSize, customerId, status, userId) {
        // console.log(pageNo + "-" + pageSize + "-" + customerId + "-" + status + "-" + userId)
        TopupOrderService.getAllTopupOrders(pageNo, pageSize, customerId, status, userId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    orderList: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1
                });
            });
    }
  
    // Approve order
    async approveOrder(id, parentId, dealerId, amount, providerId, userId) {
        if (window.confirm("Are you sure you want to approve this order?")) {
            
            TopupOrderService.approveTopupOrder(id, parentId, dealerId, amount, providerId, userId)
                .then(response => {
                    NotificationManager.success("Approved successfully",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.getAllTopupOrders(this.state.pageNo, this.state.pageSize, this.state.customerId, this.state.status, localStorage.userId)
                })

                .catch(function (err) {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 1000 );

                });

            // toast.error("Something went wrong !!")
        }

    }

    async deleteOrder(orderId) {
        if (window.confirm("Are you sure you want to delete this order?")) {

            const response = await TopupOrderService.deleteOrder(orderId, localStorage.userId);
            NotificationManager.success('Deleted Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 3000);

        } else {
            NotificationManager.error('The Data does not deleted',  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
    }

    render() {
        const { customerId,attachmentzIndex,customerType,zoomedAttachment,orderList, formErrors, pageNo, isLoading, totalPage, totalelement, personalInfo } = this.state;
        this.state.counter = pageNo
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    {/* orderList.length > 0 && */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">eTop Up Order List</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >eTop Up Order List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">
                                                    <div>
                                                        <label htmlFor='mobileNo' >Mobile No</label>
                                                        <PhoneInput countryCodeEditable={ false } value={ this.state.mobileNo }
                                                            country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                                                            placeholder="Enter phone number" id='mobileNo'
                                                            inputStyle={ { width: '100%', height: 'auto' } }
                                                            onChange={ phone => this.onChangeMobileNo(phone) } />
                                                    </div>
                                                    <div>
                                                        <label htmlFor='accountNo' > Account No</label>
                                                        <input type="text" className="form-control" id='accountNo'
                                                            onChange={ e => this.state.accountNo = e.target.value }
                                                            placeholder="Account No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='fullName'>Full Name</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="text" className="form-control" id='fullName'
                                                            onChange={ e => this.state.fullName = e.target.value }
                                                            placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label htmlFor='dateFrom' >Date From</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateFrom = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateFromError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                                                        ) }
                                                    </div>

                                                    <label>Date To</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='dateFrom'
                                                            onChange={ e => this.state.dateTo = e.target.value }
                                                            placeholder="Date" />
                                                        { formErrors.dateToError.length > 0 && (
                                                            <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchCustomerInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/addOrders', state: { type: "Agent", formName: "order Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        {/*
                                    <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                        className="btn btn-sm btn-success primary float-left mb-2"
                                        onClick={ () => this.filterToggle() }>
                                        <FontAwesomeIcon icon={ faFilter } />
                                        Filter
                                    </button> */}
                                        <table className="table table-striped table-birdered" >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>Full Name</th>
                                                    <th>Amount</th>
                                                    <th>Receipt</th>
                                                    <th>Date</th>
                                                    {/* <th>Balance</th> */ }
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { orderList !== undefined && orderList.map(order =>

                                                    <tr className='table-tr'>
                                                        <td className='p-0'>{ order.dealerId.firstName + " " + order.dealerId.middleName + " " + order.dealerId.lastName }</td>
                                                        {/* <td className='p-0 '>{ order.amount }</td> */ }
                                                        <NumberFormat value={ order.amount.toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />
                                                        <td className='p-0' style={{zIndex:this.state.attachmentzIndex}}> 
                                                            { order.attachment && 
                                                            <Image
                                                                style={{ width: "70px", height: "auto" }}
                                                                zoomed={zoomedAttachment}
                                                                src={"data:image/png;base64," + order.attachment}
                                                                alt="Receipt"
                                                                onClick={() => this.attachmentZoomIn()}
                                                                onRequestClose={() => this.attachmentZoomOut()}
                                                             />
                                                             }
                                                        </td>
                                                        <td className='p-0 '>{ order.createdAt }</td>
                                                        {/* <td className='p-0 '>{order.balance}</td> */ }

                                                        <td className='p-0'> 
                                                            { order.status === 0 && ( customerId==order.parentId.id ||customerType==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) &&
                                                                <button style={ { marginLeft: "2px" } } onClick={ () => this.approveOrder(order.id, order.parentId.id, order.dealerId.id, order.amount, order.providerId.id, localStorage.userId) } className="btn btn-danger btn-sm action-buttons" title="Approve Order"> 
                                                                 <FontAwesomeIcon icon={faCheckDouble} />
                                                                </button>
                                                            }
                                                            { order.status === 0 &&  customerId==order.dealerId.id &&
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-danger btn-sm action-buttons"  disabled title="Waiting for Approval"> 
                                                                 <FontAwesomeIcon icon={faCheckDouble} />
                                                                </button>
                                                            }
                                                            { order.status === 1 &&
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-success btn-sm action-buttons" title="Approved">
                                                                 <FontAwesomeIcon icon={faCheckDouble} />
                                                                </button>
                                                            }

                                                            <Link to={ { pathname: '/viewOrders', state: { orderId: order.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" title="View order" /> </button>
                                                            </Link>
                                                            
                                                             {/* <Link to={ { pathname: '/editOrders', state: { topupOrderId: order.id, dealerId:order.dealerId.id } } }>
                                                                    <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" title="Edit order" /> </button>
                                                             </Link>  */}
                                                            {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteOrder(order.id) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" title="Delete order" /> </button> */ }
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>

                    {/* { orderList.length < 1 &&
                        
                        <div class=" content-wrapper alert alert-danger alert-dismissible fade show" role="alert">
                            
                            <strong>Ohh Nope!</strong> Order not exist !
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    } */}
                </div>

            )
        }
    }
}
export default TopupOrderList