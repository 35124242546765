import React, { Component } from 'react';
import ProvidersService from '../../../services/common/ProvidersService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
//import Menu from '../../wallet/Menu';
import TopupMenu from '../../../components/etopup/TopupMenu';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";

class ProvidersList extends Component {
    constructor (props) {

        super(props)

        this.state = {
            isLoading: false,
            providersList: [],
            counter: 0,
            id: 0,
            isExpand: true,
            selectOption: '',
            customerList: "",
            providerIds: [],
            providerId: '',

        }
        this.editProviders = this.editProviders.bind(this);
        this.providersDetail = this.providersDetail.bind(this);
        this.addProviders = this.addProviders.bind(this);

    }

    addProviders() {
        this.props.history.push(`/addProviders`);
    }

    providersDetail(id) {
        this.props.history.push({pathname: "/viewProviders", state: {id: id}});
    }

    editProviders(id) {
        this.props.history.push({pathname: "/editProviders", state: {id: id}});
    }

    componentDidMount() {
        this.searchProvidersInfo()

    }

    searchProvidersInfo() {
        ProvidersService.getProvidersInfos(localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    providersList: data
                });
                this.setState({ isLoading: true })
            });
    }

    async deleteProviders(id) {
        if (window.confirm(`${process.env.REACT_APP_DELETE_CONFIRM_MSG}` + "?")) {
            try {
                const response = await ProvidersService.deleteProvidersInfos(id, localStorage.userId);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.searchProvidersInfo()
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`,1000);
            }
        }
    }

    render() {
        const { providersList, isLoading } = this.state;
        this.state.counter = 0;
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" >Providers Information List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Display Providers Information</div>


                                        <button type="button" title={ "Add" }
                                            className="btn btn-sm btn-success primary mb-1  float-left"
                                            onClick={ (e) => this.addProviders() }>
                                            <i className="fas fa-plus" /> Add
                                        </button>

                                        <table className=" table table-striped table-birdered" style={ { fontSize: 'smaller' } } >
                                            <thead className=' thead-light'>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>Provider Name</th>
                                                    <th>URL</th>
                                                    <th>USSD</th>
                                                    <th>Type</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '>

                                                { providersList && providersList.map(providers =>

                                                    <tr key={ providers.id }>
                                                        <td className='p-0'>{ this.state.counter++ }</td>
                                                        <td className='p-0'>{ providers.providerId.providerName }</td>
                                                        <td className='p-0'>{ providers.url }</td>
                                                        <td className='p-0'>{ providers.ussd }</td>
                                                        <td className='p-0'>{ providers.typeValue }</td>
                                                        <td className='p-0'>{ providers.statusValue }</td>
                                                        <td className='p-0'>

                                                            <button onClick={ () => this.providersDetail(providers.id,) }
                                                                title={ "Detail" } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" />
                                                            </button>
                                                            <button title={ "Edit" }
                                                                onClick={ () => this.editProviders(providers.id) }
                                                                className="btn btn-warning btn-sm action-buttons ml-1"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                                            <button style={ { marginLeft: "2px" } } title={ "Delete" } disabled={ providers.defultStatus === 0 ? true : false }
                                                                onClick={ () => this.deleteProviders(providers.id) }
                                                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <NotificationContainer />
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>
                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}


export default ProvidersList;