import React, { Component } from 'react'
import API from '../../http-common';
import ReportService from '../../services/Reports/ReportService';
import Header from '../common/Header';
import AsyncSelect from 'react-select/async'
import Menu from '../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import RegionService from '../../services/common/RegionService';
import CountryService from '../../services/common/CountryService';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';


const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

export class SalesReport extends Component {
    constructor(props) {

        super(props)
        pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        this.state = {
            CashReport: [],
            countryList: [],
            regionList: [],
            dateFrom: undefined,
            dateTo: undefined,
            searchValue: undefined,
            countryId: '',
            regionId: '',
            reportType: 'pdf',
            vewierBtn: false,
            reportVewier: false,
            reportDownload: false,
            generateData: 1,
            pageNumber: 1,
            formErrors: {
                reportTypeError: "",
                dateToError: "",
                dateFromError: ""
            },
        }

    }

    getGenerateReport = e => {
        e.preventDefault();
        const { formErrors, reportType, reportDownload } = this.state

        formErrors.reportTypeError = reportType == '' || reportType == null || reportType == undefined ? 'You must make a selection' : this.state.formErrors.reportTypeError;

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'

            }
        }

        if (formValid(this.state.formErrors)) {
            let FilterCashInfo = {
                reportFormat: this.state.reportType,
                customerId: this.state.searchValue && this.state.searchValue.value,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo
            }
            ReportService.generateSaleReport(FilterCashInfo)
                .then(
                    (res) => {
                        if (reportDownload) {
                        var oReq = new XMLHttpRequest();
                        // Server 196.189.116.26/AelafSmartPay
                        var URLToPDF = `${process.env.REACT_APP_SERVER_URL}` + "files/SalesReport.pdf";

                        oReq.open("GET", URLToPDF, true);
                        oReq.withCredentials = false
                        oReq.setRequestHeader('Authorization', localStorage.jwtToken)
                        oReq.setRequestHeader('Access-Control-Allow-Origin', '*')
                        oReq.setRequestHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
                        oReq.responseType = "blob";

                        oReq.onload = function () {
                            // Once the file is downloaded, open a new window with the PDF
                            // Remember to allow the POP-UPS in your browser
                            const file = new Blob([oReq.response], { type: 'application/pdf' });

                            const fileURL = URL.createObjectURL(file);

                            window.open(fileURL, "_blank");
                        };

                        oReq.send();
                        this.setState({
                            isLoading: true
                        });
                    } else {
                        this.setState({
                            reportVewier: !this.state.reportVewier
                        });
                    }
                    },
                    (err) => {
                        NotificationManager.error(typeof err == 'string' ? err : "No data to generate report", 'ASPay', 3000);
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                });




        }

        this.setState({
            formErrors
        });

    }

    componentDidMount() {
        {/*To get list of regions*/ }
        RegionService.getAllRegions()
            .then(response => {
                this.setState({
                    regionList: response.data
                });
                
            })
            .catch(e => {
                
            });

        {/*To get list of countries*/ }
        CountryService.getCountries()
            .then(response => {
                this.setState({
                    countryList: response.data
                });
                
            })
            .catch(e => {
                
            });


    }


    reportType(e) {
        const { formErrors } = this.state
        let selectedReportType = e.target.value
        this.state.reportType = ''
        if (selectedReportType == "----please select---")
            selectedReportType = ""

        formErrors.reportTypeError = selectedReportType == "" ? 'You must make a selection' : "";
        this.setState({
            reportType: selectedReportType,
            formErrors
        });
    }

    country(e) {
        let selectedCountry = e.target.value
        this.state.countryId = ''
        if (selectedCountry == "----please select---")
            selectedCountry = ""

        this.setState({
            countryId: selectedCountry
        });
    }

    region(e) {
        let selectedRegion = e.target.value
        this.state.regionId = ''
        if (selectedRegion == "----please select---")
            selectedRegion = ""

        this.setState({
            countryId: selectedRegion
        });
    }


    reportType(e) {
        const { formErrors } = this.state
        let selectedReportType = e.target.value
        this.state.reportType = ''
        if (selectedReportType == "----please select---")
            selectedReportType = ""

        formErrors.reportTypeError = selectedReportType == "" ? 'You must make a selection' : "";
        this.setState({
            reportType: selectedReportType,
            formErrors
        });
    }

    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) =>
                        response.data
                    )
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data
                        })
                    })
                    .catch((error) => {
                      
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (searchValue) => {
        const { formErrors } = this.state

        if (searchValue) {
            this.setState({
                searchValue,
                formErrors
            });
        }
    }

    onDocumentLoad = ({ numPages }) => {
        if (this.state.generateData === 1)
            this.setState({ numPages });
        this.state.generateData = 2
    }


    gotoPageLast = () => {
        this.setState({
            pageNumber:  this.state.numPages,
        });
    
      }
    
      gotoPageFirst = () => {
        this.setState({
            pageNumber:  1,
        });
      }
    
      privPage = () => {
        let pages = 1
        if (this.state.pageNumber > pages) {
         
          this.setState({
            pageNumber: this.state.pageNumber - pages,
        });
    
        }
      }
    
      nextPage = () => {
        let totalPages = this.state.numPages;
        if (this.state.pageNumber < totalPages) {      
            
          this.setState({
            pageNumber: this.state.pageNumber + 1,
        });
        }
      }

    reportStatus() {
        this.setState({
            reportDownload: false
        })
    }

    downloadStatus() {
        this.state.isLoading = false;
        this.setState({
            reportDownload: true
        })
    }


    render() {
        const { formErrors, countryList, regionList , reportVewier } = this.state

        return (
            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item " >Reports</li>
                                        <li className="breadcrumb-item active" >Sales Report</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" >Display Report Information</div>

                                    <form onSubmit={this.getGenerateReport} noValidate>
                                        <div className='row'>
                                           <div className=" form-group col-4 col-sm-4 col-md-4 col-lg-4  ">
                                                <label htmlFor='Province'>Region:</label>
                                                <select value={this.state.regionId}
                                               onChange={e => this.region(e)}  className="form-control" name='regionId'>
                                                    <option value={null}>----please select---</option>
                                                    {regionList.map(getregion =>
                                                        <option value={getregion.id}>{getregion.region}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                     


                                        <div className='row'>
                                            <div className=" form-group col-4 col-sm-4 col-md-4 col-lg-4 ">
                                                <label htmlFor='dateFrom' >Date From</label>

                                                <input type="date" className="form-control" id='dateFrom'
                                                    onChange={e => this.state.dateFrom = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateFromError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                                )}
                                            </div>
                                            <div className=" form-group col-4 col-sm-4 col-md-4 col-lg-4 ">
                                                <label htmlFor='dateTo'>Date To</label>
                                                <input type="date" className="form-control" id='dateTo'
                                                    onChange={e => this.state.dateTo = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateToError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                                )}

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className=" form-group col-4 col-sm-4 col-md-4 col-lg-4  ">
                                                <label htmlFor='CustomerList'>Customer:</label>
                                                <div >
                                                    <AsyncSelect id='CustomerList'
                                                        placeholder={"Search by name, account no or mobile number"}
                                                        value={this.state.searchValue}
                                                        isClearable={true}
                                                        loadOptions={this.fatchData}
                                                        isLoading={this.state.searchValue === {} &&
                                                            this.state.searchValue === undefined &&
                                                            this.state.searchValue === null ? true : false
                                                        }

                                                        onChange={(e) => {
                                                            this.onSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" form-group col-4 col-sm-4 col-md-4 col-lg-4 ">
                                                <label htmlFor='reportType'>Report Type:</label>
                                                <select className="form-control "
                                                    value={this.state.reportType}
                                                    onChange={e => this.reportType(e)} id='reportType'>
                                                    <option key={null} value={null}>----please select---</option>
                                                    <option key={"html"} value={"html"}>html</option>
                                                    <option key={"pdf"} value={"pdf"}>pdf</option>
                                                </select>
                                                {formErrors.reportTypeError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.reportTypeError}</span>
                                                )}

                                            </div>

                                        </div>
                                        <br />
                                        <div>
                                        <div className="row  d-flex justify-content-left ">
                                            <button type='submit' title={"Download"} 
                                                onClick={() => this.downloadStatus()}
                                                    className="btn btn-sm btn-success primary m-1  float-left">
                                                    <i className="fa fa-download fa-sm" /> Download
                                            </button>

                                            <button type='submit' title={"Preview"} style={{ marginLeft: "10px" }}
                                                    className="btn btn-sm btn-success primary m-1  float-left"
                                                    onClick={() => this.reportStatus()}>
                                                    <i className="fa fa-eye fa-sm" /> {!reportVewier ? `Preview ` : `Hide`}
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                    {reportVewier ?

                                        <div>

                                            <div style={{ width: 600 }}>
                                                <Document
                                                    file={{
                                                        url: `${process.env.REACT_APP_SERVER_URL}` + 'files/SalesReport.pdf',
                                                        httpHeaders: {
                                                            "access-Control-Allow-Origin": '*',
                                                            "access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                                                            "authorization": `${localStorage.jwtToken}`
                                                        }
                                                    }}
                                                    onLoadSuccess={this.onDocumentLoad}>
                                                    <Page pageNumber={this.state.pageNumber} width={600} />
                                                </Document>
                                            </div>

                                            <div className=" float-sm-center">
                                                <nav>
                                                    <span>
                                                        page{''}
                                                        <strong>
                                                            {this.state.pageNumber} of {this.state.numPages}
                                                        </strong>
                                                        {''}
                                                    </span>
                                                    <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()}
                                                        disabled={this.state.pageNumber === 1 ? true : false}>
                                                        <FontAwesomeIcon icon={faBackwardFast} />
                                                    </button>

                                                    <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()}
                                                        disabled={this.state.pageNumber === 1 ? true : false}>
                                                        <FontAwesomeIcon icon={faBackward} />
                                                    </button>

                                                    <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 "
                                                        disabled={true} value={this.state.pageNumber} />

                                                    <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()}
                                                        disabled={this.state.pageNumber === this.state.numPages ? true : false}>
                                                        <FontAwesomeIcon icon={faForward} />
                                                    </button>

                                                    <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()}
                                                        disabled={this.state.pageNumber === this.state.numPages ? true : false} >
                                                        <FontAwesomeIcon icon={faForwardFast} />
                                                    </button>
                                                </nav>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }

}

export default SalesReport