import './App.css';
import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n';
import API from './http-common';
import MerchantList from './components/wallet/merchant/MerchantList';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './components/common/Footer';
import RegionListing from './components/common/region/RegionListing';
import RegionDetail from './components/common/region/RegionDetail';
import AddRegion from './components/common/region/AddRegion';
import EditRegion from './components/common/region/EditRegion';
import TopupCustomers from './components/etopup/customer/TopupCustomers';
import CustomerGoogleMapLocation from './components/etopup/customer/CustomerGoogleMapLocation';
import TopupCustomerVerification from './components/etopup/customer/TopupCustomerVerification';
import RetailerList from './components/etopup/customer/RetailerList';
import DashboardTwo from './components/dashboard/DashboardTwo';
import ViewTopupCustomer from './components/etopup/customer/ViewTopupCustomer';
import EditTopupCustomer from './components/etopup/customer/EditTopupCustomer';
import TopupOrderList from './components/etopup/TopupOrder/TopupOrderList';
import AddTopupOrder from './components/etopup/TopupOrder/AddTopupOrder';
import EditTopupOrder from './components/etopup/TopupOrder/EditTopupOrder';
import TopupTransaction from './components/etopup/TopupTransaction/TopupTransaction';
import DirectTopupHistory from './components/etopup/TopupSalesHistory/DirectTopupHistory';
import DirectTopup from './components/etopup/DirectTopup/DirectTopup';

import Login from './components/common/Login';
import authToken from './components/util/authToken';

import OverDraftList from './components/common/OverDraft/OverDraftList';
import AddOverDraft from './components/common/OverDraft/AddOverDraft';
import OverDraftDetail from './components/common/OverDraft/OverDraftDetail';
import OverdraftSettlementList from './components/common/OverdraftSettlement/OverdraftSettlementList';
import AddMerchant from './components/wallet/merchant/AddMerchant';
import MerchantDetail from './components/wallet/merchant/MerchantDetail';
import EditMerchant from './components/wallet/merchant/EditMerchant';
import WalletTransaction from './components/wallet/RefundWallet/WalletTransaction';
import KioskList from './components/wallet/Kiosk/KioskList';
import AddKiosk from './components/wallet/Kiosk/AddKiosk';
import ViewKiosk from './components/wallet/Kiosk/ViewKiosk';
import UpdateKiosk from './components/wallet/Kiosk/UpdateKiosk';
//import KioskTopupHistoryList from './components/wallet/KioskSalesHistory/KioskTopupHistoryList';
import KioskTrxnHistory from './components/wallet/KioskTrxn/KioskTrxnHistory';
import KioskPayBillHistoryList from './components/wallet/KioskSalesHistory/KioskPayBillHistoryList';
import KioskCashInHistoryList from './components/wallet/KioskSalesHistory/KioskCashInHistoryList';
import AddKioskCustomerAssignment from './components/wallet/KioskCustomerAssignment/AddKioskCustomerAssignment';
import CashCollectorList from './components/wallet/KioskCustomerBalance/CashCollectorList';
import KioskCustomerBalanceList from './components/wallet/KioskCustomerBalance/KioskCustomerBalanceList';
import SubRegionList from './components/wallet/SubRegion/SubRegionList';
import CashInList from './components/etopup/CashIn/CashInList';
import AddCashIn from './components/etopup/CashIn/AddCashIn';
import EditCashIn from './components/etopup/CashIn/EditCashIn';
import CashInDetail from './components/etopup/CashIn/CashInDetail';
import CashOutList from './components/etopup/CashOut/CashOutList';
import AddCashOut from './components/etopup/CashOut/AddCashOut';
import EditCashOut from './components/etopup/CashOut/EditCashOut';
import CashOutDetail from './components/etopup/CashOut/CashOutDetail';
import TopupTransfer from './components/etopup/TopupTransfer/TopupTransfer';
import TopupOrderHistory from './components/etopup/TopupOrder/TopupOrderHistory';
import ProviderCommissionList from './components/etopup/ProviderCommission/ProviderCommissionList';
import AddProviderCommission from './components/etopup/ProviderCommission/AddProviderCommission';
import ViewProviderCommission from './components/etopup/ProviderCommission/ViewProviderCommission';
import EditProviderCommission from './components/etopup/ProviderCommission/EditProviderCommission';
import ViewTopupOrder from './components/etopup/TopupOrder/ViewTopupOrder';
import CreateRollbackTransaction from './components/etopup/RollbackTransaction/CreateRollbackTransaction';
import RollbackRequest from './components/wallet/RollbackRequest/RollbackRequest';
import AddRollbackRequest from './components/wallet/RollbackRequest/AddRollbackRequest';
import EditRollbackRequest from './components/wallet/RollbackRequest/EditRollbackRequest';
import Agents from './components/wallet/merchant/Agents';
import Retailers from './components/wallet/merchant/Retailers';
import Sales from './components/wallet/merchant/Sales';
import ViewCustomer from './components/common/Customer/ViewCustomer';
import CustomerRegistration from './components/common/Customer/CustomerRegistration';
import CustomerVerification from './components/common/Customer/CustomerVerification';
import CashMethodList from './components/common/CashMethod/CashMethodList';
import AddCashMethod from './components/common/CashMethod/AddCashMethod';
import EditCashMethod from './components/common/CashMethod/EditCashMethod';
import BankInfoList from './components/common/BankInfo/BankInfoList';
import AddBankInfo from './components/common/BankInfo/AddBankInfo';
import EditBankInfo from './components/common/BankInfo/EditBankInfo';
import CountryList from './components/common/Country/CountryList';
import AddCountry from './components/common/Country/AddCountry';
import ViewCountry from './components/common/Country/ViewCountry';
import AddCustomerType from './components/common/CustomerType/AddCustomerType';
import CustomerTypeList from './components/common/CustomerType/CustomerTypeList';
import AddCommission from './components/etopup/Commission/AddCommission';
import ViewCommission from './components/etopup/Commission/ViewCommission';
import EditCommission from './components/etopup/Commission/EditCommission';
import AddRefundWallet from './components/wallet/RefundWallet/AddRefundWallet';
import WalletToWalletTransfer from './components/wallet/WalletToWalletTransfer/WalletToWalletTransfer';
import CommissionList from './components/etopup/Commission/CommissionList';
import AddSalesTeamAssignment from './components/etopup/SalesTeamAssignment/AddSalesTeamAssignment';
import SalesTeamAssignmentList from './components/etopup/SalesTeamAssignment/SalesTeamAssignmentList';
import CustomerBankInfoList from './components/common/CustomerBankInfo/CustomerBankInfoList';
import AddCustomerBankInfo from './components/common/CustomerBankInfo/AddCustomerBankInfo';
import EditCustomerBankInfo from './components/common/CustomerBankInfo/EditCustomerBankInfo';
import ProvidersList from './components/common/ProvidersInfo/ProvidersList';
import TelcomBalanceList from './components/etopup/TelcomBalance/TelcomBalanceList';
import AddProviders from './components/common/ProvidersInfo/AddProviders';
import EditProviders from './components/common/ProvidersInfo/EditProviders';
import ProviderDetail from './components/common/ProvidersInfo/ProviderDetail';
import CustomerDealerList from './components/etopup/Dealers/CustomerDealerList';
import WalletToTopupTransfer from './components/wallet/WalletToToupTrsnafer/WalletToTopupTransfer';
import AddCustomerDealer from './components/etopup/Dealers/AddCustomerDealer';
import EditCustomerDealer from './components/etopup/Dealers/EditCustomerDealer';
import { createBrowserHistory } from 'history';
import PermissionList from './components/common/Auth/Permission/PermissionList';
import Signup from './components/common/Auth/User/Signup';
import ChangePassword from './components/common/Auth/User/ChangePassword';
import UsersList from './components/common/Auth/User/UsersList';
import PurchaseOrderList from './components/etopup/PurchaseOrder/PurchaseOrderList';
import AddPurchaseOrder from './components/etopup/PurchaseOrder/AddPurchaseOrder';
import ViewPurchaseOrder from './components/etopup/PurchaseOrder/ViewPurchaseOrder';
import EditPurchaseOrder from './components/etopup/PurchaseOrder/EditPurchaseOrder';
import AddPartnerTarget from './components/etopup/PartnerTarget/AddPartnerTarget';
import ViewPartnerTarget from './components/etopup/PartnerTarget/ViewPartnerTarget';
import EditPartnerTarget from './components/etopup/PartnerTarget/EditPartnerTarget';
import MonthlyTarget from './components/etopup/PartnerTarget/MonthlyTarget';
import ViewUserInfo from './components/common/Auth/User/ViewUserInfo';
import UserRoleList from './components/common/Auth/User/UserRoleList';
import RolesList from './components/common/Auth/Roles/RoleList';
import AccountLedger from './components/Reports/AccountLedger';
import CustomerReport from './components/Reports/CustomerReport';
import DealerReport from './components/Reports/DealerReport';
import SalesReport from './components/Reports/SalesReport';
import TotalDepositsReport from './components/Reports/TotalDepositsReport';
import SaleByRegionReport from './components/Reports/SaleByRegionReport';
import SaleByCustomerReport from './components/Reports/SaleByCustomerReport';
import SaleByCommissionReport from './components/Reports/SaleByCommissionReport';
import VendorsReport from './components/Reports/VendorsReport';
import SalesByOperatorReport from './components/Reports/SalesByOperatorReport';
import SalesBySalesManReport from './components/Reports/SalesBySalesManReport';
import StatusSettingList from './components/common/StatusSetting/StatusSettingList';
import AddStatusSetting from './components/common/StatusSetting/AddStatusSetting';
import EditStatusSetting from './components/common/StatusSetting/EditStatusSetting';
import TypeSettingList from './components/common/TypeSetting/TypeSettingList';
import AddTypeSetting from './components/common/TypeSetting/AddTypeSetting';
import EditTypeSetting from './components/common/TypeSetting/EditTypeSetting';
import ProvidersSettingList from './components/common/ProvidersSetting/ProvidersSettingList';
import AddProvidersSetting from './components/common/ProvidersSetting/AddProvidersSetting';
import EditProvidersSetting from './components/common/ProvidersSetting/EditProvidersSetting';
import LedgerAccountList from './components/accounting and managment/LedgerAccount/LedgerAccountList';
import AddLedgerAccount from './components/accounting and managment/LedgerAccount/AddLedgerAccount';
import EditLedgerAccount from './components/accounting and managment/LedgerAccount/EditLedgerAccount';
import UserActivityReport from './components/Reports/UserActivityReport';
import PostAccountList from './components/accounting and managment/PostAccount/PostAccountList';
import AddPostAccount from './components/accounting and managment/PostAccount/AddPostAccount';
import EditPostAccount from './components/accounting and managment/PostAccount/EditPostAccount';
import { NotificationContainer } from 'react-notifications';
import EditCompanies from './components/companyProfile/EditCompanies';
import ViewCompanies from './components/companyProfile/ViewCompanies';
import Companies from './components/companyProfile/Companies';
import CustomerProfile from './components/common/Customer/CustomerProfile';
import AccountReport from './components/Reports/AccountReport';
import Customers from './components/common/Customer/Customers';
import OpeningBalanceList from './components/etopup/OpeningBalance/OpeningBalanceList';
import AccumulatedBalanceTransfer from './components/etopup/AccumulatedBalanceTransfer/AccumulatedBalanceTransfer';
import ConnectionLost from './components/common/ConnectionLost';
import SendSmsBroadcastMessage from './components/etopup/SMSMessage/SendSmsBroadcastMessage';
import AddSmsNotification from './components/etopup/SMSMessage/AddSmsNotification';
import smsNotificationList from './components/etopup/SMSMessage/smsNotificationList';
import ViewSmsNotification from './components/etopup/SMSMessage/ViewSmsNotification';
import EditSmsNotification from './components/etopup/SMSMessage/EditSmsNotification';
import Dashboard from './components/wallet/Dashboard';
import TopupDashboard from './components/etopup/TopupDashboard';
import IntegrationTypeList from './components/etopup/IntegrationType/IntegrationTypeList';
import AddIntegrationType from './components/etopup/IntegrationType/AddIntegrationType';
import BanksInfoList from './components/companyProfile/BankInfo/BanksInfoList';
import AddBanksInfo from './components/companyProfile/BankInfo/AddBanksInfo';
import ViewBanksInfo from './components/companyProfile/BankInfo/ViewBanksInfo';
import EditBanksInfo from './components/companyProfile/BankInfo/EditBanksInfo';
import PasswordRecovery from './components/common/Auth/User/PasswordRecovery';
import EmailPasswordRecovery from './components/common/Auth/User/EmailPasswordRecovery';
import Confirmation from './components/common/Auth/User/Confirmation';
import ConfigurationList from './components/common/Configuration/ConfigurationList';
import EditCustomer from './components/common/Customer/EditCustomer';
import ErrorBoundary from './components/common/ErrorBoundary';
import { render } from '@testing-library/react';
import Header from './components/common/Header';
import ReactLoading from "react-loading";
import TopupMenu from './components/etopup/TopupMenu';
import Menu from './components/wallet/Menu';
import LoginService from './services/common/LoginService';
import SalesWalletToTopupTransfer from './components/wallet/SalesWalletToToupTrsnafer/SalesWalletToTopupTransfer';
import SalesTopupTransfer from './components/etopup/SalesTopupTransfer/SalesTopupTransfer';
import PrivacyPolicy from './PrivacyPolicy';
import BalanceChcecker from './components/Reports/BalanceChcecker';
import TopupSalesHistoryStatusMonitor from './components/etopup/TopupSalesHistory/TopupSalesHistoryStatusMonitor ';
import ProvidersLogsMonitor from './components/etopup/TopupSalesHistory/ProvidersLogsMonitor';
import TargetCommissionList from './components/etopup/TargetCommission/TargetCommissionList';
import AddTargetCommission from './components/etopup/TargetCommission/AddTargetCommission';
import ViewTargetCommission from './components/etopup/TargetCommission/ViewTargetCommission';
import EditTargetCommission from './components/etopup/TargetCommission/EditTargetCommission';


axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_URL}`

const history = createBrowserHistory();

if (localStorage.jwtToken) {
  authToken(localStorage.jwtToken);
} else {
  if (localStorage.path === '/signup') {
    localStorage.removeItem('path')
    history.push("/signup")
  }
  else if (history.location.pathname === '/privacyPolicy') {
    history.push("/privacyPolicy")
  }
  else if (localStorage.path === '/passwordRecovery') {
    localStorage.removeItem('path')
    history.push("/passwordRecovery")
  }
  else if (localStorage.path === '/emailPasswordRecovery') {
    localStorage.removeItem('path')
    history.push("/emailPasswordRecovery")
  } else if (localStorage.path === '/confirmation') {
    localStorage.removeItem('path')
    history.push("/confirmation")
  } else {

    history.push("/login")
  }



}

var componentSet = {
  'topupCustomerList': TopupCustomers,
  'viewTopupCustomer': ViewTopupCustomer,
  'editTopupCustomer': EditTopupCustomer,
  'verifyTopCustomer': TopupCustomerVerification,
  'ordersList': TopupOrderList,
  'addOrders': AddTopupOrder,
  'editOrders': EditTopupOrder,
  'viewOrders': ViewTopupOrder,
  'orderHistoryList': TopupOrderHistory,
  'transactionMonitoringList': TopupTransaction,
  'topupTransferList': TopupTransfer,
  'salesTopupTransferList': SalesTopupTransfer,
  'directTopupHistoryList': DirectTopupHistory,
  'directTopupList': DirectTopup,
  'failedTransactionList': TopupSalesHistoryStatusMonitor,
  'providersLogsMonitorList': ProvidersLogsMonitor,
  'providersCommissionList': ProviderCommissionList,
  // 'customerGoogleMapLocationList':CustomerGoogleMapLocation,
  'addProvidersCommission': AddProviderCommission,
  'viewProvidersCommission': ViewProviderCommission,
  'editProvidersCommission': EditProviderCommission,
  'purchaseOrderList': PurchaseOrderList,
  'addPurchaseOrder': AddPurchaseOrder,
  'viewPurchaseOrder': ViewPurchaseOrder,
  'editPurchaseOrder': EditPurchaseOrder,
  'targetCommissionList': TargetCommissionList,
  'addTargetCommission': AddTargetCommission,
  'viewTargetCommission': ViewTargetCommission,
  'editTargetCommission': EditTargetCommission,  
  'addPartnerTarget': AddPartnerTarget,
  'partnerTargetList': MonthlyTarget,
  'viewPartnerTarget': ViewPartnerTarget,
  'editPartnerTarget': EditPartnerTarget,
  'rollbackManagementList': CreateRollbackTransaction,
  'rollbackRequestList': RollbackRequest,
  'addRollbackRequest': AddRollbackRequest,
  'editRollbackRequest': EditRollbackRequest,
  'retailerList': RetailerList,
  'login': Login,
  'merchantList': MerchantList,
  'addMerchant': AddMerchant,
  'viewMerchant': MerchantDetail,
  'editMerchant': EditMerchant,
  'subRegionList': SubRegionList,
  'rechargeTopupList': WalletToTopupTransfer,
  'rechargeSalesTopupList': SalesWalletToTopupTransfer,
  'walletTrxnList': WalletTransaction,
  'kioskList': KioskList,
  'addKiosk': AddKiosk,
  'viewKiosk': ViewKiosk,
  'editKiosk': UpdateKiosk,
  'addKioskCustomerAssignmentList': AddKioskCustomerAssignment,
  'cashCollectorList': CashCollectorList,
  'kioskCustomerBalanceList': KioskCustomerBalanceList,
  'kioskSalesHistoryList': KioskTrxnHistory,
  'kioskPayBillHistoryList': KioskPayBillHistoryList,
  'kioskCashInHistoryList': KioskCashInHistoryList,
  'refundManagementList': AddRefundWallet,
  'walletTransferList': WalletToWalletTransfer,
  'agents': Agents,
  'addCustomer': CustomerRegistration,
  'retailers': Retailers,
  'salesList': Sales,
  'customerList': Customers,
  // 'viewCustomerLocation':CustomerGoogleMapLocation,
  'viewCustomer': ViewCustomer,
  'countryList': CountryList,
  'addCountry': AddCountry,
  'viewCountry': ViewCountry,
  'addCustomerTypes': AddCustomerType,
  'customerTypesList': CustomerTypeList,
  'addDealersCommission': AddCommission,
  'viewDealersCommission': ViewCommission,
  'editDealersCommission': EditCommission,
  'dealersCommissionList': CommissionList,
  'updateProfile': CustomerProfile,
  'editCustomer': EditCustomer,
  'verifyCustomer': CustomerVerification,
  'companyProfileList': Companies,
  'viewCompanyProfile': ViewCompanies,
  'editCompanyProfile': EditCompanies,
  'provinceList': RegionListing,
  'Viewprovince': RegionDetail,
  'integrationTypeList': IntegrationTypeList,
  'addIntegrationType':AddIntegrationType,
  'addProvince': AddRegion,
  'editProvince': EditRegion,
  'overdraftList': OverDraftList,
  'addOverdraft': AddOverDraft,
  'viewOverdraft': OverDraftDetail,
  'settlementList': OverdraftSettlementList,
  'cashInList': CashInList,
  'addCashIn': AddCashIn,
  'editCashIn': EditCashIn,
  'viewCashIn': CashInDetail,
  'cashOutList': CashOutList,
  'addCashOut': AddCashOut,
  'editCashOut': EditCashOut,
  'viewCashOut': CashOutDetail,
  'cashMethodList': CashMethodList,
  'addCashMethod': AddCashMethod,
  'addSmsBroadcastMessageList': SendSmsBroadcastMessage,
  'addSmsNotification': AddSmsNotification,
  'viewSmsNotification': ViewSmsNotification,
  'smsNotificationList': smsNotificationList,
  'editSmsNotification': EditSmsNotification,
  'editCashMethod': EditCashMethod,
  'typeSettingList': TypeSettingList,
  'addTypeSetting': AddTypeSetting,
  'editTypeSetting': EditTypeSetting,
  'statusSettingList': StatusSettingList,
  'addStatusSetting': AddStatusSetting,
  'editStatusSetting': EditStatusSetting,
  'providerSettingList': ProvidersSettingList,
  'addProviderSetting': AddProvidersSetting,
  'editProviderSetting': EditProvidersSetting,
  'bankInfoList': BankInfoList,
  'addBankInfo': AddBankInfo,
  'editBankInfo': EditBankInfo,
  'customerBankInfoList': CustomerBankInfoList,
  'addCustomerBankInfo': AddCustomerBankInfo,
  'editCustomerBankInfo': EditCustomerBankInfo,
  'companyBanksInfoList': BanksInfoList,
  'addCompanyBanksInfo': AddBanksInfo,
  'viewCompanyBanksInfo': ViewBanksInfo,
  'editCompanyBanksInfo': EditBanksInfo,
  'providersList': ProvidersList,
  'realBalanceList':TelcomBalanceList,
  'addProviders': AddProviders,
  'editProviders': EditProviders,
  'viewProviders': ProviderDetail,
  'dealersList': CustomerDealerList,
  'addDealers': AddCustomerDealer,
  'editDealers': EditCustomerDealer,
  'permissionsList': PermissionList,
  'accountList': LedgerAccountList,
  'addAccount': AddLedgerAccount,
  'editAccount': EditLedgerAccount,
  'ledgerTranList': PostAccountList,
  'addLedgerTran': AddPostAccount,
  'editLedgerTran': EditPostAccount,
  'addSalesTeamAssignment': AddSalesTeamAssignment,
  'salesTeamAssignmentList': SalesTeamAssignmentList,
  'signup': Signup,
  'ChangePassword': ChangePassword,
  'usersList': UsersList,
  'viewUsers': ViewUserInfo,
  'userRoleList': UserRoleList,
  'rolesList': RolesList,
  'totalDepositsReportList': TotalDepositsReport,
  'transactionReportList': AccountLedger,
  'customerReportList': CustomerReport,
  'dealersReportList': DealerReport,
  'salesReportList': SalesReport,
  'salesByRegionList': SaleByRegionReport,
  'salesByCustomerList': SaleByCustomerReport,
  'salesByCommissionList': SaleByCommissionReport,
  'vendorsReportList': VendorsReport,
  'salesByOperatorList': SalesByOperatorReport,
  'salesBySalesManList': SalesBySalesManReport,
  'activityReportList': UserActivityReport,
  'accountReportList': AccountReport,
  'openingBalanceList': OpeningBalanceList,
  'accumulatedBalanceList': AccumulatedBalanceTransfer,
  'balanceChceckerList': BalanceChcecker
}



function App() {
  const [count, setCount] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState([]);
  const [add, setAdd] = React.useState([]);
  const [edit, setEdit] = React.useState([]);
  const [approve, setApprove] = React.useState([]);
  const [verify, setVerify] = React.useState([]);

  React.useEffect(() => {
    function fetchMenus() {
      setIsLoading(false);
      if (localStorage.userId !== undefined) { 
        API.get("/api/v1/getRoutingInfo/" + localStorage.userId)
        .then(
          (response) => {
            setCount(response.data);
          },
          (error) => {
            let status = error.response.data.status;
            if (status == undefined) {
              localStorage.removeItem('jwtToken')
              localStorage.removeItem('userId')
              localStorage.removeItem('userIdentity')
              localStorage.removeItem('customerIdentity')
              localStorage.removeItem('userName')
              localStorage.removeItem('isProfileUpdated')
              localStorage.removeItem('type')
              localStorage.removeItem('status')              
              localStorage.removeItem('mobileNo')
              localStorage.removeItem('walletMenu')
              localStorage.clear();
              history.replace('/');
              window.location.reload()
            }
  
            if (status == '401') {
              localStorage.removeItem('jwtToken')
              localStorage.removeItem('userId')
              localStorage.removeItem('userIdentity')
              localStorage.removeItem('customerIdentity')
              localStorage.removeItem('userName')
              localStorage.removeItem('isProfileUpdated')
              localStorage.removeItem('type')
              localStorage.removeItem('status')   
              localStorage.removeItem('mobileNo')
              localStorage.removeItem('walletMenu')
              localStorage.clear();
              history.replace('/');
              window.location.reload();
            }
  
  
          })
        .catch(e => {
          localStorage.removeItem('jwtToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('userIdentity')
          localStorage.removeItem('customerIdentity')
          localStorage.removeItem('userName')
          localStorage.removeItem('isProfileUpdated')
          localStorage.removeItem('type')
          localStorage.removeItem('status')   
          localStorage.removeItem('mobileNo')
          localStorage.removeItem('walletMenu')
          localStorage.clear();
          history.replace('/');
          window.location.reload();
          
        }); 
       
       
      }
    }
    fetchMenus();
    setIsLoading(true);
    // const handleBeforeUnload = (event) => {
    //   // Remove data from localStorage here
    //   if (!event.persisted) {
    //       localStorage.removeItem('jwtToken')
    //       localStorage.removeItem('userId')
    //       localStorage.removeItem('userIdentity')
    //       localStorage.removeItem('customerIdentity')
    //       localStorage.removeItem('userName')
    //       localStorage.removeItem('isProfileUpdated')
    //       localStorage.removeItem('type')
    //       localStorage.removeItem('mobileNo')
    //       localStorage.removeItem('walletMenu')
    //   }
    // };

    // window.addEventListener('unload', handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('unload', handleBeforeUnload);
    // };
  }, [])


  return (

    <div>
      {!isLoading ?
        <div>
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
        :
        <>

          <BrowserRouter history={history} >

            <div>
            {localStorage.walletMenu === "true" || localStorage.walletMenu === "false" ?
              <Header />
              :
              <></>
             }
              
              {localStorage.walletMenu === "true" ?
                <Menu />
                :
                <></>
               }

              {localStorage.walletMenu === "false" ?
                <TopupMenu />
                :
                <></>
              }
              <NotificationContainer />
              <Switch>
                <Route exact path="/" key={'DashboardTwo'} component={DashboardTwo} />
                <Route path="/signup" key={'Signup'} component={Signup} />
                <Route path="/privacyPolicy" key={'PrivacyPolicy'} component={PrivacyPolicy} />                
                <Route path="/ChangePassword" key={'ChangePassword'} component={ChangePassword} />
                <Route path="/login" key={'Login'} component={Login} />
                <Route path="/etopupDashboard" key={'etopupDashboard'} component={TopupDashboard} />
                <Route path="/updateProfile" key={'updateProfile'} component={CustomerProfile} />
                <Route path="/viewUsers" key={'viewUsers'} component={ViewUserInfo} />
                <Route path="/eWalletDashboard" key={'eWalletDashboard'} component={Dashboard} />
                <Route path="/passwordRecovery" key={'passwordRecovery'} component={PasswordRecovery} />
                <Route path="/emailPasswordRecovery" key={'emailPasswordRecovery'} component={EmailPasswordRecovery} />
                <Route path="/confirmation" key={'confirmation'} component={Confirmation} />
                <Route path="/configurationList" key={'ConfigurationList'} component={ConfigurationList} />
                <Route path="/customerGoogleMapLocationList" key={'customerGoogleMapLocationList'} component={CustomerGoogleMapLocation} />


                {count.map(
                  (submenu) =>
                    <Route exact key={submenu.path} path={"/" + submenu.path} component={componentSet[submenu.path]} />

                )}
                <Route from="*" component={ConnectionLost}></Route>

              </Switch>
              <Footer />
            </div>
          </BrowserRouter>
        </>
      }
    </div>
  )












}



export default App;
