import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import TopupOrderService from '../../../services/etopup/TopupOrderService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import Dropzone from "react-dropzone";
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^\d{1,10}(\.\d{1,4})?$/); 

class AddTopupOrder extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            isLoading: false,
            attachment: '',
            providerId: '',
            providersList: [],
            selectOption: null,
            dealerCustomerId:null,
            customerType:null,
            pageNo: 1,
            customerId: '',
            createdBy: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                amountError: "",
                selectOptionError: ""
            },
        }
        this.onDrop = this.onDrop.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);

    }
    onDrop(files) {

        if (files.length > 0) {
          this.setState({ selectedFiles: files });
          this.setState({ currentFile: this.state.selectedFiles[0] });
        }
      }
    
    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;

            default:
                break;

        }

        this.setState({
            formErrors, [ name ]: value
        })
    }


    fatchData = (inputValue, callback) => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValueForDealer/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                      
                    })
                    .catch((error) => {
                       
                    })
            })
        }else {
            callback([])
        }
    }

    onSearchChange = (selectOption) => {
        if (selectOption) {
            this.setState({
                selectOption
            });

        }
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId,
                    customerType:response.data.type
                });
            })
            .catch(e => {
                
            });

        {/*To get list of providers*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/ordersList');
    }

    handleSubmit(e) {   //
         e.preventDefault();
        const { amount, selectOption, formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
        if( localStorage.type ==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`){
        formErrors.selectOptionError = selectOption == '' || selectOption == null || selectOption == undefined ? 'Please select the dealer' : this.state.formErrors.selectOptionError;
        }
       
        if( localStorage.type!=`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) this.state.dealerCustomerId=this.state.customerId;
        if( (localStorage.type==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`) && (this.state.selectOption && this.state.selectOption.value )) this.state.dealerCustomerId=this.state.selectOption.value;
        
        if (formValid(this.state.formErrors)) {

            var data = new FormData();
            data.append("dealerCustomerId", this.state.dealerCustomerId);
            data.append("amount", this.state.amount);
            data.append("userId", localStorage.userId);
            data.append("createdBy", localStorage.customerIdentity);
            data.append("attachment", this.state.currentFile);

            
            this.setState({ isLoading: true})
            TopupOrderService.addTopupOrder(data)
                .then(response => {
                    NotificationManager.success("Order is successfull !! ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({ isLoading: false})
                    this.Cancel()
                })
                .catch(function (err) {

                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    //this.setState({ isLoading: false})
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({ formErrors })
        }

    };


    render() {
        const { selectedFiles,customerType,providersList, formErrors } = this.state;
        
        
        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">eTop Up Order</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add Order </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                     { customerType ==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`  &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Dealer Name:</label>
                                                            <AsyncSelect id='CustomerList'
                                                                required
                                                                placeholder={ "Search by account or mobile" }
                                                                value={ this.state.selectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fatchData }
                                                                isLoading={ this.state.selectOption === {} &&
                                                                    this.state.selectOption === undefined &&
                                                                    this.state.selectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.selectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.selectOptionError }</span>
                                                            ) }
                                                        </div>
                                                      }

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'

                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card my-4 border-radius-0" >
                                            <div className="card-header border-radius-0 bg-light">
                                            <h6 class="title " >File Attachment </h6>
                                            </div>
                                            <div className="card-body">
                                            <div className='row'>

                                                <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                <label htmlFor='profilePic'>Receipt:</label>
                                                {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleAttachmentChange} /> */}
                                                {/* <FileUploader type="file"onDrop={this.onDrop} label="Drag and Drop file Or Select " classes="Drag and Drop" maxSize="2mb" onDrop={(file) => console.log(file)} onSelect={(file) => console.log(file)} multiple={false} name="attachment" /> */}

                                                <Dropzone onDrop={this.onDrop}
                                                    multiple={false}>
                                                    {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div {...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" })} style={{ border: "thin dashed black" }}>
                                                        <input {...getInputProps()} />
                                                        {selectedFiles && selectedFiles[0].name ? (
                                                            <div className="selected-file border-radius-1">
                                                            {selectedFiles && selectedFiles[0].name}
                                                            </div>
                                                        ) : (
                                                            "Drag and drop file here, or click to select file"
                                                        )}
                                                        </div>
                                                        <aside className="selected-file-wrapper">
                                                        </aside>
                                                    </section>
                                                    )}
                                                </Dropzone>

                                                </div>

                                            </div>
                                            </div>
                                        </div>

                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                        </form>
                                 </>}
                            />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddTopupOrder);
