import React, { Component } from 'react'
import Menu from '../../../components/wallet/Menu';
import RegionService from '../../../services/common/RegionService';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";
import LoadingPopup from '../../wallet/LoadingPopup';


export default class EditRegion extends Component {
    constructor (props) {
        super(props);
        this.state = {
            id: this.props.location.state.id,
            isLoading: false,
            isLoadingPop: false,
            regionData: {
                region: '',
                majorCity: '',
                regionZone: '',
                remark: ''
            },
        };
        this.onChangeRegion = this.onChangeRegion.bind(this);
        this.onChangeMajorCity = this.onChangeMajorCity.bind(this);
        this.onChangeRegionZone = this.onChangeRegionZone.bind(this);
        this.onChangeRemark = this.onChangeRemark.bind(this);
    }
    componentDidMount() {

        RegionService.getRegionById(this.state.id, localStorage.userId)
            .then(response => {
                this.setState({
                    isLoading: true,
                    regionData: response.data
                });
            })
            .catch(e => {
                
                this.setState({ isLoading: true });
            });
    }

    onChangeRegion(e) {
        const region = e.target.value;

        this.setState(function (prevState) {
            return {
                regionData: {
                    ...prevState.regionData,
                    region: region
                }
            };
        });
    }

    onChangeMajorCity(e) {
        const majorCity = e.target.value;

        this.setState(function (prevState) {
            return {
                regionData: {
                    ...prevState.regionData,
                    majorCity: majorCity
                }
            };
        });
    }

    onChangeRegionZone(e) {
        const regionZone = e.target.value;

        this.setState(function (prevState) {
            return {
                regionData: {
                    ...prevState.regionData,
                    regionZone: regionZone
                }
            };
        });
    }
    onChangeRemark(e) {
        const remark = e.target.value;

        this.setState(function (prevState) {
            return {
                regionData: {
                    ...prevState.regionData,
                    remark: remark
                }
            };
        });
    }



    Cancel = e => {
        this.props.history.push('/provinceList');
    }

    handleSubmit = e => {
        e.preventDefault();
        var data = new FormData();
        data.append("region", this.state.regionData.region);
        data.append("majorCity", this.state.regionData.majorCity);
        data.append("regionZone", this.state.regionData.regionZone);
        data.append("remark", this.state.regionData.remark);
        data.append("userId", localStorage.userId);


        RegionService.updateRegionById(this.state.id, data)
            .then(
                (response) => {

                NotificationManager.success('Updated Successfully',  `${process.env.REACT_APP_MSG_TITLE}`, 1000)
                 this.Cancel()
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);

            });

    }


    render() {

        const { regionData, isLoading } = this.state;

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Update Province</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>
                                        {/*
                                    <div class="modal-header">
                                        <h4 class="modal-title" >
                                        Agent Registration
                                        </h4>
                                    </div> */}
                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Basic Information </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='FullName'>Region Name:</label>
                                                            <input type="text" className="form-control " placeholder="Full Name" value={ regionData.region } onChange={ this.onChangeRegion } required="required" />
                                                        </div>

                                                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='companyName'>Remark:</label>
                                                            <input type="text" className="form-control " id='companyName'
                                                                placeholder="Optional"
                                                                value={ regionData.remark } onChange={ this.onChangeRemark } />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/*Basic Information*/ }
                                            <div class="btn-group d-flex justify-content-center clear-fix">
                                                <button disabled={this.state.isLoadingPop} type="submit" class="btn btn-success mr-5 ml-5 mt-4 pl-2" onClick={ () => this.editRegion() }>Update</button>
                                                <button type="submit" class="btn btn-warning mr-5 mt-4 px-2" onClick={ () => this.Cancel() } >Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                                <div >


                                </div>

                                {this.state.isLoadingPop && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                </>}
                                />}
                                            {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
