import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import NumberFormat from 'react-number-format';
import CustomerBankInfoService from '../../../services/common/CustomerBankInfoService';

class BanksInfoList extends Component {

    constructor (props) {

        super(props)
        this.state = {
            isLoading: false,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            topupStatus: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            type: LOCALES.AGENT,

            selectOption: '',
            customerList: "",
            customerBankInfoList: [],
            bankInfoId: '',
            searchValue: null,
            isLoading: false,
      
            fromDate: '',
            toDate: '',
  
            counter: 1
        }
        this.clearValue = this.clearValue.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.banksInfoFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.banksInfoFilter()
        this.render()

    }
    gotoPageFirst = () => {   
        this.state.pageNo = 1   
        this.banksInfoFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.banksInfoFilter()
            this.render()

        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.banksInfoFilter()
            this.render()
        }
    }
    clearValue() {
        // should not reload
        this.state.kioskId = null
        this.state.serialNumber = null
        this.state.status = null
        this.state.latitude = null
        this.state.longitude = null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.banksInfoFilter()
        }
    }

    searchBanksInfo(e) { 
        this.banksInfoFilter()
    }

    banksInfoFilter() {
        let FilterValue = {
            customerId: localStorage.customerIdentity,
            bankInfoId: this.state.bankInfoId,
            dateFrom: this.state.fromDate,
            dateTo: this.state.toDate,
            userId: localStorage.userId
          }
          
        CustomerBankInfoService.getCompanyBankInfosBasedOnFilter(FilterValue,this.state.pageNo, this.state.pageSize)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    customerBankInfoList: data.customerBankClone,
                    totalPage: data.totalPages,
                    totalelement: data.totalelement,
                    pageNo: data.pageNumber + 1
                });
                this.setState({ isLoading: true })
          
                });
           }
    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    // Delete customer
    async deleteCompanyBankInfo(id) {

        if (window.confirm("Are you sure you want to delete  ?")) {
            try {
                const response = await CustomerBankInfoService.deleteCustomerBankInfo(id,localStorage.userId);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
                this.banksInfoFilter()
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);
            }
        }
    }
    componentDidMount() {
        this.banksInfoFilter()
    }
 
    render() {
        const { date,customerBankInfoList, isLoading, pageNo, totalPage, totalelement, personalInfo } = this.state;
        this.state.counter = 1;
        this.state.counter = pageNo
        if (!this.state.isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Company Bank Info</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={{ fontFamily: 'normal' }}>Agents</h3> */ }
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title">Bank Info List </div>
                                        <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                                            <div className="row" hidden={ this.state.isExpand }>
                                                <div className="col-md-4">

                                                    <div>
                                                        <label htmlFor='serialNumber' > Kiosk Id</label>
                                                        <input type="text" className="form-control" id='serialNumber'
                                                            onChange={ e => this.state.kioskId = e.target.value }
                                                            placeholder="Serial No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">

                                                    <div>
                                                        <label htmlFor='serialNumber' > Serial No</label>
                                                        <input type="text" className="form-control" id='serialNumber'
                                                            onChange={ e => this.state.serialNumber = e.target.value }
                                                            placeholder="Serial No" />
                                                    </div>
                                                </div>
                                      
                                                <div className="col-md-4">
                                                    <label htmlFor='dateFrom' >Status</label>
                                                    <div className="w-90 pull-left">

                                                        <select id='status' onChange={ e => this.state.status = e.target.value } className="form-control" >
                                                            <option value=""> Select status</option>
                                                            <option value="Active"> Active</option>
                                                            <option value="Inactive"> Inactive</option>
                                                            <option value="Warning"> Warning</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <br />

                                            <div>
                                                <button title={ "Search" }
                                                    className="btn btn-sm btn-success primary m-1 float-right"
                                                    onClick={ () => this.searchBanksInfo() }>Search</button>
                                                <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                                                    className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                            </div>
                                        </form>
                                        <div style={ { clear: 'both' } }></div>
                                        <br />
                                        <Link to={ { pathname: '/addCompanyBanksInfo', state: { type: "Agent", formName: "order Registration" } } }>
                                            <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                                                <i className="fas fa-plus" /> Add
                                            </button>
                                        </Link>
                                        {/* <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                                            className="btn btn-sm btn-success primary float-left mb-2"
                                            onClick={ () => this.filterToggle() }>
                                            <FontAwesomeIcon icon={ faFilter } />
                                            Filter
                                        </button> */}
                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } }  >
                                            <thead className='thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                     <th>No.</th>  
                                                    <th>Bank Name</th>
                                                    <th>Bank Account</th>
                                                    <th>Balance</th> 
                                                    {/* <th>Date</th> */}
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                { customerBankInfoList !== undefined && customerBankInfoList.map(list =>

                                                    <tr>
                                                        <td>{ this.state.counter++}</td> 
                                                        <td>{ list.bankName }</td>
                                                        <td>{ list.bankAccount }</td>
                                                        <td>
                                                        <NumberFormat value={parseFloat(list.balance).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />
                                                           </td>
                                                        {/* <td>{ list.createdAt }</td>  */}
                                                        {/* <td>{ list.location }</td> */}
                                                        <td> 

                                                            <Link to={ { pathname: '/viewCompanyBanksInfo', state: { id: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-info btn-sm action-buttons"><i className="nav-icon fa fa-eye fa-sm" title="View detail" /> </button>
                                                            </Link>
                                                            <Link to={ { pathname: '/editCompanyBanksInfo', state: { id: list.id } } }>
                                                                <button style={ { marginLeft: "2px" } } className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" title="Edit Info" /> </button>
                                                            </Link>
                                                            
                                                             <button style={ { marginLeft: "2px" } } onClick={() => this.deleteCompanyBankInfo(list.id)} className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fas fa-trash fa-sm" title="Delete Info" /> </button> 
                                                            
                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{ '' }
                                                <strong>
                                                    { pageNo } of { totalPage }
                                                </strong>
                                                { '' }
                                            </span>
                                            <span>
                                                | Go To Page : { '' }
                                                <input type='number' defaultValue={ pageNo }
                                                    onChange={ (e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    } } />
                                            </span>
                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackwardFast } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                                                <FontAwesomeIcon icon={ faBackward } />
                                            </button>

                                            <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                                                <FontAwesomeIcon icon={ faForward } />
                                            </button>

                                            <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                                                <FontAwesomeIcon icon={ faForwardFast } />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div >

            )
        }
    }
}
export default BanksInfoList