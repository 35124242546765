import React, { Component } from 'react';
import CashMethodService from '../../../services/etopup/CashMethodService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";
import LoadingPopup from '../../wallet/LoadingPopup';


const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{3,50}$/u)
const descriptionRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,500}$/u)

class EditCashMethod extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingPop: false,
      id: this.props.location.state.id,
      cashMethodData: {
        name: "",
        description: ""
      },
      formErrors: {
        nameError: "",
        descriptionError: "",
        customerError: ""
      },
    }

  }

  componentDidMount() {
    CashMethodService.getCashMethod(this.state.id)
      .then(response => {
        this.setState({
          isLoading: true,
          cashMethodData: response.data
        });
        

      })
      .catch(e => {
        
        this.setState({ isLoading: true });
      });
  }



  cancel() {
    this.props.history.push('/cashMethodist')
  }

  handelChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'name':
        this.state.cashMethodData.name = value
        formErrors.nameError =
          value.length == 0 || !nameRegEx.test(value) ? name + " value sholud only alphabetic and (/_) characters with min 3 and max 50" : ""
        break;
      case 'description':
        this.state.cashMethodData.description = value
        formErrors.descriptionError =
          !descriptionRegEx.test(value) ? name + " value sholud only alphanumeric and (_ -) characters with max 500" : ""
        break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }


  cancel() {
    this.props.history.push('/cashMethodList')
  }

  updateCustomer() {
    const { cashMethodData, selectOption, formErrors } = this.state
    formErrors.nameError = cashMethodData.name == '' || cashMethodData.name == null ? "Name is Required" : this.state.formErrors.nameError;
    if (formValid(this.state.formErrors)) {
      let cashMethodData = {
        name: this.state.cashMethodData.name,
        description: this.state.cashMethodData.description
      }

      this.setState({ isLoadingPop: true})
      CashMethodService.updateCashMethod(this.state.id, cashMethodData)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            this.cancel()
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLEWARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  }


  render() {
    const { cashMethodData, formErrors, isLoading } = this.state

    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <Menu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div >
          {/* <Header />
          <Menu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Edit Cash Method Information</li>

                    </ol>
                  </div>

                </div>
                <div >
                  <div className="modal-body">

                    <div className='col-1'></div>
                    <div className='col-7 bg-light p-4'>

                      <div class="modal-header">
                        <h4 class="modal-title" >
                          Edit Cash Method Information
                        </h4>
                      </div>

                      {/*<h2>Register</h2> */ }

                      <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                        <label htmlFor='Name'>Name:</label>
                        <input type="text" className="form-control" id='Name'
                          value={ cashMethodData.name } name="name"
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Name" required="required" />
                        { formErrors.nameError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.nameError }</span>
                        ) }
                      </div>



                      <div className="form-group col-15 col-sm-8 col-md-8 col-lg-8">
                        <label htmlFor='Description'>Description:</label>
                        <textarea type="" className="form-control" id='Description'
                          value={ cashMethodData.description } name='description'
                          onChange={ this.handelChange } onBlur={ this.handelChange }
                          placeholder="Description" />
                        { formErrors.descriptionError.length > 0 && (
                          <span style={ { color: "#F61C04" } }>{ formErrors.descriptionError }</span>
                        ) }
                      </div>


                      <div className="row  d-flex justify-content-left ">
                        <button disabled={this.state.isLoadingPop} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }
                          onClick={ () => this.updateCustomer() }>Update</button>
                        <button type="submit" onClick={ this.cancel.bind(this) }
                          className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                      </div>
                    </div>

                  </div>
                </div>
                <NotificationContainer />
                {this.state.isLoadingPop && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>

            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}


export default EditCashMethod;