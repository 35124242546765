import React, { Component } from 'react'
import TopupMenu from '../../../components/etopup/TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import PartnerTargetService from '../../../services/etopup/PartnerTargetService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker from 'react-datepicker'
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class AddPartnerTarget extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            month: new Date(),
            target: 0,
            providerId: '',
            providersList: [],
            getPartnerTarget: [],
            isTargetExist: null,
            isLoading: false,
            pageNo: 1,
            customerId: '',
            pageSize: LOCALES.PAGE_SIZE,
            formErrors: {
                targetError: "",
                providerIdError:""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
   

    handleChange(value) {
        this.setState({ month: value });
    }
  componentDidMount() {

        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
            })
            .catch(e => { 
            });
    }

    Cancel = (e) => {
        this.props.history.push('/partnerTargetList');
    }
    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target

        let formErrors = this.state.formErrors;
        switch (name) {
            case 'target':
                formErrors.targetError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;

            default:
                break;

        }
 
                this.setState({
                    formErrors, [ name ]: value
                })
    }

    handleSubmit(e) {
        e.preventDefault();
        const { target, providerId, formErrors } = this.state
        formErrors.targetError = this.state.target === "" || this.state.target === null || this.state.target === undefined  || this.state.target === 0 ? "Target is Required" : this.state.formErrors.targetError;
        formErrors.providerIdError = this.state.providerId === "" || this.state.providerId === null || this.state.providerId === undefined ? "Please select provider" : this.state.formErrors.providerIdError;
      
     if (formValid(this.state.formErrors)) {
        var data = new FormData();
        data.append("target", this.state.target);
        data.append("providerId", this.state.providerId);
        data.append("month", moment(this.state.month).format('yyyy-MM-DD'));

        data.append("userId", localStorage.userId);

        this.setState({ isLoading: true})  
        {/*To get list of regions*/ }
        PartnerTargetService.getPartnerTargetByMonthAndProviderId(moment(this.state.month).format('yyyy-MM-DD'), this.state.providerId, localStorage.userId)
            .then(response => {
                this.setState({
                    getPartnerTarget: response.data
                });
                
                if (this.state.getPartnerTarget) {
                    if (window.confirm("Target already exists ! Are you sure you want to update it?")) {
                        PartnerTargetService.createPartnerTarget(data)
                            .then(response => {
                                NotificationManager.success("Successfully created ",`${process.env.REACT_APP_MSG_TITLE}`, 3000);
                                this.props.history.push('/partnerTargetList')
                                this.setState({ isLoading: false})  
                            })
                            .catch(function (err) {
                               
                                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                                    this.setState({ isLoading: false})  
                            });
                    }
                }
                else {
                    PartnerTargetService.createPartnerTarget(data)
                        .then(response => {
                            NotificationManager.success("Successfully created ", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                            this.setState({ isLoading: false})  
                            this.props.history.push('/partnerTargetList')
                           
                        })
                        .catch(function (err) {
                            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                            this.setState({ isLoading: false})  
                        });
                }

            })
            .catch(e => {
                this.setState({ isLoading: false})  
            });
        } 
        else {
          //  NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
              formErrors
            })
          }
    };


 
    render() {
        const { providersList,formErrors, month } = this.state;

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Partner Target</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add Partner Target </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Provider Name:</label>
                                                            <select onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" name='providerId'>
                                                                <option value=""> Choose provider</option>
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                            { formErrors.providerIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                                                            ) }
                                                        </div>

                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Target:</label>
                                                            <input type="text" className="form-control " id='amount' name='target'
                                                              
                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.targetError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.targetError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Month:</label>
                                                            {/* <input type="date" className="form-control " onChange={ this.handelInputChange } onBlur={ this.handelInputChange } name='month' placeholder="Select month" /> */ }
                                                            <DatePicker
                                                                shouldCloseOnSelect={ true }
                                                                onChange={ (event) => this.handleChange(event) }
                                                                selected={ this.state.month }
                                                                dateFormat="yyyy-MM"
                                                                showMonthYearPicker
                                                            />
                                                            {/* { formErrors.commissionRateIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.commissionRateIdError }</span>
                                                            ) } */}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                 </>}
                             />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddPartnerTarget);
