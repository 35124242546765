import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import PurchaseOrderService from '../../../services/etopup/PurchaseOrderService';
import { LOCALES } from '../../common/Constant';
import Dropzone from "react-dropzone";
import ReactLoading from "react-loading"; 
import LoadingPopup from '../../wallet/LoadingPopup';
import NumberFormat from 'react-number-format';


const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

class AddPurchaseOrder extends React.Component {

    constructor (props) {
        super(props);
        this.state = {

            providerId: '',
            amount: '',
            commissionId: '',

            totalPurchase: '',
            commissionAmount: '',
            isLoading: false,
            selectedFile: undefined,
            attachment: '',
            providersList: [],
            commissionList: [],
            providerCommission: [],
            pageNo: 1,
            customerId: '',
            pageSize: LOCALES.PAGE_SIZE,
            isTopup: 0,
            formErrors: {
                providerIdError: "",
                amountError: "",
                commissionRateIdError: ""
            },
        }
        this.onDrop = this.onDrop.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
       
    }
   
    onDrop(files) {

        if (files.length > 0) {
            this.setState({ selectedFile: files });
            this.setState({ attachment: this.state.selectedFile[ 0 ] });
        }
    }

    componentDidMount() {

        {/*To get list of regions*/ }
        ProviderService.getAllProviders(localStorage.userId)
            .then(response => {
                this.setState({
                    providersList: response.data
                });
                
            })
            .catch(e => {
                
            });

        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId
                });
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/purchaseOrderList');
    }
    handleSubmit(e) {
        e.preventDefault();
        const { providerId, amount, commissionRateId, formErrors } = this.state
        formErrors.providerIdError = this.state.providerId === "" || this.state.providerId === null || this.state.providerId === undefined ? "Provider  is Required" : "";
        formErrors.amountError = this.state.amount === "" || this.state.amount === null || this.state.amount === undefined ? "Amount is Required" : "";
        // formErrors.commissionRateIdError = this.state.commissionRateId === "" || this.state.commissionRateId === null || this.state.commissionRateId === undefined ? "Commission is Required" : "";


        if (this.state.providerCommission.id === undefined) {
            NotificationManager.error("Please set the commission first",  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            // window.location.reload();
        } else {
            var PurchaseOrderData = new FormData();
            PurchaseOrderData.append("providerId", this.state.providerId);
            PurchaseOrderData.append("amount", this.state.amount);

            PurchaseOrderData.append("commissionRateId", this.state.providerCommission.id);

            PurchaseOrderData.append("attachment", this.state.attachment);
            PurchaseOrderData.append("userId", localStorage.userId);
          
           
            this.setState({ isLoading: true})
            PurchaseOrderService.createPurchaseOrder(PurchaseOrderData)
                .then(
                (response) => {
                    NotificationManager.success("Purchase order created successfully",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({ isLoading: false})
                    this.props.history.push('/purchaseOrderList');
                }, 
                (err) => {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    this.setState({ isLoading: false})
                });
        }

    };


    handelInputChange = e => {

        e.preventDefault()
        const { name, value } = e.target
               
        let formErrors = this.state.formErrors;

        switch (name) {
            case 'providerId':
                ProviderCommissionService.getProviderCommissionByProviderId(this.state.providerId,localStorage.userId)
                    .then(response => {
                        this.setState({
                            providerCommission: response.data
                        });
                        
                    })
                    .catch(e => {
                        
                    });

                break;
            default:
                break;
        }
        this.setState({
            formErrors, [ name ]: value
        })
    }
    render() {
        const { providersList, providerCommission, amount, selectedFile, commissionAmount, attachment, formErrors } = this.state;

        if (providerCommission.onPurchase !== undefined && this.state.amount !== '') {
            let totalPurchase = (+this.state.amount / 100) * +providerCommission.onPurchase + +this.state.amount;
            this.state.totalPurchase = parseFloat(totalPurchase).toFixed(2);
         

            let commissionAmount = (+this.state.amount / 100) * +providerCommission.onPurchase
            this.state.commissionAmount = parseFloat(commissionAmount).toFixed(2);
            
        }

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Add Purchase</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Add Purchase Order </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Provider Name:</label>
                                                            <select onChange={ this.handelInputChange } onBlur={ this.handelInputChange } className="form-control" name='providerId'>
                                                                <option value=""> Choose provider</option>
                                                                { providersList.map(provider =>
                                                                    <option value={ provider.id }>{ provider.providerName }</option>
                                                                ) }
                                                            </select>
                                                            { formErrors.providerIdError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                                                            ) }
                                                        </div>
                                                    </div>
                                                    <div className='row'>



                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'
                                                                onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='amount'>Total Purchase:</label>
                                                            <input type="text" className="form-control " readOnly
                                                                value={ this.state.totalPurchase }
                                                            />
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Commission (%):</label>
                                                            <input type="text" className="form-control " id='commissionId' value={ providerCommission.onPurchase } readOnly />

                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='Country'>Commission Amount:</label>
                                                            <input type="text" className="form-control " id='commissionRateId' value={ this.state.commissionAmount } readOnly />

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >File Attachment </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='profilePic'>Attachment:</label>

                                                            <Dropzone onDrop={ this.onDrop }
                                                                multiple={ false }>
                                                                { ({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div { ...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" }) } style={ { border: "thin dashed black" } }>
                                                                            <input { ...getInputProps() } />
                                                                            { selectedFile && selectedFile[ 0 ].name ? (
                                                                                <div className="selected-file border-radius-1">
                                                                                    { selectedFile && selectedFile[ 0 ].name }
                                                                                </div>
                                                                            ) : (
                                                                                "Drag and drop here or click to select file"
                                                                            ) }
                                                                        </div>
                                                                        <aside className="selected-file-wrapper">
                                                                        </aside>
                                                                    </section>
                                                                ) }
                                                            </Dropzone>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                    />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(AddPurchaseOrder);
