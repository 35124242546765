import API from '../../http-common';

class SalesTeamAssignmentService {
    createSalesTeamAssignment(salesTeamData) {
        return API.post('/api/v1/createSalesTeamAssignment', salesTeamData);
    }

    getSalesTeamAssignmentBySalesTeamId(salesTeamId, userId) {
        return API.get('/api/v1/getSalesTeamAssignmentBySalesTeamId/' + salesTeamId + "/" + userId);
    }
    removeSalesTeamAssignment(mainDealerId, userId) {
        return API.delete('/api/v1/removeSalesTeamAssignment/' + mainDealerId + "/" + userId);
    }

    findAssignedSalesTeams(pageNo,pageSize, userId,searchParam) {
        return API.put('/api/v1/findAssignedSalesTeams/' + pageNo + "/" + pageSize+"/"+userId,searchParam);
    }

        
    findSalesTeamAssignmentBySalesTeamId(pageNo,pageSize, userId,searchParam) {
        return API.put('/api/v1/findSalesTeamAssignmentBySalesTeamId/' + pageNo + "/" + pageSize +"/" + userId,searchParam);
    }



}
export default new SalesTeamAssignmentService()