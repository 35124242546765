import API from '../../http-common';

class TypeSettingService {
  // get all Cash Information 
  getTypeSettings(userId) {
    return API.get("/api/v1/getTypeSettings/" + userId);
  }

  // create  Cash Information 
  createTypeSetting(userId, TypeSetting) {
    return API.post("/api/v1/createTypeSetting/" + userId, TypeSetting);
  }

  // get single Cash Information 
  getTypeSetting(id, userId) {
    return API.get("/api/v1/getTypeSettingById/" + id + "/" + userId);
  }

  // remove  Cash Information 
  deleteTypeSetting(id, userId) {
    return API.delete('/api/v1/deleteTypeSetting' + '/' + id + '/' + userId);
  }

  // update  Cash Method Information 
  updateTypeSetting(id, userId, TypeSetting) {
    return API.put("/api/v1/updateTypeSetting" + "/" + id + '/' + userId, TypeSetting);
  }

}


export default new TypeSettingService()