import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import NumberFormat from 'react-number-format';
import ProviderCommissionService from '../../../services/etopup/ProviderCommissionService';
import ReactLoading from "react-loading";

//export default class ViewPartnerProviderBalance extends React.Component {

export default class ViewProviderCommission extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            // partnerProviderBalanceId: this.props.match.params.partnerProviderBalanceId,
            providerCommissionId: this.props.match.params.providerCommissionId,
            isLoading: false,
            providerCommissionData: {
                providerId: '',
                onPurchase: '',
                onTarget: '',
                quarterly: '',
                remark: '',
                createdAt: ''
            }
        };
    }

    componentDidMount() {
        this.getProviderCommissionById(this.props.location.state.providerCommissionId);
    }

    getProviderCommissionById(providerCommissionId) {

        ProviderCommissionService.getProviderCommissionById(providerCommissionId, localStorage.userId)
            .then(response => {
                this.setState({
                    providerCommissionData: response.data,
                    isLoading: true,
                });
            })
            .catch(e => {
                this.setState({ isLoading: true })
                
            });
    }

    render() {
        const { isLoading, providerCommissionData } = this.state;


        //   console.log(resultArray[0]["bankName"]);
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <TopupMenu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">

                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Provider Commission Detail</li>
                                    </ol>
                                </div>

                            </div>

                            <div >
                                <div className="card ">
                                    <div className="card-body">

                                        <div className="table-responsive w-50">

                                            <table className="table table-bordered mb-5" style={ { fontSize: 'smaller' } }>

                                                <tr className="subheaders bg-light">
                                                    <td colspan="2" className="text-blue py-4">Commission Detail</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Provider Name </td>
                                                    <td className="text-justify">{ providerCommissionData.providerId.providerName }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >On Purchase</td>
                                                    <td className="text-justify">{ providerCommissionData.onPurchase }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >On Target</td>
                                                    <td className="text-justify">{ providerCommissionData.onTarget }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Quarterly</td>
                                                    <td className="text-justify">{ providerCommissionData.quarterly }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Remark</td>
                                                    <td className="text-justify">{ providerCommissionData.remark }</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left font-weight-bolder" >Created Date</td>
                                                    <td className="text-justify">{ providerCommissionData.createdAt }</td>
                                                </tr>

                                                <tr style={ { textAlign: 'center' } }>
                                                    <Link to="/providersCommissionList" className='pl-5'>
                                                        <button type="submit" className="btn btn-success auto mt-3 px-5 py-2">Back</button>
                                                    </Link>

                                                </tr>

                                            </table>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='col-2'></div>




                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>
                </div>
            )
        }
    }
}