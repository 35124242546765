import React, { Component } from 'react'
import { faBackward, faBackwardFast, faDownload, faFileExcel, faFilePdf, faForward, faForwardFast } from '@fortawesome/free-solid-svg-icons';
import ReportService from '../../services/Reports/ReportService';
import Header from '../common/Header';
import AsyncSelect from 'react-select/async'
import Menu from '../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../../http-common';
import PreviewTable from './PreviewTable';
import { LOCALES } from '../common/Constant';
import PreviewTransTable from './PreviewTransTable';
import _ from "lodash";
import ReactLoading from "react-loading";
import NumberFormat from 'react-number-format';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { Link } from 'react-router-dom';
import { downloadExcel } from "react-export-table-to-excel";


// import PDFViewer from 'pdf-viewer-reactjs'


// const ExamplePDFViewer = () => {
//     return (
//         <PDFViewer
//             document={{
//                 url: 'http://localhost:3000/AccountLedgerReport.pdf',
//             }}
//         />
//     )

// }

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}

export class AccountLedger extends Component {
    constructor(props) {

        super(props)
        pdfjs.GlobalWorkerOptions.workerSrc =
            `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        // pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
        this.state = {
            CashReport: [],
            searchValue: undefined,
            cashTypeId: undefined,
            transactionCode: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            reportType: 'pdf',
            reportformat: false,
            numPages: null,
            vewierBtn: false,
            reportVewier: false,
            reportDownload: false,
            isLoading: false,
            generateData: 1,
            pageNumber: 1,
            selectOption: null,
            summeryReport: true,
            detailReport: false,
            isCompany: false,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            counter: 1,
            totalPage: 0,
            totalelement: 0,
            columns:["Main Account Code","Sub Account Code","Opening Balance","Debit","Credit", "Closing Balance"],
            detailColumns: [
                {
                    Header: 'Account Code',
                    accessor: 'accountCode',
                },
                {
                    Header: 'Transaction Ref',
                    accessor: 'transactionRef',
                },
                {
                    Header: 'Description',
                    accessor: 'fullName',
                },
                {
                    Header: 'Opening Balance',
                    accessor: 'openingBalanceD',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value}`,
                },
                {
                    Header: 'Debit',
                    accessor: 'debitD',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value} `,
                },
                {
                    Header: 'Credit',
                    accessor: 'creditD',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value} `,
                },
                {
                    Header: 'Closing Balance',
                    accessor: 'closingBalanceD',
                    aggregate: 'sum',
                    Aggregated: ({ value }) => `${value} `,
                }

            ],

            reportData: [],
            formErrors: {
                reportTypeError: "",
                dateToError: "",
                dateFromError: ""
            },
        }

        this.reportStatus = this.reportStatus.bind(this);

    }

    getReportData() {
        let customerId = localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? this.state.searchValue && this.state.searchValue.value : localStorage.customerIdentity;

        let FilterCashInfo = {
            reportFormat: this.state.reportType,
            customerId: customerId,
            user: localStorage.userName,
            cashTypeId: this.state.cashTypeId,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            accountCode: this.state.selectOption && this.state.selectOption.value,
            reportType: !this.state.reportformat,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }

        ReportService.previewAccountTransReport(FilterCashInfo)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    reportData: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1,
                });

            })
            .catch(e => {

            });
    }

    getGenerateCashReport = e => {
        e.preventDefault();
        const { formErrors, reportType, reportDownload, isCompany } = this.state
        this.state.pageNumber = 1
        this.state.generateData = 1
        formErrors.reportTypeError = reportType === '' || reportType === null || reportType === undefined ? 'You must make a selection' : this.state.formErrors.reportTypeError;

        if (this.state.dateFrom != null && this.state.dateTo != null) {
            if (this.state.dateFrom > this.state.dateTo) {
                formErrors.dateFromError = 'Date from should be lessthan or equal to date to'

            }
        }

        if (formValid(this.state.formErrors)) {
                        if(reportDownload){
                            this.reportDownloadNew();
                        } else {

                            this.getReportData();

                            this.setState({
                                reportVewier: !this.state.reportVewier
                            });
                        }




        }

        this.setState({
            formErrors
        });

    }

    reportDownloadNew(){
        const{isCompany} = this.state
        let customerId = isCompany ? this.state.searchValue && this.state.searchValue.value : localStorage.customerIdentity;

        let filterCashInfo = {
            reportFormat: this.state.reportType,
            customerId: customerId,
            user: localStorage.userName,
            cashTypeId: this.state.cashTypeId,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo,
            accountCode: this.state.selectOption && this.state.selectOption.value,
            reportType: this.state.reportformat,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize
        }



        ReportService.getGenerateAccountLedgerReport(filterCashInfo)
            .then(
                (res) => {
                        var oReq = new XMLHttpRequest();
                        // Server 196.189.116.26/AelafSmartPay                         
                        if (this.state.reportType === 'pdf') {
                            var URLToPDF = `${process.env.REACT_APP_SERVER_URL}` + "files/AccountLedgerReport.pdf";
                            var type = 'application/pdf'
                        }
                        if (this.state.reportType === 'xlsx') {
                            var URLToPDF = `${process.env.REACT_APP_SERVER_URL}` + "files/AccountLedgerReport.xlsx";
                            var type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }

                        oReq.open("GET", URLToPDF, true);
                        oReq.withCredentials = false
                        oReq.setRequestHeader('Authorization', localStorage.jwtToken)
                        oReq.setRequestHeader('Access-Control-Allow-Origin', '*')
                        oReq.setRequestHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
                        oReq.responseType = "blob";

                        oReq.onload = function () {
                            // Once the file is downloaded, open a new window with the PDF
                            // Remember to allow the POP-UPS in your browser
                            const file = new Blob([oReq.response], { type: type });

                            const fileURL = URL.createObjectURL(file);

                            window.open(fileURL, "_blank");
                        };

                        oReq.send();
                        this.setState({
                            isLoading: true
                        });

                    },
                    (err) => {
                        NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                        this.setState({
                            reportVewier: false
                        });
                    })
                .catch(function (response) {
                    NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`,`${process.env.REACT_APP_MSG_TITLE_ERROR}` , 3000);
                });
    }


    componentDidMount() {
        this.setState({
            isCompany: localStorage.type === `${process.env.REACT_APP_CUSTOMER_TYPE_USER}` ? true : false
        });


        this.getReportData();


    }


    reportType(e) {
        const { formErrors } = this.state
        let selectedReportType = e.target.value
        this.state.reportType = ''
        if (selectedReportType === "----please select---")
            selectedReportType = ""

        formErrors.reportTypeError = selectedReportType === "" ? 'You must make a selection' : "";
        this.setState({
            reportType: selectedReportType,
            formErrors
        });
    }

    selectVaue(e) {
        let selectedCode = e.target.value
        this.state.transactionCode = undefined
        if (selectedCode === "----please select---")
            selectedCode = undefined

        this.setState({
            transactionCode: selectedCode,
            reportVewier: false
        });
    }



    onDocumentLoad = ({ numPages }) => {
        if (this.state.generateData === 1)
            this.setState({ numPages });
        this.state.generateData = 2
    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.getReportData()
    }

    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.getReportData()

    }

    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.getReportData()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.getReportData()


        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.getReportData()

        }
    }

    reportStatus() {
        this.state.isLoading = false;
        this.state.reportData = [];
        this.setState({
            reportDownload: false
        })
    }

    downloadStatus() {
        this.state.isLoading = false;
        this.setState({
            reportDownload: true
        })
    }

    fatchData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
                        });
                        callback(temp)
                    })
                    .catch((error) => {
                     
                    })
            })
        }
    }

    onSearchChange = (selectOption) => {
        this.setState({
            selectOption: selectOption
        });

    }

    onValueChange() {
        this.state.isLoading = false;
        this.state.reportData = [];
        this.state.reportformat = false;
        this.setState({
            reportVewier: true,
            summeryReport: !this.state.summeryReport,
            detailReport: !this.state.detailReport,
        })

        this.getReportData()
    }

    onDeatilValueChange() {
        this.state.isLoading = false;
        this.state.reportData = [];
        this.state.reportformat = true;
        this.setState({
            reportVewier: true,
            summeryReport: !this.state.summeryReport,
            detailReport: !this.state.detailReport,
        })

        this.getReportData()
    }

    exportPDF = () => {
        const {reportData, reportformat } = this.state
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
        doc.setFont('Lato-Regular', 'normal');
    
        const title = reportformat == false ? "Transaction Summery Report " : "Transaction Deatil Report " ;
        const headers = reportformat == false ? 
                        [["Main Account Code","Sub Account Code","Opening Balance","Debit","Credit", "Closing Balance"]]
                         :
                         [["Account Code","Transaction Ref","Description","Opening Balance","Debit","Credit", "Closing Balance"]];
        const data = []
        
        
        // reportData.map(
            
            
        //     rd=> 
        //     [ rd.parentAccountCode, rd.accountCode, rd.openingBalanceD, rd.debitD,rd.creditD,rd.closingBalanceD]
        //     );

            reportData.forEach((element, index,array) => {
                reportformat == false ?  data.push ([element.parentAccountCode, 
                    element.accountCode,   
                    parseFloat(element.openingBalanceD).toFixed(2),
                    parseFloat(element.debitD).toFixed(2),
                    parseFloat(element.creditD).toFixed(2) ,
                    parseFloat(element.closingBalanceD).toFixed(2) ])
                    :
                    data.push ([element.accountCode,  
                        element.transactionRef, 
                        element.fullName, 
                        parseFloat(element.openingBalanceD).toFixed(2),
                        parseFloat(element.debitD).toFixed(2),
                        parseFloat(element.creditD).toFixed(2) ,
                        parseFloat(element.closingBalanceD).toFixed(2) ]);
            });

            // }
    
        let content = {
          startY: 50,          
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content );
        doc.save("TransactionReport.pdf")
    }


    exportToEXcel(){
        const {reportData, reportformat } = this.state
        const header =  reportformat == false ?  
                        ["Main Account Code","Sub Account Code","Opening Balance","Debit","Credit", "Closing Balance"]
                        :
                        ["Account Code","Transaction Ref","Description","Opening Balance","Debit","Credit", "Closing Balance"];
    
        const body = [];

        reportData.forEach((element, index,array) => {
            reportformat == false ?  body.push ([element.parentAccountCode, 
                element.accountCode,  
                parseFloat(element.openingBalanceD).toFixed(2),
                parseFloat(element.debitD).toFixed(2),
                parseFloat(element.creditD).toFixed(2) ,
                parseFloat(element.closingBalanceD).toFixed(2) ])
                :
                body.push ([element.accountCode, 
                    element.transactionRef, 
                        element.fullName, 
                    parseFloat(element.openingBalanceD).toFixed(2),
                    parseFloat(element.debitD).toFixed(2),
                    parseFloat(element.creditD).toFixed(2) ,
                    parseFloat(element.closingBalanceD).toFixed(2) ])
        });

        downloadExcel({
            fileName: "TransactionReport",
            sheet: "TransactionReport",
            tablePayload: {
              header,
              body: body
            },
          });
    }

    actionPageSize(e) {
        this.state.pageSize = e.target.value;
        this.setState({
          isLoading: false,
          pageSize: e.target.value
        });
    
        this.getReportData();
      }

   
    render() {
        const { formErrors, reportVewier, isCompany, detailColumns, reportData, summeryReport, isLoading, pageNo, totalPage, pageSize } = this.state
        pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1

        return (
            <div>
                {/* <Header />
                <Menu />
                <NotificationContainer /> */}
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">

                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-left">
                                        <li className="breadcrumb-item">
                                            <a href="/#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item" >Reports</li>
                                        <li className="breadcrumb-item active" >Account Ledger</li>
                                    </ol>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-title" >Display report Information</div>

                                    <form onSubmit={this.getGenerateCashReport} noValidate>

                                        <div className='row '>
                                            {/* <label htmlFor='codedTransaction'>Ledger Account :</label>
                                                <select className="form-control "
                                                    value={this.state.transactionCode && this.state.transactionCode.id}
                                                    onChange={e => this.selectVaue(e).bind(this)} id='codedTransaction'>
                                                    <option value={null}>----please select---</option>
                                                    {codedTransactionList}
                                                </select> */}
                                            {isCompany ?
                                                <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                    <label htmlFor='drAccount'> Account :</label>
                                                    <div style={{ fontSize: 'smaller' }}>
                                                        <AsyncSelect id='drAccount'

                                                            placeholder={"Search by account code"}
                                                            isClearable={true}
                                                            loadOptions={this.fatchData}
                                                            isLoading={this.state.selectOption == {} &&
                                                                this.state.selectOption === undefined &&
                                                                this.state.selectOption === null ? true : false
                                                            }
                                                            onChange={(e) => {
                                                                this.onSearchChange(e)
                                                            }}
                                                            defaultOptions={false}
                                                        />
                                                    </div>

                                                </div>
                                                :
                                                ''
                                            }



                                            <div className=" form-group col-2 col-sm-2 col-md-2 col-lg-2 ">
                                                <label htmlFor='reportType'>Report Type:</label>
                                                <select className="form-control "
                                                    value={this.state.reportType}
                                                    onChange={e => this.reportType(e)} id='reportType'>
                                                    <option key={null} value={null}>----please select---</option>
                                                    <option key={"xlsx"} value={"xlsx"}>xlsx</option>
                                                    <option key={"pdf"} value={"pdf"}>pdf</option>
                                                </select>
                                                {formErrors.reportTypeError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.reportTypeError}</span>
                                                )}

                                            </div>

                                            <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                <label htmlFor='dateFrom' >Date From</label>

                                                <input type="date" className="form-control" id='dateFrom'
                                                    onChange={e => this.state.dateFrom = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateFromError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateFromError}</span>
                                                )}
                                            </div>
                                            <div className=" form-group col-3 col-sm-3 col-md-3 col-lg-3 ">
                                                <label htmlFor='dateTo'>Date To</label>
                                                <input type="date" className="form-control" id='dateTo'
                                                    onChange={e => this.state.dateTo = e.target.value}
                                                    placeholder="Date" />
                                                {formErrors.dateToError.length > 0 && (
                                                    <span style={{ color: "#F61C04" }}>{formErrors.dateToError}</span>
                                                )}

                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className="form-group col-3 col-sm-3 col-md-3 col-lg-3 radio">
                                                <label>
                                                    <input style={{ marginLeft: "5px" }}
                                                        type="radio"
                                                        checked={this.state.summeryReport}
                                                        onChange={() => this.onValueChange()}
                                                    />
                                                    Summery
                                                </label>

                                                <label style={{ marginLeft: "10px" }}>
                                                    <input style={{ marginLeft: "5px" }}
                                                        type="radio"
                                                        checked={this.state.detailReport}
                                                        onChange={() => this.onDeatilValueChange()}
                                                    />
                                                    Detail
                                                </label>
                                            </div>
                                        </div>
                                        <br />
                                        <div>
                                            <div className="row  d-flex justify-content-left ">

                                                <button type='submit' title={"Preview"} style={{ marginLeft: "10px" }}
                                                    className="btn btn-sm btn-success primary m-1  float-left"
                                                    onClick={() => this.reportStatus()}>
                                                    <i className="fa fa-eye fa-sm" /> Generate Report
                                                </button>

                                                <button type='submit' title={"Download"}
                                                    onClick={() => this.downloadStatus()}
                                                    className="btn btn-sm btn-success primary m-1  float-left">
                                                    <i className="fa fa-download fa-sm" /> Download
                                                </button>
                                               
                                            </div>
                                            </div>
                                    </form>
                                            <div className=" form-group col-1 col-sm-1 col-md-1 col-lg-1 float-right">
                                                <label htmlFor='Size'>Row #:</label>
                                                <select value={this.state.pageSize}
                                                    onChange={e => this.actionPageSize(e)} name='Size'>
                                                    <option key={"20"} value={20}>20</option>
                                                    <option key={"50"} value={50}>50</option>
                                                    <option key={"500"} value={500}>500</option>
                                                    <option key={"1000"} value={1000}>1000</option>
                                                    <option key={"10000"} value={10000}>10000</option>
                                                </select>
                                            </div>
                                             <Link  className=" float-right m-1"
                                                 onClick={() => this.exportToEXcel()}>                                    
                                                    <FontAwesomeIcon icon={faDownload} title="download as excel" />
                                                    <FontAwesomeIcon icon={faFileExcel} title="download as excel" />
                                             </Link>
                                          
                                             <Link  className=" float-right m-1"
                                                 onClick={() => this.exportPDF()}>                                    
                                                    <FontAwesomeIcon icon={faDownload} title="download as pdf" />
                                                    <FontAwesomeIcon icon={faFilePdf} title="download as pdf" />
                                             </Link>
                                    
                                             <div style={{ clear: 'both' }}></div>
                                    {!isLoading ?
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                                            </div>
                                        </div>

                                        :
                                        <>

                                            {summeryReport ?
                                              <div className='tableContainer'>
                                                <PreviewTransTable
                                                    data={reportData}
                                                    columns={[
                                                        // {
                                                        //     Header: '#',
                                                        //     accessor: (_row, i) => i + this.state.counter,
                                                        // },
                                                        {
                                                            Header: 'Main Account Code',
                                                            accessor: 'parentAccountCode',
                                                        },
                                                        {
                                                            Header: 'Sub Account Code',
                                                            Footer: 'Total: ',
                                                            accessor: 'accountCode',
                                                        },
                                                        {
                                                            Header: 'Opening Balance',
                                                            accessor: 'openingBalanceD',
                                                            aggregate: 'sum',
                                                            Aggregated: ({ value }) => `${value}`,
                                                        },
                                                        {
                                                            Header: 'Debit',
                                                            accessor: 'debitD',
                                                            aggregate: 'sum',
                                                            Aggregated: ({ value }) => `${value} `,
                                                            Footer: <span>
                                                                        <NumberFormat  value={parseFloat(_.sum(_.map(reportData, d => d.debitD))).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } /> 
                                                                    </span>
                                                        },
                                                        {
                                                            Header: 'Credit',
                                                            accessor: 'creditD',
                                                            aggregate: 'sum',
                                                            Aggregated: ({ value }) => `${value} `,
                                                            Footer:<span>
                                                            <NumberFormat  value={parseFloat(_.sum(_.map(reportData, d =>  d.creditD))).toFixed(2) } displayType={ 'text' } thousandSeparator={ true } prefix={ '' } />   
                                                           </span>
                                                        },
                                                        {
                                                            Header: 'Closing Balance',
                                                            accessor: 'closingBalanceD',
                                                            aggregate: 'sum',
                                                            Aggregated: ({ value }) => `${value} `,
                                                        }

                                                    ]} />
                                                    </div>
                                                :
                                                <div className='tableContainer'>
                                                <PreviewTable columns={detailColumns} data={reportData} />
                                            </div>
                                            }
                                            <div className="clearfix">&nbsp;</div>
                                            <div className=" float-sm-center">
                                                <span>
                                                    page{''}
                                                    <strong>
                                                        {pageNo} of {totalPage}
                                                    </strong>
                                                    {''}
                                                </span>
                                                <span>
                                                    | Go To Page : {''}
                                                    <input type='number' defaultValue={pageNo}
                                                        onChange={(e) => {
                                                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                            this.gotoEnteredPage(pageNumber)
                                                        }} />
                                                </span>
                                                <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                                    <FontAwesomeIcon icon={faBackwardFast} />
                                                </button>

                                                <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                                    <FontAwesomeIcon icon={faBackward} />
                                                </button>

                                                <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                                <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                                    <FontAwesomeIcon icon={faForward} />
                                                </button>

                                                <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                                    <FontAwesomeIcon icon={faForwardFast} />
                                                </button>

                                            </div>

                                        </>


                                    }
                                </div>

                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content */}
                </div>
            </div>

        )
    }

}

export default AccountLedger