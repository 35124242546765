import API from '../../../src/http-common';

class TopupOrderService {
    getTopupOrderById(id, userId) {
        return API.get('/api/v1/getTopupOrderById/' + id + "/" + userId);
    }
    getAllTopupOrders(pageNo, pageSize, customerId, status, userId) {
        return API.get('/api/v1/getAllTopupOrders/' + pageNo + "/" + pageSize + "/" + customerId + "/" + status + "/" + userId);
    }

    getTopupOrderHistory(pageNo, pageSize, userId) {
        return API.get('/api/v1/getTopupOrderHistory/' + pageNo + "/" + pageSize + "/" + userId);
    }

    approveTopupOrder(orderId, parentCustomerId, dealerCustomerId, amount, providerId, userId) {
        return API.get('/api/v1/approveTopupOrder/' + orderId + "/" + parentCustomerId + "/" + dealerCustomerId + "/" + amount + "/" + providerId + "/" + userId);
    }
    deleteOrder(orderId, userId) {
        return API.delete('/api/v1/deleteTopupOrder/' + orderId + "/" + userId);
    }
    addTopupOrder(orderData) {
        return API.post('/api/v1/addTopupOrder', orderData);
    }

    getAllPendingTopupOrders(userId) {
        return API.get('/api/v1/getAllPendingTopupOrders/' + userId);
    }
}
export default new TopupOrderService()