import API from '../../http-common';

class PostAccountService { 

  // get Customer Dealers
  getPostAccountsById(id , userId) {
    return API.get("/api/v1/getPostAccountsById/" + id + "/" + userId) ;
  }
  
   // create  Customer Dealer Information 
   createPostAccounts(PostAccountInfo) {
    return API.post("/api/v1/createPostAccounts", PostAccountInfo);
  }

   // update  Customer Dealer Information 
  updatePostAccounts(PostAccountInfo) {
    return API.put("/api/v1/updatePostAccount", PostAccountInfo);
  }

   // remove  Cash Information 
  deletePostAccounts(id, userId) {
    return API.delete('/api/v1/deletePostAccount' + '/' + id + "/" + userId);
  }

  // get list of Cash Information 
  getFilteredPostAccounts( PostAccounts,pageNo, pageSize, userId)
  {
  return API.put("/api/v1/getFilteredPostAccount"  + "/" + pageNo + "/" + pageSize + "/" + userId, PostAccounts);
  }

}
export default new PostAccountService()