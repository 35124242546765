import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LedgerAccountService from '../../../services/etopup/LedgerAccountService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant'
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faBackwardFast, faForward, faForwardFast, faCheckSquare, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import API from '../../../http-common';
import AsyncSelect from 'react-select/async'
import { Link } from 'react-router-dom';

class LedgerAccountList extends Component {
    constructor(props) {
   
        super(props)
        this.state = {
            isLoading: false,
            parentId: this.props.location.state && this.props.location.state.legerAccountId,
            ledgerAccountList: [],
            mainLedgerAccount: [],
            mainLedgerAccountId: null,
            selectOption:null,
            isExpand: true,
            clear:0,
            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            counter: 1,
            id: 0
        }

       
        this.editLedgerAccount = this.editLedgerAccount.bind(this);
        this.ledgerAccountDetail = this.ledgerAccountDetail.bind(this);
        this.addLedgerAccount = this.addLedgerAccount.bind(this);

    }

    addLedgerAccount() {
     
        if (this.state.mainLedgerAccountId != null || this.state.mainLedgerAccountId != undefined) {
           
            this.props.history.push({pathname: "/addAccount", state: {legerAccountId: this.state.mainLedgerAccountId}});            
        } else {
            this.props.history.push(`/addAccount`);
        }
    }

    ledgerAccountDetail(id) {
        this.props.history.push({pathname: "/viewAccount", state: {id: id}});
    }

    editLedgerAccount(id) {
        if (this.state.mainLedgerAccountId != null ||
            this.state.mainLedgerAccountId != undefined) {
            this.props.history.push({pathname: "/editAccount", state: {id: id, legerAccountId: this.state.mainLedgerAccountId }});
        } else {
            this.props.history.push({pathname: "/editAccount", state: {id: id}});
        }
    }

    componentDidMount() {
       this.displayLadgerAccount();

        LedgerAccountService.getAllMainLedgerAccounts(localStorage.userId)
        .then(response => {
            this.setState({               
                mainLedgerAccount: response.data
            });
            
          })
          .catch(e => {
            
            
          });            
            

        

    }

    displayLadgerAccount() {
        this.state.ledgerAccountList = null;
        let FilterValue = {
          ledgerAccountId: this.state.selectOption && this.state.selectOption.value,
        }

        if (this.state.parentId == undefined ||
            this.state.parentId == null || this.state.parentId == 'null') {
            this.searchLedgerAccounts(this.state.pageNo, this.state.pageSize, FilterValue)
        } else {
            this.searchSubManagment(this.state.pageNo, this.state.pageSize, this.state.parentId, FilterValue);
        }
    }

    searchLedgerAccounts(pageNo, pageSize, filterValue) {
        LedgerAccountService.getMainLedgerAccounts(pageNo, pageSize, localStorage.userId,filterValue)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    parentId: "null",
                    mainLedgerAccountId: null,
                    ledgerAccountList: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1,
                });
            });
           
    }


    async deleteLedgerAccount(id) {
        if (window.confirm("Are you sure you want to delete the data?")) {
            try {
                const response = await LedgerAccountService.deleteLedgerAccount(id, localStorage.userId);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`);
                this.displayLadgerAccount()
            } catch (error) {
                NotificationManager.error(error.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`);

            }
        }

    }

    searchSubManagment(pageNo, pageSize, selectedLedgerAccount, filterAccount) {
        this.state.ledgerAccountList = null
        LedgerAccountService.getSubLedgerAccounts(pageNo, pageSize, selectedLedgerAccount, localStorage.userId, filterAccount)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    mainLedgerAccountId: selectedLedgerAccount,
                    ledgerAccountList: data.content,
                    totalPage: data.totalPages,
                    totalelement: data.pageable.totalelement,
                    pageNo: data.pageable.pageNumber + 1,
                });
            });
    }


    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.displayLadgerAccount()
    }

    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.displayLadgerAccount()

    }

    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.displayLadgerAccount()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.displayLadgerAccount()
        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.displayLadgerAccount()
        }
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            this.state.clear = 0
            this.displayLadgerAccount()

        }
    }

    drFatchData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                if(this.state.parentId === undefined || this.state.parentId === null || this.state.parentId === "null"){
                    API.get("/api/v1/getMainLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
                        });
                        callback(temp)
                    })
                    .catch((error) => {
                    })
                }else{
                    API.get("/api/v1/getSubLedgerAccounCode/" + inputValue +"/" + this.state.parentId + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.accountCode + " - " + element.name, value: element.id })
                        });
                        callback(temp)
                    })
                    .catch((error) => {
                    })
                }
            })
        }
    }

    onDrSearchChange = (selectOption) => {
        this.setState({
            selectOption: selectOption
        });

    }

    filterPostAccount(e) {
    
          this.state.clear = 0;
          this.state.pageNo = 1;
          this.displayLadgerAccount();  
    
      
      }


    clearValue() {
        this.state.selectOption = null
        this.state.clear = 1
    }


    CodeValueChange = e => {
        let selectedLedgerAccount = e.target.value
        this.ledgerAccountList = null
        this.state.mainLedgerAccountId = null
       
        this.state.PostAccountList = null;
        let FilterValue = {
          ledgerAccountId: this.state.selectOption && this.state.selectOption.value,
        }

        if (selectedLedgerAccount == "----please select---") {
            selectedLedgerAccount = ""
            this.state.parentId = null
            this.searchLedgerAccounts(this.state.pageNo, this.state.pageSize, FilterValue)
        } else {
            this.state.parentId = selectedLedgerAccount
            this.searchSubManagment(this.state.pageNo, this.state.pageSize, selectedLedgerAccount, FilterValue);
        }
    }

    render() {
        const { ledgerAccountList, pageNo, pageSize,totalPage, mainLedgerAccount, mainLedgerAccountId, isLoading } = this.state;
        pageNo == 1 ? this.state.counter = 1 : this.state.counter = (pageSize * (pageNo - 1)) + 1

        let mainList = mainLedgerAccount.length > 0
            && mainLedgerAccount.map((item, i) => {
                return (
                    <option key={i} value={item.id}>{item.name}</option>
                )
            }, this);

        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" > Chart of account management List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Display  Chart of account management Information</div>
                                        <div className='row'>
                                            <div className="col-md-3">
                                                <label htmlFor='mainLedgerAccount'>Select Main Code:</label>
                                                <select className="form-control "
                                                    value={this.state.mainLedgerAccountId}
                                                    onChange={this.CodeValueChange} id='mainLedgerAccount'>
                                                    <option value={null}>----please select---</option>
                                                    {mainList}
                                                </select>
                                            </div>
                                            
                                            <form className=" form-group col-6 col-sm-6 col-md-6 col-lg-6  " onSubmit={this.clear} hidden={this.state.isExpand} noValidate>

                                                <div  hidden={this.state.isExpand}>
                                                    <div   >
                                                        <label htmlFor='CustomerList'>Ledger Account:</label>
                                                        <div style={{ fontSize: 'smaller' }}>
                                                            <AsyncSelect id='drAccount'
                                                                placeholder={"Search by account code"}
                                                                isClearable={true}
                                                                loadOptions={this.drFatchData}
                                                                isLoading={this.state.selectOption == {} &&
                                                                    this.state.selectOption === undefined &&
                                                                    this.state.selectOption === null ? true : false
                                                                }
                                                                onChange={(e) => {
                                                                    this.onDrSearchChange(e)
                                                                }}
                                                                defaultOptions={false}
                                                            />
                                                      

                                                        </div>

                                                    <div >
                                                        <button title={"Search"}
                                                            className="btn btn-sm btn-success primary m-1 float-right"
                                                            onClick={() => this.filterPostAccount()}>Search</button>
                                                        <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                                                            className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                            

                                        </div>
                                        <div className="clearfix">&nbsp;</div>
                                        <button type="button" title={mainLedgerAccountId ? "Add Sub" : "Add Main"}
                                            className="btn btn-sm btn-success primary m-1  float-left"
                                            onClick={(e) => this.addLedgerAccount()}>
                                            <i className="fas fa-plus" /> {mainLedgerAccountId ? "Add Sub" : "Add Main"}
                                        </button>
                                        <button type='button' title={"Show/Hide Filter"}
                                            className="btn btn-sm btn-success primary m-1 float-left"
                                            onClick={() => this.filterToggle()}>
                                            <FontAwesomeIcon icon={faFilter} />
                                            Filter
                                        </button>


                                        <table className=" table table-striped table-birdered " style={{ fontSize: 'smaller' }} >
                                            <thead className=' thead-light'>
                                                <tr style={{ marginTop: 0 }}>
                                                    <th>No.</th>
                                                    <th>Ledger Account </th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '>

                                                {ledgerAccountList && ledgerAccountList.map(ledgerAccount =>

                                                    <tr key={ledgerAccount.id}>
                                                        <td>{this.state.counter++}</td>

                                                        <td>
                                                        <Link to={{ pathname: '/ledgerTranList', state: { ledgerAccountId: ledgerAccount.id, accountName: ledgerAccount.name, accountCode:  ledgerAccount.accountCode} }}>
                                                            {ledgerAccount.accountCode}
                                                        </Link>
                                                        </td>
                                                        <td>{ledgerAccount.name}</td>
                                                        <td>{ledgerAccount.description}</td>
                                                        <td>

                                                            <button style={{ marginLeft: "2px" }} title={"Edit"}
                                                                onClick={() => this.editLedgerAccount(ledgerAccount.id)}
                                                                className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>

                                                            <button style={{ marginLeft: "2px" }} title={"Delete"}
                                                                onClick={() => this.deleteLedgerAccount(ledgerAccount.id)}
                                                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                                                        </td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </table>
                                        <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{''}
                        <strong>
                          {pageNo} of {totalPage}
                        </strong>
                        {''}
                      </span>
                      <span>
                        | Go To Page : {''}
                        <input type='number' defaultValue={pageNo}
                          onChange={(e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          }} />
                      </span>
                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackwardFast} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                        <FontAwesomeIcon icon={faBackward} />
                      </button>

                      <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                        <FontAwesomeIcon icon={faForward} />
                      </button>

                      <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                        <FontAwesomeIcon icon={faForwardFast} />
                      </button>
                    </div>
                                    </div>
                                </div>
                                <NotificationContainer />
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>
                        {/* /.content */}
                    </div>
                </div>

            )
        }
    }
}


export default LedgerAccountList;