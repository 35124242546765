import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Menus from './components/etopup/Menus';
import ErrorBoundary from './components/common/ErrorBoundary';




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

try {
ReactDOM.render( 
  <>
  {/* <Menus/>  */}
    <App/>  
  </>, document.getElementById("root")
  );
} catch (error) {
    console.log('Please check everything and try again.')
}


