import API from '../../http-common';

class ProvidersInfosService {


  // get all providers Information 
  getProviders(userId) {
    return API.get("/api/v1/getProviders/" + userId);
  }

  // get all providers related Information 
  getProvidersInfos(userId) {
    return API.get("/api/v1/getProvidersInfos/" + userId);
  }

  // create  providers related Information 
  createProvidersInfos(providers) {
    
    return API.post("/api/v1/createProvidersInfo", providers);
  }

  // get single providers related Information 
  getProvidersInfo(id,userId) {
    return API.get("/api/v1/getProvidersInfoById/" + id + "/" + userId);
  }

  // remove  providers related Information 
  deleteProvidersInfos(id, userId) {
    return API.delete('/api/v1/deleteProvidersInfo' + '/' + id + "/" + userId);
  }

  // update  providers related Information 
  updateProvidersInfos(providers) {
    
    return API.put("/api/v1/updateProvidersInfo", providers);
  }


}

export default new ProvidersInfosService()