import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatusSettingService from '../../../services/etopup/StatusSettingService';
import Menu from '../../wallet/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Header from '../Header';
import ReactLoading from "react-loading";

class StatusSettingList extends Component {
    constructor (props) {

        super(props)

        this.state = {
            isLoading: false,
            statusSettingList: [],
            counter: 1,
            id: 0
        }


        this.editStatusSetting = this.editStatusSetting.bind(this);
        this.statusSettingDetail = this.statusSettingDetail.bind(this);
        this.addStatusSetting = this.addStatusSetting.bind(this);

    }

    addStatusSetting() {
        this.props.history.push(`/addStatusSetting`);
    }

    statusSettingDetail(id) {
        this.props.history.push({pathname: "/viewStatusSetting", state: {id: id}})
     
    }

    editStatusSetting(id) {
        this.props.history.push({pathname: "/editStatusSetting", state: {id: id}})
    }



    componentDidMount() {
        this.searchCashStatusSettings()
    }

    searchCashStatusSettings() {
        StatusSettingService.getStatusSettings(localStorage.userId)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    isLoading: true,
                    statusSettingList: data
                });
            });
    }


    async deleteStatusSetting(id) {
        if (window.confirm("Are you sure you want to delete the data?")) {
            try {
                const response = await StatusSettingService.deleteStatusSetting(id);
                NotificationManager.success(`${process.env.REACT_APP_DELETE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            } catch (error) {
                NotificationManager.error(`${process.env.REACT_APP_DELETE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            }
        }

    }

    render() {
        const { statusSettingList, isLoading } = this.state;
        this.state.counter = 1
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" >Status Setting List</li>
                                        </ol>
                                    </div>

                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-title" >Display Status Setting Information</div>

                                        <button type="button" title={ "Add" }
                                            className="btn btn-sm btn-success primary m-1  float-left"
                                            onClick={ (e) => this.addStatusSetting() }>
                                            <i className="fas fa-plus" /> Add
                                        </button>


                                        <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } } >
                                            <thead className=' thead-light'>
                                                <tr style={ { marginTop: 0 } }>
                                                    <th>No.</th>
                                                    <th>Status</th>
                                                    <th>Description</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className='content-wrapper '>

                                                { statusSettingList.map(statusSetting =>

                                                    <tr key={ statusSetting.id }>
                                                        <td>{ this.state.counter++ }</td>
                                                        <td>{ statusSetting.statusValue }</td>
                                                        <td>{ statusSetting.description }</td>
                                                        <td>


                                                            <button style={ { marginLeft: "2px" } } title={ "Edit" }
                                                                onClick={ () => this.editStatusSetting(statusSetting.id) }
                                                                className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" /> </button>

                                                            <button style={ { marginLeft: "2px" } } title={ "Delete" }
                                                                onClick={ () => this.deleteStatusSetting(statusSetting.id) }
                                                                className="btn btn-danger btn-sm action-buttons"><i className="nav-icon fa fa-trash fa-sm" /> </button>

                                                        </td>
                                                    </tr>

                                                ) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <NotificationContainer />
                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>
                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}


export default StatusSettingList;