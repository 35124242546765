import API from '../../http-common';

class LedgerAccountService {

  // get all am code Information 
  getLedgerAccounts(userId) {
    return API.get("/api/v1/getLedgerAccounts/" + userId );
  }

   // get all main am code Information 
   getAllMainLedgerAccounts(userId) {
    return API.get("/api/v1/getAllMainLedgerAccounts/" + userId);
  }

   // get all main am code Information 
   getMainLedgerAccounts(pageNo, pageSize, userId, fiterValue) {
    return API.put("/api/v1/getMainLedgerAccounts/" + pageNo +"/"+pageSize+"/" + userId, fiterValue);
  }
  
   // get all sub am code Information 
   getSubLedgerAccounts(pageNo, pageSize, id, userId, fiterValue) {
    return API.put("/api/v1/getSubLedgerAccounts/"  +pageNo + "/" + pageSize + "/" + id + "/" + userId, fiterValue);
  }
  
  // create  am code Information 
  createLedgerAccount(cashMethod) {
    return API.post("/api/v1/createLedgerAccount", cashMethod);
  }

  // get single am code Information 
  getLedgerAccount(id, userId) {
    return API.get("/api/v1/getLedgerAccountById/" + id + "/" + userId);
  }  

  // remove  am code Information 
  deleteLedgerAccount(id, userId) {
    return API.delete('/api/v1/deleteLedgerAccount' + '/' + id + "/" + userId);
  }

  // update  am code Method Information 
  updateLedgerAccount(id, cashMethod) {
    return API.put("/api/v1/updateLedgerAccount" +"/" + id, cashMethod);
  }
  
}


export default new LedgerAccountService()