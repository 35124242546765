import React, { Component } from 'react'
import Menu from '../../wallet/Menu';
import CustomersService from '../../../services/common/CustomersService';
import RegionService from '../../../services/common/RegionService';
import CountryService from '../../../services/common/CountryService';
import CustomerTypeService from '../../../services/common/CustomerTypeService';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PhoneInput from 'react-phone-input-2';
import Dropzone from "react-dropzone";
import 'react-phone-input-2/lib/style.css';
import Header from '../Header';
import ReactLoading from "react-loading";
import API from '../../../http-common';
import LoadingPopup from '../../wallet/LoadingPopup';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const nameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{3,50}$/u)
const middleNameRegEx = RegExp(/^[a-zA-Z_\-\/ ]{0,50}$/u)
const emailRegExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)

const numberRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{1,50}$/u)
const OptionalRegEx = RegExp(/^[\p{L} \p{N}_@,.&$%#-]{0,50}$/u)
const mobileNoRegEX = RegExp(/^[0-9\b]+$/u)
const fileTypes = ["JPEG ", "jpeg", "JPG", "jpg", "PNG", "png", "GIF", "gif"];
const amountRegEx = RegExp(/^(?=[1-9])[0-9\.]{1,20}$/u)


class CustomerRegistration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      // For file attachment
      // MenuType: this.props.location.state.menu,
      // isEtopUp: this.props.location.state.isEtopUp,

      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      fileInfos: [],

      selectedFilesIdentity: undefined,
      currentFileIdentity: undefined,

      isNewId: true,
      isOldId: false,

      attachment: '',
      identityCard: '',
      selectOption: null,
      regionList: [],
      countryList: [],
      bankList: [],
      customerTypes: [],
      amCodeList: [],
      isLoading: false,

      // fullName: '',
      regionId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      companyName: '',
      mobileNo: '',
      city: '',
      type: '',
      countryId: '',
      email: '',
      street: '',
      website: '',
      isTopup: 0,
      ledgerAccountId: '',
      isLoading: false,
      idNumber: '',
      pageNo: '',
      registryNo: '',
      volumeNo: '',
      birthDay: undefined,
      issueDate: undefined,
      gender: '',
      placeofBirth: "",
      oldAccountNo: "",
      latitude: "",
      longitude: "",
      formErrors: {
        profileError:'',
        identityCardError:'',
        firstNameError: "",
        middleNameError: "",
        lastNameError: "",
        mobileNoError: "",
        regionIdError: "",
        countryIdError: "",
        customerTypeError: "",
        emailError: "",
        longitudeError: "",
        latitudeError: "",
        cityError: "",
        streetError: "",
        companyNameError: "",
        websiteError: "",
        amCodeIdError: "",
        idNumberError: "",
        issueDateError: "",
        pageNoError: "",
        registryNoError: "",
        volumeNoError: "",
        birthDayError: "",
        placeofBirthError: "",
        oldAccountNoError: "",
        genderError: ""
      },
    }
    this.onDrop = this.onDrop.bind(this);
    this.onDropIdentity = this.onDropIdentity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  onDrop(files) {

    if (files.length > 0) {
      this.setState({ selectedFiles: files });
      this.setState({ currentFile: this.state.selectedFiles[0] });
    }
  }

  onDropIdentity(files) {

    if (files.length > 0) {
      this.setState({ selectedFilesIdentity: files });
      this.setState({ currentFileIdentity: this.state.selectedFilesIdentity[0] });
    }
  }


  fatchData = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        API.get("/api/v1/getLedgerAccounCode/" + inputValue + "/" + localStorage.userId)
          .then((response) => response.data)
          .then((data) => {
            const temp = [];
            data.forEach((element) => {
              temp.push({ label: element.accountCode, value: element.id })
            });
            callback(temp)
            this.setState({
              amCodeList: data,
            })
          })
          .catch((error) => {
          })
      })
    }else {
      callback([])
    }
  }
  onSearchChange = (selectOption) => {
    const { formErrors } = this.state
    formErrors.amCodeIdError = selectOption == "" || selectOption == null ? 'You must make a selection' : "";

    this.setState({
      selectOption: selectOption,
      formErrors
    });
  }

  componentDidMount() {

    {/*To get list of regions*/ }
    RegionService.getAllRegions(localStorage.userId)
      .then(response => {
        this.setState({
          regionList: response.data
        });

      })
      .catch(e => {

      });


    {/*To get Customer types*/ }
    CustomerTypeService.getCustomerTypes(localStorage.userId)
      .then(response => {
        this.setState({
          customerTypes: response.data
        });

      })
      .catch(e => {

      });
    {/*To get list of countries*/ }
    CountryService.getCountries(localStorage.userId)
      .then(response => {
        this.setState({
          countryList: response.data
        });

      })
      .catch(e => {

      });
  }
  Cancel = (e) => {
    this.props.history.push('/customerList');
  }


  handleSubmit(e) {

    e.preventDefault();

    const { formErrors } = this.state

    formErrors.firstNameError = this.state.firstName === "" || this.state.firstName === null || this.state.firstName === undefined ? "First name is Required" : this.state.formErrors.firstNameError;
    // formErrors.middleNameError = this.state.middleName === "" || this.state.middleName === null || this.state.middleName === undefined ? "Middle name is Required" : "";
    formErrors.lastNameError = this.state.lastName === "" || this.state.lastName === null || this.state.lastName === undefined ? "Last name is Required" : this.state.formErrors.lastNameError;
    formErrors.mobileNoError = this.state.mobileNo === "" || this.state.mobileNo === null || this.state.mobileNo === undefined ? "Mobile number is Required" : this.state.formErrors.mobileNoError;
    formErrors.regionIdError = this.state.regionId === "" || this.state.regionId === null || this.state.regionId === undefined ? "Province is Required" : this.state.formErrors.regionIdError;
    formErrors.countryIdError = this.state.countryId === "" || this.state.countryId === null || this.state.countryId === undefined ? "Country is Required" : this.state.formErrors.countryIdError;
    formErrors.customerTypeError = this.state.type === "" || this.state.type === null || this.state.type === undefined ? "Customer type is Required" : this.state.formErrors.customerTypeError ;
    formErrors.cityError = this.state.city === "" || this.state.city === null || this.state.city === undefined ? "City is Required" : this.state. formErrors.cityError;
    formErrors.placeofBirthError = this.state.placeofBirth === "" || this.state.placeofBirth === null || this.state.placeofBirth === undefined ? "Place of Birth value is Required" : this.state.formErrors.placeofBirthError ;
    formErrors.birthDayError = this.state.birthDay === "" || this.state.birthDay === null || this.state.birthDay === undefined ? "Birthday value is Required" : this.state.formErrors.birthDayError;
    formErrors.genderError = this.state.gender === "" || this.state.gender === null || this.state.gender === undefined ? "Gender value is Required" : this.state.formErrors.genderError;
    formErrors.idNumberError = this.state.idNumber === "" || this.state.idNumber === null || this.state.idNumber === undefined ? "ID number is Required" : this.state.formErrors.idNumberError;
    formErrors.issueDateError = this.state.issueDate === "" || this.state.issueDate === null || this.state.issueDate === undefined ? "Issue Date value is Required" : this.state.formErrors.issueDateError;
    formErrors.latitudeError = this.state.latitude === "" || this.state.latitude === null || this.state.latitude === undefined ? "Latitude value is Required" : this.state.formErrors.latitudeError;
    formErrors.longitudeError = this.state.longitude === "" || this.state.longitude === null || this.state.longitude === undefined ? "Longitude value is Required" : this.state.formErrors.longitudeError;

    if (!this.state.isNewId) {

      formErrors.pageNoError = this.state.pageNo === "" || this.state.pageNo === null || this.state.pageNo === undefined ? "Page No Value is Required" : this.state.formErrors.pageNoError;
      formErrors.registryNoError = this.state.registryNo === "" || this.state.registryNo === null || this.state.registryNo === undefined ? "Registry No value is Required" : this.state.formErrors.registryNoError;

      formErrors.volumeNoError = this.state.volumeNo === "" || this.state.volumeNo === null || this.state.volumeNo === undefined ? "Volume No is Required" : this.state.formErrors.volumeNoError;

    }
    if (formValid(this.state.formErrors)) {

      var customerData = new FormData();
      customerData.append("regionId", this.state.regionId);
      customerData.append("firstName", this.state.firstName);
      customerData.append("middleName", this.state.middleName);
      customerData.append("lastName", this.state.lastName);
      customerData.append("companyName", this.state.companyName);
      customerData.append("mobileNo", this.state.mobileNo);
      customerData.append("city", this.state.city);
      customerData.append("type", this.state.type);
      customerData.append("attachment", this.state.currentFile);
      customerData.append("identityCard", this.state.currentFileIdentity);
      customerData.append("street", this.state.street);
      customerData.append("email", this.state.email);
      customerData.append("website", this.state.website);
      customerData.append("countryId", this.state.countryId);
      customerData.append("userId", localStorage.userId);
      customerData.append('birthDay', this.state.birthDay);
      customerData.append('gender', this.state.gender);
      customerData.append('placeofBirthValue', this.state.placeofBirth);
      customerData.append("issueDate", this.state.issueDate);
      customerData.append("idType", this.state.isNewId);
      customerData.append("pageNo", this.state.pageNo);
      customerData.append("registryNo", this.state.registryNo);
      customerData.append("idNumber", this.state.idNumber);
      customerData.append("volumeNo", this.state.volumeNo);
      customerData.append("oldAccountNo", this.state.oldAccountNo);
      customerData.append("latitude", this.state.latitude);
      customerData.append("longitude", this.state.longitude);

      //customerData.append("ledgerAccountId", this.state.selectOption.value);
      if (this.isTopup) {
        customerData.append("isTopup", 1);
      } else {
        customerData.append("isTopup", this.state.isTopup);
      }

    
     this.setState({ isLoading: true })
      CustomersService.createCustomer(customerData)
        .then(
          (res) => {
            // this.props.history.push('/verifyCustomer', { mobileNo: this.state.mobileNo });
            NotificationManager.success("Customer successfully registered", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({
              isLoading: false
            })
            e.target.reset();
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({
              isLoading: false
            })
          }
        )
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_SAVE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({
            isLoading: false
          })
        });
        
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  
  onChangeMobileNo = mobile => {

    const { formErrors } = this.state
    this.state.customerId = null

    this.state.mobileNo = mobile
    formErrors.mobileNoError =
      mobile.length == 0 || mobile.length < 6 || !mobileNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
    if (formErrors.mobileNoError.length === 0) {
      let mobilePhone = mobile
    }
    this.setState({
      formErrors
    })
  }

  handelInputChange = e => {

    e.preventDefault()
    const { name, value } = e.target
    let formErrors = this.state.formErrors;

    switch (name) {
      case 'firstName':
        formErrors.firstNameError =
          value.length == 0 || !nameRegEx.test(value) ? "" + " First name sholud only contain alphabets" : ""
        break;
      case 'middleName':
        formErrors.middleNameError =
          !middleNameRegEx.test(value) ? "" + " Middle name sholud only contain alphabets" : ""
        break;
      case 'lastName':
        formErrors.lastNameError =
          !nameRegEx.test(value) ? "" + " Last name sholud only contain alphabets" : ""
        break;
      case 'mobileNo':
        formErrors.mobileNoError =
          !mobileNoRegEX.test(value) ? "" + " Mobile number should only contain numbers" : ""
        break;
      case 'email':
        formErrors.emailError =
          !emailRegExp.test(value) ? "" + " Wrong email format" : ""
        break;
      case 'type':
        formErrors.customerTypeError =
          !this.state.type ? "" + " " : ""
        break;
      case 'countryId':
        formErrors.countryIdError =
          !this.state.countryId ? "" + " " : ""
        break;
      case 'regionId':
        formErrors.regionIdError =
          !this.state.regionId ? "" + " " : ""
        break;
      case 'city':
        formErrors.cityError =
          !this.state.city ? "" + " " : ""
        break;

      case 'idNumber':
        formErrors.idNumberError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'pageNo':
        formErrors.pageNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'registryNo':
        formErrors.registryNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'volumeNo':
        formErrors.volumeNoError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50" : ""
        break;
      case 'birthDay':
        formErrors.birthDayError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain date " : ""
        break;
      case 'issueDate':
        formErrors.issueDateError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain date " : ""
        break;
      case 'placeofBirth':
        formErrors.placeofBirthError =
          value.length == 0 || !numberRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50 " : ""
        break;
      case 'oldAccountNo':
        formErrors.oldAccountNoError =
          !OptionalRegEx.test(value) ? "" + name + " sholud contain alphanumeric with amin 3 and max 50 " : ""
        break;
      case 'longitude':
        formErrors.longitudeError =
          !amountRegEx.test(value) ? "" + name + " sholud contain decimal number only " : ""    
          break;
          case 'latitude':
            formErrors.latitudeError =
              !amountRegEx.test(value) ? "" + name + " sholud contain decimal number only " : ""    
              break;
      default:
        break;

    }

    this.setState({
      formErrors, [name]: value
    })
  }

  onChangeSuper() {
    this.setState({
      isOldId: this.state.isNewId,
      isNewId: !this.state.isNewId,
    })
  }

  onChangeAdmin() {
    this.setState({
      isNewId: this.state.isOldId,
      isOldId: !this.state.isOldId,
    })
  }

  actionGender(e) {
    let selectedGender = e.target.value
    this.state.gender = null
    if (selectedGender == "----please select---")
      selectedGender = ""

      this.state.formErrors.genderError = selectedGender == "" ? 'You must make a selection' : "";

    this.setState({
      gender: selectedGender
    });
  }

  render() {
    const { regionList, customerTypes, type, amCodeList, id, accountCode } = this.state;
    const { countryList, selectedFiles, selectedFilesIdentity } = this.state;
    const { formErrors, isNewId } = this.state

    // if (selectOption === null && id !== '' && accountCode !== '') {
    //   this.state.selectOption = {
    //     label: accountCode,
    //     value: id
    //   }
    // }
    return (

      <div>
        {/* <Header />
        <Menu /> */}

        <NotificationContainer />

        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-left">
                  <li className="breadcrumb-item">
                    <a href="/#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add Customer</li>
                </ol>
              </div>
              <div >
                <div className="modal-body pt-5 ">

                  <div className='col-1'></div>
                  <div className='col-8 bg-light p-3'>
                    {/*
                    <div class="modal-header">
                      <h4 class="modal-title" >
                         Agent Registration
                      </h4>
                    </div> */}
                    <form onSubmit={this.handleSubmit}>
                      {/*Basic Information*/}
                      <div className="card  border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Basic Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='firstName'>First Name:</label>
                              <input type="text" className="form-control " id='firstName' name='firstName'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />

                              {formErrors.firstNameError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.firstNameError}</span>
                              )}
                            </div>
                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='middleName'>Middle Name:</label>
                              <input type="text" className="form-control " id='middleName' name='middleName'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              {formErrors.middleNameError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.middleNameError}</span>
                              )}
                            </div>
                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='lastName'>Last Name:</label>
                              <input type="text" className="form-control " id='lastName' name='lastName'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              {formErrors.lastNameError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.lastNameError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='MobileNumber'>Mobile Number:</label>
                              <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                placeholder="Enter mobile number" id='mobileNo' name='mobileNo'
                                inputStyle={{ width: '100%', height: 'auto' }}
                                onChange={phone => this.onChangeMobileNo(phone)}
                              />
                              {formErrors.mobileNoError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.mobileNoError}</span>
                              )}
                            </div>
                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='CustomerType'>Customer Type:</label>

                              <select id='CustomerType' onChange={this.handelInputChange} onBlur={this.handelInputChange} className="form-control" name='type'>
                                <option value=""> Select customer type</option>
                                {customerTypes.map(type =>
                                  <option value={type.id}>{type.type}</option>
                                )}
                              </select>
                              {formErrors.customerTypeError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.customerTypeError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='companyName'>Company Name:</label>
                              {type !== undefined && type !== 'Merchant' &&
                                <input type="text" className="form-control " id='companyName' name='companyName'
                                  placeholder="Optional"
                                  onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              }
                              {type !== undefined && type === 'Merchant' &&
                                <input type="text" className="form-control " id='companyName' name='companyName'
                                  onChange={this.handelInputChange} />
                              }
                            </div>

                            <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6">
                              <label htmlFor='oldAccountNo'>Old Fin A/C #:</label>
                              <input type="text" className="form-control " id='oldAccountNo' name='oldAccountNo'
                                placeholder="Account No"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />

                              {formErrors.oldAccountNoError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.oldAccountNoError}</span>
                              )}
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='birthDay'> Date of Birth :</label>

                              <input type="date" id='birthDay' className="form-control" name='birthDay'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange}
                                placeholder="Date of Birth" />

                              {formErrors.birthDayError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.birthDayError}</span>
                              )}
                            </div>
                            <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6">
                              <label htmlFor='placeofBirth'>place of Birth:</label>
                              <input type="text" className="form-control " id='placeofBirth' name='placeofBirth'
                                placeholder="place of birth"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />

                              {formErrors.placeofBirthError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.placeofBirthError}</span>
                              )}
                            </div>
                            <div className=" form-group col-6 col-sm-6 col-md-6 col-lg-6">
                              <label htmlFor='gender'>Gender:</label>
                              <select value={this.state.gender}
                                onChange={e => this.actionGender(e)} className="form-control" name='gender'>
                                <option key={null} value={null}>----please select---</option>
                                <option key={"Male"} value={"Male"}>Male</option>
                                <option key={"Female"} value={"Female"}>Female</option>
                                <option key={"Other"} value={"Other"}>Other</option>
                              </select>

                              {formErrors.genderError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.genderError}</span>
                              )}
                            </div>

                            {  /* 
                            <div className=" card-header form-group col-12 col-sm-12 col-md-6 col-lg-6"> */}

                            <div className="form-group col-8 col-sm-6 col-md-6 col-lg-6">
                              <label htmlFor='status'>
                                <span style={{ color: 'blue' }}>Is eTop up customer?: </span></label>
                              <input type="Checkbox" className="form-control" id="status"
                                onChange={e => this.isTopup = e.target.value}
                                onBlur={this.handelInputChange}
                                placeholder="Top up Customer" />

                            </div>
                            {/* <div className="form-group col-8 col-sm-6 col-md-6 col-lg-6">
                              <label htmlFor='status'>Ledger Account: </label>
                       
                              <AsyncSelect id='CustomerList'
                                className=" form-group col-12 col-sm-12 col-md-12 col-lg-12  "
                                placeholder={ "Search by code" }
                                value={ this.state.value }
                                isClearable={ true }
                                loadOptions={ this.fatchData }

                                isLoading={ this.state.selectOption === {} &&
                                  this.state.selectOption === undefined &&
                                  this.state.selectOption === null ? true : false
                                }
                                onChange={ (e) => {
                                  this.onSearchChange(e)
                                } }
                                defaultOptions={ true }
                              />
                            </div> */}
                            {/* </div> */}
                            {/* <input className="form-check-input mt-5  ml-3" onChange={ e => this.isTopup = e.target.value } onBlur={ this.handelInputChange } type="Checkbox" />
                              <label className="form-check-label   pt-4 mt-3 ml-5"><span style={ { color: 'blue' } }>Is eTop up customer?</span></label> */}


                          </div>

                        </div>
                      </div>


                      {/*Basic Information*/}

                      {/*Address Information*/}
                      <div className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Address Information </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Country'>Country:</label>
                              <select onChange={this.handelInputChange} onBlur={this.handelInputChange} className="form-control" name='countryId'>
                                <option value=""> Choose Country</option>
                                {countryList.map(country =>
                                  <option value={country.id}>{country.countryName}</option>
                                )}
                              </select>
                              {formErrors.countryIdError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.countryIdError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Province'>Province:</label>
                              <select onChange={this.handelInputChange} onBlur={this.handelInputChange} className="form-control" name='regionId'>
                                <option value=""> Choose province</option>
                                {regionList.map(getregion =>
                                  <option value={getregion.id}>{getregion.region}</option>
                                )}
                              </select>
                              {formErrors.regionIdError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.regionIdError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='city'>City:</label>
                              <input type="text" className="form-control " id='city' name='city'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              {formErrors.cityError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.cityError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='email'>Email:</label>
                              <input type="text" className="form-control " id='email' name='email'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              {formErrors.emailError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.emailError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='Street'>Street:</label>
                              <input type="text" className="form-control " id='street' name='street'
                                placeholder="Optional"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='website'>Website:</label>
                              <input type="text" className="form-control " id='website' name='website'
                                placeholder="Optional"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Customer Identity </h6>
                        </div>
                        <div className="card-body">
                          <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor='serviceCode'> Id Number:</label>
                            <input type="text" id='idNumber' className="form-control" name='idNumber'
                              onChange={this.handelInputChange} onBlur={this.handelInputChange}
                              placeholder="Id Number" />

                            {formErrors.idNumberError.length > 0 && (
                              <span style={{ color: "#F61C04" }}>{formErrors.idNumberError}</span>
                            )}
                          </div>
                          <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6" >
                            <label style={{ marginLeft: "20px" }} htmlFor='nwId'>New Id Card:  </label>
                            <input type="Checkbox" style={{ marginLeft: "10px" }} id="newId"
                              checked={this.state.isNewId}
                              onChange={e => { this.onChangeSuper(); this.state.isNewId = e.target.checked }}
                            />

                            <label style={{ marginLeft: "20px" }} htmlFor='oldId'>Old Id Card: </label>
                            <input type="Checkbox" style={{ marginLeft: "10px" }} id="IsOldId"
                              checked={this.state.isOldId}
                              onChange={e => { this.onChangeAdmin(); this.state.isOldId = e.target.checked }}
                            />

                          </div>

                          <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='issueDate'> Issue Date:</label>

                              <input type="date" id='issueDate' className="form-control" name='issueDate'
                                onChange={this.handelInputChange} onBlur={this.handelInputChange}
                                placeholder="Issue Date" />

                              {formErrors.issueDateError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.issueDateError}</span>
                              )}
                            </div>
                            {!isNewId ?
                              <>
                                <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                  <label htmlFor='pageNo'>Page No:</label>
                                  <input type="text" id='pageNo' className="form-control" name='pageNo'
                                    onChange={this.handelInputChange} onBlur={this.handelInputChange}
                                    placeholder="Page No" />

                                  {formErrors.pageNoError.length > 0 && (
                                    <span style={{ color: "#F61C04" }}>{formErrors.pageNoError}</span>
                                  )}
                                </div>
                                <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                  <label htmlFor='registryNo'>Registry No:</label>
                                  <input type="text" id='registryNo' className="form-control" name='registryNo'
                                    onChange={this.handelInputChange} onBlur={this.handelInputChange}
                                    placeholder="Registry No" />

                                  {formErrors.registryNoError.length > 0 && (
                                    <span style={{ color: "#F61C04" }}>{formErrors.registryNoError}</span>
                                  )}
                                </div>
                                <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                  <label htmlFor='currency'>Volume No:</label>
                                  <input type="text" id='volumeNo' className="form-control" name='volumeNo'
                                    onChange={this.handelInputChange} onBlur={this.handelInputChange}
                                    placeholder="Volume No" />

                                  {formErrors.volumeNoError.length > 0 && (
                                    <span style={{ color: "#F61C04" }}>{formErrors.volumeNoError}</span>
                                  )}
                                </div>
                              </>
                              :
                              ""
                            }
                          </div>
                        </div>
                      </div>
                      {/*Address Information*/}
                      {/*Location */}
                      <div className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >Location </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='latitude'>Latitude:</label>
                              <input type="text" className="form-control" name="latitude"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                              {formErrors.latitudeError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.latitudeError}</span>
                              )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='longitude'>Longitude:</label>
                              <input type="text" className="form-control" name="longitude"
                                onChange={this.handelInputChange} onBlur={this.handelInputChange}
                              />
                              {formErrors.longitudeError.length > 0 && (
                                <span style={{ color: "#F61C04" }}>{formErrors.longitudeError}</span>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                      {/* Location*/}
                      <div className="card my-4 border-radius-0" >
                        <div className="card-header border-radius-0 bg-light">
                          <h6 class="title " >File Attachment </h6>
                        </div>
                        <div className="card-body">
                          <div className='row'>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='profilePic'>Profile Picture:</label>
                              {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleAttachmentChange} /> */}
                              {/* <FileUploader type="file"onDrop={this.onDrop} label="Drag and Drop file Or Select " classes="Drag and Drop" maxSize="2mb" onDrop={(file) => console.log(file)} onSelect={(file) => console.log(file)} multiple={false} name="attachment" /> */}

                              <Dropzone accept={".JPEG,.jpeg,.JPG,.jpg,.PNG,.png,.GIF,.gif"}
                              onDrop={this.onDrop}
                                multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps({ className: "dropzone btn btn-light border-dotted btn-square" })} style={{ border: "thin dashed black" }}>
                                      <input {...getInputProps()} />
                                      {selectedFiles && selectedFiles[0].name ? (
                                        <div className="selected-file border-radius-1">
                                          {selectedFiles && selectedFiles[0].name}
                                        </div>
                                      ) : (
                                        "Drag and drop file here, or click to select file"
                                      )}
                                    </div>
                                    <aside className="selected-file-wrapper">
                                    </aside>
                                  </section>
                                )}
                              </Dropzone>
                              <label style={{ fontSize: 'smaller' }} >N.B upload  image file only</label>
                              {formErrors.profileError.length > 0 && (
                                 <span style={{ color: "#F61C04" }}>{formErrors.profileError}</span>
                               )}
                            </div>

                            <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='IdentityCard'>Indentity Card:</label>
                              {/* <input type="file" className="form-control" placeholder="Attachment" onChange={this.handleIdentityChange} /> */}
                              <Dropzone accept={".JPEG,.jpeg,.JPG,.jpg,.PNG,.png,.GIF,.gif"}
                                onDrop={this.onDropIdentity}
                                multiple={false}>

                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps({ className: "dropzone btn btn-out-dashed btn-light btn-square" })} style={{ border: "thin dashed black" }}>
                                      <input {...getInputProps()} />
                                      {selectedFilesIdentity && selectedFilesIdentity[0].name ? (
                                        <div className="selected-file">
                                          {selectedFilesIdentity && selectedFilesIdentity[0].name}
                                        </div>
                                      ) : (
                                        "Drag and drop file here, or click to select file"
                                      )}
                                    </div>
                                    <aside className="selected-file-wrapper">

                                    </aside>
                                  </section>
                                )}
                              </Dropzone>
                              <label style={{ fontSize: 'smaller' }} >N.B upload  image file only</label>
                    
                              {formErrors.identityCardError.length > 0 && (
                                 <span style={{ color: "#F61C04" }}>{formErrors.identityCardError}</span>
                               )}
                            </div>

                          </div>
                        </div>
                      </div>
                      {/*File Attachment*/}
                      {/* <div class="btn-group d-flex justify-content-center clear-fix">
                        <button type="submit" class="btn btn-success mr-5 ml-5 mt-4 pl-2">Save</button>
                        <button type="submit" class="btn btn-warning mr-5 mt-4 px-2" onClick={ () => this.Cancel() }>Cancel</button>
                      </div> */}

                      <div className="row  d-flex justify-content-left clear-fix ">
                        <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={{ marginLeft: "30px" }}>Save</button>
                        <button type="submit" onClick={() => this.Cancel()}
                          className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
              {this.state.isLoading && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
               />}

              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}

export default CustomerRegistration;
