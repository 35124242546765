import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Menu from '../Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import RollbackRequestService from '../../../services/wallet/RollbackRequestService';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import Popup from '../Popup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class RollbackRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {

            showSenderCustomer: false,
            showReceiverCustomer: false,
            showKisok: false,
            showReceiverMobileNo: false,

            statusDescription: '',
            showAllTrxns: false,
            isLoading: false,

            senderCustomerSelectOption: null,
            receiverCustomeSelectOption: null,
            kioskSelectOption: null,
            trxnRefSelectOption: null,
            status: 0,
            isProcessed: true,
            isRejected: false,

            receiverMobileNo: null,
            trxnType: null,
            trxnDate: null,

            rollbackRequests: null,
            trxnTypeDefaultValue: 0,
            trxnType: 0,

            pageNo: 1,
            pageSize: LOCALES.PAGE_SIZE,
            totalPage: 0,
            totalelement: 0,
            counter: 1,
            id: 0,
            isExpand: true,
            formErrors: {
                dateFromError: "",
                dateToError: ""
            },

        }
        this.clearValue = this.clearValue.bind(this);
    }

    gotoEnteredPage = (pageNumber) => {
        this.state.pageNo = pageNumber
        this.searchRollbackRequestBasedOnFilter()
        this.render()
    }
    gotoPageLast = () => {
        this.state.pageNo = this.state.totalPage
        this.searchRollbackRequestBasedOnFilter()
        this.render()

    }
    gotoPageFirst = () => {
        this.state.pageNo = 1
        this.searchRollbackRequestBasedOnFilter()
        this.render()
    }

    privPage = () => {
        let pages = 1
        if (this.state.pageNo > pages) {
            this.state.pageNo = this.state.pageNo - pages
            this.searchRollbackRequestBasedOnFilter()
            this.render()
        }
    }

    nextPage = () => {
        let totalPages = this.state.totalPage;
        if (this.state.pageNo < totalPages) {
            this.state.pageNo = this.state.pageNo + 1
            this.searchRollbackRequestBasedOnFilter()
            this.render()
        }
    }
    clearValue() {
        this.state.senderCustomerId = null
        this.state.receiverCustomerId = null
        this.state.kioskId = null
        this.state.trxnRefId = null
        this.state.receiverMobileNo = null
        this.state.trxnType = null
        this.state.dateFrom = null
        this.state.dateTo = null
        this.state.clear = 1
    }
    clear = e => {
        e.preventDefault()
        if (this.state.clear == 1) {
            e.target.reset();
            this.state.clear = 0
            this.searchRollbackRequestBasedOnFilter()
        }
    }

    fetchCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            customerList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        }
    }

    onCustomerSearchChange = (customerSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = customerSelectOption == "" || customerSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            customerSelectOption: customerSelectOption,
            formErrors
        });
    }


    fetchSenderCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            senderCustomerList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        }
    }

    onSenderCustomerSearchChange = (senderCustomerSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = senderCustomerSelectOption == "" || senderCustomerSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            senderCustomerSelectOption: senderCustomerSelectOption,
            formErrors
        });
    }

    fetchReceiverCustomerData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getCustomerBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            receiverCustomerList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        }
    }

    onReceiverCustomerSearchChange = (receiverCustomeSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = receiverCustomeSelectOption == "" || receiverCustomeSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            receiverCustomeSelectOption: receiverCustomeSelectOption,
            formErrors
        });
    }

    fetchKioskData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findKioskBySearchParam/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.kioskId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            kioskList: data,

                        })
                    })
                    .catch((error) => {

                    })
            })
        }
    }

    onKioskSearchChange = (kioskSelectOption) => {
        const { formErrors } = this.state
        formErrors.customerIdError = kioskSelectOption == "" || kioskSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            kioskSelectOption: kioskSelectOption,
            formErrors
        });
    }

    fetchTrxnRefData = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/findTransactionRefBySearchValue/" + inputValue + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.trxnRefId, value: element.id })
                        });
                        callback(temp)
                        this.setState({
                            trxnRefList: data,
                        })
                    })
                    .catch((error) => {

                    })
            })
        }
    }

    onTrxnRefSearchChange = (trxnRefSelectOption) => {
        const { formErrors } = this.state

        formErrors.trxnRefIdError = trxnRefSelectOption == "" || trxnRefSelectOption == null ? 'You must make a selection' : "";
        this.setState({
            trxnRefSelectOption: trxnRefSelectOption,
            formErrors
            // input: trxnRefSelectOption.target.value.replace(/\D/g,'')
        });
    }

    handelInputChange = e => {
        e.preventDefault();
        const { name, value } = e.target
        // this.setState({
        //     formErrors, [ name ]: value
        // })
    }

    componentDidMount() {
        this.searchRollbackRequestBasedOnFilter()
    }

    searchRollbackRequestInfo(e) {
        this.state.trxnType = e.target.value
        this.state.pageNo = 1;
        this.searchRollbackRequestBasedOnFilter()
    }

    searchRollbackRequestBasedOnFilter() {

        if (this.state.dateFrom != null && this.state.dateTo == null) {
            this.setState({ dateTo: moment().format("yyyy-MM-DD") });
        }
        else if (this.state.dateFrom == null && this.state.dateTo != null) {
            NotificationManager.error("Please enter the start date", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
        }
        else {

            let rollbackRequestSearchParams = {
                senderCustomerId: this.state.senderCustomerSelectOption ? this.state.senderCustomerSelectOption.value : this.state.senderCustomerSelectOption,
                receiverCustomerId: this.state.receiverCustomeSelectOption ? this.state.receiverCustomeSelectOption.value : this.state.receiverCustomeSelectOption,
                kioskId: this.state.kioskSelectOption ? this.state.kioskSelectOption.value : this.state.kioskSelectOption,
                trxnRefId: this.state.trxnRefSelectOption ? this.state.trxnRefSelectOption.value : this.state.trxnRefSelectOption,
                receiverMobileNo: this.state.receiverMobileNo,
                trxnType: this.state.trxnType == this.state.trxnTypeDefaultValue ? `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}` : this.state.trxnType,
                trxnDate: this.state.trxnDate
            }
           
            this.state.rollbackRequests =[]
            RollbackRequestService.findAllRollbackRequests(
                this.state.pageNo, this.state.pageSize, localStorage.userId, rollbackRequestSearchParams)
                .then(response => response.data)
                .then((data) => {
                    this.setState({
                        isLoading: true,
                        rollbackRequests: data.rollbackRequests,
                        isOpen: false,
                        totalPage: data.totalPages,
                        totalelement: data.totalelement,
                        pageNo: data.pageNumber + 1
                    });

                    this.setState({ isLoading: true })
                });
        }
    }

    filterToggle() {
        this.setState({
            isExpand: !this.state.isExpand
        });
    }

    onChangeMobileNo = mobile => {

        const { formErrors } = this.state
        this.state.customerId = null

        this.state.receiverMobileNo = mobile
        formErrors.phoneNumberError =
            mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
        if (formErrors.phoneNumberError.length === 0) {
            let mobilePhone = mobile
            //this.getCustomerBankInfo(mobilePhone)
        }
        this.setState({
            formErrors
        })
    }

    togglePopup = (id, status) => {
        this.state.id = id
        this.state.status = status

        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    process() {
        if (this.state.isProcessed) {
            this.state.status = LOCALES.SMARTPAY_CASH_APPROVED
        }
        if (this.state.isRejected) {
            this.state.status = LOCALES.SMARTPAY_CASH_APPROVEREJECT
        }

        let cashInData = {
            cashStatus: this.state.status,
            approveDescription: this.state.statusDescription,
            userId: localStorage.userId
        }

        RollbackRequestService.processRollbackRequest(this.state.id, cashInData)
            .then(
                (res) => {

                    NotificationManager.success(`${process.env.REACT_APP_PROCESS_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    this.setState({
                        isOpen: false
                    })
                    this.searchRollbackRequestBasedOnFilter();


                },
                (err) => {
                    NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                })
            .catch(function (response) {
                NotificationManager.error(`${process.env.REACT_APP_PROCESS_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            });

    }

    onChangeSuper() {
        this.setState({
            isRejected: this.state.isProcessed,
            isProcessed: !this.state.isProcessed,
        })
    }

    onChangeAdmin() {
        this.setState({
            isProcessed: this.state.isRejected,
            isRejected: !this.state.isRejected,
        })
    }


    render() {
        const { rollbackRequests, isLoading, pageNo, totalPage } = this.state;
        this.state.counter = pageNo

        this.state.showSenderCustomer = this.state.trxnType == this.state.trxnTypeDefaultValue || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}` || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}` || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`
            || this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}`;

        this.state.showReceiverCustomer = this.state.trxnType == this.state.trxnTypeDefaultValue
            || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}`
            || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}`
            || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`
            || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`;


        this.state.showKisok = this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}`
            || this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`
            || this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`;

        this.state.showReceiverMobileNo = this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`
            || this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}`
            || this.state.trxnType === `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}`;


        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={90} width={90} />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    <div className="content-wrapper bg-white">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active">Rollback Request List</li>
                                        </ol>
                                    </div>

                                </div>
                                {/* <h3 className="m-0 float-left" style={ { fontFamily: 'normal' } }>Agents</h3> */}
                                <div className="card">
                                    <div className="card-body">
                                        {/* { this.state.trxnType==null &&
                                        <div className="form-title">Request List</div>
                                    }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_TOPUP}` &&
                                        <div className="form-title">top Up History  </div>
                                    }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_CASHIN}` &&
                                        <div className="form-title">cash In History </div>
                                     }
                                    { this.state.trxnType==`${process.env.REACT_APP_KIOSK_TRXN_PAYBILL}` &&
                                        <div className="form-title">pay Bill History </div>
                                     } */}
                                        <form onSubmit={this.clear} hidden={this.state.isExpand} >
                                            <div className="row" hidden={this.state.isExpand}>

                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='senderCustomerList'>Sender Customer</label>
                                                    <AsyncSelect id='senderCustomerList'
                                                        placeholder={"Search account or Mobile no"}
                                                        // value={ this.state.customerSelectOption }
                                                        isClearable={true}
                                                        loadOptions={this.fetchSenderCustomerData}
                                                        isLoading={this.state.senderCustomerSelectOption == {} &&
                                                            this.state.senderCustomerSelectOption === undefined &&
                                                            this.state.senderCustomerSelectOption === null ? true : false
                                                        }
                                                        onChange={(e) => {
                                                            this.onSenderCustomerSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='receiverCustomerList' >Receiver Customer:</label>
                                                    <AsyncSelect id='receiverCustomerList'
                                                        placeholder={"Search account or Mobile no"}
                                                        // value={ this.state.customerSelectOption }
                                                        isClearable={true}
                                                        loadOptions={this.fetchReceiverCustomerData}
                                                        isLoading={this.state.receiverCustomeSelectOption == {} &&
                                                            this.state.receiverCustomeSelectOption === undefined &&
                                                            this.state.receiverCustomeSelectOption === null ? true : false
                                                        }
                                                        onChange={(e) => {
                                                            this.onReceiverCustomerSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='startDate' >Kiosk Id:</label>
                                                    <AsyncSelect id='kioskList'
                                                        required
                                                        placeholder={"Search kioskId"}
                                                        value={this.state.kioskSelectOption}
                                                        isClearable={true}
                                                        loadOptions={this.fetchKioskData}
                                                        isLoading={this.state.kioskSelectOption == {} &&
                                                            this.state.kioskSelectOption === undefined &&
                                                            this.state.kioskSelectOption === null ? true : false
                                                        }
                                                        onChange={(e) => {
                                                            this.onKioskSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='startDate' >Trxn RefId:</label>
                                                    <AsyncSelect id='trxnRefList'
                                                        placeholder={"Search by transaction ref"}
                                                        value={this.state.trxnRefSelectOption}
                                                        isClearable={true}
                                                        loadOptions={this.fetchTrxnRefData}
                                                        isLoading={this.state.trxnRefSelectOption == {} &&
                                                            this.state.trxnRefSelectOption === undefined &&
                                                            this.state.trxnRefSelectOption === null ? true : false
                                                        }
                                                        onInputChange={this.handleInputChange}
                                                        onChange={(e) => {
                                                            this.onTrxnRefSearchChange(e)
                                                        }}
                                                        defaultOptions={false}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='startDate' >Mobile No:</label>
                                                    <PhoneInput countryCodeEditable={false} value={this.state.mobileNo}
                                                        country={`${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}`}
                                                        placeholder="Enter mobile number" id='mobileNo' name='mobileNo'
                                                        inputStyle={{ width: '100%', height: 'auto' }}
                                                        onChange={phone => this.onChangeMobileNo(phone)}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-4'>
                                                    <label htmlFor='startDate' >Trxn Date:</label>
                                                    <div className="w-90 pull-left">
                                                        <input type="date" className="form-control" id='trxnDate' name="trxnDate"
                                                            onChange={this.handelInputChange} onBlur={this.handelInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-5'></div>
                                                <div className='col-12 col-sm-8 col-md-4 col-lg-5'></div>
                                                <div className='col-12 col-sm-8 col-md-2 col-lg-2 mt-2'>
                                                    <button type='submit' onClick={this.clearValue.bind(this)} title={"Clear"}
                                                        className="btn btn-sm btn-danger primary right"  >Clear</button>
                                                    <button title={"Search"}
                                                        className="btn btn-sm btn-success primary right" style={{ marginLeft: '3px' }}
                                                        onClick={() => this.searchRollbackRequestInfo()}>Search</button>
                                                </div>
                                            </div>
                                        </form>
                                        <div style={{ clear: 'both' }}></div>
                                        <br />
                                        <Link to={{ pathname: '/addRollbackRequest', state: { trxnType: this.state.trxnType } }}>
                                            <button type="button" className="btn btn-sm btn-success primary float-left mb-2" 
                                           style={{ height: '33px' }} title="Add Request">
                                               
                                                <FontAwesomeIcon icon={faPlus} />
                                                Add
                                            </button>
                                        </Link>
                                        {!(this.state.trxnType == this.state.trxnTypeDefaultValue) &&
                                            <button type='button' style={{ marginLeft: "3px" }} title={"Show/Hide Filter"}
                                                className="btn btn-sm btn-success primary float-left mb-2"
                                                onClick={() => this.filterToggle()}>
                                                <FontAwesomeIcon icon={faFilter} />
                                                Filter
                                            </button>
                                        }
                                        {/* <label className='float-right light ml-3'> <span style={ { fontWeight: "lighter" } }> Kiosk Id :</span><span style={ { fontWeight: "bolder" } }>{ "searchKioskId" }</span></label> */}
                                        <div className='row  no-gutters float-right mb-2'>
                                            <div className='col-4 sm-12 lg-4'> <label><span style={{ fontWeight: "lighter" }}>Trxn Type:</span></label> </div>
                                            <div className='col-7 sm-12 lg-7 pr-5'>
                                                <select 
                                                    onChange={e => this.searchRollbackRequestInfo(e) }
                                                     className="form-control" name='trxnType' style={{ height: "95%", width: "auto" }}>
                                                    <option value={this.state.trxnTypeDefaultValue}>Select Trxn Type</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_TRANSFER_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_TRANSFER_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_WALLET_AIRTIME_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_TOPUP_AIRTIME_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_AIRTIME_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_LABEL}`}</option>
                                                    <option value={`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`}>{`${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_LABEL}`}</option>
                                                </select>
                                            </div>
                                            <div className='col-1 sm-12 lg-1'>

                                            </div>
                                        </div>
                                        <table className="table table-striped table-birdered pt-2" style={{ fontSize: 'smaller' }}  >
                                            <thead className='thead-light'>
                                                <tr style={{ marginTop: 0 }}>
                                                    {this.state.showSenderCustomer &&
                                                        <th>Sender Name</th>
                                                    }
                                                    {this.state.showReceiverCustomer && !(this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}` || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`) &&
                                                        <th>Receiver Name</th>
                                                    }
                                                    {this.state.showKisok &&
                                                        <th>Kiosk ID</th>
                                                    }
                                                    {this.state.showReceiverCustomer && (this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}` || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`) &&
                                                        <th>Receiver Name</th>
                                                    }
                                                    {this.state.showReceiverMobileNo &&
                                                        <th>Receiver Mobile</th>
                                                    }
                                                    <th>Amount</th>
                                                    <th>Trxn Ref.</th>
                                                    <th>Status</th>
                                                    <th>Request Id</th>
                                                    <th>Description</th>
                                                    <th>Remark</th>
                                                    <th>Date</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rollbackRequests && rollbackRequests.map(list =>
                                                    <tr>
                                                        {this.state.showSenderCustomer &&
                                                            <td >{ list.senderFullName}</td>
                                                        }
                                                        {this.state.showReceiverCustomer && !(this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_CASHIN_VALUE}` || this.state.trxnType == `${process.env.REACT_APP_ROLLBACK_REQUEST_KIOSK_PAYBILL_VALUE}`) &&
                                                            <td >{ list.recevierFullName}</td>
                                                        }
                                                        {this.state.showKisok &&
                                                            <td >{ list.kioskId}</td>
                                                        }
                                                      
                                                        {this.state.showReceiverMobileNo &&
                                                            <td >{list.receiverMobileNo}</td>
                                                        }
                                                        <td >
                                                            <NumberFormat value={list.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={''} />
                                                        </td>
                                                        <td >{list.trxnRefId}</td>
                                                        <td>

                                                            {list.status === LOCALES.CASH_STATUS_REGISTERED ? "Pending" : ""}
                                                            {list.status === LOCALES.SMARTPAY_CASH_APPROVED ? "Approved" : ""}
                                                            {list.status === LOCALES.SMARTPAY_CASH_APPROVEREJECT ? "Rejected" : ""}
                                                            {list.status === LOCALES.SMARTPAY_ROLLBACK_PROCESS ? "Rollback done" : ""}

                                                        </td>

                                                        <td >{list.requestId}</td>
                                                        <td >{list.description}</td>
                                                        <td >{list.remark}</td>
                                                        <td >{list.traxnDate2}</td>
                                                        <td>




                                                            <button
                                                                disabled={
                                                                    list.status === LOCALES.SMARTPAY_CASH_APPROVED ||
                                                                    list.status === LOCALES.SMARTPAY_CASH_APPROVEREJECT ||
                                                                    list.status === LOCALES.SMARTPAY_ROLLBACK_PROCESS

                                                                }
                                                                title={"Approve"}
                                                                style={{ marginLeft: "2px" }}
                                                                onClick={() => this.togglePopup(list.id, list.status)} className="btn btn-success btn-sm action-buttons">
                                                                <FontAwesomeIcon icon={faCheckDouble} />
                                                            </button>


                                                            <Link

                                                                to={{ pathname: '/editRollbackRequest', state: { id: list.id } }}>
                                                                <button disabled={
                                                                    list.status === LOCALES.SMARTPAY_CASH_APPROVED ||
                                                                    list.status === LOCALES.SMARTPAY_CASH_APPROVEREJECT ||
                                                                    list.status === LOCALES.SMARTPAY_ROLLBACK_PROCESS

                                                                } style={{ marginLeft: "2px" }} className="btn btn-warning btn-sm action-buttons"><i className="nav-icon fas fa-edit fa-sm" title="Edit request" /> </button>
                                                            </Link>


                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                        <div className="clearfix">&nbsp;</div>
                                        <div className=" float-sm-center">
                                            <span>
                                                page{''}
                                                <strong>
                                                    {pageNo} of {totalPage}
                                                </strong>
                                                {''}
                                            </span>
                                            <span>
                                                | Go To Page : {''}
                                                <input type='number' defaultValue={pageNo}
                                                    onChange={(e) => {
                                                        const pageNumber = e.target.value ? Number(e.target.value) : 0
                                                        this.gotoEnteredPage(pageNumber)
                                                    }} />
                                            </span>
                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.gotoPageFirst()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackwardFast} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.privPage()} disabled={pageNo === 1 ? true : false}>
                                                <FontAwesomeIcon icon={faBackward} />
                                            </button>

                                            <input type={"number"} style={{ marginLeft: "2px", textAlign: 'center' }} className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={pageNo} value={pageNo} readOnly />

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1  " onClick={() => this.nextPage()} disabled={pageNo === totalPage ? true : false}>
                                                <FontAwesomeIcon icon={faForward} />
                                            </button>

                                            <button style={{ marginLeft: "2px" }} className="btn btn-info px-1 py-1 " onClick={() => this.gotoPageLast()} disabled={pageNo === totalPage ? true : false} >
                                                <FontAwesomeIcon icon={faForwardFast} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.isOpen && <Popup
                                    content={<>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row" >
                                                    <label style={{ marginLeft: "20px" }} htmlFor='status'>Approve:  </label>
                                                    <input type="Checkbox" style={{ marginLeft: "10px" }} id="status"
                                                        checked={this.state.isProcessed}
                                                        onChange={e => { this.onChangeSuper(); this.state.isProcessed = e.target.checked }}
                                                    />

                                                    <label style={{ marginLeft: "20px" }} htmlFor='Reject'>Reject: </label>
                                                    <input type="Checkbox" style={{ marginLeft: "10px" }} id="Reject"
                                                        checked={this.state.isRejected}
                                                        onChange={e => { this.onChangeAdmin(); this.state.isRejected = e.target.checked }}
                                                    />

                                                </div>
                                                <div className="row" >
                                                    <div className="form-group col-8 col-sm-6 col-md-6 col-lg-12">
                                                        <label htmlFor='Description'>Description:</label>
                                                        <textarea type="" className="form-control" id='Description'
                                                            onChange={e => this.state.statusDescription = e.target.value}
                                                            placeholder="Description" />
                                                    </div>
                                                </div>
                                                <div className="row  d-flex justify-content-left ">
                                                    <button type="submit" style={{ marginLeft: "20px" }}
                                                        onClick={() => this.process()}
                                                        className="btn btn-success px-4 py-1">Submit</button>
                                                    <button type="submit" onClick={() => this.togglePopup()}
                                                        className="btn btn-warning  px-3 py-2" style={{ marginLeft: "10px" }}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                />}
                                {/* /.row */}
                            </div>
                            {/* /.container-fluid */}
                        </div>

                        {/* /.content */}
                    </div>
                </div >

            )
        }
    }
}
export default RollbackRequest