import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from "react-router-dom";
import Menu from '../../wallet/Menu';
import Header from '../../common/Header';
import ReactLoading from "react-loading";
import KioskService from '../../../services/wallet/KioskService';
import "react-datepicker/dist/react-datepicker.css";
import LoadingPopup from '../LoadingPopup';

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
class UpdateKiosk extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            isLoading: false,
            isLoadingPop: false,
            kioskData: {
                id: '',
                name: null,
                kioskId: '',
                serialNumber: '',
                address: '',
                latitude: '',
                longitude: '',
                status: '',
                formErrors: {
                    kioskIdError: "",
                    serialNumberError: "",
                    statusError: ""
                },
            },
            getKiosk: [],
        };

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeKioskId = this.onChangeKioskId.bind(this);
        this.onChangeSerialNo = this.onChangeSerialNo.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeLatitude = this.onChangeLatitude.bind(this);
        this.onChangeLongitude = this.onChangeLongitude.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }


    onChangeName(e) {
        const name = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    name: name
                }
            };
        });
    }
    onChangeKioskId(e) {
        const kioskId = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    kioskId: kioskId
                }
            };
        });
    }

    onChangeSerialNo(e) {
        const serialNumber = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    serialNumber: serialNumber
                }
            };
        });
    }
    onChangeAddress(e) {
        const address = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    address: address
                }
            };
        });
    }

    onChangeLatitude(e) {
        const latitude = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    latitude: latitude
                }
            };
        });
    }
    onChangeLongitude(e) {
        const longitude = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    longitude: longitude
                }
            };
        });
    }

    onChangeStatus(e) {
        const status = e.target.value;

        this.setState(function (prevState) {
            return {
                kioskData: {
                    ...prevState.kioskData,
                    status: status
                }
            };
        });
    }


    Cancel = (e) => {
        this.props.history.push('/kioskList');
    }


    componentDidMount() {
        this.getKioskById(this.props.location.state.id);
    }
    getKioskById(id) {
        KioskService.getKioskById(id, localStorage.userId)
            .then(response => {
                this.setState({
                    kioskData: response.data,
                    isLoading: true,
                });
              
                this.setState({ isLoading: true });
            })
            .catch(e => {
                
            });
    }
    handleSubmit = e => {

        e.preventDefault();

        var data = new FormData();
        data.append("id", this.props.location.state.id);
        data.append("name", this.state.kioskData.name);
        data.append("kioskId", this.state.kioskData.kioskId);
        data.append("serialNumber", this.state.kioskData.serialNumber);
        data.append("address", this.state.kioskData.address);
        data.append("latitude", this.state.kioskData.latitude);
        data.append("longitude", this.state.kioskData.longitude);
        data.append("status", this.state.status);
        data.append("userId", localStorage.userId);

 
        this.setState({ isLoadingPop: true})
        KioskService.updateKiosk(data)
            .then(
            (response) => {
                NotificationManager.success('Updated Successfully', `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                this.setState({ isLoadingPop: false})
                this.Cancel()
            }, 
            (err) => {
                NotificationManager.error(err.response.data.error,`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoadingPop: false})
            })
            .catch(function (err) {
                NotificationManager.error(err.response.data.error,`${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                this.setState({ isLoadingPop: false})
            });
    }


    render() {

        const { kioskData, isLoading, formErrors } = this.state;
       
        if (!isLoading) {
            return (
                <div>
                    {/* <Header />
                    <Menu /> */}
                    <div className='content-wrapper'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5 col-lg-5'></div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'></div>
                        </div>
                    </div>
                </div>
            )
        }
        else {

            return (

                <div>
                    {/* <Header />
                    <Menu />
                    <NotificationContainer /> */}
                    {/* Content Wrapper. Contains page content */ }
                    <div className="content-wrapper bg-white">
                        {/* Content Header (Page header) */ }
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">

                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-left">
                                            <li className="breadcrumb-item">
                                                <a href="/#">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active"> Kiosk Update</li>

                                        </ol>
                                    </div>

                                </div>
                                <div className="modal-body pt-1 ">
                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit } >
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0 ">
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >Update Kiosk</h6>
                                                </div>
                                                <div className="card-body ">
                                                    <div className='row'>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='name'>Name:</label>
                                                            <input type="text" id="name" className="form-control " value={ kioskData.name } onChange={ this.onChangeName } />
                                                        </div> */}
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='kioskId'>Kiosk ID:</label>
                                                            <input type="text" id="kioskId" className="form-control " value={ kioskData.kioskId } onChange={ this.onChangeKioskId } />
                                                            {/* { formErrors.titleError == "" && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.titleError }</span>
                                                            ) } */}
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='serialNumber'>Serial No:</label>
                                                            <input type="text" id="serialNumber" className="form-control " value={ kioskData.serialNumber } onChange={ this.onChangeSerialNo } />
                                                            {/* { formErrors.titleError == "" && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.titleError }</span>
                                                            ) } */}
                                                        </div>
                                                    </div>
           
                                                    <div className='row'>

                                                        <div class="form-group form-group-sm col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='location' className='ml-5 pl-5'>Location:</label>
                                                            <div class="row no-gutters">
                                                                <label htmlFor="latitude" class="col-sm-5 col-form-label">Latitude</label>
                                                                <div class="col-sm-7">
                                                                    <input type="text" class="form-control" id="latitude" value={ kioskData.latitude } onChange={ this.onChangeLatitude } />
                                                                </div>
                                                            </div>
                                                            <div class="row no-gutters mt-3">
                                                                <label htmlFor="longitude" class="col-sm-5 col-form-label">Longitude</label>
                                                                <div class="col-sm-7">
                                                                    <input type="text" class="form-control" id="longitude" value={ kioskData.longitude } onChange={ this.onChangeLongitude } />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='address'>Address:</label>
                                                            <input type="text" id="address" className="form-control " value={ kioskData.address } onChange={ this.onChangeAddress } />
                                                        </div>
                                                        {/* <div className=" form-group col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <label htmlFor='status'>Status:</label>
                                                            <select id='status' value={ kioskData.status } onChange={ this.onChangeStatus } className="form-control" >
                                                                <option value=""> Select status</option>
                                                                <option value="Active"> Active</option>
                                                                <option value="Inactive"> Inactive</option>
                                                                <option value="Warning"> Warning</option>
                                                            </select>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row  d-flex justify-content-left clear-fix ml-5">
                                                <button disabled={this.state.isLoadingPop } type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>



                                <div >
                                </div>
                                {this.state.isLoadingPop && <LoadingPopup
                                    content={<>
                                            <form>
                                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                                </form>
                                    </>}
                                    />}

                                {/* /.row */ }
                            </div>
                            {/* /.container-fluid */ }
                        </div>

                        {/* /.content */ }
                    </div>
                </div>

            )
        }
    }
}
export default withRouter(UpdateKiosk);