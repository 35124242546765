import API from "../../http-common";


class ReportService {

  getGenerateCashReport(reportParams) {
    return API.post("/api/v1/generateCashReport", reportParams);
  }  

  getGenerateAccountLedgerReport(reportParams) {
    return API.post("/api/v1/generateAcoountLedgerReport", reportParams);
  }  

  getGenerateAccountReport(reportParams) {
    return API.post("/api/v1/generateLedgerAccountReport", reportParams);
  }  

  getGenerateCustomerReport(reportParams) {
    return API.post("/api/v1/generateCustomerReport", reportParams);
  } 

  generateDealerReport(reportParams) {
    return API.post("/api/v1/generateDealerReport", reportParams);
  } 

  generateTestReport(reportParams) {
    return API.post("/api/v1/generateTestReport", reportParams);
  } 

  generateSaleReport(reportParams) {
    return API.post("/api/v1/generateSaleReport", reportParams);
  }
  
  generateSaleByRegionReport(reportParams) {
    return API.post("/api/v1/generateSaleByRegionReport", reportParams);
  } 

  generateSaleByCommissionReport(reportParams) {
    return API.post("/api/v1/generateSaleByCommissionReport", reportParams);
  } 

  generateSaleByCustomerReport(reportParams) {
    return API.post("/api/v1/generateSaleByCustomerReport", reportParams);
  }

  generateVendorsReport(reportParams) {
    return API.post("/api/v1/generateVendorsReport", reportParams);
  }

  generateSalesOperatorReport(reportParams) {
    return API.post("/api/v1/generateSalesOperatorReport", reportParams);
  }

  generateSalesBySalesManReport(reportParams) {
    return API.post("/api/v1/generateSalesBySalesManReport", reportParams);
  } 

  
  generateUserActivityReport(reportParams) {
    return API.post("/api/v1/generateUserActivityReport", reportParams);
  } 

  previewCustomerReport(reportParams) {
    return API.post("/api/v1/previewCustomerReport", reportParams);
  } 

  previewUserActivityReport(reportParams) {
    return API.post("/api/v1/previewUserActivityReport", reportParams);
  } 

  previewTotalDepositeReport(reportParams) {
    return API.post("/api/v1/previewCashInfoReport", reportParams);
  }   

  
  previewAccountTransReport(reportParams) {
    return API.post("/api/v1/previewAccountTransReport", reportParams);
  }

  previewAccountReport(reportParams) {
    return API.post("/api/v1/previewAccountReport", reportParams);
  }

  previewDealersReport(reportParams) {
    return API.post("/api/v1/previewDealersReport", reportParams);
  }
  
  previewVenderReport(reportParams) {
    return API.post("/api/v1/previewVenderReport", reportParams);
  }

  previewSalesByProviderReport(reportParams) {
    return API.post("/api/v1/previewSalesByProviderReport", reportParams);
  }

  previewSalesByRegionReport(reportParams) {
    return API.post("/api/v1/previewSalesByRegionReport", reportParams);
  }

  previewSalesByCommissionReport(reportParams) {
    return API.post("/api/v1/previewSalesByCommissionReport", reportParams);
  }

  previewSalesByCustomerReport(reportParams) {
    return API.post("/api/v1/previewSalesByCustomerReport", reportParams);
  }


  previewSalesBySalesManReport(reportParams) {
    return API.post("/api/v1/previewSalesBySalesManReport", reportParams);
  }

  getTotalBalanceDifference(reportParams, userId) {
    return API.post("/api/v1/getTotalBalanceDifference/"+ userId, reportParams);
  }  
  
}


export default new ReportService()