import axios from 'axios';
import React, { Component } from 'react'
import Menu from '../Menu';

import { Navigate, withRouter } from 'react-router-dom';
import MerchantService from '../../../services/wallet/MerchantService';
import RegionService from '../../../services/common/RegionService';
import Header from '../../common/Header';
import { NotificationContainer, NotificationManager } from 'react-notifications';
//toast.success,toast.error,toast.warnning


class AddMerchant extends React.Component {
  

  constructor() {
    super();
    this.state = {
      logo: '',
      regionList: []
      }
      {/*To handle file attachment*/}
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  

  handleInputChange(event) {
    this.setState({
      logo: event.target.files[0],
    })
  }
  componentDidMount() {
    // fetch('/api/v1/getRegions')
    //   .then(response => response.json())
    //   .then(data => this.setState({ regionList: data }));
    RegionService.getRegions()
    .then(response => {
      this.setState({
        regionList: response.data
        });
        
      })
      .catch(e => {
        
      });
  }
  handleSubmit = e => {
    e.preventDefault();

    var merchantData = new FormData();
    merchantData.append("name", this.name);
    merchantData.append("province", this.province);
    merchantData.append("city", this.city);
    merchantData.append("street", this.street);
    merchantData.append("tel", this.tel);
    merchantData.append("email", this.email);
    merchantData.append("website", this.website);
    merchantData.append('logo', this.state.logo);

   
    MerchantService.createMerchant(merchantData)
      .then(res => {
        //console.log(res.data);
      //  alert("Created successfully !!");
      //  toast(<h5 style={{backgroundColor: 'green'}}>Created successfully !!</h5>)
      NotificationManager.success("Successfully refunded !!", `${process.env.REACT_APP_MSG_TITLE}`, 3000);
        e.target.reset();
      })
      .catch(function (response) {
        //handle error
      //  alert("Something went wrong !!");
      NotificationManager.error("Created successfully !!", `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
      });
  };


  render() {
    const { regionList } = this.state;
    // console.log(this.props.location.state.mobileNo);
    // console.log("Mobile no="+ this.state.mobileNo);
    // console.log(this.props.location.state.type)
    return (

      <div>
        {/* <Header/> */}
        <Menu />
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper bg-white">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-left">
                    <li className="breadcrumb-item">
                      <a href="/#">Home</a>
                    </li>
                      <li className="breadcrumb-item active">Add Merchant</li>
                  </ol>
                </div>

              </div>
              <div >
                <div className="row">

                  <div className='col-2'></div>
                  <div className='col-7 bg-light p-4'>

                    <h3 className='mb-3' style={{ fontFamily: 'serif', textAlign: 'center' }}>{this.props.location.state.formName}</h3>

                    <form onSubmit={this.handleSubmit}>
                      {/*<h2>Register</h2> */}

                      <div className="row  " >
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6">
                          <input type="text" className="form-control " placeholder="Full Name" onChange={e => this.name = e.target.value} required="required" />
                        </div>
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6 ">
                      {/*  <input type="text" className="form-control " placeholder="Province" onChange={e => this.province = e.target.value} required="required" /> */}
                        <select onChange={e => this.province = e.target.value} className="form-control" >
                            <option value=""> Choose Province</option>
                            {regionList.map(getregion =>
                              <option value={getregion.id}>{getregion.region}</option>
                            )}
                          </select>
                        </div>
                      </div>

                      <div className="row ">
                        <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                          <input type="text" className="form-control" onChange={e => this.tel = e.target.value} placeholder="Mobile Number" required="required" />
                        </div>
                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                          <input type="text" className="form-control" onChange={e => this.city = e.target.value} placeholder="City" required="required" />
                        </div>
                      </div>
                      <div className="row  ">
                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6"   >
                        <input type="text" className="form-control" onChange={e => this.email = e.target.value} placeholder="Email" required="required" />
                        </div>
                        <div className="form-group col-6 "   >
                          <input type="text" className="form-control" onChange={e => this.street = e.target.value} placeholder="Street"/>
                        </div>
                      </div>

                      <div className="row  ">
                        <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6"   >
                        <input type="text" className="form-control" onChange={e => this.website = e.target.value} placeholder="Website" />
                        </div>
                        <div className="form-group col-6 "   >
                        <input type="file" className="form-control" placeholder="Logo" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="row  d-flex justify-content-center ">
                        <button type="submit" className="btn btn-success  px-5 py-2">Save</button>
                      </div>
                    </form>
                  </div>
                  <div className='col-2'></div>

                </div>
              </div>


              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </div>

          {/* /.content */}
        </div>
      </div>

    )
  }
}

export default withRouter(AddMerchant);
