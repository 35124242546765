import React, { Component } from 'react'
import { Link } from "react-router-dom";
import TopupMenu from '../../../components/etopup/TopupMenu';
import CustomersService from '../../../services/common/CustomersService';
import { faBackward, faBackwardFast, faForward, faForwardFast, faFilter, faThumbsUp, faBadgeCheck, faCheckDouble, faMoneyCheck, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../../components/common/Constant';
import PhoneInput from 'react-phone-input-2';
import CustomerTypeService from '../../../services/common/CustomerTypeService';
import CustomerVerificationService from '../../../services/common/CustomerVerificationService';
import Moment from 'react-moment';
import e from 'cors';
import Header from '../../common/Header';
import ReactLoading from "react-loading";

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}
const phoneNoRegEX = RegExp(/^[0-9\b]+$/u)

class TopupCustomers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // For search purposes
      accountNo: null,
      firstName: null,
      mobileNo: null,
      dateFrom: null,
      dateTo: null,
      customerType: null,
      customerId: '',
      isLoading: false,
      // For search purposes
      //  CustomerList: [],
      allCustomerTypes: [],
      CustomerList: [],
      pageNo: 1,
      pageSize: LOCALES.PAGE_SIZE,
      totalPage: 0,
      totalelement: 0,
      topupStatus: 1,
      counter: 1,
      id: 0,
      flag: false,
      isExpand: true,
      formErrors: {
        phoneNumberError: "",
        accountNoError: "",
        fullNameError: "",
        dateToError: "",
        dateFromError: ""
      },
    }
    //this.clearValue.bind(this)
    this.clearValue = this.clearValue.bind(this);
    this.editCustomer = this.editCustomer.bind(this);
    //  this.activation = this.activation.bind(this);
  }

  gotoEnteredPage = (pageNumber) => {

    this.state.pageNo = pageNumber
    this.seachCustomerBasedOnFilter()
    this.render()
  }
  gotoPageLast = () => {
    this.state.pageNo = this.state.totalPage
    this.seachCustomerBasedOnFilter()
    this.render()

  }
  gotoPageFirst = () => {
    this.state.pageNo = 1
    this.seachCustomerBasedOnFilter()
    this.render()
  }

  privPage = () => {
    let pages = 1
    if (this.state.pageNo > pages) {
      this.state.pageNo = this.state.pageNo - pages
      this.seachCustomerBasedOnFilter()
      this.render()

    }
  }

  nextPage = () => {

    let totalPages = this.state.totalPage;
    if (this.state.pageNo < totalPages) {
      this.state.pageNo = this.state.pageNo + 1
      this.seachCustomerBasedOnFilter()
      this.render()
    }
  }


  clearValue() {
    // should not reload
    this.state.firstName = null
    this.state.mobileNo = null
    this.state.accountNo = null
    this.state.dateFrom = null
    this.state.dateTo = null
    this.state.customerType = null
    this.state.clear = 1
  }


  clear = e => {
    e.preventDefault()
    if (this.state.clear == 1) {
      e.target.reset();
      this.state.clear = 0
      this.seachCustomerBasedOnFilter()

    }
  }

  onChangeMobileNo = mobile => {

    const { formErrors } = this.state
    this.state.customerId = null

    this.state.mobileNo = mobile
    formErrors.phoneNumberError =
      mobile.length == 0 || mobile.length < 6 || !phoneNoRegEX.test(mobile) ? "Phone Number sholud be digits with a min 6 & max 10" : ""
    if (formErrors.phoneNumberError.length === 0) {
      let mobilePhone = mobile
      //this.getCustomerBankInfo(mobilePhone)
    }
    this.setState({
      formErrors
    })
  }


  searchCustomerInfo(e) {

    const { formErrors, dateFrom, dateTo } = this.state
    formErrors.dateFromError = ''
    formErrors.dateToError = ''

    if (dateFrom != null) {
      formErrors.dateToError =
        dateTo == null ? 'date to is required' : ''
    }

    if (dateTo != null) {
      formErrors.dateFromError =
        dateFrom == null ? 'date from is required' : ''
    }

    if (this.state.dateFrom != null && this.state.dateTo != null) {
      if (this.state.dateFrom > this.state.dateTo) {
        formErrors.dateFromError = 'Date from should be lessthan or equal to date to'
      }
    }

    if (formValid(this.state.formErrors)) {

      this.state.clear = 0;
      this.state.pageNo = 1;
      this.seachCustomerBasedOnFilter()

    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
      this.setState({
        formErrors
      })
    }

  }
  seachCustomerBasedOnFilter() {

    this.CustomerList = null;
    let FilterCustomerInfo = {
      accountNo: this.state.accountNo,
      firstName: this.state.firstName,
      mobileNo: this.state.mobileNo,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      customerType: this.state.customerType,
      flag: true
    }

    CustomersService.getFilterdCustomerWithPagination(
      this.state.pageNo, this.state.pageSize, this.state.topupStatus, FilterCustomerInfo)
      .then(response => response.data)
      .then((data) => {
        if (data.content) {
          this.setState({
            CustomerList: data.content,
            isOpen: false,
            isLoading: true,
            totalPage: data.totalPages,
            isLoading: true,
            totalelement: data.pageable.totalelement,
            pageNo: data.pageable.pageNumber + 1
          });
        }
        if (data.content.length < 1) {
          NotificationManager.error("Customer not found", "AsPay");
        }
        this.setState({ isLoading: true })
      });

  }
  // componentDidUpdate() {
  //   this.seachCustomerBasedOnFilter()
  // }

  componentDidMount() {
    this.seachCustomerBasedOnFilter()

    // Get List of customer types
    CustomerTypeService.getCustomerTypes()
      .then(response => {
        if (response) {
          this.setState({
            allCustomerTypes: response.data
          });
        }
      })
      .catch(e => {
      });
  }

  // activation(customerMobileNo) {
  //   this.props.history.push({ pathname: '/verifyCustomer', state: { customerMobileNo: customerMobileNo } });
  // }

  editCustomer(customerMobileNo) {
    this.props.history.push(`/editTopupCustomer/${customerMobileNo}`);
  }

  filterToggle() {
    this.setState({
      isExpand: !this.state.isExpand
    });
  }


  // Enable for disabled customer
  async disableCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to disable this customer?")) {
      try {
        const response = await CustomerVerificationService.disableCustomerAccount(mobileNo);
        //  toast.success("Disabled successfully");
        NotificationManager.success("Disabled successfully", "Account Disabling");
      } catch (error) {
        // toast.error("Something went wrong !!");
        NotificationManager.error("Something went wrong !!", "Account Disabling");
      }
    }
    else {
    }

  }
  // Disable for customer account customer
  async enableCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to enable this customer?")) {
      try {
        const response = await CustomerVerificationService.enableCustomerAccount(mobileNo);
        NotificationManager.success("Enabled successfully", "Account Enabling");
      } catch (error) {
        // toast.error("Something went wrong !!");
        NotificationManager.error("Something went wrong !!", "Account Enabling");
      }
    }
    else {
    }

  }

  // Delete customer
  async deleteCustomer(mobileNo) {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        const response = await CustomersService.deleteCustomer(mobileNo);
        //toast.success("Deleted successfully");
        NotificationManager.success("Deleted successfully", "Account Deletion");
      } catch (error) {
        //toast.error("Something went wrong !!");
        NotificationManager.error("Something went wrong !!", "Account Deletion");
      }
    }
  }

  render() {

    const { CustomerList, formErrors, pageNo, isLoading, totalPage, allCustomerTypes } = this.state;
    this.state.counter = pageNo
    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <TopupMenu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 150 } width={ 150 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div>
          {/* <Header />
          <TopupMenu />
          <NotificationContainer /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Customer List</li>
                    </ol>
                  </div>

                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="form-title" >Customer List Information</div>
                    <form onSubmit={ this.clear } hidden={ this.state.isExpand } >
                      <div className="row" hidden={ this.state.isExpand }>
                        <div className="col-md-4">
                          <div>
                            <label htmlFor='mobileNo' >Mobile No</label>
                            {/* <input type="text" className="form-control" id='mobileNo'
                            onChange={e => this.state.mobileNo = e.target.value}
                            placeholder="Mobile No" /> */}
                            <PhoneInput countryCodeEditable={ false } value={ this.state.mobileNo }
                              country={ `${process.env.REACT_APP_ROOT_DEFUALT_COUNTRY_CODE}` }
                              placeholder="Enter phone number" id='mobileNo'
                              inputStyle={ { width: '100%', height: 'auto' } }
                              onChange={ phone => this.onChangeMobileNo(phone) } />
                          </div>
                          <div>
                            <label htmlFor='accountNo' > Account No</label>
                            <input type="text" className="form-control" id='accountNo'
                              onChange={ e => this.state.accountNo = e.target.value }
                              placeholder="Account No" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor='fullName'>Full Name</label>
                          <div className="w-90 pull-left">
                            <input type="text" className="form-control" id='fullName'
                              onChange={ e => this.state.firstName = e.target.value }
                              placeholder="Name" />
                          </div>
                          <label htmlFor='customerType'>Customer Type</label>
                          <div className="w-90 pull-left">
                            <select onChange={ e => this.state.customerType = e.target.value } className="form-control">
                              <option value=""> Choose type</option>
                              { allCustomerTypes.map(type =>
                                <option value={ type.id }>{ type.type }</option>
                              ) }
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor='dateFrom' >Date From</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateFrom'
                              onChange={ e => this.state.dateFrom = e.target.value }
                              placeholder="Date" />
                            { formErrors.dateFromError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.dateFromError }</span>
                            ) }
                          </div>

                          <label>Date To</label>
                          <div className="w-90 pull-left">
                            <input type="date" className="form-control" id='dateFrom'
                              onChange={ e => this.state.dateTo = e.target.value }
                              placeholder="Date" />
                            { formErrors.dateToError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.dateToError }</span>
                            ) }
                          </div>
                        </div>
                      </div>


                      <br />

                      <div>
                        <button type="button" title={ "Search" }
                          className="btn btn-sm btn-success primary m-1 float-right"
                          onClick={ () => this.searchCustomerInfo() }>Search</button>
                        <button type='submit' onClick={ this.clearValue.bind(this) } title={ "Clear" }
                          className="btn btn-sm btn-danger primary m-1 float-right"  >Clear</button>
                      </div>
                    </form>
                    <div style={ { clear: 'both' } }></div>
                    <br />

                    {/* <Link to={ { pathname: '/newcustomer', state: { type: "Agent", formName: "Customer Registration" } } }>
                    <button type="button" className="btn btn-sm btn-success float-left mb-2" onClick={ this.addCustomer }>
                      <i className="fas fa-plus" /> Add
                    </button>
                  </Link> */}
                    <button type='button' style={ { marginLeft: "3px" } } title={ "Show/Hide Filter" }
                      className="btn btn-sm btn-success primary float-left mb-2"
                      onClick={ () => this.filterToggle() }>
                      <FontAwesomeIcon icon={ faFilter } />
                      Filter
                    </button>
                    { isLoading &&
                      <table className=" table table-striped table-birdered " style={ { fontSize: 'smaller' } }  >
                        <thead className='thead-light'>
                          <tr style={ { marginTop: 0 } }>
                            <th>Full Name</th>
                            <th>Account No</th>
                            <th>Mobile Number</th>
                            {/* <th>Balance</th> */ }
                            <th>Type</th>

                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>

                          { CustomerList !== undefined && CustomerList.map(customer =>

                            <tr>
                              <td className='p-1 '>{ customer.firstName + " " + customer.middleName + " " + customer.lastName }</td>
                              <td className='p-1 '>{ customer.accountNo }</td>
                              <td className='p-1 '>{ customer.mobileNo }</td>
                              {/* <td className='p-1 '>{customer.balance}</td> */ }
                              <td className='p-1 '>{ customer.customerType.type }</td>


                              <td className='p-1'>
                                { customer.status === 0 &&
                                  <Link to={ { pathname: '/verifyTopCustomer', state: { mobileNo: customer.mobileNo, operation: "deactivation" } } }>
                                    <button className="btn btn-secondary btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Activate Account" /> </button>
                                  </Link>
                                }
                                { customer.status === 1 &&
                                  <Link to={ { pathname: '', state: { mobileNo: customer.mobileNo, operation: "deactivation" } } }> <button style={ { marginLeft: "2px" } } onClick={ () => this.disableCustomer(customer.mobileNo) } className="btn btn-success btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Disable Account" /> </button>
                                  </Link>
                                }
                                { customer.status === 2 &&
                                  <Link to={ { pathname: '', state: { mobileNo: customer.mobileNo, operation: "deactivation" } } }> <button style={ { marginLeft: "2px" } } onClick={ () => this.enableCustomer(customer.mobileNo) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-thumbs-up" title="Enable Account" /> </button>
                                  </Link>
                                }
                                <Link to={ { pathname: '/viewTopupCustomer', state: { CustomerType: "Agent", operationType: "View", mobileNo: customer.mobileNo } } }>
                                  <button className="btn btn-info btn-sm" title="View detail"><i className="nav-icon fa fa-eye fa-sm" /> </button>
                                </Link>
                                <button style={ { marginLeft: "2px" } } onClick={ () => this.editCustomer(customer.mobileNo) } title="Edit Customer" className="btn btn-warning btn-sm"><i className="nav-icon fas fa-edit fa-sm" /> </button>
                                {/* <button style={ { marginLeft: "2px" } } onClick={ () => this.deleteCustomer(customer.mobileNo) } className="btn btn-danger btn-sm"><i className="nav-icon fa fa-trash fa-sm" /> </button> */ }
                              </td>
                            </tr>

                          )
                          }

                        </tbody>
                      </table>
                    }
                    <div className="clearfix">&nbsp;</div>
                    <div className=" float-sm-center">
                      <span>
                        page{ '' }
                        <strong>
                          { pageNo } of { totalPage }
                        </strong>
                        { '' }
                      </span>
                      <span>
                        | Go To Page : { '' }
                        <input type='number' defaultValue={ pageNo }
                          onChange={ (e) => {
                            const pageNumber = e.target.value ? Number(e.target.value) : 0
                            this.gotoEnteredPage(pageNumber)
                          } } />
                      </span>
                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.gotoPageFirst() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackwardFast } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.privPage() } disabled={ pageNo === 1 ? true : false }>
                        <FontAwesomeIcon icon={ faBackward } />
                      </button>

                      <input type={ "number" } style={ { marginLeft: "2px", textAlign: 'center' } } className="form-group col-1 col-sm-1 col-md-1 col-lg-1 " defaultValue={ pageNo } value={ pageNo } readOnly />

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1  " onClick={ () => this.nextPage() } disabled={ pageNo === totalPage ? true : false }>
                        <FontAwesomeIcon icon={ faForward } />
                      </button>

                      <button style={ { marginLeft: "2px" } } className="btn btn-info px-1 py-1 " onClick={ () => this.gotoPageLast() } disabled={ pageNo === totalPage ? true : false } >
                        <FontAwesomeIcon icon={ faForwardFast } />
                      </button>
                    </div>
                  </div>
                </div>
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>

            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}
export default TopupCustomers