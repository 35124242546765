import API from '../../http-common';

class StatusSettingService {
  // get all Cash Information 
  getStatusSettings(userId) {
    return API.get("/api/v1/getStatusSettings/" + userId);
  }

  // create  Cash Information 
  createStatusSetting(userId, StatusSetting) {
    return API.post("/api/v1/createStatusSetting/" + userId, StatusSetting);
  }

  // get single Cash Information 
  getStatusSetting(id, userId) {
    return API.get("/api/v1/getStatusSettingById/" + id + "/" + userId);
  }  

  // remove  Cash Information 
  deleteStatusSetting(id, userId) {
    return API.delete('/api/v1/deleteStatusSetting' + '/' + id + "/" + userId);
  }

  // update  Cash Method Information 
  updateStatusSetting(id,userId, StatusSetting) {
    return API.put("/api/v1/updateStatusSetting" +"/" + id + "/"+ userId, StatusSetting);
  }
  
}


export default new StatusSettingService()