import React, { Component } from 'react';
import ProvidersService from '../../../services/common/ProvidersService';
import TopupMenu from '../../../components/etopup/TopupMenu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { LOCALES } from '../../common/Constant'
import Header from '../Header';
import ReactLoading from "react-loading";
import SettingService from '../../../services/common/SettingService';
import LoadingPopup from '../../wallet/LoadingPopup';
import IntegrationTypeService from '../../../services/etopup/IntegrationTypeService';

const formValid = formError => {
  let valid = true
  Object.values(formError).forEach(val => {
    val.length > 0 && (valid = false);
  });
  return valid;
}

const startCommRegEx = RegExp(/^(?=[1-9])[0-9]{3,4}$/u)
const commandRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const urlRegEx = RegExp(/^[\p{L} \p{N}_@.:/]{15,30}$/u)
const serviceCodeRegEx = RegExp(/^[a-zA-Z_\-\/]{2,15}$/u)
const bearerCodeRegEx = RegExp(/^[a-zA-Z_\-\/]{2,15}$/u)
const languageRegEx = RegExp(/^[a-zA-Z\-]{2,15}$/u)
const currencyRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)
const IPAddressRegEx = RegExp(/^[a-zA-Z0-9.]{1,40}$/u)
const portRegEx = RegExp(/^[0-9:]{0,4}$/u)
const addtionalPathReqEx = RegExp(/^(?=[\/])[a-zA-Z0-9\/]{1,50}$/u)
const clientUserIdRegEx = RegExp(/^[a-zA-Z0-9_\-\/ ]{3,30}$/u)
const initiatorPrincipalIdRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const channelRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const clientReferenceRegEx = RegExp(/^[a-zA-Z0-9_\-\/]{3,30}$/u)
const passwordRegEx = RegExp(/^[a-zA-Z0-9_#@&%\-\/]{3,30}$/u)
const clientRequestTimeoutRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)


class EditProviders extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingPop: false,
      providerIds: [],
      status:[],
      types:[],
      providerId: [],
      integrationId:[],
      integrationTypeList: [],
      providersInfo: {      
        startCommand: '',
        command: '',
        ipAddress: '',
        port: '',
        addtionalPath: '',
        serviceCode: '',
        bearerCode: '',
        language: '',
        currency: '',
        clientId: '',
        clientUserId: '',
        initiatorPrincipalId: '',
        channel: '',
        clientReference: '',
        password: '',
        clientRequestTimeout: 0,
        status:'',
        statusValue:'',
        type:'',
        typeValue:'',
        integrationTypeId:''
      },
      id: this.props.location.state.id,
      formErrors: {
        providerIdError: "",
        startCommandError: "",
        commandError: "",
        ammountError: "",
        IPAddressError: "",
        portError: "",
        addtionalPathError: "",
        serviceCodeError: "",
        bearerCodeError: "",
        languageError: "",
        currencyError: "",
        clientIdError: "",
        clientUserIdError: "",
        initiatorPrincipalIdError: "",
        channelError: "",
        clientReferenceError: "",
        passwordError: "",
        clientRequestTimeoutError: "",
        statusError:'',
        typeError:'',
        integrationTypeError:''
      },
      isCOMVIVA: false,
      isMTN: false,
      isEtsalat: false
    }

  }

  componentDidMount() {

    ProvidersService.getProvidersInfo(this.state.id, localStorage.userId)
      .then(response => {
        this.setState({
          providersInfo: response.data,
          providerId: response.data.providerId,
        });
      })
      .catch(e => {
        
      });


      IntegrationTypeService.getAllIntegrationTypes(localStorage.userId)
      .then(response => {
          this.setState({
              integrationTypeList: response.data,
              integrationId:response.data.id
          });
      })
      .catch(e => {                
      });

      SettingService.getStatusSettings(localStorage.userId)
      .then(response => {
        this.setState({
          status: response.data
        });
      })
      .catch(e => {
        
      });

      SettingService.getTypeSettings(localStorage.userId)
      .then(response => {
        this.setState({
          types: response.data
        });
      })
      .catch(e => {
        
      });

    ProvidersService.getProviders(localStorage.userId)
      .then(response => {
        this.setState({
          isLoading: true,
          providerIds: response.data
        });
        
      })
      .catch(e => {
        
        this.setState({ isLoading: true })
      });


  }

  providerValue = e => {
    e.preventDefault()
    const { formErrors, providerId } = this.state
    let isCOMVIVA = false
    let isMTN = false
    let isEtsalat = false

    providerId.id = e.target.value
    let selectedProviderId = e.target.value
    providerId.id = ""
    if (selectedProviderId == "----please select---") selectedProviderId = ""

    if (selectedProviderId == LOCALES.COMVIVA_PROVIDER)
      isCOMVIVA = true

    if (selectedProviderId == LOCALES.MTN_PROVIDER) {
      isMTN = true;
    }

    if (selectedProviderId == LOCALES.ETSALAT_PROVIDER) {
      isEtsalat = true;
    }


    formErrors.providerIdError = selectedProviderId == "" ? 'You must make a selection' : "";

    this.setState({
      providerId: {
        id: selectedProviderId
      },
      isCOMVIVA: isCOMVIVA,
      isMTN: isMTN,
      isEtsalat: isEtsalat,
      formErrors
    });
  }

  ClientIdValue = e =>{
    const { formErrors } = this.state

    this.state.clientId = e.target.value
    let selectedProviderId = e.target.value
    this.state.clientId = ""
    if (selectedProviderId == "----please select---") selectedProviderId = ""

    formErrors.clientIdError = selectedProviderId == "" ? 'You must make a selection' : "";

    this.setState({
      clientId: selectedProviderId,
      formErrors
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    
    const { providersInfo, providerId, formErrors } = this.state
    formErrors.IPAddressError = providersInfo.ipAddress == '' ? "IP address is Required" : this.state.formErrors.IPAddressError;
    formErrors.addtionalPathError = providersInfo.addtionalPath == '' ? "Address Path is Required" : this.state.formErrors.addtionalPathError;
    formErrors.providerIdError = providerId.id == '' || providerId.id == null || providerId.id == undefined ? 'You must make a selection' : this.state.formErrors.providerIdError;
    if (providerId.id !== '' && providerId.id == LOCALES.COMVIVA_PROVIDER) {
      formErrors.serviceCode = providersInfo.serviceCode == '' || providersInfo.serviceCode == null || providersInfo.serviceCode == undefined ? 'Service Code is Required' : this.state.formErrors.serviceCode;
      formErrors.bearerCodeError = providersInfo.bearerCode == '' || providersInfo.bearerCode == null || providersInfo.bearerCode == undefined ? 'Bearer Code is Required' : this.state.formErrors.bearerCodeError;
      formErrors.languageError = providersInfo.language == '' || providersInfo.language == null || providersInfo.language == undefined ? 'Language is Required' : this.state.formErrors.languageError;
      formErrors.currencyError = providersInfo.currency == '' || providersInfo.currency == null || providersInfo.currency == undefined ? 'Currency is Required' : this.state.formErrors.currencyError;
    }

    if (providerId.id !== '' && providerId.id == LOCALES.MTN_PROVIDER) {
      formErrors.clientIdError = providersInfo.clientId == '' || providersInfo.clientId == null || providersInfo.clientId == undefined ? 'You must make a selection' : this.state.formErrors.clientIdError;
      formErrors.clientUserIdError = providersInfo.clientUserId == '' || providersInfo.clientUserId == null || providersInfo.clientUserId == undefined ? 'Client User Id is Required' : this.state.formErrors.clientUserIdError;
      formErrors.clientReferenceError = providersInfo.clientReference == '' || providersInfo.clientReference == null || providersInfo.clientReference == undefined ? 'Client Reference is Required' : this.state.formErrors.clientReferenceError ;
      formErrors.clientRequestTimeoutError = providersInfo.clientRequestTimeout == '' || providersInfo.clientRequestTimeout == null || providersInfo.clientRequestTimeout == undefined ? 'Client Request Timeout is Required' : this.state.formErrors.clientRequestTimeoutError;
      formErrors.initiatorPrincipalIdError = providersInfo.initiatorPrincipalId == '' || providersInfo.initiatorPrincipalId == null || providersInfo.initiatorPrincipalId == undefined ? 'Initiator Principal Id is Required' : this.state.formErrors.initiatorPrincipalIdError ;
      formErrors.channelError = providersInfo.channel == '' || providersInfo.channel == null || providersInfo.channel == undefined ? 'Channel is Required' : this.state.formErrors.channelError;
      formErrors.passwordError = providersInfo.password == '' || providersInfo.password == null || providersInfo.password == undefined ? 'Password is Required' : this.state.formErrors.passwordError;

    }

    if (providerId.id !== '' && providerId.id == LOCALES.ETSALAT_PROVIDER) {
      formErrors.startCommandError = providersInfo.startCommand == '' || providersInfo.startCommand == null || providersInfo.startCommand == undefined ? 'Start Command is Required' : this.state.formErrors.startCommandError;
      formErrors.commandError = providersInfo.command == '' || providersInfo.command == null || providersInfo.command == undefined ? 'Command is Required' : this.state. formErrors.commandError;

    }

    if (formValid(this.state.formErrors)) {
      const providerInfo = new FormData()
      providerInfo.append("id", this.state.id)
      providerInfo.append("providerId", this.state.providerId.id)
      providerInfo.append("ipAddress", this.state.providersInfo.ipAddress)
      providerInfo.append("port", this.state.providersInfo.port)
      providerInfo.append("startCommand", this.state.providersInfo.startCommand == null ? 0 : this.state.providersInfo.startCommand)
      providerInfo.append("command", this.state.providersInfo.command)
      providerInfo.append("serviceCode", this.state.providersInfo.serviceCode)
      providerInfo.append("bearerCode", this.state.providersInfo.bearerCode)
      providerInfo.append("language", this.state.providersInfo.language)
      providerInfo.append("currency", this.state.providersInfo.currency)
      providerInfo.append("addtionalPath", this.state.providersInfo.addtionalPath)
      providerInfo.append("clientId", this.state.providersInfo.clientId)
      providerInfo.append("clientReference", this.state.providersInfo.clientReference)
      providerInfo.append("clientRequestTimeout", this.state.providersInfo.clientRequestTimeout)
      providerInfo.append("clientUserId", this.state.providersInfo.clientUserId)
      providerInfo.append("channel", this.state.providersInfo.channel)
      providerInfo.append("password", this.state.providersInfo.password)
      providerInfo.append("initiatorPrincipalId", this.state.providersInfo.initiatorPrincipalId)     
      providerInfo.append('status',this.state.providersInfo.status)
      providerInfo.append('statusValue',this.state.providersInfo.statusValue)
      providerInfo.append('type',this.state.providersInfo.type)
      providerInfo.append('typeValue',this.state.providersInfo.typeValue)
      providerInfo.append('integrationTypeId',this.state.providersInfo.id)
      providerInfo.append("userId", localStorage.userId)

      

      this.setState({ isLoadingPop: true})
      ProvidersService.updateProvidersInfos(providerInfo)
        .then(
          (res) => {
            NotificationManager.success(`${process.env.REACT_APP_UPDATE_SUCCESS_MSG}`, `${process.env.REACT_APP_MSG_TITLE}`, 3000);
            this.setState({ isLoadingPop: false})
            e.target.reset();
            this.cancel()
          },
          (err) => {
            NotificationManager.error(err.response.data.error, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
            this.setState({ isLoadingPop: false})
          })
        .catch(function (response) {
          NotificationManager.error(`${process.env.REACT_APP_UPDATE_ERROR_MSG}`, `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
          this.setState({ isLoadingPop: false})
        });
    } else {
      NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
      this.setState({
        formErrors
      })
    }
  };

  handelChange = e => {
    e.preventDefault()
    const { providersInfo } = this.state
    const { name, value } = e.target
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'ipAddress':
        providersInfo.ipAddress = value
        formErrors.IPAddressError =
          value.length == 0 || !IPAddressRegEx.test(value) ? name + " Sholud be a valid IP Address " : ""
        break;
      case 'port':
        providersInfo.port = value
        formErrors.portError =
          !portRegEx.test(value) ? name + " Sholud be a valid Port " : ""
        break;
      case 'addtionalPath':
        providersInfo.addtionalPath = value
        formErrors.addtionalPathError =
          value.length == 0 || !addtionalPathReqEx.test(value) ? name + " Sholud be a valid Path " : ""
        break;
      case 'serviceCode':
        providersInfo.serviceCode = value
        formErrors.serviceCodeError =
          value.length == 0 || value.length < 2 || !serviceCodeRegEx.test(value) ? name + " sholud be alphabetic with a min 3 & max 4 " : ""
        break;
      case 'bearerCode':
        providersInfo.bearerCode = value
        formErrors.bearerCodeError =
          value.length == 0 || !bearerCodeRegEx.test(value) ? name + " sholud be alphabetic with a min 4 & max 4 " : ""
        break;
      case 'language':
        providersInfo.language = value
        formErrors.languageError =
          value.length == 0 || value.length < 2 || !languageRegEx.test(value) ? name + " sholud be alphabetic with a min 2 & max 4 " : ""
        break;
      case 'currency':
        providersInfo.currency = value
        formErrors.currencyError =
          value.length == 0 || value.length < 3 || !currencyRegEx.test(value) ? name + " sholud be digites with a min 3 & max 5 " : ""
        break;
      case 'startCommand':
        providersInfo.startCommand = value
        formErrors.startCommandError =
          value.length == 0 || value.length < 3 || !startCommRegEx.test(value) ? name + " sholud be digites with a min 3 & max 4 " : ""
        break;
      case 'command':
        providersInfo.command = value
        formErrors.commandError =
          value.length == 0 || !commandRegEx.test(value) ? name + " sholud be a valid provider command " : ""
        break;
      case 'clientUserId':
        providersInfo.clientUserId = value
        formErrors.clientUserIdError =
          value.length == 0 || !clientUserIdRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'initiatorPrincipalId':
        providersInfo.initiatorPrincipalId = value
        formErrors.initiatorPrincipalIdError =
          value.length == 0 || !initiatorPrincipalIdRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'channel':
        providersInfo.channel = value
        formErrors.channelError =
          value.length == 0 || !channelRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'clientReference':
        providersInfo.clientReference = value
        formErrors.clientReferenceError =
          value.length == 0 || !clientReferenceRegEx.test(value) ? name + " sholud be alphanumeric with a min 3 & max 30 " : ""
        break;
      case 'password':
        providersInfo.password = value
        formErrors.passwordError =
          value.length == 0 || !passwordRegEx.test(value) ? name + " sholud be valid password with a min 3 & max 30 " : ""
        break;
      case 'clientRequestTimeout':
        providersInfo.clientRequestTimeout = value
        formErrors.clientRequestTimeoutError =
          value.length == 0 || !clientRequestTimeoutRegEx.test(value) ? name + " sholud be digites with a min 1 & max 10 " : ""
        break;
        case 'integrationTypeId':
          providersInfo.integrationTypeId = value
          formErrors.integrationTypeError =
            value.length == 0 ? " Please select integration type." : ""
          break;
      default:
        break;

    }

    this.setState({
      formErrors, [ name ]: value
    })
  }

  cancel() {
    this.props.history.push('/providersList')
  }

  StatusValue = e => {
    e.preventDefault()
    const { formErrors} = this.state
    var index = e.nativeEvent.target.selectedIndex;
    var text =e.nativeEvent.target[index].text;
    this.state.providersInfo.status = e.target.value
    this.state.providersInfo.statusValue = text
    let selectedValue = e.target.value
    this.state.providersInfo.status = ""
    if (selectedValue === null) selectedValue = ""

    formErrors.statusError = selectedValue === "" ? 'You must make a selection' : "";
    this.setState(function (prevState) {
      return {
        providersInfo: {
              ...prevState.providersInfo,
              status: selectedValue,
              statusValue: this.state.providersInfo.statusValue
          },
          formErrors
      };    
   
  });
   
  }

  TypeValue = e => {
    e.preventDefault()
    const { formErrors} = this.state

    var index = e.nativeEvent.target.selectedIndex;
    var text =e.nativeEvent.target[index].text;
    this.state.providersInfo.type = e.target.value
    this.state.providersInfo.typeValue = text
    let selectedValue = e.target.value
    
    this.state.providersInfo.type = ""
    if (selectedValue === null) selectedValue = ""

    formErrors.typeError = selectedValue === "" ? 'You must make a selection' : "";

    this.setState(function (prevState) {
        return {
          providersInfo: {
                ...prevState.providersInfo,
                type: selectedValue,
                typeValue: this.state.providersInfo.typeValue
            },
            formErrors
        };    
     
    });
  }


  render() {
    const { integrationTypeList, providerIds, providerId, integrationId,providersInfo, status, types, formErrors, isLoading } = this.state

      
    if (providerId.id !== undefined &&
      providerId.id === LOCALES.COMVIVA_PROVIDER) {
      this.state.isCOMVIVA = true
    }
    if (providerId.id !== undefined &&
      providerId.id === LOCALES.MTN_PROVIDER) {
      this.state.isMTN = true
    }

    if (providerId.id !== undefined &&
      providerId.id === LOCALES.ETSALAT_PROVIDER) {
      this.state.isEtsalat = true
    }


    let providerInfoList = providerIds.length > 0
      && providerIds.map((item, i) => {
        return (
          <option key={ i } value={ item.id }>{ item.providerName }</option>

        )
      }, this);

      let providerStatus = status.length > 0
      && status.map((item, i) => {
        return (
          <option key={ i }  label={ item.statusValue } value={ item.id }>{ item.statusValue }</option>

        )
      }, this);

      let providerType = types.length > 0
      && types.map((item, i) => {
        return (
          <option key={ i }  label={ item.type } value={ item.id }>{ item.type }</option>

        )
      }, this);


      let integrationType = integrationTypeList.length > 0
      && integrationTypeList.map((item, i) => {
        return (
          <option key={ i }  label={ item.type } value={ item.id }>{ item.type }</option>

        )
      }, this);


    if (!isLoading) {
      return (
        <div>
          {/* <Header />
          <TopupMenu /> */}
          <div className='content-wrapper'>
            <div className='row'>
              <div className='col-sm-12 col-md-5 col-lg-5'></div>
              <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mb-5'>
                <ReactLoading type="bars" color="#0000FF" height={ 90 } width={ 90 } />
              </div>
              <div className='col-sm-12 col-md-6 col-lg-6'></div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (

        <div >
        
          {/* <Header />
          <TopupMenu /> */}
          {/* Content Wrapper. Contains page content */ }
          <div className="content-wrapper bg-white">
            {/* Content Header (Page header) */ }
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <a href="/#">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Edit Provider Information</li>
                    </ol>
                  </div>

                </div>
                <div >
                  <div className="modal-body">

                    <div className='col-7 bg-light p-4'>

                      <div class="modal-header">
                        <h4 class="modal-title" >
                          Edit Provider Information
                        </h4>
                      </div>

                      <form onSubmit={ this.handleSubmit } noValidate>
                        <div className='row mb-2'>
                          <div className=" form-group col-6 col-sm-12 col-md-6 col-lg-6  ">
                            <label htmlFor='ProviderList'>Provider Company Name:</label>
                            <select className="form-control "
                              value={ providerId.id }
                              onChange={ this.providerValue} id='ProviderList'>
                              <option key={ null } value={ null }>----please select---</option>
                              { providerInfoList }
                            </select>

                            { formErrors.providerIdError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.providerIdError }</span>
                            ) }
                          </div>
                          <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor='ipAddress'> IP Address:</label>
                            <input type="text" id='ipAddress' className="form-control" name='ipAddress'
                              value={ providersInfo.ipAddress }
                              onChange={ this.handelChange } onBlur={ this.handelChange }
                              placeholder="IP Address" />

                            { formErrors.IPAddressError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.IPAddressError }</span>
                            ) }
                          </div>
                        </div>
                        <div className='row mb-2'>

                          <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor='port'> Port:</label>
                            <input type="text" id='port' className="form-control" name='port'
                              value={ providersInfo.port }
                              onChange={ this.handelChange } onBlur={ this.handelChange }
                              placeholder="Port" />

                            { formErrors.portError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.portError }</span>
                            ) }
                          </div>
                          <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                            <label htmlFor='addtionalPath'> Addtional Path:</label>
                            <input type="text" id='addtionalPath' className="form-control" name='addtionalPath'
                              value={ providersInfo.addtionalPath }
                              onChange={ this.handelChange } onBlur={ this.handelChange }
                              placeholder="Addtional Path" />

                            { formErrors.addtionalPathError.length > 0 && (
                              <span style={ { color: "#F61C04" } }>{ formErrors.addtionalPathError }</span>
                            ) }
                          </div>
                        </div>

                        <div className='row mb-2'>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='status'> Status:</label>
                              <select className="form-control "
                                value={ providersInfo.status }
                                onChange={ this.StatusValue } id='status'>
                                <option key={ null } value={ null }>----please select---</option>
                               {providerStatus}
                                </select>

                              { formErrors.statusError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.statusError }</span>
                              ) }
                            </div>
                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='type'> Type:</label>
                              <select className="form-control "
                                value={ providersInfo.type }
                                onChange={ this.TypeValue } id='type'>
                                <option key={ null } value={ null }>----please select---</option>
                                  {providerType}
                                </select>
                              { formErrors.typeError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.typeError }</span>
                              ) }
                            </div>

                            <div className="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                              <label htmlFor='integrationTypeId'> Integration Type:</label>
                              <select className="form-control "
                                value={ this.integrationId }
                                onChange={ this.handelChange } id='integrationTypeId'>
                                <option key={ null } value={ null }>----please select---</option>
                                  {integrationType}
                                </select>
                              { formErrors.integrationTypeError.length > 0 && (
                                <span style={ { color: "#F61C04" } }>{ formErrors.integrationTypeError }</span>
                              ) }
                            </div>
                     


                            </div>
                        <div hidden={ !this.state.isCOMVIVA } className="card my-4 border-radius-0" >
                          <div className="card-header border-radius-0 bg-light">
                            <h6 class="title " >COMVIVA Related Information </h6>
                          </div>
                          <div className="card-body">
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='serviceCode'> Service Code:</label>
                                <input type="text" id='serviceCode' className="form-control" name='serviceCode'
                                  value={ providersInfo.serviceCode }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Service Code" />

                                { formErrors.serviceCodeError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.serviceCodeError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='bearerCode'> Bearer Code:</label>
                                <input type="text" id='bearerCode' className="form-control" name='bearerCode'
                                  value={ providersInfo.bearerCode }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Bearer Code" />

                                { formErrors.bearerCodeError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.bearerCodeError }</span>
                                ) }
                              </div>

                            </div>
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='language'> Language:</label>
                                <input type="text" id='language' className="form-control" name='language'
                                  value={ providersInfo.language }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="language" />
                                { formErrors.languageError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.languageError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='currency'>Currency:</label>
                                <input type="text" id='currency' className="form-control" name='currency'
                                  value={ providersInfo.currency }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="currency" />
                                { formErrors.currencyError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.currencyError }</span>
                                ) }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div hidden={ !this.state.isMTN } className="card my-4 border-radius-0" >
                          <div className="card-header border-radius-0 bg-light">
                            <h6 class="title " >MTN Related Information </h6>
                          </div>
                          <div className="card-body">
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='clientId'> Client Id:</label>
                                <select className="form-control "
                                  value={ providersInfo.clientId }
                                  onChange={ this.ClientIdValue } id='ProviderList'>
                                  <option key={ null } value={ null }>----please select---</option>
                                  <option key='TERMINALID' value='TERMINALID'>TERMINALID</option>
                                  <option key='ADMINUSER' value='ADMINUSER'>ADMINUSER</option>
                                  <option key='RESELLERMSISDN' value='RESELLERMSISDN'>RESELLERMSISDN</option>
                                  <option key='RESELLERUSER' value='RESELLERUSER'>RESELLERUSER</option>
                                </select>

                                { formErrors.clientIdError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.clientIdError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='clientUserId'> Client User Id:</label>
                                <input type="text" id='clientUserId' className="form-control" name='clientUserId'
                                  value={ providersInfo.clientUserId }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Client User Id " />

                                { formErrors.clientUserIdError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.clientUserIdError }</span>
                                ) }
                              </div>

                            </div>
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='initiatorPrincipalId'> Initiator Principal Id:</label>
                                <input type="text" id='initiatorPrincipalId' className="form-control" name='initiatorPrincipalId'
                                  value={ providersInfo.initiatorPrincipalId }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Initiator Principal Id" />

                                { formErrors.initiatorPrincipalIdError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.initiatorPrincipalIdError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='channel'>Channel:</label>
                                <input type="text" id='channel' className="form-control" name='channel'
                                  value={ providersInfo.channel }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="channel" />

                                { formErrors.channelError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.channelError }</span>
                                ) }
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='clientReference'> Client Reference:</label>
                                <input type="text" id='clientReference' className="form-control" name='clientReference'
                                  value={ providersInfo.clientReference }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Client Reference" />

                                { formErrors.clientReferenceError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.clientReferenceError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='password'>Password:</label>
                                <input type="text" id='password' className="form-control" name='password'
                                  value={ providersInfo.password }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="password" />

                                { formErrors.passwordError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.passwordError }</span>
                                ) }
                              </div>
                            </div>
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='clientRequestTimeout'>Client Request Timeout:</label>
                                <input type="text" id='clientRequestTimeout' className="form-control" name='clientRequestTimeout'
                                  value={ providersInfo.clientRequestTimeout }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Client Request Timeout" />

                                { formErrors.clientRequestTimeoutError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.clientRequestTimeoutError }</span>
                                ) }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div hidden={ !this.state.isEtsalat } className="card my-4 border-radius-0" >
                          <div className="card-header border-radius-0 bg-light">
                            <h6 class="title " >Etsalat Related Information </h6>
                          </div>
                          <div className="card-body">
                            <div className='row mb-2'>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='startCommand'>Start Command:</label>
                                <input type="text" id='startCommand' className="form-control" name='startCommand'
                                  value={ providersInfo.startCommand }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Start Command" />

                                { formErrors.startCommandError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.startCommandError }</span>
                                ) }
                              </div>
                              <div className="form-group col-6 col-sm-12 col-md-6 col-lg-6">
                                <label htmlFor='command'>Command:</label>
                                <input type="text" id='command' className="form-control" name='command'
                                  value={ providersInfo.command }
                                  onChange={ this.handelChange } onBlur={ this.handelChange }
                                  placeholder="Command" />

                                { formErrors.commandError.length > 0 && (
                                  <span style={ { color: "#F61C04" } }>{ formErrors.commandError }</span>
                                ) }
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row  d-flex justify-content-left ">
                          <button disabled={this.state.isLoadingPop} type="submit"
                            className="btn btn-success px-4 py-2" style={ { marginLeft: "20px" } }>Update</button>
                          <button type="submit" onClick={ this.cancel.bind(this) }
                            className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                        </div>

                      </form>
                    </div>

                  </div>
                </div>
                <NotificationContainer />
                {this.state.isLoadingPop && <LoadingPopup
                      content={<>
                               <form>
                                    <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                </form>
                      </>}
                />}
                {/* /.row */ }
              </div>
              {/* /.container-fluid */ }
            </div>

            {/* /.content */ }
          </div>
        </div>

      )
    }
  }
}




export default EditProviders;