import API from '../../http-common';

class RollbackTransactionService {
    rollbackTransaction(rollbackTransactionDAta) {
        return API.post('/api/v1/rollbackMissendedTransaction', rollbackTransactionDAta);
    }
    getAllRollbackHistory(pageNo, pageSize, userId) {
        return API.get('/api/v1/getAllRollbackHistory/' + pageNo + "/" + pageSize + "/" + userId);
    }
    getInterruptedTopupsByCustomerId(customerId, userId) {
        return API.get('/api/v1/getInterruptedTopups/' + customerId + "/" + userId);
    }
    findTopupSalesHistoryById(id, userId) {
        return API.get('/api/v1/findTopupSalesHistoryById/' + id + "/" + userId);
    }
    rollbackIntruptedTrxn(id, userId) {
        return API.get('/api/v1/rollbackIntruptedTrxn/' + id + "/" + userId);
    }
}
export default new RollbackTransactionService()