import React, { Component } from 'react'
import TopupMenu from '../TopupMenu';
//import TopupMenu from '../../../components/etopup/TopupMenu';
import { Navigate, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import 'react-phone-input-2/lib/style.css';
import Header from '../../common/Header';
import ProviderService from '../../../services/etopup/ProviderService';
import LoginService from '../../../services/common/LoginService';
import TopupTransferService from '../../../services/etopup/TopupTransferService';
import { LOCALES } from '../../common/Constant';
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import API from '../../../http-common';
import LoadingPopup from '../../wallet/LoadingPopup';
import ReactLoading from "react-loading"; 

const formValid = formError => {
    let valid = true
    Object.values(formError).forEach(val => {
        val.length > 0 && (valid = false);
    });
    return valid;
}
const amountRegEx = RegExp(/^(?=[1-9]\d*|\.\d+)\d*(\.\d{1,10})?$/)

class SalesTopupTransfer extends React.Component {

    constructor (props) {
        super(props);
        this.state = {

            customerType:null,
            parentDealerSelectOption: null,
            childDealerSelectOption: null,
            parentId:null,
            childId:null,
            customerId: '',
            createdBy: '',
            formErrors: {
                amountError: "",
                parentDealerSelectOptionError: "",
                childDealerSelectOptionError:""
            },
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handelInputChange = this.handelInputChange.bind(this);

    }

    handelInputChange = e => {
        e.preventDefault();
        const { customerId } = this.state
        const { name, value } = e.target
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'amount':
                formErrors.amountError =
                    value.length == 0 || !amountRegEx.test(value) ? name + " sholud only be digits " : ""
                break;
            default:
                break;
        }

        this.setState({
            formErrors, [ name ]: value
        })
    }
    
    fetchParentDealer = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            setTimeout(() => {
                API.get("/api/v1/getMainDealers/" + inputValue +"/"+ localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.fullName, value: element.id })
                        });
                        callback(temp)
                        this.setState({ 
                            parentDealers: data,

                        })

                    })
                    .catch((error) => {
                    })
            })
        }
    }
    
    onParentDealerSearchChange = (parentDealerSelectOption) => {
        if (parentDealerSelectOption) {
            this.setState({
                parentDealerSelectOption:parentDealerSelectOption
            });
        }
    }
    
    fetchChildDealer = (inputValue, callback) => {
        if (!inputValue) {
            callback({})
        } else {
            this.setState({
                parentId:this.state.parentDealerSelectOption!=null  ? this.state.parentDealerSelectOption.value :localStorage.customerIdentity
            })
            setTimeout(() => {
                API.get("/api/v1/findCustomerDealers/" + inputValue + "/" + this.state.parentId + "/" + localStorage.userId)
                    .then((response) => response.data)
                    .then((data) => {
                        const temp = [];
                        data.forEach((element) => {
                            temp.push({ label: element.dealerCustomerId.fullName, value: element.dealerCustomerId.id })
                        });
                        callback(temp)
                        this.setState({ 
                            childDealers: data,
                        })
                    })
                    .catch((error) => {
                    })
            })
        }
    }
    
    onChildDealerSearchChange = (childDealerSelectOption) => {
        if (childDealerSelectOption) {
            this.setState({
                childDealerSelectOption:childDealerSelectOption
            });
        }
    }

    componentDidMount() {
        LoginService.getUserInfo(localStorage.userId)
            .then(response => {
                this.setState({
                    customerId: response.data.customerId,
                    createdBy: this.state.customerId,
                    customerType: response.data.type
                });
            })
            .catch(e => {
                
            });
    }

    Cancel = (e) => {
        this.props.history.push('/etopupDashboard');
    }

    handleSubmit(e) {       
         e.preventDefault();//
        const { amount, parentDealerSelectOption,childDealerSelectOption, formErrors } = this.state
        formErrors.amountError = amount == '' || amount == null || amount == undefined ? 'Amount is Required' : this.state.formErrors.amountError;
        if(localStorage.type==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}`){
        formErrors.parentDealerSelectOptionError = parentDealerSelectOption == '' || parentDealerSelectOption == null || parentDealerSelectOption == undefined ? 'Please select the parent dealer' : this.state.formErrors.parentDealerSelectOptionError;
           }
        formErrors.childDealerSelectOptionError = childDealerSelectOption == '' || childDealerSelectOption == null || childDealerSelectOption == undefined ? 'Please select the Sub Dealer' : this.state.formErrors.childDealerSelectOptionError;
   
        this.state.parentId=this.state.parentDealerSelectOption!=null ? this.state.parentDealerSelectOption.value : localStorage.customerIdentity;
        this.state.childId=this.state.childDealerSelectOption!=null ? this.state.childDealerSelectOption.value : this.state.childDealerSelectOption;

        if (formValid(this.state.formErrors)) {
           
            this.setState({ isLoading: true})
            TopupTransferService.createSalesTopupTransfer(this.state.parentId,this.state.childId, this.state.amount, localStorage.userId)
                .then(response => {
                    NotificationManager.success("Transfer is successfull",  `${process.env.REACT_APP_MSG_TITLE}`, 3000);
                    //this.setState({ isLoading: false})
                    this.props.history.push('/transactionMonitoringList')
                })
                .catch(function (err) {
                    NotificationManager.error(err.response.data.error,  `${process.env.REACT_APP_MSG_TITLE_ERROR}`, 3000);
                    //this.setState({ isLoading: false})
                });
        } else {
            NotificationManager.warning(`${process.env.REACT_APP_REQUIRED_FIELDS}`, `${process.env.REACT_APP_MSG_TITLE_WARNING}`, 3000);
            this.setState({
                formErrors
            })
        }
    };


    render() {
        const { parentDealers, providersList, customerType,formErrors } = this.state;

        return (

            <div>
                {/* <Header />
                <TopupMenu />

                <NotificationContainer /> */}
                <div className="content-wrapper bg-white">
                    {/* Content Header (Page header) */ }
                    <div className="content-header">
                        <div className="container-fluid">

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-left">
                                    <li className="breadcrumb-item">
                                        <a href="/#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">eTop Up Transfer</li>
                                </ol>
                            </div>
                            <div >
                                <div className="modal-body pt-5 ">

                                    <div className='col-1'></div>
                                    <div className='col-8 bg-light p-3'>

                                        <form onSubmit={ this.handleSubmit }>
                                            {/*Basic Information*/ }
                                            <div className="card  border-radius-0" >
                                                <div className="card-header border-radius-0 bg-light">
                                                    <h6 class="title " >eTop Up Transfer </h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                    { this.state.customerType==`${process.env.REACT_APP_CUSTOMER_TYPE_USER}` &&
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Parent Dealer:</label>
                                                            <AsyncSelect id='CustomerList'
                                                                required
                                                                placeholder={ "Search by account or mobile" }
                                                                value={ this.state.parentDealerSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchParentDealer }
                                                                isLoading={ this.state.parentDealerSelectOption === {} &&
                                                                    this.state.parentDealerSelectOption === undefined &&
                                                                    this.state.parentDealerSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onParentDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.parentDealerSelectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.parentDealerSelectOptionError }</span>
                                                            ) }
                                                        </div>
                                                        }
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='Country'>Sub Dealer:</label>
                                                            <AsyncSelect id='CustomerList'
                                                                required
                                                                placeholder={ "Search  by account or mobile" }
                                                                value={ this.state.childDealerSelectOption }
                                                                isClearable={ true }
                                                                loadOptions={ this.fetchChildDealer }
                                                                isLoading={ this.state.childDealerSelectOption === {} &&
                                                                    this.state.childDealerSelectOption === undefined &&
                                                                    this.state.childDealerSelectOption === null ? true : false
                                                                }
                                                                onChange={ (e) => {
                                                                    this.onChildDealerSearchChange(e)
                                                                } }
                                                                defaultOptions={ false }
                                                            />
                                                            { formErrors.childDealerSelectOptionError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.childDealerSelectOptionError }</span>
                                                            ) }
                                                        </div>
                                                        <div className=" form-group col-12 col-sm-12 col-md-7 col-lg-7">
                                                            <label htmlFor='amount'>Amount:</label>
                                                            <input type="text" className="form-control " id='amount' name='amount'

                                                                onChange={ this.handelInputChange } onBlur={ this.handelInputChange } />
                                                            { formErrors.amountError.length > 0 && (
                                                                <span style={ { color: "#F61C04" } }>{ formErrors.amountError }</span>
                                                            ) }
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                            {/*Basic Information*/ }

                                            <div className="row  d-flex justify-content-left clear-fix ">
                                                <button disabled={this.state.isLoading} type="submit" className="btn btn-success px-4 py-2 " style={ { marginLeft: "30px" } }>Save</button>
                                                <button type="submit" onClick={ () => this.Cancel() }
                                                    className="btn btn-warning  px-3 py-2" style={ { marginLeft: "10px" } }>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading && <LoadingPopup
                                content={<>
                                        <form>
                                                <ReactLoading  type="bars" color="#0000FF" height={50} width={50} />
                                            </form>
                                </>}
                             />}

                            {/* /.row */ }
                        </div>
                        {/* /.container-fluid */ }
                    </div>

                    {/* /.content */ }
                </div>
            </div>

        )
    }
}

export default withRouter(SalesTopupTransfer);
